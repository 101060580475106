import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

import { AdvancePaymentService } from '../../../services/advancePayment.service';
import { SettlementService } from '../../../services/settlement.service';
import { InventoryService } from '../../../services/inventory.service';
import { ProductorService } from '../../../services/productor.service';
import { PaymentService } from '../../../services/payment.service';
import { PrintService } from '../../../services/print.service';
import { ExcelService } from '../../../services/excel.service';
import { UserService } from '../../../services/user.service';
import { GLOBAL } from '../../../services/global';

import { SettlementsPayComponent } from '../settlements-pay/settlements-pay.component';
import { SeatAddModalComponent } from '../../seat-add-modal/seat-add-modal.component';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';

import { AdvancePayment } from '../../../models/advancePayment';
import { CoffeeFarmer } from '../../../models/productor';
import { Settlement } from '../../../models/settlement';
import { Inventory } from '../../../models/inventory';
import { Payment } from '../../../models/payment';
import { Route } from '@angular/compiler/src/core';
import { IhcafeVoucherComponent } from '../ihcafe-voucher/ihcafe-voucher.component';

@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html',
  styleUrls: ['./settlement.component.css'],
})

export class SettlementComponent implements OnInit, AfterViewInit {

  public title = 'Liquidaciones';
  public inventory: any = {};
  public identity;
  public identi;
  public harvests = ['Todas', '2018 / 2019', '2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024'];
  public currentHarvest = '';
  public indexedSettlements: any = {};
  public loading = false;
  public dataTable;
  public form: FormGroup;
  public moreBtn = false;
  public alertMessage = '';
  public totalSettlements = 0;
  public settlementsData: Settlement[] = [];
  public convertToExcel = [];
  public countSettlements = 0;
  public repetidos = [];
  public filteredCoffeeFarmers: CoffeeFarmer[] = [];
  public availableCoffeefarmers: CoffeeFarmer[] = [];
  public pipe = new DatePipe('es-HN');
  public status = ['Pendiente', 'Parcial', 'Pagado'];
  public displayedColumns: string[] = ['cod_set', 'date', 'coffeefarmer', 'total_qq', 'total',
    'total_deductions', 'total_net', 'status', 'observations', 'action' ];

  private tableRef: HTMLElement;
  private page = 0;
  private limit = 100;
  public currency;
  public tempData = {
    totalqq: 0,
    totalqqWn: 0,
    real: 0
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private _inventoryService: InventoryService,
    private _userService: UserService,
    private _advancePaymentService: AdvancePaymentService,
    private _settlementService: SettlementService,
    private _printService: PrintService,
    private _coffeeFarmerService: ProductorService,
    private _paymentService: PaymentService,
    private excelService: ExcelService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.identity = this._userService.getCompany();
    this.identi = this._userService.getIdentity();
    console.log(this.identi);
  }

  ngOnInit() {
    this.tableRef = document.getElementById('matTableSettlement');
    this.currentHarvest = this.identi.company.harvest;
    this.currency = GLOBAL.currency;
    if (!this.harvests.some(h => h === this.currentHarvest)) {
      this.harvests.push(this.currentHarvest);
    }
    this.createForm();
    this.getCoffeefarmers();
    this.listeners();
    this.form.get('harvest').setValue(this.currentHarvest);
    this.getDataWithFilters();
    this.totalSumSettlementPerHarvest();
  }

  ngAfterViewInit(): void {
    if (this.identity._id === '5f6b9d60e0ac8256dcb36e28') {
      this.displayedColumns.splice(7, 0, 'pending');
    }
    this.paginator._intl.itemsPerPageLabel = 'Registros por página';
  }

  listeners(): void {
    this.form.get('coffeefarmerSearch').valueChanges.subscribe((value: string) => {
      this.filteredCoffeeFarmers = this.availableCoffeefarmers
        .filter(coffeefarmer => String(coffeefarmer.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });
  }

  getCoffeefarmers(): void {
    this._coffeeFarmerService.getProductores().subscribe(response => {
      this.availableCoffeefarmers = response as CoffeeFarmer[];
      this.filteredCoffeeFarmers = response as CoffeeFarmer[];
    });
  }

  getDataWithFilters(): void {
    const filters = this.getFilterData();
    this._settlementService.getSettlementsWithFilter(filters).subscribe(res => {
      this.loading = false;
      if (res) {
        this.settlementsData = res.items;
        this.countSettlements = res.count;
        /* res.items.forEach((item, index) => {
          this.tempData.totalqqWn += _.sum(item.qqForWn);
          this.tempData.totalqq += item.total_qq;
          this.tempData.real += _.sumBy(item.weight_notes, 'total_net_qq');
          if (this.tempData.totalqqWn !== this.tempData.totalqq || this.tempData.totalqqWn !== this.tempData.real) {
            console.log(`
            ${index + 1}, wn: ${this.tempData.totalqqWn}
            qq: ${this.tempData.totalqq}
            real: ${this.tempData.real}
            current: ${_.sumBy(item.weight_notes, 'total_net_qq')}
            `);
            this.tempData.real = this.tempData.totalqqWn;
            const temp = _.cloneDeep(item);
            temp.weight_notes = temp.weight_notes.map(i => i.total_net_qq);
            console.error(temp);
          }
          if (index === res.items.length - 1) {
            console.log('finish iter');
          }
        }); */
      } else {
        this.settlementsData = [];
      }
    }, err => {
      console.error('ERROR EN Liquidaciones con filtro ', err);
      this.loading = false;
      this.alertMessage = 'No se pudieron obtener los registros';
    });
  }

  getFilterData(): any {
    const filters = _.cloneDeep(this.form.getRawValue());
    this.loading = true;
    Object.keys(filters).forEach((key) => {
      if (filters[key] == null || filters[key] === '' || (key === 'harvest' && filters[key] === 'Todas')) {
        delete filters[key];
      } else {
        filters[key] = (key !== 'date_in' && key !== 'date_out' && key !== null) ? filters[key].toString().toLowerCase() : filters[key];
      }
    });
    delete filters['coffeefarmerName'];
    filters['skip'] = this.page * this.limit;
    filters['limit'] = this.limit;
    return filters;
  }

  totalSumSettlementPerHarvest(): void {
    this._settlementService.getSumHarvest(this.currentHarvest).subscribe(response => {
      this.totalSettlements = response.sum;
      this.countSettlements = response.len;
    }, err => console.error('Error al intertar obtener la sumatoria: ', err));
  }

  setLocalStorageData(): void {
    const price = this._userService.getCompany().price;
    const data = {
      date: '',
      coffeefarmer: '',
      coffeefarmer_id: '',
      total: 0,
      total_net: 0,
      cost_per_beneficiary: 0,
      costs_per_beneficiary: [],
      total_deductions: 0,
      freight: 0,
      contributions: 0,
      export_costs: 0,
      export_cert: 0,
      coffee_price: price,
      financial_expenses: 0,
      other_deductions: 0,
      advancePayments: [],
      weight_notes: []
    };
/*     if (localStorage.getItem('settlement') !== null) {
      const dialogRef = this.dialog.open(DeleteModalComponent, {
        panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
        width: '400px',
        data: {
          header: 'Cargar datos',
          title: '',
          text: `Hay datos ingresados anteriormente. ¿Desea mantenerlos?`
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result !== 'confirm') {
          console.log('Limpiar el LS');
          localStorage.setItem('settlement', JSON.stringify(data));
          localStorage.setItem('indexSelectedAdvancePayments', JSON.stringify({}));
          localStorage.setItem('indexSelectedWeightNotes', JSON.stringify({}));
        }
        this.router.navigateByUrl('/administracion/liquidaciones/crear/paso1');
      });
    } else { */
      localStorage.setItem('settlement', JSON.stringify(data));
      localStorage.setItem('indexSelectedAdvancePayments', JSON.stringify({}));
      localStorage.setItem('indexSelectedWeightNotes', JSON.stringify({}));
      this.router.navigateByUrl('/administracion/liquidaciones/crear/paso1');
    // }

  }

  filterData(): void {
    this.page = 0;
    this.paginator.firstPage();
    this.getDataWithFilters();
  }

  changePage(paginator: MatPaginator) {
    this.limit = paginator.pageSize;
    this.page = paginator.pageIndex;
    const tableRef = window.document.getElementById('matTableSettlement');
    tableRef !== null ? tableRef.scrollTo(0, 0) : console.log('tableRefNull', tableRef);
    this.getDataWithFilters();
  }

  createForm(): void {
    this.form = this.fb.group({
      cod_set:            [''],
      harvest:            [''],
      coffeefarmer_id:    [''],
      coffeefarmerSearch: [''],
      coffeefarmerName:   [''],
      date_in:            [''],
      date_out:           [''],
      status:             [''],
      observations:       [''],
    });
  }

  clearForm(): void {
    // tslint:disable-next-line:forin
    for (const name in this.form.controls) {
      this.form.controls[name].setValue('');
    }
    this.form.get('harvest').setValue(this.currentHarvest);
    this.page = 0;
    this.getDataWithFilters();
  }

  openDialogD(settlement) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Anular liquidación',
        title: '',
        text: `¿Seguro que quieres anular <strong>#${settlement.cod_set}</strong>?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.cancelSettlement(settlement);
      }
    });
  }

  openDialogPay(settlement: Settlement): void {
    const dialogRef = this.dialog.open(SettlementsPayComponent, {
      width: '600px',
      height: '560px',
      data: settlement
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDataWithFilters();
      }
    });
  }

  openDialogSeat(settlement: Settlement): void {
    settlement['tag'] = 'Liquidación';
    const dialogRef = this.dialog.open(SeatAddModalComponent, {
      height: '700px',
      width: '900px',
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
      data: settlement
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancel') {
        this.getDataWithFilters();
      }
    });
  }

  openIHCAFE(settlement: Settlement): void {
    settlement['tag'] = 'Liquidación';
    const dialogRef = this.dialog.open(IhcafeVoucherComponent, {
      height: '700px',
      width: '900px',
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
      data: settlement
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancel') {
        this.getDataWithFilters();
      }
    });
  }

  print(settlement) {
    console.log('toPrint: ', settlement);
    this._printService.getLiquidation(settlement._id).then(
      (res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      })
      .catch(err => console.log('Error', err));
  }

  cancelSettlement(settlement) {
    settlement['enabled'] = false;
    this._settlementService.updateSettlement(settlement._id, settlement).subscribe(response => {
      console.log('Liquidacion anulada: ', response);
      setTimeout(() => {
        this.getDataWithFilters();
      }, 1000);
      this.cancelPayments(settlement);
      this.returnWnValues(settlement);
      // todo : Anular asiento
    }, err => console.error('Error al anular liquidacion: ', err));
  }

  cancelPayments(settlement: Settlement): void {
    this._paymentService.getPaymentPerSettlement(settlement._id).subscribe(response => {
      const payments = response as Payment[];
      payments.forEach((item) => {
        item['enabled'] = false;
        this._paymentService.editPayment(item._id, item).subscribe(responseEditPayment => {
          console.log('Abono anulado: ', responseEditPayment);
          this._advancePaymentService.recalculateAdvancePayment(item.advancePayment._id);
        }, err => console.error('Error al anular abono: ', err));
      });
    });
  }

  returnWnValues(settlement: Settlement): void {
    const data = settlement.weight_notes;
    data.forEach((wn: Inventory, index) => {
      wn['partialData'] = wn.hasOwnProperty('partialData') ? wn.partialData : { dry: false, settlement: false };
      const total_qq = settlement.qqForWn[index] + wn.total_net_sett;
      wn.total_net_sett = total_qq < 0 ? 0 : total_qq;
      wn.total_net_sett = wn.total_net_sett > wn.total_net_qq ? wn.total_net_qq : total_qq;
      wn.liquidation = wn.liquidation.filter(sett => sett._id !== settlement._id);
      if (wn.total_net_sett === 0) {
        wn.liquidated = true;
        wn.entry = 'Propio';
        wn.partialData.settlement = false;
      } else if (wn.total_net_sett === wn.total_net_qq ) {
        wn.liquidated = false;
        wn.entry = 'Depósito';
        wn.partialData.settlement = false;
        // delete wn['liquidation'];
      } else {
        wn.entry = 'Depósito';
        wn.liquidated = false;
        wn.partialData.settlement = true;
      }
      this._inventoryService.editInventory(wn._id, wn).subscribe(res => {
        console.log('Inventario editado: ', res);
      }, err => console.error('Error al editar inventario: ', err));
    });
  }

  existPending(settlement: any) {
    let status: boolean;
    if (settlement.hasOwnProperty('pending')) {
      if (settlement.pending !== null && settlement.pending !== undefined) {
        if (settlement.pending > 0) {
          status = true;
        } else {
          status = false;
        }
      } else {
        status = false;
      }
    } else {
      status = false;
    }
    return status;
  }

  setCoffeefarmer(producer): void {
    this.form.get('coffeefarmer_id').setValue(producer._id);
    this.form.get('coffeefarmerName').setValue(producer.name);
  }

  exportAsXLSX(): void {
    const filters = _.cloneDeep(this.form.getRawValue());
    this.loading = true;
    Object.keys(filters).forEach((key) => {
      if (filters[key] == null || filters[key] === '' || (key === 'harvest' && filters[key] === 'Todas')) {
        delete filters[key];
      } else {
        filters[key] = (key !== 'date_in' && key !== 'date_out' && key !== null) ? filters[key].toString().toLowerCase() : filters[key];
      }
    });
    delete filters['coffeefarmerName'];
    filters['skip'] = 0;
    filters['limit'] = 25000;
    this._settlementService.getSettlementsWithFilter(filters).subscribe(res => {
      if (res) {
        this.convertToExcel = res.items as Settlement[];
        this.convertToExcel.forEach((element, i) => {
          let status = '';
          if (this.identity._id === '5ff5e3213545ae31b8d199b0') {
            status = element.weight_notes[0].state;
          }
          const newDate = this.pipe.transform(element.date, 'shortDate');
          if (element === undefined) {
            console.error('Undefined element: ', element, i);
          }
          // let nota = 0;
/*             element.weight_notes.forEach(item => {
            setTimeout(() => {
              res.forEach(sett => {
                sett.weight_notes.forEach(note => {
                  if ((item.total_net_qq + note.total_net_qq) === 1.76) {
                    console.log(item.total_net_qq + ' y ' + note.total_net_qq + ' de la liquidacion: ' + element.cod_set);
                  }

                });
              });
            }, 1000);
          }); */

          let cpb = 0;
          if (element.cost_per_beneficiary) {
            cpb = element.cost_per_beneficiary;
          }
            const rows = {
              Código: element.cod_set,
              Fecha: newDate,
              Productor: element.coffeefarmer,
              Quintales: element.total_qq,
              Precio: element.coffee_price,
              Costo_por_Beneficiado: cpb,
              Total: element.total,
              Total_Deducciones: element.total_deductions,
              Total_Neto:  element.total_net,
              Cosecha: element.harvest,
              Observaciones: element.observations,
              Estado: element.status,
            };
            if (this.identity._id === '5ff5e3213545ae31b8d199b0') {

              rows['EstadoCafe'] = status;
            }

            const convertToExcel = Object.assign([], this.convertToExcel, { [i]: rows });
            this.convertToExcel = convertToExcel;

        });
      this.excelService.exportAsExcelFile(this.convertToExcel, 'Liquidaciones');
      }
    }, err => this.alertMessage = 'No se pudieron obtener los registros');
  }
}
