import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material';

import { SeatService } from '../../../services/seat.service';
import { PrintService } from '../../../services/print.service';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../../services/excel.service';
import * as _ from 'lodash';
import { UserService } from '../../../services/user.service';
import { getCurrency } from '../../../helpers/number';

@Component({
  selector: 'app-balance-sheet',
  templateUrl: './balance-sheet.component.html',
  styleUrls: ['./balance-sheet.component.css'],
  providers: [SeatService, PrintService, ExcelService, DatePipe]
})
export class BalanceSheetComponent implements OnInit {

  public title = 'Balance General';
  public alertMessage = '';
  public form: FormGroup;
  public accountingProfit: any;
  public totalP_C: any;
  public balanceGroup = [];
  public step = -1;
  public currentPeriod = 0;
  public balance = [];
  public periods: number[] = [];
  public accumulatedUtility = 0;
  public convertToExcel = [];
  public currency = getCurrency();
  public pipe = new DatePipe('es-HN');

  @ViewChild( MatExpansionPanel ) extensionPanel: MatExpansionPanel;

  constructor(
    private _seatService: SeatService,
    private _userService: UserService,
    private _printService: PrintService,
    private fb: FormBuilder,
    private excelService: ExcelService,
  ) {
    this.currentPeriod = this._userService.getIdentity().company.settings.period;
    this.currentPeriod = this.currentPeriod ? this.currentPeriod : 1;
    for (let i = 1; i <= this.currentPeriod; i ++) this.periods.push(i);
  }

  ngOnInit() {
    this.createForm();
    this.form.get('period').setValue(this.currentPeriod);
  }

  filterData() {
    const paramsFilter = this.setDataFilter();
    console.log('Params in balance sheet: ', paramsFilter);
    this._seatService.getbalance(paramsFilter).subscribe((res: any[]) => {
      console.log('res balance: ', res);
      this.balance = _.flatten(res.filter(item => item.name === 'Activo' || item.name === 'Pasivos' || item.name === 'Capital')
        .map(item => item.category));
      console.log('balance: ', this.balance);
      const revenge = _.orderBy(res, ['id'], ['asc', 'desc']);
      this.balanceGroup = revenge.filter(item => item.name === 'Activo' || item.name === 'Pasivos' || item.name === 'Capital');
      this.balanceGroup.forEach(item => {
        item.category = _.orderBy(item.category, (item_) => {
          return String(item_.code).substring(0, 2);
        }, 'asc');
      });
      const revengeIndexed = res.reduce((acc, curr) => ({
        ...acc,
        [curr.name.toLowerCase()]: curr,
      }), {});
      const activos = revengeIndexed.hasOwnProperty('activo') ? revengeIndexed['activo'].total : 0;
      const pasivos = revengeIndexed.hasOwnProperty('pasivos') ? revengeIndexed['pasivos'].total : 0;
      const capital = revengeIndexed.hasOwnProperty('capital') ? revengeIndexed['capital'].total : 0;

      const gastos = revengeIndexed.hasOwnProperty('gastos') ? revengeIndexed['gastos'].total : 0;
      const costos = revengeIndexed.hasOwnProperty('costos') ? revengeIndexed['costos'].total : 0;
      const ingresos = revengeIndexed.hasOwnProperty('ingresos') ? revengeIndexed['ingresos'].total : 0;
      const utility = revengeIndexed.hasOwnProperty('accumulatedutility') ? revengeIndexed['accumulatedutility'].total : 0;

      this.accountingProfit = !revengeIndexed.hasOwnProperty('Costos y Gastos') ?
        (ingresos - (gastos + costos)).toFixed(2) :
        (ingresos - revengeIndexed['costos y gastos'].total).toFixed(2);
      this.accumulatedUtility = Number(this.accountingProfit) + utility;
      console.log('Indexced', revengeIndexed);

      if (revengeIndexed.hasOwnProperty('capital')) {
        this.totalP_C = pasivos + capital;
        console.log(`${pasivos} + ${capital} = ${this.totalP_C}`);
      } else {
        console.error('No hay un capital aun');
      }
    this.step = -1;
    }, error => console.error('Error al obtener balance: ', error));
  }

  setDataFilter(): void {
    const params = this.form.getRawValue();
    console.log('form', params);
    Object.keys(params).forEach(key => {
      const current = params[key];
      if (current === '' || current === null || current === undefined) {
        delete params[key];
      } else {
        if (key === 'date_out') {
          const temp = new Date(current);
          temp.setHours(23, 59, 59);
          params[key] = temp;
        }
      }
    });
    return params;
  }

  printBalance(): void {
    console.log('print balance');
    const temp = new Date(this.form.get('date_out').value);
    temp.setHours(23, 59, 59);
    const params = {
      title: 'BALANCE GENERAL',
      date_out: temp
    };
    this._printService.getBalanceSheet(params).then((result: any) => {
      console.log('result: ', result);
      if (result !== null) {
        this.alertMessage = '';
        const blob = new Blob([result], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      } else {
        this.alertMessage = 'No se encontraron asientos a la fecha';
        this.totalP_C = 0;
        this.accountingProfit = 0;
      }
      })
      .catch(err => console.log('Error', err));
  }

  goUp(): void {
    window.document.getElementById('generalContent').scrollTo(0, 0);
  }

  changeStep(n: number) {
    this.step = this.step + n;
  }

  createForm(): void {
    this.form = this.fb.group({
      date_in:  [''],
      date_out: [''],
      period:   ['']
    });
  }

  exportAsXLSX(object): void {
      const array: any = [];
      object.forEach((element, i) => {
            const rows = {
              Código: +element.code,
              Cuenta: element.name,
              Subtotales: 0,
              Totales: element.total
            };

            array.push(rows);
            element.group.forEach((item, index) => {
                const row = {
                  Código: item.code,
                  Cuenta: item.name,
                  Subtotales: item.sub_total,
                  Totales: 0
                };
                array.push(row);
            });
      });
      console.log(array);
      this.convertToExcel = array;

      this.excelService.exportAsExcelFile(array, 'Balance');

  }

}
