import { TestingService } from './../../../services/testing.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatDialog, MatSort, MatPaginator } from '@angular/material';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { InventoryService } from '../../../services/inventory.service';
import { CertificationService } from '../../../services/certification.service';

import { Certification } from '../../../models/certification';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';

import { CertificationAddComponent } from '../certification-add/certification-add.component';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.css'],
  providers: [UserService, CertificationService, InventoryService]
})
export class CertificationsComponent implements OnInit {

  public titulo: string;
  public certifications: Certification[];
  public certification = [];
  public identity;
  public token;
  public url: string;
  public confirmado;
  public alertMessage;
  closeResult: string;

  displayedColumns: string[] = ['name', 'house', 'action'];
  highlightedRows = [];
  public dataSource = new TableVirtualScrollDataSource<Certification>();
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }

  constructor(
    private _certificationService: CertificationService,
    private _userService: UserService,
    private _testingService: TestingService,
    public dialog: MatDialog
  ) {
    this.titulo = 'Certificaciones';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.url = GLOBAL.url;
  }

  ngOnInit() {
    this.getCertifications();
    const limit = this.dataSource.data.length - 7;
    console.log('Limit: ', limit);
    this.viewport.scrolledIndexChange.subscribe(value => {
      this.viewport.setTotalContentSize(limit);
      console.log('ViewPort: ', value);
    });
  }

  changePage(paginator: MatPaginator): void {
    const page = paginator.pageIndex;
    console.log('page: ', page);
    this._testingService.weightNotesValues({
      harvest: '2020 / 2021',
      // company: '5de280c2ff21b207b052c7cc', // Cafescor
      // company: '5f80c3091052d10488531338', // COcrebistol
      company: '5da11d3959d1b64b6a4a80b9', // Aprosand
      limit: 100,
      skip : 100 * page
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(CertificationAddComponent, {
      width: '450px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getCertifications();
    });
  }

  openDialogD(certification) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar certificación',
        title: '',
        text: `¿Seguro que quieres eliminar a<br><strong>${certification.name}</strong>?`,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteCertification(certification._id);
      }
    });
  }

  getCertifications() {
    this._certificationService.getCertifications().subscribe(
      (res) => {
        this.dataSource.data = res as Certification[];
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  deleteCertification(id) {
    this._certificationService.deleteCertification(id).subscribe(
    response => {
      if (!response) {
        alert('Error en el servidor');
      } else {
        this.getCertifications();
      }
    },
    error => {
      const errorMessage = <any>error;
      if (errorMessage != null) {
        const body = JSON.parse(error._body);
        console.log(error);
      }
    }
    );
  }

}
