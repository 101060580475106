import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort } from '@angular/material';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import * as XLSX from 'xlsx';

import { UserService } from '../../../services/user.service';
import { CustomerService } from '../../../services/customer.service';
import { SaleService } from '../../../services/sale.service';

import { Customer } from '../../../models/customer';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { CustomerAddComponent } from '../customer-add/customer-add.component';
import { CustomerEditComponent } from '../customer-edit/customer-edit.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
  providers: [UserService, CustomerService, SaleService]
})
export class CustomersComponent implements OnInit {

  public titulo: string;
  public customers: Customer[];
  public customer = [];
  public identity;
  public alertMessage;
  public closeResult: string;
  public enableInputFile = false;
  public selectedFile: File;

  displayedColumns: string[] = ['cod', 'name', 'contact', 'address', 'country', 'email', 'telephone', 'rtn', 'action'];
  highlightedRows = [];
  public dataSource = new TableVirtualScrollDataSource<Customer>();
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }
  constructor(
    private _customerService: CustomerService,
    private _saleService: SaleService,
    private _userService: UserService,
    public dialog: MatDialog
  ) {
    this.titulo = 'Clientes';
    this.identity = this._userService.getCompany();
  }

  ngOnInit() {
    this.getCustomers();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.enableInputFile = filterValue === 'enableInputFile' ? true : false;
  }

  openDialog() {
    const dialogRef = this.dialog.open(CustomerAddComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getCustomers();
    });
  }

  openDialogEdit(customer: Customer): void {
    const dialogRef = this.dialog.open(CustomerEditComponent, {
      width: '600px',
      data: customer
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getCustomers();
    });
  }

  openDialogD(customer: Customer) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar cliente',
        title: '',
        text: `¿Seguro que quieres eliminar a <br><strong>#${customer.name}</strong>?`,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteCustomer(customer._id);
      }
    });
  }

  getCustomers() {
    this._customerService.getCustomers().subscribe(
      (res) => {
        this.dataSource.data = res as Customer[];
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  delete(id) {
    this.alertMessage = 'Registo eliminado';
    this._customerService.deleteCustomer(id).subscribe(
      response => {
        if (!response) {
          alert('Error en el servidor');
        } else {
          this.getCustomers();
        }
      },
      error => console.error('Error al eliminar cliente: ', error)
    );
  }

  deleteCustomer(id) {
    this.alertMessage = '';
    this._saleService.getSalePerCustomer(id).subscribe(
      res => {
        console.log(res.length);
        if (res.length === 0) {
           this.delete(id);
        } else {
          this.alertMessage = 'el cliente no se puede borrar';
        }
      }
    );
  }

    // -------------------------- ! Importar un documento de excel con columnas predefinidas, -----------------------
  saveFile(file): void {
    this.selectedFile = file;
    console.log('File: ', file);
  }

  convert(): void {
    if (this.selectedFile) {
        console.log('indexedProducers');
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(this.selectedFile);
        fileReader.onload = (event: Event) => {
        const binaryData = fileReader.result;
        const workbook = XLSX.read(binaryData, { type: 'binary', cellDates: false });
        const data: any[] = XLSX.utils.sheet_to_json(workbook.Sheets.A);
        console.log('data : ', XLSX.utils.sheet_to_json(workbook.Sheets.A));
        this.dataSource.data = data;
        data.forEach(current => {
          // current.harvest = '2019 / 2020';
          setTimeout(() => {
            this._customerService.addCustomer(current).subscribe(response => {
              console.log('Agregado !');
            }, err => console.error('Error al agregar cliente: ', err));
          }, 2000);
        });
      };
    }
  }
}
