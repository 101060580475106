import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PhysicalAnalysis } from '../models/physical';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class PhysicalService {

  public url = GLOBAL.url + '/physicals';
  constructor(
    private http: HttpClient
  ) { }

  addPhysical = (physical: PhysicalAnalysis): Observable<any> => this.http.post(`${this.url}/new`, physical);
  updatePhysical = (physical: PhysicalAnalysis): Observable<any> => this.http.put(`${this.url}/${physical._id}`, physical);
  getPhysical = (physicalId: string): Observable<any> => this.http.get(`${this.url}/${physicalId}`);
  getPhysicals = (): Observable<any> => this.http.get(`${this.url}/list`);
}
