import { StepperComponent } from './../stepper/stepper.component';
import { NgModule } from '@angular/core';

import { DryRoutingModule } from './dry-routing.module';
import { DriedAddComponent } from './dried-add/dried-add.component';
import { DriedsComponent } from './drieds/drieds.component';
import { DriedDetailComponent } from './dried-detail/dried-detail.component';
import { MachinesComponent } from './machines/machines.component';
import { MachineAddComponent } from './machine-add/machine-add.component';
import { MachineEditComponent } from './machine-edit/machine-edit.component';
import { TransferAddComponent } from './transfer-add/transfer-add.component';
import { TransferEditComponent } from './transfer-edit/transfer-edit.component';
import { TransfersComponent } from './transfers/transfers.component';
import { EditDateDriedComponent } from './edit-date-dried/edit-date-dried.component';
import { InventoryQqComponent } from './inventory-qq/inventory-qq.component';
import { DriedUpdateComponent } from './dried-update/dried-update.component';
import { DriedsFilterComponent } from './drieds/drieds-filter/drieds-filter.component';
import { InventoryComponent } from './inventory/inventory.component';
import { MatSelectSearchModule } from '../mat-select-search/mat-select-search.module';
import { ConfirmTransferComponent } from './confirm-transfer/confirm-transfer.component';
import { MachineService } from '../../services/machine.service';
import { SharedModule } from '../../shared/shared.module';
import { SamplesModule } from '../QA/samples/samples.module';
import { BlendsModule } from './blends/blends.module';

@NgModule({
  declarations: [
    DriedAddComponent,
    DriedsComponent,
    DriedDetailComponent,
    MachinesComponent,
    MachineAddComponent,
    MachineEditComponent,
    TransferAddComponent,
    TransferEditComponent,
    TransfersComponent,
    EditDateDriedComponent,
    InventoryQqComponent,
    DriedUpdateComponent,
    DriedsFilterComponent,
    InventoryComponent,
    ConfirmTransferComponent,
    StepperComponent,
  ],
  imports: [
    SharedModule,
    SamplesModule,
    DryRoutingModule,
    BlendsModule,
    MatSelectSearchModule,
  ],
  entryComponents: [
    DriedsFilterComponent,
    ConfirmTransferComponent,
  ],
  providers: [MachineService]
})
export class DryModule { }
