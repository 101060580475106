import { MatSelectSearchModule } from '../mat-select-search/mat-select-search.module';
import { WeightNoteRoutingModule } from './weight-note-routing.module';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxMaskModule } from 'ngx-mask';

import { CertificationAddComponent } from './certification-add/certification-add.component';
import { CertificationEditComponent } from './certification-edit/certification-edit.component';
import { CertificationsComponent } from './certifications/certifications.component';
import { CheckboxGroupComponent } from '../checkbox-group/checkbox-group.component';
import { FilterPanelComponent } from './weight-notes/filter-panel/filter-panel.component';
import { InventoryAddComponent } from './inventory-add/inventory-add.component';
import { InventoryEditComponent } from './inventory-edit/inventory-edit.component';
import { LiquidationComponent } from './liquidation/liquidation.component';
import { OriginAddComponent } from './origin-add/origin-add.component';
import { OriginEditComponent } from './origin-edit/origin-edit.component';
import { OriginsComponent } from './origins/origins.component';
import { ProductorAddComponent } from './productor-add/productor-add.component';
import { ProductorDetalleComponent } from './detalle-productor/detalle-productor.component';
import { ProductorEditComponent } from './productor-edit/productor-edit.component';
import { ProductoresComponent } from './productores/productores.component';
import { WarehouseAddComponent } from './warehouse-add/warehouse-add.component';
import { WarehouseEditComponent } from './warehouse-edit/warehouse-edit.component';
import { WarehousesComponent } from './warehouses/warehouses.component';
import { WeightNotesComponent } from './weight-notes/weight-notes.component';
import { WeightNotesEditComponent } from './weight-notes-edit/weight-notes-edit.component';
import { WeightNotePrintComponent } from './weight-note-print/weight-note-print.component';
import { WeightNoteAddComponent } from './weight-note-add/weight-note-add.component';

import { IhcafeVouchesModule } from './ihcafe-vouches/ihcafe-vouches.module';
import { SaleVouchersModule } from './sale-vouchers/sale-vouchers.module';
import { SharedModule } from '../../shared/shared.module';
import { CollectionCenterAddComponent } from './collection-center-add/collection-center-add.component';
import { CollectionCenterEditComponent } from './collection-center-edit/collection-center-edit.component';
import { CollectionCentersComponent } from './collection-centers/collection-centers.component';


@NgModule({
  declarations: [
    OriginsComponent,
    OriginAddComponent,
    WarehousesComponent,
    OriginEditComponent,
    ProductoresComponent,
    LiquidationComponent,
    WeightNotesComponent,
    WarehouseAddComponent,
    ProductorAddComponent,
    InventoryAddComponent,
    WarehouseEditComponent,
    ProductorEditComponent,
    CertificationsComponent,
    CertificationAddComponent,
    CertificationEditComponent,
    InventoryEditComponent,
    WeightNotesEditComponent,
    ProductorDetalleComponent,
    CheckboxGroupComponent,
    FilterPanelComponent,
    WeightNoteAddComponent,
    WeightNotePrintComponent,
    CollectionCenterAddComponent,
    CollectionCenterEditComponent,
    CollectionCentersComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    WeightNoteRoutingModule,
    IhcafeVouchesModule,
    SaleVouchersModule,
    MatSelectSearchModule,
    TableVirtualScrollModule,
    InfiniteScrollModule,
    NgxMaskModule.forRoot(),
  ],
  entryComponents: [
  ]
})
export class WeightNoteModule { }
