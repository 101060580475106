import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Exporter } from '../../../models/exporter';
import { ExporterService } from '../../../services/exporter.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-exporter-add',
  templateUrl: './exporter-add.component.html',
  styleUrls: ['./exporter-add.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ExporterAddComponent implements OnInit {
  public title = 'Agregar Exportador';
  formGroup: FormGroup;
  constructor(
    private exporterService: ExporterService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ExporterAddComponent>
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm(): void {
    const emailRegex = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$';
    this.formGroup = this.fb.group({
      name:       ['', Validators.required],
      email:      ['', Validators.pattern(emailRegex)],
      address:    ['', Validators.required],
      rtn:    [''],
      country:    ['', Validators.required],
      telephone:  ['', Validators.required],
    });
  }

  saveData(): void {
    const newExporter: Exporter = this.formGroup.value;
    console.log('Exporter: ', this.formGroup.value);
    this.exporterService.addExporter(newExporter).subscribe( response => {
      console.log('Respuesta al agregar:', response);
      this.dialogRef.close();
    }, error => {
      console.error('Error al agregar exportador');
      const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.error(error);
        }
    });
  }
}
