import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { UserService } from '../../../services/user.service';
import { CustomerService } from '../../../services/customer.service';
import { Customer } from '../../../models/customer';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.css'],
  providers: [UserService, CustomerService]
})
export class CustomerEditComponent implements OnInit {

  public title = 'Editar Cliente';
  public customer: Customer;
  public mask = ['(', '5', '0', '4', ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private _customerService: CustomerService,
    public dialogRef: MatDialogRef<CustomerEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.customer = this.data;
  }

  onSubmit() {
    this._customerService.editCustomer(this.customer._id, this.customer).subscribe(response => {
        if (!response) {
        } else {
          this.dialogRef.close();
        }
      }, error => console.error('Error al editar cliente: ', error));
    }
}
