import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../../services/excel.service';
import * as _ from 'lodash';
import { ChekkuService } from '../../../services/chekku.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PrintService } from '../../../services/print.service';
import { PolygonService } from '../../../services/polygon.service';
import { loadGapiInsideDOM } from 'gapi-script';
import * as tokml from 'tokml';
import * as togeojson from '@mapbox/togeojson';

declare var ee: any;
declare var google: any;
@Component({
  selector: 'app-check-ins',
  templateUrl: './check-ins.component.html',
  styleUrls: ['./check-ins.component.css']
})
export class CheckInsComponent implements OnInit {
  @ViewChild('googleMap') gmapElement: any;
  //  @ViewChild('googleMap', { static: false }) gmapElement: ElementRef;
  map: google.maps.Map;
  dataLayer = new google.maps.Data();
  apiLoaded: Observable<boolean>;
  scriptLoadingPromise: Promise<void>;
  loader: any;
  marker: any;
  currentPage = 1;
  isLoading = false;

  years: number[] = [2020, 2021, 2022, 2023];
  currentYear: number = 2023;
/*   private clientId = '197806794607-kveb8bbe50fubadkf7kee7vnc7n6jceb.apps.googleusercontent.com'; */
  private clientId = '197806794607-5h3o0v8gjucf43a38iomnd68456r8vi7.apps.googleusercontent.com';
  private apiKey = 'AIzaSyAQ2_6f6qQNeBOPlqFmLUWfpYfJ9XUtRoA'; // Reemplaza con tu API key
  private scope = [
    'https://www.googleapis.com/auth/earthengine.readonly'
  ].join(' ');

  center = {lat: 24, lng: 12};
  zoom = 15;
  loc;
  maps: google.maps.Map;
  private geocoder: google.maps.Geocoder;
  lat = 15.507190776629812;
  lng = -88.02471461460642;
  placeName;
  selectedLoc;
  checkinForm;
  checkinImage = '';
  titleForm = '';
  formId = '';
  infowindow: any;
  public allCheckins = [];
  public currentAllCheckins = [];
  public forms = [];
  public values = [];
  public excelData = [];
  public excelTitle;
  public page = 1;
  public form: FormGroup;
  excelHeaders: string[] = [];
  templateToExcel: string[][] = [this.excelHeaders, []];
  // selectedFile: File;
  selectedFiles: FileList;
  polygon: google.maps.Polygon;
  polygonToDownload: any;
  satelliteImageUrl: string;


  constructor(
    http: HttpClient,
    private _chekkuService: ChekkuService,
    private fb: FormBuilder,
    private excelService: ExcelService,
    private _printService: PrintService,
    private _polygonService: PolygonService,
  ) {}

  ngOnInit(): void {
    // this.loadGoogleIdentityServices();
    this.createForm();
    this.getCheckins(this.currentPage);
    this.listeners();
  }

  listeners(): void {
/*     this.form.get('filter').valueChanges.subscribe((form: any) => {
      let values = [];
      this.allCheckins = this.currentAllCheckins;
      this.excelTitle = form.base_form.name;
      const headers = form.content_form.map(({ props }) => props.title);
      this.excelHeaders = headers;
      console.log(form, this.excelHeaders, headers);
      this.allCheckins = this.allCheckins.filter(fil => fil.form[0].base_form._id === form.base_form._id);
      console.log(this.allCheckins);
      this.allCheckins.map(item => {
         const res: string[] = item.form[0].content_form.map(({ value }) => {
            let val = '';
            if (value !== undefined) {
               val = value;
            }
            return val.toString();
         });
         values.push(res);
      });
      this.excelData = values.map(value => 
      value.reduce((acc, data, index) => 
         ({...acc, [this.excelHeaders[index]]: data}), {}));
      console.log(this.excelData);
    }); */

    this.form.get('filter').valueChanges.subscribe((form: any) => {
      let values = [];
      this.allCheckins = this.currentAllCheckins;

      if (form.base_form) {
        this.excelTitle = form.base_form.name;
      } else {
        this.excelTitle = "Título por defecto"; // Manejo del caso cuando form.base_form es undefined
      }

      const headers = form.content_form.map(({ props }) => props.title);
      this.excelHeaders = headers;
      console.log(form, this.excelHeaders, headers);

      this.allCheckins = this.allCheckins.filter(fil => {
        return (
          fil.form &&
          fil.form[0] &&
          fil.form[0].base_form &&
          form.base_form &&
          fil.form[0].base_form._id === form.base_form._id
        );
      });

      console.log(this.allCheckins);

      this.allCheckins.map(item => {
        const res: string[] = item.form[0].content_form.map(({ value }) => {
          let val = '';
          if (value !== undefined) {
            val = value;
          }
          return val.toString();
        });
        values.push(res);
      });

      this.excelData = values.map(value =>
        value.reduce((acc, data, index) =>
          ({ ...acc, [this.excelHeaders[index]]: data }), {}));
      console.log(this.excelData);
    });

  }

  createForm(): void {
    this.form = this.fb.group({
      filter: [''],
    });
  }

  getCheckins(page: number) {
    this.page = page;
    this.isLoading = true;
    this._chekkuService.getCheckins(page).subscribe(res => {

      if (res.length > 0) {
        if (page === 1) {
          this.allCheckins = res;
          this.currentAllCheckins = res;
        } else {
          this.allCheckins = [...this.allCheckins, ...res];
          this.currentAllCheckins = [...this.currentAllCheckins, ...res];
        }
        this.isLoading = false;
        this.lat = res[0].loc.coordinates[1];
        this.lng = res[0].loc.coordinates[0];
        if (res[0].customer.geocode_data.display_name) {
          this.placeName = res[0].customer.geocode_data.display_name;
        } else {
          this.placeName = '';
        }
        this.titleForm = res[0].form[0].base_form.name;
        this.checkinForm = res[0].form[0].content_form;
        if (res[0].image) {
          this.checkinImage = res[0].image;
        } else {
          this.checkinImage = '';
        }
        this.onSelect(res[0]);

        const result = this.allCheckins
          .map(({ form }) => form[0])
          .filter(form => form && Object.keys(form).length > 0);
        
        let hash = {};
        const forms = result.filter(current => {
          const exists = !hash[current.base_form._id];
          hash[current.base_form._id] = true;
          return exists;
        });
        this.forms = forms;
        this.initMap();
      }
    })
  }

  loadMore() {
    this.currentPage++;
    this.getCheckins(this.currentPage);
  }

  exportTemplateAsExcel() {
    this.excelService.exportAsExcelFile(this.excelData, this.excelTitle);
  }

  init() {
    const mapProp = {
      center: new google.maps.LatLng(this.lat , this.lng),
      zoom: 14,
      mapTypeId: google.maps.MapTypeId.HYBRID
    };
    
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.marker = new google.maps.Marker({ position: mapProp.center });
    this.marker.setMap(this.map);
  
  }

  initMap() {
    const mapProp = {
      center: new google.maps.LatLng(this.lat , this.lng),
      zoom: 14,
      mapTypeId: google.maps.MapTypeId.HYBRID
    };
    
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.marker = new google.maps.Marker({ position: mapProp.center });
    this.marker.setMap(this.map);
    
    const infowindow = new google.maps.InfoWindow({
      content: this.placeName
    });
    infowindow.open(this.map, this.marker);
  }

  onSelect(loc: any): void {
    console.log(loc);
    if (loc.customer.name) {
      this.placeName = loc.customer.name;
    } else {
      this.placeName = '';
    }
    this.loc = loc;
    this.selectedLoc = loc;
    this._polygonService.getPolygon(this.loc._id).subscribe((res) => {
      if (res.error === 'not') {
        this.titleForm = this.loc.form[0].base_form.name;
        this.checkinForm = this.loc.form[0].content_form;
        if (this.loc.image) {
          this.checkinImage = this.loc.image;
        } else {
          this.checkinImage = '';
        }
        this.lat = loc.loc.coordinates[1];
        this.lng = loc.loc.coordinates[0];
        const mapProp = {
          center: new google.maps.LatLng(loc.loc.coordinates[1], loc.loc.coordinates[0]),
          zoom: 14,
          mapTypeId: google.maps.MapTypeId.HYBRID
        };
        
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
        this.marker = new google.maps.Marker({ position: mapProp.center });
        this.marker.setMap(this.map);
        const infowindow = new google.maps.InfoWindow({
          content: this.placeName
        });
        infowindow.open(this.map, this.marker);
      } else {
        console.log(res);
        this.initMap();
        this.showPolygon(res.polygon);
      }
    });

  }

  reload() {
    this.getCheckins(this.currentPage);
  }

  resetMap() {
    this.marker = new google.maps.Marker({ position: null });
    this.marker.setMap(null);
    const infowindow = new google.maps.InfoWindow(null);
    infowindow.open(this.map, null);
  }

  print(checkin) {
    console.log(checkin);
    this._printService.getCheckin(checkin._id, this.page).then((res: any) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');

      console.log(blob, res, blobUrl);
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    })
    .catch(err => console.log('Error', err));
  }

  triggerFileInput() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

/*   onFilesSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.selectedFiles = input.files;
      this.readGeojsonFiles();
    }
  } */

  onFilesSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.selectedFiles = input.files;
      this.processSelectedFiles();
    }
  }
/* 
  readGeojsonFiles() {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const geojson = JSON.parse(fileReader.result as string);
        this.addGeojsonToMap(geojson);
      };
      fileReader.readAsText(file);
    }
  } */

  processSelectedFiles() {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const content = fileReader.result as string;
        if (file.type === 'application/vnd.google-earth.kml+xml' || file.name.endsWith('.kml') || file.name.endsWith('.kmz')) {
          this.convertKmlToGeojson(content);
        } else if (file.type === 'application/json' || file.name.endsWith('.geojson')) {
          const geojson = JSON.parse(content);
          this.addGeojsonToMap(geojson);
        }
      };
      fileReader.readAsText(file);
    }
  }

/*   convertKmlToGeojson(kmlContent: string) {
    try {
      const geojson = tokml(kmlContent);
      this.addGeojsonToMap(geojson);
    } catch (error) {
      console.error('Error converting KML to GeoJSON:', error);
    }
  } */

convertKmlToGeojson(kmlContent: string) {
  const parser = new DOMParser();
  const kml = parser.parseFromString(kmlContent, 'application/xml');
  try {
    let geojson = togeojson.kml(kml);
/*     if (geojson.crs || geojson.name) {
      geojson = this.modifyGeojsonStructure(geojson, "untitle.kml", "untitle", "untitle");
    } */
    geojson = this.modifyGeojsonStructure(geojson, "untitle.kml", "untitle", "untitle");
    this.addGeojsonToMap(geojson);
  } catch (error) {
    console.error('Error converting KML to GeoJSON:', error);
  }
}

modifyGeojsonStructure(geojson: any, fileName: string, name: string, description: string) {
  return {
    type: 'FeatureCollection',
    name: geojson.name || fileName,
    crs: geojson.crs || {
      type: 'name',
      properties: {
        name: 'urn:ogc:def:crs:OGC:1.3:CRS84'
      }
    },
    features: geojson.features.map(feature => ({
      ...feature,
      _id: feature.id || feature._id,
      properties: {
        ...feature.properties,
        Name: feature.properties.Name || 'Default Name',
        description: feature.properties.description || 'Default Description'
      }
    }))
  };
}

  readGeojsonFiles() {
    this.processSelectedFiles();
  }

  downloadGeoJson() {
    const geojson = this.polygonToDownload;
    const blob = new Blob([JSON.stringify(geojson)], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'polygon.geojson';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  showPolygon(geojson) {
    try {
      this.dataLayer.setMap(null);
      this.dataLayer = new google.maps.Data();

      this.dataLayer.addGeoJson(geojson);
      this.dataLayer.setStyle({
        fillColor: 'green',
        strokeColor: 'green',
        strokeWeight: 2
      });
      this.dataLayer.setMap(this.map);
      this.polygonToDownload = {
        type: geojson.type,
        name: geojson.name,
        crs: geojson.crs,
        features: geojson.features,
      }
      console.log(this.polygonToDownload);

      const bounds = new google.maps.LatLngBounds();
      if (!bounds) {
        this.initMap();
      }
      this.dataLayer.forEach(feature => {
        this.processPoints(feature.getGeometry(), bounds.extend, bounds);
      });
      this.map.fitBounds(bounds);
    
      if (this.marker) {
        this.marker.setMap(null); // Eliminar el marcador anterior, si existe
      }
    } catch (error) {
      console.error('Error adding GeoJSON to map:', error);
    }
  }

  newPolygon(geojson: any) {
    geojson.checkin = this.loc._id;
    this._polygonService.addPolygon(geojson).subscribe(res => {
      console.log(res);
      this.showPolygon(res);
    });
  }
      
  addGeojsonToMap(geojson: any) {
    if (!this.map) {
      console.error('Map is not initiaized');
      return;
    }
    if (geojson) {
              console.log('ya esta geo', geojson);
      this._polygonService.getPolygon(this.loc._id).subscribe((res) => {
        console.log('ya esta', res);
        if (res.error === 'not') {
          this.newPolygon(geojson);
        } else {
          this._polygonService.remove(this.loc._id).subscribe((res) => {
            console.log('que hay aqui', res);
            if (res) {
              this.newPolygon(geojson);
            }
          });
        }
      }, (err: any) => {
        this.newPolygon(geojson);
      });
    }
  }

  // google.maps.Data.Geometry 

processPoints(geometry: any, callback: Function, thisArg: any): void {
  if (geometry instanceof google.maps.LatLng) {
    callback.call(thisArg, geometry);
  } else if (geometry instanceof google.maps.Data.Point) {
    callback.call(thisArg, geometry.get());
  } else if (
    geometry instanceof google.maps.Data.MultiPoint || 
    geometry instanceof google.maps.Data.LineString || 
    geometry instanceof google.maps.Data.LinearRing || 
    geometry instanceof google.maps.Data.MultiLineString || 
    geometry instanceof google.maps.Data.Polygon || 
    geometry instanceof google.maps.Data.MultiPolygon
  ) {
    const geometryArray: any[] = geometry.getArray();
    if (Array.isArray(geometryArray)) {
      geometryArray.forEach((g: any) => this.processPoints(g, callback, thisArg));
    } else {
      console.error("Expected geometry.getArray() to return an array");
    }
  } else {
    console.error("Unknown geometry type", geometry);
  }
}

  //-----------------------------------//
/* 
  loadGoogleIdentityServices() {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => this.initializeGISClient();
    document.body.appendChild(script);
  }

  initializeGISClient() {
    console.log('si llega');
    google.accounts.id.initialize({
      client_id: this.clientId,
      callback: this.handleCredentialResponse.bind(this)
    });
  }

  handleCredentialResponse(response: any) {
    console.log(response);
    const token = response.credential;
    ee.data.authenticateViaOauth(token, () => {
      ee.initialize(null, null, () => {
        console.log('Earth Engine initialized.');
        this.loadSatelliteImage();
      }, (err: any) => {
        console.error('EE initialization failed: ', err);
      });
    }, (err: any) => {
      console.error('Authentication failed: ', err);
    });
  }

  loadSatelliteImage() {
    const lat = this.lat;
    const lng = this.lng;
    const year = this.currentYear;

    const point = ee.Geometry.Point(lng, lat);
    const startDate = `${year}-01-01`;
    const endDate = `${year}-12-31`;

    console.log(point);
    const collection = ee.ImageCollection("LANDSAT/LC08/C01/T1")
      .filterBounds(point)
      .filterDate(startDate, endDate);
    console.log(collection);

    const image = ee.Image(collection.sort("system:time_start", false).first());

    image.getThumbURL({ dimensions: 500 }, (url: string) => {
      console.log(url);
      this.satelliteImageUrl = url;
      this.updateMapImage(url);
    });
  }
  
  updateMapImage(imageUrl: string) {
    if (!this.map) {
      console.error('Map is not initialized');
      return;
    }

    this.dataLayer.setMap(null);

    const overlayBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(this.lat - 0.1, this.lng - 0.1),
      new google.maps.LatLng(this.lat + 0.1, this.lng + 0.1)
    );

    const overlay = new google.maps.GroundOverlay(imageUrl, overlayBounds);
    overlay.setMap(this.map);

    this.map.setOptions({
      zoom: this.zoom,
      center: { lat: this.lat, lng: this.lng },
      mapTypeId: 'satellite'
    });

    if (this.dataLayer) {
      this.dataLayer.setMap(this.map);
    }
  }

  onYearChange(event: any): void {
    console.log(event);
    this.currentYear = +event.value;
    this.loadSatelliteImage();
  }
 */
}
