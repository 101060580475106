import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Payment } from '../models/payment';
import { Http, Response, Headers } from '@angular/http';

@Injectable()
export class PaymentService {
  public url: string;

  constructor(
    private http: HttpClient
    ) {
    this.url = GLOBAL.url;
  }
  getPayments(): Observable<any> {
    return this.http.get(`${this.url}/payments/list`);
  }

  getEnabledPayments(): Observable<any> {
    return this.http.get(`${this.url}/payments/list-enabled`);
  }

  getPaymentsWithFilters(filters: any): Observable<any> {
    return this.http.get(`${this.url}/payments/list-filtered`, {params: filters});
  }

  getPaymentsPerHarvest(harvest: string): Observable<any> {
    if (harvest !== undefined) {
      const data = harvest.replace('/', '-');
      console.log('harvest service: ', data);
      return this.http.get(`${this.url}/payments/list-per-harvest/${data}`);
    }
  }

  getSum(): Observable<any> {
    return this.http.get(`${this.url}/payments/sum`);
  }

  addPayment(payment: Payment): Observable<any> {
    return this.http.post(`${this.url}/payments/new`, payment);
  }

  getPayment(id): Observable<any> {
    return this.http.get(`${this.url}/payments/${id}`);
  }

  editPayment(id, payment): Observable<any> {
    return this.http.put(`${this.url}/payments/${id}`, payment);
  }

  deletePayment(id): Observable<any> {
    return this.http.delete(`${this.url}/payments/${id}`);
  }

  getPaymentPerAdvance(advancePayment): Observable<any> {
    return this.http.get(
      `${this.url}/payments/advance_payments/${advancePayment}`
    );
  }

  getPaymentPerSettlement(settlement): Observable<any> {
    return this.http.get(`${this.url}/payments/settlements/${settlement}`);
  }

  getAdvancePaymentsCodes(harvest: string): Observable<any> {
    const query = harvest.replace('/', '-');
    console.log('harvest: ', query);
    return this.http.get(`${this.url}/payments/only_codes/${query}`);
  }

  checkExistAdvancePayment(id: string): Observable<any> {
    return this.http.get(`${this.url}/payments/verify-exist/${id}`);
  }

  getPaymentsByAP(id: string): Observable<any> {
    return this.http.get(`${this.url}/payments/list-per-ap/${id}`);
  }

  getSumPaymentsCurrentHarvest(): Observable<any> {
    return this.http.get(`${this.url}/payments/sum-current-harvest`);
  }

  getAllPayments(): Observable<any> {
    return this.http.get(`${this.url}/payments/list-all`);
  }
}
