import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';

import { LotService } from '../../../../services/lot.service';

import { Dried } from '../../../../models/dried';
import { Lot } from '../../../../models/lot';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-lot-add',
  templateUrl: './lot-add.component.html',
  styleUrls: ['./lot-add.component.css']
})

export class LotAddComponent implements OnInit {
  public form: FormGroup;
  public dryInventory: Dried[] = [];
  public types = [
    { value: 'Saco Yute', viewValue: 'Saco Yute' },
    { value: 'Jumbo Liner', viewValue: 'Jumbo Liner' },
    { value: 'BigBag', viewValue: 'BigBag' }
  ];

  constructor(
    private fb: FormBuilder,
    private _lotService: LotService,
    public dialogRef: MatDialogRef<LotAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.createForm();
  }

  save() {
    const lot: Lot = this.form.getRawValue();
    this._lotService.addLot(lot).subscribe((res: Lot) => {
      console.log('Lote agregado: ', res);
      this.dialogRef.close(res);
    }, (err: HttpErrorResponse) => console.error('Error al editar lote: ', err));
  }

  private createForm(): void {
    this.form = this.fb.group({
      lot:        ['', Validators.required],
      stamp:      [''],
      bags:       ['', Validators.required],
      total_qq:   ['', Validators.required],
      departure:  [''],
      quality:    [''],
      mark:       ['']
    });
  }

}
