import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { FD3 } from '../models/FD3';

@Injectable()
export class FTOService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getFD3s(): Observable<any> {
    return this.http.get(`${this.url}/ft/list`);
  }
  addFD3(fd3: FD3): Observable<any> {
    return this.http.post(`${this.url}/ft/new`, fd3);
  }
  getFD3(id): Observable<any> {
    return this.http.get(`${this.url}/ft/${id}`);
  }
  getPFD3(id): Observable<any> {
    return this.http.get(`${this.url}/ft/ft/${id}`);
  }
  editFD3(id: string, fd3: FD3): Observable<any> {
    return this.http.put(`${this.url}/ft/${id}`, fd3);
  }
  deleteFD3(id): Observable<any> {
    return this.http.delete(`${this.url}/ft/${id}`);
  }

}
