import { Descriptor } from './descriptor';
import { Laboratory } from './laboratory';
import { Sample } from './sample';
import { Taster } from './taster';
import { User } from './user';
import { range, cloneDeep } from 'lodash';
import { Location } from './location';

export interface AnalysisValue {
    name: string;
    key: string;
    cups?: boolean[];
    puntuation: number;
    descriptors?: string[];
}

export class SensoryAnalysisResult {
    sample: any | Sample;
    roast: string;
    values: AnalysisValue[];
    puntuation: number;
    intensity: number;
}

export interface SensoryAnalysis {
    company: string;
    code: number;
    session: string;
    tasters: Taster[];
    date: string;
    category: string;
    lab: Laboratory;
    location: Location;
    results: SensoryAnalysisResult[];
    observations: string;
    user: User;
    _id: string;
}

export const defaultSensoryValues: AnalysisValue[] = [
    { name: 'Aroma',        key: 'smell',       puntuation: 6, descriptors: []},
    { name: 'Sabor',        key: 'flavor',      puntuation: 6, descriptors: []},
    { name: 'Acidez',       key: 'acidity',     puntuation: 6, descriptors: []},
    { name: 'Uniformidad',  key: 'uniformity',  puntuation: 10, cups: range(5).map(i => (false))},
    { name: 'Dulzura',      key: 'sweetness',   puntuation: 10, cups: range(5).map(i => (false))},
    { name: 'Taza limpia',  key: 'cleanCup',    puntuation: 10, cups: range(5).map(i => (false))},
    { name: 'Postgusto',    key: 'aftertaste',  puntuation: 6 },
    { name: 'Cuerpo',       key: 'body',        puntuation: 6 },
    { name: 'Balance',      key: 'balance',     puntuation: 6 },
    { name: 'Catador',      key: 'taster',      puntuation: 6 },
    { name: 'Defectos',     key: 'defects',     puntuation: 0 },
];
