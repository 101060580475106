import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnInit {
  @Input() params: any;
  constructor() { }

  ngOnInit() {
    console.log('Stepper params: ', this.params);
  }

}
