import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Laboratory } from '../models/laboratory';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class LaboratoryService {

  public url = GLOBAL.url + '/labs';
  constructor(
    private http: HttpClient
  ) { }

  addLaboratory(laboratory: Laboratory): Observable<any> {
    return this.http.post(`${this.url}/new`, laboratory);
  }

  getLaboratories(): Observable<any> {
     return this.http.get(`${this.url}/list`);
   }

   getLaboratory(laboratoryId: string): Observable<any> {
     return this.http.get(`${this.url}/${laboratoryId}`);
   }

   updateLaboratory(laboratory: Laboratory): Observable<any> {
     return this.http.put(`${this.url}/${laboratory._id}`, laboratory);
   }
}
