import { NgModule } from '@angular/core';
import { SaleVoucherAddComponent } from './sale-voucher-add/sale-voucher-add.component';
import { SaleVoucherEditComponent } from './sale-voucher-edit/sale-voucher-edit.component';
import { SaleVoucherListComponent } from './sale-voucher-list/sale-voucher-list.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [
    SaleVoucherAddComponent,
    SaleVoucherEditComponent,
    SaleVoucherListComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    SaleVoucherAddComponent,
  ]
})
export class SaleVouchersModule { }
