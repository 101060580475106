import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrokersComponent } from './brokers/brokers.component';
import { ContractAddComponent } from './contracts/contract-add/contract-add.component';
import { ContractEditComponent } from './contracts/contract-edit/contract-edit.component';
import { ContractListComponent } from './contracts/contract-list/contract-list.component';
import { CustomerAddComponent } from './customer-add/customer-add.component';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { CustomersComponent } from './customers/customers.component';
import { ExportersComponent } from './exporters/exporters.component';
import { LotListComponent } from './lots/lot-list/lot-list.component';

const routes: Routes = [
  { path: 'crear-cliente', component: CustomerAddComponent },
  { path: 'editar-cliente/:id', component: CustomerEditComponent },
  { path: 'clientes', component: CustomersComponent },
  { path: 'contrato', component: ContractAddComponent },
  { path: 'contrato/:id', component: ContractEditComponent },
  { path: 'exportadores', component: ExportersComponent },
  { path: 'lotes', component: LotListComponent },
  { path: 'brokers', component: BrokersComponent },
  { path: 'contratos', component: ContractListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExportsRoutingModule { }
