import { Category } from './../../../models/category';
import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../../services/category.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  public title = 'Cuentas contables';
  public DATA: Category[] = [];
  treeControl = new NestedTreeControl<Category> (node => node.children);
  dataSource = new ArrayDataSource(this.DATA);
  public categories = [];

  constructor(
    private categoryService: CategoryService
    ) { }


  ngOnInit() {
    this.categoryService.getCategories().subscribe(res => {
      const indexedCode: any = this.indexedByCode(res);
        const data: any = [];
        Object.keys(indexedCode).forEach(code => {
            if (indexedCode[code].length > 1) { console.error('Codigo duplicado: ', code); }
            const currentCategory = indexedCode[code];
            const parentIndex: number = currentCategory.parent;
            // * NO Es un cuenta root.
            if (currentCategory.parent !== null ) {
                const parent = indexedCode[parentIndex];
                if (!currentCategory.hasOwnProperty('children')) { currentCategory['children'] = []; }
                if (!parent.hasOwnProperty('children')) { parent['children'] = []; }
                parent.children.push(currentCategory);
            } else {
                currentCategory['children'] = [];
                data.push(currentCategory);
            }
          });
          console.log('Categories tree data: ', data);
          this.categories = data;
    }, err => console.error('Error al obtener tree de categorias: ', err));
  }
  // tslint:disable-next-line: use-life-cycle-interface

  hasChild = (_: number, node: Category) => !!node.children && node.children.length > 0;

  indexedByCode(data: Category[]): Object {
    const indexed = {};
    data.forEach((current, index) => {
      delete current.children;
      if (indexed.hasOwnProperty(current.code)) {
        console.error('Codigo repetido: ', current.code);
      } else {
        indexed[current.code] = current;
      }
    });
    return indexed;
  }
}

