import { PeriodClosing } from '../../../../models/period-closing';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CategoryService } from '../../../../services/category.service';
import { SeatService } from '../../../../services/seat.service';
import { UserService } from '../../../../services/user.service';
import { GLOBAL } from '../../../../services/global';
import * as _ from 'lodash';

import { TestingService } from '../../../../services/testing.service';
import { PeriodClosingService } from '../../../../services/period-closing.service';
import { CompaniesService } from '../../../../services/companies.service';
import { Seat } from '../../../../models/seat';
import { Category } from '../../../../models/category';

@Component({
  selector: 'app-close-period',
  templateUrl: './close-period.component.html',
  styleUrls: ['./close-period.component.css'],
})
export class ClosePeriodComponent implements OnInit {
  public title = 'Cierre de Periodo';
  public currentPeriod;
  public form: FormGroup;
  public utility: any = 0;
  public sumPC: any = 0;
  public currency;
  public accumulatedUtility = 0;
  public currentBalanceIndex: any = {
    activo:   {name: 'Activo',    category: [], total: 0},
    capital:  {name: 'Capital',   category: [], total: 0},
    costos:   {name: 'Costos',    category: [], total: 0},
    gastos:   {name: 'Gastos',    category: [], total: 0},
    ingresos: {name: 'Ingresos',  category: [], total: 0},
    pasivos:  {name: 'Pasivos',   category: [], total: 0},
  };
  public indexDetailsCategories: any = {};
  public company;
  public lastPeriod: PeriodClosing;

  constructor(
    private seatService: SeatService,
    private userService: UserService,
    private categoryService: CategoryService,
    private closingService: PeriodClosingService,
    private testingService: TestingService,
    private companyService: CompaniesService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ClosePeriodComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentPeriod = this.userService.getIdentity().company.settings.period;
    this.company = this.userService.getCompany();
  }

  ngOnInit() {
    this.currency = GLOBAL.currency;
    this.createForm();
    // this.getCurrentPeriod();
    this.setIndexDetailCategories();
    this.getLastPeriod();
    this.form.get('date').valueChanges.subscribe(() => {
      this.getCurrentPeriod();
      this.currentBalanceIndex = {
        activo:   {name: 'Activo',    category: [], total: 0},
        capital:  {name: 'Capital',   category: [], total: 0},
        costos:   {name: 'Costos',    category: [], total: 0},
        gastos:   {name: 'Gastos',    category: [], total: 0},
        ingresos: {name: 'Ingresos',  category: [], total: 0},
        pasivos:  {name: 'Pasivos',   category: [], total: 0},
      };
    });
  }

  // * Indice de valores de todas las cuentas de detalles con valores iniciales.
  setIndexDetailCategories(): any {
    this.categoryService.getDetailCategories().subscribe(res => {
      const indexDetails = res.reduce((acc: any, curr: Category) => ({
        ...acc,
        [curr._id]: {debit: 0, credit: 0, balance: 0, description: curr.description, code: curr.code, name: curr.name},
      }), {});
      console.log('cuentas detalle: ', indexDetails);
      this.indexDetailsCategories = indexDetails;
    }, err => console.error('Error al obtener cuentas detalle: ', err));
  }

  // * Obtiene el cierre del periodo anterior.
  getLastPeriod(): void {
    this.closingService.getLastPeriod(this.currentPeriod).subscribe(res => {
      this.lastPeriod = res ? res.balances : {};
      this.accumulatedUtility = res ? res.accumulatedUtility : 0;
      console.log('LAST PERIOD: ', _.cloneDeep(this.lastPeriod));
    }, err => console.error('Error al obtener el ultimo periodo: ', err));
  }

  // * Obtiene los datos del periodo actual.
  getCurrentPeriod(): void {
    const date = new Date(this.form.get('date').value);
    date.setHours(23, 59, 59);
    const params = {date_out: date, period: this.currentPeriod, status: true};
    console.log('params balance period: ', params);
    this.seatService.getbalance(params).subscribe((res: any[]) => {
      console.log('res: ', res);
      const balance = res;
      balance.forEach(item => {
        item.category = _.orderBy(item.category, (item_) => {
          return String(item_.code).substring(0, 2);
        }, 'asc');
      });
      this.currentBalanceIndex = Object.assign(this.currentBalanceIndex, res.reduce((acc, curr) => ({
        ...acc,
        [curr.name.toLowerCase()]: curr,
      }), {}));

      console.log('period data: ', this.currentBalanceIndex);
      this.sumPC = Number(this.currentBalanceIndex.pasivos.total + this.currentBalanceIndex.capital.total).toFixed(2);
      this.utility = !this.currentBalanceIndex.hasOwnProperty('costos y gastos') ?
        (this.currentBalanceIndex.ingresos.total -
          (this.currentBalanceIndex.gastos.total + this.currentBalanceIndex.costos.total)).toFixed(2) :
        (this.currentBalanceIndex.ingresos.total - this.currentBalanceIndex['costos y gastos'].total).toFixed(2);
      this.utility = parseFloat(Number(this.utility).toFixed(4));
    }, err => console.error('Errorres.items al obtener datos de cierre: ', err));
  }

  getUtility(): void {}

  submitForm(): void {
    const closing: any = this.form.getRawValue();
    const date = new Date(this.form.get('date').value);
    date.setHours(23, 59, 59);
    const params = {date_out: date, period: this.currentPeriod, status: true};
    closing.date = date;

    this.seatService.getSeatsWithFilters(params).subscribe(res => {
      // console.log('seats for period', this.currentPeriod, res);
      // tslint:disable-next-line:no-shadowed-variableo

      // * Establecer los saldos de las cuentas usadas en el periodo actual
      const categories: any = _.flattenDeep(res.items.map(category => category.SeatCategory));
      const categoriesGrouped: any = _.groupBy(categories, 'category._id');
      const currentBalancesIndexCategories: any = {};
      Object.keys(categoriesGrouped).forEach(categoryId => {
        const current = categoriesGrouped[categoryId];
        const sign = current[0].category.description === 'Pasivos' ||
          current[0].category.description === 'Capital' ||
          current[0].category.description === 'Patrimonio' ||
          current[0].category.description === 'Ingresos' ? -1 : 1;
        const totalDebit = _.sumBy(current, 'debit');
        const totalCredit = _.sumBy(current, 'credit');
        currentBalancesIndexCategories[categoryId] = {
          credit: totalCredit,
          debit: totalDebit,
          description: current[0].category.description,
          code: current[0].category.code,
          balance: (totalDebit - totalCredit) * sign,
          name: current[0].category.name
        };
      });
      // * -------------------------------------------------------
      console.log('BALANCES CURRENT PERIOD', _.cloneDeep(currentBalancesIndexCategories));
      Object.keys(this.currentBalanceIndex).forEach(key => {
        this.currentBalanceIndex[key] = this.currentBalanceIndex[key].total;
      });
      this.setIndexBalanceToNewPeriod(currentBalancesIndexCategories);
      closing.accountingSummary = this.currentBalanceIndex;
      closing.accountingSummary['utility'] = parseFloat(Number(this.utility).toFixed(4));
      closing.accountingSummary['accumulatedUtility'] = this.accumulatedUtility + this.utility;
      closing.balances = this.indexDetailsCategories;
      closing.period = this.currentPeriod;
      console.log('periodo cerrado: ', closing);
     this.closingService.addPeriodClosing(closing).subscribe(resp => {
        // this.updateSeatsPeriod();
        console.log('periodo cerrado: ', resp);
        this.companyService.updatePeriod(this.company._id, this.currentPeriod + 1).subscribe(respo => {
          console.log('period actualizad al perfil', respo);
          localStorage.setItem('company', JSON.stringify(respo));
          const currentUser = this.userService.getIdentity();
          currentUser.company = respo;
          localStorage.setItem('user', JSON.stringify(currentUser));
          this.dialogRef.close(true);
        }, err => console.error('Error al actualizar periodo a la compania:', err));
      }, err => console.error('Error al cerrar periodo: ', err));
    }, err => console.error('Error al obtener asientos por periodo: ', err));
    console.log('closing: ', closing);
  }

  // * Establece el indice de los balances del nuevo periodo, tomando encuenta el periodo anterior.
  async setIndexBalanceToNewPeriod(indexCurrentBalance): Promise<any> {
    // * Si es el primer periodo, hara una union del indice de cuentas basico con el indice del periodo actual.
    if (this.currentPeriod === 1) {
      this.indexDetailsCategories = Object.assign(this.indexDetailsCategories, indexCurrentBalance);
    } else { // * Si no es el primer cierre, hara la union de las cuentas con el periodo anterior para luego sumar los saldos con
      // * el periodo actual.
      const balanceIndexLastPeriod: any = this.lastPeriod;
      const indexMerged = Object.assign(this.indexDetailsCategories, balanceIndexLastPeriod);
      console.log('current balances: ', indexCurrentBalance);
      console.log('last balance index: ', indexMerged);
      Object.keys(indexCurrentBalance).forEach(key => {
        const totalBalance = parseFloat(Number(indexMerged[key].balance + indexCurrentBalance[key].balance).toFixed(4));
        this.indexDetailsCategories[key].balance = totalBalance;
      });
    }
  }

  // * Actualiza el periodo a los asientos siguientes al cierre de periodo.
  updateSeatsPeriod(): void {
    const date = new Date(this.form.get('date').value);
    date.setHours(23, 59, 59);
    const params = {date_in: date, period: 3, status: true};
    this.seatService.getSeatsWithFilters(params).subscribe(res => {
      console.log('Asientos a actualizar', res.item.length);
      res.items.forEach((current: Seat) => {
        current.period = 2;
        this.seatService.editSeat(current._id, current).subscribe(res2 => {

        }, err => console.error('Error al actualizar asiento'));
      });
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      date:           ['', Validators.required],
      observations :  [''],
    });
  }

}
