import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { IhcafeVoucher } from '../../../../models/ihcafe-voucher';
import { SaleVoucher } from '../../../../models/sale-voucher';

import { Exporter } from '../../../../models/exporter';
import { ExporterService } from '../../../../services/exporter.service';
import { SaleVoucherService } from '../../../../services/sale-voucher.service';
import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { IhcafeVoucherService } from '../../../../services/ihcafe-voucher.service';

@Component({
  selector: 'app-sale-voucher-edit',
  templateUrl: './sale-voucher-edit.component.html',
  styleUrls: ['./sale-voucher-edit.component.css']
})
export class SaleVoucherEditComponent implements OnInit {
  public exporters: Exporter[] = [];
  public exporter: string;
  public selected: any[] = [];
  public soldQQ = {};
  public error = false;
  public saleVoucherId: string;

  constructor(
    private _exporterService: ExporterService,
    private _saleVoucherService: SaleVoucherService,
    private _ihcafeVoucherService: IhcafeVoucherService,
    private _sidebar: SidebarManagerService,
  ) { }

  ngOnInit() {
    this.saleVoucherId = this._sidebar.getInput('sale-voucher-edit');
    this._saleVoucherService.getSaleVoucher(this.saleVoucherId).subscribe((voucher: SaleVoucher) => {
      console.log('to edit', voucher);
      this.selected = voucher.retentions.map((item: IhcafeVoucher, i: number) =>
        Object.assign(_.pick(item, ['_id', 'sale', 'coffeefarmer']), {
          total_qq: voucher.sold_qq[i],
          total: item.sale.remaining + voucher.sold_qq[i],
          sale: Object.assign(item.sale, {
            sold: item.sale.sold - voucher.sold_qq[i],
            remaining: item.sale.remaining + voucher.sold_qq[i]
          })
        })
      );
      this.soldQQ = this.selected.reduce((acc, curr) => ({
        ...acc,
        [curr._id]: curr.total_qq,
      }), {});
      this.exporter = voucher.exporter._id;
    });
    this.getExporters();
  }

  getExporters = (): void => {
    this._exporterService.getExporters().subscribe((exporters: Exporter[]) => {
      this.exporters = exporters;
    }, (err: HttpErrorResponse) => console.error('Error al obtener exportadores', err));
  }

  changeValue = (): void => {
    this.error = false;
    this.selected.forEach((voucher) => {
      if (this.soldQQ[voucher._id] > voucher.total) this.error = true;
    });
  }

  updateVoucher = (): void => {
    const saleVoucher: any = {
      exporter: this.exporter,
      sold_qq: this.selected.map((curr) => this.soldQQ[curr._id]),
      remaining_qq: this.selected.map((curr) => curr.total_qq - this.soldQQ[curr._id]),
      retentions: this.selected.map((curr) => curr._id),
      totalSold: this.totalqq
    };

    console.log('Comprobante de venta', saleVoucher);
    this._saleVoucherService.updateSaleVoucher(this.saleVoucherId, saleVoucher).subscribe((voucher) => {
      this.selected.forEach((item: IhcafeVoucher, i: number) => {
        const data = item.sale;
        if (data.vouchers.length === 0) {
          data.gold = item.total_qq;
          data.sold = 0;
          data.remaining = item.total_qq;
        }
        // data.vouchers.push(voucher._id);
        data.sold += saleVoucher.sold_qq[i];
        data.remaining -= saleVoucher.sold_qq[i];
        this._ihcafeVoucherService.updateVoucher(item._id, { sale: data }).subscribe((res) => {
          console.log('retencion actualizada');
          if (i === this.selected.length - 1) {
            this._sidebar.close('sale-voucher-edit', true);
          }
        }, (err: HttpErrorResponse) => console.error('Error al actualizar retencion', err));
      });
    }, (err: HttpErrorResponse) => console.error('Error al crear comprobante de venta', err));
  }

  get totalqq () {
    return _.sum(Object.values(this.soldQQ));
  }

}
