import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.css']
})
export class ChangeStatusComponent implements OnInit {
  status;
  constructor(
    public dialogRef: MatDialogRef<ChangeStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log(this.data);
    this.status = this.data.status;
  }
  onCloseConfirm() {
    this.dialogRef.close({res: 'confirm', id: this.data._id, status: this.status, dried: this.data.dryingOrder ? this.data.dryingOrder._id : ''});
  }
  onCloseCancel() {
    this.dialogRef.close('cancel');
  }

}
