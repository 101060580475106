import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserEditComponent } from './pages/user-edit/user-edit.component';
import { StarterComponent } from './pages/starter/starter.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { StateReportComponent } from './pages/state-report/state-report.component';
import { DepositReportComponent } from './pages/deposit-report/deposit-report.component';
import { PurchaseDateComponent } from './pages/purchase-date/purchase-date.component';
import { PurchaseCoffeefarmerComponent } from './pages/purchase-coffeefarmer/purchase-coffeefarmer.component';

// ! import { LiquidationPdfComponent } from './pages/liquidation-pdf/liquidation-pdf.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { RoleGuardService as RoleGuard } from './auth/role-guard.service';
import { CentercostAddComponent } from './pages/centercost-add/centercost-add.component';
import { CentercostEditComponent } from './pages/centercost-edit/centercost-edit.component';
import { BankEditComponent } from './pages/bank-edit/bank-edit.component';
import { BankComponent } from './pages/bank/bank.component';
import { ProveedoresComponent } from './pages/proveedores/proveedores.component';
import { ProveedoresAddComponent } from './pages/proveedores-add/proveedores-add.component';
import { ProveedoresEditComponent } from './pages/proveedores-edit/proveedores-edit.component';
import { Fd3Component } from './pages/fd3/fd3.component';
import { Fd32Component } from './pages/fd32/fd32.component';
import { Fd33Component } from './pages/fd33/fd33.component';
import { Fd34Component } from './pages/fd34/fd34.component';
import { SettlementsComponent } from './pages/administration/settlements/settlements.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { ImporterComponent } from './pages/importer/importer.component';
import { CheckInsComponent } from './pages/chekku/check-ins/check-ins.component';
import { AsisstsComponent } from './pages/asissts/asissts/asissts.component';
import { AsisstanceAddComponent } from './pages/asissts/asisstance-add/asisstance-add.component';
import { AsisstanceEditComponent } from './pages/asissts/asisstance-edit/asisstance-edit.component';
import { RoleRedirectGuard } from './shared/guards/redirect.guard';

// const appRoutes: Routes = [
//   { path: '', component: StarterComponent },
//   { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
//   { path: 'dashboard', component: StarterComponent },
//   { path: 'checkins', component: CheckInsComponent },
//   /*{ path: 'dashboard', component: StarterComponent },
//   {
//     path: 'login', component: UserComponent,
//     children: [{ path: '', component: SignInComponent }]
//   },
//   { path: '', redirectTo: '/login', pathMatch: 'full' },*/
//   {
//     path: 'user-edit',
//     component: UserEditComponent,
//     canActivate: [RoleGuard],
//     data: {
//       expectedRole: ['ADMIN', 'FARM']
//     }
//   },
//   {
//     path: 'ajustes',
//     component: SettingsComponent,
//     /* canActivate: [RoleGuard],
//     data: {
//       expectedRole: ['ADMIN', 'FARM']
//     } */
//   },
//   { path: 'centro-costo', component: CentercostAddComponent },
//   { path: 'centro-costo/:id', component: CentercostEditComponent },

//   // * ------------------------- Notas de Peso --------------------------
//   /* {
//     path: 'notas-de-peso',
//     loadChildren: () => import('./pages/weight-note/weight-note.module').then(mod => mod.WeightNoteModule)
//   }, */

//   { path: 'reportes', component: ReportsComponent },
//   { path: 'lista-productores', component: Fd32Component },
//   { path: 'listaProductores', component: Fd33Component },
//   { path: 'lista_productores', component: Fd34Component },
//   { path: 'reporte-estado', component: StateReportComponent },
//   { path: 'reporte-deposito', component: DepositReportComponent },
//   { path: 'compra-fecha', component: PurchaseDateComponent },
//   { path: 'perfil-usuario', component: UserProfileComponent },
//   { path: 'compra-productor', component: PurchaseCoffeefarmerComponent },
//   // { path: 'liquidacion/:id', component: LiquidationPdfComponent },
//   // * ------------------------- A d m i n i s t r a c i o n --------------------------
//   // { path: 'crear-liquidacion', component: SettlementsComponent },
//   { path: 'contabilidad/bancos', component: BankComponent },
//   { path: 'editar-banco/:id', component: BankEditComponent },
//   { path: 'proveedores', component: ProveedoresComponent },
//   { path: 'proveedores/agregar', component: ProveedoresAddComponent },
//   { path: 'proveedores/editar/:id', component: ProveedoresEditComponent},
//   { path: 'fd3', component: Fd3Component },
//   { path: 'find-my-coffee', component: ImporterComponent },
//   { path: 'checkins', component: CheckInsComponent },
//   { path: 'asistencia', component: AsisstsComponent },
//   { path: 'asistencia/nueva', component: AsisstanceAddComponent },
//   { path: '**', component: StarterComponent },
// ];


const appRoutes: Routes = [
  { path: '', component: StarterComponent, canActivate: [RoleRedirectGuard] },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: StarterComponent },
  { path: 'checkins', component: CheckInsComponent },
  {
    path: 'user-edit',
    component: UserEditComponent,
  },
  {
    path: 'ajustes',
    component: SettingsComponent,
  },
  { path: 'centro-costo', component: CentercostAddComponent },
  { path: 'centro-costo/:id', component: CentercostEditComponent },
  { path: 'reportes', component: ReportsComponent },
  { path: 'lista-productores', component: Fd32Component },
  { path: 'listaProductores', component: Fd33Component },
  { path: 'lista_productores', component: Fd34Component },
  { path: 'reporte-estado', component: StateReportComponent },
  { path: 'reporte-deposito', component: DepositReportComponent },
  { path: 'compra-fecha', component: PurchaseDateComponent },
  { path: 'perfil-usuario', component: UserProfileComponent },
  { path: 'compra-productor', component: PurchaseCoffeefarmerComponent },
  { path: 'contabilidad/bancos', component: BankComponent },
  { path: 'editar-banco/:id', component: BankEditComponent },
  { path: 'proveedores', component: ProveedoresComponent },
  { path: 'proveedores/agregar', component: ProveedoresAddComponent },
  { path: 'proveedores/editar/:id', component: ProveedoresEditComponent },
  { path: 'fd3', component: Fd3Component },
  { path: 'find-my-coffee', component: ImporterComponent },
  { path: 'checkins', component: CheckInsComponent },
  { path: 'asistencia/nueva', component: AsisstanceAddComponent },
  { path: '**', component: StarterComponent },
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, { useHash: true });
