import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CashCountAddComponent } from './cash-count-add/cash-count-add.component';
import { CashCountEditComponent } from './cash-count-edit/cash-count-edit.component';
import { CashCountListComponent } from './cash-count-list/cash-count-list.component';
import { SharedModule } from '../../../shared/shared.module';
import { CashCountClosingComponent } from './cash-count-closing/cash-count-closing.component';

@NgModule({
  declarations: [
    CashCountAddComponent,
    CashCountEditComponent,
    CashCountListComponent,
    CashCountClosingComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class CashCountsModule { }
