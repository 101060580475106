import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { MatSort } from '@angular/material';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { WarehouseService } from '../../../services/warehouse.service';
import { InventoryService } from '../../../services/inventory.service';
import { DriedService } from '../../../services/dried.service';
import { SaleService } from '../../../services/sale.service';
import { Warehouse } from '../../../models/warehouse';
import { WarehouseAddComponent } from '../warehouse-add/warehouse-add.component';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';

@Component({
  selector: 'app-warehouses',
  templateUrl: './warehouses.component.html',
  styleUrls: ['./warehouses.component.css'],
  providers: [],
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .dark-modal .modal-content {
      background-color: #009efb;
      color: white;
    }
    .dark-modal .close {
      color: white;
    }
  `]
})
export class WarehousesComponent implements OnInit {

  public titulo: string;
  public warehouses: Warehouse[];
  public identity;
  public token;
  public url: string;
  public confirmado;
  public alertMessage;
  closeResult: string;
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  public dataSource = new TableVirtualScrollDataSource<Warehouse>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }
  public dataTable: Warehouse[];

  displayedColumns: string[] = ['name', 'cod', 'address', 'capacity', 'actions' ];
  highlightedRows = [];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _warehouseService: WarehouseService,
    private _inventoryService: InventoryService,
    private _driedService: DriedService,
    private _saleService: SaleService,
    private _userService: UserService,
    private modalService: NgbModal,
    public dialog: MatDialog
  ) {
    this.titulo = 'Bodegas';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.url = GLOBAL.url;
  }

  ngOnInit() {
    this.getWarehouses();
  }

  openDialogD(productor) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar bodega',
        title: '',
        text: `¿Seguro que quieres eliminar <strong>#${productor.cod}</strong> ?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteCustomer(productor._id);
      }
    });
  }


  deleteCustomer(id) {

    this.alertMessage = '';
    this._warehouseService.getWarehouse(id).subscribe(
      res => {
        console.log(res.length);
        if (res.length !== 0) {
           this.delete(id);
        } else {
          this.alertMessage = 'el cliente no se puede borrar';
        }
      }
    );
  }

  openDialog() {
    const dialogRef = this.dialog.open(WarehouseAddComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getWarehouses();
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  open2(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  open(content2) {
    this.modalService.open(content2).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getWarehouses() {
    this._warehouseService.getWarehouses().subscribe(
      (res) => {
        if (!res) {
          this._router.navigate(['/']);
        } else {
          this.warehouses = res;
          this.dataSource.data = res;
          console.log(res);
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  onDeleteConfirm(id) {
    this.confirmado = id;
  }

  onCancelWarehouse() {
    this.confirmado = null;
  }

  delete(id) {
    this._warehouseService.deleteWarehouse(id).subscribe(
      response => {
        if (!response) {
          alert('Error en el servidor');
        } else {
          this.getWarehouses();
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  onDeleteWarehouse(id) {
    this.alertMessage = '';
    console.log(id);
    this._inventoryService.getInventoryPerWarehouse(id).subscribe(
      response => {
        console.log(response.length);
        this._driedService.getDriedPerWarehouse(id).subscribe(
          respons => {
            console.log(respons.length);
            this._saleService.getSalePerWarehouse(id).subscribe(
              res => {
                console.log(res.length);
                if (response.length === 0 && respons.length === 0 && res.length === 0) {
                  this.delete(id);
                } else {
                  this.alertMessage = 'La bodega no se puede borrar';
                }
              }
            );
          }
        );
      }
    );
  }
}
