import { UserService } from './../../../../../services/user.service';
import { GLOBAL } from './../../../../../services/global';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as _moment from 'moment';
import * as _ from 'lodash';

import { SaleService } from '../../../../../services/sale.service';
import { Sale } from '../../../../../models/sale';
@Component({
  selector: 'app-step-re-two',
  templateUrl: './step-re-two.component.html',
  styleUrls: ['./step-re-two.component.css']
})
export class StepTwoReComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public sales: Sale[] = [];
  public indexSelected: any = {};
  public referral: any = {};
  public identity;
  public totalNet = 0;
  public currency;
  public totalSale = 0;
  public balanceStatus: any = {};
  constructor(
    private userService: UserService,
    private _saleService: SaleService,
    private fb: FormBuilder,
    ) {
      this.identity = this.userService.getCompany()._id;
      const data = localStorage.getItem('referral');
      const index = localStorage.getItem('indexSelectedSales');
      this.referral = data !== null ? JSON.parse(data) : {};
      this.indexSelected = index !== null ? JSON.parse(index) : {};
      if (this.identity !== '5f50fdc413c3ab1f4454c17d') {
        this.referral.total_qq_referral = this.sumBy(this.indexSelected, 'total_qq_ps');
      }
      this.totalNet = this.referral.total_net + this.referral.total_qq_referral;
      console.log('Step 2 current LS data: ', this.referral);
  }

  ngOnInit() {
    console.log(this.referral);
    this.currency = GLOBAL.currency;
    this.getSales();
  }

  getSales(): void {
    this._saleService.getSales().subscribe((data) => {
      if (data !== null) {
        const ventas = data.filter(sale => {
          const newTotal = sale.price * sale.total_qq_referral;
          Object.assign(sale, {newTotal: newTotal});
          return sale.status !== 'Propio';
        });
        this.sales = ventas;
      } else {}
    }, err => console.error('Error al obtener ventas: ', err));
  }

  public highlight(sale: any): void {
    sale['total_qq_referral'] = sale.hasOwnProperty('total_qq_referral') ? sale.total_qq_referral : sale.total_qq_ps;
    sale['sale_price'] = sale.hasOwnProperty('price') ? sale.price : sale.price;
    // console.log('value: ', ap, ap.paymentValue);
    if (this.indexSelected.hasOwnProperty(sale._id)) {
      delete this.indexSelected[sale._id];
    } else {
      this.indexSelected[sale._id] = { total_qq_ps: sale.total_qq_ps, total_qq_referral: sale.total_qq_referral, total_sale: sale.total_sale, sale_price: sale.price };
    }
    this.getSum();
    console.log(this.indexSelected);
  }

  getSum(): void {
    let sale = 0;
    const totalPs = Object.keys(this.indexSelected).reduce((acc, key) => acc + this.indexSelected[key].total_qq_ps, 0);
    Object.keys(this.indexSelected).forEach(key => {
      const current: any = this.indexSelected[key];
      sale += current.total_qq_ps * current.sale_price;
    });
    console.log(sale);
    this.referral.total_qq_referral = parseFloat(Number(totalPs).toFixed(2));
    this.referral.total_sales = parseFloat(Number(sale).toFixed(2));
    this.referral.total_net = this.getTotalNet(this.referral, this.referral.total);
    console.log(this.referral);
  }

  saveData(): void {
    const index = this.indexSelected;
    let totalSale = 0;
    let restMoney = []
    Object.keys(index).forEach(key => {
      const current = index[key];
      current.total_qq_referral = current.total_qq_ps >= current.total_qq_referral ? current.total_qq_referral : current.total_qq_ps;
      const total_sale = current.total_qq_referral * current.sale_price;
      console.log(total_sale, current.total_qq_referral, current.sale_price);
      restMoney.push(total_sale);
      this.totalSale += total_sale;
    });
    let total_qq = this.sumBy(this.indexSelected, 'total_qq_referral');
    console.log(totalSale);
    const stepTwoData = Object.assign(this.referral, {
      sales: Object.keys(index),
      taked_sale: this.mapBy(index, 'total_qq_referral'),
      restMoney: restMoney,
      rest_sale: Object.keys(index).map(key => {
        const rest = parseFloat(Number(Number(index[key].total_qq_ps) - Number(index[key].total_qq_referral)).toFixed(6));
        return rest;
      }),
      total_sales: this.totalSale,
      total_qq: parseFloat(Number(total_qq).toFixed(2))
    });
    stepTwoData['total_net'] = this.getTotalNet(stepTwoData, stepTwoData.total);
    this.referral = Object.assign(this.referral, stepTwoData);
    console.log('referral end step 2:  ', this.referral);
    localStorage.setItem('referral', JSON.stringify(this.referral));
    localStorage.setItem('indexSelectedSales', JSON.stringify(index));
  }

  getTotalNet(settlement: any, total): number {
    let total_net = total;
    const numbers = _.pick(settlement, ['r_qq', 'bags', 'wet_quintals', 'dry_qq',
      'inventory_qq', 'moisture_loss', 'dry_qq', 'total_qq_referral', 'total_sale']);
    Object.keys(numbers).forEach(key => {
      numbers[key] = isNaN(numbers[key]) ? 0 : numbers[key];
      total_net -= numbers[key];
    });
    return parseFloat(Number(total_net).toFixed(2));
  }

  sumBy(object: any, field: string): number {
    return Object.keys(object).reduce((acc, key) => acc + object[key][field] , 0);
  }

  mapBy (object: any, field: string): Object {
    return Object.keys(object).map(key => object[key][field]);
  }

  ngOnDestroy(): void {
    this.saveData();
  }

  createForm(): void {
    this.form = this.fb.group({
      total_sales: [this.referral.total_sales]
    });
    console.log('form init: ', this.form.value);
  }
}

