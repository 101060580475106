import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { GLOBAL } from '../../services/global';
import { UserService } from '../../services/user.service';
import { CenterCostService } from '../../services/center-cost.service';
import { CenterCost } from '../../models/center-cost';

@Component({
  selector: 'app-centercost-add',
  templateUrl: './centercost-add.component.html',
  styleUrls: ['./centercost-add.component.css'],
  providers: [UserService, CenterCostService]
})
export class CentercostAddComponent implements OnInit {
  public titulo: string;
  public centercost: any = {};
  public identity;
  public token;
  public harvest;
  public url: string;
  public alertMessage;
  public disabled = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _centercostService: CenterCostService,
    public dialogRef: MatDialogRef<CentercostAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.titulo = 'Agregar Centro de Costo';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.url = GLOBAL.url;
    this.centercost = {} as CenterCost;
  }

  ngOnInit() {
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  submitForm() {
    this.disabled = true;
    this.centercost.harvest = this.harvest;
    this._centercostService.addCenterCost(this.centercost).subscribe(
      response => {
        this.alertMessage = 'Registro creado correctamente';
        this._router.navigate(['/ajustes']);
        this.onCancelClick();
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          this.alertMessage = body.message;
          console.log(error);
        }
      }
    );
  }

}
