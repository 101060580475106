import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaleAddComponent } from './sale-add/sale-add.component';
import { SaleEditComponent } from './sale-edit/sale-edit.component';
import { SalesComponent } from './sale-list/sales.component';
import { SharedModule } from '../../../shared/shared.module';
import { SimpleSaleAddComponent } from './simple-sale-add/simple-sale-add.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { SimpleSaleEditComponent } from './simple-sale-edit/simple-sale-edit.component';

@NgModule({
  declarations: [
    SaleAddComponent,
    SaleEditComponent,
    SalesComponent,
    SimpleSaleAddComponent,
    InvoiceComponent,
    InvoicesComponent,
    SimpleSaleEditComponent,
  ],
  imports: [
    SharedModule,
    CommonModule
  ]
})
export class SalesModule { }
