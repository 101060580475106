import { DriedUpdateComponent } from './dried-update/dried-update.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DriedAddComponent } from './dried-add/dried-add.component';
import { DriedDetailComponent } from './dried-detail/dried-detail.component';
import { DriedsComponent } from './drieds/drieds.component';
import { InventoryQqComponent } from './inventory-qq/inventory-qq.component';
import { InventoryComponent } from './inventory/inventory.component';
import { MachineAddComponent } from './machine-add/machine-add.component';
import { MachineEditComponent } from './machine-edit/machine-edit.component';
import { MachinesComponent } from './machines/machines.component';
import { TransferAddComponent } from './transfer-add/transfer-add.component';
import { TransferEditComponent } from './transfer-edit/transfer-edit.component';
import { TransfersComponent } from './transfers/transfers.component';
import { BlendListComponent } from './blends/blend-list/blend-list.component';

const routes: Routes = [
  { path: 'ordenes-secado', component: DriedsComponent },
  { path: 'ordenes-secado/crear-orden', component: DriedAddComponent },
  { path: 'ordenes-secado/editar-orden-secado/:id', component: DriedUpdateComponent },
  { path: 'ordenes-secado/inventario-seco', component: InventoryComponent },
  { path: 'ordenes-secado/mezclas', component: BlendListComponent },
  { path: 'ordenes-secado/inventario', component: InventoryQqComponent },
  { path: 'ordenes-secado/maquinas', component: MachinesComponent },
  { path: 'ordenes-secado/crear-maquina', component: MachineAddComponent },
  { path: 'ordenes-secado/editar-maquina/:id', component: MachineEditComponent },
  { path: 'ordenes-secado/traslados', component: TransfersComponent },
  { path: 'ordenes-secado/crear-traslado', component: TransferAddComponent },
  { path: 'ordenes-secado/editar-traslado/:id', component: TransferEditComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DryRoutingModule { }
