import { NgModule } from '@angular/core';
import { LocationListComponent } from './location-list/location-list.component';
import { LocationAddComponent } from './location-add/location-add.component';
import { LocationEditComponent } from './location-edit/location-edit.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [
    LocationListComponent,
    LocationAddComponent,
    LocationEditComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    LocationAddComponent,
  ]
})
export class LocationsModule { }
