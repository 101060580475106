import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AvatarModule } from 'ngx-avatar';
import { NgApexchartsModule } from 'ng-apexcharts';

import { SharedModule } from '../../../shared/shared.module';
import { ProductionAddComponent } from './production-add/production-add.component';
import { ProductionListComponent } from './production-list/production-list.component';
import { ProductionEditComponent } from './production-edit/production-edit.component';
import { LeftoversModule } from '../leftovers/leftovers.module';
import { PaginationComponent } from '../../pagination/pagination.component';
import { NewValueComponent } from './new-value/new-value.component';

@NgModule({
  declarations: [
    ProductionListComponent,
    ProductionAddComponent,
    ProductionEditComponent,
    PaginationComponent,
    NewValueComponent,
  ],
  imports: [
    SharedModule,
    AvatarModule,
    LeftoversModule,
    NgApexchartsModule,
  ],
  providers: [
    // ContractService
  ],
  entryComponents: [
    NewValueComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class ProductionsModule { }
