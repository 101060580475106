import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ExpenseService } from '../../../services/expense.service';
import { UserService } from '../../../services/user.service';
import { GLOBAL } from '../../../services/global';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Expense } from '../../../models/expense';
import { MatExpansionPanel, MatDialog } from '@angular/material';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { SettlementService } from '../../../services/settlement.service';
import { Settlement } from '../../../models/settlement';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.css']
})
export class ExpensesComponent implements OnInit {
  private skip = 0;
  public currentHarvest;
  public form: FormGroup;
  public harvests = ['2018 / 2019', '2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024'];
  public title = 'Egresos';
  public loading = false;
  public filter = false;
  public moreBtn = false;
  public currency;
  public displayedColumns = ['cod', 'date', 'concept', 'concept_code', 'mount', 'observations', 'action'];
  public dataSource = new TableVirtualScrollDataSource<Expense>();
  @ViewChild( MatExpansionPanel ) extensionPanel: MatExpansionPanel;
  constructor(
    private fb: FormBuilder,
    private expenseService: ExpenseService,
    private userService: UserService,
    private settlementService: SettlementService,
    public dialog: MatDialog
  ) {
    this.currentHarvest = this.userService.getIdentity().company.harvest;
    console.log('CurrentHarvest: ', this.currentHarvest);
  }

  ngOnInit() {
    this.createForm();
    this.getExpensesWithFilters();
    this.currency = GLOBAL.currency;
    // this.listenerFormValue();
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getExpensesWithFilters(): void {
    const filters = this.form.value;
    this.loading = true;
    Object.keys(filters).forEach((key) => {
      if (filters[key] == null || filters[key] === '') {
        delete filters[key];
      } else {
        filters[key] = filters[key].toString().toLowerCase();
      }
    });
    if (
      this.form.get('date_in').invalid ||
      this.form.get('date_out').invalid ||
      filters.date_in === '' ||
      filters.date_out === ''
      ) {
      delete filters.date_in;
      delete filters.date_out;
    }
    filters['harvest'] = this.currentHarvest;
    filters['skip'] = this.skip;
    filters['limit'] = 100;
    console.log('filters: ', filters);
    this.expenseService.getExpensesWithFilters(filters).subscribe(res => {
      console.log('res: ', res);
      this.loading = false;
      this.dataSource.data = this.dataSource.data.concat(res);
      console.log('newLen: ', this.dataSource.data.length);
    }, err => console.error('ERROR al obtener egresos ', err));
  }

  getHarvest(harvest: string): void {
    this.skip = 0;
    this.dataSource.data = [];
    this.currentHarvest = harvest;
    this.getExpensesWithFilters();
  }

  filterData(): void {
    this.dataSource.data = [];
    this.skip = 0;
    this.getExpensesWithFilters();
  }

  createForm(): void {
    this.form = this.fb.group({
      code:         [''],
      concept:      [''],
      mount:        [''],
      date_in:      [''],
      date_out:     [''],
      observations: [''],
    });
  }

  clearForm(): void {
    this.form.reset();
    this.skip = 0;
    this.dataSource.data = [];
    this.getExpensesWithFilters();
  }

  displayFilterForm(): void {
    this.filter = !this.filter;
    if (this.filter) {
      this.extensionPanel.open();
    } else {
      this.extensionPanel.close();
    }
  }

  delete(expense: Expense): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar egreso',
        title: '',
        text: `¿Seguro que quieres eliminar el egreso <strong>#${expense.cod}</strong>?`,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.dataSource.data = [];
        this.expenseService.deleteExpense(expense._id).subscribe(response => {
          console.log('Egreso removido: ');
          this.changeValueToModel(expense);
          this.getExpensesWithFilters();
        }, err => console.error('Error al remover egreso: ', err));
      }
    });
  }

  printExpense(expense: Expense): void {}
  changeValueToModel(expense: Expense): void {
    const objectModel: any = expense.concept_id;
    if (expense.concept_model === 'Settlement') {
      objectModel.pending = objectModel.pending + expense.mount;
      this.settlementService.updateSettlementPending(objectModel._id, objectModel).subscribe(response => {
        console.log('valor retornado a liquidacion');
      }, err => console.error('Error al retornar abono a liquidacion'));
    } else if (expense.abbreviation === 'Liq') {

    } else {
      console.error('Modelo de egresos no definido');
    }
  }
}
