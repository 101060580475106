import { CategoriesComponent } from './categories/categories.component';
import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { SeatAddComponent } from './seat-add/seat-add.component';
import { SeatingComponent } from './seating/seating.component';
import { SeatEditComponent } from './seat-edit/seat-edit.component';
import { LedgerDetailComponent } from './ledger-detail/ledger-detail.component';
import { BalanceSheetComponent } from './balance-sheet/balance-sheet.component';
import { StatementOfIncomeComponent } from './statement-of-income/statement-of-income.component';
import { CategoriesTreeComponent } from './categories-tree/categories-tree.component';
import { TrialBalanceComponent } from './trial-balance/trial-balance.component';
import { DailybookComponent } from './dailybook/dailybook.component';
import { PeriodClosingListComponent } from './period-closings/period-closing-list/period-closing-list.component';
import { PeriodClosingViewComponent } from './period-closings/period-closing-view/period-closing-view.component';

const routes: Routes = [
    { path: 'contabilidad/cuentas', component: CategoriesTreeComponent },
    { path: 'contabilidad/cuentas-contables', component: CategoriesComponent },
    { path: 'contabilidad/asientos', component: SeatingComponent },
    { path: 'contabilidad/asientos/nuevo', component: SeatAddComponent},
    { path: 'contabilidad/asientos/:id', component: SeatEditComponent },
    { path: 'contabilidad/libro-diario', component: DailybookComponent },
    { path: 'contabilidad/libro-mayor', component: LedgerDetailComponent },
    { path: 'contabilidad/balance-general', component: BalanceSheetComponent },
    { path: 'contabilidad/estado-resultados', component: StatementOfIncomeComponent },
    { path: 'contabilidad/balance-comprobacion', component: TrialBalanceComponent },
    { path: 'contabilidad/cierres-contables', component: PeriodClosingListComponent },
    { path: 'contabilidad/cierre-contable/:id', component: PeriodClosingViewComponent }
];

export const ModuleRouting: ModuleWithProviders = RouterModule.forChild(routes);
