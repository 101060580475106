import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Descriptor } from '../models/descriptor';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class DescriptorService {
  public url = GLOBAL.url + '/descriptors';
  constructor(
    private http: HttpClient
  ) { }

  addDescriptor(descriptor: Descriptor): Observable<any> {
    return this.http.post(`${this.url}/new`, descriptor);
  }

  getDescriptors(): Observable<any> {
     return this.http.get(`${this.url}/list`);
   }

   getDescriptor(descritorId: string): Observable<any> {
     return this.http.get(`${this.url}/${descritorId}`);
   }

   updateDescriptor(descriptorId: string, descriptor: Descriptor): Observable<any> {
     return this.http.put(`${this.url}/${descriptorId}`, descriptor);
   }
}
