import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from './global';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UploadService {
  public url: string;
  public token;
  public identity;

  constructor(private _http: Http, public http: HttpClient) {
    this.url = GLOBAL.url;
  }
  getImage(image: string): Observable<Blob> {
    return this.http.get(`${this.url}/users/get-image-file/${image}`, { responseType: 'blob' });
  }

  subirnuevaimagen(url: string, file: File, name: string, token: string){
    console.log("Mandando archivo");
    return new Promise(function (resolve, reject) {

      console.log("Guardando archivo de imagen");
      console.log(name);

      const formData: any = new FormData();
      const xhr = new XMLHttpRequest();

      formData.append("imagen", file, file.name);

      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      xhr.send(formData);

    });
  }

  makeFileRequest(url: string, params: Array<string>, files: Array<File>, token: string, name: string) {
    return new Promise(function (resolve, reject) {
      const formData: any = new FormData();
      const xhr = new XMLHttpRequest();

      for (let i = 0; i < files.length; i++) {
        formData.append(name, files[i], files[i].name);
      }

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };

      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      xhr.send(formData);

    });
  }
}
