import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

import { PrintService } from '../../../services/print.service';
import { PurchaseContractService } from '../../../services/purchase-contract.service';
import { GLOBAL } from '../../../services/global';

import { AdvancePaymentEditComponent } from '../advance-payments/advance-payment-edit/advance-payment-edit.component';
import { PurchaseContractAddComponent } from '../purchase-contract-add/purchase-contract-add.component';

import { PurchaseContract } from '../../../models/purchase-contract';

@Component({
  selector: 'app-purchase-contracts',
  templateUrl: './purchase-contracts.component.html',
  styleUrls: ['./purchase-contracts.component.css']
})
export class PurchaseContractsComponent implements OnInit {
  public title = 'Contratos de Compra';
  public alertMessage = '';
  dataTable;
  public lenCurrentFilters = 0;
  public loading = false;
  public currency;
  public dataSource = new TableVirtualScrollDataSource<PurchaseContract>();
  public displayedColumns = ['cod', 'coffeefarmer', 'contract', 'total_qq', 'price', 'contract_value', 'remaining_qq', 'status', 'action'];
  public form: FormGroup;
  constructor(
    private purchaseContractService: PurchaseContractService,
    private printService: PrintService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getPurchaseContracts();
    this.currency = GLOBAL.currency;
  }

  private getPurchaseContracts(): void {
    this.purchaseContractService.getPurchaseContracts().subscribe(res => {
      console.log('Contratos de compra: ', res);
      this.dataSource.data = res;
    }, err => console.error('Error al obtener contratos de compra: ', err));
  }

  openDialogCreate(): void {
    const dialogRef = this.dialog.open(PurchaseContractAddComponent, {
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
      width: '450px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getPurchaseContracts();
    });
  }
  openPurchaseContractEdit(purchaseContract: PurchaseContract): void {
    const dialogRef = this.dialog.open(AdvancePaymentEditComponent, {
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getPurchaseContracts();
    });
  }

  openDialogOverride(purchaseContract: PurchaseContract): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Anular anticipo',
        title: '',
        text: `¿Seguro que quieres anular el contrato <strong>#${purchaseContract.cod}</strong>?`,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // todo Desvincular liquidacion del contrato, o anular la liquidacion.
    });
  }

  changePage(event): void {}

  print(id: string): void {
    this.printService.getAdvancePayment(id, status).then(
      (res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      })
      .catch(err => console.log('Error', err));
  }
}
