import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Contract } from '../models/contract';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getContracts(): Observable<any> {
    return this.http.get(`${this.url}/contracts/list`);
  }

  addContract(contract: Contract): Observable<any> {
    return this.http.post(`${this.url}/contracts/new`, contract);
  }
  getContract(id): Observable<any> {
    return this.http.get(`${this.url}/contracts/${id}`);
  }
  editContract(id, contract): Observable<any> {
    return this.http.put(`${this.url}/contracts/${id}`, contract);
  }
  deleteContract(id): Observable<any> {
    return this.http.delete(`${this.url}/contracts/${id}`);
  }

}
