import { Component, OnInit } from '@angular/core'; 
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from '../../services/user.service';
import { UploadService } from '../../services/upload.service';
import { GLOBAL } from '../../services/global';
import { Company } from '../../models/company';

@Component({
  selector: 'app-uploadimagen',
  templateUrl: './uploadimagen.component.html',
  styleUrls: ['./uploadimagen.component.css'],
  providers: [UserService, UploadService],
})
export class UploadimagenComponent implements OnInit {
  public alertMessage;
  public filesToUpload: Array<File>;
  public url: string = "";
  public token;
  public company: Company;
  public image;
  public isImageLoading;
  imageToShow: any;
  public respuesta = "no";
  public textoboton = "Salir";

  constructor(
    public dialogRef: MatDialogRef<UploadimagenComponent>,
    public _userService: UserService,
    public _uploadService: UploadService
  ) { 
    this.url = GLOBAL.url;
    this.token = this._userService.getToken();
    this.company = this._userService.getCompany();
    this.image = this.company.image;
    this.isImageLoading = true;
   }

  ngOnInit() {
    //this.getImage();
  }

  onClose(){
    this.dialogRef.close(this.respuesta);
  }

  subirimagen(file){
    this.filesToUpload = <Array<File>>file.target.files;


    if (file.target.files.length > 0 ) {
     
      const archivo = file.target.files[0];
      const nombrearchivo = file.target.files[0].name;
      
      console.log(archivo);
      console.log(nombrearchivo);

      this._uploadService.subirnuevaimagen(`${this.url}/users/upload-img-new/${this.company._id}`, archivo, nombrearchivo, this.token);

      this._userService.getCompanyUser(this.company._id).subscribe((result: any) => {

        this.company.image = result.image;
        
        //window.location.reload();
        console.log(result);

        localStorage.setItem('company', JSON.stringify(this.company));
        
        this.getImage();
        this.respuesta = "listo";
        this.textoboton = "Guardar y actualizar";    
        
      });

      
    } else {
      console.log("No se escogio ninguna imagen");
      
    }
    

  }


  getImage() {
    this.image = this.company.image;
    this.isImageLoading = true;
    this._uploadService.getImage(this.image).subscribe(data => {
      this.createImageFromBlob(data);
      this.isImageLoading = false;
    }, error => {
      this.isImageLoading = false;
      console.log(error);
    });
  }


  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

}
