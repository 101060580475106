import { NgModule } from '@angular/core';
import { LaboratoryListComponent } from './laboratory-list/laboratory-list.component';
import { LaboratoryAddComponent } from './laboratory-add/laboratory-add.component';
import { LaboratoryEditComponent } from './laboratory-edit/laboratory-edit.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [
    LaboratoryListComponent,
    LaboratoryAddComponent,
    LaboratoryEditComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    LaboratoryAddComponent,
    LaboratoryEditComponent
  ]
})
export class LaboratoriesModule { }
