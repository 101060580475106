import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Validators, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';

import { SelectCategoryComponent } from './select-category/select-category.component';

import { CategoryService } from '../../../services/category.service';
import { SeatService } from '../../../services/seat.service';
import { GLOBAL } from '../../../services/global';

import { Category } from '../../../models/category';
import { cssNumber } from 'jquery';

@Component({
  selector: 'app-seat-edit',
  templateUrl: './seat-edit.component.html',
  styleUrls: ['./seat-edit.component.css'],
  providers: [SeatService, CategoryService]
})
export class SeatEditComponent implements OnInit, OnDestroy  {

  public title = 'Editar Asiento';
  public alertMessage;
  public selectedFile;
  public data = [];
  public seatForm: FormGroup;
  public totalDebit = 0;
  public totalCredit = 0;
  public diff = 0;
  public currentSeat;
  public seatToEdit;
  public currency;
  public indexedCategoriesIn: any = {};
  public ledgerCategories: Category[];
  public seatCategoryData: any[] = [];
  public tags = ['Anticipo', 'Liquidación', 'Gasto', 'Ingreso', 'Varios'];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _seatService: SeatService,
    private _categoryService: CategoryService,
    private fb: FormBuilder,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.getSeat();
    this.createForm();
    this.getCategories();
    this.currency = GLOBAL.currency;
    // window.document.getElementById('generalContent').addEventListener('scroll', this.scrollTable);
  }

  getCategories() {
    this._categoryService.getLedgerCategories().subscribe(res => {
        const categories = res as Category[];
        this.ledgerCategories = categories;
      }, error => console.error('Error al obtener la cuentas de detalle: ', error)
    );
  }

  sumValues(): void {
    this.totalDebit = parseFloat(this.seatCategoryData.reduce((acc, item) => acc + (isNaN(Number(item.debit)) ? 0 : Number(item.debit)), 0).toFixed(2));
    this.totalCredit = parseFloat(this.seatCategoryData.reduce((acc, item) => acc + (isNaN(Number(item.credit)) ? 0 : Number(item.credit)), 0).toFixed(2));

    this.diff = parseFloat((this.totalDebit - this.totalCredit).toFixed(2));
    this.diff = isNaN(Number(this.diff)) ? 0 : this.diff;
    this.alertMessage = Number(this.diff) === 0 ? '' : this.alertMessage;
  }

  getSeat() {
    console.log('router: ', this._route.params);
    const id = this._route.params['_value'].id;
    this._seatService.getSeat(id).subscribe(response => {
      const seat = JSON.parse(JSON.stringify(response));
      this.seatForm.get('date_voucher').setValue(seat.date_voucher);
      this.seatForm.get('description').setValue(seat.description);
      console.log('seatToEdit: ', seat);
      this.seatCategoryData = _.cloneDeep(response.SeatCategory);
      this.seatCategoryData.forEach(item => {
        item['name'] = `${item.category.code}-${item.category.name}`;
      });
      this.indexedCategoriesIn = _.groupBy(seat.SeatCategory, 'category._id');
      this.seatToEdit = JSON.parse(JSON.stringify(response));
      this.currentSeat = _.cloneDeep(response);
      // this.seatForm.get('tag').setValue(seat.tag === undefined ? 'Varios' : seat.tag);

      this.totalDebit = parseFloat(this.seatCategoryData.reduce((acc, item) => acc + (isNaN(Number(item.debit)) ? 0 : Number(item.debit)), 0).toFixed(2));
      this.totalCredit = parseFloat(this.seatCategoryData.reduce((acc, item) => acc + (isNaN(Number(item.credit)) ? 0 : Number(item.credit)), 0).toFixed(2));
      
      this.diff = parseFloat((this.totalDebit - this.totalCredit).toFixed(2));
      this.diff = isNaN(Number(this.diff)) ? 0 : this.diff;
      }, err => console.error('Error al obtener asiento: ', err)
    );
  }

  openCategoryModal(category: any, index: number): void {
    const dialogRef = this.dialog.open(SelectCategoryComponent, {
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
      width: '500px',
      data: {
        current: category,
        categories: this.ledgerCategories
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response !== undefined) {
        const name = `${response.code}-${response.name}`;
          this.seatCategoryData[index].category = response;
          this.seatCategoryData[index].name = name;
      }
    });
  }

  editSeat(): void {
    const seat = Object.assign(this.seatToEdit, this.seatForm.value); // Quien recibe - De donde recibe
    const totalDebit = parseFloat(this.seatCategoryData.reduce((acc, item) => acc + (isNaN(Number(item.debit)) ? 0 : Number(item.debit)), 0).toFixed(2));
    const totalCredit = parseFloat(this.seatCategoryData.reduce((acc, item) => acc + (isNaN(Number(item.credit)) ? 0 : Number(item.credit)), 0).toFixed(2));
    this.diff = parseFloat((totalDebit - totalCredit).toFixed(2));
    this.diff = isNaN(Number(this.diff)) ? 0 : this.diff;
    this.seatCategoryData = this.seatCategoryData.filter(item =>
      item.category !== null &&
      item.category !== undefined &&
      item.category !== '');
    this.sumValues();
    if (Number(totalCredit) !== Number(totalDebit)) {
      this.alertMessage = 'El asiento no esta cuadrado';
      console.error('Cantidad faltante: ', totalCredit - totalDebit);
    } else {
      seat.date_voucher_n = new Date(this.seatForm.controls['date_voucher'].value).getTime();
      seat.total_debit = totalDebit;
      seat.total_credit = totalCredit;
      seat.SeatCategory = this.seatCategoryData;
      console.log('seat: ', seat);
      this._seatService.editSeat(seat._id, seat).subscribe(response => {
        console.log('Asiento editado: ', response);
        this.updateCategories(seat.SeatCategory);
        this._router.navigateByUrl('/contabilidad/asientos');
      }, err => console.error('Error al editar asiento: ', seat, err));
    }
  }

  /*
    * Se comparan las cuentas del asiento con las cuentas al submit, para verificar cambios y actualizaciones de saldos.
    * 1) Si la cuenta aun permanece y su valor no ha sido alterado.
    * 2) Si la cuenta aun permanece y su valor ha sido alterado.
    * 3) Si hay cuenta nueva agregada.
    * 4) Si hay cuenta removida.
  */
  updateCategories(categories: any[]): void {
    const indexedCategoriesOut = _.groupBy(categories, 'category._id');
    console.log('indexedIn: ', this.indexedCategoriesIn);
    console.log('indexedOut: ', indexedCategoriesOut);

    Object.keys(indexedCategoriesOut).forEach(key => {
      const currentOut: any = indexedCategoriesOut[key];
      const currentIn = this.indexedCategoriesIn[key];
      const toUpdate = currentOut[0].category;
      const balanceOut = parseFloat((+_.sumBy(currentOut, 'debit') - +_.sumBy(currentOut, 'credit')).toFixed(4)) * this.getSign(currentOut);
      if (this.indexedCategoriesIn.hasOwnProperty(key)) {
        const balanceIn = parseFloat((+_.sumBy(currentIn, 'debit') - +_.sumBy(currentIn, 'credit')).toFixed(4)) * this.getSign(currentIn);
        console.log('La cuenta permanece en el asiento: ', currentOut);
        if (balanceOut !== balanceIn) {
          toUpdate.balance += balanceIn * (this.getSign(currentIn) * this.getSign(currentIn));
          toUpdate.balance += balanceOut * this.getSign(currentOut);
          console.log('Cambio el valor: ', balanceOut, balanceIn);
          this._categoryService.editCategory(toUpdate._id, toUpdate).subscribe(response => {
            console.log('Cuenta existente actualizada: ', response);
          }, err => console.error('Error al editar cuenta removida: ', err));
        } else {
          console.log('No Cambio el valor: ', balanceOut, balanceIn);
        }
        delete this.indexedCategoriesIn[key];
      } else {
        console.log('La cuenta no estaba en el asiento: ', currentOut);
        toUpdate.balance += balanceOut * this.getSign(currentOut);
        this._categoryService.editCategory(toUpdate._id, toUpdate).subscribe(response => {
          console.log('Cuenta nueva agregada: ', response);
        }, err => console.error('Error al editar cuenta removida: ', err));
      }
    });
    Object.keys(this.indexedCategoriesIn).forEach(key => {
      const current = this.indexedCategoriesIn[key];
      const balance = parseFloat((+_.sumBy(current, 'debit') - +_.sumBy(current, 'credit')).toFixed(4)) * this.getSign(current);
      const toUpdate = current[0].category;
      toUpdate.balance += balance * (this.getSign(current) * this.getSign(current));
      this._categoryService.editCategory(toUpdate._id, toUpdate).subscribe(response => {
        console.log('Cuenta removida actualizada: ', response);
      }, err => console.error('Error al editar cuenta removida: ', err));
    });
    console.log('Cuentes eliminadas del asiento: ', this.indexedCategoriesIn);
  }

  getSign(item: any): number {
    const sign = item[0].category.description === 'Pasivos' ||
      item[0].category.description === 'Capital' ||
      item[0].category.description === 'Ingresos' ? -1 : 1;
    return sign;
  }

  // ? ---------------------------- FORMULARIO -----------------------------
  createForm(): void {
    this.seatForm = this.fb.group({
      date_voucher:  [Date(), Validators.required],
      description:   [''],
      // tag:           ['Varios']
    });
  }

  addUnit() {
    console.log('new item');
    const obj = {
      name:         '',
      category:     null,
      description:  '',
      debit:        0,
      credit:       0
    };
    this.seatCategoryData =  this.seatCategoryData.concat(obj);
  }

  removeUnit(i: number) {
    const data1 = this.seatCategoryData.slice(0, i);
    const data2 = this.seatCategoryData.slice(i + 1, this.seatCategoryData.length);
    const data = data1.concat(data2);
    this.seatCategoryData = [];
    this.seatCategoryData = data;
    this.sumValues();
  }

  clearAllUnits() {
    this.seatCategoryData = [];
    this.addUnit();
    this.sumValues();
  }
  // * ---------------------------- FORMULARIO FIN -----------------------------


  // ? -------------------------- Scroll button ---------------------------------
  private scrollTable = function(event) {
    /* const btn = window.document.getElementById('moreItems');
    const scrollTop =  (event.target as HTMLTextAreaElement).scrollTop;
    const height = (event.target as HTMLTextAreaElement).scrollHeight;
    if ((height - 1200) >= scrollTop) {
      btn.style.display = 'block';
    } else {
      btn.style.display = 'none';
    } */
  };

  goPosition(direction: string): void {
    // const element = window.document.getElementById('generalContent');
    // element.scrollTo(0, element.scrollHeight);
  }

  ngOnDestroy(): void {
    // window.document.getElementById('generalContent').removeEventListener('scroll', this.scrollTable);
  }

  // * ---------------------- Scroll button fin --------------------------------

  // ! --------------------------- EXPORTAR ASIENTO DESDE EXCEL --------------------------
  saveFile(file): void {
    this.selectedFile = file;
    console.log('File: ', file);
  }

  convert(): void {
    if (this.selectedFile) {
      const indexedCategories = this.data.reduce((acc, curr) => ({
        ...acc,
        [curr.code]: curr
      }), {});

      const fileReader = new FileReader();
      fileReader.readAsBinaryString(this.selectedFile);
      fileReader.onload = (event: Event) => {
          const data = fileReader.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const myData: any[] = XLSX.utils.sheet_to_json(workbook.Sheets.A);
          const seatToSend: any = {};
          console.log('indexed: ', indexedCategories);
          seatToSend['description'] = myData[0].description;
          seatToSend['date_voucher'] = myData[0].date_voucher;
          seatToSend['total_credit'] = +_.sumBy(myData, 'credit').toFixed(2);
          seatToSend['total_debit'] = +_.sumBy(myData, 'debit').toFixed(2);
          const SeatCategory: any[] = [];
          const categoriesEdit: any[] = [];
          const checked = [];
          myData.forEach(current => {
            if (indexedCategories.hasOwnProperty(current.category)) {
              const category = indexedCategories[current.category];
              if (!checked.some(code => code === category.code)) {
                checked.push(category.code);
              } else {
                console.error('CODIGO REPETIDO: ', category.code);
              }
              // console.log('currentC: ,', Object.assign({}, category), 'key: ', current);
              const tempObj: any = {};
              const currentSign =  category.description === 'Pasivos' ||
              category.description === 'Capital' ||
              category.description === 'Ingresos' ? -1 : 1;
              tempObj['category'] = category._id;
              tempObj['credit'] = current.credit;
              tempObj['debit'] = current.debit;
              tempObj['description'] = current.description_category;
              const saldo = (current.debit - current.credit) * currentSign;
              SeatCategory.push(tempObj);
              categoriesEdit.push(category);
            } else {
              console.log('Cuenta no existe: ', current);
            }
          });
          seatToSend['SeatCategory'] = SeatCategory;
          this.seatToEdit.SeatCategory = SeatCategory;
          // console.log('MyData: ', myData);
          console.log('toSend: ', seatToSend);
          console.log('categoriesEdit: ', categoriesEdit);
          this.seatToEdit.total_credit = seatToSend.total_credit;
          this.seatToEdit.total_debit = seatToSend.total_debit;
          this.seatToEdit.date_voucher_n = new Date (this.seatToEdit.date_voucher).getTime();
          console.log('seatToEdit: ', this.seatToEdit);
          // this.seatForm.get('SeatCategory').setValue(SeatCategory);
          /* this._seatService.editSeat(this.seatToEdit._id, this.seatToEdit).subscribe(response => {
            console.log('Asiento agregado: ', response);
          }, err => console.error('Error al agregar el asiento: ', err)); */
      };
    }
  }
}
