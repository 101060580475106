import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CashCount } from '../models/cash-count';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class CashCountsService {
  public url: string;
  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url + '/cash-counts';
  }

  addCashCount(cashCount: CashCount): Observable<any> {
    console.log(cashCount);
    return this.http.post(`${this.url}/new`, cashCount);
  }

  getCashCount(cashCountId: string): Observable<any> {
    return this.http.get(`${this.url}/${cashCountId}`);
  }

  getCashCounts(): Observable<any> {
    return this.http.get(`${this.url}/list`);
  }

  getCashCountsLast(cashCountId: string): Observable<any> {
    return this.http.get(`${this.url}/list/${cashCountId}`);
  }

  userAssigned(userId: string): Observable<any> {
    return this.http.get(`${this.url}/user-assigned/${userId}`);
  }

  updateCashCount(cashCountId: string, cashCount: any): Observable<any> {
    return this.http.put(`${this.url}/${cashCountId}`, cashCount);
  }

}
