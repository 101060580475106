import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { CustomerService } from '../../../services/customer.service';
import { Customer } from '../../../models/customer';

@Component({
  selector: 'app-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['./customer-add.component.css'],
  providers: [UserService, CustomerService]
})
export class CustomerAddComponent implements OnInit {

  public titulo = 'Agregar cliente';
  public customer: any = {};
  public identity;
  public harvest;
  public mask = ['(', '5', '0', '4', ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private _userService: UserService,
    private _certificationService: CustomerService,
    public dialogRef: MatDialogRef<CustomerAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.titulo = 'Agregar Cliente';
    this.identity = this._userService.getCompany();
    this.customer = {} as Customer;
  }

  ngOnInit() {
    this.identity = this._userService.getCompany();
    this.harvest = this.identity.harvest;
  }

  submitForm() {
    this._certificationService.addCustomer(this.customer).subscribe(response => {
      this.dialogRef.close();
      }, error => console.error('Error al agregar cliente: ', error)
    );
  }

}
