import { CategoriesComponent } from './categories/categories.component';
import { AngularTreeGridModule} from 'angular-tree-grid';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule, Http } from '@angular/http';
import { NgModule } from '@angular/core';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { TextMaskModule } from 'angular2-text-mask';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatSelectSearchModule } from '../mat-select-search/mat-select-search.module';
import { MaterialModule } from '../../material-module';
import { MatTreeModule, MatIconModule, MatButtonModule } from '@angular/material';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { PipesModule } from '../../pipes/pipe.module';
import { NgxMaskModule } from 'ngx-mask';
import { ModuleRouting } from './accounting.routing';

import { BalanceSheetComponent } from './balance-sheet/balance-sheet.component';
import { CategoriesTreeComponent } from './categories-tree/categories-tree.component';
import { CodeComponent } from './ledger/code/code.component';
import { ClosingComponent } from './closing/closing.component';
import { ClosePeriodComponent } from './period-closings/close-period/close-period.component';
import { DailybookComponent } from './dailybook/dailybook.component';
import { LedgerComponent } from './ledger/ledger.component';
import { LedgerDetailComponent } from './ledger-detail/ledger-detail.component';
import { PrintModalComponent } from './ledger-detail/print-modal/print-modal.component';
import { StatementOfIncomeComponent } from './statement-of-income/statement-of-income.component';
import { SeatingComponent } from './seating/seating.component';
import { SeatAddComponent } from './seat-add/seat-add.component';
import { SeatEditComponent } from './seat-edit/seat-edit.component';
import { SeatDetailComponent } from './seat-detail/seat-detail.component';
import { SelectCategoryComponent } from './seat-edit/select-category/select-category.component';
import { TrialBalanceComponent } from './trial-balance/trial-balance.component';

import { CategoryService } from '../../services/category.service';
import { ExcelService } from '../../services/excel.service';
import { ImportDataService } from '../../services/import-data.service';
import { PrintService } from '../../services/print.service';
import { UserService } from '../../services/user.service';
import { SeatService } from '../../services/seat.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PeriodClosingListComponent } from './period-closings/period-closing-list/period-closing-list.component';
import { PeriodClosingViewComponent } from './period-closings/period-closing-view/period-closing-view.component';


@NgModule({
  declarations: [
    BalanceSheetComponent,
    CategoriesTreeComponent,
    ClosingComponent,
    ClosePeriodComponent,
    CodeComponent,
    DailybookComponent,
    LedgerComponent,
    LedgerDetailComponent,
    PrintModalComponent,
    StatementOfIncomeComponent,
    SeatAddComponent,
    SeatEditComponent,
    SeatingComponent,
    SeatDetailComponent,
    SelectCategoryComponent,
    TrialBalanceComponent,
    CategoriesComponent,
    PeriodClosingListComponent,
    PeriodClosingViewComponent
  ],
  exports: [
    CategoriesTreeComponent,
  ],
  imports: [
    CommonModule,
    ModuleRouting,
    MaterialModule,
    MatIconModule,
    MatButtonModule,
    MatTreeModule,
    AngularTreeGridModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // tslint:disable-next-line: deprecation
    HttpModule,
    MatSelectSearchModule,
    MatFormFieldModule,
    MatInputModule,
    TableVirtualScrollModule,
    TextMaskModule,
    PipesModule,
    NgxMaskModule.forRoot(),
    NgbModule
  ],
  entryComponents: [
    ClosingComponent,
    CodeComponent,
    PrintModalComponent,
    ClosePeriodComponent,
  ],
  providers: [
    CurrencyPipe,
    PrintService,
    SeatService,
    CategoryService,
    ExcelService,
    ImportDataService,
    UserService,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
})
export class AccountingModule { }
