import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrintService } from '../../../services/print.service';

@Component({
  selector: 'app-weight-note-print',
  templateUrl: './weight-note-print.component.html',
  styleUrls: ['./weight-note-print.component.css']
})
export class WeightNotePrintComponent implements OnInit {
  public form: FormGroup;
  public toPrint: string[] = [];

  constructor(
    private fb: FormBuilder,
    private _printService: PrintService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      cod: ['', Validators.required]
    });
  }

  addRow = (): void => {
    if (this.form.invalid) return;
    this.toPrint.push(String(this.form.value.cod));
    this.form.reset();
  }

  removeRow = (index: number): void => {
    this.toPrint.splice(index, 1);
  }

  printCodes = (): void => {
    this._printService.getMultipleWn(this.toPrint);
  }

}
