import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSort, MatPaginator } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import * as _moment from 'moment';
import * as _ from 'lodash';

import { DriedService } from '../../../services/dried.service';
import { InventoryService } from '../../../services/inventory.service';
import { MachineService } from '../../../services/machine.service';
import { WarehouseService } from '../../../services/warehouse.service';

import { Dried } from '../../../models/dried';
import { Inventory } from '../../../models/inventory';
import { Machine } from '../../../models/machine';
import { Warehouse } from '../../../models/warehouse';

@Component({
  selector: 'app-dried-update',
  templateUrl: './dried-update.component.html',
  styleUrls: ['./dried-update.component.css'],
})
export class DriedUpdateComponent implements OnInit, AfterViewInit {

  public title = 'Editar orden de secado';
  public displayedColumns1: string[] = ['cod', 'date', 'coffeefarmer', 'origin', 'total_qq_dry', 'action'];
  public form: FormGroup;
  public currentCapacity: any = '0';
  public totalSelected = 0;
  public alert = { message: '', color: 'info' };
  public tableRef: HTMLElement;

  public availableWarehouses: Warehouse[] = [];
  public warehousesFiltered: Warehouse[] = [];
  public availableMachines: Machine[] = [];
  public machinesFiltered: Machine[] = [];
  public next = false;
  private dried: Dried;
  private warehouseCount = 0;
  private wet_qqCount = 0;
  public indexSelected: any = {};

  public page1 = 0;
  public page2 = 0;
  public limit = 50;
  public direction = 'right';
  public lenData = 0;

  public availableWeightNotes: Inventory[] = [];
  public highlightedRows = [];
  public paginatedHighlightedRows = [];
  public lenData1 = 0;
  public stepperParams = {
    'steps': ['Detalle', 'Notas de Peso', 'Notas Seleccionadas'],
    'step': 1
  };
  constructor(
    private _warehouseService: WarehouseService,
    private _inventoryService: InventoryService,
    private _driedService: DriedService,
    private _machineService: MachineService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.getDried();
  }

  ngOnInit() {
    this.createForm();
    this.listenerFormValue();
    // this.paginator1._intl.itemsPerPageLabel = 'Notas de Peso por página';
  }

  ngAfterViewInit(): void {
    this.tableRef = window.document.getElementById('matTableDriedAdd');
  }

  private getDried(): void {
    const id = this.route.snapshot.paramMap.get('id');
    console.log('id: ', id);
    this._driedService.getDried(id).subscribe(res => {
      this.dried = res;
      this.dried.machine = this.dried.machine !== undefined ? this.dried.machine._id : '';
      this.dried.warehouse = this.dried.warehouse !== undefined ? this.dried.warehouse._id : '';
      this.form.patchValue(this.dried);
      console.log('patchValue', this.form.value);
      this.getDataFromServices();
    }, err => console.error('Error al obtener orden: ', err));
  }

  private getDataFromServices() {
    this._warehouseService.getWarehouses().subscribe(warehouse => {
      this.availableWarehouses = warehouse as Warehouse[];
      this.warehousesFiltered = warehouse as Warehouse[];
    });

    this._machineService.getMachines().subscribe(machine => {
      this.availableMachines = machine as Machine[];
      this.machinesFiltered = machine as Machine[];
    });
    setTimeout(() => {
      this.highlightedRows = this.dried.weight_notes;
      this.highlightedRows.forEach((wn, index) => {
        wn.total_qq_dry += this.dried.driedqq[index];
        wn['net_qq'] = this.dried.driedqq[index];
        this.indexSelected[wn._id] = this.dried.driedqq[index];
      });
      this.getHighlighteds();
      this.getWeightNotesByWarehouse(this.dried.warehouse);
    }, 100);
  }

  private listenerFormValue(): void {
    this.form.valueChanges.subscribe(() => {
      if (
        this.form.get('date').valid &&
        this.form.get('hours').valid &&
        this.form.get('warehouse').valid &&
        this.form.get('machine').valid &&
        this.form.get('start_temp').valid &&
        this.form.get('final_temp').valid
        ) {
        this.next = true;
      } else {
        this.next = false;
      }
    });

    this.form.get('machine').valueChanges.subscribe((machineId: string) => {
      this._machineService.getMachine(machineId).subscribe(machine_ => {
        const machine: Machine = machine_.machine;
        console.log('Maquina obtenida: ', machine);
        this.currentCapacity = machine.capacity;
      });
    });

    this.form.get('warehouse').valueChanges.subscribe((warehouse) => {
      if (this.warehouseCount > 0) {
        this.page1 = 0;
        this.highlightedRows = [];
        this.indexSelected = {};
        this.form.get('wet_quintals').setValue(0);
        this.getWeightNotesByWarehouse(warehouse);
      } else {
        this.warehouseCount += 1;
      }
    });

    this.form.get('wet_quintals').valueChanges.subscribe(value => {
      if (this.wet_qqCount > 0) {
        if (value > this.totalSelected) {
          this.form.controls['wet_quintals'].setErrors({'incorrect': true});
          this.alert.message = this.wet_qqCount > 0 ? 'Cantidad de qq exceden el total seleccionados.' : '';
          this.alert.color = this.wet_qqCount > 0 ? 'danger' : 'info';
        } else {
          this.alert.message = '';
          this.alert.color = 'info';
          this.form.controls['wet_quintals'].clearValidators();
          this.form.controls['wet_quintals'].setValidators(Validators.required);
        }
      } else {
        this.wet_qqCount += 1;
      }
    });
  }

  getWeightNotesByWarehouse(id: string): void {
    this._inventoryService.getNonDried({
      ids: [],
      warehouse: id,
      skip: this.page1 * this.limit,
      limit: this.limit
    }).subscribe(res => {
      console.log('Inventerio por bodega: ', res);
      this.lenData = res.count;
      this.availableWeightNotes = res.items as Inventory[];
      this.availableWeightNotes.forEach(item => {
        if (this.indexSelected.hasOwnProperty(item._id) && !isNaN(this.indexSelected[item._id]) ) {
          item.total_qq_dry += this.indexSelected[item._id];
        }
      });
      this.alert.message =  this.availableWeightNotes.length === 0 ? `No hay Notas de Peso en la Bodega` : '';
    }, err => console.error('Error al obtener notasd de peso: ', err));
  }

  public getHighlighteds(): void {
    const left = this.page2 * this.limit;
    const right = (this.page2 * this.limit) + this.limit;
    console.log('left: ', left, 'right: ', right);
    this.paginatedHighlightedRows = this.highlightedRows.slice(left, right);
    this.totalSelected = _.sumBy(this.highlightedRows, 'total_qq_dry');
    this.form.get('wet_quintals').setValue(_.sumBy(this.highlightedRows, 'net_qq'));
  }

  public changeStep(direction: string, step: number): void {
    this.stepperParams.step = step;
    this.direction = direction;
    console.log('change step: ', direction, this.stepperParams);
  }

  changePage(paginator: MatPaginator, table: number): void {
    if (table === 1) {
      window.document.getElementById('containerList1').scrollTo(0, 0);
      this.page1 = paginator.pageIndex;
      this.getWeightNotesByWarehouse(this.form.get('warehouse').value);
    } else {
      window.document.getElementById('containerList2').scrollTo(0, 0);
      this.page2 = paginator.pageIndex;
      this.getHighlighteds();
    }
  }

  public getSum(): void {
    this.form.get('wet_quintals').setValue(_.sumBy(this.highlightedRows, 'net_qq'));
  }

  public highlight(wn: any): void {
    wn['net_qq'] = wn.hasOwnProperty('net_qq') ? wn.net_qq : wn.total_qq_dry;

    // * Remover el elemento.
    if (this.indexSelected.hasOwnProperty(wn._id)) {
      delete this.indexSelected[wn._id];
      const ref = document.getElementById(`item-${wn._id}`);
      if (ref !== null) { ref.classList.add('animate__fadeOutRightBig'); }
      setTimeout(() => {
        this.highlightedRows = this.highlightedRows.filter(item => item._id !== wn._id);
        this.getHighlighteds();
      }, 300);
      wn.net_qq = wn.total_qq_dry;
    } else {
      this.indexSelected[wn._id] = true;
      this.highlightedRows = this.highlightedRows.concat(wn);
      this.getHighlighteds();
    }
    console.log('1: ', this.availableWeightNotes.length);
    console.log('2: ', this.highlightedRows.length);
  }

  public editDried() {
    const dried: Dried = Object.assign(_.cloneDeep(this.dried), this.form.getRawValue());
    console.log('dried: ', dried);
    this.highlightedRows.forEach(item => {
      item.net_qq = item.total_qq_dry >= item.net_qq ? item.net_qq : item.total_qq_dry;
    });
    dried.wet_quintals = parseFloat(Number(_.sumBy(this.highlightedRows, 'net_qq')).toFixed(6));
    dried.weight_notes = this.highlightedRows.map(item => item._id);
    dried.driedqq = this.highlightedRows.map(item => item.net_qq);
    dried.restqq = this.highlightedRows.map((item) => parseFloat(Number(item.total_qq_dry - item.net_qq).toFixed(4)));
    this.updateDried(dried);
  }

  private updateDried(dried): void {
    this._driedService.editDried(dried._id, dried).subscribe(response => {
      this.router.navigateByUrl('ordenes-secado');
      this.updateDataWn(dried);
      this.returnItems();
      console.log('Orden de secado editada: ', response);
    });
  }

  private updateDataWn(dried: any): void {
    this.highlightedRows.forEach(item => {
      dried['partialData'] = dried.hasOwnProperty('partialData') ? dried.partialData : {dry: false, settlement: false};
      if (item.total_qq_dry > item.net_qq) {
        item.dried = false;
        item.partialData.dry = true;
      } else {
        item.dried = true;
        item.partialData.dry = false;
      }
      item.total_qq_dry = parseFloat(Number(item.total_qq_dry - item.net_qq).toFixed(6));
      item.total_qq_dry = item.total_qq_dry < 0 ? 0 : item.total_qq_dry;
      this._inventoryService.editInventory(item._id, item).subscribe(response => {
        console.log('Inventario editado: ', response);
      }, err => console.error('Error al editar inventario: ', err));
    });
  }

  returnItems(): void {
    const substract: any =  _.differenceBy(this.dried.weight_notes, this.highlightedRows, '_id');
    console.log('A devolver: ', substract);
    if (substract.length > 0) {
      substract.forEach(item => {
        if (item.total_qq_dry === 0) {
          item.dried = true;
          item.partialData.dry = false;
        } else if (item.total_qq_dry === item.total_net_qq ) {
          item.dried = false;
          item.partialData.dry = false;
        } else {
          item.dried = false;
          item.partialData.dry = true;
        }
        item.total_qq_dry = item.total_qq_dry < 0 ? 0 : item.total_qq_dry;
        this._inventoryService.editInventory(item._id, item).subscribe(res => {
          console.log('Registro devuelto: ', res);
        }, err => console.error('Error al devolver registro: ', err));
      });
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      date:           ['', Validators.required],
      hours:          ['', Validators.required],
      warehouse:      ['', Validators.required],
      wet_quintals:   [0,  Validators.required],
      start_temp:     ['', Validators.required],
      final_temp:     ['', Validators.required],
      machine:        ['', Validators.required],
      days:           [''],
      warehouseName:  [''],
      machineName:    [''],
      observations:   [''],
    });
  }

}

