import { Component, OnInit } from '@angular/core';

import { Leftover } from '../../../../models/leftover';
import { LeftoversService } from '../../../../services/leftovers.service';
import { AppSidebar } from '../../../../models/sidebar';
import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { filter } from 'lodash';

@Component({
  selector: 'app-leftover-list',
  templateUrl: './leftover-list.component.html',
  styleUrls: ['./leftover-list.component.css']
})
export class LeftoverListComponent implements OnInit {
  public list = [1, 2, 3, 4, 5, 6, 7, 8];
  public leftovers: Leftover[] = [];
  public assign;
  public lotToAssign;
  public sidebars: AppSidebar[] = [
    { name: 'leftover-add',        title: 'Asignar sobrantes', layout: {
      width: 30, height: 'auto', position: 'right-top-y'
    }},
  ];

  constructor(
    public _sidebar: SidebarManagerService,
    private _leftover: LeftoversService,
  ) {
    this._sidebar.setSidebarsList(this.sidebars);
    this.assign = this._sidebar.getInput('leftover-list');
    console.log('prod to assign', this.assign);
  }

  ngOnInit() {
    this.getLeftovers();
  }

  getLeftovers = (): void => {
    this._leftover.getLeftovers().subscribe((res: Leftover[]) => {
      this.leftovers = res;
      console.log('sobrantes', res);
      const i = _.findIndex(res, { prod: { _id: this.assign.prod._id }});
      console.log('i', i);
      this.leftovers.splice(i, 1);
      // this.leftovers = this.leftovers.filter(res => res.quality );
    }, (error: HttpErrorResponse) => console.error('Error al obtener sobrantes', error));
  }

  openAddLeftover = (leftover: Leftover): void => {
    this._sidebar.open('leftover-add', {
      leftover,
      prod: this.assign
    });
    this._sidebar.afterClosed('leftover-add').subscribe((res) => {
      if (res) this.getLeftovers();
    });
  }

}
