import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatDialog } from '@angular/material';

import { ProductorService } from '../../../services/productor.service';
import { SettlementService } from '../../../services/settlement.service';

import { CoffeeFarmer } from '../../../models/productor';

@Component({
  selector: 'app-productor-detalle',
  templateUrl: './detalle-productor.component.html',
  styleUrls: ['./detalle-productor.component.css'],
})

export class ProductorDetalleComponent implements OnInit {

  public date;
  public coffeefarmer: any = {};
  public total_qq;
  public id;
  public total_net;

  constructor(
    private _route: ActivatedRoute,
    private _productorService: ProductorService,
    private _settlementService: SettlementService,
    public dialog: MatDialog,
  ) {
    this.id = this._route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.getProductor(this.id);
    this.getTotal_qqLiquidated(this.id);
  }

  getProductor(id) {
    this._productorService.getProductor(id).subscribe((response: CoffeeFarmer) => {
      console.log('coffeefarmer: ', response);
      this.coffeefarmer = response;
    }, error => console.error('Error al obtener productor: ', error));
  }

  getTotal_qqLiquidated(id) {
    this._settlementService.getSettlementPerCoffeeFarmerTqq(id).subscribe(res => {
      this.total_qq = +res.total_qq.toFixed(2);
      this.total_net = +res.total_net.toFixed(2);
    });
  }

}
