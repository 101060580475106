interface Descriptor {
    name: string;
    lvl?: number;
    items?: Descriptor[];
}

export const temp: Descriptor[] = [
    {
        name: 'Floral', lvl: 1, items: [
            { name: 'Té Negro', lvl: 2},
            { name: 'Floral', lvl: 2, items: [
                { name: 'Manzanilla', lvl: 3 },
                { name: 'Rosa', lvl: 3 },
                { name: 'Jazmin', lvl: 3 },
            ]}
        ]
    },
    {
        name: 'Afrutado', lvl: 1, items: [
            { name: 'Bayas', lvl: 2, items: [
                { name: 'Mora', lvl: 3 },
                { name: 'Arándano', lvl: 3 },
                { name: 'Fresa', lvl: 3 },
            ]},
            { name: 'Frutos Secos', lvl: 2, items: [
                { name: 'Pasas de Uva', lvl: 3 },
                { name: 'Pasas de Ciruela', lvl: 3 },
            ]},
            { name: 'Otras Frutas', lvl: 2, items: [
                { name: 'Coco', lvl: 3 },
                { name: 'Cereza', lvl: 3 },
                { name: 'Piña Ananas', lvl: 3 },
                { name: 'Uva', lvl: 3 },
                { name: 'Manzana', lvl: 3 },
                { name: 'Durazno', lvl: 3 },
                { name: 'Pera', lvl: 3 },
            ]},
            { name: 'Cítricos', lvl: 2, items: [
                { name: 'Pomelo', lvl: 3 },
                { name: 'Naranja', lvl: 3 },
                { name: 'Limón', lvl: 3 },
                { name: 'Lima', lvl: 3 },
            ]},
        ]
    },
    {
        name: 'Ácido/Fermentado', lvl: 1, items: [
            { name: 'Ácido', lvl: 2, items: [
                { name: 'Aromaticos Ácidos', lvl: 3 },
                { name: 'Ácido Acético', lvl: 3 },
                { name: 'Ácido Butírico', lvl: 3 },
                { name: 'Ácido Isovalérico', lvl: 3 },
                { name: 'Ácido Cítrico', lvl: 3 },
                { name: 'Ácido Malico', lvl: 3 },
            ]},
            { name: 'Alcohol/Fermentado', lvl: 2, items: [
                { name: 'Avinado', lvl: 3 },
                { name: 'Whisky', lvl: 3 },
                { name: 'Fermentado', lvl: 3 },
                { name: 'Sobre Maduro', lvl: 3 },
            ]},
        ]
    },
    {
        name: 'Verde/Vegetal', lvl: 1, items: [
            { name: 'Aceite de Oliva', lvl: 2 },
            { name: 'Crudo', lvl: 2 },
            { name: 'Leguminoso', lvl: 2 },
            { name: 'Verde/Vegetal', lvl: 2, items: [
                { name: 'Inmaduro', lvl: 3 },
                { name: 'Vaina', lvl: 3 },
                { name: 'Fresco', lvl: 3 },
                { name: 'Verde Oscuro', lvl: 3 },
                { name: 'Vegetal', lvl: 3 },
                { name: 'Heno', lvl: 3 },
                { name: 'Herbáceo', lvl: 3 },
            ]},
            { name: 'Alcohol/Fermentado', lvl: 2, items: [
                { name: 'Avinado', lvl: 3 },
                { name: 'Whisky', lvl: 3 },
                { name: 'Fermentado', lvl: 3 },
                { name: 'Sobre Maduro', lvl: 3 },
            ]},
        ]
    },
    {
        name: 'Otros', lvl: 1, items: [
            { name: 'Papel/Humedad', lvl: 2, items: [
                { name: 'Fenólico', lvl: 3 },
                { name: 'Carne/Caldo', lvl: 3 },
                { name: 'Animal', lvl: 3 },
                { name: 'Rancio/Tierra', lvl: 3 },
                { name: 'Rancio/Polvo', lvl: 3 },
                { name: 'Mohoso/Húmedo', lvl: 3 },
                { name: 'Madera', lvl: 3 },
                { name: 'Papel', lvl: 3 },
                { name: 'Carton', lvl: 3 },
                { name: 'Rancio', lvl: 3 },
            ]},
            { name: 'Químico', lvl: 2, items: [
                { name: 'Goma', lvl: 3 },
                { name: 'Zorrillo', lvl: 3 },
                { name: 'Petróleo', lvl: 3 },
                { name: 'Medicinal', lvl: 3 },
                { name: 'Salado', lvl: 3 },
                { name: 'Amaranto', lvl: 3 },
            ]},
        ]
    },
    {
        name: 'Tostado', lvl: 1, items: [
            { name: 'Tabaco', lvl: 2 },
            { name: 'Tabaco de Pipa', lvl: 2 },
            { name: 'Quemado', lvl: 2, items: [
                { name: 'Marron Tostado', lvl: 3 },
                { name: 'Humo', lvl: 3 },
                { name: 'Ceniza', lvl: 3 },
                { name: 'Acre', lvl: 3 },
            ]},
            { name: 'Cereal', lvl: 2, items: [
                { name: 'Malta', lvl: 3 },
                { name: 'Grano', lvl: 3 },
            ]},
        ]
    },
    {
        name: 'Especias', lvl: 1, items: [
            { name: 'Pimienta', lvl: 2 },
            { name: 'Acre', lvl: 2 },
            { name: 'Especias Marrones', lvl: 2, items: [
                { name: 'Clavo', lvl: 3 },
                { name: 'Canela', lvl: 3 },
                { name: 'Nuez Moscada', lvl: 3 },
                { name: 'Anís', lvl: 3 },
            ]},
            { name: 'Cereal', lvl: 2, items: [
                { name: 'Malta', lvl: 3 },
                { name: 'Grano', lvl: 3 },
            ]},
        ]
    },
    {
        name: 'Nueces/Cacao', lvl: 1, items: [
            { name: 'Cacao', lvl: 2, items: [
                { name: 'Chocolate Amargo', lvl: 3 },
                { name: 'Chocolate', lvl: 3 },
            ]},
            { name: 'Nueces', lvl: 2, items: [
                { name: 'Almendra', lvl: 3 },
                { name: 'Avellana', lvl: 3 },
                { name: 'Maní', lvl: 3 },
            ]},
        ]
    },
    {
        name: 'Dulce', lvl: 1, items: [
            { name: 'Aromaticos Dulces', lvl: 2 },
            { name: 'Dulce General', lvl: 2 },
            { name: 'Aroma de Vainilla', lvl: 2 },
            { name: 'Vainilla', lvl: 2 },
            { name: 'Azucar Morena', lvl: 2, items: [
                { name: 'Miel', lvl: 3 },
                { name: 'Caramelizado', lvl: 3 },
                { name: 'Jarabe de Arce', lvl: 3 },
                { name: 'Melaza', lvl: 3 },
            ]},
        ]
    },
];
let list: Descriptor[] = [];
const getList = (items) => {
    items.forEach(element => {
        if (element.items) getList(element.items);
        else list.push({ name: element.name });
    });
};

export const getDescriptorsList = (): Descriptor[] => {
    list = [];
    getList(temp);
    return list;
};
