import { Injectable } from '@angular/core';
import { AdvancePayment } from '../models/advancePayment';
import { AdvancePaymentService } from './advancePayment.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FixService {

  constructor(
    private advancePaymentService: AdvancePaymentService,
  ) {}

  fixAPBalanceByStatus(): void {
    this.advancePaymentService.getAdvancePayments().subscribe((response: AdvancePayment[]) => {
      response.forEach((ap: AdvancePayment) => {
        if (ap.payment_status === 'Pagado' && ap.total_balance !== 0) {
          console.log('Pagado y !== 0: ', ap);
          /* ap.total_balance = 0;
          ap.current_capital = 0;
          this.advancePaymentService.editAdvancePayment(ap._id, ap).subscribe(res => {
            console.log('Anticipo corregido: ', res);
          }, err => console.error('Error al corregir anticipo: ', err)); */
        }
        if (ap.payment_status === 'Pagado' && (ap.total_interest !== 0 || ap.current_capital !== 0)) {
          /* ap.total_balance = 0;
          ap.current_capital = 0;
          ap.total_interest = 0;
          this.advancePaymentService.editAdvancePayment(ap._id, ap).subscribe(res => {
            console.log('Anticipo corregido: ', res);
          }, err => console.error('Error al corregir anticipo: ', err)); */
          console.log('pagado y capital o interes !== 0: ', ap);
        }
      });
      // console.log('grouped: ', _.groupBy(response, 'type'));
    });
  }
}
