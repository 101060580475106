import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';

import { UserService } from '../../services/user.service';
import { FD3Service } from '../../services/rfa.service';
import { DriedService } from '../../services/dried.service';
import { Dried } from '../../models/dried';
import { FD3 } from '../../models/FD3';
import { InventoryService } from '../../services/inventory.service';
import { Inventory } from '../../models/inventory';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-fd33-edit',
  templateUrl: './fd33-edit.component.html',
  styleUrls: ['./fd33-edit.component.css'],
  providers: [UserService, FD3Service, DriedService, InventoryService]
})
export class Fd33EditComponent implements OnInit {

  public titulo: string;
  public sale: any = {};
  public fd3: any = {};
  public dried: any = {};
  public identity;
  public alertMessage;
  public valor: number;
  public valors = [];
  public _ids = [];
  public harvest;
  public wns = [];
  public _ids2 = [];
  public editIds = [];
  public editIds2 = [];
  public qqWNUpdate = [];
  public price;
  public total_qq;
  public current_qq;
  public disabled = false;
  public statusNP = false;
  public balances = [];
  public qqUpdate = [];
  public inventory: any = {};
  public id;
  disabledbtn1 = false;

  payments_status = [
    { value: 'Depósito', viewValue: 'Depósito' },
    { value: 'Parcial', viewValue: 'Parcial' },
    { value: 'Pagado', viewValue: 'Pagado' }
  ];

  // tslint:disable-next-line:max-line-length
  displayedColumns: string[] = ['cod', 'date', 'cod_nota', 'warehouse', 'coffeefarmer', 'total_net_qq', 'total_net_rfa'];
  highlightedRows = [];
  public rows = [];
  dataSource = new MatTableDataSource<Dried>();

  highlight(element: Element) {
    element.highlighted = !element.highlighted;
  }

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _fd3Service: FD3Service,
    private _driedService: DriedService,
    private _inventoryService: InventoryService,
    public dialogRef: MatDialogRef<Fd33EditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.identity = this._userService.getCompany();
    this.fd3 = {} as FD3;
    this.dried = {} as Dried;
    this.inventory = {} as Inventory;
  }

  ngOnInit() {
    this.identity = this._userService.getCompany();
    this.harvest = this.identity.harvest;
    this.id = this.data._id;
    const id = this.data._id;
    this.fd3 = this.data;
    this.getFd3();
    this.getInventories();
  }

  getInventories() {

    this._driedService.getDrieds().subscribe(
      (res) => {
        this.dataSource.data = res as Dried[];
        this.dataSource.data.forEach((element, index) => {
          this.dataSource.data[index].weight_notes.forEach((item, i) => {
            let weight = 0;
            if (item.total_net_rfa) {
              weight = item.total_net_rfa;
            } else {
              weight = 0;
            }
            const rows = {
              _id: element._id,
              id_wn: item._id,
              cod: element.cod,
              cod_nota: item.cod,
              date: item.date,
              warehouse: item.warehouse_name,
              coffeefarmer: item.coffeefarmer.name,
              total_net_rfa: weight,
              total_net_qq: item.total_net_qq,
              highlighted: false
            };
            if (item.rfa) { } else {
              if (item.rfa === false) {
                this.wns = this.wns.concat(rows);
              }
            }
          });
        });
        this.dataSource.data = this.wns;

        if (this.rows.length > 0) {
          this.dataSource.data = this.dataSource.data.concat(this.rows);
          // tslint:disable-next-line: max-line-length
          this.dataSource.data = this.dataSource.data.reduce((acc, cur) => acc.some(x => (x.id_wn === cur.id_wn)) ? acc : acc.concat(cur), []);
          this.dataSource.data.forEach((item: any, index) => {
            this.rows.forEach((editItem) => {
              if (item.cod_nota === editItem.cod_nota) {
                Object.assign(item, {
                  highlighted: true
                });
              }
            });
          });
        } else {
          console.log('volver hacer el get hasta que llene este arreglo');
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );

  }
  getFd3() {
    this.editIds = [];
    this.editIds2 = [];
    this.rows = [];

    this._fd3Service.getFD3(this.fd3._id).subscribe(
      response => {
        if (!response) {
          this._router.navigate(['/']);
        } else {
          this.fd3 = response;
        }
        this.fd3.weight_notes.forEach((item, index) => {
          let weight = 0;
          if (item.total_net_rfa) {
            weight = item.total_net_rfa;
          } else {
            weight = 0;
          }
          const rows = {
            _id: this.fd3.dry_notes[index]._id,
            id_wn: item._id,
            cod: this.fd3.dry_notes[index].cod,
            cod_nota: item.cod,
            date: item.date,
            warehouse: item.warehouse_name,
            coffeefarmer: item.coffeefarmer.name,
            total_net_rfa: weight,
            qqForWn: this.fd3.qqForWn[index],
            restForWn: this.fd3.restForWn[index],
            total_net_qq: item.total_net_qq,
            highlighted: true
          };
          this.rows.push(rows);
          this.selectRow(rows);
          this.editIds.push(rows._id);
          this.editIds2.push(rows);
        });
      }
    );
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  selectRow(row) {
    this.valors = [];
    this.balances = [];
    if (row.highlighted === true) {
      this.highlightedRows.push(row);
      this.pushIdsWN(this.highlightedRows);
    } else if (row.highlighted === false) {
      this.removeWN(this._ids, this._ids2, row);
    }
    this.calculateQQ();
  }

  partialWN() {
    this.qqUpdate = [];
    this.qqWNUpdate = [];
    this.total_qq = 0;
    this.total_qq = +this.fd3.total_qq;
    for (const balances of this.valors) {
      this.qqUpdate.push(0);
      this.qqWNUpdate.push(balances.total_net_qq);
    }
    this.fd3.total_kg = (this.fd3.total_qq * 100) / 2.2046;
  }

  partialNP() {
    this.statusNP = !this.statusNP;
    this.disabledbtn1 = true;
    this.qqWNUpdate = [];
    this.total_qq = 0;
    this.qqUpdate = [];
    this.total_qq = +this.fd3.total_qq;

    for (const balances of this.valors) {
      if (this.total_qq >= balances.total_net_qq) {
        this.total_qq = this.total_qq - balances.total_net_qq;
        const zero = 0;
        this.qqUpdate.push(zero);
        this.qqWNUpdate.push(balances.total_net_qq);
      } else if (balances.total_net_qq > this.total_qq) {
        this.total_qq = balances.total_net_qq - this.total_qq;
        const restQQ = this.total_qq - balances.total_net_qq;
        this.qqUpdate.push(this.total_qq);
        this.qqWNUpdate.push(-restQQ);
      }
    }
    this.fd3.total_kg = (this.fd3.total_qq * 100) / 2.2046;
  }
  calculateQQ() {
    // tslint:disable-next-line:forin
    for (const i in this.highlightedRows) {
      if (this.highlightedRows[i].total_net_rfa) {
        const valor = {
          total_net_qq: this.highlightedRows[i].total_net_rfa
        };
        this.valors.push(valor);
      } else {
        const valor = {
          total_net_qq: this.highlightedRows[i].total_net_qq
        };
        this.valors.push(valor);
      }
    }
    this.partialWN();
    this.fd3.total_qq = this.valors.reduce((sum, curr) => sum + curr.total_net_qq, 0).toFixed(2);
    this.fd3.total_kg = (this.fd3.total_qq * 100) / 2.2046;
  }
  calculateTotal(prime) {
    this.fd3.total = this.fd3.sub_total + prime;
  }
  removeWN(id, id_wn, row) {
    this._ids2.forEach((item, index) => {
      if (item._id === row.id_wn) {
        this.highlightedRows.splice(index, 1);
        this._ids2.splice(index, 1);
        this._ids.splice(index, 1);
        if (Object.keys(this.highlightedRows).length === 0) {
          this.highlightedRows = [];
        }
      }
    });
  }
  pushIdsWN(highlightedRows) {
    this._ids = [];
    this._ids2 = [];
    // tslint:disable-next-line:forin
    for (const i in highlightedRows) {
      const ids = {
        _id: highlightedRows[i]._id,
      };
      this._ids.push(ids);

      const ids2 = {
        _id: highlightedRows[i].id_wn,
      };
      this._ids2.push(ids2);
    }
  }
  update_dry() {
    let limit = 0;
    this._ids.forEach((item, index) => {
      limit++;
      if (this.qqUpdate[index] === 0) {
        this.dried.rfa = true;
      } else {
        this.dried.rfa = false;
      }
      this._driedService.editDried(item._id, this.dried).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Nota de Secado actualizada correctamente';
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );
    });
    if (limit === this._ids.length) {
      this.update_WNEdits();
    }
  }
  update_dryEdits() {
    let count = 0;
    let fd3;
    this.editIds.forEach((id, index) => {
      count++;
      this._ids.forEach(dry => {
        if (dry._id === id) {
          fd3 = true;
        }
      });
      if (fd3 !== true) {
        this.dried.rfa = false;
        this._driedService.editDried(id, this.dried).subscribe(
          response => {
            if (!response) {
              this.alertMessage = 'Error en el servidor';
            } else {
              this.alertMessage = 'Nota de Secado actualizada correctamente';
            }
          },
          error => {
            const errorMessage = <any>error;
            if (errorMessage != null) {
              const body = JSON.parse(error._body);
              this.alertMessage = body.message;
              console.log(error);
            }
          }
        );
      }
      if (count === this.editIds.length) {
        this.update_dry();
      }
    });
  }
  update_WNEdits() {
    let count = 0;
    this.editIds2.forEach((item, index) => {
      let fd3;
      let total_qq = 0;
      count++;
      total_qq = item.qqForWn + item.restForWn;
      this._ids2.forEach(wn => {
        if (wn._id === item.id_wn) {
          fd3 = true;
        }
      });
      if (fd3 !== true) {
        this.inventory.rfa = false;
        this._inventoryService.getInventory(item.id_wn).subscribe(response => {
          if (total_qq === response.total_net_qq) {
            this.inventory.rfa = false;
          } else {
            this.inventory.rfa = true;
          }
          this.inventory.total_net_rfa = total_qq;
          this._inventoryService.editInventory(item.id_wn, this.inventory).subscribe(
            res => {
              if (!res) {
                this.alertMessage = 'Error en el servidor';
              } else {
                this.alertMessage = 'Nota de peso actualizada correctamente';
              }
            }
          );
        });
      }
      if (count === this.editIds2.length) {
        this.update_wn();
      }
    });
  }
  update_wn() {
    let limit = 0;
    this._ids2.forEach((item, index) => {
      limit++;
      if (this.qqUpdate[index] === 0) {
        this.inventory.total_net_rfa = 0;
        this.inventory.rfa = true;
      } else {
        this.inventory.total_net_rfa = this.qqUpdate[index];
        this.inventory.rfa = false;
      }
      this._inventoryService.editInventory(item._id, this.inventory).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Nota de Secado actualizada correctamente';
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );
    });
    if (limit === this._ids2.length) {
      this._fd3Service.editFD3(this.fd3._id, this.fd3).subscribe(
        response => {
          this.alertMessage = 'Registro creado correctamente';
          this.onCancelClick();
        },
        error => {
          const errorMessage = <any>error;

          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );
    }
  }
  submitForm() {
    if (this.id) {
      const id = this.fd3._id;
      this.disabled = true;
      this.fd3.harvest = this.harvest;
      this.fd3.dry_notes = this._ids;
      this.fd3.weight_notes = this._ids2;
      this.fd3.qqForWn = this.qqWNUpdate;
      this.fd3.restForWn = this.qqUpdate;
      this.update_dryEdits();
    }
  }

}

export interface Element {
  checked: boolean;
  cod: number;
  date: string;
  fd3: any;
  wet_quintals: number;
  dry_qq: number;
  r_qq: number;
  highlighted?: boolean;
  hovered?: boolean;
}

