import { Component, OnInit } from '@angular/core';

import { TasterService } from '../../../../services/taster.service';
import { Taster } from '../../../../models/taster';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-taster-list',
  templateUrl: './taster-list.component.html',
  styleUrls: ['./taster-list.component.css']
})
export class TasterListComponent implements OnInit {
  public tasters: Taster[] = [];

  constructor(
    private _tasterService: TasterService,
  ) { }

  ngOnInit() {
    this.getTasters();
  }

  getTasters = (): void => {
    this._tasterService.getTasters().subscribe((res: Taster[]) => {
      this.tasters = res;
    }, (err: HttpErrorResponse) => console.error('Error al obtener catadores', err));
  }

}
