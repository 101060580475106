import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Category } from '../../../../models/category';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-select-category',
  templateUrl: './select-category.component.html',
  styleUrls: ['./select-category.component.css']
})
export class SelectCategoryComponent implements OnInit {
  public select = new FormControl();
  public form: FormGroup;
  public detailsCategories: Category[];
  public filteredCategories: Category[];
  public title = 'Seleccion de cuenta';
  public selected: any;
  constructor(
    public dialogRef: MatDialogRef<SelectCategoryComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    // console.log('categories: ', this.data);
    this.selected = this.data.current;
    this.filteredCategories = this.data.categories;
    this.detailsCategories = this.data.categories;
  }

  ngOnInit() {
    this.form = this.fb.group({
      categoryName: [''],
      category:     ['', Validators.required]
    });

    this.form.get('categoryName').valueChanges.subscribe((value: string) => {
      this.filteredCategories = this.detailsCategories
      .filter(category => String(category.code + category.name)
        .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .includes(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
          .sort((n1, n2) => n1.code - n2.code);
    });
    this.form.get('category').setValue(this.selected);
  }

  selectCategory(category: Category): void {
    this.form.get('category').setValue(category);
    this.selected = category;
    this.select.setValue(category.code);
    // console.log('selected: ', this.selected);
    this.dialogRef.close(category);
  }

  attributeDisplay(attribute1, attribute2) {
    if (attribute1 === this.selected.code) {
      console.log('att: ', attribute1, attribute2);
      return attribute1 + attribute2;
    }
  }

  // ! Sin uso
  setCategory(): void {
    console.log('form: ', this.form.value);
    this.dialogRef.close(this.form.get('category').value);
  }

}
