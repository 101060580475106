import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { InventoryService } from '../../../../services/inventory.service';


@Component({
  selector: 'app-new-value',
  templateUrl: './new-value.component.html',
  styleUrls: ['./new-value.component.css']
})
export class NewValueComponent implements OnInit {

  quintals;
  wn: any;
  constructor(
    private _inventoryService: InventoryService,
    public dialogRef: MatDialogRef<NewValueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log(this.data);
    this.quintals = this.data.quintals;

    this._inventoryService.getInventory(this.data._id).subscribe(res => {
      console.log(res);
      this.wn = res;
    });
  }
  onCloseConfirm() {
    this.dialogRef.close({res: 'confirm', id: this.data._id, quintals: this.quintals});
  }
  onCloseCancel() {
    this.dialogRef.close('cancel');
  }

}
