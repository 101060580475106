import { UserService } from './../../../services/user.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { MatDialog, MatSort } from '@angular/material';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { Category } from '../../../models/category';
import { Seat } from '../../../models/seat';

import { DatePipe } from '@angular/common';
import { ExcelService } from '../../../services/excel.service';
import * as XLSX from 'xlsx';

import { CategoryService } from '../../../services/category.service';
import { SeatService } from '../../../services/seat.service';
import { PrintModalComponent } from './print-modal/print-modal.component';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { getCurrency } from '../../../helpers/number';
import { sumBy } from 'lodash';

@Component({
  selector: 'app-ledger-detail',
  templateUrl: './ledger-detail.component.html',
  styleUrls: ['./ledger-detail.component.css'],
  providers: [SeatService, CategoryService, ExcelService, DatePipe ]
})

export class LedgerDetailComponent implements OnInit {
  private availableCategories: Category[] = [];
  public title = 'Libro Mayor';
  public alertMessage = '';
  public initialBalance = 0;
  public indexedVoucher = {};
  public seatForm: FormGroup;
  public currentSeatList: Seat;

  public detailsCategoriesData: Category[] = [];
  public filteredLedgerCategories: Category[] = [];
  public seatsData: Seat[] = [];
  public totals = {
    debit: 0,
    credit: 0,
  };
  public convertToExcel = [];
  public pipe = new DatePipe('es-HN');
  public currentPeriod: number;
  public periods: number[] = [];
  public seats: Seat[] = [];
  public tagColor = {
    'Abono': 'primary',
    // 'Gasto': 'danger',
    'Anticipo': 'success',
    'Liquidación': 'warning',
    'Varios': 'secondary',
  };
  public currency = getCurrency();

  constructor(
    public dialog: MatDialog,
    private _categoryService: CategoryService,
    private formBuilder: FormBuilder,
    private excelService: ExcelService,
    private _userService: UserService,
    private _seatService: SeatService,
  ) {
    this.currentPeriod = this._userService.getIdentity().company.settings.period;
    this.currentPeriod = this.currentPeriod ? this.currentPeriod : 1;
    for (let i = 1; i <= this.currentPeriod; i ++) this.periods.push(i);
  }

  ngOnInit() {
    this.createForm();
    this.seatForm.get('period').setValue(this.currentPeriod);
    this.listenersValues();
    this.getDataFromServices();
  }

  createForm(): void {
    this.seatForm = this.formBuilder.group({
      category:     ['5f9b31a88fc7672ae136d1ff', Validators.required],
      categoryName: [''],
      date_in:      [''],
      date_out:     [''],
      period:       ['', Validators.required]
    });
  }

  getDataFromServices(): void {
    this._categoryService.getLedgerCategories().subscribe(res => {
      this.availableCategories = res;
      this.filteredLedgerCategories = res;
    });
  }

  listenersValues(): void {
    this.seatForm.get('categoryName').valueChanges.subscribe(value => {
      this.filteredLedgerCategories = this.availableCategories
      .filter(category => String(category.code + category.name)
        .toLowerCase().includes(value.toLowerCase()))
          .sort((n1, n2) => n1.code - n2.code);
    });
  }

  searchSeating(): void {
    const params = this.setDataFilter();
    delete params['categoryName'];
    console.log('params: ', params);
    this._seatService.getLedgerCategory(params).subscribe(res => {
      this.initialBalance = res.initial;
      this.seats = res.seats;
      this.totals.debit = sumBy(this.seats, 'total_debit');
      this.totals.credit = sumBy(this.seats, 'total_credit');
    }, err => console.error('Error al obtener el mayor: ', err));

  }

  // * --------------------------- SECONDARY FUNCTION ----------------------- */
  exportAsXLSX(): void {
    this.convertToExcel = this.seats;
    this.convertToExcel.forEach((element, i) => {
      const newDate = this.pipe.transform(element.date_voucher, 'shortDate');
      if (element === undefined) {
        console.error('Undefined element: ', element, i);
      }

        const rows = {
          Número: element.number,
          Código: element.abbreviation + '-' + element.code,
          Fecha: newDate,
          Cosecha: element.harvest,
          descripción: element.description,
          Total_Crédito: element.total_credit.toFixed(2),
          Total_Débito: element.total_debit.toFixed(2),
          Saldo: element.balance.toFixed(2),
        };

        const convertToExcel = Object.assign([], this.convertToExcel, { [i]: rows });
        this.convertToExcel = convertToExcel;

    });

    console.log('ToExcelMayor: ', this.convertToExcel);
    this.excelService.exportAsExcelFile(this.convertToExcel, 'Libro Mayor');
  }

  printReport() {
    const dialogRef = this.dialog.open(PrintModalComponent, {
      width: '650px',
      height: '600px',
      data: {
        selected: this.seatForm.get('category').value,
        periods: this.periods
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      console.log('Cerrando ventana print: ', response);
    });
  }

  openCategoriesModal(seat: Seat): void {
    this.currentSeatList = seat;
  }

  setDataFilter(): void {
    const params = this.seatForm.getRawValue();
    console.log('form', params);
    Object.keys(params).forEach(key => {
      const current = params[key];
      if (current === '' || current === null || current === undefined) {
        delete params[key];
      } else {
        if (key === 'date_out') {
          const temp = new Date(current);
          temp.setHours(23, 59, 59);
          params[key] = temp;
        }
      }
    });
    return params;
  }

}
