import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { GLOBAL } from './global';
import { AdvancePayment } from '../models/advancePayment';
import { PaymentService } from './payment.service';
import { Payment } from '../models/payment';
import * as _ from 'lodash';
import { differenceInDays } from 'date-fns';

@Injectable()
export class AdvancePaymentService {
  public url: string;
  public dayDiff = false;

  constructor(
    private http: HttpClient,
    private paymentService: PaymentService,
    ) {
    this.url = GLOBAL.url;
  }

  getAdvancePayments(): Observable<any> {
    return this.http.get(`${this.url}/advance_payment/list`);
  }

  getAllAdvancePayments(): Observable<any> {
    return this.http.get(`${this.url}/advance_payment/list-all`);
  }

  addAdvancePayment(advancePayment: AdvancePayment): Observable<any> {
    return this.http.post(`${this.url}/advance_payment/new`, advancePayment);
  }

  getAdvancePerCoffeeFarmer(coffeefarmer): Observable<any> {
    return this.http.get(
      `${this.url}/advance_payment/coffeefarmer-val/${coffeefarmer}`
    );
  }

  getAdvancePayment(id): Observable<any> {
    return this.http.get(`${this.url}/advance_payment/only_one/${id}`);
  }

  getAdvancePaymentPerCoffeeFarmer(coffeefarmer): Observable<any> {
    return this.http.get(
      `${this.url}/advance_payment/coffeefarmer/${coffeefarmer}`
    );
  }

  getAdvancePerWeightNotes(weight_notes): Observable<any> {
    return this.http.get(
      `${this.url}/advance_payment/weight_notes/${weight_notes}`
    );
  }

  getBalancePerCoffeefarmer(coffeefarmer): Observable<any> {
    return this.http.get(`${this.url}/advance_payment/balance-per-coffeefarmer/${coffeefarmer}`);
  }
  editAdvancePayment(id, advancePayment): Observable<any> {
    return this.http.put(`${this.url}/advance_payment/${id}`, advancePayment);
  }
  deleteAdvancePayment(id): Observable<any> {
    return this.http.delete(`${this.url}/advance_payment/${id}`);
  }
  getTotalAdvancePaymentPerHarvest(harvest: string): Observable<any> {
    const param = harvest.replace('/', '-');
    return this.http.get(`${this.url}/advance_payment/sum-per-harvest/${param}`);
  }
  getTotalOpeningDiscounted(id): Observable<any> {
    return this.http.get(`${this.url}/advance_payment/total-discounted/${id}`);
  }
  getTotalAdvancePaymentPerHarvestAproccor(harvest: string): Observable<any> {
    const param = harvest.replace('/', '-');
    return this.http.get(`${this.url}/advance_payment/sum-per-harvest-aproccor/${param}`);
  }

  getAdvancePaymentsWithFilter(filters: any): Observable<any> {
    return this.http.get(`${this.url}/advance_payment/list-filter`, {params: filters});
  }

  updateValues(id, advancePayment): Observable<any> {
    return this.http.put(`${this.url}/advance_payment/update-values/${id}`, advancePayment);
  }

  getEnabledAdvancePayments(): Observable<any> {
    return this.http.get(`${this.url}/advance_payment/list-enabled)`);
  }



  // * --------------------------------------------------- RECALCULO DE ANTICIPOS -------------------------------------
  recalculateAdvancePayment(id: string, run = true, print = true): void {
    this.paymentService.getPaymentsByAP(id).subscribe((payments: Payment[]) => {
      const sortedPayments = payments.sort((n1, n2) => new Date(n1.date).getTime() - new Date(n2.date).getTime());
      setTimeout(() => {
        this.recalculatePayments(sortedPayments, run, id);
      }, 100);
    }, err => console.error('Error al obtener abonos de anticipo: ', err));
  }

  /*
    * Recalcula el saldo, capital, intereses al anticipo de todos los abonos ACTIVOS que este tiene.
    * Si el anticipo tiene mas de 1 abonos, se calcula desde la fecha del abono anterior a ese sucesivamente.
    * En caso de solo tener 1 abono(anulado) tener abonos, se calcula desde la fecha del anticipo.
    @param payments: Los abonos de un determinado anticipo a recalcular.
    @param status: Si el cambio sera local o incluyendo la BD.
    @return void
  */
 recalculatePayments(payments: any[], status = false, ap?: string): void {
  // console.log('Abonos a recalcular: ', payments);
  if (payments.length > 0) {
    const currentAdvancePayment: any = _.cloneDeep(payments[0].advancePayment);
    currentAdvancePayment.current_capital = currentAdvancePayment.mount;
    currentAdvancePayment.total_interest = 0;
    currentAdvancePayment.days = 0;
    currentAdvancePayment['restGrace'] = currentAdvancePayment.grace_days;
    currentAdvancePayment.total_balance = currentAdvancePayment.mount;
    if (currentAdvancePayment === undefined) { console.error('anticipo indefnido: ', currentAdvancePayment); }

    if (currentAdvancePayment.projected !== true) {
      // console.log(' \n\n --------------------- NO PROJECTED ---------------------');
      this.calculateInterest(currentAdvancePayment, currentAdvancePayment.date, payments[0].date);
    } else {
      // console.log(' \n\n --------------------- PROJECTED) ---------------------');
      this.calculateInterest(currentAdvancePayment, currentAdvancePayment.date, currentAdvancePayment.date_end);
    }
    // console.log('------------------**** Valores del anticipo antes de los abono *****------------------\n');
    // console.table(_.pick(currentAdvancePayment, ['current_capital', 'interest', 'total_interest', 'total_balance']));

    if (payments.length > 1) {
      for (let i = 0; i < payments.length; i++ ) {
        const currentPayment = _.cloneDeep(payments[i]);
        // console.log(`\n========================================================================================================`);
        // console.log(`-----------ABONO #${i + 1} - ${currentPayment.payment} - ${currentPayment.date}----------`);
        // console.log(`==========================================================================================================\n\n`);
        if (!currentPayment.hasOwnProperty('payment')) {
          console.error('No tiene valor de abono: ', currentPayment);
          currentPayment['payment'] = 0;
        }

        if (currentPayment === undefined) { console.error('currentPayment: udefined: ', currentPayment); }
        if (!currentPayment.hasOwnProperty('date') || currentPayment === undefined) {
          console.error('undefined: paymnts ', currentPayment);
          payments[i].date = currentPayment.createdAt;
        }

        this.payPayment(currentAdvancePayment, currentPayment);
        // console.log('\n\n -------------- Valores despues de abonar --------------\n');
        this.printData(currentAdvancePayment, currentPayment);


        if (currentAdvancePayment.total_balance < 0) {
          // console.error('Abono tiene un saldo negativo: ', currentAdvancePayment, currentPayment);
          currentAdvancePayment.payment_status = 'Pagado';
          currentAdvancePayment.total_balance = 0;
          currentAdvancePayment.total_interest = 0;
          currentAdvancePayment.interest = 0;
          currentAdvancePayment.current_capital = 0;
        }

        if (status) {
          this.paymentService.editPayment(currentPayment._id, currentPayment).subscribe(() => {
            // console.log('Abono editado');
          }, err => console.error('Error al editar abono: ', err));
        }
        if (i === payments.length - 1) {
          break;
        } else {
          if (currentAdvancePayment.projected !== true) {
            // * Se calculan los intereses acumulados hasta la siguiente fecha.
            const nextPayment = payments[i + 1];
            const nextDate = this.getLocalDate(nextPayment.date);
            this.calculateInterest(currentAdvancePayment, currentPayment.date, nextDate);
            this.printData(currentAdvancePayment, currentPayment);
          }
        }
        // * ------------------------- Fin del ciclo for -----------------------
      }
      } else {
        this.payPayment(currentAdvancePayment, payments[0]);
        // console.log(`\n\n------ ABONO UNICO - ${payments[0].date} - ${payments[0].payment}--------: \n`);
        this.printData(currentAdvancePayment, payments[0]);
        if (status) {
          this.paymentService.editPayment(payments[0]._id, payments[0]).subscribe(() => {
            console.log('Abono editado');
          }, err => console.error('Error al editar abono: ', err));
        }
      }

      // ? ----------------------- CALCULO DESDE EL ULTIMO ABONO HASTA LA FECHA DE HOY ----------------------

      const today = currentAdvancePayment.projected !== true ? new Date() : new Date(currentAdvancePayment.date_end);
      // today.setDate(today.getDate() + 1); // Descomentar por dia "faltante" con Cocrebistol;
      // console.log('\n\n================================================================================================');
      console.log(`---------------- Calculando valores hasta HOY : ${today} -------------`);
      // console.log('====================================================================================================\n\n');

      const lastPayment = payments[payments.length - 1];
      if (currentAdvancePayment.total_balance > 0 && currentAdvancePayment.total_balance < 1) {
        currentAdvancePayment.total_balance = 0;
        currentAdvancePayment.interest = 0;
        currentAdvancePayment.total_interest = 0;
      }
      if (currentAdvancePayment.total_balance > 0 && currentAdvancePayment.total_balance < currentAdvancePayment.mount) {
        currentAdvancePayment.payment_status = 'Parcial';
      } else if (currentAdvancePayment.total_balance <= 0) {
        currentAdvancePayment.payment_status = 'Pagado';
        currentAdvancePayment.total_balance = 0;
        currentAdvancePayment.total_interest = 0;
        currentAdvancePayment.interest = 0;
        currentAdvancePayment.current_capital = 0;
      } else if (currentAdvancePayment.total_balance === currentAdvancePayment.mount) {
        currentAdvancePayment.payment_status = 'Pendiente';
      }
      if (currentAdvancePayment.projected !== true && currentAdvancePayment.status !== 'Pagado') {
        this.calculateInterest(currentAdvancePayment, lastPayment.date, String(today));
        console.log(' ===============================================================================================');
        console.log('----------------------------- FIN DEL RECALCULO --------------------');
        console.log('=================================================================================================\n\n\n');
        this.printAP(currentAdvancePayment);
      } else {}

      if (status) {
        this.updateValues(currentAdvancePayment._id, currentAdvancePayment).subscribe(res => {
          console.log('Anticipo actualizado; ', res);
        }, err => console.error('Error al actualizar anticipo: ', err));
      }
    } else {
      // console.log('\n--- !!!!!! NO HAY ABONOS A RECALCULAR !!!!! ---');
      this.getAdvancePayment(ap).subscribe(advancePayment => {
        const today = advancePayment.projected !== true ? new Date() : new Date(advancePayment.date_end);
        advancePayment.current_capital = advancePayment.mount;
        advancePayment.total_interest = 0;
        advancePayment.days = 0;
        advancePayment.total_balance = advancePayment.mount;
        advancePayment.payment_status = 'Pendiente';
        advancePayment['restGrace'] = advancePayment.grace_days;
        /// console.log(' \n\n --------------------- Valores hasta hoy ---------------------: ', String(today));
        this.calculateInterest(advancePayment, advancePayment.date, String(today));
        if (status) {
          setTimeout(() => {
            this.updateValues(advancePayment._id, advancePayment).subscribe(res => {
              console.log('Anticipo actualizado; ', res);
            }, err => console.error('Error al actualizar anticipo: ', err));
          }, 500);
        }
        this.printAP(advancePayment);
      }, err => console.error('Error al obtener anticipo a calcular sin abonos: ', err));
    }
  }

  /*
    * Calcula los intereses diario para asi calcular el interes acumulado en un determinado rango de fechas.l
    @param mount: El monto del capital a calcular.
    @param i: La tasa de interes.
    @param date1: La fecha inicial desde donde se calculara.
    @param date2: La fecha hasta donde se calculara.
    @return retorna el interes diario y el interes acumulado en el rango de fechas.
  */
  calculateInterest(ap: AdvancePayment, date_left: string, date_right: string): void {
    const dateIn = new Date(date_left);
    dateIn.setHours(0, 0, 0, 0);
    const daysData: any = this.calculateDiffDays(dateIn, date_right, ap);
    const days = daysData.days;
    const mi = ap.current_capital * ap.i_porcent;
    const tm = mi / 12;
    const ixd = parseFloat((tm / 30).toFixed(8));
    ap.interest = ixd;
    ap.days = daysData.totalDays;
    if (days > 0) {
      ap.total_interest = parseFloat(Number(ap.total_interest + (ixd * days)).toFixed(8));
      ap.total_balance = parseFloat(Number(ap.total_balance + (ixd * days)).toFixed(8));
    }
  }

  calculateDiffDays(date_left: any, date_right: string, ap: AdvancePayment): JSON {
    const daysData = JSON.parse(JSON.stringify({}));
    const days = !ap.commercial_date ?
      this.getDays(date_left, date_right) :
      this.calculateDaysCommercialYear(date_left, date_right);
    daysData.totalDays = ap.days + days;
    // console.log(`dias de diferencia: ${date_left} - ${date_right} = ${days}`);
    if (ap.grace) {
      if (ap.grace_days >= 0) {
        const grace = Number(ap['restGrace']) - days;
        // console.log('grace: ', grace);
        if (grace > 0) {
          ap['restGrace'] = grace;
          daysData['days'] = 0;
        } else {
          daysData['days'] = days - ap['restGrace'];
          ap['restGrace'] = 0;
        }
      }
    } else {
      daysData['days'] = days;
    }
    // console.log('daysData: ', daysData);
    return daysData;
  }

  /*
    * Calcula los dias entres 2 fechas considerando que es un a?o comercial.
    * Comienza desde la fecha de inicio pero no incluye el dia de la fecha final.
  */
  calculateDaysCommercialYear(date_in: any, date_out: any): number {
    // yyyy mm dd
    const date1 = this.getLocalDate(date_in);
    const date2 = this.getLocalDate(date_out);
    const splitedDate1 = [date1.getFullYear(), date1.getMonth() + 1, date1.getDate()];
    const splitedDate2 = [date2.getFullYear(), date2.getMonth() + 1, date2.getDate()];
    // let days = 0;
    let days = this.dayDiff !== true ? -1 : 0; // Descomentar por dia "faltante" con Cocrebistol.
    this.dayDiff = true;
    // * Mientras el mes y el a?o sea diferente, que sume 30 cada mes.
    while (
      (splitedDate1[1] !== splitedDate2[1]) ||
      (splitedDate1[0] !== splitedDate2[0])
      ) {
      days += 30;
      const currentMonth = splitedDate1[1];
      splitedDate1[1] = currentMonth === 12 ? 1 : currentMonth + 1;
      splitedDate1[0] = currentMonth === 12 ? splitedDate1[0] + 1 : splitedDate1[0];
    }
    const restDays = splitedDate2[2] <= 30 ? splitedDate2[2] - splitedDate1[2] : 30 - splitedDate1[2];
    days += restDays;
    return days;
  }

  /*
    * Calcula los dias de diferencia entre 2 fechas.
    * Calcula desde la fecha de inicio pero no calcula el dia de la fecha final.
  */
  getDays(date_in: any, date_out: any): number {
    const pre1 = this.getLocalDate(date_in);
    const pre2 = this.getLocalDate(date_out);

    /* const dasy2 = Math.abs((pre1 - pre2) / (1000 * 60 * 60 * 24));
    console.log('days2: ', dasy2); */
    const diffDays = Math.abs(differenceInDays(pre1, pre2));
    return diffDays;
  }

  getLocalDate(date: any): any {
    const date_ = new Date(date);
    // date_.setHours(date_.getHours() + 6);
    return date_;
  }

  // * Realiza la simulacion de la realizacion de un abono.
  payPayment(currentAP, currentPayment): void {
    const tempObj = _.cloneDeep(currentAP);
    const value = currentPayment.payment;
    const amount = currentAP.total_interest - value;
    currentAP.total_interest = amount > 0 ? amount : 0;
    currentAP.total_balance = parseFloat((Number(currentAP.total_balance) - Number(value)).toFixed(6));
    currentAP.total_balance = currentAP.total_balance < 0 ? 0 : currentAP.total_balance;
    currentPayment.outstanding_balance = currentAP.total_balance;
    if (amount < 0) {
      currentPayment.interest_paid = tempObj.total_interest;
      currentPayment.capital = parseFloat((Number(tempObj.total_balance) -
        Number(tempObj.total_interest) - currentAP.total_balance).toFixed(6));
      currentAP.current_capital = currentAP.current_capital - currentPayment.capital;
      currentAP.total_interest = 0;
    } else {
      currentPayment.interest_paid = value;
      currentPayment.capital = 0;
    }
    currentAP.interest = currentAP.projected !== true ? ((currentAP.current_capital * currentAP.i_porcent) / 12 ) / 30 : currentAP.interest;
  }

  printData(currentAP, currentPayment): void {
    const ap = _.cloneDeep(currentAP);
    const payment = _.cloneDeep(currentPayment);
    const obj = Object.assign(ap, payment);
    // console.table(_.pick(obj, ['date', 'capital', 'interest_paid', 'current_capital', 'interest', 'total_interest', 'total_balance']));
  }

  printAP(currentAdvancePayment) {
    // console.table(_.pick(currentAdvancePayment, ['current_capital', 'interest', 'total_interest', 'total_balance']));
  }
}
