import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

import { AdvancePaymentService } from '../../../../services/advancePayment.service';
import { ReferralService } from '../../../../services/referral.service';
import { InventoryService } from '../../../../services/inventory.service';
import { ProductorService } from '../../../../services/productor.service';
import { PaymentService } from '../../../../services/payment.service';
import { PrintService } from '../../../../services/print.service';
import { ExcelService } from '../../../../services/excel.service';
import { UserService } from '../../../../services/user.service';
import { GLOBAL } from '../../../../services/global';

import { SeatAddModalComponent } from '../../../seat-add-modal/seat-add-modal.component';
import { DeleteModalComponent } from '../../../delete-modal/delete-modal.component';

import { CoffeeFarmer } from '../../../../models/productor';
import { Referral } from '../../../../models/referral';
import { Inventory } from '../../../../models/inventory';
import { Payment } from '../../../../models/payment';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.css']
})
export class ReferralsComponent implements OnInit {

  public title = 'Remisiones';
  public inventory: any = {};
  public identity;
  public identi;
  public harvests = ['Todas', '2018 / 2019', '2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023'];
  public currentHarvest = '';
  public indexedReferrals: any = {};
  public loading = false;
  public dataTable;
  public form: FormGroup;
  public moreBtn = false;
  public alertMessage = '';
  public exist = 0;
  public totalReferrals = 0;
  public referralsData: Referral[] = [];
  public convertToExcel = [];
  public countReferrals = 0;
  public repetidos = [];
  public filteredCoffeeFarmers: CoffeeFarmer[] = [];
  public availableCoffeefarmers: CoffeeFarmer[] = [];
  public pipe = new DatePipe('es-HN');
  public status = ['Pendiente', 'Parcial', 'Pagado'];
  public displayedColumns: string[] = ['cod_set', 'date', 'coffeefarmer', 'total_qq', 'total',
    'total_deductions', 'total_net', 'status', 'observations', 'action' ];

  private tableRef: HTMLElement;
  private page = 0;
  private limit = 100;
  public currency;
  public tempData = {
    totalqq: 0,
    totalqqWn: 0,
    real: 0
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private _userService: UserService,
    private _referralService: ReferralService,
    private _printService: PrintService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.identity = this._userService.getCompany();
    this.identi = this._userService.getIdentity();
  }

  ngOnInit() {
    this.tableRef = document.getElementById('matTableReferral');
    this.currentHarvest = this.identi.company.harvest;
    this.currency = GLOBAL.currency;
    if (!this.harvests.some(h => h === this.currentHarvest)) {
      this.harvests.push(this.currentHarvest);
    }
    this.getDataWithFilters();
  }

  ngAfterViewInit(): void {
    if (this.identity._id === '5f6b9d60e0ac8256dcb36e28') {
      this.displayedColumns.splice(7, 0, 'pending');
    }
    this.paginator._intl.itemsPerPageLabel = 'Registros por página';
  }

  getDataWithFilters(): void {
    this._referralService.getReferrals().subscribe(res => {
      this.loading = false;
      if (res) {
        this.referralsData = res;
        this.countReferrals = res.length;
      } else {
        this.referralsData = [];
      }
    }, err => {
      console.error('ERROR EN Liquidaciones con filtro ', err);
      this.loading = false;
      this.alertMessage = 'No se pudieron obtener los registros';
    });
  }

  setLocalStorageData(): void {
    const price = this._userService.getCompany().price;
    const data = {
      price: 0,
      date: '',
      brokerName: '',
      exporterName: '',
      customerName: '',
      certificationName: '',
      exporter: '',
      broker: '',
      total_qq_ps: 0,
      total_qq_net: 0,
      customer: '',
      contract: '',
      shippingAddress: '',
      status: 'Depósito',
      sub_total: 0,
      prime_stamps: 0,
      total: 0,
      total_qq_referral: 0,
      total_sale: 0,
      total_qq: 0,
      total_sales: 0,
      bags: 0,
      observations: '',
      position: '',
      certifications: '',
      differential: 0,
      total_tare: 0,
      label: '',
      driver: '',
      licensePlate: '',
      dredgePlate: '',
      truckPlate: '',
    };
    localStorage.setItem('referral', JSON.stringify(data));
    localStorage.setItem('indexSelectedSales', JSON.stringify({}));
    localStorage.setItem('indexSelectedDrieds', JSON.stringify({}));
    this.router.navigateByUrl('/administracion/remisiones/crear/paso1');
  }

  filterData(): void {
    this.page = 0;
    this.paginator.firstPage();
    this.getDataWithFilters();
  }

  changePage(paginator: MatPaginator) {
    this.limit = paginator.pageSize;
    this.page = paginator.pageIndex;
    const tableRef = window.document.getElementById('matTableReferral');
    tableRef !== null ? tableRef.scrollTo(0, 0) : console.log('tableRefNull', tableRef);
    this.getDataWithFilters();
  }


  openDialogD(referral) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Anular Remision',
        title: '',
        text: `¿Seguro que quieres anular <strong>#${referral.seq}</strong>?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.cancelReferral(referral);
      }
    });
  }

  print(sale: Referral): void {
    console.log('Sale to print: ', sale);
    this._printService.getReferral(sale._id).then((res: any) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    })
    .catch(err => console.log('Error', err));
  }

  cancelReferral(referral) {
    referral['enabled'] = false;
    this._referralService.editReferral(referral._id, referral).subscribe(response => {
      console.log('Remision anulada: ', response);
      setTimeout(() => {
        this.getDataWithFilters();
      }, 1000);
      // todo : Anular asiento
    }, err => console.error('Error al anular Remision: ', err));
  }

}
