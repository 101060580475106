import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Closing } from '../../../../models/closing';
import { PeriodClosingService } from '../../../../services/period-closing.service';
import { GLOBAL } from '../../../../services/global';

@Component({
  selector: 'app-period-closing-view',
  templateUrl: './period-closing-view.component.html',
  styleUrls: ['./period-closing-view.component.css']
})
export class PeriodClosingViewComponent implements OnInit {
  public periodClosing: Closing;
  public categoriesBalance: any[] = [];
  public currency;
  public groups = [
    { title: 'Activos', key: 'activo' },
    { title: 'Pasivos', key: 'pasivos' },
    { title: 'Capital', key: 'capital' },
    { title: 'Costos', key: 'pasivos' },
    { title: 'Gastos', key: 'gastos' },
    { title: 'Ingresos', key: 'ingresos' }
  ];
  constructor(
    private closingService: PeriodClosingService,
    private activatedRoute: ActivatedRoute,
  ) {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    console.log('id', id);
    this.closingService.getPeriodClosing(id).subscribe(res => {
      this.periodClosing = res;
      console.log('period closing view', this.periodClosing);
    });
    /* this.activatedRoute.queryParams.subscribe(params => {
      const id = params['id'];
  }); */
  }

  ngOnInit() {
    this.currency = GLOBAL.currency;
  }

}
