import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AsisstanceService } from '../../../services/asisstance.service';
import { ProductorService } from '../../../services/productor.service';
import { Asisstance } from '../../../models/asisstance';
import { CoffeeFarmer } from '../../../models/productor';

@Component({
  selector: 'app-asisstance-add',
  templateUrl: './asisstance-add.component.html',
  styleUrls: ['./asisstance-add.component.css']
})
export class AsisstanceAddComponent implements OnInit {
  
  public form: FormGroup;
  public farmerForm: FormGroup;
  public productores: CoffeeFarmer[];
  public coffeefarmers: CoffeeFarmer[];
  public selectedFarms: ReplaySubject<CoffeeFarmer[]> = new ReplaySubject<CoffeeFarmer[]>(1);
  public eventTypes: string[] = ['Asistencia Tecnica', 'Capacitación', 'Formación Vocacional', 'Reunión' ];
  public count = 0;
  public topic;

  constructor(
    private _assistanceService: AsisstanceService,
    private _productorService: ProductorService,
    private fb: FormBuilder,
    private router: Router,
    ) { }

  ngOnInit() {
    this.createForm();
    this.farmerF();
    this.getDataFromServices();
    this.listenersFormChanges();
  }

  getDataFromServices() {
    this._productorService.getProductores().subscribe(res => {
      this.productores = res;
      }, error => console.error('Error al obtener productores: ', error)
    );
  }

  listenersFormChanges() {
    this.farmerForm.get('coffeefarmers').valueChanges.subscribe((value: any) => {
      this.selectedFarms.next(value);
      this.coffeefarmers = value;
      this.count = this.coffeefarmers.length;
    });

    this.form.get('topic').valueChanges.subscribe((value: any) => {
      this.topic = value;
    });
  }

  remove(farmer) {
    const farmers = this.coffeefarmers.filter(deletado => deletado._id != farmer._id);
    this.selectedFarms.next(farmers);
    this.count = farmers.length;
    this.farmerForm.get("coffeefarmers").setValue(farmers);
  }

  saveData(): void {
    const farmsForm = this.farmerForm.getRawValue();
    const farmers = farmsForm.coffeefarmers.map(item => item._id)
    const assistance: Asisstance = this.form.getRawValue();

    assistance.coffeefarmers = farmers;
    console.log(assistance);

    this._assistanceService.create(assistance).subscribe(res => {
      console.log('agregada: ', res);
      setTimeout(() => {
        this.router.navigateByUrl('/asistencia');
      }, 500);
    });

  }

  createForm(): void {
    this.form = this.fb.group({
      name:             [''],
      address:          [''],
      topic:            [''],
      event_type:       [''],
      date:             ['', Validators.required],
      coffeefarmers:    [''],
      commit:           [''],
    });
    console.log('form init: ', this.form.value);
  }

  farmerF(): void {
    this.farmerForm = this.fb.group({
      coffeefarmers: [''],
    });
    console.log('form init: ', this.form.value);
  }

}
