import { Component, OnInit, ViewChild } from '@angular/core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatSort, MatDialog } from '@angular/material';

import { Shipping } from '../../../models/shipping';
import { ShippingService } from '../../../services/shipping.service';
import { ShippingEditComponent } from '../shipping-edit/shipping-edit.component';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { LotService } from '../../../services/lot.service';
import { Lot } from '../../../models/lot';
import { ShippingAddComponent } from '../shipping-add/shipping-add.component';

@Component({
  selector: 'app-shippings',
  templateUrl: './shippings.component.html',
  styleUrls: ['./shippings.component.css'],
  providers: [ShippingService, LotService]
})
export class ShippingsComponent implements OnInit {
  public title = 'Lista de Embalaje';
  public displayedColumns = ['seq', 'lot', 'container', 'shipping', 'driver', 'transport', 'shipping_company',
  'label', 'booking', 'chassis_plate', 'license_plate', 'date', 'observations', 'action'];

  public dataSource = new TableVirtualScrollDataSource<Shipping>();
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }
  constructor(
    private shippingService: ShippingService,
    private lotService: LotService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getDataFromServices();
  }

  getDataFromServices(): void {
    this.shippingService.getShippings().subscribe(response => {
      this.dataSource.data = response as Shipping[];
      this.dataSource.connect().next(response);
      // this.dataSource.data = this.dataSource.data;
      console.log('\n\nEnvios obtenidos: ', this.dataSource.data);
    }, err => console.error('Error a obtener los envios: ', err));
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialogEdit(shipping: Shipping): void {
    const dialogRef = this.dialog.open(ShippingEditComponent,
      {
        panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
        width: '650px',
        height: '600px',
        data: {
          shippingId: shipping._id,
        }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('Result after closed edit: ', result);
      this.getDataFromServices();
    });
  }

  addShipping() {
    const dialogRef = this.dialog.open(ShippingAddComponent, {
      width: '25rem'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getDataFromServices();
    });
  }

  openDialogDelete(shipping: Shipping): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar despacho',
        title: '',
        text: `¿Seguro que quieres eliminar <strong>#${shipping.seq}</strong> ?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.shippingService.deleteShipping(shipping._id).subscribe(response => {
          console.log('Despacho eliminado...');
          const lot: Lot = shipping.lote;
          lot.shipping = false;
          this.lotService.editLot(lot._id, lot).subscribe(() => {
            console.log('Status cambiado');
          }, err => console.error('Error al cambiar status a lote: ', err));
          this.getDataFromServices();
        }, err => console.error('Error al eliminar el despacho:', err));
      }
    });
  }

}
