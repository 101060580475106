import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';

import { DriedService } from '../../../services/dried.service';
import { PrintService } from '../../../services/print.service';

import { Dried } from '../../../models/dried';

import { EditDateDriedComponent } from '../edit-date-dried/edit-date-dried.component';

import * as _moment from 'moment';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-dried-detail',
  templateUrl: './dried-detail.component.html',
  styleUrls: ['./dried-detail.component.css'],
})
export class DriedDetailComponent implements OnInit {

  public dried: any = {};
  public form: FormGroup;
  public alertMessage = '';
  public currentId = '';
  public status = [
    { value: 'Proceso'},
    { value: 'Secando'},
    { value: 'Descargando'},
    { value: 'Finalizado'}
  ];
  @Input() currentDried: BehaviorSubject<Dried> = new BehaviorSubject<Dried>(null);

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private fb: FormBuilder,
    private _driedService: DriedService,
    private _printService: PrintService,
    public dialog: MatDialog,
    ) { }

  ngOnInit() {
    this.createForm();
    this.currentDried.subscribe((dried: Dried) => {
      if (dried) this.getDried(dried._id);
    });
    this.listenFormValues();
  }

  createForm(): void {
    this.form = this.fb.group({
      status:         [''],
      date_end:       [''],
      dry_qq:         [''],
      r_qq:           [''],
      bags:           [''],
      moisture_loss:  [0],
      area:           [''],
      wet_quintals:   [''],
    });
  }

  listenFormValues(): void {
    this.form.get('dry_qq').valueChanges.subscribe(value => {
      const rqq = value - this.form.get('wet_quintals').value;
      this.form.get('r_qq').setValue(rqq);
    });
  }

  getDried(id: string) {
    this.form.reset();
    this._driedService.getDried(id).subscribe(response => {
      this.dried = response;
      this.form.patchValue(response);
      console.log('detail', response);
      if (this.dried.status === 'Finalizado') this.form.controls.status.disable();
      else this.form.controls.status.enable();
      if (this.dried.inventoryStatus.production) {
        if (this.dried.inventoryStatus.production.ref.length !== 0) this.form.controls.dry_qq.disable();
        else this.form.controls.dry_qq.enable();
      }
    });
  }

  onSubmit() {
    this.dried = Object.assign(this.dried, this.form.getRawValue());
    this.dried.inventory_qq = this.dried.dry_qq;
    const days = Math.floor(
      Math.abs(
        (new Date(this.dried.date).getTime() - new Date(this.dried.date_end).getTime()) / (1000 * 60 * 60 * 24)
      )
    );
    this.dried.days = days + 1;
    this.dried.moisture_loss = this.form.get('moisture_loss').value !== undefined ? this.form.get('moisture_loss').value : null;
    if (this.dried.status === 'Finalizado') {
      this.dried.inventoryStatus.sale.total = this.dried.dry_qq;
      this.dried.inventoryStatus.referral.total = this.dried.dry_qq;
      this.dried.inventoryStatus.production.total = this.dried.dry_qq;
    }
    this._driedService.editDried(this.dried._id, this.dried).subscribe(response => {
      this._router.navigate(['/ordenes-secado/inventario-seco']);
      console.log('Orden editada: ', response);
    }, error => console.error('Error al actualizar orden: ', error));
  }

  openDialogC(dried: Dried) {
    const dialogRef = this.dialog.open(EditDateDriedComponent, {
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
      width: '30rem',
      data: dried
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) this.getDried(this.currentDried.value._id);
    });
  }

  print() {
    this._printService.getOrderDried(this.dried._id).then(res => {
        console.log('Imprimiendo');
        const blob = new Blob([res], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      })
      .catch(err => console.log('Error al imprimir orden de secado:', err));
  }

}
