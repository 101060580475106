import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GLOBAL } from './global';
import { Observable } from 'rxjs';

@Injectable()
export class ReportService {
  public url: string = GLOBAL.url;
  constructor( public http: HttpClient) { }

  getReportPersonalize(parameters: any): Promise<Blob> {
    const path = `reports/${parameters.path}`;
    console.log(`path: ${this.url}/${path}`);
    return this.http.get(`${this.url}/${path}`, {params: parameters, responseType: 'blob'}).toPromise();
  }

  getReportPersonalizeExcel(parameters: any): Observable<any> {
    const path = `reports/${parameters.path}`;
    console.log(`path: ${this.url}/${path}`);
    return this.http.get(`${this.url}/${path}`, {params: parameters});
  }

  getReportDeposito() {
    return this.http.get(`${this.url}/cafe_deposito`, {responseType: 'blob'}).toPromise();
  }

  // ? -------------------------------------------- vvv peticiones fuera de este componente.
  getDaily(parameters): void {
    const blobRes: Promise<Blob> = this.http.get(`${this.url}/reports/daily_book`, {params: parameters, responseType: 'blob'}).toPromise();
    blobRes
      .then((res: any) => this.displayBlob(res))
      .catch(err => console.log('Error imprmir asiento', err));
  }

  getLedgerReport(query: any): Promise<Blob> {
    return this.http.get(`${this.url}/reports/ledger`,
    { params: query, responseType: 'blob' }).toPromise();
  }

  getReportState() {
    return this.http.get(`${this.url}/reporte_estado`, {responseType: 'blob'}).toPromise();
  }

  getReportCompra() {
    return this.http.get(`${this.url}/compra_fecha`, {responseType: 'blob'}).toPromise();
  }

  getReportCompraProductor() {
    return this.http.get(`${this.url}/reporte_compra_productor`, {responseType: 'blob'}).toPromise();
  }

  displayBlob(res: any): void {
    const blob = new Blob([res], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

}
