import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { UserService } from '../../../services/user.service';
import { MachineService } from '../../../services/machine.service';
import { Machine } from '../../../models/machine';

@Component({
  selector: 'app-machine-add',
  templateUrl: './machine-add.component.html',
  styleUrls: ['./machine-add.component.css'],
  providers: [UserService, MachineService]
})
export class MachineAddComponent implements OnInit {

  public titulo = 'Agregar Máquina';
  public machine: Machine = JSON.parse(JSON.stringify({}));
  public alertMessage = '';
  disabled = false;

  constructor(
    private _machineService: MachineService,
    public dialogRef: MatDialogRef<MachineAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() { }

  submitForm() {
    this._machineService.addMachine(this.machine).subscribe(response => {
        this.alertMessage = 'Registro creado correctamente';
        this.dialogRef.close();
      }, error => console.error('Error al agregar maquina: ', error)
    );
  }
}
