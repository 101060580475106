import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { AnalysisPhysicalResult, PhysicalAnalysis, PhysicalValue, physicalValues, TotalResults } from '../../../../models/physical';
import { Sample } from '../../../../models/sample';
import { Taster } from '../../../../models/taster';

import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { PhysicalService } from '../../../../services/physical.service';
import { SampleService } from '../../../../services/sample.service';
import { TasterService } from '../../../../services/taster.service';

@Component({
  selector: 'app-physical-edit',
  templateUrl: './physical-edit.component.html',
  styleUrls: ['./physical-edit.component.css'],
  styles: [`
    :host {
      height: 100%;
      overflow-y: auto;
    }`
  ],
})

export class PhysicalEditComponent implements OnInit {
  public form: FormGroup;
  public tasters: Taster[] = [];
  public filteredSamples: Sample[] = [];
  public samples: Sample[] = [];
  public currentSample: any;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public certificates: string[] = ['Organic', 'Fairtrade', 'UTZ', 'Convencional', 'RFA'];
  public analysisValues = physicalValues;
  public physicalAnalysisEditing: PhysicalAnalysis;

  constructor(
    private _tasterService: TasterService,
    private _sampleService: SampleService,
    private _physicalService: PhysicalService,
    private _sidebarManager: SidebarManagerService,
    private _fb: FormBuilder
  ) {
    const physicalId = this._sidebarManager.getInput('physical-edit');
    this.createForm();
    console.log(physicalId);
    this._physicalService.getPhysical(physicalId).subscribe((physicalAnalysis) => {
      this.physicalAnalysisEditing = physicalAnalysis;
      console.log('miguelk angel', physicalAnalysis);
      this.form.patchValue(physicalAnalysis);
      this.form.setControl('results', this._fb.array(physicalAnalysis.results.map((current: AnalysisPhysicalResult) =>
        this._fb.group({
          sample: [current.sample, Validators.required],
          values: this.createAnaylisisForm(current.values),
          total: this._fb.group({
            defects:    [current.total.defects],
            damages:    [current.total.damages],
            subtotal:   [current.total.subtotal],
            total:      [current.total.total],
          })
        })
      )));
    }, (err: HttpErrorResponse) => console.error('Error al obtener analisis fisico', err));
  }

  ngOnInit() {
    this.getTasters();
    this.getSamples();
  }

  getTasters(): void {
    this._tasterService.getTasters().subscribe(res => {
      this.tasters = res;
    }, (err: HttpErrorResponse) => console.error('Error al obtener catadores', err));
  }

  // ? Concatenar las muestras del analisis fisico a editar.
  getSamples(): void {
    this._sampleService.getSamples({ 'analysis.physical': null, enabled: true }).subscribe((res: Sample[]) => {
      this.filteredSamples = res;
      this.samples = res;
    }, (err: HttpErrorResponse) => console.error('Error al obtener muestras', err));
  }

  searchSample = (name: string): void => {
    this.filteredSamples = _.differenceBy(this.samples, this.selectedItems.value
      .map(((item: AnalysisPhysicalResult) => item.sample)), '_id')
      .filter(item => item.name.includes(name));
  }

  searchSamples = (name: string | number): void => {
    this.filteredSamples = this.samples
      .filter(sample => {
        const codeString = String(sample.code); // Asegurarse de que el código sea una cadena
        return codeString.indexOf(String(name)) !== -1;
      })
      .filter(sample => !this.selectedItems.value.some(item => item.sample._id === sample._id));
  }

  pushSample(sample: Sample): void {
    this.selectedItems.push(this._fb.group({
      sample: sample,
      values: this.createAnaylisisForm({ grossWeight: sample.weight }),
      total: this._fb.group({
        defects:    [0],
        damages:    [0],
        subtotal:   [0],
        total:      [0],
      })
    }));
    this.filterSamples();
  }

  selectSample(index: number): void {
    this.currentSample = this.selectedItems.at(index);
    console.log('current sample', this.currentSample);
  }

  remove(index: number): void {
    const list = this.form.controls.results as FormArray;
    list.removeAt(index);
    this.filterSamples();
  }

  sumValues = (type = 'damage'): void => {
    if (!this.currentSample) return;
    if (type === 'damage') {
      const defects = this.values.primaryDefect + this.values.secondaryDefect;
      const damages = this.values.machineDamage + this.values.bandDamage;
      const subtotal = defects + damages;
      const total = subtotal - this.values.exportDamage;
      this.currentSample.get('total').setValue({
        defects,
        damages,
        subtotal,
        total,
      });
    } else {
      this.currentSample.get('values').patchValue({
        threshingPerformance:  parseFloat((this.values.grossWeight / this.values.goldWeight).toFixed(4))
      });
    }
  }

  editPhysical(): void {
    const physicalAnalysis: PhysicalAnalysis = Object.assign(_.cloneDeep(this.physicalAnalysisEditing), this.form.getRawValue());
    const removedSamples = _.differenceBy(this.physicalAnalysisEditing.results, physicalAnalysis.results, 'sample._id');
    removedSamples.forEach((current: AnalysisPhysicalResult, index) => {
      current.sample.analysis.physical = null;
      this._sampleService.updateSample(current.sample._id, current.sample).subscribe((res) => {
        console.log('muestra removida actualizada');
        if (index === removedSamples.length - 1 && physicalAnalysis.results.length === 0)
          this._sidebarManager.close('physical-edit', true);
      },
      err => console.error('Error al actualizar muestra removida', err));
    });
    this._physicalService.updatePhysical(physicalAnalysis).subscribe(res => {
      physicalAnalysis.results.forEach((item: AnalysisPhysicalResult, index: number) => {
        item.sample.analysis.physical = physicalAnalysis._id;
        this._sampleService.updateSample(item.sample._id, item.sample).subscribe(resp2 => {
          if (index === physicalAnalysis.results.length - 1) this._sidebarManager.close('physical-edit', true);
        }, (err: HttpErrorResponse) => console.error('Error al actualizar muestra', err));
      });
    }, (err: HttpErrorResponse) => console.error('Error al editar analisis fisico', err));
  }

  createForm(): void {
    this.form = this._fb.group({
      date:           [new Date(), Validators.required],
      certificates:   ['', Validators.required],
      tasters:        ['', Validators.required],
      observations:   [''],
      results: this._fb.array([], Validators.required)
    });
  }

  filterSamples = (): void => {
    this.filteredSamples = _.differenceBy(this.samples, this.selectedItems.value
      .map(((item: AnalysisPhysicalResult) => item.sample)), '_id');
  }

  createAnaylisisForm(data = {}): FormGroup {
    const form: any = {};
    this.analysisValues.forEach(item => form[item] = ['', Validators.required]);
    const analysisForm = this._fb.group(form);
    analysisForm.patchValue(data);
    return analysisForm;
  }

  get selectedItems() {
    return this.form.controls.results as FormArray;
  }

  get analysis(): AnalysisPhysicalResult {
    return this.currentSample.value;
  }

  get values(): PhysicalValue {
    return this.currentSample.value.values;
  }

  get total(): TotalResults {
    return this.currentSample.value.total;
  }

}
