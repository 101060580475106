import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { TransferService } from '../../../services/transfer.service';
import { Transfer } from '../../../models/transfer';

@Component({
  selector: 'app-confirm-transfer',
  templateUrl: './confirm-transfer.component.html',
  styleUrls: ['./confirm-transfer.component.css'],
  providers: [UserService, TransferService]
})
export class ConfirmTransferComponent implements OnInit {


  public titulo: string;
  public transfer: any = {};
  public identity;
  public token;
  public harvest;
  public url: string;
  public alertMessage;
  public disabled = false;
  public id;
  public list_code = [];
  public companies = [];


  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _transferService: TransferService,
    public dialogRef: MatDialogRef<ConfirmTransferComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.titulo = 'Recibir transferencia';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.url = GLOBAL.url;
    this.transfer = {} as Transfer;
  }

  ngOnInit() {
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.harvest = this.identity.harvest;
    this.id = this.data._id;
    this.transfer = this.data;
    console.log(this.id);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  getTransfersPerCode(code) {

    this._transferService.getTranfersPerCode(code).subscribe(
      (res) => {
        if (!res) {
          this._router.navigate(['/']);
        } else {
          res.forEach(code => {
            // tslint:disable-next-line: max-line-length
            if (code.company === '5de280c2ff21b207b052c7cc' || code.company === '5de283c827c52b47289691da' || code.company === '5de284e5bedbfe4010fd7df8' || code.company === '5de285945fea37570c10b1d0') {
              this.list_code.push(code._id);
              this.companies.push(code.company);
            }
          });

          console.log(this.list_code);
          console.log(this.companies);
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }
  onCloseConfirm() {
    this.dialogRef.close('confirm');
  }
  editMany(transfer) {
    this.list_code.forEach((id, index) => {
      console.log(id);
      transfer._id = id;
      transfer.company = this.companies[index];
      this._transferService.editTransfer(id, transfer).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this._router.navigate(['/traslados']);
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );
    });
  }

  submitForm() {
    this.disabled = true;
    this.transfer.status = true;

      this._transferService.editTransfer(this.id, this.transfer).subscribe(
        response => {
          this._router.navigate(['/traslados']);
          this.onCancelClick();
        },
        error => {
          const errorMessage = <any>error;

          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );
  }
}
