import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LotListComponent } from './lot-list/lot-list.component';
import { SharedModule } from '../../../shared/shared.module';
import { LotAddComponent } from './lot-add/lot-add.component';
import { LotEditComponent } from './lot-edit/lot-edit.component';

@NgModule({
  declarations: [
    LotListComponent,
    LotAddComponent,
    LotEditComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  entryComponents: [
    LotAddComponent,
    LotEditComponent
  ]
})
export class LotsModule { }
