import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { startWith } from 'rxjs/operators/startWith';
import { map } from 'rxjs/operators/map';
import { Location } from '@angular/common';
import { NgxSerial } from 'ngx-serial';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { ProductorService } from '../../../services/productor.service';
import { WarehouseService } from '../../../services/warehouse.service';
import { InventoryService } from '../../../services/inventory.service';
import { SocketIoService } from '../../../services/io.service';
import { CertificationService } from '../../../services/certification.service';
import { OriginService } from '../../../services/origin.service';
import { DriedService } from '../../../services/dried.service';

import { Dried } from '../../../models/dried';
import { Inventory } from '../../../models/inventory';
import { Certification } from '../../../models/certification';
import { CheckboxItem } from '../../../models/checkbox-item';
import serialPort from 'browser-serialport';
import { HttpErrorResponse } from '@angular/common/http';
import { Sample } from '../../../models/sample';
import { SampleService } from '../../../services/sample.service';

@Component({
  selector: 'app-inventory-add',
  templateUrl: './inventory-add.component.html',
  styleUrls: ['./inventory-add.component.css'],
})

export class InventoryAddComponent implements OnInit {

  public titulo: string;
  public productor: any = {};
  public warehouse: any = {};
  farmer: any = {};
  public inventory: any = {};
  public invento: any = {};
  public origin: any = {};
  public cert: any = {};
  public certs: Certification[];
  public identity;
  public token;
  public farm;
  public bodega;
  public url: string;
  public alertMessage;
  public tara: number;
  public harvest;
  public price;
  public nameCF;
  public sample: Sample = {};
  public bags = [];
  public disabled = false;
  e_factor = false;

  public sacos;
  public tsacos: number;
  public desc: number;
  public descuentoHumedad: number;
  public valor: number;
  public flet: number;
  public certss: any;

  public id = [];
  public restqq = [];
  public driedqq = [];
  public tare;
  public total_tara;
  public desc_humedad;
  public damage = 0;
  public discoinDamage = 0;
  public gross_total_lb;
  public gross_total_q;
  public total_net_green;
  public total_after_discount;
  public total_discount;
  public total_net_lb;
  public factor;
  public gallons = false;
  public total_net_qq;
  public total_net_qqq = 0;
  public inventoryDetails = [];

  myControl: FormControl = new FormControl();
  myControlCF: FormControl = new FormControl();
  myControlOrigin: FormControl = new FormControl();
  public availableWarehouse = [];
  public availableCoffeeFarmer = [];
  public availableOrigin = [];
  public availableOrigi = [];
  public filteredOptions: Observable<any[]>;
  public filteredOptionsCF: Observable<any[]>;
  public filteredOptionsOrigin: Observable<any[]>;
  public selectedWarehouse = null;
  public selectedCoffeeFarmer = null;
  public selectedOrigin = null;
  public selectedName = '';
  public selectedNameCF = '';
  public selectedNameOrigin = '';

  public dried: any;

  interestFormGroup: FormGroup;
  interests: any;
  selected: any;

  states = [
    { value: 'Seco', viewValue: 'Seco' },
    { value: 'Uva', viewValue: 'Uva' },
    { value: 'Mojado', viewValue: 'Mojado' },
    { value: 'Húmedo', viewValue: 'Húmedo' },
    { value: 'Verde', viewValue: 'Verde' },
    { value: 'Resaca', viewValue: 'Resaca' },
    { value: 'StockLot', viewValue: 'StockLot' },
    { value: 'Oro Exportable', viewValue: 'Oro Exportable' },
    { value: 'Galones', viewValue: 'Galones' }
  ];
  processCoffee = [
    { value: 'Lavado', viewValue: 'Lavado' },
    { value: 'Lavado Reposado', viewValue: 'Lavado Reposado' },
    { value: 'Natural', viewValue: 'Natural' },
    { value: 'Natura Reposado 48 horas', viewValue: 'Natural Reposado 48 horas' },
    { value: 'Natura Reposado 72 horas', viewValue: 'Natural Reposado 72 horas' },
    { value: 'Natura Reposado 90 horas', viewValue: 'Natural Reposado 90 horas' },
    { value: 'Melado', viewValue: 'Melado' },
    { value: 'Semi Melado', viewValue: 'Semi Melado' },
    { value: 'Melado Rojo', viewValue: 'Melado Rojo' },
    { value: 'Anaeróbico 48 Horas', viewValue: 'Anaeróbico 48 Horas'},
    { value: 'Anaeróbico 80 Horas', viewValue: 'Anaeróbico 80 Horas'},
    { value: 'Anaeróbico 72 Horas', viewValue: 'Anaeróbico 72 Horas'},
  ];

  receptions = [
    { value: 'Pergamino', viewValue: 'Pergamino' },
    { value: 'Uva', viewValue: 'Uva' },
    { value: 'Oro Exportable', viewValue: 'Oro Exportable' }
  ];

  entrys = [
    { value: 'Propio', viewValue: 'Propio' },
    { value: 'Depósito', viewValue: 'Depósito' }
  ];

  isDisabled = false;
  form = new FormGroup({
    'cb1': new FormControl({ value: 0, disabled: false }),
    'cb2': new FormControl({ value: 0, disabled: false })
  });

  public inventoryModel = {
     certs: []
  };
  wnForm: FormGroup;
  nestedForm: FormGroup;

  private certifications = [
    { id: 1, name: 'FairTrade' },
    { id: 2, name: 'RainForest' },
    { id: 3, name: 'UTZ Certified' },
    { id: 4, name: 'CAFE Practices' },
    { id: 5, name: 'JAS' },
    { id: 6, name: 'BirdFriandly' },
    { id: 7, name: 'Orgánico' }
  ];

  public varieties: string[] = ['Catuaí', 'Catuai Amarillo', 'Caturra', 'Bourbón', 'ihcafe 90', 'Parainema',
  'Typica', 'Lempira', 'Pacas', 'Obata', 'Colombiano', 'Geisha', 'Icatu', 'Magarogipe', 'Blend',
   'Catimor', 'SL20', 'SL25', 'SL28', 'Catuai Rojo','Java', 'Anacafe 14', 'Paca Nanca', 'Blend', 'Borbon Rosado', 'Pacamara',
  'Aji Natural', 'Bourbon Sidra', 'Wush Wush', 'Chiroso', 'Laurina', 'Sudan Rume', 'Sidra', 'Bernardina', 'Moka', 'Castilla', 'Purpurasea', 'Heirloom' ];

  certsOptions = new Array<CheckboxItem>();
  coffeefarmer = '';
  origin_val = '';
  warehouse_val = '';
  submitted = false;
  public certificationName = '';
  public certs2 = [];
  public select = new FormControl();
  public varietyS = new FormControl();
  public processS = new FormControl();
  public certs_select = [];
  public house_select = [];
  public c_select = [];
  private _onDestroy = new Subject<void>();
  public tareTroco = 0;
  public gals;

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _productorService: ProductorService,
    private _warehouseService: WarehouseService,
    private _inventoryService: InventoryService,
    private _driedService: DriedService,
    private _originService: OriginService,
    private _sampleService: SampleService,
    private _certificationService: CertificationService,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    // private _io: SocketIoService,
    public dialogRef: MatDialogRef<InventoryAddComponent>,
    // private serialPort: serialPort,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.titulo = 'Agregar Nota de Peso';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.inventory = {} as Inventory;
    this.invento = {} as Inventory;
    this.inventory.date = moment()['_d'];
    console.log('data: ', this.inventory.date);
    this.url = GLOBAL.url;
    this.dried = {} as Dried;
    // this.dried = new Dried();
    this.form.get('cb1').disable();
    this.form.get('cb2').disable();
/*     this._io.listen('weight').subscribe((res: any) => {
      console.log('Peso que viene', res);
    }, (err: any) => console.error('Error get subscription socket: ', err)); */
  }

  ngOnInit() {
    console.log(serialPort);
    this.interestFormGroup = this.formBuilder.group({
      certs: this.formBuilder.array([])
    });
    this.inventory.washed = false;
    this.harvest = this.identity.harvest;
    console.log(this.identity);
    if (this.identity._id === '5ff5e3213545ae31b8d199b0') {
      this.inventory.reception = 'Pergamino';
    }
    this.total_net_qqq = 0;
    this.inventory.microlot = false;
    this.inventory.total_qq_dry = 0;
    this.getCertifications();
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.inventory.entry = 'Depósito';
    /*------------------Autocompletes--------------------*/
    this._warehouseService.getWarehouses().subscribe(warehouse => {
      this.availableWarehouse = warehouse;
    });

    this._productorService.getProductores().subscribe(productor => {
      this.availableCoffeeFarmer = productor;
    });

    // this.getOrigins();

    this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(val => this.filter(val))
        );

    this.filteredOptionsCF = this.myControlCF.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterCF(String(val).normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      );

    this.filteredOptionsOrigin = this.myControlOrigin.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterOrigin(val))
      );

    /*------------------/Autocompletes--------------------*/
    /***--------checkbox--------- */

    setTimeout(() => {
      this.certsOptions = this.certs.map(x => new CheckboxItem(x.cod, x.name));
      this.certs2 = this.certs.map(x => x.name);
    }, 1000);
  }

  onCertsChange(value) {
    this.inventoryModel.certs = value;
    console.log('Certification:', this.inventoryModel.certs);
  }
  getOrigins(origins) {
    this.availableOrigin = [];
    this._originService.getOrigins().subscribe(origin => {
    this.filteredOptionsOrigin = this.myControlOrigin.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterOrigin(val))
      );
    console.log(origins);
    this.availableOrigin = origin;
    if (origins.length > 0 || origins) {
        this.availableOrigi = [];
        origins.forEach(id => {
        console.log(id);
        const farmer = _.filter(origin, { '_id': id });
        console.log(farmer);
        this.availableOrigi.push(farmer[0]);
      });
      if (this.availableOrigi.length > 0) {
        this.availableOrigin = this.availableOrigi;
      }
    } else if (origins.length === 0) {
      this.availableOrigin = origin;
    } else {
      this.availableOrigin = origin;
    }
    });
  }
    getCertifications() {

    this._certificationService.getCertifications().subscribe(
      (res) => {
        if (!res) {
          this._router.navigate(['/']);
        } else {
          // this.certs = res.map(element => element.name);
          this.certs = res;
          this.certs_select = this.certs.map(x => x.name);
          const houses: string[] = this.certs.map(x => x.house);
          this.house_select = _.uniq(houses);
          console.log('certs: ', this.house_select);
          console.log(res);
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }
/*------------------Autocompletes--------------------*/
  filter(val: any): any[] {
    return this.availableWarehouse.filter(warehouse =>
      warehouse.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
  }
  filterCF(val: any): any[] {
    return this.availableCoffeeFarmer.filter(productor =>
      String(productor.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(val.toLowerCase()) > -1);
  }
  filterOrigin(val: any): any[] {
    return this.availableOrigin.filter(origin =>
      origin.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
  }
/*------------------/Autocompletes--------------------*/

onChange(event) {
    const certs = <FormArray>this.interestFormGroup.get('certs') as FormArray;

    if (event.checked) {
      certs.push(new FormControl(event.source.value));
    } else {
      const i = certs.controls.findIndex(x => x.value === event.source.value);
      certs.removeAt(i);
    }
  }

  /*------------------------ lista de peso -----------------------------*/
  calculateTara(newVal) {
    this.sacos = newVal;
    this.total_tara = this.tare * this.sacos;
  }

  tareTrocoChange(value: number) {
    console.log('troco', value);
    this.total_tara = Number(this.tare * this.sacos) + value;
  }

  calculateTNA(val) {
    this.damage = val;
    this.calculateTNL(this.desc_humedad);
  }

  calculateGals(val) {
    this.gross_total_q = +val;
    this.total_net_lb = +val;
    this.total_net_qq = +val;
    this.total_net_lb = +val;
    this.total_net_green = +val;
  }
  calculateTNL(Val) {
    this.desc_humedad = Val;
    this.total_discount = 0;
    this.inventory.desc_humedad = this.desc_humedad;
    this.descuentoHumedad = this.desc_humedad / 100;
    this.discoinDamage = this.damage / 100;
    const libras_brutas = this.gross_total_lb - this.total_tara;
    const q_brutos = this.gross_total_lb * 0.0045;
    const tara_qq: number = this.total_tara * 0.0045;
    const totalgrossqq = q_brutos - tara_qq;
    if (this.damage > 0) {
     const grossPercent = totalgrossqq * this.discoinDamage;
     const gross = totalgrossqq - grossPercent;
     this.gross_total_q = Math.floor(gross * 100) / 100;
    } else {
     this.gross_total_q = Math.floor(totalgrossqq * 100) / 100;
    }
    const total_neto = libras_brutas * this.descuentoHumedad;
    if (this.e_factor === true) {
      if (Number.isInteger(libras_brutas) && Number.isInteger(total_neto)) {
        if (this.damage > 0) {
          const total_lb = libras_brutas - total_neto;
          const damagePercent = total_lb * this.discoinDamage;
          this.total_net_lb = total_lb - damagePercent;
        } else {
          this.total_net_lb = libras_brutas - total_neto;
        }
      } else {
        if (this.damage > 0) {
          const total_lb = libras_brutas - total_neto;
          const damagePercent = total_lb * this.discoinDamage;
          this.total_net_lb = Math.floor(total_lb - damagePercent);
        } else {
          this.total_net_lb = Math.floor(libras_brutas - total_neto);
        }
      }
      if (this.factor > 0) {
        this.total_net_qq = this.total_net_lb / this.factor;
        this.total_net_green = this.total_net_qq / 1.25;
      } else {
        // this.gross_total_q = this.total_net_lb / 100;
        this.total_net_qq = this.total_net_lb / 100;
        this.total_net_green = this.total_net_qq / 1.25;
      }



      if (this.identity._id === '5f80c3091052d10488531338') {
        const totalqq = this.total_net_lb / this.factor;
        this.total_net_qq = Math.floor(totalqq * 100) / 100;
      } else if (this.identity._id === '61aff09e476d4e0bc705d23d') {
        this.total_net_qq = this.total_net_lb / this.factor;
        this.gross_total_q = this.total_net_lb / 100;
        this.total_net_green = this.total_net_qq / 1.22;
      }
    } else {
      if (Number.isInteger(libras_brutas) && Number.isInteger(total_neto)) {
        if (this.damage > 0) {
          const total_lb = libras_brutas - total_neto;
          const damagePercent = total_lb * this.discoinDamage;
          this.total_net_lb = total_lb - damagePercent;
        } else {
          this.total_net_lb = libras_brutas - total_neto;
        }
      } else {
        if (this.damage > 0) {
          const total_lb = libras_brutas - total_neto;
          const damagePercent = total_lb * this.discoinDamage;
          this.total_net_lb = Math.floor(total_lb - damagePercent);
        } else {
          this.total_net_lb = Math.floor(libras_brutas - total_neto);
        }
      }




      if (this.identity._id === '61aff09e476d4e0bc705d23d') {
        this.gross_total_q = (this.gross_total_lb - this.total_tara) / 100;
        this.total_net_qq = this.total_net_lb / 100;
        this.total_net_green = this.total_net_qq / 1.22;
      } else {
        if (this.identity._id === '5f80c3091052d10488531338') {
          const totalqq = this.total_net_lb / 100;
          this.total_net_qq = Math.floor(totalqq * 100) / 100;
        } else {
          this.total_net_qq = this.total_net_lb / 100;
          this.total_net_green = this.total_net_qq / 1.25;
        }
      }
    }
  }

  flete(flete) {
    if (flete > 0) {
      const costo = this.inventory.gross_total_q * flete;
      this.inventory.flete = +costo.toFixed(2);
    } else {
      this.inventory.flete = 0;
    }
  }

  getTotalLB(arr) {
    this.inventory.gross_total_lb = arr.reduce((sum, curr) => sum + curr.gross_total_lb, 0);
    return arr.reduce((sum, curr) => sum + curr.gross_total_lb, 0);
  }
  getTotalTara(arr) {
    // this.inventory.tare = arr.reduce((sum, curr) => sum + curr.total_tara, 0);
    return arr.reduce((sum, curr) => sum + curr.total_tara, 0);
  }
  getTotalBags(arr) {
    this.inventory.bags = arr.reduce((sum, curr) => sum + curr.sacos, 0);
    return arr.reduce((sum, curr) => sum + curr.sacos, 0);
  }

  getTotalLb(arr) {
    this.inventory.total_net_lb = arr.reduce((sum, curr) => sum + curr.total_net_lb, 0);
    return arr.reduce((sum, curr) => sum + curr.total_net_lb, 0).toFixed(2);
  }
  getTotalGrossQ(arr) {
    const xtract = _.sumBy(arr, 'gross_total_q');
    if (this.identity._id === '61aff09e476d4e0bc705d23d') {
      this.inventory.gross_total_q = xtract.toFixed(2);
      return this.inventory.gross_total_q;
    } else {
      this.inventory.gross_total_q = xtract.toFixed(2);
      return this.inventory.gross_total_q;
    }
  }
  getTotalGreen(arr) {
    const xtract = _.sumBy(arr, 'total_net_green');
    if (this.identity._id === '61aff09e476d4e0bc705d23d') {
      this.inventory.total_net_green = xtract.toFixed(2);
      return this.inventory.total_net_green;
    } else {
      this.inventory.total_net_green = xtract.toFixed(2);
      return this.inventory.total_net_green;
    }
  }
  getTotalqq(arr) {
    const xtract = _.sumBy(arr, 'total_net_qq');
    if (this.identity._id === '61aff09e476d4e0bc705d23d') {
      this.inventory.total_net_qq = xtract.toFixed(2);
      this.inventory.total_qq_dry = this.inventory.total_net_qq;
      this.total_net_qqq = this.inventory.total_net_qq;
      return this.inventory.total_net_qq;
    } else {
      if (this.identity._id === '5f80c3091052d10488531338') {
        this.inventory.total_net_qq = xtract;
        this.inventory.total_qq_dry = this.inventory.total_net_qq;
      } else {
        this.inventory.total_net_qq = xtract.toFixed(2);
        this.inventory.total_qq_dry = this.inventory.total_net_qq;
      }
      this.total_net_qqq = this.inventory.total_net_qq;
      this.getState(this.inventory.state);
      // this.inventory.observations = `${this.inventory.total_net_qq} qq de café pergámino seco.`;
      return this.inventory.total_net_qq;
    }

  }
  getState(state) {
    this.tare = 0;
    if (this.identity._id === '5ff5e3213545ae31b8d199b0') {
      if (state === 'Mojado') {
        this.tare = 1;
        this.e_factor = false;
      } else if (state === 'Seco') {
        this.valor = 0;
        this.calculateVB(0);
        this.tare = 1;
        this.e_factor = false;
      } else if (state === 'Uva') {
        this.valor = 180;
        this.calculateVB(180);
        this.tare = 1;
        this.e_factor = true;
      } else if (state === 'Húmedo') {
        this.valor = 100;
        this.calculateVB(100);
        this.tare = 1;
        this.e_factor = false;
      }
    } else if (this.identity._id === '5fa2a50e1c9a8952e6e1bc1c') {
      if (state === 'Mojado') {
        this.tare = 1;
        this.e_factor = false;
      } else if (state === 'Seco') {
        this.tare = 0.5;
        this.e_factor = false;
      } else if (state === 'Uva') {
        this.tare = 1;
        this.e_factor = true;
      } else if (state === 'Húmedo') {
        this.tare = 0.5;
        this.e_factor = false;
      }
    } else if (this.identity._id === '5d78227b91f73c2429310132') {
      if (state === 'Mojado') {
        this.tare = 0.5;
        this.e_factor = false;
      } else if (state === 'Seco') {
        this.tare = 0.5;
        this.e_factor = false;
      } else if (state === 'Uva') {
        this.tare = 1;
        this.e_factor = true;
      } else if (state === 'Húmedo') {
        this.tare = 0.5;
        this.e_factor = false;
      }
    } else {
      if (state === 'Mojado') {
        this.tare = 1;
        this.e_factor = false;
      } else if (state === 'Seco') {
        this.tare = 0.5;
        this.e_factor = false;
      } else if (state === 'Uva') {
        this.tare = 1;
        this.e_factor = true;
      } else if (state === 'Húmedo') {
        this.tare = 1;
          this.e_factor = false;
      } else if (state === 'Galones') {
        this.gallons = true;
      } else if (state === 'Oro Exportable') {
        this.tare = 0.5;
      }
    }

  }
  getCoffeefarmerId(id, origins) {
    this.inventory.coffeefarmer_name = this.coffeefarmer;
    this.inventory.coffeefarmer = id;
    if (id) {
      this.getOrigins(origins);
    }
  }
  getWarehouseId(id) {
    this.inventory.warehouse_name = this.warehouse_val;
    this.inventory.warehouse = id;
    if (id === '5ff64c38847924446366cfbe') {
      this.factor = 475;
    } else if (id === '5ff64c60847924446366cfc0') {
      this.factor = 520;
    }
  }
  getOriginId(origin) {
    this.inventory.origin = origin._id;
    this.farm = origin.name;
    this.c_select = [];
    console.log('origin seleccionado: ', origin);
    console.log(this.certs2);
    this.certs2.forEach(
      (c) => {
        origin.certifications.forEach(
          (certi) => {
            if (certi.name === c) {
              this.c_select.push(certi.name);
            }
          }
        );
      }
     );
    console.log('certificaciones de origin: ', this.c_select, this.certs2);
    if (this.identity._id === '5ff5e3213545ae31b8d199b0') {
      this.select.setValue(this.certs2);
    } else {
      this.select.setValue(this.c_select);
    }
  }
  asignT(totalqq) {
    this.inventory.total_net_qq = totalqq;
    this.inventory.total_qq_dry = totalqq;
  }
  asignlb(totallb) {
    this.inventory.total_net_lb = totallb;
  }
  asigntd(totaltd) {
    this.inventory.total_discount = totaltd;
  }
  asignTara(tara) {
    this.inventory.tare = tara;
  }
  asignLB(totaLB) {
    this.inventory.gross_total_lb = totaLB;
  }

  addDetail() {
    const newDetail: any = {
      tare: this.tare,
      sacos: this.sacos,
      gross_total_lb: Math.floor(this.gross_total_lb),
      total_tara: this.total_tara,
      factor: this.factor,
      desc_humedad: this.desc_humedad,
      damage: this.damage,
      total_net_lb: this.total_net_lb,
      total_net_qq: this.total_net_qq,
      gross_total_q: this.gross_total_q,
      total_net_green: this.total_net_green,
    };
    if (
      this.identity._id === '616661122f820561d402aecf' || // * Aruco
      this.identity._id === '5d7742116bc1d64060abfc9b'    // * Info
    ) newDetail.tareTroco = this.tareTroco ? this.tareTroco : 0;
    console.log(newDetail);
    this.inventoryDetails.push(newDetail);
    this.tareTroco = 0;
    this.factor = 0;
    this.damage = 0;
    this.desc_humedad = 0;
    this.sacos = 0;
  }

  deleteFile(inventoryDetail) {
    this.inventoryDetails = this.inventoryDetails.filter(t => t.gross_total_lb !== inventoryDetail.gross_total_lb);
  }

  validation() {
    if (this.coffeefarmer !== '' && this.origin_val !== '' && this.warehouse_val !== '') {
      this.disabled = false;
      console.log(this.disabled);
    } else {
      this.disabled = true;
      console.log(this.disabled);
    }
  }

   newDried(inventory) {
     const dried: any = {};
     dried.inventoryStatus = {
      sale: {
        total: 0
      },
      referral: {
        total: 0
      },
      production: {
        total: 0
      },
     };
 
     console.log(inventory.total_net_qq, this.inventory.total_net_qq);
     this.id.push(inventory._id);
     this.restqq.push(0);
     this.driedqq.push(this.inventory.total_net_qq);
     dried.weight_notes = this.id;
     dried.wet_quintals = this.inventory.total_net_qq;
     dried.dry_qq = this.inventory.total_net_qq;
     dried.inventoryStatus.sale.total = this.inventory.total_net_qq;
     dried.inventoryStatus.referral.total = this.inventory.total_net_qq;
     dried.inventoryStatus.production.total = this.inventory.total_net_qq;
     dried.inventory_qq = this.inventory.total_net_qq;
     dried.warehouse = inventory.warehouse;
     dried.observations = inventory.observations;
     dried.company = inventory.company;
     dried.user = inventory.user;
     dried.microlot = inventory.microlot;
     dried.restqq = this.restqq;
     dried.driedqq = this.driedqq;
     dried.hours = inventory.repose;
     dried.bags = inventory.bags;
     dried.status = 'Finalizado';
     dried.moved = true;
     dried.r_qq = 0;

     console.log(dried);

     this._driedService.addDried(dried).subscribe(
       res => {
         this.inventory.total_qq_dry = 0;
         this.inventory.dried = true;
         // this.inventory.dry = res._id;
         if (this.identity._id = '64f957caeb8de570580b171b') {

         }
         this.addSample(res);
         this._inventoryService.editInventory(inventory._id, this.inventory).subscribe(
           response => {
             this.onCancelClick();
           }
         );
       },
       error => {
         const errorMessage = <any>error;

         if (errorMessage != null) {
           const body = JSON.parse(error._body);
           this.alertMessage = body.message;
           console.log(error);
         }
       }
     );
   }

    addSample = (dry): void => {
      console.log(dry, this.inventory.varieties);
      const dried: any = dry;
      let varieties = [];
      const owner: any = {
        name: this.inventory.coffeefarmer_name,
        farm: this.farm,
      }
      varieties.push(this.inventory.variety);
      this.sample.microlot = dried.microlot;
      this.sample.type = 'Inventario seco';
      this.sample.date = this.inventory.date;
      this.sample.offer = this.inventory.offer;
      this.sample.water_activity = this.inventory.water_activity;
      this.sample.owner = owner;
      this.sample.coffeeType = 'Pergamino seco';
      this.sample.weight = 300;
      this.sample.varieties = this.inventory.varieties;
      this.sample.dryingOrder = dry._id;
      this._sampleService.addSample(this.sample).subscribe((res: Sample) => {
        console.log('muestra agregada', res);
        this.checkSampleRef(Object.assign(res, this.sample));
      }, (err: HttpErrorResponse) => console.error('Error al crear muestra', err));
    }


    checkSampleRef = (sample: Sample): void => {
      console.log('sample', sample);
      if (sample.dryingOrder) {
        let dried: any = {};
        if (!dried.inventoryStatus) {
          dried.inventoryStatus = {
            samples: [],
          };
        }
        dried.inventoryStatus.samples = dried.inventoryStatus.samples.concat(sample._id);
        console.log(dried);
        this._driedService.editDried(sample.dryingOrder, { inventoryStatus: dried.inventoryStatus }).subscribe((res2) => {
          console.log('inventario seco actualizado', dried);
        }, (err: HttpErrorResponse) => console.error('Error al actualizar inventario seco', err));
      } else if (sample.blend) {}
    }
    
   submitForm() {
    console.log(this.inventory);
    if (this.coffeefarmer !== '' && this.origin_val !== '' && this.warehouse_val !== '') {
          this.disabled = true;
          if (this.identity._id === '61aff09e476d4e0bc705d23d') {
            this.inventory.total_net_sett = this.inventory.gross_total_q;
            this.inventory.total_qq_dry = this.inventory.total_net_qq;
          }
          if (this.inventory.warehouse === "5ff64c38847924446366cfbe") {
            this.inventory.user = "5fff8483e5d53510ef67e0ee";
          } else if ( this.inventory.warehouse === "5ff64c60847924446366cfc0" ) {
            this.inventory.user = "6012e147a31da658268d0725";
          }
          else if ( this.inventory.warehouse === "61940a2d6c30754a353b95b0" ) {
            this.inventory.user = "5db752d2537e6562cdba1a63";
          }
          if (this.identity._id === '643d773bfebd7823442022cb') {
            this.inventory.total_net_sett = this.inventory.total_net_lb;
          } else {
            this.inventory.total_net_sett = this.inventory.total_net_qq;
          }
          this.inventory.total_qq_dry = this.inventory.total_net_qq;
          this.inventory.certs = this.select.value;
          this.inventory.varieties = this.varietyS.value;
          this.inventory.gallons = this.gallons;
          this.inventory.microlot = this.inventory.microlot;
          this.inventory.certifications = this.interestFormGroup.value;
          this.inventory.inventoryDetails = this.inventoryDetails;
          const bags = this.inventoryDetails.reduce((sum, curr) => sum + -curr.sacos, 0);
          this.inventory.tare = -bags;
          this.inventory.harvest = this.harvest;
          this.inventory.price = this.price;
          console.log('to add: ', this.inventory);
          this._inventoryService.addInventory(this.inventory).subscribe(
            response => {
              if (this.inventory.state === 'Seco' || this.inventory.state === 'Resaca' || this.inventory.state === 'StockLot' || this.inventory.state === 'Oro Exportable') {
                this.newDried(response);
              }
              this.alertMessage = 'Registro creado correctamente';
              this._router.navigate(['/notas-de-peso']);
              this.onCancelClick();
            },
            error => {
              const errorMessage = <any>error;

              if (errorMessage != null) {
                this.alertMessage = 'Ingrese los datos Correctamente';
                const body = JSON.parse(error._body);
                this.alertMessage = body.message;
                console.log(error);
              }
            }
          );
    } else {
      this.disabled = true;
    }
  }

  enableSlideToggle() {
    this.form.get('cb1').enable();
    this.form.get('cb2').enable();
  }

  calculateVB(valor) {
    if (valor > 0) {
      const costo = this.inventory.total_net_qq * valor;
      this.inventory.cost_per_beneficiary = costo.toFixed(2);
    } else {
      this.inventory.cost_per_beneficiary = 0;
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  onCloseConfirm() {
    this.dialogRef.close('confirm');
  }
}
