/*
    * Obtiene la configuracion de anticipos de una compania.
    * En caso que la compania no tenga una configuracion especial, se obtiene false cada campo.
    @param company: Compania a la que se obtendra la configuracion.
    @return JSON: Configuracion de los anticipos.
*/
export const getConfig = (company: string): Object => {
    let config = JSON.parse(JSON.stringify({}));
    if (ap_config.hasOwnProperty(company)) {
        config = ap_config[company];
    } else {
        config = {
            commercial_date: false,
            grace: false,
            projected: false
        };
    }
    if (special_options.hasOwnProperty(company)) {
        config['special_options'] = true;
        config = Object.assign(config, special_options[company]);
    } else {
        config['special_options'] = false;
    }
    return config;
};

/*
    * Configuracion por ID de companias, respecto a si usan dias de gracia, fecha comercial o anticipos proyectado.
    * key grace: Estado si el perfil puede usar dias de gracia.
    * key graceTypes: Tipos de anticipos excentos de usar dias de gracia.
    * key commercial_date: Estado si el perfil puede usar meses comerciales.
    * key commercialTypes: Tipos de anticipos que permite uso de meses comerciales.
    * key projected: Si la compania tiene permitido usar anticipos proyectos.
    * key projectedTypes: Tipos de anticipos que son proyectados.
*/
export const ap_config = {
    // * Gestopp Info
    '5d7742116bc1d64060abfc9b' : {
        grace: true,
        graceTypes: ['Adecuación', 'Café en Depósito', 'Crédito a largo plazo', 'Anticipo por Trabajo', 'CxC'],
        commercial_date: true,
        commercialTypes: ['Adecuación', 'Café en Depósito', 'Crédito a largo plazo', 'Anticipo por Trabajo', 'Pre-Cosecha'],
        projected: true,
        projectedTypes: ['Pre-Cosecha']
    },

    // * Cocrebistol
    '5f80c3091052d10488531338' : {
        grace: true,
        graceTypes: ['Adecuación', 'Café en Depósito', 'Crédito a largo plazo', 'Anticipo por Trabajo', 'CxC'],
        commercial_date: true,
        commercialTypes: ['Adecuación', 'Café en Depósito', 'Crédito a largo plazo', 'Anticipo por Trabajo', 'CxC', 'Pre-Cosecha', 'Crédito a Corto plazo'],
        projected: true,
        projectedTypes: ['Pre-Cosecha']
    },

    // ?
    '5fd10dfc3b56ba3e00d0f0d9' : {
        grace: true,
        graceTypes: ['Adecuación', 'Café en Depósito', 'Crédito a largo plazo', 'Anticipo por Trabajo', 'CxC'],
        commercial_date: true,
        commercialTypes: ['Adecuación', 'Pre-Cosecha'],
        projected: true,
        projectedTypes: ['Pre-Cosecha']
    },

    // * lggutierrez local
    '5fdc3b594974ab5ea65cf981' : {
        grace: true,
        graceTypes: ['Adecuación', 'Café en Depósito', 'Crédito a largo plazo', 'Anticipo por Trabajo', 'CxC'],
        commercial_date: true,
        commercialTypes: ['Adecuación', 'Pre-Cosecha'],
        projected: true,
        projectedTypes: ['Pre-Cosecha']
    }
};

export const special_options = {
    // * Gestopp Info
    '5d7742116bc1d64060abfc9b' : {
        payment_types: ['Especies'],
        types: ['Adecuación', 'CxC']
    },

    // * Cocrebistol
    '5f80c3091052d10488531338' : {
        payment_types: ['Especies'],
        types: ['Adecuación', 'CxC']
    },

    // ?
    '5fd10dfc3b56ba3e00d0f0d9' : {
        payment_types: ['Especies'],
        types: ['Adecuación', 'CxC']
    },

    // * lggutierrez (local)
    '5fdc3b594974ab5ea65cf981' : {
        payment_types: ['Especies'],
        types: ['Adecuación', 'CxC']
    },
    // * Coadecal
    '5f50fdc413c3ab1f4454c17d' : {
        payment_types: ['Fertilizante', 'Readecuacion'],
        types: ['Fertilizante']
    }
};
