import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { ProveedoresModel } from '../models/proveedoresModel';

@Injectable({
  providedIn: 'root'
})

export class ProveedoresService {

  public url:string ;
  public coffeeFamerData;

  constructor( public http: HttpClient ) {
    this.url = GLOBAL.url;
   }

   getProveedores(): Observable<any>{
     return this.http.get(`${this.url}/providers/list`);
   }

   addProveedor(proveedor: ProveedoresModel): Observable<any> {

    return this.http.post(`${this.url}/providers/new`, proveedor);
    
  }

  getProveedor(id): Observable<any> {
    return this.http.get(`${this.url}/providers/${id}`);
  }

  editProveedor(id, proveedor): Observable<any> {
    return this.http.put(`${this.url}/providers/${id}`, proveedor);
  }

  deleteProveedor(id): Observable<any> {
    return this.http.delete(`${this.url}/providers/${id}`);
  }


}



