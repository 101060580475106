import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { CertificationService } from '../../../services/certification.service';
import { Certification } from '../../../models/certification';

@Component({
  selector: 'app-certification-add',
  templateUrl: './certification-add.component.html',
  styleUrls: ['./certification-add.component.css'],
  providers: [UserService, CertificationService]
})
export class CertificationAddComponent implements OnInit {

  public titulo: string;
  public certification: any = {};
  public identity;
  public token;
  public harvest;
  public url: string;
  public alertMessage;
  public disabled = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _certificationService: CertificationService,
    public dialogRef: MatDialogRef<CertificationAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.titulo = 'Agregar Certificación';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.url = GLOBAL.url;
    this.certification = {} as Certification;
  }

  ngOnInit() {
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.harvest = this.identity.harvest;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  submitForm() {
    this.disabled = true;
    this.certification.harvest = this.harvest;
    this._certificationService.addCertification(this.certification).subscribe(
      response => {
        this.alertMessage = 'Registro creado correctamente';
        this.onCancelClick();
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          this.alertMessage = body.message;
          console.log(error);
        }
      }
    );
  }

}
