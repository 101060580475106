import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { differenceInDays } from 'date-fns';
import * as _ from 'lodash';

import { AdvancePaymentService } from '../../../../services/advancePayment.service';
import { PaymentService } from '../../../../services/payment.service';
import { AdvancePayment } from '../../../../models/advancePayment';
import { PrintService } from '../../../../services/print.service';
import { UserService } from '../../../../services/user.service';
import { GLOBAL } from '../../../../services/global';

import { Payment } from '../../../../models/payment';

@Component({
  selector: 'app-advance-payments-pay',
  templateUrl: './advance-payments-pay.component.html',
  styleUrls: ['./advance-payments-pay.component.css'],
  providers: [AdvancePaymentService, PaymentService],
})

export class AdvancePaymentsPayComponent implements OnInit, AfterViewInit {
  public form: FormGroup;
  public advancePayment: AdvancePayment;
  public totalBalance = 0;
  public currentDate;
  public company = '';
  public totalDays = 0;
  public percent = 0;
  public exceded = false;
  public currency;
  constructor(
    private fb: FormBuilder,
    private advancePaymentService: AdvancePaymentService,
    private router: Router,
    private paymentService: PaymentService,
    private _printService: PrintService,
    private _userService: UserService,
    public dialogRef: MatDialogRef<AdvancePaymentsPayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AdvancePayment,
  ) {
    this.company = this._userService.getCompany()._id;
    this.advancePayment = JSON.parse(JSON.stringify(this.data));
   }

  ngOnInit() {
    this.createForm();
    this.listernersFormValues();
    this.currency = GLOBAL.currency;
    this.totalBalance = this.data.total_balance;
    this.percent = this.data.i_porcent;
    console.log(this.data);
    this.currentDate = this.data.date;
  }

  ngAfterViewInit(): void {
    this.form.get('outstanding_balance').setValue(0);
  }

  createForm(): void {
    this.form = this.fb.group({
      date:                 ['', Validators.required],
      payment:              ['', Validators.required],
      outstanding_balance:  [{value: '', disabled: true}, Validators.required],
      observations:         [''],
      payFull:              [''],
    });
  }

  listernersFormValues(): void {
    this.form.get('payment').valueChanges.subscribe(value => {
      const payment = parseFloat(String(value).split(',').join(''));
      if (payment > this.data.total_balance) {
        this.form.controls['payment'].setErrors({'incorrect': true});
        this.exceded = true;
      } else {
        this.exceded = false;
        this.form.controls['payment'].clearValidators();
        this.form.controls['payment'].setValidators(Validators.required);
        const outstanding_balance = parseFloat(Number(Number(this.advancePayment.total_balance) - Number(payment)).toFixed(6));
        this.form.get('outstanding_balance').setValue(outstanding_balance);
      }
    });

    this.form.get('payFull').valueChanges.subscribe(status => {
      if (status) {
        this.form.controls['payment'].disable();
        this.form.get('payment').setValue(this.data.total_balance);
      } else {
        this.form.get('payment').setValue(0);
        this.form.controls['payment'].enable();
      }
    });
  }

  addPay(): void {
    if (this.company === '62cfb754502565c8e04a161c') {
      this.addPayAproccor();
    } else {
      const payment: Payment = this.form.getRawValue();
      payment['mount'] = this.advancePayment.mount;
      payment['payment'] = parseFloat(String(payment.payment).split(',').join(''));
      payment['advancePayment'] = this.data._id;
      payment['coffeefarmer'] = this.data.coffeefarmer._id;
      payment['observations'] = this.form.get('observations').value;
      console.log(payment);
      this.paymentService.addPayment(payment).subscribe(res => {
        this.dialogRef.close(res);
        if (this.advancePayment.payment_status !== 'Pagado') {
          this.advancePaymentService.recalculateAdvancePayment(this.data._id);
        }
        // this.router.navigateByUrl('/administracion/abonos');
      }, err => console.error('Error al agregar payment: ', err));
    }
  }

  addPayAproccor(): void {
    const payment: Payment = this.form.getRawValue();
    let advancePayment: any = {};
    let outstanding_balance = 0;
    let interest_paid = 0;
    let total_interest = 0;
    let capital = 0;
    const daysData: any = this.calculateDiffDays();

    console.log(daysData);
    this.totalDays = daysData.totalDays;
    const days = daysData.totalDays;
    const i = this.percent;
    const mi = this.totalBalance * i;
    const dayliInterest = parseFloat((mi / 360).toFixed(8));
    const newInterest = dayliInterest * days;
    const totalBalance = this.totalBalance + newInterest;

    payment['mount'] = this.totalBalance;
    payment['payment'] = parseFloat(String(payment.payment).split(',').join(''));
    payment['advancePayment'] = this.data._id;
    payment['coffeefarmer'] = this.data.coffeefarmer._id;
    payment['observations'] = this.form.get('observations').value;

    if (payment.payment >= newInterest) {
      outstanding_balance = totalBalance - payment.payment;
      interest_paid = newInterest;
      const paid = payment.payment - newInterest;
      total_interest = 0;
      capital = paid;
    } else if (payment.payment < newInterest) {
      outstanding_balance = totalBalance - payment.payment;
      total_interest = newInterest - payment.payment;
      interest_paid = payment.payment;
      capital = 0;
    }

    const ob = this.form.get('outstanding_balance').value;
    if (ob > 0) {
      advancePayment.payment_status = 'Pendiente';
    } else {
      advancePayment.payment_status = 'Pagado';
    }

    advancePayment.days = days + this.advancePayment.days;
    advancePayment.total_interest = total_interest;
    advancePayment.total_balance = outstanding_balance;

    payment['outstanding_balance'] = outstanding_balance;
    payment['interest_paid'] = interest_paid;
    payment['capital'] = capital;
    

    console.log(advancePayment);
    console.log(payment);

    this.paymentService.addPayment(payment).subscribe(res => {
      this.dialogRef.close(res);
      if (this.advancePayment.payment_status !== 'Pagado') {
        this.advancePaymentService.editAdvancePayment(this.data._id, advancePayment);
      }
    }, err => console.error('Error al agregar payment: ', err));
  }

  calculateDiffDays(): JSON {
    let days = 0;
    const daysData = JSON.parse(JSON.stringify({}));
    const dateIn = new Date(this.currentDate);
    dateIn.setHours(0, 0, 0, 0);
    const date = new Date(this.form.get('date').value);

    console.log(dateIn, date );

    if (dateIn && this.form.get('date').valid) {
      days = this.getDays(dateIn, date);
    } else { console.error ('Fecha invalida'); }
    daysData['totalDays'] = days;
    return daysData;
  }

  getDays(date_in: any, date_out: any): number {
    const diffDays = Math.abs(differenceInDays(date_in, date_out));
    return diffDays;
  }

  print(payment: Payment) {
    console.log('toPrintPayment: ', payment);
    const group = payment.fullbalance ? true : false;
    !payment.fullbalance ?
      this._printService.getPayment(payment._id) :
      this._printService.getPaymentBalance(payment.fullbalanceId, payment.coffeefarmer._id);
  }

}
