import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { UserService } from '../../services/user.service';
import { ProveedoresService } from '../../services/proveedores.service';
import { ProveedoresModel } from '../../models/proveedoresModel';

@Component({
  selector: 'app-proveedores-edit',
  templateUrl: './proveedores-edit.component.html',
  styleUrls: ['./proveedores-edit.component.css'],
  providers: [UserService, ProveedoresService]
})


export class ProveedoresEditComponent implements OnInit {

  public titulo: string;
  public proveedor: any = {};
  public identity;
  public alertMessage;
  public mask = ['(', '5', '0', '4', ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _proveedoresService: ProveedoresService
  ) {
    this.titulo = 'Editar Cliente';
    this.identity = this._userService.getCompany();
    this.proveedor = {} as ProveedoresModel;
  }

  ngOnInit() {
    this.getProveedor();
  }

  getProveedor() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._proveedoresService.getProveedor(id).subscribe(
        response => {
          if (!response) {
            this._router.navigate(['/']);
          } else {

            this.proveedor = response.proveedor;
            
            
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            console.log(error);
          }
        }
      );
    });
  }
  actualizarProveedor() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._proveedoresService.editProveedor(id, this.proveedor).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Proveedor actualizado correctamente';
            this._router.navigate(['/proveedores']);
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );

    });
  }

}