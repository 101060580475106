import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

import { UserService } from '../../services/user.service';
import { BankService } from '../../services/bank.service';
import { SaleService } from '../../services/sale.service';
import { BankModel } from '../../models/bankModel';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { BankAddComponent } from '../bank-add/bank-add.component';


@Component({
  selector: 'app-back',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.css'],
  providers: [UserService, BankService, SaleService]

})


export class BankComponent implements OnInit, AfterViewInit {

  public titulo: string;
  public banks: BankModel[];
  public bank = [];
  public identity;
  public alertMessage;
  closeResult: string;

  displayedColumns: string[] = ['cod', 'name', "description", 'contact', 'address', 'action'];
  highlightedRows = [];
  dataSource = new MatTableDataSource<BankModel>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _bankService: BankService,
    private _saleService: SaleService,
    private _userService: UserService,
    public dialog: MatDialog
  ) {
    this.titulo = 'Bancos';
    this.identity = this._userService.getCompany();
  }

  ngOnInit() {
    this.getBanks();
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  openDialog() {
    const dialogRef = this.dialog.open(BankAddComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getBanks();
    });
  }

  openDialogD(customer) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '350px',
      data: {
        header: '¿Estas Seguro?',
        title: '',
        text: '¿Seguro que quieres eliminar ' + customer.cod + '?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteCustomer(customer._id);
      }
    });
  }
  getBanks() {

    this._bankService.getBanks().subscribe(
      (res) => {
        this.dataSource.data = res as BankModel[];
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  delete(id) {
    this.alertMessage = 'Registo eliminado';
    this._bankService.deleteBank(id).subscribe(
      response => {
        if (!response) {
          alert('Error en el servidor');
        } else {
          this.getBanks();
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  deleteCustomer(id) {

    this.alertMessage = '';
    this._saleService.getSalePerCustomer(id).subscribe(
      res => {
        console.log(res.length);
        if (res.length === 0) {
           this.delete(id);
        } else {
          this.alertMessage = 'el cliente no se puede borrar';
        }
      }
    );
  }


}

