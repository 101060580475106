import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSelect } from '@angular/material';
import { startWith } from 'rxjs/operators/startWith';
import { ReplaySubject, Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { MatSort } from '@angular/material';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { map } from 'rxjs/operators/map';
import * as _moment from 'moment';
import 'rxjs/add/observable/of';
import * as _ from 'lodash';

import { AdvancePaymentService } from '../../../services/advancePayment.service';
import { CenterCostService } from '../../../services/center-cost.service';
import { SettlementService } from '../../../services/settlement.service';
import { InventoryService } from '../../../services/inventory.service';
import { ProductorService } from '../../../services/productor.service';
import { CategoryService } from '../../../services/category.service';
import { SeatService } from '../../../services/seat.service';
import { UserService } from '../../../services/user.service';
import { GLOBAL } from '../../../services/global';

import { PaymentService } from '../../../services/payment.service';
import { AdvancePayment } from '../../../models/advancePayment';
import { CenterCost } from '../../../models/center-cost';
import { Settlement } from '../../../models/settlement';
import { Inventory } from '../../../models/inventory';
import { Category } from '../../../models/category';
import { Payment } from '../../../models/payment';
import { Seat } from '../../../models/seat';

@Component({
  selector: 'app-settlements',
  templateUrl: './settlements.component.html',
  styleUrls: ['./settlements.component.css'],
})
export class SettlementsComponent implements OnInit, OnDestroy, AfterViewInit {
  public titulo: string;
  public settlements: Settlement[];
  public Date;
  public seat: any = {};
  public settings;
  public productor: any = {};
  public centerscost: CenterCost[];
  public centercost;
  public categories;
  public category_id;
  public currentTotalDebit;
  public currentTotalCredit;
  public currency;

  toggle = false;

  public before_inventories = [];
  public inventories: Inventory[];
  public inventory: any = {};
  public settlement: any = {};
  public payment: any = {};
  public advancePayment: any;
  public identity;
  public token;
  public url: string;
  public next_page;
  public prev_page;
  public show = false;
  public confirmado;
  closeResult: string;
  myControl: FormControl = new FormControl();
  public availableOrigin = [];
  public filteredOptions: Observable<any[]>;
  public selectedOrigin = null;
  public selectedName = '';
  public namer;
  public name;
  public valor: number;
  public valors = [];
  public cost = [];
  public cost_ben = [];
  public _ids = [];
  public _ids2 = [];
  public restAP = [];
  public balances = [];
  public balancesUpdate = [];
  public interestUpdate = [];
  public total_interest;
  public mount;
  public highlightedRows2 = [];
  public wnUpdate = [];
  public qqUpdate = [];
  public liquidated = [];
  public update = [];
  public total_deduction;
  public qqWNUpdate = [];
  public price;
  public wn_total;
  public status = false;
  np_total;
  public statusNP = false;
  disabledbtn1 = false;
  disabledbtn2 = false;
  public harvest;
  public disabled = false;
  public date_n: Date = new Date();

  myControlCF: FormControl = new FormControl();
  public availableCoffeeFarmer = [];
  public filteredOptionsCF: Observable<any[]>;
  public selectedCoffeeFarmer = null;
  public selectedNameCF = '';

  /**-------------select------------------*/
  public bankCtrl: FormControl = new FormControl();

  public bankFilterCtrl: FormControl = new FormControl();

  public data: any[];

  public filteredBanks: ReplaySubject<Category[]> = new ReplaySubject<
    Category[]
  >(1);

  @ViewChild('singleSelect') singleSelect: MatSelect;

  private _onDestroy = new Subject<void>();
  /*---------------------------------------------*/
  seatForm: FormGroup;
  totalDebit = 0;
  totalCredit = 0;
  myFormValueChanges$;
  period;
  public category: any = {};

  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  payments_status = [
    { value: 'Pendiente', viewValue: 'Pendiente' },
    { value: 'Pagado', viewValue: 'Pagado' },
  ];

  displayedColumns: string[] = ['cod', 'date', 'total_net_sett'];
  highlightedRows = [];
  public indexedAdvancePayments = JSON.parse(JSON.stringify({}));

  public dataSource = new TableVirtualScrollDataSource<Inventory>();
  public advancePayments = new TableVirtualScrollDataSource<Element>();
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort) set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
      this.advancePayments.sort = value;
    }
  }

  displayedColumnsAP: string[] = [
    'cod',
    'date',
    'days',
    'mount',
    'total_interest',
    'total_balance',
  ];
  highlightedRowsAP = [];
  ap_total;
  alertMessage: string;
  public alertTableMessage = {
    alertMessageInventory: 'Seleccione un productor para mostrar inventario.',
    inventoryColor: 'info',
    alertMessageAdvancePayment: 'Seleccione un productor para mostrar los anticipos.',
    advancePaymentColor: 'info',
  };

  /* -------- * Abono a anticipos de productor ------------- */
  public apForm: FormGroup;
  public excededPayment = false;

  highlight(element_: Element) {
    element_.highlighted = !element_.highlighted;
  }

  highlightAP(element_: ElementAP) {
    element_.highlightedAP = !element_.highlightedAP;
  }

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _settlementService: SettlementService,
    private _userService: UserService,
    private _advancePaymentService: AdvancePaymentService,
    private _inventoryService: InventoryService,
    private _productorService: ProductorService,
    private _paymentService: PaymentService,
    private _seatService: SeatService,
    private _categoryService: CategoryService,
    private _centercostService: CenterCostService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private _formBuilder: FormBuilder,
  ) {
    this.titulo = 'Liquidaciones';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.url = GLOBAL.url;
    this.inventory = {} as Inventory;
    this.advancePayment = {} as AdvancePayment;
    this.payment = {} as Payment;
    this.seat = {} as Seat;
    this.Date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.date_n = this.Date;
    this.seat = {} as Seat;
    this.category = {} as Category;
    this.bankCtrl.setValue('');
  }

  ngOnInit() {
    this.getCategories();
    this.settlement.status = 'Pendiente';
    this.settings = this.identity.settings;
    this.currency = GLOBAL.currency;
    if (this.settings) {
      this.period = this.settings.period;
    }
    const numberPatern = '^[0-9.,]+$';
    this.seatForm = this.formBuilder.group({
      description: [''],
      SeatCategory: this.formBuilder.array([this.getUnit()]),
      pending: [0],
    });
    this.myFormValueChanges$ = this.seatForm.controls[
      'SeatCategory'
    ].valueChanges;
    this.myFormValueChanges$.subscribe((SeatCategory) =>
      this.updateTotal(SeatCategory)
    );

    this.price = this.identity.price;
    console.log(this.identity._id);
    this.harvest = this.identity.harvest;
    this.settlement.coffee_price = this.price;
    this._productorService.getProductores().subscribe((productor) => {
      this.availableCoffeeFarmer = productor;
    });
    this.filteredOptionsCF = this.myControlCF.valueChanges.pipe(
      startWith(''),
      map((val) => this.filterCF(val))
    );

    this.apForm = this.formBuilder.group({
      payment:       [''],
      coffeefarmer:  [''],
      totalBalance:  [0],
      totalInterest: [0],
      totalCapital:  [0],
      updateBalance: [0]
    });
    this.listenerBalanceForm();
  }

  listenerBalanceForm(): void {
    this.apForm.get('payment').valueChanges.subscribe(value => {
      if (value <= this.settlement.total_net) {
        this.excededPayment = false;
        const updateBalance = parseFloat(Number(Number(this.apForm.get('totalBalance').value) - Number(value)).toFixed(4));
        this.apForm.get('updateBalance').setValue(updateBalance);
      } else {
        this.excededPayment = true;
        console.error('El valor excede a la capacidad de pago: ');
      }
    });
  }

  ngAfterViewInit(): void {
    document.getElementById('matTableProducersSettlements').style.height = '0';
    document.getElementById('matTableProducersSettlements').style.opacity = '0';
    document.getElementById('matTableAdvancePaymentsSettlements').style.height = '0';
    document.getElementById('matTableAdvancePaymentsSettlements').style.opacity = '0';
  }

  touggle() {
    this.toggle = ! this.toggle;
  }
  selectRow(row) {
    this.valors = [];
    this.balances = [];
    this.valor = this.price;
    if (row.highlighted === true) {
      if (this.highlightedRows.length === 0) {
        this.highlightedRows.push(row);
        this.pushIdsWN(this.highlightedRows);
      } else {
        this.highlightedRows.forEach((item, index) => {
          if (item._id === row._id) {
            this.highlightedRows.splice(index, 1);
          }
        });
        this.highlightedRows.push(row);
        this.pushIdsWN(this.highlightedRows);
      }
    } else if (row.highlighted === false) {
      this.removeWN(this._ids, row);
    }
    this.calculateValor();

    if (row.highlightedAP === true) {
      if (this.highlightedRowsAP.length === 0) {
        this.highlightedRowsAP.push(row);
        this.pushIdsAP(this.highlightedRowsAP);
      } else {
        this.highlightedRowsAP.forEach((item, index) => {
          if (item._id === row._id) {
            this.highlightedRowsAP.splice(index, 1);
          }
        });
        this.highlightedRowsAP.push(row);
        this.pushIdsAP(this.highlightedRowsAP);
      }
    } else if (row.highlightedAP === false) {
      this.removeAP(this._ids2, row);
    }
    this.calculateDeduction();
    // this.settlement.total_net = this.settlement.total - this.total_deduction;

    if (row.highlightedAP === true || row.highlighted === true) {
      // if (this.settlement.total_deductions > this.settlement.total || this._ids2.length > 1) {
      // this.partialAP();
      // } else if (this.settlement.total > this.settlement.total_deductions || this._ids.length > 1) {
      // this.partialWN();
      // }
    } else if (row.highlighted === false || row.highlightedAP === false) {
      this.removeAP(this._ids2, row);
      this.removeWN(this._ids, row);
    }
    this.cost_ben = [];
    this._ids.forEach((item, index) => {
      this._inventoryService.getInventory(item._id).subscribe((wn) => {
        if (wn.total_net_qq === wn.total_net_sett) {
          const costoA = {
            total_cost: wn.cost_per_beneficiary,
          };
          this.cost_ben.push(costoA);
        } else if (wn.total_net_qq > wn.total_net_sett) {
          const costoB = {
            total_cost: 0,
          };
          this.cost_ben.push(costoB);
        }
        this.settlement.cost_per_beneficiary = this.cost_ben.reduce(
          (sum, curr) => sum + curr.total_cost,
          0
        );
        const costo = +this.settlement.cost_per_beneficiary;
        if (!costo) {
          this.total_deduction = this.settlement.total_deductions;
        } else {
          this.total_deduction = this.settlement.total_deductions + costo;
        }
        if (this.total_deduction > this.settlement.total) {
          this.settlement.total_net = 0;
          this.settlement.total_deductions = this.settlement.total;
        } else if (this.settlement.total > this.total_deduction) {
          this.settlement.total_net =
            this.settlement.total - this.total_deduction;
        }
      });
    });
    if (this._ids.length === 0) {
      this.settlement.cost_per_beneficiary = 0;
      const costo = +this.settlement.cost_per_beneficiary;
      if (!costo) {
        this.total_deduction = this.settlement.total_deductions;
      } else {
        this.total_deduction = this.settlement.total_deductions + costo;
      }
      this.settlement.total_net = 0;
    }
  }

  ngOnDestroy(): void {
    this.myFormValueChanges$.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private filterBanks() {
    if (!this.data) {
      return;
    }
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredBanks.next(
      this.data.filter((bank) => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }
  calculateContribution(value) {
    this.settlement.total_net = this.settlement.total_net - this.settlement.contributions;
  }
  calculateFreight(value) {
    this.settlement.total_net = this.settlement.total_net - this.settlement.freight;
  }
  calculateOtherD(value) {
    this.settlement.total_net = this.settlement.total_net - this.settlement.other_deductions;
  }
  calculateExportCost(value) {
    this.settlement.total_net = this.settlement.total_net - this.settlement.export_costs;
  }
  calculateExportCert(value) {
    this.settlement.total_net = this.settlement.total_net - this.settlement.export_cert;
  }
  calculateFinancial(value) {
    this.settlement.total_net = this.settlement.total_net - this.settlement.financial_expenses;
  }
  calculateValor() {
    this.valors = [];
    this.cost = [];
    // tslint:disable-next-line:forin
    for (const i in this.highlightedRows) {
      if (this.highlightedRows[i].total_net_sett > 0) {
        const valor = {
          total_pagar: this.highlightedRows[i].total_net_sett * this.price,
          total_qq: this.highlightedRows[i].total_net_sett,
          total_qq_i: this.highlightedRows[i].total_net_sett,
          total_cost: this.highlightedRows[i].cost_per_beneficiary,
        };
        this.valors.push(valor);
        this.cost.push(valor.total_cost);
      } else if (this.highlightedRows[i].total_net_settF > 0) {
        const valor = {
          total_pagar: this.highlightedRows[i].total_net_sett * this.price,
          total_qq: this.highlightedRows[i].total_net_sett,
          total_qq_i: this.highlightedRows[i].total_net_qq,
          total_cost: this.highlightedRows[i].cost_per_beneficiary,
        };
        this.valors.push(valor);
        this.cost.push(valor.total_cost);
      } else {
        const valor = {
          total_pagar: this.highlightedRows[i].total_net_sett * this.price,
          total_qq: this.highlightedRows[i].total_net_sett,
          total_qq_i: this.highlightedRows[i].total_net_qq,
          total_cost: this.highlightedRows[i].cost_per_beneficiary,
        };
        this.valors.push(valor);
        this.cost.push(valor.total_cost);
      }
    }
    if (this.disabledbtn1 === false) {
      this.partialWN();
    } else {
      // this.partialNP();
    }
    this.settlement.total = this.valors.reduce(
      (sum, curr) => sum + curr.total_pagar,
      0
    );
    this.settlement.total_qq = 0;
    this.settlement.total_qq = this.valors.reduce(
      (sum, curr) => sum + curr.total_qq,
      0
    );
    const costo = +this.settlement.cost_per_beneficiary;
    if (!costo) {
      this.total_deduction = this.settlement.total_deductions;
    } else {
      this.total_deduction = this.settlement.total_deductions + costo;
    }
    if (this.total_deduction > this.settlement.total) {
      this.settlement.total_net = 0;
      this.settlement.total_deductions = this.settlement.total;
    } else if (this.settlement.total > this.total_deduction) {
      this.settlement.total_net = this.settlement.total - this.total_deduction;
    }
  }
  calculateDeduction() {
    this.balances = [];
    // tslint:disable-next-line:forin
    for (const i in this.highlightedRowsAP) {
      const list_balance = {
        total_balance: this.highlightedRowsAP[i].total_balance,
        total_interest: this.highlightedRowsAP[i].total_interest,
        mount: this.highlightedRowsAP[i].mount,
        projected: this.highlightedRowsAP[i].projected,
        tasa: this.highlightedRowsAP[i].i_porcent
      };
      console.log(list_balance);
      this.balances.push(list_balance);
    }

    this.settlement.total_deductions = this.balances.reduce(
      (sum, curr) => sum + curr.total_balance,
      0
    );
    this.total_interest = this.balances.reduce(
      (sum, curr) => sum + curr.total_interest,
    0);
    this.mount = this.balances.reduce(
      (sum, curr) => sum + curr.mount,
    0);
    console.log(this.total_interest);
    const costo = +this.settlement.cost_per_beneficiary;
    if (!costo) {
      this.total_deduction = this.settlement.total_deductions;
    } else {
      this.total_deduction = this.settlement.total_deductions + costo;
    }
    if (this.total_deduction > this.settlement.total) {
      this.settlement.total_net = 0;
      this.settlement.total_deductions = this.settlement.total;
    } else if (this.settlement.total > this.total_deduction) {
      this.settlement.total_net = this.settlement.total - this.total_deduction;
    }
    this.partialAP();
    this.wn_total = +this.settlement.total;
  }
  partialWN() {
    this.liquidated = [];
    this.qqUpdate = [];
    this.qqWNUpdate = [];
    for (const t_value of this.valors) {
      this.qqUpdate.push(0);
      this.qqWNUpdate.push(t_value.total_qq_i);
    }
  }
  partialAP() {
    this.balancesUpdate = [];
    this.interestUpdate = [];
    this.restAP = [];

    for (const balances of this.balances) {
      if (this.wn_total >= balances.total_balance) {
        this.wn_total = this.wn_total - balances.total_balance;
        const zero = 0;
        console.log(this.wn_total);
        this.balancesUpdate.push(zero);
        this.interestUpdate.push(zero);
        this.restAP.push(balances.total_balance);
      } else if (this.wn_total === balances.total_balance) {
        this.wn_total = this.wn_total - balances.total_balance;
        const zero = 0;
        console.log(this.wn_total);
        this.balancesUpdate.push(zero);
        this.interestUpdate.push(zero);
        this.restAP.push(balances.total_balance);
      } else if (balances.total_balance > this.wn_total) {
        this.wn_total = balances.total_balance - this.wn_total;
        console.log(this.wn_total);
        const restAP = this.wn_total - balances.total_balance;
        this.balancesUpdate.push(this.wn_total);
        this.interestUpdate.push(balances.total_interest);
        if (restAP === 0) {
          this.restAP.push(0);
        } else {
          this.restAP.push(-restAP);
        }
      }
      console.log(this.restAP);
      console.log(this.balancesUpdate);
    }
  }

  partial() {
    this.status = !this.status;
    this.disabledbtn2 = true;
    this.balancesUpdate = [];
    this.interestUpdate = [];
    this.restAP = [];
    this.wn_total = +this.settlement.total_deductions;

    for (const balances of this.balances) {
/*       if (Number.isNaN(this.wn_total)) {
        this.wn_total = 0;
      } else {
        this.wn_total = 0;
        this.wn_total = +this.settlement.total_deductions;
      } */
      if (this.wn_total >= balances.total_balance) {
        this.wn_total = this.wn_total - balances.total_balance;
        const zero = 0;
        console.log(this.wn_total);
        this.balancesUpdate.push(zero);
        this.interestUpdate.push(zero);
        this.restAP.push(balances.total_balance);
      } else if (this.wn_total === balances.total_balance) {
        this.wn_total = this.wn_total - balances.total_balance;
        const zero = 0;
        console.log(this.wn_total);
        this.balancesUpdate.push(zero);
        this.interestUpdate.push(zero);
        this.restAP.push(balances.total_balance);
      } else if (balances.total_balance > this.wn_total) {
        this.wn_total = balances.total_balance - this.wn_total;
        console.log(this.wn_total);
        const restAP = this.wn_total - balances.total_balance;
        this.balancesUpdate.push(this.wn_total);
        this.interestUpdate.push(balances.total_interest);
        if (restAP === 0) {
          this.restAP.push(0);
        } else {
          this.restAP.push(-restAP);
        }
      }
      console.log(this.restAP);
      console.log(this.balancesUpdate);
    }
    const costo = +this.settlement.cost_per_beneficiary;
    if (!costo) {
      this.total_deduction = this.settlement.total_deductions;
    } else {
      this.total_deduction = this.settlement.total_deductions + costo;
    }
    if (this.total_deduction > this.settlement.total) {
      this.settlement.total_net = 0;
      this.settlement.total_deductions = this.settlement.total;
    } else if (this.settlement.total > this.total_deduction) {
      this.settlement.total_net = this.settlement.total - this.total_deduction;
    }
  }

  partialNP() {
    this.statusNP = !this.statusNP;
    this.disabledbtn1 = true;
    this.qqUpdate = [];
    this.qqWNUpdate = [];
    this.liquidated = [];
    this.np_total = 0;
    this.np_total = +this.settlement.total_qq;

    for (const t_value of this.valors) {
      if (this.np_total >= t_value.total_qq_i) {
        this.np_total = this.np_total - t_value.total_qq_i;
        const zero = 0;
        this.qqUpdate.push(zero);
        this.qqWNUpdate.push(t_value.total_qq_i);
      } else if (t_value.total_qq_i > this.np_total) {
        this.np_total = t_value.total_qq_i - this.np_total;
        const restqq = this.np_total - t_value.total_qq_i;
        this.qqUpdate.push(this.np_total);
        this.qqWNUpdate.push(-restqq);
      }
    }
    this.reData(this.qqUpdate, this.qqWNUpdate);
    this.calculatePago();
    console.log(this.qqUpdate);
    console.log(this.qqWNUpdate);
  }

  reData(update, highlightedRowsUpdate) {
    this.dataSource.data.forEach((element, index) => {
      this.highlightedRows.forEach((row, i) => {
        if (element._id === row._id) {
          const reData = {
            _id: element._id,
            cod: element.cod,
            date: element.date,
            total_net_qq: element.total_net_qq,
            total_net_settF: update[i],
            total_net_sett: highlightedRowsUpdate[i],
            cost_per_beneficiary: element.cost_per_beneficiary,
            /*             warehouse: element.warehouse,
            origin: element.origin, */
          };
          const newArray = Object.assign([], this.dataSource.data, {
            [index]: reData,
          });
          this.dataSource.data = newArray;
        }
      });
    });

    this.highlightedRows.forEach((element, i) => {
      const rows = {
        _id: element._id,
        cod: element.cod,
        date: element.date,
        total_net_qq: element.total_net_qq,
        total_net_settF: update[i],
        total_net_sett: highlightedRowsUpdate[i],
        cost_per_beneficiary: element.cost_per_beneficiary,
        /*           warehouse: element.warehouse,
          origin: element.origin, */
      };

      const highlightedRowsUpdateArray = Object.assign(
        [],
        this.highlightedRows,
        { [i]: rows }
      );
      this.highlightedRows = highlightedRowsUpdateArray;
    });
  }

  removeWN(id, row) {
    id.forEach((item, index) => {
      if (item._id === row._id) {
        this.highlightedRows.splice(index, 1);
        id.splice(index, 1);
        if (Object.keys(this.highlightedRows).length === 0) {
          this.highlightedRows = [];
        }
      }
    });
  }
  removeAP(id, row) {
    id.forEach((item, index) => {
      if (item._id === row._id) {
        this.highlightedRowsAP.splice(index, 1);
        id.splice(index, 1);
        if (Object.keys(this.highlightedRowsAP).length === 0) {
          this.highlightedRowsAP = [];
        }
      }
    });
  }
  pushIdsWN(highlightedRows) {
    this._ids = [];
    // tslint:disable-next-line:forin
    for (const i in highlightedRows) {
      const ids = {
        _id: highlightedRows[i]._id,
      };
      this._ids.push(ids);
    }
  }
  pushIdsAP(highlightedRowsAP) {
    this._ids2 = [];
    // tslint:disable-next-line:forin
    for (const i in highlightedRowsAP) {
      const ids = {
        _id: highlightedRowsAP[i]._id,
      };
      this._ids2.push(ids);
    }
  }
  filterCF(val: any): any[] {
    return this.availableCoffeeFarmer.filter(
      (productor) =>
        productor.name.toLowerCase().indexOf(val.toLowerCase()) > -1
    );
  }

  getCoffeefarmerId(id, name) {
    this._inventoryService
      .getInventoryPerCoffeeFarmerWithoutPopulation(id)
      .subscribe((data) => {
      console.error('data inventory: ', data);
        this.dataSource.data = data as Inventory[];
        if (this.dataSource.data.length > 0) {
          document.getElementById('matTableProducersSettlements').style.height = '25rem';
          document.getElementById('matTableProducersSettlements').style.opacity = '1';
          this.alertTableMessage.alertMessageInventory = '';
        } else {
          document.getElementById('matTableProducersSettlements').style.height = '0';
          document.getElementById('matTableProducersSettlements').style.opacity = '0';
          this.alertTableMessage.alertMessageInventory = `No hay inventario que mostrar para el productor ${name}`;
          this.alertTableMessage.inventoryColor = 'secondary';
        }
      });
    this._advancePaymentService
      .getAdvancePaymentPerCoffeeFarmer(id)
      .subscribe((data) => {
        this.advancePayments.data = data;
        if (this.advancePayments.data.length > 0) {
          this.indexedAdvancePayments = (data as AdvancePayment[]).reduce((acc, curr) => ({
            ...acc,
            [curr._id]: curr,
          }), {});
          // document.getElementById('matTableAdvancePaymentsSettlements').style.display = 'block';
          document.getElementById('matTableAdvancePaymentsSettlements').style.height = '25rem';
          document.getElementById('matTableAdvancePaymentsSettlements').style.opacity = '1';
          this.alertTableMessage.alertMessageAdvancePayment = '';
        } else {
          document.getElementById('matTableAdvancePaymentsSettlements').style.height = '0';
          document.getElementById('matTableAdvancePaymentsSettlements').style.opacity = '0';
          this.alertTableMessage.alertMessageAdvancePayment = `No hay anticipos que mostrar para el productor ${name}`;
          this.alertTableMessage.advancePaymentColor = 'secondary';
        }
        /*       for (let i = 0; i < 10; i ++ ) {
        this.advancePayments.data = this.advancePayments.data.concat(data);
      } */
    });

    this._advancePaymentService.getBalancePerCoffeefarmer(id).subscribe(res => {
      console.log('Saldo de productor: ', res);
      this.apForm.get('totalBalance').setValue(res.totalBalance);
      this.apForm.get('totalCapital').setValue(res.totalCapital);
      this.apForm.get('totalInterest').setValue(res.totalInterest);
      this.apForm.get('coffeefarmer').setValue(id);
    }, err => console.error('Error al obtener saldo de productor: ', err));
    this.settlement.coffeefarmer_id = id;
    this.payment.coffeefarmer = id;
    this.settlement.coffeefarmer = name;
    this.qqUpdate = [];
    this.qqWNUpdate = [];
    this.show = true;
  }
  calculatePago() {
    this.valors = [];
    this.cost = [];
    this.valor = this.price;
    // tslint:disable-next-line:forin
    for (const i in this.highlightedRows) {
      const valor = {
        total_pagar: this.highlightedRows[i].total_net_sett * this.price,
        total_qq: this.highlightedRows[i].total_net_sett,
        total_qq_i: this.highlightedRows[i].total_net_qq,
        total_cost: this.highlightedRows[i].cost_per_beneficiary,
      };
      this.valors.push(valor);
      this.cost.push(valor.total_cost);
    }
    this.settlement.total = this.valors.reduce(
      (sum, curr) => sum + curr.total_pagar,
      0
    );
    if (this.total_deduction > this.settlement.total) {
      this.settlement.total_net = 0;
      this.settlement.total_deductions = this.settlement.total;
    } else if (this.settlement.total > this.total_deduction) {
      this.settlement.total_net = this.settlement.total - this.total_deduction;
    }
    this.settlement.total_qq = this.valors.reduce(
      (sum, curr) => sum + curr.total_qq,
      0
    );
  }

  update_wn() {
    this._ids.forEach((item, index) => {
      console.log(this.qqUpdate[index]);
      if (this.qqUpdate[index] === 0) {
        this.inventory.total_net_sett = 0;
        this.inventory.entry = 'Propio';
        this.inventory.liquidated = true;
      } else {
        this.inventory.total_net_sett = this.qqUpdate[index];
        this.inventory.entry = 'Depósito';
        this.inventory.liquidated = false;
      }
      console.log(this.inventory);
      this._inventoryService
        .editInventory(item._id, this.inventory)
        .subscribe(update_wn => {
          console.log(update_wn);
        });
    });
  }

  seatAdd(categories, id) {
    this.seat.abbreviation = 'LQ';
    this.seat.date_voucher = this.settlement.date;
    this.seat.date_voucher_n = this.settlement.date.getTime();
    this.seat.description = this.settlement.observations;
    this.seat.settlement = id;
    this.seat.total_debit = this.totalDebit;
    this.seat.total_credit = this.totalCredit;
    this.seat.period = this.period;
    let limit = 0;
    categories.forEach((item) => {
      limit++;
      this._categoryService.getCategory(item.category).subscribe((res) => {
        if (isNaN(res.category.balance_area)) {
          console.log('no hay nada');
          if (
            res.category.description === 'Activo' ||
            res.category.description === 'Gastos' ||
            res.category.description === 'Costos y Gastos' ||
            res.category.description === 'Costos'
          ) {
            this.category.balance_area = item.debit - item.credit;
          } else if (
            res.category.description === 'Pasivos' ||
            res.category.description === 'Capital' ||
            res.category.description === 'Ingresos'
          ) {
            this.category.balance_area = item.credit - item.debit;
          }
          this._categoryService
            .editCategory(item.category, this.category)
            .subscribe((resp) => {
              console.log('listo');
            });
        } else {
          if (
            res.category.description === 'Activo' ||
            res.category.description === 'Gastos' ||
            res.category.description === 'Costos'
          ) {
            const saldo = item.debit - item.credit;
            this.category.balance_area = res.category.balance_area + saldo;
          } else if (
            res.category.description === 'Pasivos' ||
            res.category.description === 'Capital' ||
            res.category.description === 'Ingresos'
          ) {
            const saldo = item.credit - item.debit;
            this.category.balance_area = res.category.balance_area + saldo;
          }
          this._categoryService
            .editCategory(item.category, this.category)
            .subscribe((resp) => {
              console.log('listo');
            });
        }
      });
    });
    if (limit === categories.length) {
      console.log('creado');
      this._seatService.addSeat(this.seat).subscribe(
        (re) => {
          console.log('Asiento creado correctamente');
         if (this._ids2.length === 0) {
           this._router.navigate(['/administracion/liquidaciones']);
        } else {
           this._ids2.forEach((item, index) => {
            console.log(this.balancesUpdate[index]);
            if (this.balancesUpdate[index] === 0) {
              this.payment.outstanding_balance = this.balancesUpdate[index];
              // this.payment.mount = this.balances[index].total_balance;
              this.payment.payment = this.balances[index].total_balance;
            } else {
              this.payment.outstanding_balance = this.balancesUpdate[index];
              // this.payment.mount = this.balances[index].total_balance;
              this.payment.payment = this.restAP[index];
            }
            this.payment.liquidation = id;
            this.payment.advancePayment = item._id;
            this.payment.date = this.settlement.date;

            const total_interest = this.indexedAdvancePayments[item._id].total_interest; // Valor de interes proveniente de BD
            const total_balance = this.indexedAdvancePayments[item._id].total_balance; // Valor de saldo proveniente de BD
            const new_total_balance = this.balancesUpdate[index];  // Nuevo saldo ???
            const payment = this.restAP[index]; // Abono ?
            if ((total_interest - payment) < 0) {
              this.payment.capital = (total_balance - total_interest) - new_total_balance;
              this.payment.interest_paid = total_interest;
            } else {
              this.payment.interest_paid = payment;
              this.payment.capital = 0;
            }

            console.log('Abono con asiento: ', this.payment);
            this._paymentService.addPayment(this.payment).subscribe((res) => {
              if (!res) {
                this.alertMessage = 'Error en el servidor';
              } else {
                // this.seatPaymentAdd(res._id, this.payment.payment);
                if (this.balancesUpdate[index] === 0) { // Saldos sobrantes ????
                  this.advancePayment.payment_status = 'Pagado';
                  this.advancePayment.liquidated = true;
                  this.advancePayment.total_interest = 0;
                  this.advancePayment.total_balance = this.balancesUpdate[index];
                } else {
                  this.advancePayment.payment_status = 'Parcial';
                  if  (this.restAP[index] > this.balances[index].total_interest) {
                    if (this.balances[index].projected === true) {
                      console.log('Proyectado');
                      this.advancePayment.total_interest = this.balances[index].total_interest - this.restAP[index];
                    } else {
                      this.advancePayment.total_interest = 0;
                      this.advancePayment.current_capital = this.balancesUpdate[index];
                      const mi = this.balancesUpdate[index] * this.balances[index].tasa;
                      const tm = mi / 12;
                      this.advancePayment.interest = parseFloat(Number(tm / 30).toFixed(8));
                    }
                  } else {
                    this.advancePayment.total_interest = this.balances[index].total_interest - this.restAP[index];
                  }
                  this.advancePayment.total_balance = this.balancesUpdate[index];
                  this.advancePayment.liquidated = false;
                }
                this.advancePayment.liquidation = id;
                this.advancePayment.payment = res._id;
                console.log('Anticipo con asiento: ', this.advancePayment);
                this._advancePaymentService.editAdvancePayment(item._id, this.advancePayment).subscribe((resp) => {
                  console.log('======= Anticipo editado ======= \n Recalculando anticipo...');
                  if (!resp) {
                      this.alertMessage = 'Error en el servidor';
                    } else {
                      this._advancePaymentService.recalculateAdvancePayment(item._id);
                      this._router.navigate(['/administracion/liquidaciones']);
                    }
                });
              }
            });
          });
         }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

    seatPaymentAdd(id, payment) {
    this.seat.abbreviation = 'AB';
    const number_date = Date.now();
    this.seat.date_voucher_n = number_date;
    this.seat.date_voucher = this.Date;
    this.seat.description = this.settlement.observations;
    this.seat.center_cost = this.centercost;
    this.seat.payment = id;
    this.seat.total_debit = payment;
    this.seat.total_credit = payment;
    this.seat.SeatCategory = [{
      category: this.settings.payment.categoryA,
      debit: +payment,
      credit: 0,
      description: this.settlement.observations
    },
    {
      category: this.settings.payment.categoryP,
      debit: 0,
      credit: +payment,
      description: this.settlement.observations
    }];
    this._seatService.addSeat(this.seat).subscribe(
      seatAB => {
        this.alertMessage = 'Registro creado correctamente';
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          this.alertMessage = body.message;
          console.log(error);
        }
      }
    );
  }

  private getUnit() {
    const numberPatern = '^[0-9.,]+$';
    return this.formBuilder.group({
      category: ['', Validators.required],
      description: [''],
      debit: ['', [Validators.pattern(numberPatern)]],
      credit: ['', [Validators.pattern(numberPatern)]],
    });
  }

  addUnit() {
    const control = <FormArray>this.seatForm.controls['SeatCategory'];
    control.push(this.getUnit());
  }

  removeUnit(i: number) {
    const control = <FormArray>this.seatForm.controls['SeatCategory'];
    control.removeAt(i);
  }

  clearAllUnits() {
    const control = <FormArray>this.seatForm.controls['SeatCategory'];
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    control.clearValidators();
    control.push(this.getUnit());
  }

  private updateTotal(SeatCategory: any) {
    const control = <FormArray>this.seatForm.controls['SeatCategory'];
    this.totalDebit = 0;
    this.totalCredit = 0;
    // tslint:disable-next-line: forin
    for (const i in SeatCategory) {
      if (SeatCategory[i].debit === '') {
        SeatCategory[i].debit = 0;
        // control.at(+i).get('debit').disable();
      }
      if (SeatCategory[i].credit === '') {
        SeatCategory[i].credit = 0;
        // control.at(+i).get('credit').disable();
      }
      this.currentTotalDebit = SeatCategory[i].debit;
      this.currentTotalCredit = SeatCategory[i].credit;

      this.totalDebit += this.currentTotalDebit;
      this.totalCredit += this.currentTotalCredit;
    }
  }

  getCategories() {
    this._categoryService.getCategories().subscribe(
      (res) => {
        const categories = res as Category[];
        this.data = _.filter(categories, { type: 'Detalle' });
        this.bankCtrl.setValue('');

        this.filteredBanks.next(this.data.slice());

        this.bankFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterBanks();
          });
      },
      (error) => {
        const errorMessage = <any>error;
        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }
  Apayment(id) {
    this.alertMessage = 'Asiento creado correctamente';
    console.log('balances submit abonos sin asiento: ', this.balancesUpdate);
    this._ids2.forEach((item, index) => {
      console.log(this.balancesUpdate[index]);
      if (this.balancesUpdate[index] === 0) {
        this.payment.outstanding_balance = this.balancesUpdate[index];
        this.payment.mount = this.balances[index].total_balance;
        this.payment.payment = this.balances[index].total_balance;
      } else {
        this.payment.outstanding_balance = this.balancesUpdate[index];
        this.payment.mount = this.balances[index].total_balance;
        this.payment.payment = this.restAP[index];
      }
      this.payment.liquidation = id;
      this.payment.advancePayment = item._id;
      this.payment.date = this.settlement.date;

      const total_interest = this.indexedAdvancePayments[item._id].total_interest; // Valor de interes proveniente de BD
      const total_balance = this.indexedAdvancePayments[item._id].total_balance; // Valor de saldo proveniente de BD
      const new_total_balance = this.balancesUpdate[index];
      const payment = this.restAP[index]; // Abono ?
      if ((total_interest - payment) < 0) {
        this.payment.capital = (total_balance - total_interest) - new_total_balance;
        this.payment.interest_paid = total_interest;
      } else {
        this.payment.interest_paid = payment;
        this.payment.capital = 0;
      }

      console.log('Abono sin asiento: ', this.payment);
      this._paymentService.addPayment(this.payment).subscribe((res) => {
        if (!res) {
          this.alertMessage = 'Error en el servidor';
        } else {
          // this.seatPaymentAdd(res._id, this.payment.payment);
          if (this.balancesUpdate[index] === 0) {
            this.advancePayment.payment_status = 'Pagado';
            this.advancePayment.liquidated = true;
            this.advancePayment.total_interest = 0;
            this.advancePayment.total_balance = this.balancesUpdate[index];
          } else {
            this.advancePayment.payment_status = 'Parcial';
            if  (this.restAP[index] > this.balances[index].total_interest) {
              if (this.balances[index].projected === true) {
                console.log('Proyectado');
                this.advancePayment.total_interest = this.balances[index].total_interest - this.restAP[index];
              } else {
                this.advancePayment.total_interest = 0;
                this.advancePayment.current_capital = this.balancesUpdate[index];
                const mi = this.balancesUpdate[index] * this.balances[index].tasa;
                const tm = mi / 12;
                this.advancePayment.interest = parseFloat(Number(tm / 30).toFixed(8));
              }
            } else {
              this.advancePayment.total_interest = this.balances[index].total_interest - this.restAP[index];
            }
            this.advancePayment.total_balance = this.balancesUpdate[index];
            this.advancePayment.liquidated = false;
          }
          this.advancePayment.liquidation = id;
          this.advancePayment.payment = res._id;
          console.log('Anticipo sin asiento: ', this.advancePayment);
          this._advancePaymentService.editAdvancePayment(item._id, this.advancePayment).subscribe(() => {
            console.log('======= Anticipo editado ======= \n Recalculando anticipo...');
            this._advancePaymentService.recalculateAdvancePayment(item._id);
            this._router.navigate(['/administracion/liquidaciones']);
          });
        }
      });
    });
  }
  submitForm() {
    this.disabled = true;
    this.settlement.coffee_price = this.price;
    this.settlement.weight_notes = this._ids;

    this.settlement.advancePayments = this._ids2;
    this.payment.weight_notes = this._ids;
    this.settlement.qqForWn = this.qqWNUpdate;
    this.settlement.restForWn = this.qqUpdate;
    this.settlement.costs_per_beneficiary = this.cost;
    this.settlement.pending = this.seatForm.get('pending').value;
    this.settlement.status = this.settlement.pending > 0 ? 'Pendiente' : this.settlement.status;
    console.log(this.settlement);
    this.update_wn();
    this.payment.advancePayments = this._ids2;
    this.advancePayment.weight_notes = this._ids;
    this.settlement.harvest = this.harvest;
    this.advancePayment.harvest = this.harvest;
    this.payment.harvest = this.harvest;
    this.payment.observations = this.settlement.observations;
    const categories = this.seatForm.controls['SeatCategory'].value.map(
      (x) => x
    );
    this.seat = this.seatForm.value;
    console.log('toAdd: ', this.settlement);
    this._settlementService.addSettlements(this.settlement).subscribe(
      (response) => {
        console.log('Liquidacion creada: ', response);
        this.payInTotalBalance(response._id);
        if (!response) {
          this.alertMessage = 'Error en el servidor';
        } else {
          if (this._ids2.length === 0) {
            if (this.toggle === true) {
              this.seatAdd(categories, response._id);
            } else {
              this._router.navigate(['/administracion/liquidaciones']);
            }
          } else {
            if (this.toggle === true) {
              this.seatAdd(categories, response._id);
            } else {
              this.Apayment(response._id);
            }
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  payInTotalBalance(settlementId: string): void {
    // * coadecal, info
    if (this.identity._id === '5f50fdc413c3ab1f4454c17d') {
      const data = this.apForm.getRawValue();
      console.log('\n\n\n\n REALIZANDO ABONOS A DEUDA TOTAL\n\n');
      if (data.payment > 0) {
        this._advancePaymentService.getAdvancePaymentPerCoffeeFarmer(data.coffeefarmer).subscribe((advancePayments: AdvancePayment[]) => {
          let restValue = data.payment;
          for (let i = 0; i < advancePayments.length; i++) {
            const advancePayment = advancePayments[i];
            const payment: any = {};
            payment['date'] = this.settlement.date;
            payment['liquidation'] = settlementId;
            payment['coffeefarmer'] = data.coffeefarmer;
            payment['advancePayment'] = advancePayment._id;
            payment['mount'] = advancePayment.mount;

            // * El valor a abonar es mayor al saldo del anticipo
            if (parseFloat(Number(advancePayment.total_balance).toFixed(2)) < parseFloat(Number(restValue).toFixed(2))) {
              console.log('El abono es mayor al saldo: ', advancePayment.total_balance, restValue);
              payment['payment'] = advancePayment.total_balance;
              restValue = parseFloat(Number(Number(restValue) - Number(advancePayment.total_balance)).toFixed(4));
              this._paymentService.addPayment(payment).subscribe(res => {
                console.log('Abono realizado: ', res);
                this._advancePaymentService.recalculateAdvancePayment(advancePayment._id, true);
              }, err => console.error('Error al efectuar el abono: ', err));
              continue;

            // * El valor a abonar es menor o igual al saldo.
            } else {
              console.log('El abono es menor o igual al saldo: ', advancePayment.total_balance, restValue);
              payment['payment'] = restValue;
              this._paymentService.addPayment(payment).subscribe(res => {
                console.log('Abono realizado: ', res);
                this._advancePaymentService.recalculateAdvancePayment(advancePayment._id, true);
              }, err => console.error('Error al efectuar el abono: ', err));
              break;
            }
          }
        }, err => console.error('Error al obtener anticipos por productor: ', err));
      }
    }
  }

}
export interface Element {
  checked: boolean;
  cod: number;
  date: string;
  total_net_qq: number;
  total_net_sett: number;
  origin: any;
  highlighted?: boolean;
  hovered?: boolean;
}

export interface ElementAP {
  checkedAP: boolean;
  payment_status: string;
  coffeefarmer: any;
  observations: string;
  total_balance: string;
  highlightedAP?: boolean;
  hoveredAP?: boolean;
}

