import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Sidebar, AppSidebar, SidebarLayout } from '../models/sidebar';
import * as _ from 'lodash';
import { filter, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})

export class SidebarManagerService implements OnDestroy {
  public sidebars: BehaviorSubject<Sidebar> = new BehaviorSubject<Sidebar>({});
  public defaultLayout: SidebarLayout = {
    width: 30,
    position: 'right-top-x',
    height: 'auto'
  };

  // * Obtener el estado abierto de un sidebar.
  // tslint:disable-next-line:whitespace
  isOpen = (name: string): boolean => this.sidebarsIndex[name].open.value;

  // * Visualiza un sidebar dado su nombre y si tiene datos de entrada.
  open = (name: string, input = null): void => {
    const sidebars = this.sidebarsIndex;
    const sidebar = sidebars[name] as AppSidebar;
    sidebar.open = new BehaviorSubject<boolean>(true);
    sidebar.close = new BehaviorSubject<any>(false);
    sidebar.data.input = input;
    this.sidebars.next(sidebars);
  }

  // * Cierra un sidebar dado su nombre y si tiene datos a devolver.
  close = (name: string, output = null): void => {
    const sidebars = this.sidebarsIndex;
    const sidebar = sidebars[name];
    sidebar.data.output = output;

    this.sidebars.next(sidebars);
    sidebar.open.next(false);
    sidebar.close.next(true);

    delete sidebar.close;
    delete sidebar.open;
  }

  // * Se establece la lista de sidebars a manejar junto a su configuracion.
  setSidebarsList = (list: AppSidebar[]): void => {
    const sidebars = this.sidebarsIndex;
    this.sidebars = new BehaviorSubject<Sidebar>({});
    list.forEach((item: AppSidebar) => {
      const app: AppSidebar = {
        title: item.title,
        layout: Object.assign(_.cloneDeep(this.defaultLayout), item.layout),
        data: {
          output: null,
          input: null
        }
      };
      sidebars[item.name] = app;
    });
    this.sidebars.next(sidebars);
  }

  // * Observa el estado de un sidebar, devolviendo una data siendo el caso.
  afterClosed = (sidebar: string): Observable<any> =>
    this.getSidebarData(sidebar).close.pipe(
      filter((status: boolean) => status),      // * Filtrar solo si close === true;
        map(() => this.getOutput(sidebar))      // * Devuelve el output del evento close.
    )

  // * Obtiene la data de salida de un sidebar.
  // tslint:disable-next-line:whitespace
  getOutput = (sidebar: string): any => this.getSidebarData(sidebar).data.output;

  // * Obtiene la data de salida de un sidebar.
  // tslint:disable-next-line:whitespace
  getInput = (sidebar: string): any => this.getSidebarData(sidebar).data.input;

  // * Obtiene los datos de un sidebar dado su nombre.
  getSidebarData = (name: string): AppSidebar => this.sidebarsIndex[name];

  get sidebarsIndex(): Sidebar {
    return this.sidebars.value;
  }

  ngOnDestroy(): void { }
}
