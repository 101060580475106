import { Router } from '@angular/router';
import { Referral } from './../../../../../models/referral';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect, MatDialog } from '@angular/material';
import { CertificationService } from '../../../../../services/certification.service';
import { CustomerService } from '../../../../../services/customer.service';
import { ExporterService } from '../../../../../services/exporter.service';
import { BrokerService } from '../../../../../services/broker.service';
import { DriedService } from '../../../../../services/dried.service';
import { SaleService } from '../../../../../services/sale.service';
import { ReferralService } from '../../../../../services/referral.service';
import { GLOBAL } from '../../../../../services/global';

import { Certification } from '../../../../../models/certification';
import { Exporter } from '../../../../../models/exporter';
import { Customer } from '../../../../../models/customer';
import { Broker } from '../../../../../models/broker';
import { Dried } from '../../../../../models/dried';
import { Sale } from '../../../../../models/sale';
import { saleConstraint } from '../../../../../helpers/partialControl';
import { UserService } from '../../../../../services/user.service';
import { CollectionCenterService } from '../../../../../services/collectionCenter.service';

import 'rxjs/add/observable/of';
import * as _ from 'lodash';
@Component({
  selector: 'app-step-re-three',
  templateUrl: './step-re-three.component.html',
  styleUrls: ['./step-re-three.component.css']
})
export class StepThreeReComponent implements OnInit, AfterViewInit, OnDestroy {

  public identity;
  public identi: any;
  public ident: any;
  public payments_status = [
    { value: 'Depósito', viewValue: 'Depósito' },
    { value: 'Parcial', viewValue: 'Parcial' },
    { value: 'Pagado', viewValue: 'Pagado' }
  ];

  public filteredCertifications: Certification[];
  public filteredExporters: Exporter[];
  public filteredCurtomers: Customer[];
  public filteredBrokers: Broker[];
  public certificationsData: Certification[];
  public exportersData: Exporter[];
  public customersData: Customer[];
  public brokersData: Broker[];

  /**-------------select------------------*/
  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  public data: any[];
  @ViewChild('singleSelect') singleSelect: MatSelect;
  private _onDestroy = new Subject<void>();
  /*---------------------------------------------*/

  public currency;
  myFormValueChanges$;
  referral: any = {};
  public indexSelected = {};
  public save = true;
  public collectionCenter: any;
  public cashCountId;
  form: FormGroup;

  constructor(
    private _userService: UserService,
    private fb: FormBuilder,
    private _certificationService: CertificationService,
    private _exporterService: ExporterService,
    private _customerService: CustomerService,
    private _brokerService: BrokerService,
    private _driedService: DriedService,
    private _saleService: SaleService,
    private _referralService: ReferralService,
    private _collectionCenterService: CollectionCenterService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.identity = this._userService.getCompany();
    this.ident = this._userService.getIdentity();
    const data = localStorage.getItem('referral');
    const index = localStorage.getItem('indexSelectedDrieds');
    this.indexSelected = index !== null ? JSON.parse(index) : {};
    this.referral = data !== null ? JSON.parse(data) : {};
    this.referral.tbags = this.referral.bags;
    this.referral.trend = this.referral.trend;
    this.referral.tpb = this.referral.total_qq_ps;
    this.referral.tpn = this.referral.total_qq_net;
    this.referral.ttare = this.referral.total_tare;
    console.log('referral step 3: ', this.referral);
  }

  ngOnInit() {
    this._userService.getUser(this.ident._id).subscribe(res => {
      this.identi = res;
      console.log(res);
      if (this.identi.collectionCenter) {
        this._collectionCenterService.getCollectionCenter(this.identi.collectionCenter).subscribe( res => {
          this.collectionCenter = res;
          console.log(this.collectionCenter);
          this.referral.cashCount = this.collectionCenter.cashCount._id;
          this.referral.collectionCenter = this.identi.collectionCenter;
        });
      }
    });
    this.createForm();
    this.getDataFromServices();
    setTimeout(() => {
      this.listenersFormChanges();
    }, 1000);
    this.currency = GLOBAL.currency;
    this.form.get('tbags').setValue(this.referral.bags);
    this.form.get('trend').setValue(this.referral.trend);
    this.form.get('tpb').setValue(this.referral.total_qq_ps);
    this.form.get('tpn').setValue(this.referral.total_qq_net);
    this.form.get('ttare').setValue(this.referral.total_tare);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.form.patchValue(this.referral);
    }, 100);
  }

  listenersFormChanges() {
    this.form.get('customerName').valueChanges.subscribe(name => {
      this.filteredCurtomers = this.customersData
      .filter((customer: Customer) => this.existString(customer.name, name));
    });

    this.form.get('certificationName').valueChanges.subscribe(name => {
      this.filteredCertifications = this.certificationsData
      .filter((certification: Certification) => this.existString(certification.name, name));
    });

    this.form.get('brokerName').valueChanges.subscribe(name => {
      this.filteredBrokers = this.brokersData
      .filter((broker: Broker) => this.existString(broker.name, name));
    });

    this.form.get('exporterName').valueChanges.subscribe(name => {
      this.filteredExporters = this.exportersData
      .filter((exporter: Exporter) => this.existString(exporter.name, name));
    });

  }


  createForm(): void {
    this.form = this.fb.group({
      date:              ['', Validators.required],
      brokerName:        [''],
      exporterName:      [''],
      customerName:      [''],
      certificationName: [''],
      exporter:          [null],
      broker:            [null],
      customer:          [''],
      contract:          [''],
      tbags: [''],
      tpb: [''],
      ttare: [''],
      tpn: [''],
      trend: [''],
      tdan: [''],
      thdad: [''],
      status:            ['Depósito'],
      observations:      [''],
      position:          [''],
      certifications:     [''],
      total_tare:        [0]
    });
  }

  saveData(): void {
    const form = this.form.getRawValue();
    this.referral = Object.assign(this.referral, form);
    if (!this.referral.exporter) {
      delete this.referral.exporter;
    }
    this.referral.total_net = this.getTotalNet(this.referral, this.referral.total);
    console.log('new referral: ', this.referral);
    localStorage.setItem('referral', JSON.stringify(this.referral));
  }

  getTotalNet(referral: any, total): number {
    let total_net = total;
    const numbers = _.pick(referral, ['r_qq', 'bags', 'wet_quintals', 'dry_qq',
      'inventory_qq', 'moisture_loss', 'dry_qq', 'total_qq_referral', 'total_sale']);
    Object.keys(numbers).forEach(key => {
      numbers[key] = isNaN(numbers[key]) ? 0 : numbers[key];
      total_net -= numbers[key];
    });
    return parseFloat(Number(total_net).toFixed(2));
  }

  submitForm(): void {
    const form = this.form.getRawValue();
    this.referral = Object.assign(this.referral, form);
    localStorage.setItem('referral', JSON.stringify(this.referral));
    const referral: Referral = this.referral;

    let numeros = [1, 2, 3, 4, 5];
    let partialSum = this.referral.rest_qq.reduce((a, b) => a + b, 0);
    if (partialSum > 0) {
      this.referral.status = 'Parcial';
    }

    
    const propertiesToDelete = ['customer', 'broker', 'exporter', 'certifications', 'cashCount'];

    propertiesToDelete.forEach(property => {
      referral[property] === "" ? delete referral[property] : null;
    });
    console.log('referral: ', referral);
    this._referralService.addReferral(referral).subscribe(res => {
      console.log('agregada: ', res);
      if (this.referral.inventories && this.referral.inventories.length > 0) {
        this.updateDrieds(res._id);
      }
      if (this.referral.sales && this.referral.sales.length > 0) {
        this.updateSales(res._id);
      }
      this.save = false;
      setTimeout(() => {
        localStorage.removeItem('referral');
        localStorage.removeItem('indexSelectedSales');
        localStorage.removeItem('indexSelectedDrieds');
        this.router.navigateByUrl('/administracion/remisiones');
      }, 500);
    });
  }

  updateDrieds(settlementId: string): void {
    const referral: Referral = this.referral;
    let referralsId = [];
    let remittedOn = false;
    referralsId.push(settlementId);
    this.referral.inventories.forEach((id, index) => {
          this._driedService.getDried(id).subscribe((dried: Dried) => {
            const sale: any = dried.inventoryStatus.sale;
            if (sale.ref.length === 0) {
              sale.total = dried.dry_qq;
              sale.finished = false;
              sale.partial = false;
            }
            sale.total = Number(referral.rest_qq[index]);
            sale.saled = Number(referral.taked_qq[index]);
            sale.remain = Number(referral.rest_qq[index]);
            sale.ref = referralsId;
            saleConstraint(sale, dried.dry_qq);
            console.log('actualizando remision', sale);
            if (sale.partial === true) {
              remittedOn = false;
            } else {
              remittedOn = true;
            }
            this._driedService.editDried(dried._id, {
              remitted: remittedOn,
              inventoryStatus: Object.assign(dried.inventoryStatus, { referral: sale })
            }).subscribe(response => {
              console.log('Inventario seco actualizado: ', response);
            }, (err: any) => console.error('Error al actualizar inventario seco: ', err));
          });
    });
  }

  updateSales(idReferral): void {
    const referral: Referral = this.referral;
    let referralsId = [];
    referralsId.push(idReferral);
    this.referral.sales.forEach((id, index) => {
          this._saleService.getSale(id).subscribe((sale: Sale) => {
            if (sale.total_qq_sale > sale.total_qq_ps) {
              sale.total_qq_sale = sale.total_qq_ps;
              sale.total_qq_referral = sale.total_qq_ps;
            } else if (sale.total_qq_sale < sale.total_qq_ps) {
              sale.total_qq_sale = sale.total_qq_sale;
              sale.total_qq_referral = sale.total_qq_sale;
            } else {
              sale.total_qq_sale = sale.total_qq_ps;
              sale.total_qq_referral = sale.total_qq_ps;
            }
            sale.total_qq_sale = Number(referral.rest_sale[index]);
            sale.rest_sale = Number(referral.restMoney[index]);
            sale.total_qq_referral = Number(referral.rest_sale[index]);
            sale.referrals = referralsId;
/*             sale.total_qq_sale = sale.total_qq_sale < 0 ? 0 : sale.total_qq_sale;
            sale.total_qq_sale = sale.total_qq_sale > sale.total_qq_ps ? sale.total_qq_ps : sale.total_qq_sale; */
            if (sale.total_qq_sale === 0) {
              sale.status = 'Propio';
            } else if (sale.total_qq_sale === sale.total_qq_ps ) {
              sale.status = 'Depósito';
            } else if (sale.total_qq_sale < sale.total_qq_ps && sale.total_qq_sale > 0 ){
              sale.status = 'Parcial';
            }

            console.log('actualizando venta', sale);
            this._saleService.editSale(sale._id, sale).subscribe(response => {
              console.log('Venta actualizado: ', response);
            }, (err: any) => console.error('Error al actualizar: ', err));
          });
    });
  }

  getDataFromServices() {

    this._certificationService.getCertifications().subscribe(resp => {
      this.certificationsData = resp;
      this.filteredCertifications = resp;
    });

    this._customerService.getCustomers().subscribe(resp => {
        this.customersData = resp;
        this.filteredCurtomers = resp;
     });

    this._brokerService.getBrokers().subscribe( resp => {
       this.brokersData = resp;
       this.filteredBrokers = resp;
     });

    this._exporterService.getExporters().subscribe(resp => {
      this.exportersData = resp;
      this.filteredExporters = resp;
    });

   }

  ngOnDestroy(): void {
    if (this.save) {
      this.saveData();
    }
  }

  existString(text1: string, text2: string): boolean {
    const status = text1
    .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .indexOf(text2
      .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1;
    return status;
  }

  changeFormValue(name: string, value: any): void {
    this.form.get(name).setValue(value);
  }

  getNum(num1: any, num2: any, op = '+'): number {
    console.log(`${num1} ${op} ${num2}`);
    return parseFloat(Number(op === '+' ? num1 + num2 : num1 - num2).toFixed(6));
  }

}
