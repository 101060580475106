import { Component, OnInit,  OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SeatService } from '../../../services/seat.service';
import { Seat } from '../../../models/seat';
import { Company } from '../../../models/company';
import { CategoryService } from '../../../services/category.service';
import { Category } from '../../../models/category';
import { CenterCostService } from '../../../services/center-cost.service';
import { CenterCost } from '../../../models/center-cost';
import { FormBuilder, Validators, FormControl, FormGroup, FormArray } from '@angular/forms';

import { ReplaySubject } from 'rxjs/ReplaySubject';
import * as _ from 'lodash';
import { UserService } from '../../../services/user.service';
import { Subject } from 'rxjs/Subject';
import { MatSelect } from '@angular/material';
import { Report } from '../../../models/report';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-closing',
  templateUrl: './closing.component.html',
  styleUrls: ['./closing.component.css'],
  providers: [SeatService, CategoryService, CenterCostService, UserService]
})
export class ClosingComponent implements OnInit {

  seatForm = this.fb.group({
    date_voucher: null,
    description: null
  });

  public titulo: string;
  public seat: any = {};
  public category: any = {};
  public categories;
  public cat = [];
  public reports: any = {};
  public company: Company;

  /**-------------select------------------*/
  public bankCtrl: FormControl = new FormControl();

  public bankFilterCtrl: FormControl = new FormControl();

  public data: any[];
  public dataP: any[];

  public filteredBanks: ReplaySubject<Category[]> = new ReplaySubject<Category[]>(1);

  @ViewChild('singleSelect') singleSelect: MatSelect;

  private _onDestroy = new Subject<void>();
/*---------------------------------------------*/
  // seatForm: FormGroup;
  totalDebit = 0;
  totalCredit = 0;
  myFormValueChanges$;
  currentPeriod;
  config: Company;
  utility;
  uPeriod;
  closing;
  ingresos = 0;
  gastos = 0;
  costos = 0;
  utilidad_bool = false;
  utilidad;

  ingresosP = 0;
  gastosP = 0;
  costosP = 0;
  utilidad_boolP = false;
  utilidadP;
  newPeriod;
  constructor(
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private _router: Router,
    private _categoryService: CategoryService,
    private _seatService: SeatService,
    private _userService: UserService,
    public dialogRef: MatDialogRef<ClosingComponent>
  ) {
    this.titulo = 'Cierre de Periodo';
    this.seat = {} as Seat;
    this.category = {} as Category;
    this.bankCtrl.setValue('');
    this.reports = {} as Report;
    this.config = this._userService.getCompany();
    // this.company = this._userService.getCompany();
  }

  ngOnInit() {
    this.currentPeriod = this.config.settings.period;
    this.newPeriod = this.currentPeriod + 1;
    this.uPeriod = this.config.settings.config.uPeriod;
    this.utility = this.config.settings.config.uLost;
    console.log(this.newPeriod);
    this.getAllCapital();
    this.getPeriodCapital();
    const numberPatern = '^[0-9.,]+$';
    this.seatForm = this.formBuilder.group({
      date_voucher: [Date(), Validators.required],
      description: ['']
    });

  }

  getAllCapital() {
    this._seatService.getAllCapital().subscribe(
      (res) => {
        this.dataP = res;
        console.log(this.dataP);
        let costosGastosUnion = false;
        let costosGastosValue = 0;
        this.dataP.forEach(element => {
          if (element.name === 'Ingresos') {
            this.ingresos = element.total;
          }
          if (element.name === 'Gastos') {
            this.gastos = element.total;
          }
          if (element.name === 'Costos') {
            this.costos = element.total;
          }
          if (element.name === 'Costos y Gastos') {
            costosGastosValue = element.total;
            costosGastosUnion = true;
          }
          this.utilidad_bool = true;
        });
        this.utilidad = !costosGastosUnion ?
          (this.ingresos - (this.gastos + this.costos)).toFixed(2) :
          (this.ingresos - costosGastosValue).toFixed(2);
        console.log(this.utilidad);
      },
      error => {
        const errorMessage = <any>error;
        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  getPeriodCapital() {
    this._seatService.getPeriodCapital().subscribe(
      (res) => {
        this.data = res;

        console.log(res);
        let costosGastosUnion = false;
        let costosGastosUnionValue = 0;
        this.data.forEach(element => {
          if (element.name === 'Ingresos') {
            this.ingresosP = element.total;
            console.log(this.ingresosP);
          }
          if (element.name === 'Gastos') {
            this.gastosP = element.total;
            console.log(this.gastosP);
          }
          if (element.name === 'Costos') {
            this.costosP = element.total;
            console.log(this.costosP);
          }

          if (element.name === 'Costos y Gastos') {
            costosGastosUnionValue = element.total;
            costosGastosUnion = true;
          }
          this.utilidad_boolP = true;
        });
        this.utilidadP = !costosGastosUnion ?
          (this.ingresosP - (this.gastosP + this.costosP)).toFixed(2) :
          (this.ingresosP - costosGastosUnionValue).toFixed(2);
        console.log(this.utilidadP);
      },
      error => {
          console.log(error);
      }
    );
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  uPeriodUpdate() {
    this.category.balance_area = this.utilidadP;

    this._categoryService.editCategory(this.uPeriod, this.category).subscribe(
      response => {
        console.log(response);
      }
    );
  }
  utilityUpdate() {
    this.category.balance_area = this.utilidad;

    this._categoryService.editCategory(this.utility, this.category).subscribe(
      response => {
        console.log(response);
      }
    );
  }
  clossing(seat) {
    this.category.balance_area = 0;
    this.seat.closed = true;
    this._categoryService.closing(this.category).subscribe(
      response => {
        console.log(response);
      }
    );
    this._seatService.closing(this.seat).subscribe(
      res => {
        console.log(res);
      }
    );
    this._seatService.addSeat(seat).subscribe(
      re => {
        this._router.navigate(['/contabilidad/asientos']);
        this.onCancelClick();
      },
      error => {
          console.log(error);
      }
    );
  }

  updatePeriod() {
    const config = this.config.settings;

    console.log(this.config.settings);

    this.config.settings = Object.assign(this.config.settings, {
      period: this.newPeriod
    });

    console.log(config);
    this.config.settings = config;

    this._userService.updateUser(this.config).subscribe(
      response => {
          localStorage.setItem('company', JSON.stringify(this.config));
      }
    );
  }

  save() {
    this.seat = this.seatForm.value;
    console.log(this.seat);
    this.seat.period = this.currentPeriod;
    this.seat.abbreviation = 'CC';
/*     this.uPeriodUpdate();
    this.utilityUpdate();
    this.updatePeriod();
    this.clossing(this.seat); */
  }

}
