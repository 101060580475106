import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import * as jwt_decode from 'jwt-decode';

// import decode from 'jwt-decode';


@Injectable()
export class RoleGuardService implements CanActivate {

  public roleTrue = false;

  constructor(public auth: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    const expectedRole = route.data.expectedRole;
    const token = localStorage.getItem('token');

    const tokenPayload = jwt_decode.decode(token);
    expectedRole.forEach(role => {
      if (tokenPayload.user.userType.description === role) {
        this.roleTrue = true;
      }
    });

    if (!this.auth.isAuthenticated() || this.roleTrue === true) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
   return true;
  }
}
