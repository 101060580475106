import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ChartComponent } from 'ng-apexcharts';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';

import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { ProductionService } from '../../../../services/production.service';
import { ContractService } from '../../../../services/contract.service';
import { DriedService } from './../../../../services/dried.service';
import { PrintService } from '../../../../services/print.service';
import { LotService } from '../../../../services/lot.service';
import { InventoryService } from '../../../../services/inventory.service';

import { ProductionEditComponent } from '../production-edit/production-edit.component';
import { ProductionAddComponent } from '../production-add/production-add.component';
import { DeleteModalComponent } from '../../../delete-modal/delete-modal.component';
import { ShippingAddComponent } from '../../shipping-add/shipping-add.component';

import { Production } from '../../../../models/production';
import { Contract } from '../../../../models/contract';
import { Dried, ProductionControl } from './../../../../models/dried';
import { Lot } from '../../../../models/lot';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexStroke,
  ApexFill
} from 'ng-apexcharts';
import { AppSidebar } from '../../../../models/sidebar';
import { prodConstraint } from '../../../../helpers/partialControl';
import { LeftoversService } from '../../../../services/leftovers.service';
import { UserService } from '../../../../services/user.service';
import { LotAddComponent } from '../../../exports/lots/lot-add/lot-add.component';
import { NewValueComponent } from '../new-value/new-value.component';
import { result } from 'lodash';
import { CdkVirtualForOf } from '@angular/cdk/scrolling';

// tslint:disable-next-line:interface-over-type-literal
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  stroke: ApexStroke;
  fill: ApexFill;
};
@Component({
  selector: 'app-production-list',
  templateUrl: './production-list.component.html',
  styleUrls: ['./production-list.component.css'],
  providers: []
})
export class ProductionListComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public form: FormGroup;
  public productions: Production[];
  public graph: any = [];
  public production: Production;
  public lot: any = {};
  public contract: any = {};
  public wns: any = [];
  public farmers: any = [];
  public lotFarms: any = [];
  public results: any = [];
  public class = false;
  public complete = false;
  public external = false;
  public hasContract = false;
  public companyId: string;
  public companyName;
  public sidebars: AppSidebar[] = [
    { name: 'leftover-list',        title: 'Lista de sobrantes', layout: {
      width: 30, height: 'full',
    }},
  ];
  labels = ['Exportable', 'Pergamino', 'Sobrante', 'Repasos', 'Stock', 'Resaca', 'Otro'];
  selectedProd: Production;
  selectedLot: Lot;
  rendimiento;
  alertMessage = '';

  constructor(
    public _sidebar: SidebarManagerService,
    private _productionService: ProductionService,
    private _driedService: DriedService,
    private _lotService: LotService,
    private _contractService: ContractService,
    private _printService: PrintService,
    private _userService: UserService,
    private _leftoverService: LeftoversService,
    private _inventoryService: InventoryService,
    private fb: FormBuilder,
    public dialog: MatDialog,
  ) {
    this.production = {} as Production;
    this.lot = {} as Lot;
    this.contract = {} as Contract;
    this.productions = [];
    this._sidebar.setSidebarsList(this.sidebars);
    this.companyId = this._userService.getIdentity().company._id;
    this.companyName = this._userService.getIdentity().company.name;
   }

  ngOnInit() {
    this.createForm();
    this.getProductions();
    // this.getGlobalProductions();
  }

  changeValue(exportb: number, wn): void {
    console.log(exportb, wn);
    const dialogRef = this.dialog.open(NewValueComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {_id: wn, quintals: exportb}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.res === 'confirm') {
        this._inventoryService.editInventory(wn, {afterProd: true, total_net_green: exportb, total_net_qq: exportb, total_net_sett: exportb}).subscribe(res => {
          console.log('se modifico ', res);
          this.selectProd(this.production);
        });
      }
    });
  }

  selectProd(prod: Production, click = false): void {
    this.farmers = [];
    this.lot = {};``
    this.createForm();
    this.selectedProd = prod;

    this._productionService.getProduction(prod._id).subscribe((res) => {
      // this.selectedProd = res;
      this.production = res;
      console.log('\n\nProduction selected: ', this.production);
      if (this.production.lots.length > 0 && this.production.lots[0].values.length > 0) {
        this.production.lots[0].values.forEach(result => {
          if (result.wn) {
            this._inventoryService.getInventory(result.wn).subscribe(res => {
              result.total = res.afterProd ? res.total_net_green : res.total_net_qq;
              this.addUnitLot(result);
            });
          } else {
            this.InitResults();
          }
        });
      } else {
        this.InitResults();
      }
    }, (error: HttpErrorResponse) => console.error('Error al obtener producciones', error));


    /* prod.dry_inventory.forEach((dry: Dried, i: number) => {
      const production: ProductionControl = dry.inventoryStatus.production;
      production.total = dry.dry_qq;
      production.produced = prod.dry_qq[i];
      production.remaining = dry.dry_qq - prod.dry_qq[i];
      if (production.ref.length === 0) {
        console.error('No tiene prod', dry, i);
      }
      if (production.ref.length >= 2) {
        console.warn('Tiene mas de 1');
      }
      if (production.remaining !== production.total && production.remaining !== 0) {
        production.partial = true;
        production.finished = false;
      } else if (production.remaining <= 0) {
        production.remaining = 0;
        production.produced = production.total;
        production.finished = true;
        production.partial = false;
      } else {
        production.finished = false;
        production.partial = false;
      }
      dry.inventoryStatus.production = production;
      console.log('\n\nprod control: ', i, dry.dry_qq, prod.dry_qq[i], prod.rest_qq[i]);
      console.table(production);
      this._driedService.editDried(dry._id, {
        inventoryStatus: dry.inventoryStatus
      }).subscribe((res) => console.log('dry actualizado'));
    }); */
    
  }

  InitResults() {
      if (this.production.microlot === true || this.production.blend) {
        for (let index = 0; index < this.production.dry_inventory.length; index++) {
          console.log('\n\nProduction selected dry: ', this.production.dry_inventory[index]);
          const dried: any = this.production.dry_inventory[index];
          dried.weight_notes.forEach(id => {
            let farmer = {};
            this.wns.push(id);
            this._inventoryService.getInventory(id).subscribe(res => {
              console.log('el res ', res);
              farmer = {
                id: res.coffeefarmer._id,
                name: res.coffeefarmer.name,
                variety: res.varieties && res.varieties.length > 0 ? res.varieties : res.variety,
                total: res.total_net_qq,
                wn: id
              };
              this.farmers.push(farmer);
              this.addUnit(farmer);
            });
          });
        }
      } else {
        this.addUnit('');
      }
  }
  // todo No dejar editar si sus sobrantes se han usado en otras producciones,
  // todo Agregar estado completo cuando los valores (5) sumen 0,

  saveInitResults() {
    this.results = [];
    const results = this.form.getRawValue();
    this.complete = false;

    console.log('si llega ', results.results);
    
    let count = 0;
    results.results.forEach(result => {
      count++;
      delete result.adjustmentId;
      delete result.leftoverId;
      delete result.lot;
      this.results.push(result);
      if (count === results.results.length) {
        const data = {
          dry_inventory: this.production.dry_inventory,
          values: this.results,
          complete: this.complete,
          microlot: this.production.microlot,
        }
        console.log(data);
        this._lotService.editLot(this.lot._id, data).subscribe(res => {
          console.log('ha llegado', res);
        });
      }
    });
  }

  saveResults() {
    this.results = [];
    const results = this.form.getRawValue();
    console.log('results', results.results);
    const exportb = _.sumBy(results.results, 'exportb');
    const leftover = _.sumBy(results.results, 'exportb');
    const res = (exportb + leftover) - this.lot.total_qq;
    if (res >= this.lot.total_qq) {
      this.complete = true;
    } else {
      this.complete = false;
    }
    if (this.production.lots.length > 0 && this.lot) {
      if (this.lot.values.length > 0 && results.results[0].leftoverId !== '') {
        let count = 0;
        results.results.forEach(result => {
          if (result.adjustmentId === '') {
            delete result.adjustmentId;
          } 
          if (result.varieties.length > 0) {
            delete result.variety;
          } else {
            delete result.varieties;
          }
          result.ready = this.complete;
          this._leftoverService.updateLeftover(result.leftoverId, result).subscribe((res) => {
            console.log('sobrantes actualizados', res);
            count++;
            if (count === results.results.length) {
              
              const data = {
                dry_inventory: this.production.dry_inventory,
                values: results.results,
                complete: this.complete,
                microlot: this.production.microlot,
              }
              console.log('ha llegado', data);
              this._lotService.editLot(this.lot._id, data).subscribe(res => {
                console.log(res);
                if (this.production.microlot = true) {
                  this.updateWn(results);
                }
                this.getProductions();
              });
            }
          }, (err: HttpErrorResponse) => console.error('Error al actualizar sobrantes de resultados', err));
        });
      } else {
        let count = 0;
        results.results.forEach(leftover => {
          if (leftover.adjustmentId === '') {
            delete leftover.adjustmentId;
          }
          if (leftover.varieties.length > 0) {
            delete leftover.variety;
          } else {
            delete leftover.varieties;
          }
          leftover.prod = this.production._id;
          leftover.microlot = this.production.microlot;
          leftover.lot = this.lot._id;
          leftover.prods = [];
          leftover.ready = this.complete;
          leftover.quality = this.lot.quality;
          leftover.analysis = this.production.analysis;
          this._leftoverService.addLeftover(leftover).subscribe((res) => {
            console.log('Sobrante de resultados creados', res);
            count++;
            leftover.leftoverId = res._id,
            this.results.push(leftover);
            console.log(leftover);
            if (count === results.results.length) {
              const data = {
                dry_inventory: this.production.dry_inventory,
                values: this.results,
                complete: this.complete,
                microlot: this.production.microlot,
              }
              console.log('ha llegado', data);
              this._lotService.editLot(this.lot._id, data).subscribe(res => {
                console.log(res);
                if (this.production.microlot = true) {
                  this.updateWn(results);
                }
                this.getProductions();
              });
            }
          }, (err: HttpErrorResponse) => console.error('Error al crear sobrante para prod', err));
        });
      }
    } else {
      this.production.results = results.results;
      this.updateProd(this.production);
    }
  }

  updateWn(results) {
    this.wns.forEach(wn => {
      this._inventoryService.getInventory(wn).subscribe(res => {
        results.results.forEach(item => {
          if (item.wn === res._id) {
            this._inventoryService.editInventory(wn, {afterProd: true, total_net_green: item.exportb, total_net_qq: item.exportb, total_net_sett: item.exportb}).subscribe(res => {
              console.log('se modifico el micro ', res);
            });
          }
        });
      });
    });
  };

  updateProd = (data: Production): void => {
    console.log(data);
    delete data.results[0].adjustmentId;
    delete data.results[0].leftoverId;
    delete data.results[0].lot;
    this._productionService.editProduction(this.production._id, data).subscribe(response => {
      console.log('produccion actualizada', response);
      this.getProductions();
    }, (err: HttpErrorResponse) => console.error('Error al editar produccion', err));
  }

  completeProd = (): void => {
    let status = 0;
    this.production.lots.forEach((lot, index) => {
      this._lotService.getLot(lot._id).subscribe(res => {
        console.log(res.lot);
        if(res.lot.complete === true) {
          status++;
        }
        if ((index + 1) === this.production.lots.length) {
          if (status === this.production.lots.length) {
            console.log(status,'si pasa');
            this._productionService.editProduction(this.production._id, {complete: true}).subscribe(response => {
              console.log('produccion actualizada', response);
              this.getProductions();
            }, (err: HttpErrorResponse) => console.error('Error al editar produccion', err));
          }
        }
      });
    });
  }

  remove() {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '600px',
      data: {
        header: 'Eliminar producción',
        title: '',
        text: `¿Seguro que quieres eliminar <strong>#${this.production.cod}</strong>?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.delete(this.production);
      }
    });
  }

  delete(production: Production) {
    production.lots.forEach(lot => {
      this.lot.prod = null;
      this.lot.complete = false;
      this.lot.values = [];
      this.lot.dry_inventory = [];
      this._lotService.editLot(lot._id, this.lot).subscribe();
    });
    production.dry_inventory.forEach((dry: Dried, i: number) => {
      const current: ProductionControl = dry.inventoryStatus.production;
      current.remaining += production.dry_qq[i];
      current.produced -= production.dry_qq[i];
      current.ref = current.ref.filter(idP => idP !== production._id);
      prodConstraint(current);
      this._driedService.editDried(dry._id, {
        inventoryStatus: Object.assign(dry.inventoryStatus, { production: current })
      }).subscribe(response => {
        console.log('Inventario seco actualizado: ', response);
      }, (err: HttpErrorResponse) => console.error('Error al actualizar inventario seco: ', err));
    });

    this._productionService.updateProduction(production._id, { enabled: false }).subscribe(response => {
      this.alertMessage = 'Registo eliminado';
      this.getProductions();
    }, error => console.error('Error al anular prod', error));
  }

  openDialog() {
    const dialogRef = this.dialog.open(ProductionAddComponent, {
      width: '25rem'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getProductions();
    });
  }

  addLot() {
    const dialogRef = this.dialog.open(LotAddComponent, {
      width: '25rem'
    });
    dialogRef.afterClosed().subscribe(result => {
    let lots = [];
    lots.push(result._id);
    this._productionService.updateProduction(this.production._id, { lots: lots }).subscribe(response => {
      console.log('lote agregado', result, response);
      this.getProductions();
    }, error => console.error('Error al anular prod', error));
      this.getProductions();
    });
  }

  opendDialogEdit() {
    const dialogRef = this.dialog.open(ProductionEditComponent,
      {
        width: '25rem',
        data: this.production
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.getProductions();
      }
    });
  }

  printProd = (): void => {
    this._printService.getProduction(this.production._id).then((res: any) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    })
    .catch(err => console.log('Error', err));
  }

  opendDialogAssign() {
    this._sidebar.open('leftover-list', { lot: this.lot, prod: this.selectedProd });
    this._sidebar.afterClosed('leftover-list').subscribe((response) => {
      if (response) {

      }
    });
  }

  openShippingAdd() {
    if (this.lot.hasOwnProperty('contract')) {
      const dialogRef = this.dialog.open(ShippingAddComponent,
        {
          width: '25rem',
          data: {
            lotId: this.lot._id,
          }
        });
        dialogRef.afterClosed().subscribe(() => {
          this.completeProd();
        });
    } else {
      this.alertMessage = 'Lote seleccionado no tiene un contrato.';
    }
  }

  onSelectLot(lot: Lot): void {
    if (!lot) return;
    console.log('selected lot', lot);
    this.contract = {};
    this.hasContract = false;
    this.lot = lot;
    this.selectedLot = lot;
    this.class = true;
    if (lot.complete === true) {
      this.alertMessage = 'Lote Completado';
    } else {
      this.alertMessage = '';
    }
    this.form.patchValue({results: lot.values ? lot.values : {}});
    this._lotService.getLot(this.lot._id).subscribe(response => {
      this.lot = response.lot;
      console.log('quien soy values num ', this.lot, this.lot.values.length);
      if (this.lot.values.length === 0) {
        this.saveInitResults();
      }
      if (lot.shipping) { this.alertMessage = 'Lote seleccionado ya ha sido enviado.'; }
      if (this.lot.hasOwnProperty('contract')) {
        this.hasContract = true;
        this._contractService.getContract(this.lot.contract).subscribe(res => {
          this.contract = res;
        }, err => console.error('Error al obtener contrato: ', err));
      } else {
        this.hasContract = false;
        this.alertMessage = 'Lote seleccionado no tiene un contrato.';
      }
    });
  }

  onSelectLotR(lot: Lot): void {
    if (!lot) return;
    this.contract = {};
    this.hasContract = false;
    this.lot = lot;
    this.selectedLot = lot;
    this.class = true;
    if (lot.complete === true) {
      this.alertMessage = 'Lote Completado';
    } else {
      this.alertMessage = '';
    }
    if (lot.values.length > 0) {
      console.log('selected lot > 0', lot.values);
      this.form.patchValue({results: lot.values ? lot.values : {}});
      this._lotService.getLot(this.lot._id).subscribe(response => {
        this.lot = response.lot;
        if (lot.shipping) { this.alertMessage = 'Lote seleccionado ya ha sido enviado.'; }
        if (this.lot.hasOwnProperty('contract')) {
          this.hasContract = true;
          this._contractService.getContract(this.lot.contract).subscribe(res => {
            this.contract = res;
          }, err => console.error('Error al obtener contrato: ', err));
        } else {
          this.hasContract = false;
          this.alertMessage = 'Lote seleccionado no tiene un contrato.';
        }
      });
    } else {
      this._lotService.getLot(this.lot._id).subscribe(response => {
        this.lot = response.lot;
        if (this.lot.values.length > 0) {
          if (lot.shipping) { this.alertMessage = 'Lote seleccionado ya ha sido enviado.'; }
          if (this.lot.hasOwnProperty('contract')) {
            this.hasContract = true;
            this._contractService.getContract(this.lot.contract).subscribe(res => {
              this.contract = res;
            }, err => console.error('Error al obtener contrato: ', err));
          } else {
            this.hasContract = false;
            this.alertMessage = 'Lote seleccionado no tiene un contrato.';
          }
        } else {
          this.createForm();
          this.form.reset();
          console.log(this.farmers);
          let count = 0;
          this.farmers.forEach((farmer) => {
              count++;
              this.addUnitLot(farmer);
/*               if (count === this.farmers.length) {
                console.log('selecionar conR');
                  this.saveInitResults();
              } */
          });
        }
      });
    }
  }

  getProductions() {
    setTimeout(() => {
      this._productionService.getProductions().subscribe((res) => {
        this.productions = res;
        this.selectProd(this.productions[0]);
      }, (error: HttpErrorResponse) => console.error('Error al obtener producciones', error));
    })
  }

  getGlobalProductions() {
    this._productionService.getGlobalProductions().subscribe(
      (res) => {
        this.graph = res[0];
        this.rendimiento = this.graph.parchment / this.graph.exportb;
        this.chartOptions = {
          // tslint:disable-next-line:max-line-length
          series: [this.graph.parchment, this.graph.exportb, this.graph.surplus, this.graph.revision, this.graph.stock, this.graph.undertow, this.graph.other],
          chart: {
            type: 'polarArea'
          },
          stroke: {
            colors: ['#fff']
          },
          fill: {
            opacity: 0.8
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }
          ]
        };
      },
      error => {
          console.log(error);
      }
    );
  }

  createForm = (): void => {
    this.form = this.fb.group({
      results: this.fb.array([])
    });
  }

  getUnit(farmer) {
    return this.fb.group({
      farmer:       [farmer.name],
      variety:      [farmer.variety],
      varieties:    [farmer.variety],
      total:        [farmer.total],
      wn:           [farmer.wn],
      lot:          [''],
      exportb:      [''],
      surplus:      [''],
      revision:     [''],
      stock:        [''],
      undertow:     [''],
      other:        [''],
      leftoverExport: [''],
      leftoverId:   [''],
      adjustmentId: [''],
    });
  }

  addUnit(farmer) {
    console.log(farmer)
    const control = <FormArray>this.form.controls['results'];
    control.push(this.getUnit(farmer));
    if (this.production.lots.length > 0) {
      this.onSelectLot(this.production.lots[0]);
    } else if(this.production.results.length > 0) {
      this.form.patchValue({results: this.production.results ? this.production.results : {}});
    }
  }

  addUnitLot(result) {
    console.log('agregar lote sion resultados', result)
    const control = <FormArray>this.form.controls['results'];
    control.push(this.getUnit(result));
    this.onSelectLot(this.production.lots[0]);
  }

  get totalqq () {
    if (this.lot.total_qq > 0 && this.lot) {
      return this.lot.total_qq;
    } else if (this.production.quintals > 0 && this.production) {
      return this.production.quintals;
    } else {
      return 0;
    }
  }

  get goal () {
    if (!this.lot) return 0;
    if (!this.lot.values) return 0;
    const leftover = _.sumBy(this.lot.leftoverValues, 'surplus');
    const exportb = _.sumBy(this.lot.values, 'exportb');
    const res = (exportb + leftover) - this.lot.total_qq;
    return res;
  }

  get surplusValue () {
    if (!this.lot) return 0;
    if (!this.lot.leftoverValues) return 0;
    return _.sumBy(this.lot.leftoverValues, 'surplus');
  }
 
  get isComplete () {
    if (!this.lot) return false;
    return this.goal === 0 ? true : false;
  }

  get unready () {
    if (!this.production) return true;
    // if (this.form.invalid) return true;
    if (this.production.leftover) {
      if (!this.production.leftover.prods) return true;
      if (this.production.leftover.prods.length === 0) return false;
      else return true;
    }
  }

}
