import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputNumberFormat'
})
export class InputNumberFormatPipe implements PipeTransform {

  transform(value: number, decimals?: number): any {
    const addStr = (str, index, stringToAdd) => str.substring(0, index) + stringToAdd + str.substring(index, str.length);
    const splippedNum = String(value).split('.');
    const strNum = splippedNum[0].split(',').join('');
    let formatedNum = strNum;
    let c = 0;
    for (let i = strNum.length; i > 0; i-- ) {
      if (c === 3 && i !== strNum.length) {
        formatedNum = addStr(formatedNum, i, ',');
        c = 0;
      }
      c += 1;
    }
    // console.log('newValue: ', formatedNum);
    return formatedNum + (splippedNum.length > 1 ? '.' + splippedNum[1] : '');
  }
}
