import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/finally';
import { LoadingService } from '../services/loading.service';
import 'rxjs/add/operator/do';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
/* 
  public token;

    constructor(
        private router: Router,
        private loaderService: LoadingService
        ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   // debugger;

      if (req.headers.get('No-Auth') === 'True') {
            return next.handle(req.clone());
        }

        if (localStorage.getItem('token') != null) {
            // this.showLoader();
            const clonedreq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            });
            return next.handle(clonedreq);
            // .finally(() => this.loaderService.hide());
        } else {
          this.router.navigateByUrl('/');
          this.token = localStorage.getItem('token');
            this.token = null;
            localStorage.removeItem('identity');
            localStorage.removeItem('token');
            localStorage.removeItem('company');
            localStorage.clear();

            this.router.navigate(['/']);
        }
    }

    private onEnd(): void {
        this.hideLoader();
    }

    private showLoader(): void {
        this.loaderService.show();
    }

    private hideLoader(): void {
        this.loaderService.hide();
    } */

    
    constructor(
        private router: Router,
        private loaderService: LoadingService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.get('No-Auth') === 'True') {
            return next.handle(req.clone());
        }

        const token = localStorage.getItem('token');
        if (token) {
            const tokenExpiration = this.getTokenExpiration(token);
            if (tokenExpiration && new Date(tokenExpiration) < new Date()) {
                // Token expirado, realizar logout
                this.logout();
            } else {
                const clonedReq = req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + token)
                });
                return next.handle(clonedReq);
            }
        } else {
            // No hay token, realizar logout
            this.logout();
        }
    }

    private getTokenExpiration(token: string): Date {
        try {
            const tokenPayload = JSON.parse(atob(token.split('.')[1]));
            return new Date(tokenPayload.exp * 1000);
        } catch (error) {
            return null;
        }
    }

    private logout(): void {
        localStorage.removeItem('identity');
        localStorage.removeItem('token');
        localStorage.removeItem('company');
        localStorage.clear();
        this.router.navigateByUrl('/');
        location.reload();
    }
}
