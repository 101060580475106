import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

import { DriedService } from '../../../services/dried.service';
import { SaleService } from '../../../services/sale.service';
import { WarehouseService } from '../../../services/warehouse.service';

import { Sale } from '../../../models/sale';
import * as _ from 'lodash';
import { Inventory } from '../../../models/inventory';
import { PrintService } from '../../../services/print.service';
import { UserService } from '../../../services/user.service_old';

@Component({
  selector: 'app-inventory-qq',
  templateUrl: './inventory-qq.component.html',
  styleUrls: ['./inventory-qq.component.css'],
  providers: [DriedService, SaleService, WarehouseService, PrintService, UserService]
})
export class InventoryQqComponent implements OnInit {

  // tslint:disable-next-line: max-line-length
  public displayedColumns: string[] = ['cod', 'date', 'date_end', 'days', 'hours', 'warehouse', 'area', 'machine', 'wet_quintals', 'dry_qq', 'moisture_loss', 'r_qq', 'action'];
  public token;
  public highlightedRows = [];
  public total_qq_sold = 0;
  public total_qq_pending = 0;
  public title = 'Inventario';
  public dataSource = new TableVirtualScrollDataSource<Sale>();
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }
  constructor(
    private _warehouseService: WarehouseService,
    private _saleService: SaleService,
    private _printService: PrintService,
    private _userService: UserService,
    ) {
    this.token = this._userService.getToken();
    }

  ngOnInit() {
    this.getInventories();
  }

  getInventories(): void {
    this._saleService.getSales().subscribe( res => {
        const sales = res as Sale[];
        console.log('Ventas obtenidas: ', res);
        let inventories = [];
        this.total_qq_pending = 0;
        this.total_qq_sold = 0;
        sales.forEach(sale_ => {
          console.log('currentSale: ', sale_);
          this.total_qq_sold += sale_.taked_qq.reduce((acc, val) => acc + val, 0);
          inventories = inventories.concat(sale_.inventories);
        });
        console.log('Total sold despues de reduce:', this.total_qq_sold);
        const data = [];
        console.log('Inventories: ', inventories);
        const totalDry = inventories.reduce((acc, val) => acc + val.dry_qq, 0);
        console.log('total Dry: ', totalDry);
        const idsCheckeds = [];
        inventories.forEach(driedA => {
          if (!idsCheckeds.includes(driedA._id)) {
            // driedA.inventory_qq = inventories.filter(dried => dried._id === driedA._id).reduce((acc, val) => acc + val.inventory_qq, 0);
            this._warehouseService.getWarehouse(driedA.warehouse).subscribe(warehouse => driedA.warehouse = warehouse.warehouse);
            data.push(driedA);
            idsCheckeds.push(driedA._id);
          }
        });
        console.log('Valor data:', data);
        this.total_qq_pending = data.reduce((acc, val) => acc + val.dry_qq, 0) - this.total_qq_sold;
        this.dataSource.data = data.filter(dried => dried.inventory_qq !== 0);
      },
      error => this.error_(error)
      );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  error_(error) {
    const errorMessage = <any>error;
        if (errorMessage != null) {
          const body = error._body;
          console.error(error);
        }
  }

  toPrintInventory(inventory: Inventory): void {
    console.log('ToPrint: ', inventory);
    this._printService.printInventory(inventory._id, true).then(
      (res: any) => {
        console.log('Imprimiendo Inventario');

        const blob = new Blob([res], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      })
      .catch(err => console.log('Error', err));
  }
}
