import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { ReplaySubject, Subject, Observable } from 'rxjs';
import { MatSelect, MAT_CHECKBOX_CLICK_ACTION } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';

import { Contract } from '../../../../models/contract';
import { Customer } from '../../../../models/customer';
import { Lot } from '../../../../models/lot';
import { Broker } from '../../../../models/broker';

import { ContractService } from '../../../../services/contract.service';
import { CustomerService } from '../../../../services/customer.service';
import { BrokerService } from '../../../../services/broker.service';
import { LotService } from '../../../../services/lot.service';

import { LotAddComponent } from '../../lots/lot-add/lot-add.component';


interface Item {
  id: string;
  name: string;
}

@Component({
  selector: 'app-contract-add',
  templateUrl: './contract-add.component.html',
  styleUrls: ['./contract-add.component.css'],
  providers: [CustomerService, BrokerService, LotService, ContractService,
    {provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'check'}
  ]
})
export class ContractAddComponent implements OnInit, OnDestroy {

  public lot: any = {};
  public contract: any = {};
  certs: any;
  lots: any[] = [];
  type: any;
  lotes = [];
  holder = '';
  public varietyMultiCtrl: FormControl = new FormControl();
  public varietyMultiFilterCtrl: FormControl = new FormControl();

  private varieties: Item[] = [
    { name: 'Catuaí', id: 'Catuaí' },
    { name: 'Caturra', id: 'Caturra' },
    { name: 'Bourbón', id: 'Bourbón' },
    { name: 'ihcafe 90', id: 'ihcafe 90' },
    { name: 'Parainema', id: 'Parainema' },
    { name: 'Typica', id: 'Typica' },
    { name: 'Lempira', id: 'Lempira' },
    { name: 'Pacas', id: 'Pacas' },
    { name: 'Obata', id: 'Obata' },
    { name: 'Colombiano', id: 'Colombiano' },
    { name: 'Geisha', id: 'Geisha' }
  ];

  public filteredVarietyMulti: ReplaySubject<Item[]> = new ReplaySubject<Item[]>(1);
  private _onDestroy = new Subject<void>();

  variety: any;

  public customerCtrl: FormControl = new FormControl();
  public customerFilterCtrl: FormControl = new FormControl();
  public customer: any[];
  public filteredCustomers: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);

  public brokerCtrl: FormControl = new FormControl();
  public brokerFilterCtrl: FormControl = new FormControl();
  public broker: any[];
  public filteredBrokers: ReplaySubject<Broker[]> = new ReplaySubject<Broker[]>(1);
  // tslint:disable-next-line: max-line-length
  public differential = [];
  public stamps = [
    { value: 'coffee_bird', viewValue: 'Coffee Bird friendly' },
    { value: 'rain_forest', viewValue: 'Rain Forest' },
    { value: 'nespresso', viewValue: 'Nespresso AAA' },
    { value: 'fairtrade', viewValue: 'Fairtrade' },
    { value: 'organic', viewValue: 'USDA organic' },
    { value: 'diff', viewValue: 'Diferencial' },
    { value: 'utz', viewValue: 'UTZ' },
    { value: '_4c', viewValue: '4C' },
  ];
  closeResult: string;
  form: FormGroup;
  @ViewChild('singleSelect') singleSelect: MatSelect;
  enable = false;
  checked = [];
  next = false;
  /*------------------chips--------------------------*/
  public allLots: Lot[] = [
  ];
  public chipSelectedLots: any[] = [];
  public filteredLots: Observable<String[]>;

  private allowFreeTextAddLot = true;

  public lotControl = new FormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('lotInput') lotInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
/*--------------------------------------------*/
  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private _customerService: CustomerService,
    private _brokerService: BrokerService,
    private _lotService: LotService,
    private _contractService: ContractService,
    public dialog: MatDialog
  ) {
    this.lot = {} as Lot;
    this.contract = {} as Contract;
    this.createForm();
  }

  ngOnInit() {
    this.getCustomer();
    this.listenersFormValue();
    this.getBroker();
    this.getLots();
    this.type = 'externo';
    this.filteredVarietyMulti.next(this.varieties.slice());
     /*-------------------------------------*/
  }

  createForm(): void {
    const numberPatern = '^[0-9.,]+$';
    this.form = this.fb.group({
      contract: [''],
      flo_id:   [''],
      date: [''],
      customer: ['', Validators.required],
      broker:   [''],
      payment:  ['', Validators.required],
      price:    ['', [Validators.pattern(numberPatern)]],
      type:     [''],
      bags:     ['', [Validators.pattern(numberPatern)]],
      quintals: ['', [Validators.pattern(numberPatern)]],
      quality:  [''],
      harvest:  [''],
      embark:   [''],
      dif:      [''],
      FTdif:    [''],
      FTOdif:   [''],
      position: [''],
      terms:    [''],
      options:  [''],
      optionsCheck: this.fb.group({
        coffee_bird:  [false],
        rain_forest:  [false],
        nespresso:    [false],
        fairtrade:    [false],
        organic:      [false],
        diff:         [false],
        utz:          [false],
        _4c:          [false],
      }),
      differential: this.fb.group({
      })
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  listenersFormValue() {
    this.form.valueChanges.subscribe(val => {
      console.log(this.form.get('differential').valid);
      if (
        this.form.get('price').valid &&
        this.form.get('payment').valid &&
        this.form.get('customer').valid &&
        this.form.get('differential').valid
        ) {
          this.next = true;
      } else { this.next = false; }
    });
    this.form.get('optionsCheck').valueChanges.subscribe(json => {
      this.checked = [];
      Object.keys(json).forEach(key => {
        if (json[key]) {
          (this.form.get('differential') as FormGroup).addControl(key, this.fb.control(null, Validators.required));
          this.checked.unshift({'key': key, 'name': this.getOptionName(key)});

        } else {
          (this.form.get('differential') as FormGroup).removeControl(key);
        }
      });
    });

    this.varietyMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterVarietyMulti();
    });

    /*------------------chips--------------*/
    this.filteredLots = this.lotControl.valueChanges.pipe(
      // tslint:disable-next-line: deprecation
      startWith(null),
      map(lotName => this.filterOnValueChange(lotName))
    );

  }

  getOptionName(keyName): string {
    let view = '';
    this.stamps.forEach(json => {
      if (json.value === String(keyName)) {
        view = json.viewValue;
      }
    });
    return view;
  }

  getLot(lot) {
    console.log(lot);
    this.enable = true;
    this._lotService.getLot(lot._id).subscribe(
      response => {
        this.lot = response.lot;
      }
    );
  }
/*-----------------------------------chips--------------------*/
  public addLot(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddLot) {
      console.log('allowFreeTextAddLot is false');
      return;
    }
    console.log(event);

    if (this.matAutocomplete.isOpen) {
      return;
    }
    const value = event.value;
    if ((value || '').trim()) {
      this.selectLotByName(value.trim());
    }

    this.resetInputs();
  }

  public removeLot(lot: Lot): void {
    const index = this.chipSelectedLots.indexOf(lot);
    if (index >= 0) {
      this.chipSelectedLots.splice(index, 1);
      this.resetInputs();
    }
  }

  public lotSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectLotByName(event.option.value);
    this.resetInputs();
  }

  private resetInputs() {
    this.lotInput.nativeElement.value = '';
    this.lotControl.setValue(null);
  }

  private filterOnValueChange(lotName: string | null): String[] {
    let result: String[] = [];

    const allLotsLessSelected = this.allLots.filter(lot => this.chipSelectedLots.indexOf(lot) < 0);
    if (lotName) {
      result = this.filterLot(allLotsLessSelected, lotName);
    } else {
      result = allLotsLessSelected.map(lot => lot.lot);
    }
    return result;
  }

  private filterLot(lotList: Lot[], lotName: String): String[] {
    let filteredLotList: Lot[] = [];
    const filterValue = lotName.toLowerCase();
    const lotsMatchingLotName = lotList.filter(lot => lot.lot.toLowerCase().indexOf(filterValue) === 0);
    if (lotsMatchingLotName.length || this.allowFreeTextAddLot) {

      filteredLotList = lotsMatchingLotName;
    } else {

      filteredLotList = lotList;
    }

    return filteredLotList.map(lot => lot.lot);
  }

  private selectLotByName(lotName) {
    const foundLot = this.allLots.filter(lot => lot.lot === lotName);
    if (foundLot.length) {

      this.chipSelectedLots.push(foundLot[0]);
      this.getLot(foundLot[0]);
    } else {

      const highestEmployeeId = Math.max(...this.chipSelectedLots.map(lot => lot.seq), 0);
      this.chipSelectedLots.push({ lot: lotName, seq: highestEmployeeId + 1 });
    }
  }
  private selectLotById(res) {
      this.getLot(res);
      this.chipSelectedLots.push(res);
  }

  getLots() {
    this._lotService.getLotsOffContract().subscribe(
      (res) => {
        this.allLots = res as  Lot[];
      }
    );
  }
/*----------------------------------chips-----------------------*/
  openDialog() {
    const dialogRef = this.dialog.open(LotAddComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.selectLotById(result);
    });
  }

  private filterVarietyMulti() {
    if (!this.varieties) {
      return;
    }
    // get the search keyword
    let search = this.varietyMultiFilterCtrl.value;
    if (!search) {
      this.filteredVarietyMulti.next(this.varieties.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredVarietyMulti.next(
      this.varieties.filter((bank) => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  getCustomer() {
    this._customerService.getCustomers().subscribe(
      (res) => {

        this.customer = res as Customer[];
        this.customerCtrl.setValue('');
        this.filteredCustomers.next(this.customer.slice());

        this.customerFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterCustomer();
          });
      },
      error => {
        const errorMessage = <any>error;
        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  getBroker() {
    this._brokerService.getBrokers().subscribe(
      (res) => {

        this.broker = res as Customer[];
        this.brokerCtrl.setValue('');
        this.filteredBrokers.next(this.broker.slice());

        this.brokerFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterBroker();
          });
      }
    );
  }

  private filterCustomer() {
    if (!this.customer) {
      return;
    }
    let search = this.customerFilterCtrl.value;
    if (!search) {
      this.filteredCustomers.next(this.customer.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCustomers.next(
      this.customer.filter(centro => centro.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterBroker() {
    if (!this.broker) {
      return;
    }
    let search = this.brokerFilterCtrl.value;
    if (!search) {
      this.filteredBrokers.next(this.broker.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredBrokers.next(
      this.broker.filter(centro => centro.name.toLowerCase().indexOf(search) > -1)
    );
  }
  updateLots(lots, id) {
    this.lot = {} as Lot;
    this.lot.status = true;
    this.lot.contract = id;
    let count = 0;
    lots.forEach(_id => {
      count++;
      this._lotService.editLot(_id, this.lot).subscribe(
        response => {
          console.log(response);
        }
      );
    });
    if (count === lots.length) {
      this._router.navigate(['/contratos']);
    }
  }

  closeAddComponent() {

  }

  save() {
    console.log('\n\nForm:', this.form.value);
    const contract: Contract = this.form.getRawValue();
    const lotes = this.chipSelectedLots.map(lot => lot._id);
    delete contract.differential;
    this.differential = this.form.get('differential').value;
    contract.dif = this.differential;
    // contract.dif = this.differential;
    // contract.differential = temp;

    contract.lots = lotes;
    contract.varieties = this.variety;
    contract.type = this.type;
    console.log('Contract: ', contract);
    this._contractService.addContract(contract).subscribe(
      response => {
        console.log(response._id);
        this.updateLots(lotes, response._id);
      },
      error => {
          console.error(error);
      }
    );
  }
}
