import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material';

import { SettlementService } from '../../../services/settlement.service';
import { ExpenseService } from '../../../services/expense.service';
import { GLOBAL } from '../../../services/global';

import { Settlement } from '../../../models/settlement';
import { Expense } from '../../../models/expense';

@Component({
  selector: 'app-settlements-pay',
  templateUrl: './settlements-pay.component.html',
  styleUrls: ['./settlements-pay.component.css'],
})

export class SettlementsPayComponent implements OnInit, AfterViewInit {
  public form: FormGroup;
  public pendingValue = 0;
  public settlement: Settlement;
  public exceded = false;
   public currency;
  constructor(
    private fb: FormBuilder,
    private settlementService: SettlementService,
    private expenseService: ExpenseService,
    public dialogRef: MatDialogRef<SettlementsPayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.currency = GLOBAL.currency;
    this.createForm();
    this.listernsFormValue();
    console.log('data: ', this.data);
    if (!this.data.hasOwnProperty('pending')) {
      this.data['pending'] = 0;
    }
    this.pendingValue = this.data.pending;
    this.settlement = this.data;
  }

  ngAfterViewInit(): void {
    this.form.get('pending').setValue(this.pendingValue);
  }

  createForm(): void {
    this.form = this.fb.group({
      date:           [{value: '', disabled: true}, Validators.required],
      mount:          ['', Validators.required],
      pending:        [{value: 0, disabled: true}],
      observations:   [''],
      payFull:        [false]
    });
  }

  listernsFormValue(): void {
    this.form.get('mount').valueChanges.subscribe(value => {
      if (this.settlement.pending >= value) {
        this.exceded = false;
        const pending = this.settlement.pending - value;
        this.form.get('pending').setValue(pending);
      } else {
        this.exceded = true;
      }
    });

    this.form.get('payFull').valueChanges.subscribe(status => {
      if (status) {
        this.form.controls['mount'].disable();
        this.form.get('mount').setValue(this.settlement.pending);
      } else {
        this.form.get('mount').setValue(0);
        this.form.controls['mount'].enable();
      }
    });
  }

  addPay(): void {
    const expense: Expense = this.form.getRawValue();
    expense.pending = this.form.get('payFull').value ? 0 : expense.pending;
    expense.mount = this.form.get('payFull').value ? this.settlement.pending : this.form.get('mount').value;
    this.settlement.status = expense.pending === 0 ? 'Pagado' : this.settlement.status;
    expense.concept = `Abono a liquidacion #${this.settlement.cod_set}`;
    expense.concept_code = this.settlement.cod_set;
    expense.concept_id = this.settlement._id;
    expense.concept_model = 'Settlement';
    expense.abbreviation = 'ABLI';
    this.settlement.pending = expense.pending;
    this.expenseService.addExpense(expense).subscribe(response => {
      console.log('Abonado: ', response);
      this.settlementService.updateSettlementPending(this.settlement._id, this.settlement).subscribe(res => {
        console.log('Liquidacion editada: ', res);
        this.dialogRef.close();
      }, err => console.error('Error al edit liquidacion: ', err));
    }, err => console.error('Error al abonar a liquidacion: ', err));

  }

}
