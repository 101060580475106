import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { PhysicalAddComponent } from './physical-add/physical-add.component';
import { PhysicalEditComponent } from './physical-edit/physical-edit.component';
import { PhysicalListComponent } from './physical-list/physical-list.component';

@NgModule({
  declarations: [
    PhysicalListComponent,
    PhysicalAddComponent,
    PhysicalEditComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    PhysicalAddComponent,
    PhysicalEditComponent
  ]
})
export class PhysicalsModule { }
