import { setDecimals } from './../../../../helpers/number';
import { PaymentService } from './../../../../services/payment.service';
import { AdvancePayment } from './../../../../models/advancePayment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AdvancePaymentService } from './../../../../services/advancePayment.service';
import { Component, OnInit, Inject } from '@angular/core';
import * as _ from 'lodash';
import { PrintService } from '../../../../services/print.service';
import { GLOBAL } from '../../../../services/global';

@Component({
  selector: 'app-advance-payment-pay-balance',
  templateUrl: './advance-payment-pay-balance.component.html',
  styleUrls: ['./advance-payment-pay-balance.component.css']
})
export class AdvancePaymentPayBalanceComponent implements OnInit {
  public balanceStatus: any = {};
  public form: FormGroup;
   public currency;
  constructor(
    private _advancePaymentService: AdvancePaymentService,
    private _paymentService: PaymentService,
    private _printService: PrintService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AdvancePaymentPayBalanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.createForm();
    this.getCoffeefarmerBalance();
    this.currency = GLOBAL.currency;
  }

  getCoffeefarmerBalance(): void {
    this._advancePaymentService.getBalancePerCoffeefarmer(this.data.coffeefarmer._id).subscribe(res => {
      console.log('Saldo de productor: ', res);
      this.balanceStatus = res;
    }, err => console.error('Error al obtener saldo de productor: ', err));
  }

  setPayment(): void {
    this.form.get('payment').setValue(this.balanceStatus.totalBalance);
  }

  getPrintPayment(): void {
    // this._printService.get
  }

  pay(): void {
    const form = this.form.getRawValue();
    let totalBalance = form.payment > this.balanceStatus.totalBalance ? this.balanceStatus.totalBalance : form.payment;
    totalBalance = setDecimals(totalBalance);
    const totalBalanceValue = setDecimals(totalBalance);
    const fullbalanceId = (new Date()).getTime().toString() + Math.random().toString(8).slice(2);
    this._advancePaymentService.getAdvancePaymentPerCoffeeFarmer(this.data.coffeefarmer._id).subscribe(res => {
      let paymentObj = null;
      const advancePayments = _.orderBy(res, ['date'], ['asc']);
      for (let i = 0; i < advancePayments.length; i ++ ) {
        const currentAdvancePayment: AdvancePayment = advancePayments[i];
        const temp = setDecimals(totalBalance - currentAdvancePayment.total_balance);
        const paymentValue = temp >= 0 ? setDecimals(currentAdvancePayment.total_balance) : totalBalance;
        const payment: any = {};

        payment['mount'] = currentAdvancePayment.mount;
        payment['payment'] = paymentValue;
        payment['advancePayment'] = currentAdvancePayment._id;
        payment['coffeefarmer'] = this.data.coffeefarmer._id;
        payment['observations'] = form.observations;

        payment['fullbalance'] = true;
        payment['fullbalanceId'] = fullbalanceId;
        payment['fullbalanceTotal'] = totalBalanceValue;
        payment['outstanding_balance'] = parseFloat(Number(currentAdvancePayment.total_balance - paymentValue).toFixed(4));
        payment['date'] = form.date;
        this._paymentService.addPayment(payment).subscribe(async res_ => {
          if (i === 0) paymentObj = res_;
          console.log('Abono agregado: ', res_);
          await this._advancePaymentService.recalculateAdvancePayment(currentAdvancePayment._id, true, false);
          if (i === advancePayments.length - 1) this.dialogRef.close(paymentObj);
        });
        totalBalance -= paymentValue;
        totalBalance = setDecimals(totalBalance);
        if (totalBalance <= 0) {
          // this.dialogRef.close(true);
          break;
        }
      }
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      payment:      ['', Validators.required],
      date:         ['', Validators.required],
      observations: ['']
    });
  }

}
