import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort } from '@angular/material';
import * as _ from 'lodash';

import { PrintService } from '../../../../services/print.service';
import { DriedService } from '../../../../services/dried.service';
import { UserService } from '../../../../services/user.service';
import { SaleService } from '../../../../services/sale.service';
import { GLOBAL } from '../../../../services/global';

import { DeleteModalComponent } from '../../../delete-modal/delete-modal.component';
import { SaleEditComponent } from '../sale-edit/sale-edit.component';
import { SaleAddComponent } from '../sale-add/sale-add.component';
import { SimpleSaleAddComponent } from '../simple-sale-add/simple-sale-add.component';
import { SimpleSaleEditComponent } from '../simple-sale-edit/simple-sale-edit.component';

import { Dried } from '../../../../models/dried';
import { Sale } from '../../../../models/sale';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css'],
})
export class SalesComponent implements OnInit {
  public title = 'Ventas';
  public sales: Sale[];
  public sale = [];
  public identity;
  public identi;
  public closeResult: string;
  public currency;

  // tslint:disable-next-line:max-line-length
  public displayedColumns: string[] = ['seq', 'date', 'customer', 'contract', 'differential', 'total_qq_ps', 'price', 'sub_total', 'total', 'status', 'observations', 'action'];
  public highlightedRows = [];
  public dataSource = new TableVirtualScrollDataSource<Sale>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }

  constructor(
    private _saleService: SaleService,
    private _userService: UserService,
    private _driedService: DriedService,
    private _printService: PrintService,
    public dialog: MatDialog
  ) {
    this.identity = this._userService.getCompany();
    this.identi = this._userService.getIdentity();
  }

  ngOnInit() {
    this.getSales();
    this.currency = GLOBAL.currency;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialogAdd() {
    const dialogRef = this.dialog.open(SimpleSaleAddComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '550px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getSales();
    });
  }

  openDialogD(sale) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '410px',
      data: {
        header: 'Eliminar venta',
        title: '',
        text: `¿Seguro que quieres eliminar <strong>#${sale.seq}</strong> ?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteSale(sale);
      }

    });
  }

  getSales() {
    this._saleService.getSales().subscribe(
      (res) => {
        console.log('Ventas: ', res);
        this.dataSource.data = res as Sale[];
        this.dataSource.connect().next(res);
      }, error => console.error('Error al intentar obtener la ventas: ', error)
    );
  }

  deleteSale(sale: Sale) {
    console.log('\n\nVenta a eliminar: ', sale);
    const sale_ = _.cloneDeep(sale);
    const driedToUpdate = sale_.inventories;
    driedToUpdate.forEach((dried: Dried, index) => {
      this._driedService.getDried(dried._id).subscribe( originDried => {
        console.log('dried original: ', originDried);
        dried.inventory_qq = originDried.dried.inventory_qq + sale_.taked_qq[index];
        dried.sold = false;
        console.log('Nuevo valor del Dried: ', dried.inventory_qq);
        if (dried.inventory_qq > dried.dry_qq) { console.error('Valor devuelto excede el original'); }

        this._driedService.editDried(dried._id, dried).subscribe(response => {
          console.log('Inventario actualizado...');
        }, error => console.error('Error al editar secada: ', error));
      });

    });
    this._saleService.deleteSale(sale._id).subscribe(response => {
      console.log('Venta eliminada: ', response);
      this.getSales();
    }, error => console.error('Error al eliminar venta: ', error));

  }

    opendDialogEdit(sale: Sale) {
    const dialogRef = this.dialog.open(SaleEditComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '650px',
      height: '620px',
        data: sale._id
      });
    dialogRef.afterClosed().subscribe(result => {
      this.getSales();
    });
  }

  openEdit(sale: Sale): void {
        const dialogRef = this.dialog.open(SimpleSaleEditComponent, {
          width: '720px',
          panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
          data: sale
        });
        dialogRef.afterClosed().subscribe(callback => {
          if (callback !== 'cancel') {
            this.getSales();
          }
        });
  }
  printSale(sale: Sale): void {
    console.log('Sale to print: ', sale);
    this._printService.getSale(sale._id).then((res: any) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    })
    .catch(err => console.log('Error', err));
  }

}
