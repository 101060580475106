import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Category } from '../models/category';

@Injectable()
export class CategoryService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getCategories(): Observable<any> {
    return this.http.get(`${this.url}/categories/list`);
  }
  getDetailCategories(): Observable<any> {
    return this.http.get(`${this.url}/categories/list-detail`);
  }
  getLedgerCategories(): Observable<any> {
    return this.http.get(`${this.url}/categories/ledger-categories`);
  }
  getCategoriesTree(): Observable<any> { // ! Sin finalizar
    return this.http.get(`${this.url}/categories/list-tree`);
  }

  getCategoriesTreeData(): Observable<any> {
    return this.http.get(`${this.url}/categories/tree-data`);
  }

  getCategoriesFromCompany(id: string): Observable<any> {
    return this.http.get(`${this.url}/categories/list/${id}`);
  }
  updateAll(category): Observable<any> {
    return this.http.post(`${this.url}/categories/setAll`, category);
  }
  closing(category): Observable<any> {
    return this.http.post(`${this.url}/categories/closing`, category);
  }
  addCategory(category: Category): Observable<any> {
    return this.http.post(`${this.url}/categories/new`, category);
  }

  createArchitecture = (): Observable<any> => this.http.post(`${this.url}/categories/create-architecture`, {}); // * COSTOS Y GASTOS

  createArchitecture2 = (): Observable<any> => this.http.post(`${this.url}/categories/create-architecture2`, {}); // * COSTOS, GASTOS

  addCategoryToCompany(category: Category ): Observable<any> {
    return this.http.post(`${this.url}/categories/new`, category);
  }

  getCategory(id): Observable<any> {
    return this.http.get(`${this.url}/categories/${id}`);
  }
  getParent(parent): Observable<any> {
    return this.http.get(`${this.url}/categories/parent/${parent}`);
  }
  editCategory(id, category): Observable<any> {
    return this.http.put(`${this.url}/categories/${id}`, category);
  }

  deleteCategory(id): Observable<any> {
    return this.http.delete(`${this.url}/categories/${id}`);
  }

  constraintCategory(id): Observable<any> {
    return this.http.get(`${this.url}/seating/check-constraint/${id}`);
  }

  getBalanceInDate(id: string, date_in: string, date_out: string): Observable<any> {
    return this.http.get(`${this.url}/categories/balance-in-date/${id}/${date_in}/${date_out}`);
  }
  getBalanceInDateDemo(id: string, date_in: string, date_out: string, only_balance = false): Observable<any> {
    return this.http.get(`${this.url}/categories/balance-in-date-demo/${id}/${date_in}/${date_out}/${only_balance}`);
  }

  getLedgerAccount(params: any): Observable<any> {
    return this.http.get(`${this.url}/categories/ledger-account`, {params});
  }

}
