import { Component, OnInit, Inject, ViewChild, NgZone, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA, MatSort } from '@angular/material';
import { FormControl } from '@angular/forms';
import { startWith } from 'rxjs/operators/startWith';
import { map } from 'rxjs/operators/map';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as _ from 'lodash';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { TransferService } from '../../../services/transfer.service';
import { Transfer } from '../../../models/transfer';
import { WarehouseService } from '../../../services/warehouse.service';
import { InventoryService } from '../../../services/inventory.service';
import { Inventory } from '../../../models/inventory';
import { noop as _noop } from 'lodash-es';
import { take } from 'rxjs/operators';

const ITEMS_PER_PAGE = 3;
@Component({
  selector: 'app-transfer-add',
  templateUrl: './transfer-add.component.html',
  styleUrls: ['./transfer-add.component.css'],
  providers: [UserService, TransferService, WarehouseService, InventoryService]
})

export class TransferAddComponent implements OnInit {
  public titulo: string;
  public warehouse: any = {};
  public inventory: any = {};
  public transfer: any = {};
  public identity;
  public user;
  public url: string;
  public alertMessage;
  public harvest;
  public valor: number;
  public valors = [];
  public bags = [];
  public _ids = [];
  public _ids2 = [];
  public balances = [];
  public warehouses = [];
  public price;
  public disabled = false;
  public wareval1;
  public wareval2;

  myControl: FormControl = new FormControl();
  public availableWarehouse = [];
  public filteredOptions: Observable<any[]>;
  public selectedWarehouse = null;
  public selectedName = '';

  myControlR: FormControl = new FormControl();
  public availableWarehouseR = [];
  public filteredOptionsR: Observable<any[]>;
  public selectedWarehouseR = null;
  public list_code = [];
  public list_before_code = [];
  public companies = [];
  public company;
  public code;
  public selectedNameR = '';
  public loading = false;
  page = 0;
  inventoryScroll = new BehaviorSubject([]);
  myData = new TableVirtualScrollDataSource<Transfer>();
  batch = 50;        // size of each query
  lastKey = '';     // key to offset next query from
  finished = false;  // boolean when end of database is reached
  selection = new SelectionModel<Element>(true, []);
  offset: number;
  // displayedColumns: string[] = [ 'cod', 'warehouse', 'coffeefarmer', 'date', 'total_net_qq', 'entry', 'origin'];
  visibleColumns: string[] = [ 'cod', 'warehouse', 'coffeefarmer', 'date', 'total_net_qq', 'entry', 'origin'];
  highlightedRows = [];
  limit = 1000;
  full = true;
  public dataSource = new TableVirtualScrollDataSource<Transfer>();
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }

  highlight(element: Element) {
    element.highlighted = !element.highlighted;
  }

  constructor(
    private _router: Router,
    private _userService: UserService,
    private _warehouseService: WarehouseService,
    private _inventoryService: InventoryService,
    private _transferService: TransferService,
    ////
    private ngZone: NgZone,
    ///
    public dialogRef: MatDialogRef<TransferAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('data: ', this.data);
    this.titulo = 'Agregar Traslado';
    this.identity = this._userService.getCompany();
    this.user = this._userService.getIdentity();
    this.url = GLOBAL.url;
    this.transfer = {} as Transfer;
    this.inventory = {} as Inventory;
    // tslint:disable-next-line:no-unused-expression
    (this.ngZone);
  }

  ngOnInit() {
    this.harvest = this.identity.harvest;
    this._warehouseService.getWarehouses().subscribe(warehouse => {
      this.availableWarehouse = warehouse;
    });

    this._inventoryService.getInventories().subscribe( result => {
      if (this.identity._id === '5fa2a50e1c9a8952e6e1bc1c' || this.identity._id === '643d773bfebd7823442022cb') {
        const res = result.filter(wn => wn.user === this.user._id);
        this.dataSource.data = res;
        console.log(res);
      } else {
        this.dataSource.data = result;
      }
    });

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filter(val))
      );

    this._warehouseService.getWarehouses().subscribe(machine => {
      this.availableWarehouseR = machine;
    });

    this.filteredOptionsR = this.myControlR.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterM(val))
      );
    // this.getInventoriesScroll();
  }
  onScroll() {
    this.loading = true;
    if (this.finished === false) {
      this.page = this.page + 1;
      this.getInventoriesScroll();
    }
  }

  fetch() {
    this._inventoryService.getInventories().subscribe( result => {
      this.dataSource.data = result;
      console.log('Data obtenida: ', this.dataSource.data);
    });
  }

  private getInventoriesScroll(key?) {
    this._inventoryService.getInventoriesScroll(this.batch + 1, this.lastKey, this.page)
      .do(res => {
        this.loading = false;
        this.lastKey = _.last(res);
        const newInventory = _.slice(res, 0, this.batch);

        const current = this.inventoryScroll.getValue();

        if (this.lastKey === _.last(newInventory)) {
          this.finished = true;
        }
        this.inventoryScroll.next(_.concat(current, res));
      })
      .pipe(take(1))
      .subscribe();

  }

  filter(val: any): any[] {
    return this.availableWarehouse.filter(warehouse =>
      warehouse.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      return [];
  }
  filterM(val: any): any[] {
    return this.availableWarehouseR.filter(machine =>
      machine.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      return [];
  }
  getWarehouseId(id) {
    this.transfer.warehouse_send = id;
  }
  getWarehouseIdR(id) {
    this.transfer.warehouse_receives = id;
  }
  selectRow(row) {
    this.valors = [];
    this.balances = [];
    this.list_before_code = [];
    if (row.highlighted === true) {
      this.highlightedRows.push(row);
      this.pushIdsWN(this.highlightedRows);
    } else if (row.highlighted === false) {
      this.removeWN(this._ids, this.bags, this.valors, row);
    }
    this.calculateValor();
  }
  calculateValor() {
    // tslint:disable-next-line:forin
    for (const i in this.highlightedRows) {
      const qq = {
        total_net_qq: this.highlightedRows[i].total_net_qq
      };
      this.list_before_code.push(this.highlightedRows[i].cod);
      this.valors.push(qq);
    }
    // this.company = this.highlightedRows[0].company;
    this.transfer.total_qq = this.valors.reduce((sum, curr) => sum + curr.total_net_qq, 0);
  }
  removeWN(id, bags, qq, row) {
    id.forEach((item, index) => {
      if (item._id === row._id) {
        this.highlightedRows.splice(index, 1);
        this.bags.splice(index, 1);
        this.transfer.bags = this.bags.reduce((sum, curr) => sum + curr.sacos, 0);
        console.log(this.valors);
        id.splice(index, 1);
        if (Object.keys(this.highlightedRows).length === 0) {
          this.highlightedRows = [];
          this.bags = [];
        }
      }
    });
  }
  pushIdsWN(highlightedRows) {
    this._ids = [];
    this.bags = [];
    this.valors = [];
    // tslint:disable-next-line:forin
    for (const i in highlightedRows) {
      const ids = {
        _id: highlightedRows[i]._id
      };
      this._ids.push(ids);
    }
    // tslint:disable-next-line:forin
    for (const o in highlightedRows) {
      const valor = {
        sacos: highlightedRows[o].tare
      };
      this.bags.push(valor);
    }
    this.transfer.bags = this.bags.reduce((sum, curr) => sum + curr.sacos, 0);
  }
  validation() {
    if (this.wareval1 !== '' && this.wareval2 !== '') {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }
  update_wn(id) {
    let limit = 0;
    this._ids.forEach((item, index) => {
      limit++;
      this._inventoryService.getInventory(item._id).subscribe(warehouse => {
        const warehouses = warehouse;
        this.warehouses = [];
        if (warehouses.warehouses.length === 0) {
          console.log(warehouses.warehouse._id);
          this.warehouses.push(warehouses.warehouse._id);
          this.warehouses.push(id);
        } else if (warehouses.warehouses.length > 0) {
          warehouses.warehouses.forEach((element) => {
            this.warehouses.push(element);
          });
          this.warehouses.push(id);
        }
        this.inventory.warehouses = this.warehouses;
        this.inventory.warehouse = id;
        this._inventoryService.editInventory(item._id, this.inventory).subscribe(
        update_wn => {
              if (!update_wn) {
                this.alertMessage = 'Error en el servidor';
              } else {
                this.alertMessage = 'Nota de Peso actualizada correctamente';
              }
            }
          );
      });
    });
    if (limit === this._ids.length) {
      this._transferService.addTransfer(this.transfer).subscribe(
        response => {
          this.alertMessage = 'Registro creado correctamente';
          this._router.navigate(['/ordenes-secado/traslados']);
          this.dialogRef.close();
        },
        error => {
          const errorMessage = <any>error;

          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );
    }
  }
  submitForm() {

    if (this.wareval1 !== '' && this.wareval2 !== '') {
    this.disabled = true;
    this.transfer.harvest = this.harvest;
    this.transfer.weight_notes = this._ids;
    this.update_wn(this.transfer.warehouse_receives);
    } else {
      this.disabled = true;
    }
  }

}

export interface Element {
  checked: boolean;
  cod: number;
  cafffeefarmer: any;
  entry: string;
  date: string;
  warehouse: any;
  total_net_qq: number;
  origin: any;
  highlighted?: boolean;
  hovered?: boolean;
}

