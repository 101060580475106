import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from './global';
import { Router } from '@angular/router';

@Injectable()
export class UserService {

  public identity;
  public company;
  public token;
  public url: string;

  constructor(private _http: Http, private http: HttpClient, private _router: Router ) {
    this.url = GLOBAL.url;
  }

  uploadimagenew(file) {
    return this._http.post(`${this.url}/users/upload-img-new`, file);
  }

  userAuthentication(username, password) {
    const data = {
      username,
      password
    };

    const header = new Headers({ 'Content-Type': 'application/json' });
    return this._http.post(`${this.url}/auth/login`, data, { headers: header });
  }
  getIdentity() {
    const identity = JSON.parse(localStorage.getItem('user'));
    if (identity) {
      this.identity = identity;
    } else {
      this.identity = null;
    }
    return this.identity;
  }

  getCompany() {
    const company = JSON.parse(localStorage.getItem('company'));

    if (company) {
      this.company = company;
    } else {
      this.company = null;

    }
    return this.company;
  }

  getCompanyUser(id): Observable<any> { return this.http.get(`${this.url}/users/company/${id}`); }
  updateUser(user_to_update) {
    const params = JSON.stringify(user_to_update);
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getToken()}`
    });

    return this._http.put(`${this.url}/users/updates-user/${user_to_update._id}`, params, { headers: headers })
      .map(res => res.json());
  }

  getUsers(): Observable<any> { return this.http.get(`${this.url}/users`); }

  getToken() {
    const token = localStorage.getItem('token');

    if (token !== 'undefined') {
      this.token = token;
    } else {
      this.token = null;
      localStorage.removeItem('identity');
      localStorage.removeItem('token');
      localStorage.removeItem('company');
      localStorage.clear();

      this.identity = null;
      this._router.navigate(['/']);
    }
    return this.token;
  }

  // ! Con fines de prueba.
  getAllUsers(): any {
    return this.http.get(`${this.url}/users/all`);
  }

  saveRules(userId, rules): Observable<any> {
    return this.http.put(`${this.url}/users/update-rules/${userId}`, rules);
  }

  getUser(userId): Observable<any> {
    return this.http.get(`${this.url}/users/user/${userId}`);
  }

  getCurrentCompany(Id): Observable<any> {
    return this.http.get(`${this.url}/users/company/${Id}`);
  }

  createUser(user): Observable<any> {
    return this.http.post(`${this.url}/users/register-user`, user);
  }

  changePassword = (password: string): Observable<any> => this.http.put(`${this.url}/users/change-password/${password}`, password);
}
