import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort} from '@angular/material';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { MachineService } from '../../../services/machine.service';
import { DriedService } from '../../../services/dried.service';
import { Machine } from '../../../models/machine';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { MachineAddComponent } from '../machine-add/machine-add.component';
import { MachineEditComponent } from '../machine-edit/machine-edit.component';

@Component({
  selector: 'app-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.css'],
  providers: [UserService, MachineService, DriedService]
})
export class MachinesComponent implements OnInit {

  public titulo: string;
  public machines: Machine[];
  public machine = [];
  public identity;
  public token;
  public url: string;
  public confirmado;
  public alertMessage;
  closeResult: string;


  displayedColumns: string[] = ['name', 'description', 'capacity', 'action'];
  highlightedRows = [];
  public dataSource = new TableVirtualScrollDataSource<Machine>();
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }

  constructor(
    private _machineService: MachineService,
    private _driedService: DriedService,
    private _userService: UserService,
    public dialog: MatDialog
  ) {
    this.titulo = 'Maquinas';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.url = GLOBAL.url;
  }

  ngOnInit() {
    this.getMachines();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog() {
    const dialogRef = this.dialog.open(MachineAddComponent, {
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
      width: '550px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getMachines();
    });
  }

  openDialogEdit(machine: Machine) {
    const dialogRef = this.dialog.open(MachineEditComponent, {
      width: '550px',
      data: machine
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getMachines();
    });
  }

  openDialogD(machine) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar máquina',
        title: '',
        text: `¿Seguro que quieres eliminar <strong>#${machine.name}</strong>?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteMachine(machine._id);
      }
    });
  }

  getMachines() {
    this._machineService.getMachines().subscribe(res => {
        this.dataSource.data = res as Machine[];
      }, error => console.error('Error al obtener maquinas: ', error)
    );
  }

  delete(id) {
    this.alertMessage = 'El registro fue eliminado!';
    /*this._machineService.deleteMachine(id).subscribe(
      response => {
        if (!response) {
          alert('Error en el servidor');
        } else {
          this.getMachines();
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );*/
  }
  deleteMachine(id) {
    this.alertMessage = '';
    this._driedService.getDriedPerMachine(id).subscribe(
      res => {
        console.log(res.length);
        if (res.length === 0) {
          this.delete(id);
        } else {
          this.alertMessage = 'La maquina no se puede borrar';
        }
      }
    );
  }

}
