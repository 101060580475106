import { TestingService } from './../../../services/testing.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatDialog, MatSort, MatPaginator } from '@angular/material';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { InventoryService } from '../../../services/inventory.service';
import { CollectionCenterService } from '../../../services/collectionCenter.service';

import { CollectionCenter } from '../../../models/collectionCenter';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';

import { CollectionCenterAddComponent } from '../collection-center-add/collection-center-add.component';

@Component({
  selector: 'app-collection-centers',
  templateUrl: './collection-centers.component.html',
  styleUrls: ['./collection-centers.component.css'],
  providers: [UserService, CollectionCenterService, InventoryService]
})
export class CollectionCentersComponent implements OnInit {

  public titulo: string;
  public collectionCenters: CollectionCenter[];
  public collectionCenter = [];
  public identity;
  public token;
  public url: string;
  public confirmado;
  public alertMessage;
  closeResult: string;

  displayedColumns: string[] = ['nomenclature', 'name', 'description', 'address', 'cashCount', 'action'];
  highlightedRows = [];
  public dataSource = new TableVirtualScrollDataSource<CollectionCenter>();
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }

  constructor(
    private _collectionCenterService: CollectionCenterService,
    private _userService: UserService,
    private _testingService: TestingService,
    public dialog: MatDialog
  ) {
    this.titulo = 'Centros de Acopio';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.url = GLOBAL.url;
  }

  ngOnInit() {
    this.getCollectionCenters();
    const limit = this.dataSource.data.length - 7;
    console.log('Limit: ', limit);
    this.viewport.scrolledIndexChange.subscribe(value => {
      this.viewport.setTotalContentSize(limit);
      console.log('ViewPort: ', value);
    });
  }

  changePage(paginator: MatPaginator): void {
    const page = paginator.pageIndex;
    console.log('page: ', page);
    this._testingService.weightNotesValues({
      harvest: '2023 / 2024',
      // company: '5de280c2ff21b207b052c7cc', // Cafescor
      // company: '5f80c3091052d10488531338', // COcrebistol
      company: '5da11d3959d1b64b6a4a80b9', // Aprosand
      limit: 100,
      skip : 100 * page
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(CollectionCenterAddComponent, {
      width: '450px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getCollectionCenters();
    });
  }

  openDialogD(collectionCenter) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar certificación',
        title: '',
        text: `¿Seguro que quieres eliminar a<br><strong>${collectionCenter.name}</strong>?`,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteCollectionCenter(collectionCenter._id);
      }
    });
  }

  getCollectionCenters() {
    this._collectionCenterService.getCollectionCenters().subscribe(
      (res) => {
        this.dataSource.data = res as CollectionCenter[];
        console.log(this.dataSource.data);
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  deleteCollectionCenter(id) {
    this._collectionCenterService.deleteCollectionCenter(id).subscribe(
    response => {
      if (!response) {
        alert('Error en el servidor');
      } else {
        this.getCollectionCenters();
      }
    },
    error => {
      const errorMessage = <any>error;
      if (errorMessage != null) {
        const body = JSON.parse(error._body);
        console.log(error);
      }
    }
    );
  }

}
