import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TextMaskModule } from 'angular2-text-mask';

import { GLOBAL } from '../../services/global';
import { UserService } from '../../services/user.service';
import { BankService } from '../../services/bank.service';
import { BankModel } from '../../models/bankModel';

@Component({
  selector: 'app-bank-add',
  templateUrl: './bank-add.component.html',
  styleUrls: ['./bank-add.component.css'],
  providers: [UserService, BankService]
})

export class BankAddComponent implements OnInit {

  public titulo: string;
  public bank: any = {};
  public identity;
  public token;
  public harvest;
  public url: string;
  public alertMessage;
  public disabled = false;
  public mask = ['(', '5', '0', '4', ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _certificationService: BankService,
    public dialogRef: MatDialogRef<BankAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.titulo = 'Agregar un nuevo Banco';
    this.identity = this._userService.getCompany();
    this.url = GLOBAL.url;
    this.bank = {} as BankModel;
  }

  ngOnInit() {
    this.identity = this._userService.getCompany();
    this.harvest = this.identity.harvest;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  
  submitForm() {
    this.disabled = true;

    console.log(this.bank);

    this._certificationService.addBank(this.bank).subscribe(
      response => {
        this.alertMessage = 'Registro creado correctamente';
        this._router.navigate(['contabilidad/bancos']);
        this.onCancelClick();
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          this.alertMessage = body.message;
          console.log(error);
        }
      }
    );
  }

}
