import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-code',
  template: `
    <b><a [routerLink]="[cell_value]">{{cell_value}}</a></b>
  `,
})
export class CodeComponent {

  @Input()
  column: any;

  @Input()
  cell_value: string;

}
