import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';

import { SeatService } from '../../../services/seat.service';
import { PrintService } from '../../../services/print.service';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../../services/excel.service';
import { UserService } from '../../../services/user.service';
import { GLOBAL } from '../../../services/global';
@Component({
  selector: 'app-statement-of-income',
  templateUrl: './statement-of-income.component.html',
  styleUrls: ['./statement-of-income.component.css'],
  providers: [SeatService, PrintService, ExcelService, DatePipe]
})
export class StatementOfIncomeComponent implements OnInit, OnDestroy {
  public title = 'Estado de Resultados';
  public accountingProfit: any;
  public totalP_C: any;
  public alertMessage;
  public utilidad_bool = false;
  public form: FormGroup;
  public balanceGroup = [];
  public convertToExcel = [];
  public pipe = new DatePipe('es-HN');
  public step = 0;
  public currentPeriod = 0;
  public accumulatedUtility = 0;
  public periods: number[] = [];
  public currency;

  constructor(
    private _seatService: SeatService,
    private _printService: PrintService,
    private _userService: UserService,
    private excelService: ExcelService,
    private formBuilder: FormBuilder,
  ) {
    this.currentPeriod = this._userService.getIdentity().company.settings.period;
    this.currentPeriod = this.currentPeriod ? this.currentPeriod : 1;
    for (let i = 1; i <= this.currentPeriod; i ++) this.periods.push(i);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      date_in:  ['', Validators.required],
      date_out: [, Validators.required],
      status:   [false],
      period:   [this.currentPeriod],
    });
    this.form.get('period').setValue(this.currentPeriod);
    this.currency = GLOBAL.currency;
  }

  ngOnDestroy(): void {
    console.log('Destroy btn estado de resultados');
  }

  getCategories() {
    const paramsFilter = this.setDataFilter();
    this._seatService.getbalance(paramsFilter).subscribe((res: any[]) => {
      const revenge = _.orderBy(res, ['id'], ['asc', 'desc']);
      this.balanceGroup = revenge.filter(item =>
        item.name === 'Ingresos' ||
        item.name === 'Costos' ||
        item.name === 'Gastos' ||
        item.name === 'Costos y Gastos'
      );
      this.balanceGroup.forEach(item => {
        item.category = _.orderBy(item.category, (item_) => {
          return String(item_.code).substring(0, 2);
        }, 'asc');
      });
      const revengeIndexed = res.reduce((acc, curr) => ({
        ...acc,
        [curr.name.toLowerCase()]: curr,
      }), {});
      const gastos = revengeIndexed.hasOwnProperty('gastos') ? revengeIndexed['gastos'].total : 0;
      const costos = revengeIndexed.hasOwnProperty('costos') ? revengeIndexed['costos'].total : 0;
      const ingresos = revengeIndexed.hasOwnProperty('costos') ? revengeIndexed['ingresos'].total : 0;
      const utility = revengeIndexed.hasOwnProperty('accumulatedutility') ? revengeIndexed['accumulatedutility'].total : 0;

      this.accountingProfit = !revengeIndexed.hasOwnProperty('Costos y Gastos') ?
        (ingresos - (gastos + costos)).toFixed(2) :
        (ingresos - revengeIndexed['costos y gastos'].total).toFixed(2);
      this.accumulatedUtility = Number(this.accountingProfit) + utility;

      this.utilidad_bool = true;
      this.step = -1;
    }, err => console.error('Error al obtener estado de resltados: ', err));
  }

  printStatementIncome(): void {
    const params = {
      date_out: this.form.get('date_out').value,
      title: 'ESTADO DE RESULTADOS'
    };
    this._printService.getStatementIncome(params).then((result: any) => {
      if (result !== null) {
        this.alertMessage = '';
        const blob = new Blob([result], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      } else {
        this.alertMessage = 'No se encontraron asientos a la fecha';
        this.accountingProfit = 0;
      }
      })
      .catch(err => console.log('Error', err));
  }

  setDataFilter(): void {
    const params = this.form.getRawValue();
    Object.keys(params).forEach(key => {
      const current = params[key];
      if (current === '' || current === null || current === undefined) {
        delete params[key];
      } else {
        if (key === 'date_out') {
          const temp = new Date(current);
          temp.setHours(23, 59, 59);
          params[key] = temp;
        }
      }
    });
    return params;
  }

  trackOrder(index, cuenta) {
    return cuenta ? cuenta.id : undefined;
  }

  goUp(): void {
    window.document.getElementById('generalContent').scrollTo(0, 0);
  }

  changeStep(n: number) {
    this.step = this.step + n;
  }

  exportAsXLSX(object): void {
      const array: any = [];
      object.forEach((element, i) => {
            const rows = {
              Código: +element.code,
              Cuenta: element.name,
              Subtotales: 0,
              Totales: element.total
            };

            array.push(rows);
            element.group.forEach((item, index) => {
                const row = {
                  Código: item.code,
                  Cuenta: item.name,
                  Subtotales: item.sub_total,
                  Totales: 0
                };
                array.push(row);
            });
      });
      this.convertToExcel = array;

      this.excelService.exportAsExcelFile(array, 'Balance');

  }
}
