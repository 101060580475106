import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { LaboratoryService } from '../../../../services/laboratory.service';

import { Laboratory } from '../../../../models/laboratory';

@Component({
  selector: 'app-laboratory-edit',
  templateUrl: './laboratory-edit.component.html',
  styleUrls: ['./laboratory-edit.component.css']
})
export class LaboratoryEditComponent implements OnInit {
  public form: FormGroup;
  public lab: Laboratory;
  constructor(
    private _fb: FormBuilder,
    private _laboratoryService: LaboratoryService,
    private _sidebarManager: SidebarManagerService,
  ) { }

  ngOnInit() {
    this.lab = this._sidebarManager.getInput('laboratory-edit');
    this.createForm();
    this.form.patchValue(this.lab);
  }

  editLaboratory = (): void => {
    const laboratoy: Laboratory = Object.assign(this.lab, this.form.getRawValue());
    this._laboratoryService.updateLaboratory(laboratoy).subscribe(res => {
      this._sidebarManager.close('laboratory-edit', true);
    }, (err: HttpErrorResponse) => console.error('Error al editar laboratorio', err));
  }

  createForm = (): void => {
    this.form = this._fb.group({
      name:       ['', Validators.required],
      address:    ['', Validators.required],
      telephone:  ['', Validators.required],
    });
  }

}
