import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { TasterAddComponent } from './taster-add/taster-add.component';
import { TasterEditComponent } from './taster-edit/taster-edit.component';
import { TasterListComponent } from './taster-list/taster-list.component';

@NgModule({
  declarations: [
    TasterAddComponent,
    TasterEditComponent,
    TasterListComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    TasterAddComponent,
    TasterEditComponent
  ]
})
export class TastersModule { }
