import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductionListComponent } from './productions/production-list/production-list.component';
import { ShippingsComponent } from './shippings/shippings.component';
import { InvProductionListComponent } from './inv-production/inv-production-list/inv-production-list.component';

const routes: Routes = [
  { path: 'produccion', component: ProductionListComponent },
  { path: 'despachos', component: ShippingsComponent },
  { path: 'inventario-prod', component: InvProductionListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductionRoutingModule { }
