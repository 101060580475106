import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SampleAddComponent } from './sample-add/sample-add.component';
import { SampleEditComponent } from './sample-edit/sample-edit.component';
import { SampleListComponent } from './sample-list/sample-list.component';
import { PhysicalsModule } from '../physicals/physicals.module';
import { SensoriesModule } from '../sensories/sensories.module';
import { SharedModule } from '../../../shared/shared.module';
import { LaboratoriesModule } from '../laboratories/laboratories.module';
import { TastersModule } from '../tasters/tasters.module';
import { DescriptorsModule } from '../descriptors/descriptors.module';
import { LocationsModule } from '../locations/locations.module';
import { PaginationComponent } from './pagination/pagination.component';
import { ChangeStatusComponent } from './change-status/change-status.component';

@NgModule({
  declarations: [
    SampleAddComponent,
    SampleEditComponent,
    SampleListComponent,
    ChangeStatusComponent,
    PaginationComponent,
  ],
  imports: [
    SharedModule,
    PhysicalsModule,
    SensoriesModule,
    LaboratoriesModule,
    LocationsModule,
    TastersModule,
    DescriptorsModule,
  ],
  exports: [
    SampleAddComponent
  ],
  entryComponents: [
    ChangeStatusComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class SamplesModule { }
