import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatDialog } from '@angular/material';
import { ExporterEditComponent } from '../exporter-edit/exporter-edit.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

import { Exporter } from '../../../models/exporter';

import { ExporterService } from '../../../services/exporter.service';
import { ExporterAddComponent } from '../exporter-add/exporter-add.component';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';

@Component({
  selector: 'app-exporters',
  templateUrl: './exporters.component.html',
  styleUrls: ['./exporters.component.css'],
  providers: [ExporterService],
})
export class ExportersComponent implements OnInit {
  public title = 'Exportadores';

  public dataSource = new TableVirtualScrollDataSource<Exporter>();
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }
  displayedColumns: string[] = ['cod', 'name', 'email', 'address', 'country', 'telephone', 'action'];

  constructor(
    private exporterService: ExporterService,
    public dialog: MatDialog,
    ) { }

  ngOnInit() {
    this.getExportersData();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getExportersData(): void {
    this.exporterService.getExporters().subscribe(response => {
      this.dataSource.data = response as Exporter[];
      console.log('Data: ', response);
    }, error => {
      console.error('Error al obtener los exportadores');
    });
  }

  openDialogAdd(): void {
    console.log('Abriendo modal para agregar...');
    const dialogRef = this.dialog.open(ExporterAddComponent,
      {
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
        width: '580px',
      });

      dialogRef.afterClosed().subscribe(result => {
        this.getExportersData();
      });
  }

  opendDialogEdit(exporter: Exporter) {
    console.log('A editar: ', exporter);
    const dialogRef = this.dialog.open(ExporterEditComponent,
      {
        panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
        width: '580px',
        data: {
          id: exporter._id
        }
      });
    dialogRef.afterClosed().subscribe(result => {
      this.getExportersData();
    });
  }

  openModalDelete(exporter: Exporter) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar exportador',
        title: '',
        text: `¿Seguro que quieres eliminar a <br><strong>${exporter.name}</strong>`,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteExporter(exporter._id);
      }
    });

  }

  deleteExporter (id: string) {
    this.exporterService.deleteExporter(id).subscribe( response => {
      console.log('Exportador eliminado...');
      this.getExportersData();
    }, error => {
      console.error('Error al eliminar el exportador');
      console.log(error);
    });
  }

}
