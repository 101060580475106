import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

import { Transfer } from '../../../models/transfer';
import { Inventory } from '../../../models/inventory';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { TransferService } from '../../../services/transfer.service';
import { InventoryService } from '../../../services/inventory.service';
import { TransferAddComponent } from '../transfer-add/transfer-add.component';
import { ConfirmTransferComponent } from '../confirm-transfer/confirm-transfer.component';
import { UserService } from '../../../services/user.service';
import { PrintService } from '../../../services/print.service';
import { WarehouseService } from '../../../services/warehouse.service';
import { ExcelService } from '../../../services/excel.service';
import { DatePipe } from '@angular/common';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as _ from 'lodash';

@Component({
  selector: 'app-transfers',
  templateUrl: './transfers.component.html',
  styleUrls: ['./transfers.component.css'],
  providers: [TransferService, InventoryService, ExcelService, UserService, PrintService, WarehouseService]
})
export class TransfersComponent implements OnInit {
  public titulo: string;
  public transfers: Transfer[];
  public transfer: Transfer[];
  public inventory: any = {};
  public Transfer = [];
  public convertToExcel: any = {};
  pipe = new DatePipe('es-HN');
  public token;
  public cfName;
  public wName;
  public oName;

  p = 1;
  page = 0;
  transferScroll = new BehaviorSubject([]);
  batch = 12;
  lastKey = '';
  finished = false;

  displayedColumns: string[] = ['cod', 'date', 'warehouse_send', 'warehouse_receives', 'carrier', 'total_qq',
    'observations', 'status', 'action'];
  highlightedRows = [];
  public dataSource = new TableVirtualScrollDataSource<Transfer>();
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }

  personas = Array(1000).fill(0);
  public alertMessage: string;

  constructor(
    private _router: Router,
    private _transferService: TransferService,
    private _inventoryService: InventoryService,
    private _userService: UserService,
    private _printService: PrintService,
    private excelService: ExcelService,
    public dialog: MatDialog
  ) {
    this.titulo = 'Traslados';
    this.inventory = {} as Inventory;
    this.convertToExcel = {} as Transfer;
    this.token = this._userService.getToken();
  }

  ngOnInit() {
    console.log('Probando el virtualScrolling');
    this.getTransfers();
    this.getTransferPerPage();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialogC(transfer: any) {
    const dialogRef = this.dialog.open(ConfirmTransferComponent, { data: transfer });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.getTransfers();
      }
    });
  }
  openDialog() {
    const dialogRef = this.dialog.open(TransferAddComponent, {
      panelClass: ['of-x', 'scrollbar'],
      width: '940px',
      height: '650px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getTransfers();
    });
  }
  openDialogD(transfer) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar transferencia',
        title: '',
        text: `¿Seguro que quieres eliminar <strong>#${transfer.cod}</strong>?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteTransfer(transfer._id, transfer);
      }
    });
  }

  private getTransferPerPage(key?) {
    if (this.page < 0) {
      this.page = 0;
    }
    console.log(this.page);

      this._transferService.getTransferPerPage(this.batch + 1, this.lastKey, this.page)
        .do(movies => {
          this.lastKey = _.last(movies);
          const newMovies = _.slice(movies, 0, this.batch);
          if (this.lastKey === _.last(newMovies)) {
            this.finished = true;
            this.page = this.page - 1;
          }
          this.transferScroll = movies;
          // this.dataSource.data = movies;
          // this.transferScroll.next(_.concat(currentMovies, newMovies));
        })
        .pipe(take(1))
        .subscribe();

  }

  getTransfers() {
    this._transferService.getTransfers().subscribe(
      list => {
        console.log('Data obtenida:', list);
        this.dataSource.data = list as Transfer[];
        // this.dataSource = new TableVirtualScrollDataSource(res);
      },
      error => console.error('Error al obtener transferencias')
    );
  }
  getTransfer(transfert) {
    this._transferService.getTransfer(transfert._id).subscribe(
      list => {
        this.convertToExcel = list.transfer as Transfer[];
        this.convertToExcel.weight_notes.forEach((element, i) => {
          const newDate = this.pipe.transform(this.convertToExcel.date, 'shortDate');
          if (element.coffeefarmer === undefined) {
            this.cfName = '';
          } else {
            this.cfName = element.coffeefarmer.name;
          }
          if (element.warehouse_name === undefined) {
            this.wName = '';
          } else {
            this.wName = element.warehouse_name;
          }
          if (this.convertToExcel.warehouse_send === undefined) {
            this.oName = '';
          } else {
            this.oName = this.convertToExcel.warehouse_send.name;
          }
          let wareR;
          if (this.convertToExcel.warehouse_receives === undefined) {
            wareR = '';
          } else {
            wareR = this.convertToExcel.warehouse_receives.name;
          }
          const rows = {
            Nº_Nota: element.cod,
            Fecha: newDate,
            Total_Neto_qq: element.total_net_qq,
            Total_Bruto: (element.gross_total_lb - element.tare) / 100,
            Productor: this.cfName,
            Bodega: this.wName,
            Bodega_E: this.oName,
            Bodega_R: wareR
          };

          this.convertToExcel = Object.assign([], this.convertToExcel, { [i]: rows });
        });
        this.excelService.exportAsExcelFile(this.convertToExcel, 'Transferencia');
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.convertToExcel, 'Transferencia');
  }

  imprimir(transfert) {

    console.log(transfert);
    this._printService.getTransfert(transfert._id).then((res: any) => {
        console.log('Imprimiendo Transferencia');
        const blob = new Blob([res], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      })
      .catch(err => console.log('Error', err));

  }



  deleteTransfer(id, transfer) {
    const wn = transfer.weight_notes;
    let limit = 0;
    wn.forEach((item, index) => {
      limit++;
      this.inventory.warehouse = transfer.warehouse_send;
      this._inventoryService.editInventory(item._id, this.inventory).subscribe(
        response => { console.log('modificado'); });
    });

    if (limit === wn.length) {
      this._transferService.deleteTransfer(id).subscribe(
        response => {
          if (!response) {
            alert('Error en el servidor');
          } else {
            this.getTransfers();
            this._router.navigate(['/traslados']);
          }
        },
        error => {
          const errorMessage = <any>error;

          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            console.log(error);
          }
        }
      );
    }
  }

}

export interface Element {
  checked: boolean;
  cod: number;
  warehouse_send: any;
  warehouse_receives: any;
  carrier: string;
  bags: number;
  total_qq: number;
  area_s: string;
  area_r: string;
  weight_notes: any;
  observations: string;
  action: number;
  highlighted?: boolean;
  position: number;
  hovered?: boolean;
}
