import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

import { OriginService } from '../../../services/origin.service';
import { InventoryService } from '../../../services/inventory.service';
import { Origin } from '../../../models/origin';
import { OriginAddComponent } from '../origin-add/origin-add.component';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { OriginEditComponent } from '../origin-edit/origin-edit.component';
import { ImportDataService } from '../../../services/import-data.service';

@Component({
  selector: 'app-origins',
  templateUrl: './origins.component.html',
  styleUrls: ['./origins.component.css'],
  providers: []
})
export class OriginsComponent implements OnInit {

  public titulo = 'Procedencias';
  public alertMessage;
  private selectedFile: File = null;
  public lenOrigins = 0;
  public productor: any = {};
  public data: any[] = [];
  public displayedColumns: string[] = ['name', 'address', 'varieties', 'altitude', 'soil', 'action'];
  public dataSource = new TableVirtualScrollDataSource<Origin>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }

  constructor(
    private _originService: OriginService,
    private _inventoryService: InventoryService,
    private _importDataService: ImportDataService,
    public dialog: MatDialog
  ) {
    this.productor = {} as Origin;
   }

  ngOnInit() {
    this.getOrigins();
    this.paginator._intl.itemsPerPageLabel = 'Registros por página';
    this.dataSource.paginator = this.paginator;
  }
  todos() {
    this.data.forEach(element => {
      this.productor = element;
       this._originService.addOrigin(this.productor).subscribe(response => {
       console.log('Procedencia agregada: ', response);
    }, error => console.error('Error al agregar procedencia: ', error));
    });
  }

  getOrigins() {
    this._originService.getOrigins().subscribe(res => {
      this.dataSource.data = res as Origin[];
      console.log(this.dataSource.data);
      this.lenOrigins = this.dataSource.data.length;
      }, error => console.error('Error al obtener procedencias: ', error)
    );
  }

  changePage(paginator: MatPaginator): void {}

  openDialog() {
    const dialogRef = this.dialog.open(OriginAddComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '500px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getOrigins();
    });
  }

  openDialogEdit(origin) {
    console.log('ToEdit: ', origin);
    const dialogRef = this.dialog.open(OriginEditComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '500px',
      data: origin
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getOrigins();
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialogD(origin) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar procedencia',
        title: '',
        text: `¿Seguro que quieres eliminar a <br><strong>#${origin.name}</strong>?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteOrigin(origin._id);
      }
    });
  }

  delete(id) {
    this._originService.deleteOrigin(id).subscribe(response => {
      this.getOrigins();
    }, error => console.error('Error al eliminar procedencia: ', error));
  }

  deleteOrigin(id) {
    this.alertMessage = '';
    this._inventoryService.getInventoryPerOrigin(id).subscribe(response => {
        if (response.length === 0) {
          this.delete(id);
        } else {
          this.alertMessage = 'La Finca no se puede borrar';
        }
      }
    );
  }

  saveFile(file): void {
    this.selectedFile = file;
    console.log('File: ', file);
  }

  importData(): void {
    this._importDataService.importOrigins(this.selectedFile);
  }

}
