import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';


//Importaciones internas
import { UserService } from '../../services/user.service';
import { ProveedoresService } from '../../services/proveedores.service';
import { SaleService } from '../../services/sale.service';
import { ProveedoresModel } from '../../models/proveedoresModel';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { AddproveedoresComponent } from '../addproveedores/addproveedores.component';



@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.css'],
  providers: [UserService, ProveedoresService, SaleService]
})

export class ProveedoresComponent implements OnInit, AfterViewInit {

  public titulo: string;
  public proveedores: ProveedoresModel[];
  public proveedor = [];
  public identity;
  public alertMessage;
  closeResult: string;

  displayedColumns: string[] = ['rtn', 'name', 'saler', 'city', 'action'];
  highlightedRows = [];
  dataSource = new MatTableDataSource<ProveedoresModel>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _proveedoresService: ProveedoresService,
    private _saleService: SaleService,
    private _userService: UserService,
    public dialog: MatDialog
  ) {
    this.titulo = 'Proveedores';
    this.identity = this._userService.getCompany();
  }

  ngOnInit() {
    this.getProveedores();
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  openDialog() {

    this._router.navigate(['/agregar-proveedor']);
/*
    const dialogRef = this.dialog.open(AddproveedoresComponent, {
      width: '800px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getProveedores();
    });

*/

  }

  openDialogD(proveedor) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar proveedor',
        title: '',
        text: `¿Seguro que quieres eliminar a <br><strong>${proveedor.name}</strong> ?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteCustomer(proveedor._id);
      }
    });
  }


  getProveedores() {

    this._proveedoresService.getProveedores().subscribe(
      (res) => {
        this.dataSource.data = res as ProveedoresModel[];
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  delete(id) {
    this.alertMessage = 'Registo eliminado';
    this._proveedoresService.deleteProveedor(id).subscribe(
      response => {
        if (!response) {
          alert('Error en el servidor');
        } else {
          this.getProveedores();
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  deleteCustomer(id) {

    this.alertMessage = '';
    this._saleService.getSalePerCustomer(id).subscribe(
      res => {
        console.log(res.length);
        if (res.length === 0) {
           this.delete(id);
        } else {
          this.alertMessage = 'el cliente no se puede borrar';
        }
      }
    );
  }


}

