import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Category } from '../../models/category';
import { CategoryService } from '../../services/category.service';
import { GLOBAL } from '../../services/global';
import { SeatService } from '../../services/seat.service';
import { SelectCategoryComponent } from '../accounting/seat-edit/select-category/select-category.component';
import * as _ from 'lodash';
import { Seat } from '../../models/seat';

@Component({
  selector: 'app-seat-add-modal',
  templateUrl: './seat-add-modal.component.html',
  styleUrls: ['./seat-add-modal.component.css'],
  providers: [CategoryService, SeatService],
})
export class SeatAddModalComponent implements OnInit {
  public title = 'Crear asiento';
  public alertMessage = '';
  public tags = ['Anticipo', 'Liquidación', 'Gasto', 'Ingreso', 'Varios'];
  public form: FormGroup;
  public ledgerCategories: Category[] = [];
  public totalCredit = 0;
  public totalDebit = 0;
  public diff = 0.0;
  public currency;

  constructor(
    private categoryService: CategoryService,
    private seatService: SeatService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SeatAddModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('ap to createSeat: ', this.data);
  }

  ngOnInit() {
    this.currency = GLOBAL.currency;
    this.createForm();
    this.getCategories();
    this.listeners();
  }

  getCategories(): void {
    this.categoryService.getLedgerCategories().subscribe(res => {
      const categories = res as Category[];
      this.ledgerCategories = categories;
    }, error => console.error('Error al obtener cuentas: ', error));
  }

  listeners(): void {
    this.form.get('SeatCategory').valueChanges.subscribe(value => {
      this.totalDebit =  parseFloat(_.sumBy(this.form.get('SeatCategory').value, 'debit').toFixed(2));
      this.totalCredit = parseFloat(_.sumBy(this.form.get('SeatCategory').value, 'credit').toFixed(2));
      this.diff = parseFloat((this.totalDebit - this.totalCredit).toFixed(2));
      this.diff = isNaN(Number(this.diff)) ? 0.0 : this.diff;
    });
  }

  openCategoryModal(index: number): void {
    const dialogRef = this.dialog.open(SelectCategoryComponent, {
      width: '500px',
      height: '200px',
      data: {
        categories: this.ledgerCategories
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response !== undefined) {
        const name = `${response.code}-${response.name}`;
          const faControl = (<FormArray>this.form.controls['SeatCategory']).at(index);
          faControl['controls'].category.setValue(response);
          faControl['controls'].name.setValue(name);
      }
    });
  }

  submitForm(): void {
    const seat: Seat = this.form.getRawValue();
    if (this.totalCredit !== this.totalDebit) {
      this.alertMessage = 'El asiento no esta cuadrado';
      console.error('Cantidad faltante: ', this.totalCredit - this.totalDebit);
    } else {
      this.dialogRef.close();
      this.alertMessage = '';
      seat.date_voucher_n = this.form.controls['date_voucher'].value.getTime();
      seat.abbreviation = 'AC';
      seat.tag = this.data.tag;
      seat.total_debit = this.totalDebit;
      seat.total_credit = this.totalCredit;
      this.setRefItem(seat);
      console.log('submit: ', seat);
      this.seatService.addSeat(seat).subscribe(response => {
        console.log('Asiento creado: ', response);
        this.updateCategories(seat.SeatCategory);
      }, err => console.error('Error al crear asiento: ', err));
    }
  }

  updateCategories(categories: any[]): void {
    categories.forEach(current => {
      const category: Category = current.category;
      const sign = category.description === 'Pasivos' ||
        category.description === 'Capital' ||
          category.description === 'Ingresos' ? -1 : 1;
      this.categoryService.editCategory(category._id, category).subscribe(response => {
        console.log('Cuenta actualizada: ', response);
      }, err => console.error('Error al editar cuenta: ', current, err));
    });
  }

  setRefItem(seat: Seat): void {
    if (this.data.tag === 'Anticipo') {
      seat.advancePayment = this.data._id;
    } else if (this.data.tag === 'Abono') {
      seat.payment = this.data._id;
    } else if (this.data.tag === 'Liquidación') {
      seat.settlement = this.data._id;
    }
  }

  createForm(): void {
    this.form = this.fb.group({
      date_voucher:         [new Date()],
      description:          ['', Validators.required],
      tag:                  [''],
      SeatCategory: this.fb.array([
        this.getUnit(),
        this.getUnit()
      ])
    });
  }

  getUnit() {
    return this.fb.group({
      name:         [''],
      category:     ['', Validators.required],
      description:  [''],
      debit:        [0],
      credit:       [0]
    });
  }

  addUnit() {
    const control = <FormArray>this.form.controls['SeatCategory'];
    control.push(this.getUnit());
  }

  removeUnit(i: number) {
    window.document.getElementById(`item-${i}`).classList.add('animate__fadeOutRightBig');
    setTimeout(() => {
      const control = <FormArray>this.form.controls['SeatCategory'];
      control.removeAt(i);
    }, 600);
  }

  clearAllUnits() {
    const control = <FormArray>this.form.controls['SeatCategory'];
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    control.clearValidators();
    control.push(this.getUnit());
    control.push(this.getUnit());
  }

}
