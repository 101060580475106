import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { BankModel } from '../models/bankModel';

@Injectable()
export class BankService {

  public url: string ;
  public coffeeFamerData;

  constructor( public http: HttpClient ) {
    this.url = GLOBAL.url;
   }

   getBanks(): Observable<any> {
     return this.http.get(`${this.url}/banks/list`);
   }

   addBank(bank: BankModel): Observable<any> {

    return this.http.post(`${this.url}/banks/new`, bank);
  }

  getBank(id): Observable<any> {
    return this.http.get(`${this.url}/banks/${id}`);
  }

  editBank(id, bank): Observable<any> {
    return this.http.put(`${this.url}/banks/${id}`, bank);
  }

  deleteBank(id): Observable<any> {
    return this.http.delete(`${this.url}/banks/${id}`);
  }

}
