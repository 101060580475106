import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { PhysicalService } from '../../../../services/physical.service';

import { PhysicalAnalysis } from '../../../../models/physical';

@Component({
  selector: 'app-physical-list',
  templateUrl: './physical-list.component.html',
  styleUrls: ['./physical-list.component.css']
})

export class PhysicalListComponent implements OnInit {
  public physicalList: PhysicalAnalysis[] = [];

  constructor(
    private _physicalService: PhysicalService,
  ) { }

  ngOnInit() {
    this.getPhysicalList();
  }

  getPhysicalList = (): void => {
    this._physicalService.getPhysicals().subscribe((res: PhysicalAnalysis[]) => {
      this.physicalList = res;
    }, (err: HttpErrorResponse) => console.error('Error al obtener analisis fisicos', err));
  }

}
