import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { SelectCategoryComponent } from '../seat-edit/select-category/select-category.component';

import { CategoryService } from '../../../services/category.service';
import { SeatService } from '../../../services/seat.service';
import { UserService } from '../../../services/user.service';
import { GLOBAL } from '../../../services/global';

import { Category } from '../../../models/category';
import { Seat } from '../../../models/seat';
@Component({
  selector: 'app-seat-add',
  templateUrl: './seat-add.component.html',
  styleUrls: ['./seat-add.component.css'],
  providers: [SeatService, CategoryService, UserService]
})

export class SeatAddComponent implements OnInit, OnDestroy {
  public title = 'Agregar Asiento';
  public alertMessage = '';
  public form: FormGroup;
  public totalDebit = 0;
  public totalCredit = 0;
  public diff = 0;
  public period;
  public currency;
  public ledgerCategories: Category[];
  public tags = ['Anticipo', 'Liquidación', 'Gasto', 'Ingreso', 'Varios'];

  constructor(
    private _router: Router,
    private _seatService: SeatService,
    private _categoryService: CategoryService,
    private _userService: UserService,
    private fb: FormBuilder,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.createForm();
    this.listeners();
    this.getCategories();
    this.currency = GLOBAL.currency;
    this.period = this._userService.getCompany().settings.period;
    // window.document.getElementById('generalContent').addEventListener('scroll', this.scrollDown);
  }

  getCategories() {
    this._categoryService.getLedgerCategories().subscribe(res => {
        const categories = res as Category[];
        this.ledgerCategories = categories;
      }, error => console.error('Error al obtener cuentas: ', error)
    );
  }

  listeners(): void {
    this.form.get('SeatCategory').valueChanges.subscribe(value => {
      console.log('list', this.form.get('SeatCategory').value);
      // this.totalDebit =  parseFloat(_.sumBy(this.form.get('SeatCategory').value, 'debit').toFixed(2));
      // this.totalCredit = parseFloat(_.sumBy(this.form.get('SeatCategory').value, 'credit').toFixed(2));
/*       this.totalDebit = this.form.get('SeatCategory').value.reduce((acc, item) =>
        acc + (isNaN(Number(item.debit)) ? 0 : Number(item.debit)), 0);
      this.totalCredit = this.form.get('SeatCategory').value.reduce((acc, item) =>
        acc + (isNaN(Number(item.credit)) ? 0 : Number(item.credit)), 0); */

      
      this.totalDebit = parseFloat(this.form.get('SeatCategory').value.reduce((acc, item) =>
        acc + (isNaN(Number(item.debit)) ? 0 : Number(item.debit)), 0).toFixed(2));

      this.totalCredit = parseFloat(this.form.get('SeatCategory').value.reduce((acc, item) =>
        acc + (isNaN(Number(item.credit)) ? 0 : Number(item.credit)), 0).toFixed(2));

      this.diff = parseFloat((this.totalDebit - this.totalCredit).toFixed(2));
      this.diff = isNaN(Number(this.diff)) ? 0 : this.diff;
      console.log('values', this.totalDebit, this.totalCredit);
      this.alertMessage = Number(this.diff) === 0 ? '' : this.alertMessage;
    });
  }

  openCategoryModal(category: any, index: number): void {
    const dialogRef = this.dialog.open(SelectCategoryComponent, {
      width: '500px',
      height: '200px',
      data: {
        categories: this.ledgerCategories
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response !== undefined) {
        const name = `${response.code}-${response.name}`;
          const faControl = (<FormArray>this.form.controls['SeatCategory']).at(index);
          faControl['controls'].category.setValue(response);
          faControl['controls'].name.setValue(name);
      }
    });
  }

  submitForm(): void {
    const seat: Seat = this.form.getRawValue();
/*     const totalDebit = this.form.get('SeatCategory').value.reduce((acc, item) =>
      acc + (isNaN(Number(item.debit)) ? 0 : Number(item.debit)), 0);
    const totalCredit = this.form.get('SeatCategory').value.reduce((acc, item) =>
      acc + (isNaN(Number(item.credit)) ? 0 : Number(item.credit)), 0); */
    

    const totalDebit = parseFloat(this.form.get('SeatCategory').value.reduce((acc, item) =>
      acc + (isNaN(Number(item.debit)) ? 0 : Number(item.debit)), 0).toFixed(2));

    const totalCredit = parseFloat(this.form.get('SeatCategory').value.reduce((acc, item) =>
      acc + (isNaN(Number(item.credit)) ? 0 : Number(item.credit)), 0).toFixed(2));

    this.diff = parseFloat((totalDebit - totalCredit).toFixed(2));
    this.diff = isNaN(Number(this.diff)) ? 0 : this.diff;
    if (Number(totalCredit) !== Number(totalDebit)) {
      this.alertMessage = 'El asiento no esta cuadrado';
      console.error('Cantidad faltante: ', totalCredit - totalDebit);
    } else {
      this.alertMessage = '';
      seat.date_voucher_n = this.form.controls['date_voucher'].value.getTime();
      seat.abbreviation = 'AC';
      seat.period = this.period;
      seat.total_debit = totalDebit;
      seat.total_credit = totalCredit;
      console.log('submit: ', seat);
      this._seatService.addSeat(seat).subscribe(response => {
        this.updateCategories(seat.SeatCategory);
        console.log('Asiento creado: ', response);
        this._router.navigateByUrl('/contabilidad/asientos');
      }, err => console.error('Error al crear asient[o: ', err));
    }
  }

  updateCategories(categories: any[]): void {
    categories.forEach(current => {
      const category: Category = current.category;
      const sign = category.description === 'Pasivos' ||
        category.description === 'Capital' ||
        category.description === 'Ingresos' ? -1 : 1;
      category.balance += parseFloat((Number(current.debit) - Number(current.credit)).toFixed(4)) * sign;
      this._categoryService.editCategory(category._id, category).subscribe(response => {
        console.log('Saldo de cuenta actualizado: ', response);
      }, err => console.error('Error al actualizar saldo de cuenta: ', current, err));
    });
  }

  // ? -------------------- Formulario ------------------------
  createForm(): void {
    this.form = this.fb.group({
      date_voucher: [new Date(), Validators.required],
      description:  [''],
      SeatCategory: this.fb.array([
        this.getUnit(),
        this.getUnit()
      ])
    });
  }

  getUnit() {
    return this.fb.group({
      name:         [''],
      category:     ['', Validators.required],
      description:  [''],
      debit:        [0],
      credit:       [0]
    });
  }

  // Agrega nuevo elemento al group list
  addUnit() {
    const control = <FormArray>this.form.controls['SeatCategory'];
    control.push(this.getUnit());
  }

  // Remueve un elemento del group list
  removeUnit(i: number) {
    const control = <FormArray>this.form.controls['SeatCategory'];
    control.removeAt(i);
  }

  // Limpia todos los elementos del group list;
  clearAllUnits() {
    const control = <FormArray>this.form.controls['SeatCategory'];
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    control.clearValidators();
    control.push(this.getUnit());
  }

  /* private scrollDown = function(event) {
    const btnDown = window.document.getElementById('btnDown');
    const scrollTop =  (event.target as HTMLTextAreaElement).scrollTop;
    const height = (event.target as HTMLTextAreaElement).scrollHeight;
    if ((height - 1200) >= scrollTop) {
      btnDown.style.display = 'block';
    } else {
      btnDown.style.display = 'none';
    }
  }; */

  /* goPosition(direction: string): void {
    const element = window.document.getElementById('generalContent');
    element.scrollTo(0, element.scrollHeight);
  } */

  ngOnDestroy(): void {
    // window.document.getElementById('generalContent').removeEventListener('scroll', this.scrollDown);
  }
}

