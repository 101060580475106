import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Seat } from '../models/seat';

@Injectable()
export class SeatService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getSeating(): Observable<any> {
    return this.http.get(`${this.url}/seating/list`);
  }
  getAllSeats(): Observable<any> {
    return this.http.get(`${this.url}/seating/list-all`);
  }
  getAll(): Observable<any> {
    return this.http.get(`${this.url}/seating/all`);
  }
  getCategoriesInRange(params: any): Observable<any> {
    return this.http.get(`${this.url}/seating/categories-in-date`, {params: params});
  }
  getLedger(): Observable<any> {
    return this.http.get(`${this.url}/seating/ledger`);
  }
  getTrialBalance(params: any): Observable<any> {
    return this.http.get(`${this.url}/seating/trial-balance`, {params: params});
  }
  getAllCapital(): Observable<any> {
    return this.http.get(`${this.url}/seating/allCapital`);
  }
  getPeriodCapital(): Observable<any> {
    return this.http.get(`${this.url}/seating/periodCapital`);
  }
  closing(seat): Observable<any> {
    return this.http.post(`${this.url}/seating/closing`, seat);
  }
  getLedgerWithFilter(date_in, date_out, id: string): Observable<any> {
    return this.http.get(`${this.url}/seating/ledger/${date_in}/${date_out}/${id}`);
  }
  getbalance(params): Observable<any> {
    return this.http.get(`${this.url}/seating/balance`, {params: params});
  }
  addSeat(seat: Seat): Observable<any> {
    return this.http.post(`${this.url}/seating/new`, seat);
  }
  getSumSeat(): Observable<any> {
    return this.http.get(`${this.url}/seating/sum`);
  }
  getCount(): Observable<any> {
    return this.http.get(`${this.url}/seating/count-period`);
  }
  getSeat(id): Observable<any> {
    return this.http.get(`${this.url}/seating/${id}`);
  }
  editSeat(id, seat): Observable<any> {
    return this.http.put(`${this.url}/seating/${id}`, seat);
  }
  updateAll(seat): Observable<any> {
    return this.http.post(`${this.url}/seating/setAll`, seat);
  }
  deleteSeat(id): Observable<any> {
    return this.http.delete(`${this.url}/seating/${id}`);
  }
  getSeatsWithFilters(params: any): Observable<any> {
    return this.http.get(`${this.url}/seating/seats-filter`, {params: params});
  }

  getLedgerCategory(params: any): Observable<any> {
    return this.http.get(`${this.url}/seating/ledger-category`, { params: params });
  }

}
