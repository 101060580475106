import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GLOBAL } from './global';

import { Exporter } from '../models/exporter';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExporterService {
  url = GLOBAL.url;
  constructor( private http: HttpClient ) { }

  getExporters(): Observable<any> {
    return this.http.get(`${this.url}/exporters/list`);
  }
  addExporter(exporterObj: Exporter): Observable<any> {
    return this.http.post(`${this.url}/exporters/new`, exporterObj);
  }
  getExporter(id: string): Observable<any> {
    return this.http.get(`${this.url}/exporters/${id}`);
  }
  editExporter(id: string, exporterObj: Exporter): Observable<any> {
    return this.http.put(`${this.url}/exporters/${id}`, exporterObj);
  }
  deleteExporter(id): Observable<any> {
    return this.http.delete(`${this.url}/exporters/${id}`);
  }


}
