import { Component, OnInit } from '@angular/core';


import { Router, ActivatedRoute, Params } from '@angular/router';


import { UserService } from '../../services/user.service';
import { ProveedoresService } from '../../services/proveedores.service';
import { ProveedoresModel } from '../../models/proveedoresModel';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@Component({
  selector: 'app-proveedores-add',
  templateUrl: './proveedores-add.component.html',
  styleUrls: ['./proveedores-add.component.css'],
  providers: [UserService, ProveedoresService]
})


export class ProveedoresAddComponent implements OnInit {

  public titulo: string;
  public activo: true;
  public proveedores: any = {};
  public identity;
  public alertMessage;
  public mask = ['(', '5', '0', '4', ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _proveedoresService: ProveedoresService
  ) {
    this.titulo = 'Agregar nuevo proveedor';
    this.identity = this._userService.getCompany();
    this.proveedores = {} as ProveedoresModel;
  }


  ngOnInit() {
    //this.getBank();
    this.proveedores.discount = 0;
  }

  onCancelClick(){
    this._router.navigate(['/proveedores']);
  }

  mostraractivo(){
    console.log(this.activo);
    
  }
  
  saveProveedor() {

    console.log(this.proveedores);
    
    

      this._proveedoresService.addProveedor(this.proveedores).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Banco actualizado correctamente';
            this._router.navigate(['/proveedores']);
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        });

        
  
  }

}


