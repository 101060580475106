import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class PolygonService {

  public url = GLOBAL.url + '/polygons';
  constructor(
    private http: HttpClient
  ) { }

  addPolygon(polygon: any): Observable<any> {
    return this.http.post(`${this.url}/new`, polygon);
  }

  getLaboratories(): Observable<any> {
     return this.http.get(`${this.url}/list`);
   }

   getPolygon(polygonId: string): Observable<any> {
     return this.http.get(`${this.url}/${polygonId}`);
   }

   updatePolygon(polygon: any): Observable<any> {
     return this.http.put(`${this.url}/${polygon._id}`, polygon);
   }

   
   remove(id: any): Observable<any> {
     return this.http.delete(`${this.url}/${id}`);
   }
}
