import { NgModule } from '@angular/core';
import { SensoryListComponent } from './sensory-list/sensory-list.component';
import { SensoryAddComponent } from './sensory-add/sensory-add.component';
import { SensoryEditComponent } from './sensory-edit/sensory-edit.component';
import { SharedModule } from '../../../shared/shared.module';
@NgModule({
  declarations: [
    SensoryListComponent,
    SensoryAddComponent,
    SensoryEditComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    SensoryAddComponent,
    SensoryEditComponent
  ]
})
export class SensoriesModule { }
