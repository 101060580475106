import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { SettlementService } from '../../../services/settlement.service';
import { InventoryService } from '../../../services/inventory.service';
import { UserService } from '../../../services/user.service';
import { CollectionCenterService } from '../../../services/collectionCenter.service';
import { GLOBAL } from '../../../services/global';

import { Settlement } from '../../../models/settlement';
import { Inventory } from '../../../models/inventory';
import { Company } from '../../../models/company';
import { SidebarManagerService } from '../../../services/sidebar-manager.service';

@Component({
  selector: 'app-liquidation',
  templateUrl: './liquidation.component.html',
  styleUrls: ['./liquidation.component.css'],
  providers: [UserService, InventoryService, SettlementService, CollectionCenterService]
})
export class LiquidationComponent implements OnInit {

  public form: FormGroup;
  public currency = GLOBAL.currency;
  public settlement: Settlement = {} as Settlement;
  public company: Company;
  public plainCoffee = false;
  public weightNote: any;
    public identity: any = {};
      public identi: any;
  public collectionCenter: any;
    public cashCountId = '';
  public collectionCenterId = '';
  public withCollectionCenter = false;

  constructor(
    private _userService: UserService,
    private _inventoryService: InventoryService,
    private fb: FormBuilder,
    private _sidebarManager: SidebarManagerService,
    private _collectionCenterService: CollectionCenterService,
    private _settlementService: SettlementService,
  ) {
    this.company = this._userService.getCompany();
    this.identi = this._userService.getIdentity();
    this.settlement = {} as Settlement;
    this.weightNote = this._sidebarManager.getInput('liquidation');
  }
  ngOnInit() {

    console.log(this.company.price);
    this._userService.getUser(this.identi._id).subscribe(res => {
      this.identity = res;
      if (this.identity.collectionCenter) {
        this.withCollectionCenter = true;
        this._collectionCenterService.getCollectionCenter(this.identity.collectionCenter).subscribe( res => {
          this.collectionCenter = res;
          this.cashCountId = this.collectionCenter.cashCount._id;
          this.collectionCenterId = this.identity.collectionCenter;
          console.log(this.collectionCenterId, this.cashCountId);
        });
      }
    });
    console.log('weightnote', this.weightNote);
    this.weightNote.total_net_sett = this.weightNote.total_net_sett > this.weightNote.total_net_qq
      ? this.weightNote.total_net_qq
      : this.weightNote.total_net_sett;
    this.assignValues();
    this.createForm();
    this.listenerValuesForm();
  }

  assignValues = (): void => {
    this.settlement.weight_notes = [this.weightNote._id];
    this.settlement.total = this.weightNote.total_net_qq * this.company.price;
    this.settlement.total_qq = this.weightNote.total_net_qq;
    console.log(this.identity);
    if (this.company._id === '5fa2a50e1c9a8952e6e1bc1c' || this.company._id === '643d773bfebd7823442022cb') {
      this._collectionCenterService.getCollectionCenter(this.identity.collectionCenter).subscribe( res => {
        this.collectionCenter = res;
        console.log(res);
        this.settlement.coffee_price = res.collectionCenter.price;
        this.form.get('coffee_price').setValue(res.collectionCenter.price);
      });
    } else {
      console.log('no entra');
      this.settlement.coffee_price = this.company.price;
      // this.form.get('coffee_price').setValue(this.settlement.coffee_price);
    }
    this.settlement.coffeefarmer = this.weightNote.coffeefarmer.name;
    this.settlement.coffeefarmer_id = this.weightNote.coffeefarmer._id;
    this.settlement.liquidated = true;
    this.settlement.qqForWn = [this.weightNote.total_net_sett]; // * Todo el valor restante por liquidar
    this.settlement.restForWn = [0];
    this.settlement.total_deductions = 0;
    this.settlement.cost_per_beneficiary = this.weightNote.cost_per_beneficiary
      ? this.weightNote.cost_per_beneficiary
      : 0;
    this.settlement.total_net = this.settlement.total - this.settlement.cost_per_beneficiary;
  }

  listenerValuesForm = (): void => {
    this.form.get('coffee_price').valueChanges.subscribe((value: number) => {
      const total: number = this.weightNote.total_net_qq * value;
      this.form.get('total').setValue(total);
      this.form.get('total_net').setValue(total - this.settlement.cost_per_beneficiary);
    });
  }

  createSettlement = (): void => {
    const settlement: Settlement = Object.assign(this.settlement, this.form.getRawValue());
    settlement.plainCoffee = this.plainCoffee;
        if(this.collectionCenterId !== '') {
      settlement.collectionCenter = this.collectionCenterId;
    }
    if (this.cashCountId !== '') {
      settlement.cashCount = this.cashCountId;
    }
    this._settlementService.addSettlement(settlement).subscribe((res: Settlement) => {
      console.log('Liquidado desde notas de peso: ', res);
      this._sidebarManager.close('liquidation', true);
      this.updateWeightNote(res);
    }, (err: HttpErrorResponse) => console.error('Error al crear liquidacion de nota de peso', err));

  }

  updateWeightNote = (settlement: Settlement) => {
    const wn: Inventory = this.weightNote;
    wn['partialData'] = wn.hasOwnProperty('partialData') ? wn.partialData : { dry: false, settlement: false };
    wn.liquidated = true;
    wn.entry = 'Propio';
    wn.partialData.settlement = false;
    wn.total_net_sett = 0;
    wn.liquidation = wn.liquidation.concat(settlement._id);
    this._inventoryService.editInventory(wn._id, wn).subscribe(() => {
      console.log('Nota de peso liquidada: ');
    }, (err: HttpErrorResponse) => console.error('Error al liquidar nota de peso', err));
  }

  createForm = () => {
    this.form = this.fb.group({
      date:         [new Date, Validators.required],
      coffee_price: ['', Validators.required],
      status:       ['Pagado', Validators.required],
      observations: [''],
      total_net:    ['', Validators.required],
      total:        ['', Validators.required]
    });
  }

}
