import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Blend } from '../models/blend';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class BlendsService {
  public url: string;
  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url + '/blends';
  }

  addBlend = (blend: Blend): Observable<any> => this.http.post(`${this.url}/new`, blend);

  getBlends = (): Observable<any> => this.http.get(`${this.url}/list`);

  getBlend = (blendId: string): Observable<any> => this.http.get(`${this.url}/${blendId}`);

  searchSampleBlend = (filters: any = {}): Observable<any> => this.http.get(`${this.url}/sample-list`, { params: filters });

  updateBlend = (blendId: string, blend: Blend | any): Observable<any> => this.http.put(`${this.url}/${blendId}`, blend);
}
