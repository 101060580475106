import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';

import { UserService } from '../../services/user.service';
import { FD3Service } from '../../services/fd3.service';
import { DriedService } from '../../services/dried.service';
import { InventoryService } from '../../services/inventory.service';
import { Dried } from '../../models/dried';
import { Inventory } from '../../models/inventory';
import { FD3 } from '../../models/FD3';

@Component({
  selector: 'app-fd3-add',
  templateUrl: './fd3-add.component.html',
  styleUrls: ['./fd3-add.component.css'],
  providers: [UserService, FD3Service, DriedService, InventoryService]
})
export class Fd3AddComponent implements OnInit {

  public titulo: string;
  public sale: any = {};
  public fd3: any = {};
  public dried: any = {};
  public inventory: any = {};
  public identity;
  public alertMessage;
  public valor: number;
  public valors = [];
  public _ids = [];
  public harvest;
  public _ids2 = [];
  public balances = [];
  public restQQ = [];
  public wns = [];
  public qqUpdate = [];
  public qqWNUpdate = [];
  public price;
  public total_qq;
  public current_qq;
  public disabled = false;
  public statusNP = false;
  disabledbtn1 = false;

  payments_status = [
    { value: 'Depósito', viewValue: 'Depósito' },
    { value: 'Parcial', viewValue: 'Parcial' },
    { value: 'Pagado', viewValue: 'Pagado' }
  ];

  // tslint:disable-next-line:max-line-length
  displayedColumns: string[] = ['cod', 'date', 'cod_nota', 'warehouse', 'coffeefarmer', 'total_net_qq'];
  highlightedRows = [];
  dataSource = new MatTableDataSource<Dried>();

  highlight(element: Element) {
    element.highlighted = !element.highlighted;
  }

  constructor(
    private _userService: UserService,
    private _fd3Service: FD3Service,
    private _driedService: DriedService,
    private _inventoryService: InventoryService,
    public dialogRef: MatDialogRef<Fd3AddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.identity = this._userService.getCompany();
    this.fd3 = {} as FD3;
    this.dried = {} as Dried;
    this.inventory = {} as Inventory;
  }

  ngOnInit() {
    this.identity = this._userService.getCompany();
    this.harvest = this.identity.harvest;
    this.getInventories();
  }

  getInventories() {

    this._driedService.getDrieds().subscribe(
      (res) => {
        console.log('fd3 add len: ', res.length);
        this.dataSource.data = res as Dried[];
        this.dataSource.data.forEach((element, index) => {
          this.dataSource.data[index].weight_notes.forEach((wn, i) => {
            let weight = 0;
            if (wn.total_net_fd3) {
              weight = wn.total_net_fd3;
            } else {
              weight = wn.total_net_qq;
            }
            const rows = {
              _id: element._id,
              id_wn: wn._id,
              cod: element.cod,
              cod_nota: wn.cod,
              date: wn.date,
              warehouse: wn.warehouse_name,
              coffeefarmer: wn.coffeefarmer.name,
              total_net_qq: weight
            };

            if (wn.fd3 === true) {
              /* this._driedService.testFd3(wn._id, wn).subscribe(resp => {
                console.log('wn fd3 true test', resp);
              }, err => console.error('Error test fd3', err)); */
            } else {
              this.wns = this.wns.concat(rows);
            }
          });
        });
        this.dataSource.data = this.wns;
        console.log('wn len: ', this.dataSource.data.length);
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  selectRow(row) {
    console.log('Seleccionado en FD3');
    this.valors = [];
    this.balances = [];
    if (row.highlighted === true) {
      this.highlightedRows.push(row);
      this.pushIdsWN(this.highlightedRows);
    } else if (row.highlighted === false) {
      this.removeWN(this._ids, row);
    }
    this.calculateQQ();
  }
  partialWN() {
    this.qqUpdate = [];
    this.qqWNUpdate = [];
    this.total_qq = 0;
    this.total_qq = +this.fd3.total_qq;
    for (const balances of this.valors) {
      this.qqUpdate.push(0);
      this.qqWNUpdate.push(balances.total_net_qq);
    }
    this.fd3.total_kg = (this.fd3.total_qq * 100) / 2.2046;
  }
  partialNP() {
    this.statusNP = !this.statusNP;
    this.disabledbtn1 = true;
    this.qqWNUpdate = [];
    this.total_qq = 0;
    this.qqUpdate = [];
    this.total_qq = +this.fd3.total_qq;

    for (const balances of this.valors) {
      if (this.total_qq >= balances.total_net_qq) {
        this.total_qq = this.total_qq - balances.total_net_qq;
        const zero = 0;
        this.qqUpdate.push(zero);
        this.qqWNUpdate.push(balances.total_net_qq);
      } else if (balances.total_net_qq > this.total_qq) {
        this.total_qq = balances.total_net_qq - this.total_qq;
        const restQQ = this.total_qq - balances.total_net_qq;
        this.qqUpdate.push(this.total_qq);
        this.qqWNUpdate.push(-restQQ);
      }
      console.log(this.qqUpdate);
    }
    this.fd3.total_kg = (this.fd3.total_qq * 100) / 2.2046;
  }

  calculateQQ() {
    // tslint:disable-next-line:forin
    for (const i in this.highlightedRows) {
      if (this.highlightedRows[i].total_net_fd3) {
        const valor = {
          total_net_qq: this.highlightedRows[i].total_net_fd3
        };
        this.valors.push(valor);
      } else {
        const valor = {
          total_net_qq: this.highlightedRows[i].total_net_qq
        };
        this.valors.push(valor);
      }
    }
    this.partialWN();
    this.fd3.total_qq = this.valors.reduce((sum, curr) => sum + curr.total_net_qq, 0).toFixed(2);
    this.fd3.total_kg = (this.fd3.total_qq * 100) / 2.2046;
  }
  calculateTotal(prime) {
    this.fd3.total = this.fd3.sub_total + prime;
  }
  removeWN(id, row) {
    this._ids2.forEach((item, index) => {
      if (item._id === row.id_wn) {
        this.highlightedRows.splice(index, 1);
        this._ids2.splice(index, 1);
        this._ids.splice(index, 1);
        if (Object.keys(this.highlightedRows).length === 0) {
          this.highlightedRows = [];
        }
      }
    });
  }
  pushIdsWN(highlightedRows) {
    this._ids = [];
    this._ids2 = [];
    // tslint:disable-next-line:forin
    for (const i in highlightedRows) {
      const ids = {
        _id: highlightedRows[i]._id,
      };
      this._ids.push(ids);

      const ids2 = {
        _id: highlightedRows[i].id_wn,
      };
      this._ids2.push(ids2);
    }
  }
  update_dry() {
    let limitD = 0;
    this._ids.forEach((item, index) => {
      limitD++;
      if (this.qqUpdate[index] === 0) {
        this.dried.fd3 = true;
      } else {
        this.dried.fd3 = false;
      }
      this._driedService.editDried(item._id, this.dried).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Nota de Secado actualizada correctamente';
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );
    });
    if (limitD === this._ids.length) {
      this._fd3Service.addFD3(this.fd3).subscribe(
        response => {
          this.alertMessage = 'Registro creado correctamente';
          this.onCancelClick();
        },
        error => {
          const errorMessage = <any>error;

          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );
    }
  }
  update_wn() {
    let limit = 0;
    this._ids2.forEach((item, index) => {
      limit++;
      if (this.qqUpdate[index] === 0) {
        this.inventory.total_net_fd3 = 0;
        this.inventory.fd3 = true;
      } else {
        this.inventory.total_net_fd3 = this.qqUpdate[index];
        this.inventory.fd3 = false;
      }
      this._inventoryService.editInventory(item._id, this.inventory).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Nota de Secado actualizada correctamente';
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );
    });
    if (limit === this._ids2.length) {
      this.update_dry();
    }
  }
  submitForm() {
      this.disabled = true;
      this.fd3.harvest = this.harvest;
      this.fd3.dry_notes = this._ids;
      this.fd3.weight_notes = this._ids2;
      this.fd3.qqForWn = this.qqWNUpdate;
      this.fd3.restForWn = this.qqUpdate;
      this.update_wn();
  }

}

export interface Element {
  checked: boolean;
  cod: number;
  date: string;
  fd3: any;
  wet_quintals: number;
  total_net_qq: number;
  r_qq: number;
  highlighted?: boolean;
  hovered?: boolean;
}
