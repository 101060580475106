import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppSidebar, Sidebar } from '../../models/sidebar';
import { SidebarManagerService } from '../../services/sidebar-manager.service';

@Component({
  selector: 'app-sidebar-view',
  templateUrl: './sidebar-view.component.html',
  styleUrls: ['./sidebar-view.component.css'],
})
export class SidebarViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() name: string;
  public sidebar: AppSidebar;
  public select: string;
  public class: string;
  public zIndex: string;

  constructor(
    private _sidebarManager: SidebarManagerService,
  ) { }

  ngOnInit(): void {
    this.select = 'app-' + this.name;
    this.sidebar = this._sidebarManager.getSidebarData(this.name);
    this.setStyle();
  }

  setStyle = (): void => {
    const apps: Sidebar = this._sidebarManager.sidebarsIndex;
    const countOpen: number = Object.keys(apps)
      .reduce((count: number, name: string) => {
        return count + (('open' in apps[name] ? apps[name].open.value : false) ? 1 : 0);
      }, 0);

    this.class = `sidebar-form ${this.sidebar.layout.position} w-${this.sidebar.layout.width}
      h-${this.sidebar.layout.height}`;
    this.zIndex = `z-index: ${50 + countOpen};`;
  }

  ngAfterViewInit(): void {
    const active = `${this.sidebar.layout.position}-active`;
    setTimeout(() => {
      const sidebarElement: HTMLElement = document.getElementById('sidebar-' + this.name);
      sidebarElement.classList.add(active);
    }, 100);
  }

  // * Cerrar el sidebar;
  closeSidebar = (): void => {
    const active = `${this.sidebar.layout.position}-active`;
    const sidebarElement: HTMLElement = document.getElementById('sidebar-' + this.name);
    sidebarElement.classList.remove(active);
    setTimeout(() => {
      this._sidebarManager.close(this.name);
    }, 500);
  }

  ngOnDestroy(): void {
  }

}
