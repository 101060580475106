import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class LeftoversService {

  public url = GLOBAL.url + '/leftovers';

  constructor(
    public http: HttpClient
  ) {
  }

  addLeftover = (leftover): Observable<any> => this.http.post(`${this.url}/new`, leftover);
  getLeftovers = (): Observable<any> => this.http.get(`${this.url}/list`);
  getLeftover = (id: string): Observable<any> => this.http.get(`${this.url}/${id}`);
  updateLeftover = (id: string, fields: any): Observable<any> => this.http.put(`${this.url}/${id}`, fields);
}
