import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-weight-note-add',
  templateUrl: './weight-note-add.component.html',
  styleUrls: ['./weight-note-add.component.css']
})
export class WeightNoteAddComponent implements OnInit {
  public wnForm: FormGroup;
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm = (): void => {
    this.wnForm = this.fb.group({
      date: ['', Validators.required],
      origin: ['', Validators.required],
      coffeefarmer: ['', Validators.required],
      warehouse: ['', Validators.required],
      certifications: ['', Validators.required],
    });
  }

}
