import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Certification } from '../models/certification';
import { Http, Response, Headers } from '@angular/http';

@Injectable()
export class CertificationService {
  public url: string;

  constructor(private _http: Http, private http: HttpClient ) {
    this.url = GLOBAL.url;
  }

  getCertifications(): Observable<any> { return this.http.get(`${this.url}/certificaciones/list`); }

  addCertification(certification: Certification): Observable<any> {
    return this.http.post(`${this.url}/certificaciones/new`, certification);
  }
  getCertification(id): Observable<any> {return this.http.get(`${this.url}/certificaciones/${id}`); }
  editCertification(id, certification): Observable<any> {
    return this.http.put(`${this.url}/certificaciones/${id}`, certification);
  }
  deleteCertification(id): Observable<any> {
    return this.http.delete(`${this.url}/certificaciones/${id}`);
  }
}
