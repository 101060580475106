import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { startWith } from 'rxjs/operators/startWith';
import { map } from 'rxjs/operators/map';

import { GLOBAL } from '../../services/global';
import { UserService } from '../../services/user.service';
import { ReportService } from '../../services/reports.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-deposit-report',
  templateUrl: './deposit-report.component.html',
  styleUrls: ['./deposit-report.component.css'],
  providers: [UserService, ReportService]
})
export class DepositReportComponent implements OnInit {

  public titulo: string;
  public identity;
  public token;
  public url: string;
  public report: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _reportService: ReportService,
    private modalService: NgbModal,
    public dialog: MatDialog
  ) {
    this.titulo = '';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.url = GLOBAL.url;

  }

  ngOnInit() {
    this.getReport();
  }

  getReport() {
    this._reportService.getReportDeposito().then(
      (res: any) => {
        console.log(res);
        const file = new Blob([res._body], { type: 'application/pdf' });
        this.report = URL.createObjectURL(file);
        console.log(this.report);

      })
      .catch(err => console.log('Error', err));
  }


  getReportt() {
    this._reportService.getReportDeposito().then(
      (res: any) => {
        console.log(res);
        const file = new Blob([res._body], { type: 'application/pdf' });
        this.report = URL.createObjectURL(file);
        console.log(this.report);


        const a = document.createElement('a');
        a.href = this.report;

        a.download = 'Reporte.pdf';

        a.click();

      })
      .catch(err => console.log('Error', err));
  }

}
