import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SensoryAnalysis } from '../models/sensory';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class SensoryService {

  public url = GLOBAL.url + '/sensory';
  constructor(
    private http: HttpClient
  ) { }

  addSensory = (sensory: SensoryAnalysis): Observable<any> => this.http.post(`${this.url}/new`, sensory);

  getSensory = (sensoryId: string): Observable<any> => this.http.get(`${this.url}/${sensoryId}`);

  updateSensory = (sensory: SensoryAnalysis): Observable<any> => this.http.put(`${this.url}/${sensory._id}`, sensory);

  getSensories = (): Observable<any> => this.http.get(`${this.url}/list`);
}
