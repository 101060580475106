import { MatDialogRef, MAT_DIALOG_DATA, MatSort } from '@angular/material';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _moment from 'moment';
import * as _ from 'lodash';

import { CertificationService } from '../../../../services/certification.service';
import { CustomerService } from '../../../../services/customer.service';
import { ExporterService } from '../../../../services/exporter.service';
import { BrokerService } from '../../../../services/broker.service';
import { DriedService } from '../../../../services/dried.service';
import { ReferralService } from '../../../../services/referral.service';

import { Certification } from '../../../../models/certification';
import { Exporter } from '../../../../models/exporter';
import { Customer } from '../../../../models/customer';
import { Broker } from '../../../../models/broker';
import { Dried } from '../../../../models/dried';
import { Referral } from '../../../../models/referral';

@Component({
  selector: 'app-referral-edit',
  templateUrl: './referral-edit.component.html',
  styleUrls: ['./referral-edit.component.css']
})
export class ReferralEditComponent implements OnInit {
  public alertMessage;
  public qqUpdate = [];
  public title = 'Editar Remision';
  public payments_status = [
    { value: 'Depósito', viewValue: 'Depósito' },
    { value: 'Parcial', viewValue: 'Parcial' },
    { value: 'Pagado', viewValue: 'Pagado' }
  ];
  public filteredCertifications: Certification[];
  public filteredExporters: Exporter[];
  public filteredCurtomers: Customer[];
  public filteredBrokers: Broker[];
  public certificationsData: Certification[];
  public exportersData: Exporter[];
  public customersData: Customer[];
  public brokersData: Broker[];

  public displayedColumns: string[] = ['cod', 'warehouse', 'inventory_qq', 'r_qq'];
  public highlightedRows = [];
  public statusQQ = false;
  public total_qq: number;
  public form: FormGroup;
  public maxQQ = 0;
  public diccToSearch = {};
  public restqq = [];
  public returnFromParcial = null;
  public data: Referral;
  public dataSource = new TableVirtualScrollDataSource<Dried>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }

  constructor(
    private _certificationService: CertificationService,
    private _exporterService: ExporterService,
    private _customerService: CustomerService,
    private _brokerService: BrokerService,
    private _driedService: DriedService,
    private _referralService: ReferralService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ReferralEditComponent>,
    @Inject(MAT_DIALOG_DATA) public referralId: any
  ) {
    this._referralService.getReferral(this.referralId).subscribe(referral => {
      this.data = referral as Referral;
      console.log('Venta: ', this.data);
      this.highlightedRows = _.cloneDeep(this.data.inventories);
      this.highlightedRows.forEach((dried, index) => {
        dried.inventory_qq += this.data.taked_qq[index];
        dried.highlighted = true;
      });
    });
  }

  ngOnInit() {
    this.createForm();
    this.form.patchValue(this.data);
    this.getDataFromServices();
    this.listenersFormChanges();
  }

  createForm(): void {
    // * name : [valor-defecto, [validador sincrono...], [validador asincronos...]]
    this.form = this.fb.group({
      price:             ['', Validators.required],
      date:              ['', Validators.required],
      brokerName:        [''],
      exporterName:      [''],
      customerName:      [''],
      certificationName: [''],
      exporter:          [null],
      broker:            [null],
      total_qq_ps:       [0],
      total_qq_net:      [0],
      customer:          ['', Validators.required],
      contract:          [''],
      status:            ['Depósito', Validators.required],
      sub_total:         ['', Validators.required],
      prime_stamps:      [ 0, Validators.required],
      total:             ['', Validators.required],
      observations:      [''],
      warehouse:         [''],
      position:          [''],
      certification:     ['', Validators.required],
      differential:      [0,  Validators.required],
      total_tare:        [0],
      bags:        [0]
    });
  }

  getDataFromServices() {
    this._driedService.getDriedsToSale().subscribe(res => {
      const data = (res as Dried[]).filter(dried => this.highlightedRows.some(item => item._id !== dried._id));
      this.dataSource.data = data.concat(this.highlightedRows);

      console.log('Datos de Drieds: ', res);
      }, err => console.error('Error al obtener inventario seco: ', err)
    );

    this._certificationService.getCertifications().subscribe(resp => {
      this.certificationsData = resp;
      this.filteredCertifications = resp;
    });

    this._customerService.getCustomers().subscribe(resp => {
        this.customersData = resp;
        this.filteredCurtomers = resp;
     });

    this._brokerService.getBrokers().subscribe( resp => {
       this.brokersData = resp;
       this.filteredBrokers = resp;
     });

    this._exporterService.getExporters().subscribe(resp => {
      this.exportersData = resp;
      this.filteredExporters = resp;
    });

   }

  listenersFormChanges() {
    this.form.get('customerName').valueChanges.subscribe(name => {
      this.filteredCurtomers = this.customersData
      .filter((customer: Customer) => this.existString(customer.name, name));
    });

    this.form.get('certificationName').valueChanges.subscribe(name => {
      this.filteredCertifications = this.certificationsData
      .filter((certification: Certification) => this.existString(certification.name, name));
    });

    this.form.get('brokerName').valueChanges.subscribe(name => {
      this.filteredBrokers = this.brokersData
      .filter((broker: Broker) => this.existString(broker.name, name));
    });

    this.form.get('exporterName').valueChanges.subscribe(name => {
      this.filteredExporters = this.exportersData
      .filter((exporter: Exporter) => this.existString(exporter.name, name));
    });

    this.form.get('total_qq_ps').valueChanges.subscribe(value => {
      if (value !== null || value !== '') {
        const total_qq_ps = _.sumBy(this.highlightedRows, 'original_qq');
        if (value > total_qq_ps) {
          this.form.controls['total_qq_ps'].setErrors({'incorrect': true});
          this.statusQQ = true;
        } else {
          this.statusQQ = false;
          this.form.controls['total_qq_ps'].clearValidators();
          this.form.controls['total_qq_ps'].setValidators(Validators.required);
        }
      }
    });

    this.form.get('price').valueChanges.subscribe(value => {
      if (value !== null || value !== '') {
        this.calculateQQ();
      }
    });

    this.form.get('total_tare').valueChanges.subscribe(value => {
      if (value !== null || value !== '') {
        this.calculateQQ();
      }
    });

    this.form.get('differential').valueChanges.subscribe(value => {
      const total = this.form.get('sub_total').value + value;
      this.form.get('total').setValue(total);
    });

  }

  selectRow(row) {
    row['original_qq'] = row.hasOwnProperty('original_qq') ? row.original_qq : row.inventory_qq;
    this.highlightedRows = row.highlighted === true ?
      this.highlightedRows.concat(_.cloneDeep(row)) :
      this.highlightedRows.filter(item => item._id !== row._id);
    this.calculateQQ(true);
  }

  calculateQQ(original = false) {
    const tare_qq = Number(this.form.get('total_tare').value) * 0.00453592;
    const total_qq_ps = !original ? _.sumBy(this.highlightedRows, 'inventory_qq') : _.sumBy(this.highlightedRows, 'original_qq');
    const total_qq_net = this.getNum(total_qq_ps, tare_qq, '-');
    const sub_total = total_qq_net * this.form.get('price').value;
    const total = sub_total + this.form.get('differential').value;
    this.changeFormValue('total_qq_ps', total_qq_ps);
    this.changeFormValue('total_qq_net', total_qq_net);
    this.changeFormValue('sub_total', sub_total);
    this.changeFormValue('total', total);
  }

  partialQQ() {
    if (this.form.get('total_qq_ps').valid) {
      const totalInput = this.form.get('total_qq_ps').value;
      const totalSelected = _.sumBy(this.highlightedRows, 'original_qq');
      let diff = this.getNum(totalSelected, totalInput, '-');
      console.log('diff: ', diff);
      const tare_qq = Number(this.form.get('total_tare').value) * 0.00453592;
      this.form.get('total_qq_net').setValue(this.getNum(totalInput, tare_qq, '-'));
      if (diff !== 0) {
        for (let i = this.highlightedRows.length - 1; i >= 0; i--) {
          const current: any = this.highlightedRows[i];
          const lastTotal = current['original_qq'];
          if (diff < this.getNum(lastTotal, 0)) {
            console.log('NO Excede al ultimo');
            current.inventory_qq = this.getNum(lastTotal, diff, '-');
            this.returnFromParcial = _.cloneDeep(current);
            this.returnFromParcial.inventory_qq = diff;
            break;
          } else {
            diff = this.getNum(diff, current.original_qq, '-');
            this.highlightedRows = this.highlightedRows.filter(item => item._id !== current._id);
            this.dataSource.data.forEach(currentInventory => {
              if (currentInventory._id === current._id) {
                currentInventory['highlighted'] = false;
              }
            });
            this.returnFromParcial = diff === 0 ? null : this.returnFromParcial;
            console.log('Excede al ultimo: ', current, diff);
            if (diff !== 0) { continue; } else { break; }
          }
        }
      } else {
        this.returnFromParcial = null;
        console.warn('No calcula nada, valores iguales');
      }
      console.log('highlighted: ', this.highlightedRows);
      console.log('returnToParcial: ', this.returnFromParcial);
      this.calculateQQ();
    }
  }

  existString(text1: string, text2: string): boolean {
    const status = text1
    .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .indexOf(text2
      .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1;
    return status;
  }

  changeFormValue(name: string, value: any): void {
    this.form.get(name).setValue(value);
  }

  getNum(num1: any, num2: any, op = '+'): number {
    console.log(`${num1} ${op} ${num2}`);
    return parseFloat(Number(op === '+' ? num1 + num2 : num1 - num2).toFixed(6));
  }

  isLast(row): Boolean {
    if (this.highlightedRows.length > 0) {
      if (this.highlightedRows[this.highlightedRows.length - 1]._id === row._id) {
        return true;
      }
    }
    return false;
  }

  submitForm() {
    const referral: Referral = this.form.getRawValue();
    referral.status = this.returnFromParcial !== null ? 'Parcial' : 'Pagado';
    referral.warehouse = null;
    this.returnFromParcial = this.returnFromParcial === null ? {_id: null} : this.returnFromParcial;
    referral.inventories = this.highlightedRows.map( item => item._id);
    referral.taked_qq = this.highlightedRows.map(item => {
      if (item._id === this.returnFromParcial._id) {
        return this.getNum(item['original_qq'], this.returnFromParcial.inventory_qq, '-');
      } else {
        return item.inventory_qq;
      }
    });
    referral.rest_qq = this.highlightedRows.map(item => {
      if (item._id === this.returnFromParcial._id) {
        return this.returnFromParcial.inventory_qq;
      } else {
        return 0;
      }
    });

    console.log('Valor de venta: ', referral);
    this.dialogRef.close();
    // this.update_wn();
    this.registerReferral(referral);
  }

  update_wn(): void {
    this.highlightedRows.forEach(dried => {
      let current = dried;
      if (current._id === this.returnFromParcial._id) {
        current = this.returnFromParcial;
        current.sold = false;
      } else {
        current.sold = true;
        current.inventory_qq = 0;
      }
      // console.log('Orden de secado actualizada: ', current);
      this._driedService.editDried(current._id, current).subscribe( response => {
        console.log('Orden de secado actualizada: ', response);
      }, error => console.error('Error al editar secada: ', error));
    });
  }

  registerReferral(referral: Referral): void {
    this._referralService.addReferral(referral).subscribe(response => {
      this.update_wn();
      console.log('Venta registrada...: ', response);
    }, error => console.error('Error al agregar la venta: ', error));
  }

}
