import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { LocationService } from '../../../../services/location.service';

import { Location } from '../../../../models/location';

@Component({
  selector: 'app-location-add',
  templateUrl: './location-add.component.html',
  styleUrls: ['./location-add.component.css']
})
export class LocationAddComponent implements OnInit {
  public form: FormGroup;
  constructor(
    private _locationService: LocationService,
    private _sidebarManager: SidebarManagerService,
    private _fb: FormBuilder
  ) { }

  ngOnInit() {
    this.createForm();
  }

  addLocation(): void {
    const location: Location = this.form.getRawValue();
    this._locationService.addLocation(location).subscribe(res => {
      this._sidebarManager.close('location-add');
    }, err => console.error('Error al agregar ubicacion', err));
  }

  createForm(): void {
    this.form = this._fb.group({
      name:     ['', Validators.required],
      address:  ['', Validators.required],
      city:     ['', Validators.required],
      country:  ['', Validators.required]
    });
  }

}
