import { Observable } from 'rxjs/Observable';
import { GLOBAL } from './global';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private url: string = GLOBAL.url + '/companies';
  constructor(
    private http: HttpClient,
  ) { }

  updatePeriod(id: string, period: number): Observable<any> {
    return this.http.put(`${this.url}/update-period/${id}`, { newPeriod: period });
  }

  updateRegion(companyId: string, region: any): Observable<any> {
    return this.http.put(`${this.url}/update-region/${companyId}`, { region });
  }
}
