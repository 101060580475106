import { WeightNoteAddComponent } from './weight-note-add/weight-note-add.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { CertificationEditComponent } from './certification-edit/certification-edit.component';
import { CertificationAddComponent } from './certification-add/certification-add.component';
import { CertificationsComponent } from './certifications/certifications.component';
import { InventoryEditComponent } from './inventory-edit/inventory-edit.component';
import { InventoryAddComponent } from './inventory-add/inventory-add.component';
import { LiquidationComponent } from './liquidation/liquidation.component';
import { OriginEditComponent } from './origin-edit/origin-edit.component';
import { OriginAddComponent } from './origin-add/origin-add.component';
import { OriginsComponent } from './origins/origins.component';
import { ProductorEditComponent } from './productor-edit/productor-edit.component';
import { ProductoresComponent } from './productores/productores.component';
import { ProductorAddComponent } from './productor-add/productor-add.component';
import { ProductorDetalleComponent } from './detalle-productor/detalle-productor.component';
import { WarehouseEditComponent } from './warehouse-edit/warehouse-edit.component';
import { WarehouseAddComponent } from './warehouse-add/warehouse-add.component';
import { WarehousesComponent } from './warehouses/warehouses.component';
import { WeightNotesComponent } from './weight-notes/weight-notes.component';
import { WeightNotesEditComponent } from './weight-notes-edit/weight-notes-edit.component';
import { CollectionCenterAddComponent } from './collection-center-add/collection-center-add.component';
import { CollectionCenterEditComponent } from './collection-center-edit/collection-center-edit.component';
import { CollectionCentersComponent } from './collection-centers/collection-centers.component';

import { ComponentsGuard } from '../../shared/guards/components.guard';
import { IhcafeVoucherComponent } from './ihcafe-vouches/ihcafe-voucher/ihcafe-voucher.component';
import { SaleVoucherListComponent } from './sale-vouchers/sale-voucher-list/sale-voucher-list.component';

const routes: Routes = [
    /* path: '',
    canActivateChild: [ComponentsGuard],
    data: {component: 'weight_notes'}, */
    { path: 'notas-de-peso', component: WeightNotesComponent },
    { path: 'notas-de-peso/productores', component: ProductoresComponent },
    { path: 'notas-de-peso/crear-productor', component: ProductorAddComponent },
    { path: 'notas-de-peso/productores/:page', component: ProductoresComponent },
    { path: 'notas-de-peso/editar-productor/:id', component: ProductorEditComponent },
    { path: 'notas-de-peso/detalle-productor/:id', component: ProductorDetalleComponent },
    { path: 'notas-de-peso/crear-nota-de-peso', component: InventoryAddComponent },
    { path: 'notas-de-peso/create-nota-de-peso', component: WeightNoteAddComponent },
    { path: 'notas-de-peso/editar-bodega/:id', component: WarehouseEditComponent },
    { path: 'notas-de-peso/bodegas', component: WarehousesComponent },
    { path: 'notas-de-peso/crear-bodega', component: WarehouseAddComponent },
    { path: 'notas-de-peso/editar-procedencia/:id', component: OriginEditComponent },
    { path: 'notas-de-peso/procedencias', component: OriginsComponent },
    { path: 'notas-de-peso/crear-procedencia', component: OriginAddComponent },
    { path: 'notas-de-peso/editar-certificacion/:id', component: CertificationEditComponent },
    { path: 'notas-de-peso/crear-certificacion', component: CertificationAddComponent },
    { path: 'notas-de-peso/certificaciones', component: CertificationsComponent },
    { path: 'notas-de-peso/notas-de-peso', component: WeightNotesComponent },
    { path: 'notas-de-peso/nota-de-peso/:id', component: WeightNotesEditComponent },
    { path: 'notas-de-peso/editar-nota/:id', component: InventoryEditComponent },
    { path: 'notas-de-peso/liquidacion', component: LiquidationComponent },
    { path: 'notas-de-peso/comprobantes', component: IhcafeVoucherComponent },
    { path: 'notas-de-peso/comprobantes-ventas', component: SaleVoucherListComponent },
    { path: 'notas-de-peso/editar-centro-acopio/:id', component: CollectionCenterEditComponent },
    { path: 'notas-de-peso/crear-centro-acopio', component: CollectionCenterAddComponent },
    { path: 'notas-de-peso/centros-acopio', component: CollectionCentersComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WeightNoteRoutingModule { }
