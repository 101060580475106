import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { CertificationService } from '../../../services/certification.service';
import { Certification } from '../../../models/certification';

@Component({
  selector: 'app-certification-edit',
  templateUrl: './certification-edit.component.html',
  styleUrls: ['./certification-edit.component.css'],
  providers: [UserService, CertificationService]
})
export class CertificationEditComponent implements OnInit {

  public titulo: string;
  public certification: any = {};
  public identity;
  public token;
  public url: string;
  public alertMessage;
  public is_edit;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _certificationService: CertificationService
  ) {
    this.titulo = 'Editar Certificación';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.url = GLOBAL.url;
    this.certification = {} as Certification;
  }

  ngOnInit() {
    this.getCertification();
  }

  getCertification() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._certificationService.getCertification(id).subscribe(
        response => {
          if (!response) {
            this._router.navigate(['/']);
          } else {
            this.certification = response.certification;
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            console.log(error);
          }
        }
      );
    });
  }
  onSubmit() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._certificationService.editCertification(id, this.certification).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Certificación actualizada correctamente';
            // this.certification = response.certification;
            // console.log(this.certification);
            this._router.navigate(['/certificaciones']);
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );

    });
  }

}
