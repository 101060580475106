import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ProductorService } from '../../../services/productor.service';
import { UserService } from '../../../services/user.service';
import { OriginService } from '../../../services/origin.service';

import { CoffeeFarmer } from '../../../models/productor';
import { Origin } from '../../../models/origin';

import { ProductorAddComponent } from '../productor-add/productor-add.component';
@Component({
  selector: 'app-productor-edit',
  templateUrl: './productor-edit.component.html',
  providers: []
})

export class ProductorEditComponent implements OnInit, AfterViewInit {

  public title = 'Editar Productor';
  public harvest;
  public alertMessage = '';
  public civilStatus = ['Soltero', 'Casado'];
  public mask = ['(', '5', '0', '4', ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public maskihcafe = [/\d/, '-', /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
  public maskid = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
  public maskrtn = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  public filteredOriginMulti: Origin[] = [];
  public form: FormGroup;
  public status_list = ['Afiliado', 'No Afiliado', 'Proveedor', 'Empleado'];
  public genders = ['Masculino', 'Femenino'];
  public farmerTypes = ['Empresa Privada', 'Productor', 'Gobierno', 'Sociedad Civil', 'ONG'];
  public idExits = false;
  public identity;
  private ids: string[];
  private availableOrigins: Origin[] = [];
  private currentEdit: CoffeeFarmer;

  constructor(
    private _productorService: ProductorService,
    private _originService: OriginService,
    private _userService: UserService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ProductorAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.identity = this._userService.getCompany();
    this.currentEdit = this.data;
    console.log('data: ', this.data);
  }

  ngOnInit() {
    this.createForm();
    this.form.patchValue(this.data);
    this.getDataFromService();
    this.listenerFormValue();
  }

  ngAfterViewInit(): void {
    console.log('newForm: ', this.form.value);
  }

  getDataFromService(): void {
    this._productorService.getProducersId().subscribe(idList => {
      this.ids = idList;
    }, err => console.error('Error al obtener los ids: ', err));

    this._originService.getOrigins().subscribe(res => {
        this.availableOrigins = res;
        this.filteredOriginMulti = res;
      }, err => console.error('Error al obtener las fincas: ', err)
    );
  }

  listenerFormValue(): void {
    this.form.get('n_id').valueChanges.subscribe(value => {
      if (this.ids.hasOwnProperty(value) && value !== this.currentEdit.n_id) {
        console.log('Ya existe ID');
        this.idExits = true;
        this.form.controls['n_id'].setErrors({'incorrect': true});
      } else {
        this.idExits = false;
        this.form.controls['n_id'].clearValidators();
        this.form.controls['n_id'].setValidators(Validators.required);
      }
    });

    this.form.get('originName').valueChanges.subscribe((value: string) => {
      this.filteredOriginMulti = this.availableOrigins
        .filter(origin => String(origin.name)
          .toLowerCase().includes(value.toLowerCase()));
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      name:         ['', Validators.required],
      email:        [''],
      address:      ['', Validators.required],
      status:       ['Afiliado', Validators.required],
      n_id:         ['', Validators.required],
      cod_cert:     [''],
      cod_cant:     [''],
      cod_area:     [''],
      originName:   [''],
      birth:        [''],
      farmerType:   [''],
      gender:       [''],
      bank:         [''],
      origins:      ['', Validators.required],
      ihcafe:       ['', Validators.required],
      rtn:          [''],
      telephone:    ['', Validators.required],
      _id:          [''],
      civilStatus:  ['Soltero']
    });
  }

  editProducer() {
    const producer: CoffeeFarmer = this.form.getRawValue();
    console.log('form: ', producer);
    console.log('current: ', this.currentEdit);
    this.currentEdit = Object.assign(this.currentEdit, producer);
    console.log('submit: ', this.currentEdit);
    this._productorService.editProductor(this.currentEdit._id, this.currentEdit).subscribe(response => {
      this.alertMessage = 'El Productor se ha creado correctamente';
      this.dialogRef.close();
    }, error => console.error('Error al agregar productor: ', error));
  }
}
