import { MatSelectSearchModule } from '../mat-select-search/mat-select-search.module';
import { AdministrationRoutingModule } from './administration-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { CdkDetailRowDirective } from '../../directives/cdk-detail-row-directive.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LOCALE_ID, NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from '../../material-module';
import { MatCardModule, MatFormFieldModule } from '@angular/material';
import { PipesModule } from '../../pipes/pipe.module';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { AvatarModule } from 'ngx-avatar';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
// registerLocaleData(localeEs, 'es');

import { AdvancePaymentsPayComponent } from './advance-payments/advance-payments-pay/advance-payments-pay.component';
import { AdvancePaymentEditComponent } from './advance-payments/advance-payment-edit/advance-payment-edit.component';
import { AdvancePaymentAddComponent } from './advance-payments/advance-payment-add/advance-payment-add.component';
import { AdvancePaymentListComponent } from './advance-payments/advance-payment-list/advance-payment-list.component';
import { SettlementsPayComponent } from './settlements-pay/settlements-pay.component';
import { SettlementsComponent } from './settlements/settlements.component';
import { SettlementComponent } from './settlement/settlement.component';
import { SaleEditComponent } from './sales/sale-edit/sale-edit.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { PaymentsComponent } from './payments/payments.component';
import { SaleAddComponent } from './sales/sale-add/sale-add.component';
import { SalesComponent } from './sales/sale-list/sales.component';

import { SeatAddModalComponent } from '../seat-add-modal/seat-add-modal.component';
import { PurchaseContractsComponent } from './purchase-contracts/purchase-contracts.component';
import { PurchaseContractAddComponent } from './purchase-contract-add/purchase-contract-add.component';
import { PurchaseContractEditComponent } from './purchase-contract-edit/purchase-contract-edit.component';
import { PurchaseContractService } from '../../services/purchase-contract.service';
import { CollectionCenterService } from '../../services/collectionCenter.service';
import { InvoiceService } from '../../services/invoice.service';
import { SettlementAddComponent } from './settlement-add/settlement-add.component';
import { SettlementEditComponent } from './settlement-edit/settlement-edit.component';
import { WeightNotesSelectComponent } from './settlement-add/weight-notes-select/weight-notes-select.component';
import { StepTwoComponent } from './settlement-add/step-two/step-two.component';
import { StepThreeComponent } from './settlement-add/step-three/step-three.component';
/* import { StepOneReComponent } from './referrals/referral-add/step-one/step-one.component';
import { StepTwoReComponent } from './referrals/referral-add/step-two/step-two.component';
import { StepThreeReComponent } from './referrals/referral-add/step-three/step-three.component'; */
// tslint:disable-next-line:max-line-length
import { AdvancePaymentsFilterComponent } from './advance-payments/advance-payment-list/advance-payments-filter/advance-payments-filter.component';
import { StepOneComponent } from './settlement-add/step-one/step-one.component';
import { AdvancePaymentPayBalanceComponent } from './advance-payments/advance-payment-pay-balance/advance-payment-pay-balance.component';
import { PaymentAddV1Component } from './payments/payment-add-v1/payment-add-v1.component';
import { SalesModule } from './sales/sales.module';
import { ReferralsModule } from './referrals/referrals.module';
import { CashCountsModule } from './cash-counts/cash-counts.module';
import { CashCountClosingComponent } from './cash-counts/cash-count-closing/cash-count-closing.component';
import { SimpleSaleAddComponent } from './sales/simple-sale-add/simple-sale-add.component';
import { SimpleSaleEditComponent } from './sales/simple-sale-edit/simple-sale-edit.component';
import { IhcafeVoucherComponent } from './ihcafe-voucher/ihcafe-voucher.component';

@NgModule({
  declarations: [
    AdvancePaymentListComponent,
    AdvancePaymentAddComponent,
    AdvancePaymentEditComponent,
    AdvancePaymentsPayComponent,
    CdkDetailRowDirective,
    ExpensesComponent,
    PaymentsComponent,
    SettlementComponent,
    SettlementsPayComponent,
    SettlementsComponent,
    SeatAddModalComponent,
    PurchaseContractsComponent,
    PurchaseContractAddComponent,
    PurchaseContractEditComponent,
    SettlementAddComponent,
    SettlementEditComponent,
    WeightNotesSelectComponent,
    StepTwoComponent,
    StepThreeComponent,
/*     StepTwoReComponent,
    StepThreeReComponent,
    StepOneReComponent, */
    AdvancePaymentsFilterComponent,
    StepOneComponent,
    AdvancePaymentPayBalanceComponent,
    PaymentAddV1Component,
    IhcafeVoucherComponent
  ],
  imports: [
    AdministrationRoutingModule,
    BrowserModule,
    SalesModule,
    ReferralsModule,
    CashCountsModule,
    CommonModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectSearchModule,
    MaterialModule,
    TableVirtualScrollModule,
    MatCardModule,
    AvatarModule,
    NgxMaskModule.forRoot(),
  ],
  entryComponents: [
    AdvancePaymentAddComponent,
    AdvancePaymentEditComponent,
    AdvancePaymentsPayComponent,
    SettlementsPayComponent,
    SeatAddModalComponent,
    PurchaseContractAddComponent,
    PurchaseContractEditComponent,
    AdvancePaymentPayBalanceComponent,
    PaymentAddV1Component,
    CashCountClosingComponent,
    SimpleSaleAddComponent,
    SimpleSaleEditComponent,
    IhcafeVoucherComponent
  ],
  providers: [
    PurchaseContractService,
    CollectionCenterService,
    InvoiceService
    /* {provide: LOCALE_ID,
    useValue: 'es'} */
  ]
})
export class AdministrationModule { }
