import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GLOBAL } from './global';

@Injectable()
export class PrintService {
  public url: string = GLOBAL.url;

  constructor( private http: HttpClient) { }

  
  getCheckin(id: string, page): Promise<Blob> {
    return this.http.get(`${this.url}/prints/checkin/${id}/${page}`, {responseType: 'blob'}).toPromise();
  }

  getProduction(id: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/production/${id}`, {responseType: 'blob'}).toPromise();
  }

  getLiquidation(id: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/settlement/${id}`, {responseType: 'blob'}).toPromise();
  }

  getVoucher(id): Promise<Blob> {
    return this.http.get(`${this.url}/prints/voucher/${id}`, { responseType: 'blob' }).toPromise();
  }

  getAsisstance(id: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/asissts/${id}`, {responseType: 'blob'}).toPromise();
  }

  getSaleVoucher(saleVoucherId: string): void {
    const blobRes: Promise<Blob> = this.http.get(`${this.url}/prints/sale-voucher/${saleVoucherId}`, {
      responseType: 'blob',
    }).toPromise();
    blobRes
      .then((res: any) => this.displayBlob(res))
      .catch(err => console.error('Error imprmir comprobante de venta', err));
  }

  getOrderDried(id: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/dried/${id}`, {responseType: 'blob'}).toPromise();
  }

  getSeat(id: string): void {
    const blobRes: Promise<Blob> = this.http.get(`${this.url}/prints/seat/${id}`, {responseType: 'blob'}).toPromise();
    blobRes
      .then((res: any) => this.displayBlob(res))
      .catch(err => console.log('Error imprmir asiento', err));
  }

  getSample(id: string): void {
    const blobRes: Promise<Blob> = this.http.get(`${this.url}/prints/sample/${id}`, {responseType: 'blob'}).toPromise();
    blobRes
      .then((res: any) => this.displayBlob(res))
      .catch(err => console.log('Error imprimir muestra', err));
  }

  getSampleLabel(id: string): void {
    const blobRes: Promise<Blob> = this.http.get(`${this.url}/prints/sample-label/${id}`, {responseType: 'blob'}).toPromise();
    blobRes
      .then((res: any) => this.displayBlob(res))
      .catch(err => console.log('Error imprimir muestra', err));
  }

  getTransfert(id: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/transfert/${id}`, {responseType: 'blob'}).toPromise();
  }

  getFd3(id: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/fd3/${id}`, {responseType: 'blob'}).toPromise();
  }

  getFT(id: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/ft/${id}`, {responseType: 'blob'}).toPromise();
  }

  getRFA(id: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/rfa/${id}`, {responseType: 'blob'}).toPromise();
  }

  getUTZ(id: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/utz/${id}`, {responseType: 'blob'}).toPromise();
  }

  printInventory(id: string, status = false): Promise<Blob> {
    return this.http.get(`${this.url}/prints/inventario/${id}/${status}`, {responseType: 'blob'}).toPromise();
  }

  getWeightNote(id: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/weightnote/${id}`, {responseType: 'blob'}).toPromise();
  }
  getWeightNoteT(id: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/weightnotet/${id}`, {responseType: 'blob'}).toPromise();
  }

  getWeightNoteLast(id: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/weightnotelast/${id}`, {responseType: 'blob'}).toPromise();
  }

  getAdvancePayment(id: string, onlyPayment): any {
    const blob: Promise<Blob> = this.http.get(`${this.url}/prints/advancepayment/${id}/${onlyPayment}`, {responseType: 'blob'}).toPromise();
    blob
      .then((res: any) => this.displayBlob(res))
      .catch(err => console.error('Error al obtener comprobante de anticipo', err));
  }

  getPayment(id: string): void {
    const blob: Promise<Blob> = this.http.get(`${this.url}/prints/payment/${id}`, {responseType: 'blob'}).toPromise();
    blob
      .then((res: any) => this.displayBlob(res))
      .catch(err => console.error('Error al obtener recibo de abono', err));
  }

  getBalanceSheet(params: any): Promise<Blob> {
    return this.http.get(`${this.url}/prints/balance-sheet`, {params: params, responseType: 'blob'}).toPromise();
  }

  getStatementIncome(params: any): Promise<Blob> {
    return this.http.get(`${this.url}/prints/statement-income`, {params: params, responseType: 'blob'}).toPromise();
  }
  getTrialBalance(params: any): Promise<Blob> {
    return this.http.get(`${this.url}/prints/trial-balance`, {params: params, responseType: 'blob'}).toPromise();
  }

  getGroupedPayments(liquidation_id: any): Promise<Blob> {
    return this.http.get(`${this.url}/prints/grouped-paymets/${liquidation_id}`, {responseType: 'blob'}).toPromise();
  }

  getSale(sale_id: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/sale/${sale_id}`, {responseType: 'blob'}).toPromise();
  }

  getReferral(referral: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/referral/${referral}`, {responseType: 'blob'}).toPromise();
  }

  getInvoice(invoice: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/invoice/${invoice}`, {responseType: 'blob'}).toPromise();
  }

  getAccountStatus(coffeefarmer: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/account-status/${coffeefarmer}`, {responseType: 'blob'}).toPromise();
  }

  getPromissoryNote(advancePayment: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/promissory_note/${advancePayment}`, {responseType: 'blob'}).toPromise();
  }
  getPromissoryNoteAruco(advancePayment: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/promissory_note_aruco/${advancePayment}`, {responseType: 'blob'}).toPromise();
  }
  getPromissoryNoteAprocaf(advancePayment: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/promissory_note_aprocaf/${advancePayment}`, {responseType: 'blob'}).toPromise();
  }

  getPromissoryNoteAPROCASCO(advancePayment: string): Promise<Blob> {
    return this.http.get(`${this.url}/prints/promissory_note_aprocasco/${advancePayment}`, {responseType: 'blob'}).toPromise();
  }

  getPaymentBalance(fullbalanceId: string, advancePayment: string): void {
    const blob: Promise<Blob> = this.http.get(`${this.url}/prints/payment-balance/${fullbalanceId}/${advancePayment}`,
      {responseType: 'blob'}).toPromise();
    blob
      .then((res: any) => this.displayBlob(res))
      .catch(err => console.error('Error al obtener recibo de abono a saldo', err));
  }

  getProd = (id: string): void => {
    const blob: Promise<Blob> = this.http.get(`${this.url}/prints/prod/${id}`, { responseType: 'blob' }).toPromise();
    blob
      .then((res: any) => this.displayBlob(res))
      .catch(err => console.error('Error al obtener recibo de orden de producción', err));
  }

  displayBlob(res: any): void {
    const blob = new Blob([res], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  getMultipleWn(codes: string[]): any {
    const blob: Promise<Blob> = this.http.get(`${this.url}/prints/weight-notes`, {
      params: { codes },
      responseType: 'blob',
    }).toPromise();
    blob
      .then((res: any) => this.displayBlob(res))
      .catch(err => console.error('Error al obtener multiples notas de peso', err));
  }
}

