import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Inventory } from '../models/inventory';
import {Http, Response} from '@angular/http';

import { PageRequest, Page } from '../page';
import { User } from '../user';
import { of, BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';

export interface InventoryQuery {
  search: string;
  registration: Date;
}
@Injectable()
export class InventoryService {
  public url: string;
  public inventory: any = {};
  private inv: any = {};
  public token;
  filter: Observable<Inventory>;
  public cadet = [];
  private myUser = new BehaviorSubject<Inventory>(null);

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
    this.inventory = {} as Inventory;
    this.inv = {} as Inventory;
  }

  getInventoriesScroll(batch, lastKey?, page?): Observable<any> {

    const query = {
      limit: batch,
      page: page
    };
    if (lastKey) { query['startAt'] = lastKey; }

    return this.http.get(`${this.url}/inventories/lista`, { params: query });
  }
  getInventoriesScrollPerHarvest(harvest, batch, lastKey?, page?): Observable<any> {

    const query = {
      limit: batch,
      page: page,
      harvest: harvest
    };
    if (lastKey) { query['startAt'] = lastKey; }

    return this.http.get(`${this.url}/inventories/lista-per-harvest`, { params: query });
  }

  getTotalOpeningDiscounted(id): Observable<any> {
    return this.http.get(`${this.url}/inventories/total-discounted/${id}`);
  }

  getInventories(): Observable<any> {
    return this.http.get(`${this.url}/inventories/list`);
  }

  editAccess(id: string): Observable<any> {
    return this.http.get(`${this.url}/inventories/edit-access/${id}`);
  }

  getAllInventoriesFilters(params: any): Observable<any> {
    return this.http.get(`${this.url}/inventories/list-all-filters`, {params: params});
  }
  getInventoriesState(): Observable<any> {
    return this.http.get(`${this.url}/inventories/listS`);
  }
  getToday(): Observable<any> {
    return this.http.get(`${this.url}/inventories/today`);
  }
  getInventoriesFD3(): Observable<any> {
    return this.http.get(`${this.url}/inventories/listFd3`);
  }
  getInventoryPerCode(code): Observable<any> {
    return this.http.get(`${this.url}/inventories/list/${code}`);
  }
  getLiquidationInventories(): Observable<any> {
    return this.http.get(`${this.url}/inventories/listL`);
  }
  getDriedInventories(): Observable<any> {
    return this.http.get(`${this.url}/inventories/listD`);
  }
  getInventoryPerCoffeeFarmer(coffeefarmer): Observable<any> {
    return this.http.get(`${this.url}/inventories/coffeefarmer/${coffeefarmer}`);
  }
  getInventoryPerCoffeeFarmerWithoutPopulation(coffeefarmer): Observable<any> {
    return this.http.get(`${this.url}/inventories/coffeefarmerWP/${coffeefarmer}`);
  }
  getInventoryPerCoffeefarmer(coffeefarmer): Observable<any> {
    return this.http.get(`${this.url}/inventories/coffeeFarmer/${coffeefarmer}`);
  }
  getInventoryPerWarehouse(warehouse): Observable<any> {
    return this.http.get(`${this.url}/inventories/warehouse/${warehouse}`);
  }
  getNonDriedInventoryByWarehouse(warehouseId): Observable<any> {
    return this.http.get(`${this.url}/inventories/list-warehouse-non-dried/${warehouseId}`);
  }
  getNonDried(data): Observable<any> {
    return this.http.get(`${this.url}/inventories/list-non-dried`, {params: data});
  }
  getInventoryPerOrigin(origin): Observable<any> {
    return this.http.get(`${this.url}/inventories/origin/${origin}`);
  }
  getInventoryPerCert(certification): Observable<any> {
    return this.http.get(`${this.url}/inventories/certification/${certification}`);
  }
  addInventory(inventory: Inventory): Observable<any> {
    return this.http.post(`${this.url}/inventories/new`, inventory);
  }
  getSumInventory(): Observable<any> {
    return this.http.get(`${this.url}/inventories/sum`);
  }
  getSumInventoryPerHarvest(harvest): Observable<any> {
    const query = {harvest: harvest};
    return this.http.get(`${this.url}/inventories/sum-per-harvest`, {params: query});
  }
  getSumByEntry(): Observable<any> {
    return this.http.get(`${this.url}/inventories/sum-by-entry`);
  }
  getInventory(id): Observable<any> {
    return this.http.get(`${this.url}/inventories/${id}`);
  }
  editInventory(id, inventory): Observable<any> {
    return this.http.put(`${this.url}/inventories/${id}`, inventory);
  }
  deleteInventory(id): Observable<any> {
    return this.http.delete(`${this.url}/inventories/${id}`);
  }
  getCount(): Observable<any> {
    return this.http.get(`${this.url}/inventories/total-documents`);
  }
  getDataWithFilters(parameters: any): Observable<any> {
    return this.http.get(`${this.url}/inventories/list-filter`, {params: parameters});
  }
}
