import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CollectionCenter } from '../../../../models/collectionCenter';
import { CashCountsService } from '../../../../services/cash-counts.service';
import { CollectionCenterService } from '../../../../services/collectionCenter.service';
import { UserService } from '../../../../services/user.service';
import { AuthService } from '../../../../services/auth.service';
import { pick, sumBy } from 'lodash';
import { CashCount } from '../../../../models/cash-count';
import * as moment from 'moment';
import { SettlementService } from '../../../../services/settlement.service';
import { AdvancePaymentService } from '../../../../services/advancePayment.service';

@Component({
  selector: 'app-cash-count-closing',
  templateUrl: './cash-count-closing.component.html',
  styleUrls: ['./cash-count-closing.component.css']
})
export class CashCountClosingComponent implements OnInit {
  public form: FormGroup;
  public opening;
  public open;
  public totalDiscountedAP = 0;
  public totalDiscountedSett = 0;
  public identity: any = {};
  public total = {
    sales: 0,
    closing: 0,
    count: 0,
    diff: 0
  };
/*   public denominations = [
    { name: 'Gastos',         key: 'expenses',  total: 0 },
    { name: 'Cheques',        key: 'checkes',   total: 0 },
    { name: 'POS Bancario',   key: 'banking',   total: 0 },
    { name: 'Monedas',        key: 'coins',     total: 0 },
    { name: 'Transferencias', key: 'transfers', total: 0 },
    { name: 'Billete',        key: 1,           total: 0 },
    { name: 'Billete',        key: 2,           total: 0 },
    { name: 'Billete',        key: 5,           total: 0 },
    { name: 'Billete',        key: 10,          total: 0 },
    { name: 'Billete',        key: 20,          total: 0 },
    { name: 'Billete',        key: 50,          total: 0 },
    { name: 'Billete',        key: 100,         total: 0 },
    { name: 'Billete',        key: 200,         total: 0 },
    { name: 'Billete',        key: 500,         total: 0 }
  ]; */

  public denominations = [
    { name: 'Anticipos',     key: 'advancePayments', total: 0 },
    { name: 'Liquidaciones', key: 'settlements',     total: 0 },
    { name: 'Otros',         key: 'others',          total: 0 },
  ];

  constructor(
    private _fb: FormBuilder,
    //private _saleService: SalesService,
    private _authService: AuthService,
    private _userService: UserService,
    private _cashCountService: CashCountsService,
    private _advancePaymentService: AdvancePaymentService,
    private _settlementService: SettlementService,
    public _dialogRef: MatDialogRef<CashCountClosingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CashCount

  ) {
    this.identity = this._userService.getIdentity();
  }

  ngOnInit(): void {
    this.createForm();
    console.log(this.data.collectionCenter._id);
    (async () => {
      this.totalDiscountedSett = await this.getTotalOpeningDiscounted();
      this.totalDiscountedAP = await this.getTotalOpeningDiscountedAP();
      console.log('dato curioso ', this.totalDiscountedSett, this.totalDiscountedAP);
      this.denominations[0].total = this.totalDiscountedAP;
      this.denominations[1].total = this.totalDiscountedSett;
      this.total.count = this.totalDiscountedSett + this.totalDiscountedAP;
      this.open = this.totalDiscountedSett + this.totalDiscountedAP;
      this.total.diff = this.data.opening - this.open;
      const options = this.form.getRawValue();
      options.saleDate = moment(options.saleDate).format('YYYY-MM-DD HH:mm:ss');
      options.cashCount = this.data._id;
      console.log('options', options);
      this.total.sales = this.total.count;
      this.total.closing = this.data.opening;
      console.log(this.total.closing, this.data.opening, this.total.count);
      this.calculate(0);
    })();
  }

  async getTotalOpeningDiscounted() {
    const data = await this._settlementService.getTotalOpeningDiscounted(this.data.collectionCenter._id).toPromise();
    return data.sum;
  }

  async getTotalOpeningDiscountedAP() {
    const data = await this._advancePaymentService.getTotalOpeningDiscounted(this.data.collectionCenter._id).toPromise();
    return data.sum;
  }

  calculate(value: number): void {
    const count = this.denominations.reduce((acc: any, curr: any) =>
      acc + (typeof curr.key === 'number'
        ? curr.key * curr.total
        : (curr.key !== 'coins' ? (curr.total === '' ? 0 : curr.total) : (curr.total / 100))), // * Control de las monedas
      0);
    this.total.count = count;
    this.total.diff = this.total.closing - this.total.count;
  }

  closeCashCount(): void {
    const closing: CashCount = Object.assign(this.data, this.form.getRawValue());
    closing.closed = true;
    closing.closing = this.total.count;
    closing.totalCount = this.total.count;
    closing.denominationsCount = this.denominations.reduce((acc: any, obj: any) => ({
      ...acc,
      [obj.key]: obj.total
    }), {});
    console.log(closing);
    this._cashCountService.updateCashCount(closing._id, closing).subscribe(res => {
      console.log('Arqueo de caja finalizado!');
      this._dialogRef.close(true);
    }, err => console.error('Error al cerrar arqueo de caja', err));
  }

  createForm(): void {
    this.form = this._fb.group({
      saleDate:     [new Date(), Validators.required],
      // totalCount:   ['', Validators.required],
      description:  ['']
    });
  }

  clearField(event: any, field?: string): void {
    event.stopPropagation();
    this.form.get(field).setValue('');
  }

  get count(): number {
    return 1;
    return this.form.get('totalCount').value;
  }
}
