import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatPaginator } from '@angular/material';
import * as _ from 'lodash';

import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexChart,
  ApexXAxis,
  ChartComponent,
  ApexPlotOptions,
  ApexStroke,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexMarkers
} from 'ng-apexcharts';
import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { SampleService } from '../../../../services/sample.service';
import { PrintService } from '../../../../services/print.service';

import { Sample } from '../../../../models/sample';
import { AppSidebar } from '../../../../models/sidebar';
import { PhysicalValue } from '../../../../models/physical';
import { AnalysisValue } from '../../../../models/sensory';
import { DeleteModalComponent } from '../../../../pages/delete-modal/delete-modal.component';
import { PaginatorConfig } from '../../../../models/miscellaneous';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChangeStatusComponent } from '../change-status/change-status.component';
import { DriedService } from '../../../../services/dried.service';


@Component({
  selector: 'app-sample-list',
  templateUrl: './sample-list.component.html',
  styleUrls: ['./sample-list.component.css']
})
export class SampleListComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> = {
    series: [{
      name: 'Puntaje',
      data: []
    }],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '11px',
        fontWeight: 600
      },
      background: {
        opacity: 1,
        borderRadius: 2
      }
  },
    chart: {
      height: 230,
      type: 'radar',
      toolbar: {
        show: false
      },
    },
    colors: ['#AA8077'],
    stroke: {
      width: 2
    },
    fill: {
      opacity: 0.6
    },
    markers: {
      size: 3
    },
    xaxis: {
      categories: ['Aroma', 'Sabor', 'Acidez', 'Uniformidad', 'Dulzura', 'Taza Limpia', 'Posgusto',
      'Cuerpo', 'Balance', 'Catador', 'Defectos'],
      labels: {
        style: {
          fontWeight: 600,
          fontSize: '11px',
          colors: _.range(11).map(i => '#4871CB')
        }
      }
    }
  };

  public sidebars: AppSidebar[] = [
    { name: 'taster-add',        title: 'Agregar catador', layout: {
      width: 25, height: 'auto'
    }},
    { name: 'sensory-add',        title: 'Agregar análisis sensorial', layout: {
      width: 25, height: 'full'
    }},
    { name: 'sensory-edit',        title: 'Editar análisis sensorial', layout: {
      width: 25, height: 'full'
    }},
    { name: 'physical-add',        title: 'Agregar análisis físico', layout: {
      width: 25, height: 'full'
    }},
    { name: 'physical-edit',        title: 'Editar análisis fisico', layout: {
      width: 25, height: 'full'
    }},
    { name: 'descriptor-add',        title: 'Agregar descriptor', layout: {
      width: 25, height: 'auto'
    }},
    { name: 'sample-add',        title: 'Agregar muestra', layout: {
      width: 25, height: 'full'
    }},
    { name: 'sample-edit',        title: 'Editar muestra', layout: {
      width: 25, height: 'full'
    }},
    { name: 'laboratory-add',        title: 'Agregar laboratorio', layout: {
      width: 25, height: 'auto'
    }},
    { name: 'location-add',        title: 'Agregar ubicacion', layout: {
      width: 25, height: 'auto'
    }},
  ];

  public physicalValues = [
    { name: 'Humedad', key: 'humidity'},
    { name: 'Rendimiento trilla', key: 'threshingPerformance'},
    { name: 'Defecto primario', key: 'primaryDefect'},
    { name: 'Defecto secundario', key: 'secondaryDefect'},
    { name: 'Daño máquina', key: 'machineDamage'},
    { name: 'Daño banda', key: 'bandDamage'},
    { name: 'Daño permitido exportacion', key: 'exportDamage'},
  ];
  public types: string[] = ['Pre-compra', 'Oferta', 'Evaluación', 'Inventario seco', 'Mezcla'];

  public paginatorConfig: PaginatorConfig = {
    count: 0,
    page: 0,
    limit: 100,
    label: 'Inventario'
  };
  public filters = false;
  public filtersForm: FormGroup;
  public samples: Sample[];
  public currentSampleAnalysis = '';
  public selectedSampleData: SelectedSampleData;
  public selected = '';
  public lengtht = 0;
  @ViewChild('pdfTable') pdfTable: ElementRef;
  constructor(
    private _sampleService: SampleService,
    private fb: FormBuilder,
    private _printService: PrintService,
    private _driedService: DriedService,
    public _sidebarManager: SidebarManagerService,
    public dialog: MatDialog,
  ) {
    this._sidebarManager.setSidebarsList(this.sidebars);
    this.samples = [];
  }

  ngOnInit() {
    this.selectedSampleData = {};
    this.getSamples();
    this.createForm();
  }

  getSamples(): void {
    this._sampleService.getSamples({ enabled: true }).subscribe(res => {
      if (res.length > 0) {
        console.log(res);
      this.lengtht = res.length;
      this.samples = res;
      }
      if (res.length > 0) this.selectSample(res[0]);
    }, (err: HttpErrorResponse) => console.error('Error al obtener muestras', err));
  }

  selectSample(sample: Sample): void {
    if (!sample.analysis) return;
    this.selected = sample._id;
    const selected = _.cloneDeep(sample);
      this.selectedSampleData = {
        sample: selected,
        physical: selected.analysis.physical
          ? Object.assign(selected.analysis.physical, { results: this.getAnalysisResult(selected._id, selected.analysis.physical.results)})
          : null,
        sensory: selected.analysis.sensory
          ? Object.assign(selected.analysis.sensory, { results: this.getAnalysisResult(selected._id, selected.analysis.sensory.results)})
          : null,
      };
      const puntuations: number[] = this.selectedSampleData.sensory
        ? this.selectedSampleData.sensory.results.values.map(curr => curr.puntuation)
        : [];
      const series: ApexAxisChartSeries = [{
        name: 'Puntaje',
        data: puntuations
      }];
      this.chartOptions.series = series;
      console.log('selected', this.selectedSampleData);
  }

  disableSample(sample: Sample): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Anular muestra',
        title: '',
        text: `¿Seguro que quieres anular la muestra <strong>#${sample.name}</strong>?`,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this._sampleService.disableSample(sample._id).subscribe(() => this.getSamples()
        , error => console.error('Error al anular muestra: ', error));
      }
    });
  }

  statusSample(sample: any, status): void {
    sample.status = status;
    const dialogRef = this.dialog.open(ChangeStatusComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: sample
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.res === 'confirm') {
        console.log(result);
        this._sampleService.updateSample(result.id, { tasteStatus: result.status }).subscribe((res) => {
          if (result.dried) {
            this._driedService.editDried(result.dried, { tasteStatus: result.status }).subscribe((res2) => {
              console.log('inventario seco actualizado', res, res2);
              this.getSamples();
            }, (err: HttpErrorResponse) => console.error('Error al actualizar inventario seco', err));
          } else {
            console.log('inventario seco actualizado', res);
            this.getSamples();
          }
        }, error => console.error('Error al anular muestra: ', error));
      }
    });
  }

  getAnalysisResult = (sampleId: string, results: any[]) => {
    if (!results) return [];
    const index: number = results.findIndex((item) => item.sample === sampleId);
    return results[index];
  }

  openSidebar = (name: string, data: any | Sample = null): void => {
    this._sidebarManager.open(name, data);
    this._sidebarManager.afterClosed(name).subscribe(() => {
      this.getSamples();
    });
  }

  editSample = (sample: Sample) => {
    this._sidebarManager.open('sample-edit', sample);
    this._sidebarManager.afterClosed('sample-edit').subscribe((res) => res ? this.getSamples() : '');
  }

  editSensory = () => {
    this._sidebarManager.open('sensory-edit', this.selectedSampleData.sample.analysis.sensory._id);
    this._sidebarManager.afterClosed('sensory-edit').subscribe((res) => res ? this.getSamples() : '');
  }

  addSensory = (sample: Sample) => {
    this._sidebarManager.open('sensory-add', sample);
    this._sidebarManager.afterClosed('sensory-add').subscribe((res) => res ? this.getSamples() : '');
  }

  editPhysical = () => {
    this._sidebarManager.open('physical-edit', this.selectedSampleData.sample.analysis.physical._id);
    this._sidebarManager.afterClosed('physical-edit').subscribe((res) => res ? this.getSamples() : '');
  }

  printSample(sample: Sample): void {
    this._printService.getSample(sample._id);
  }

  printSampleLabel(sample: Sample): void {
    this._printService.getSampleLabel(sample._id);
  }

  changePage(paginator: MatPaginator) {
    this.paginatorConfig.page = paginator.pageIndex;
    this.getSamples();
  }

  filterData = (): void => {
    const filters = this.filtersForm.getRawValue();
    console.log('filters', filters);
  }

  clearField(event: any, field?: string): void {
    event.stopPropagation();
    this.filtersForm.get(field).setValue('');
  }

  createForm = (): void => {
    this.filtersForm = this.fb.group({
      name:     [''],
      type:     [''],
      sensory:  [false],
      physical: [false],
    });
  }

  get values(): PhysicalValue {
    if (this.selectedSampleData) {
      if (this.selectedSampleData.physical) {
        if (this.selectedSampleData.physical.results) {
          return this.selectedSampleData.physical.results.values;
        }
      }
    }
    return ;
  }

  get cleanCup(): number {
    if (this.selectedSampleData) {
      if (this.selectedSampleData.sensory) {
        const list: AnalysisValue[] = this.selectedSampleData.sensory.results.values;
        const index: number = list.findIndex((curr: AnalysisValue) => curr.key === 'cleanCup');
        return list[index].puntuation;
      }
    }
  }

}

export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  tooltip: any;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  colors: string[];
  yaxis: ApexYAxis;
  markers: ApexMarkers;
  xaxis: ApexXAxis;
}

export interface SelectedSampleData {
  sample?: Sample;
  sensory?: any;
  physical?: any;
}
