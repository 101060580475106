import { SettlementAddComponent } from './settlement-add/settlement-add.component';
import { PurchaseContractsComponent } from './purchase-contracts/purchase-contracts.component';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdvancePaymentEditComponent } from './advance-payments/advance-payment-edit/advance-payment-edit.component';
import { AdvancePaymentAddComponent } from './advance-payments/advance-payment-add/advance-payment-add.component';
import { AdvancePaymentListComponent } from './advance-payments/advance-payment-list/advance-payment-list.component';
import { SettlementComponent } from './settlement/settlement.component';
import { SaleEditComponent } from './sales/sale-edit/sale-edit.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { PaymentsComponent } from './payments/payments.component';
import { SaleAddComponent } from './sales/sale-add/sale-add.component';
import { InvoiceComponent } from './sales/invoice/invoice.component';
import { InvoicesComponent } from './sales/invoices/invoices.component';
import { SalesComponent } from './sales/sale-list/sales.component';
import { WeightNotesSelectComponent } from './settlement-add/weight-notes-select/weight-notes-select.component';
import { StepOneComponent } from './settlement-add/step-one/step-one.component';
import { StepTwoComponent } from './settlement-add/step-two/step-two.component';
import { StepThreeComponent } from './settlement-add/step-three/step-three.component';
import { StepOneReComponent } from './referrals/referral-add/step-one/step-re-one.component';
import { StepTwoReComponent } from './referrals/referral-add/step-two/step-re-two.component';
import { StepThreeReComponent } from './referrals/referral-add/step-three/step-re-three.component';
import { CashCountAddComponent } from './cash-counts/cash-count-add/cash-count-add.component';
import { CashCountEditComponent } from './cash-counts/cash-count-edit/cash-count-edit.component';
import { CashCountListComponent } from './cash-counts/cash-count-list/cash-count-list.component';
import { ReferralEditComponent } from './referrals/referral-edit/referral-edit.component';
import { ReferralAddComponent } from './referrals/referral-add/referral-add.component';
import { ReferralsComponent } from './referrals/referrals/referrals.component';
import { CashCountClosingComponent } from './cash-counts/cash-count-closing/cash-count-closing.component';

const routes: Routes = [
      // { path: 'administracion' , component: AdvancePaymentsComponent },
      { path: 'administracion/anticipos', component: AdvancePaymentListComponent },
      { path: 'administracion/crear-anticipo', component: AdvancePaymentAddComponent },
      { path: 'administracion/liquidaciones', component: SettlementComponent },
      { path: 'administracion/liquidaciones/crear/paso1', component: StepOneComponent },
      { path: 'administracion/liquidaciones/crear/paso2', component: StepTwoComponent },
      { path: 'administracion/liquidaciones/crear/paso3', component: StepThreeComponent },
      { path: 'administracion/remisiones/crear/paso1', component: StepOneReComponent },
      { path: 'administracion/remisiones/crear/paso2', component: StepTwoReComponent },
      { path: 'administracion/remisiones/crear/paso3', component: StepThreeReComponent },
      { path: 'administracion/crear-liquidacion', component: SettlementAddComponent },
      { path: 'administracion/contratos-compra', component: PurchaseContractsComponent },
      { path: 'administracion/editar-anticipo/:id', component: AdvancePaymentEditComponent },
      { path: 'administracion/abonos', component: PaymentsComponent },
      { path: 'administracion/ventas', component: SalesComponent },
      { path: 'administracion/facturas', component: InvoicesComponent },
      { path: 'administracion/ventas/factura', component: InvoiceComponent },
      { path: 'administracion/crear-venta', component: SaleAddComponent },
      { path: 'administracion/editar-venta/:id', component: SaleEditComponent },
      { path: 'administracion/egresos', component: ExpensesComponent },
      { path: 'administracion/seleccionar-notas', component: WeightNotesSelectComponent },
      { path: 'administracion/arqueo', component: CashCountListComponent },
      { path: 'administracion/crear-arqueo', component: CashCountAddComponent },
      { path: 'administracion/editar-arqueo/:id', component: CashCountEditComponent },
      { path: 'administracion/remisiones', component: ReferralsComponent },
      { path: 'administracion/crear-remision', component: ReferralAddComponent },
      { path: 'administracion/editar-remision/:id', component: ReferralEditComponent },
];

/* @NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
}) */
export const AdministrationRoutingModule: ModuleWithProviders = RouterModule.forChild(routes);

// export class AdministrationRoutingModule { }
