import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IhcafeVoucher } from '../models/ihcafe-voucher';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class IhcafeVoucherService {

  private url: string;

  constructor(
    private http: HttpClient,
  ) {
    this.url = `${GLOBAL.url}/ihcafe-voucher`;
  }

  createVoucher(coffeefarmerVoucher: IhcafeVoucher): Observable<any> {
    return this.http.post(`${this.url}/create`, coffeefarmerVoucher);
  }

  getVouchers(): Observable<any> {
    return this.http.get(`${this.url}/list`);
  }

  getVouchersPerCoffeefarmer(id): Observable<any> {
    return this.http.get(`${this.url}/list-per-coffeefarmer/${id}`);
  }

  getVoucher(id: string): Observable<any> {
    return this.http.get(`${this.url}/get/${id}`);
  }

  updateVoucher (id, voucher): Observable<any> {
    return this.http.put(`${this.url}/update/${id}`, voucher);
  }

  deleteVoucher (id): Observable<any> {
    return this.http.delete(`${this.url}/delete/${id}`);
  }
}
