import { Component, OnInit } from '@angular/core';
import { Closing } from '../../../../models/closing';
import { UserService } from '../../../../services/user.service';

import { GLOBAL } from '../../../../services/global';
import { PeriodClosingService } from '../../../../services/period-closing.service';

@Component({
  selector: 'app-period-closing-list',
  templateUrl: './period-closing-list.component.html',
  styleUrls: ['./period-closing-list.component.css']
})
export class PeriodClosingListComponent implements OnInit {
  public periodClosings: Closing[] = [];
  public currentPeriod: Number;
  public alertMessage = '';
  public loading = false;
    public currency;
  constructor(
    private closingService: PeriodClosingService,
    private _userService: UserService
  ) {
    this.currentPeriod = this._userService.getIdentity().company.settings.period;
  }

  ngOnInit() {
    this.currency = GLOBAL.currency;
    this.getPeriodClosings();
  }

  getPeriodClosings(): void {
    this.closingService.getPeriodsClosing().subscribe(res => {
      this.periodClosings = res;
      console.log('closings', res);
    }, err => console.error('Error al obtener cierres de periodo'));
  }

}
