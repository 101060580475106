import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductionRoutingModule } from './production-routing.module';

import { ProductionAddComponent } from './productions/production-add/production-add.component';
import { ProductionEditComponent } from './productions/production-edit/production-edit.component';
import { ShippingsComponent } from './shippings/shippings.component';
import { ShippingAddComponent } from './shipping-add/shipping-add.component';
import { ShippingEditComponent } from './shipping-edit/shipping-edit.component';
import { SharedModule } from '../../shared/shared.module';
import { ProductionsModule } from './productions/productions.module';
import { InvProductionModule } from './inv-production/inv-production.module';

@NgModule({
  declarations: [
    ShippingsComponent,
    ShippingAddComponent,
    ShippingEditComponent
  ],
  imports: [
    SharedModule,
    ProductionsModule,
    ProductionRoutingModule,
    InvProductionModule
  ],
  entryComponents: [
    ProductionAddComponent,
    ProductionEditComponent,
    ShippingAddComponent,
    ShippingEditComponent,
  ],
})
export class ProductionModule { }
