export class AdvancePayment {
  constructor(
    public _id: string,
    public company: any,
    public cod: number,
    public liquidation: any,
    public payment: any,
    public coffeefarmer: any,
    public collectionCenter: any,
    public cashCount: any,
    public date: string,
    public date_end: string,
    public days: number,
    public harvest: string,
    public document_type: string,
    public mount: number,
    public i_porcent: number,
    public interest: number,
    public total_interest: number,
    public payment_type: string,
    public payment_status: string,
    public n_cheque: string,
    public weight_note: any,
    public liquidated: boolean,
    public last: boolean,
    public type: string,
    public mora: number,
    public totalDebt: number,
    public total_balance: number,
    public deductions: number,
    public observations: string,
    public user: string,
    public current_capital: number,
    public enabled: Boolean,
    public projected: Boolean,
    public grace: Boolean,
    public grace_days: Number,
    public commercial_date: Boolean
  ) { }
}
