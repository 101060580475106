import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Inventory } from '../models/inventory';
import { Seat } from '../models/seat';
import * as moment from 'moment';

import { Settlement } from '../models/settlement';
import { AdvancePayment } from '../models/advancePayment';
import { AdvancePaymentService } from './advancePayment.service';
import { InventoryService } from './inventory.service';
import { SeatService } from './seat.service';
import { SettlementService } from './settlement.service';
import { differenceInDays } from 'date-fns';
import { PaymentService } from './payment.service';
import { Payment } from '../models/payment';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class TestingService {

  constructor(
    private settlementService: SettlementService,
    private inventoryService: InventoryService,
    private advancePaymentService: AdvancePaymentService,
    private paymentService: PaymentService,
    private seatService: SeatService,
    private userService: UserService,
  ) {}

  // * ================================================ A N T I C I P O S ===========================================

  /*
    * Verificar anticipos que fueron pagados pero los valores: interest, total_balance, current_capital no son 0;
    * Tambien los saldos negativos pero su estado no es pagado;
    * Main TEST
  */
 paidAdvancePaymentsValues(changes = true, local = true): void {
  /* this.advancePaymentService.getAllAdvancePayments().subscribe((response: AdvancePayment[]) => {
    const grouped = _.groupBy(response, 'company');
    console.log('grouped: ', grouped);
  }); */
  /* this.advancePaymentService.getAllAdvancePayments().subscribe((response2: AdvancePayment[]) => {
    const grouped = _.groupBy(response2, 'company');
    let list = '';
    Object.keys(grouped).forEach(company => {
      list = list + company + '\n';
    });
    console.log(list);
  }); */
    this.advancePaymentService.getAdvancePaymentsWithFilter({
      payment_status: ['Pendiente', 'Parcial'],
      limit: 500,
      skip: 0,
    }).subscribe((response: any) => {
      const data = response.items;
      const toUpdate = data.filter(item => item.enabled === true);
      console.log('toUpdate: ', toUpdate.length);
      toUpdate.forEach((current, index) => {
        this.test3(current, changes);
        if (index === toUpdate.length - 1) {
          console.log('------FINALIZADOOOOOOOOOOO-------');
        }
      });
    }, err => console.error('Error al obtener los anticipos para testing 1: ', err));
  }

  calculateDiffDays(item: AdvancePayment): JSON {
    let days = 0;
    const daysData = JSON.parse(JSON.stringify({}));
    const date_in = new Date(item.date);
    date_in.setHours(0, 0, 0, 0);
      if (item.projected) {
        days = this.calculateDaysCommercialYear(date_in, new Date(item.date_end));
      } else {
        days = !item.commercial_date ?
          this.getDays(date_in, moment()['_d']) :
          this.calculateDaysCommercialYear(date_in, moment()['_d']);
      }
    daysData['totalDays'] = days;
    if (item.grace) {
      if (item.grace_days >= 0) {
        const grace = Number(days) - Number(item.grace_days);
        daysData['days'] = grace > 0 ? grace : 0;
      }
    } else {
      daysData['days'] = days;
    }
    return daysData;
  }

  calculateDaysCommercialYear(date_in: any, date_out: any): number {
    // yyyy mm dd
    const date1 = date_in;
    const date2 = date_out;
    const splitedDate1 = [date1.getFullYear(), date1.getMonth() + 1, date1.getDate()];
    const splitedDate2 = [date2.getFullYear(), date2.getMonth() + 1, date2.getDate()];
    let days = 0;

    // * Mientras el mes y el a?o sea diferente, que sume 30 cada mes.
    while (
      (splitedDate1[1] !== splitedDate2[1]) ||
      (splitedDate1[0] !== splitedDate2[0])
      ) {
      days += 30;
      const currentMonth = splitedDate1[1];
      splitedDate1[1] = currentMonth === 12 ? 1 : currentMonth + 1;
      splitedDate1[0] = currentMonth === 12 ? splitedDate1[0] + 1 : splitedDate1[0];
    }
    // * Usar 31 - splitedDate1[2] si se incluye el dia de expiracion.
    const restDays = splitedDate2[2] <= 30 ? splitedDate2[2] - splitedDate1[2] : 30 - splitedDate1[2];
    days += restDays;
    return days;
  }

  getDays(date_in: any, date_out: any): number {
    const diffDays = Math.abs(differenceInDays(date_in, date_out));
    return diffDays;
  }

  // * Anticipos Ya pagados pero se quedaron con valores algunas propiedades.
  private test1 = (item) => {
    if (
      (
        item.total_balance !== 0 ||
        item.current_capital !== 0 ||
        item.total_interest !== 0 ||
        item.interest !== 0
      ) &&
      item.payment_status === 'Pagado') {
        /* item.total_balance = 0;
        item.current_capital = 0;
        item.total_interest = 0;
        item.interest = 0;
        this.advancePaymentService.editAdvancePayment(item._id, item).subscribe(res => {
          console.log('Anticipo editado: ', res);
        }, err => console.error('Error a; editar anticipo: ', err)); */
        console.error('Estado pagado pero valores diferente de 0: ', item);
    }
  }

  // * Anticipos que no se han pagado pero algunas propiedades erroneas
  private test2 = (item) => {
    if (
      (
        item.total_balance === 0 ||
        item.current_capital === 0
      ) &&
      item.payment_status !== 'Pagado') {
        // item.current_capital = parseFloat(Number(Number(item.total_balance) - Number(item.total_interest)).toFixed(2));
        console.error('Estado NO pagado pero valores igual a 0: ', item);
    }
  }

  // * Verifica si la cantidad de dias transcurridos coinciden con los del anticipo.
  private test3 = (item, changes = false) => {
    const totalDays: any = this.calculateDiffDays(item);
    if (totalDays.totalDays !== item.days && item.payment_status !== 'Pagado' && item.enabled !== false) {
      console.error('Los dias no coinciden: ', totalDays.totalDays - item.days, item.date, item);
      if (changes) {
        setTimeout(() => this.advancePaymentService.recalculateAdvancePayment(item._id, true), 100);
      }
    } else {
      console.log('dias bien');
    }
  }

  private test4 = () => {
    this.advancePaymentService.getAllAdvancePayments().subscribe(response => {
      const grouped = _.groupBy(response, 'company');
      const cocasancol = grouped['5f340cc21f69e575e1395b48'];
      console.log('cocasancol: ', cocasancol);
      cocasancol.forEach(current => {
        this.test3(current, true);
      });
      // console.log('grouped: ', grouped);
    });
  }

  // * ================================================ A B O N O S ===========================================
  paymentsValues(): void {
    this.paymentService.getPayments().subscribe((payments: Payment[]) => {
      payments.forEach(item => {
        if (item.date === undefined || item.date === null) {
          console.error('No tiene fecha: ', item);
          if (item.liquidation !== null && item.liquidation !== undefined) {
            console.log('SI tiene liquidacion');
            if (item.liquidation.date === null || item.liquidation.date === undefined) {
              console.log('Liquidacion NO TIENE FECHA');
              item.date = item.liquidation.createdAt;
            } else {
              item.date = item.liquidation.date;
            }
          } else {
            item.date = item.createdAt;
            console.log('NO tiene liquidacion');
          }
          /* this.paymentService.editPayment(item._id, item).subscribe(res => {
            console.log('Abono edittado: ', item);
          }, err => console.error('Error al editar abono: ', err)); */
        }
        if (item.payment === undefined || item.payment === null) {
          console.error('No tiene valor abono: ', item);
          if (item.liquidation === null || item.liquidation === undefined) {
            console.log('NO tiene liquidacion: ');
            if (item.advancePayment.liquidation !== null && item.advancePayment.liquidation !== undefined) {
              console.log('El anticipo si tiene liquidacion');
              this.settlementService.getSettlement(item.advancePayment.liquidation).subscribe((res: Settlement) => {
                if (res.advancePayments.length === 1) {
                  console.log('Solo tiene un abono la liquidacion');
                  item.payment = res.total_deductions;
                  /* this.paymentService.editPayment(item._id, item).subscribe(res_ => {
                    console.log('Abono edittado: ', res_);
                  }, err => console.error('Error al editar abono: ', err)); */
                } else {
                  console.log('TIENE MAS DE un abono la liquidacion: ', res);
                  /* item.advancePayment.payment_status = 'Pagado';
                  item.advancePayment.total_balance = 0;
                  item.advancePayment.interest = 0;
                  item.advancePayment.current_capital = 0;
                  item.advancePayment.total_interest = 0;
                  this.advancePaymentService.editAdvancePayment(item.advancePayment._id, item.advancePayment).subscribe(r => {

                  }, err => console.error('Error al editar anticipo: ', err)); */
                }
              });
            }
          } else {
            console.log('SI tiene liquidacion');
          }
        }

      });
    }, err => console.error('Error al obtener abonos: ', err));
  }

  usersRules(): void {
    this.userService.getAllUsers().subscribe(res => {
      const userRules = [];
      res.forEach(item => {
        if (item.rules === null) {
          console.log('No tiene rules: ', item);
        } else {
          Object.keys(item.rules).forEach(key => {
            const rule = item.rules[key];
            if (!rule) {
              userRules.push(item);
            }
          });
        }

      });
      console.log('Users con rules: ', userRules);
    });
  }

  weightNotesValues(params: any): void {
    this.inventoryService.getAllInventoriesFilters(params).subscribe((res: Inventory[]) => {
      console.log('notas de peso obteneida: ', res);
      res.forEach(wn => {
        let liquidated = false;
        if (wn.total_net_sett === wn.total_net_qq && wn.total_net_qq !== 0 && wn.liquidated === true) {
          console.error('condicion 1 total_net_sett && total_net_qq !== 0 && liquidated == true: ', wn);
          liquidated = true;
        }

        if (wn.total_net_sett !== wn.total_net_qq && wn.liquidation === null) {
          console.error('condicion 2 total_net_sett !== total_net_qq && liquidation == null: ', wn);
          liquidated = true;
        }

        if (wn.total_net_sett > wn.total_net_qq) {
          console.error('condicion 3 total_net_sett > total_net_qq', wn);
        }

        if (wn.total_net_sett === 0 && wn.total_net_qq !== 0 && wn.entry !== 'Propio') {
          console.error('condicion 4 total_net_sett === 0 pero no es propio', wn);
        }

        if (wn.total_net_qq === wn.total_net_sett && wn.entry !== 'Depósito') {
          console.error('condicion 5 total_net_sett === total_net_qq pero no es Depósito', wn);
        }

        if (wn.total_net_qq === wn.total_net_sett &&
          (wn.liquidated === true || (wn.liquidation !== null && wn.liquidation !== undefined))) {
          console.error('condicion6 total_net_sett === total_net_qq pero esta liquidado', wn);
          liquidated = true;
        }

        if (wn.total_net_qq !== wn.total_net_sett && wn.total_net_qq !== 0 && wn.total_net_sett !== 0 && wn.entry === 'Propio') {
          console.error('condicion7 PARCIAL', wn);
          liquidated = false;
        }

        if (liquidated) {
          wn.entry = 'Propio';
          wn.total_net_sett = 0;
          wn.liquidated = true;
          this.inventoryService.editInventory(wn._id, wn).subscribe(resp => {
            console.log('Nota de peso actualizada: ', resp);
          }, err => console.error('Error al editar nota de peso: ', err));
        }
      });
    });
  }
}
