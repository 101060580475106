import { Component, OnInit, Inject } from '@angular/core';

import { UserService } from '../../../services/user.service';
import { MachineService } from '../../../services/machine.service';
import { Machine } from '../../../models/machine';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-machine-edit',
  templateUrl: './machine-edit.component.html',
  styleUrls: ['./machine-edit.component.css'],
  providers: [UserService, MachineService]
})
export class MachineEditComponent implements OnInit {

  public titulo = 'Editar Máquina';
  public machine: Machine;
  public alertMessage = '';
  disabled = false;

  constructor(
    private _machineService: MachineService,
    public dialogRef: MatDialogRef<MachineEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.machine = this.data;
  }

  submitForm() {
    this._machineService.editMachine(this.machine._id, this.machine).subscribe(response => {
      if (!response) {
        this.alertMessage = 'Error en el servidor';
      } else {
        this.alertMessage = 'Certificación actualizada correctamente';
        this.dialogRef.close();
      }
    }, error => console.error('Error al editar maquina: ', error)
    );
  }
}
