import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material';
import * as _moment from 'moment';
import * as _ from 'lodash';

import { CertificationService } from '../../../../services/certification.service';
import { CustomerService } from '../../../../services/customer.service';
import { ExporterService } from '../../../../services/exporter.service';
import { BrokerService } from '../../../../services/broker.service';
import { DriedService } from '../../../../services/dried.service';
import { SaleService } from '../../../../services/sale.service';
import { GLOBAL } from '../../../../services/global';

import { Certification } from '../../../../models/certification';
import { Exporter } from '../../../../models/exporter';
import { Customer } from '../../../../models/customer';
import { Broker } from '../../../../models/broker';
import { Dried } from '../../../../models/dried';
import { Sale } from '../../../../models/sale';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sale-add',
  templateUrl: './sale-add.component.html',
  styleUrls: ['./sale-add.component.css'],
})

export class SaleAddComponent implements OnInit {
  public alertMessage;
  public qqUpdate = [];
  public title: string;
  public payments_status = [
    { value: 'Depósito', viewValue: 'Depósito' },
    { value: 'Parcial', viewValue: 'Parcial' },
    { value: 'Pagado', viewValue: 'Pagado' }
  ];

  public filteredCertifications: Certification[];
  public filteredExporters: Exporter[];
  public filteredCurtomers: Customer[];
  public filteredBrokers: Broker[];
  public certificationsData: Certification[];
  public exportersData: Exporter[];
  public customersData: Customer[];
  public brokersData: Broker[];

  public displayedColumns: string[] = ['cod', 'warehouse', 'inventory_qq', 'r_qq', 'moisture_loss'];
  public highlightedRows = [];
  public statusQQ = false;
  public total_qq: number;
  public form: FormGroup;
  public maxQQ = 0;
  public diccToSearch = {};
  public restqq = [];
  public returnFromParcial = null;
  public totalSelected = 0;
  public tareQq = 0;
  public currency;
  public dataSource = new TableVirtualScrollDataSource<Dried>();
  public selected: any = {};
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }

  constructor(
    private _certificationService: CertificationService,
    private _exporterService: ExporterService,
    private _customerService: CustomerService,
    private _brokerService: BrokerService,
    private _driedService: DriedService,
    private _saleService: SaleService,
    private fb: FormBuilder,
    private _route: Router
    // public dialogRef: MatDialogRef<SaleAddComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = 'Agregar Venta';
    this.createForm();
  }

  ngOnInit() {
    this.currency = GLOBAL.currency;
    this.getDataFromServices();
    this.listenersFormChanges();
  }

  createForm(): void {
    // * name : [valor-defecto, [validador sincrono...], [validador asincronos...]]
    this.form = this.fb.group({
      price:             ['', Validators.required],
      date:              ['', Validators.required],
      brokerName:        [''],
      exporterName:      [''],
      customerName:      [''],
      certificationName: [''],
      exporter:          [null],
      broker:            [null],
      total_qq_ps:       [null],
      total_qq_net:      [null],
      customer:          ['', Validators.required],
      contract:          [''],
      status:            ['Depósito', Validators.required],
      sub_total:         ['', Validators.required],
      prime_stamps:      [ 0, Validators.required],
      total:             ['', Validators.required],
      observations:      [''],
      // warehouse:         [''],
      position:          [''],
      certifications:     ['', Validators.required],
      differential:      [0,  Validators.required],
      total_tare:        [0]
    });
  }

  getDataFromServices() {
    this._driedService.getDriedsToSale().subscribe(res => {
      this.dataSource.data = res as Dried[];
      // this.dataSource.data = this.dataSource.data.filter(value => value.inventory_qq !== 0);
      console.log('Datos de Drieds: ', res);
      }, err => console.error('Error al obtener inventario seco: ', err)
    );

    this._certificationService.getCertifications().subscribe(resp => {
      this.certificationsData = resp;
      this.filteredCertifications = resp;
    });

    this._customerService.getCustomers().subscribe(resp => {
        this.customersData = resp;
        this.filteredCurtomers = resp;
     });

    this._brokerService.getBrokers().subscribe( resp => {
       this.brokersData = resp;
       this.filteredBrokers = resp;
     });

    this._exporterService.getExporters().subscribe(resp => {
      this.exportersData = resp;
      this.filteredExporters = resp;
    });

   }

  listenersFormChanges() {
    this.form.get('customerName').valueChanges.subscribe(name => {
      this.filteredCurtomers = this.customersData
      .filter((customer: Customer) => this.existString(customer.name, name));
    });

    this.form.get('certificationName').valueChanges.subscribe(name => {
      this.filteredCertifications = this.certificationsData
      .filter((certification: Certification) => this.existString(certification.name, name));
    });

    this.form.get('brokerName').valueChanges.subscribe(name => {
      this.filteredBrokers = this.brokersData
      .filter((broker: Broker) => this.existString(broker.name, name));
    });

    this.form.get('exporterName').valueChanges.subscribe(name => {
      this.filteredExporters = this.exportersData
      .filter((exporter: Exporter) => this.existString(exporter.name, name));
    });

    this.form.get('total_qq_ps').valueChanges.subscribe(value => {
      if (value !== null || value !== '') {
        const total_qq_ps = this.totalSelected;
        if (value > total_qq_ps) {
          this.form.controls['total_qq_ps'].setErrors({'incorrect': true});
          this.statusQQ = true;
        } else {
          this.statusQQ = false;
          this.form.controls['total_qq_ps'].clearValidators();
          this.form.controls['total_qq_ps'].setValidators(Validators.required);
        }
      }
    });

    this.form.get('price').valueChanges.subscribe(value => {
      if (value !== null || value !== '') {
        this.calculateQQ();
      }
    });

    this.form.get('total_tare').valueChanges.subscribe(value => {
      if (value !== null || value !== '') {
        this.calculateQQ();
      }
    });

    this.form.get('differential').valueChanges.subscribe(value => {
      const total = this.form.get('sub_total').value + value;
      this.form.get('total').setValue(total);
    });

  }

  selectRow(row: Dried) {
    if (!this.selected.hasOwnProperty(row._id)) {
      this.selected[row._id] = Object.assign(_.cloneDeep(row), {
        toSale: row.inventoryStatus.sale.ref.length === 0 ? row.dry_qq : row.inventoryStatus.sale.remain
      });
    } else {
      delete this.selected[row._id];
    }
    console.log('selected', Object.values(this.selected));
    this.totalSelected = _.sumBy(Object.values(this.selected), 'toSale');
    this.calculateQQ();
  }

  calculateQQ() {
    this.tareQq = Number(this.f.total_tare) * (0.00453592 / 2);
    const total_qq_net = this.getNum(this.totalSelected, this.tareQq, '-');
    const sub_total = total_qq_net * this.f.price;
    const total = sub_total + this.f.differential;
    this.changeFormValue('total_qq_ps', this.totalSelected);
    this.changeFormValue('total_qq_net', total_qq_net);
    this.changeFormValue('sub_total', sub_total);
    this.changeFormValue('total', total);
  }

  partialQQ() {
    if (this.form.get('total_qq_ps').valid) {
      const totalInput = this.form.get('total_qq_ps').value;
      const totalSelected = _.sumBy(this.highlightedRows, 'original_qq');
      let diff = this.getNum(totalSelected, totalInput, '-');
      console.log('diff: ', diff);
      this.form.get('total_qq_net').setValue(this.getNum(totalInput, this.tareQq, '-'));
      if (diff !== 0) {
        for (let i = this.highlightedRows.length - 1; i >= 0; i--) {
          const current: any = this.highlightedRows[i];
          const lastTotal = current['original_qq'];
          if (diff < this.getNum(lastTotal, 0)) {
            console.log('NO Excede al ultimo');
            current.inventory_qq = this.getNum(lastTotal, diff, '-');
            this.returnFromParcial = _.cloneDeep(current);
            this.returnFromParcial.inventory_qq = diff;
            break;
          } else {
            diff = this.getNum(diff, current.original_qq, '-');
            this.highlightedRows = this.highlightedRows.filter(item => item._id !== current._id);
            this.dataSource.data.forEach(currentInventory => {
              if (currentInventory._id === current._id) {
                currentInventory['highlighted'] = false;
              }
            });
            this.returnFromParcial = diff === 0 ? null : this.returnFromParcial;
            console.log('Excede al ultimo: ', current, diff);
            if (diff !== 0) { continue; } else { break; }
          }
        }
      } else {
        this.returnFromParcial = null;
        console.warn('No calcula nada, valores iguales');
      }
      console.log('highlighted: ', this.highlightedRows);
      console.log('returnToParcial: ', this.returnFromParcial);
      this.calculateQQ();
    }
  }

  existString(text1: string, text2: string): boolean {
    const status = text1
    .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .indexOf(text2
      .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1;
    return status;
  }

  changeFormValue(name: string, value: any): void {
    this.form.get(name).setValue(value);
  }

  getNum(num1: any, num2: any, op = '+'): number {
    console.log(`${num1} ${op} ${num2}`);
    return parseFloat(Number(op === '+' ? num1 + num2 : num1 - num2).toFixed(6));
  }

  isLast(row): Boolean {
    if (this.highlightedRows.length > 0) {
      if (this.highlightedRows[this.highlightedRows.length - 1]._id === row._id) {
        return true;
      }
    }
    return false;
  }

  /*
    * inventory_qq -> Es el valor que falta por vender.
    * dry_qq -> Es el valor secado.
  */

  submitForm() {
    const sale: Sale = this.form.getRawValue();
    sale.status = this.returnFromParcial !== null ? 'Parcial' : 'Pagado';
    // sale.warehouse = null;
    sale.total_qq_sale = sale.total_qq_ps;
    sale.inventories = Object.keys(this.selected);
    sale.taked_qq = Object.values(this.selected).map((curr: any) => curr.toSale);
    sale.rest_qq = Object.values(this.selected).map(item => 0);

    console.log('Valor de venta: ', sale);
    this.registerSale(sale);
  }

  registerSale(sale: Sale): void {
    this._saleService.addSale(sale).subscribe(response => {
      this.update_wn(sale);
      this._route.navigateByUrl('administracion/ventas');
      console.log('Venta registrada...: ', response);
    }, error => console.error('Error al agregar la venta: ', error));
  }

  update_wn(sale: Sale): void {
    Object.values(this.selected).forEach((dried: any) => {
      const status = dried.inventoryStatus.sale;
      if (status.ref.length === 0) { // * Primera venta
        status.total = dried.dry_qq;
        status.remain = dried.dry_qq - dried.toSale;
      } else {
        status.remain = dried.remain - dried.toSale;
      }
      status.ref.push(sale._id);
      status.finished = status.remain === 0 ? true : false;
      status.partial = status.total !== status.remain && status.remain !== 0 ? true : false;
      this._driedService.editDried(dried._id, dried).subscribe( response => {
        console.log('Orden de secado actualizada: ', response);
      }, error => console.error('Error al editar secada: ', error));
    });
  }

  get f () {
    return this.form.value;
  }
}
