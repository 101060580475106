import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { CenterCost } from '../models/center-cost';

@Injectable()
export class CenterCostService {
  public url: string;

  constructor( private http: HttpClient ) {
    this.url = GLOBAL.url;
  }


  getCentersOfCosts(): Observable<any> { return this.http.get(`${this.url}/centers-costs/list`); }

  addCenterCost(centerCost: CenterCost): Observable<any> {
    return this.http.post(`${this.url}/centers-costs/new`, centerCost);
  }
  getCenterCost(id): Observable<any> {return this.http.get(`${this.url}/centers-costs/${id}`); }

  editCenterCost(id, centerCost): Observable<any> {
    return this.http.put(`${this.url}/centers-costs/${id}`, centerCost);
  }
  deleteCenterCost(id): Observable<any> {
    return this.http.delete(`${this.url}/centers-costs/${id}`);
  }
}
