import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(text: string): any {
    const splitedText = text.trim().split(' ');
    // console.log('textSplited: ', splitedText);
    return splitedText.map(str => {
      if (str !== '') {
        return str[0].toUpperCase() + str.substr(1).toLowerCase();
      }
    });
  }

}
