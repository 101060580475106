import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { SensoryService } from '../../../../services/sensory.service';
import { PrintService } from '../../../../services/print.service';

import { SensoryAnalysis } from '../../../../models/sensory';

@Component({
  selector: 'app-sensory-list',
  templateUrl: './sensory-list.component.html',
  styleUrls: ['./sensory-list.component.css']
})
export class SensoryListComponent implements OnInit {
  public sensories: SensoryAnalysis[] = [];

  constructor(
    private _sensoryService: SensoryService,
    private _printService: PrintService,
  ) { }

  ngOnInit() {
    this.getSensories();
  }

  getSensories = (): void => {
    this._sensoryService.getSensories().subscribe((res: SensoryAnalysis[]) => {
      this.sensories = res;
    }, (err: HttpErrorResponse) => console.error('Error al obtener analisis sensoriales'));
  }

  printSensory = (): void => {

  }

}
