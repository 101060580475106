import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AvatarModule } from 'ngx-avatar';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { MaterialModule } from '../material-module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxMaskModule } from 'ngx-mask';
import { SidebarViewComponent } from './sidebar-view/sidebar-view.component';
import { MatSelectSearchModule } from '../pages/mat-select-search/mat-select-search.module';

@NgModule({
  declarations: [
    // BreadcrumbComponent,
    SidebarViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    NgxMaskModule,
    NgbModule,
    AvatarModule,
    TableVirtualScrollModule,
    InfiniteScrollModule,
    NgbDropdownModule,
    NgApexchartsModule,

    SidebarViewComponent,
    MatSelectSearchModule,
    TableVirtualScrollModule,
    InfiniteScrollModule,
    TextMaskModule,

  ],
  schemas: [ NO_ERRORS_SCHEMA ],
})
export class SharedModule { }
