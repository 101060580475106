import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppSidebar } from '../../../../models/sidebar';
import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { Laboratory } from '../../../../models/laboratory';
import { LaboratoryService } from '../../../../services/laboratory.service';

@Component({
  selector: 'app-laboratory-list',
  templateUrl: './laboratory-list.component.html',
  styleUrls: ['./laboratory-list.component.css']
})
export class LaboratoryListComponent implements OnInit {
  public labs: Laboratory[] = [];
  public sidebars: AppSidebar[] = [
    { name: 'laboratory-edit', title: 'Editar laboratorio', layout: {
      width: 25, height: 'auto'
    }},
  ];
  constructor(
    public _sidebarManager: SidebarManagerService,
    private _labService: LaboratoryService
  ) {}

  ngOnInit() {
    this._sidebarManager.setSidebarsList(this.sidebars);
    this.getLabs();
  }

  getLabs = (): void => {
    this._labService.getLaboratories().subscribe((res) => {
      this.labs = res;
    }, (err: HttpErrorResponse) => console.error('Error al obtener laboratorios', err));
  }

  editLab = (lab: Laboratory): void => {
    this._sidebarManager.open('laboratory-edit', lab);
    this._sidebarManager.afterClosed('laboratory-edit').subscribe((res) => {
      if (res) this.getLabs();
    });
  }

}
