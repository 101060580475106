import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort } from '@angular/material';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import * as _ from 'lodash';

import { LotService } from '../../../../services/lot.service';
import { DriedService } from '../../../../services/dried.service';
import { Lot } from '../../../../models/lot';
import { DeleteModalComponent } from '../../../delete-modal/delete-modal.component';
import { LotAddComponent } from './../lot-add/lot-add.component';
import { LotEditComponent } from './../lot-edit/lot-edit.component';
import { ShippingAddComponent } from '../../../production/shipping-add/shipping-add.component';

@Component({
  selector: 'app-lot-list',
  templateUrl: './lot-list.component.html',
  styleUrls: ['./lot-list.component.css'],
  providers: []
})
export class LotListComponent implements OnInit {

  public titulo: string;
  public lots: Lot[];
  public lot = [];
  public alertMessage = '';
  public hasContract = false;
  closeResult: string;

  // tslint:disable-next-line: max-line-length
  displayedColumns: string[] = ['code', 'lot', 'stamp', 'departure', 'bags', 'quintals', 'quality', 'mark', 'status', 'action'];
  highlightedRows = [];
  public dataSource = new TableVirtualScrollDataSource<Lot>();
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }
  constructor(
    private _lotService: LotService,
    private _driedService: DriedService,
    public dialog: MatDialog
  ) {
    this.titulo = 'Lotes';
  }

  ngOnInit() {
    this.getLots();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog() {
    const dialogRef = this.dialog.open(LotAddComponent, {
      width: '25rem',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getLots();
    });
  }

  openDialogEdit(lot: any) {
    const dialogRef = this.dialog.open(LotEditComponent, { width: '25rem', data: lot });
    dialogRef.afterClosed().subscribe(result => {
      this.getLots();
    });
  }

  openDialogD(lot) {
    console.log('A eliminar: ', lot);
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      height: '650px',
      data: {
        header: 'Eliminar lote',
        title: '',
        text: `¿Seguro que quieres eliminar el lote <strong>#${lot.lot}</strong> ?`,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        lot.dry_notes.forEach(dry => {
          dry.production = false;
          this._driedService.editDried(dry._id, dry).subscribe(response => {
            console.log('Nota actualizada con exito...');
          }, error => console.error('Fallo al actualizar la nota!!'));
        });
        this.delete(lot._id);
      }
    });
  }

  openDialogAddShipping(lot: Lot): void {
    this.alertMessage = '';
    if (!lot.shipping) {
      if (lot.hasOwnProperty('contract')) {
        if (String(lot.contract) !== 'null' || lot.contract !== '') {
          const dialogRef = this.dialog.open(ShippingAddComponent,
            {
              width: '25rem',
              data: {
                lotId: lot._id,
              }
            });
            dialogRef.afterClosed().subscribe((resp) => {
              console.log('Lote enviado: ', resp);
              if (resp) this.getLots();
          });
        } else {
          this.alertMessage = `Lote ${lot.code} no tiene un contrato`;
        }
      } else {
        this.alertMessage = `Lote ${lot.code} no tiene un contrato`;
      }
    } else {
      this.alertMessage = `Lote ${lot.code} ya ha sido enviado`;
    }
  }

  getLots() {
    this.alertMessage = '';
    this._lotService.getLotes().subscribe((res: Lot[]) => {
      console.log('loyes', res);
        this.dataSource.data = res;
        this.dataSource.connect().next(res);
        if (this.dataSource.data.length > 0) {
          document.getElementById('matTableLot').style.display = 'block';
        } else {
          document.getElementById('matTableLot').style.display = 'none';
        }
        console.log('Lotes: ', this.dataSource.data);
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  delete(id) {
    this.alertMessage = 'Registo eliminado';
    this._lotService.deleteLot(id).subscribe(
      response => {
        if (!response) {
          alert('Error en el servidor');
        } else {
          this.getLots();
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

}
