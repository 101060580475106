import { Dried } from '../../../models/dried';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';

import { DriedService } from '../../../services/dried.service';
import { InventoryService } from '../../../services/inventory.service';
import { UserService } from '../../../services/user.service';
import { SaleService } from '../../../services/sale.service';

import { DriedsFilterComponent } from './drieds-filter/drieds-filter.component';
import { Inventory } from '../../../models/inventory';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';

@Component({
  selector: 'app-drieds',
  templateUrl: './drieds.component.html',
  styleUrls: ['./drieds.component.css'],
  providers: [DriedService, InventoryService, SaleService, UserService]
})

export class DriedsComponent implements OnInit {
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  public drieds = new BehaviorSubject([]);
  public inventory: any = {};
  public identi;
  public alertMessage = '';
  public page = 0;
  public limit = 50;

  public finished = false;  // boolean when end of database is reached
  public loading = false;
  public selectedDried: any = {};
  public dataFilter = {};
  public currentDried: BehaviorSubject<Dried> = new BehaviorSubject<Dried>(null);

  constructor(
    private _router: Router,
    private _driedService: DriedService,
    public dialog: MatDialog,
    private _userService: UserService,
    private _inventoryService: InventoryService
    ) {
    this.inventory = {} as Inventory;
    this.identi = this._userService.getIdentity();
  }

  ngOnInit() {
    const harvest = this._userService.getCompany().harvest;
    this.dataFilter = { harvest: harvest };
    this.getDriedsWithFilters();
  }

  getDriedsWithFilters(): void {
    this.dataFilter['skip'] = this.page * this.limit;
    this.dataFilter['limit'] = this.limit;
    this._driedService.getDriedsWithFilter(this.dataFilter).subscribe((response: Dried[]) => {
      this.drieds.next(_.concat(this.drieds.getValue(), response));
      return ;
      const data: any = [];
      let rows = '';
      let c = 1;
      response.forEach((curr: Dried, inde) => {
        curr.weight_notes.forEach((wn: Inventory, index: number) => {
          if (curr.driedqq[index] !== wn.total_net_qq) {
            const diff = Math.abs(curr.driedqq[index] - wn.total_net_qq);
            const inf = {
              dried: curr.cod,
              wn: wn.cod,
              totalqq: wn.total_net_qq,
              diff,
              totaldried: curr.driedqq[index],
              total_qq_dry: wn.total_qq_dry
            };
            rows += `
              <tr>
                  <td>${c}</td>
                  <td>${inf.dried}</td>
                  <td>${inf.wn}</td>
                  <td>${inf.totalqq}</td>
                  <td>${inf.totaldried}</td>
                  <td>${inf.diff}</td>
                  <td>${inf.total_qq_dry}</td>
                </tr>
            `;
            c += 1;
            data.push(inf);
          }
        });
      });
      const div = document.getElementById('drieddata');
      const grouped = _.groupBy(data, 'dried');
      console.log('rows', rows);
      const table = `
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Orden</th>
              <th scope="col">Nota</th>
              <th scope="col">Total qq</th>
              <th scope="col">Secado</th>
              <th scope="col">Diferencia</th>
              <th scope="col">Secado(Nota)</th>
            </tr>
          </thead>
          <tbody>
            ${rows}
          </tbody>
        </table>
      `;
      div.innerHTML += table;
      console.log('table', table);
    }, err => console.error('Error al obtener ordenes con filtros: ', err));
  }

  openDialogFilter(): void {
    const dialogRef = this.dialog.open(DriedsFilterComponent, {
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
      width: '280px',
      height: '450px',
      data: this.dataFilter
    });
    dialogRef.afterClosed().subscribe(callback => {
      if (callback['apply']) {
        this.page = 0;
        this.dataFilter = callback.data;
        console.log('Data para filtrar: ', this.dataFilter);
        this.drieds = new BehaviorSubject([]);
        this.getDriedsWithFilters();
      }
    });
  }

  openDialogD(dried) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar orden de secado',
        title: '',
        text: `¿Seguro que quieres eliminar la orden <strong>#${dried.cod}</strong>?`,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this._driedService.checkConstraint(dried._id).subscribe(res => {
          console.log('constraint status: ', res);
          let remove = true;
          Object.keys(res).forEach(key => {
            if (res[key]) { remove = false; }
          });
          console.log('remove: ', remove);
          if (remove) {
            this.removeDried(dried);
          } else {
            let message = 'La secada esta siendo utilizada en: ';
            message = res.sale ? message + ' Ventas, ' : message;
            message = res.production ? message + ' Produccion, ' : message;
            message = res.fd3 ? message + ' FD3, ' : message;
            message = res.ft ? message + ' FT, ' : message;
            message = res.utz ? message + 'UTZ, ' : message;
            this.alertMessage = message;
            console.log('message: ', this.alertMessage);
            setTimeout(() => this.alertMessage = '', 5000);
          }
        }, err => console.error('Error al verificar orden de secado: ', err));
        // this.delete(dried._id, dried);
      }
    });
  }

  // --------------------------------------------------------------------------------------------

  public onScroll() {
    this.page += 1;
    this.getDriedsWithFilters();
  }

  public changeDetailDried(dried: Dried): void {
    this.currentDried.next(dried);
  }

  private removeDried(dried: Dried): void {
    dried.weight_notes.forEach((wn, index) => {
      setTimeout(() => {
        const total_qq = dried.driedqq[index] + wn.total_qq_dry;
        wn['partialData'] = wn.hasOwnProperty('partialData') ? wn.partialData : { dry: false, settlement: false };
        wn.dried = false;
        if (total_qq >= wn.total_net_qq) {
          wn.partialData.dry = false;
          wn.total_qq_dry = wn.total_net_qq;
        } else {
          wn.partialData.dry = total_qq !== 0 ? true : false;
          wn.total_qq_dry = total_qq;
        }
        this._inventoryService.editInventory(wn._id, wn).subscribe(res => {
          if (index === (dried.weight_notes.length - 1)) {
            this._driedService.deleteDried(dried._id).subscribe( response_ => {
              this.alertMessage = 'Orden de secado eliminada';
              setTimeout(() => this.alertMessage = '', 5000);
              this.drieds = new BehaviorSubject([]);
              this.page = 0;
              this.getDriedsWithFilters();
              // this._router.navigate(['/ordenes-secado']);
            }, (error: HttpErrorResponse) => console.error('Error al actualizar secada', error));
          }
        });
      }, 100);
    });
  }

  clearFilters(): void {
    this.dataFilter = {};
    this.drieds = new BehaviorSubject([]);
    this.page = 0;
    this.getDriedsWithFilters();
  }

  createDried = (): void => {
    this._router.navigateByUrl('ordenes-secado/crear-orden');
  }

}
