import { CashCountEditComponent } from './../cash-count-edit/cash-count-edit.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CashCountsService } from '../../../../services/cash-counts.service';
import { UserService } from '../../../../services/user.service';
import { CashCountAddComponent } from '../cash-count-add/cash-count-add.component';
import { CashCountClosingComponent } from '../cash-count-closing/cash-count-closing.component';

import { CashCount } from '../../../../models/cash-count';
import { ExcelService } from '../../../../services/excel.service';
import { User } from '../../../../models/user';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';

@Component({
  selector: 'app-cash-count-list',
  templateUrl: './cash-count-list.component.html',
  styleUrls: ['./cash-count-list.component.css']
})
export class CashCountListComponent implements OnInit {
  public cashCounts: CashCount[] = [];
  public currentUser: User;
  public alertMessage = '';
  public pipe = new DatePipe('es-HN');
  public convertToExcel = [];
  constructor(
    private _cashCountService: CashCountsService,
    private _authService: UserService,
    private excelService: ExcelService,
    public dialog: MatDialog,
  ) {
    this.currentUser = this._authService.identity;
  }

  ngOnInit(): void {
    this.getCashCounts();
  }

  getCashCounts(): void {
    this._cashCountService.getCashCounts().subscribe(res => {
      this.cashCounts = res;
      console.log(this.cashCounts);
    }, err => console.error('Error al obtener arqueos de caja', err));
  }

  openCancelCount(count: CashCount): void {
    count.enabled = false;
    this._cashCountService.updateCashCount(count._id, count).subscribe(res => {
      this.alertMessage = 'Arqueo de caja anulado!';
    }, err => console.error('Error al anular apertura de caja', err));
  }

  openAddOpening(): void {
/*     this._cashCountService.userAssigned(this.currentUser._id).subscribe(res => {

      if (!res) { */
        const dialogRef = this.dialog.open(CashCountAddComponent , {
          width: '15rem',
          panelClass: ['animate__animated', 'animate__zoomIn'],
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            // this._authService.updateLocalUser(this.currentUser);
            this.getCashCounts();
          }
        });
/*       } else {
        console.log('denegado');
      }
    }, err => console.error('Error al obtener estado de usuario sobre apertura', err)); */
  }

  openClosing(count: CashCount): void {
    const dialogRef = this.dialog.open(CashCountClosingComponent , {
      width: '60rem',
      panelClass: ['animate__animated', 'animate__zoomIn'],
      disableClose: true,
      data: count
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCashCounts();
      }
    });
  }

  reOpenClosing = (count: CashCount): void => {
    this._cashCountService.updateCashCount(count._id, { closed: false }).subscribe((res) => {
      this.getCashCounts();
    }, (err: HttpErrorResponse) => console.error('Error al reabrir registro', err));
  }

    exportAsXLSX(): void {
    this._cashCountService.getCashCounts().subscribe(res => {
      this.convertToExcel = res;
      this.convertToExcel.forEach((element, i) => {
        const newDate = this.pipe.transform(element.createdAt, 'shortDate');
        if (element === undefined) {
          console.error('Undefined element: ', element, i);
        }
          const collectionCenter = element.hasOwnProperty('collectionCenter') ? ( element.collectionCenter !== null ? element.collectionCenter.name : '') : '';
          const user = element.hasOwnProperty('user') ? ( element.user !== null ? element.user.name : '') : '';
          const description = element.hasOwnProperty('description') ? ( element.description !== null ? element.description : '') : '';
          const wet_quintals = element.hasOwnProperty('wet_quintals') ? ( element.wet_quintals !== null ? element.wet_quintals : 0) : 0;
          const opening = element.hasOwnProperty('opening') ? ( element.opening !== null ? element.opening : 0) : 0;
          const subOpening = element.hasOwnProperty('subOpening') ? ( element.subOpening !== null ? element.subOpening : 0) : 0;
          const closing = element.hasOwnProperty('closing') ? ( element.closing !== null ? element.closing : 0) : 0;

          const rows = {
            Código: element.code,
            Fecha: newDate,
            Agencia: collectionCenter,
            Usuario: user,
            Apertura: subOpening,
            AperturaTotal: opening,
            Cierre: closing,
            Estado: element.closed,
            observaciones: description,
          };

          const convertToExcel = Object.assign([], this.convertToExcel, { [i]: rows });
          this.convertToExcel = convertToExcel;

      });
      this.excelService.exportAsExcelFile(this.convertToExcel, 'Inventario');
    }, err => console.error('Error al obtener inventario seco con filtros: ', err));
  }

}
