import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';



import { SeatService } from '../../../services/seat.service';
import { Seat } from '../../../models/seat';
import { AngularTreeGridComponent } from 'angular-tree-grid';
import { CategoryService } from '../../../services/category.service';
import { Category } from '../../../models/category';

import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { MatSelect, MatTableDataSource } from '@angular/material';
import { take, takeUntil } from 'rxjs/operators';
import { CodeComponent } from './code/code.component';
import { getCurrency } from '../../../helpers/number';

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.css'],
  providers: [SeatService, CategoryService]
})
export class LedgerComponent implements OnInit, OnDestroy {
  @ViewChild('angularGrid') angularGrid: AngularTreeGridComponent;
  public seating: Seat[];
  public seat = [];
  public Seat: any = {};
  data: any[] = [
  ];

  public anulados = false;
  /**-------------select------------------*/
  public detailsCategories: Category[] = [];

  public bankCtrl: FormControl = new FormControl();

  public bankFilterCtrl: FormControl = new FormControl();

  public filteredBanks: ReplaySubject<Category[]> = new ReplaySubject<Category[]>(1);

  @ViewChild('singleSelect') singleSelect: MatSelect;

  private _onDestroy = new Subject<void>();
/*---------------------------------------------*/

  f1 = new Date();
  f2 = new Date();

  seatForm: FormGroup;
  totalDebit = 0;
  totalCredit = 0;
  myFormValueChanges$;

  dataSource = new MatTableDataSource<Seat>();
  dataFija = new MatTableDataSource<Seat>();
  filterData = [];
  todos = new MatTableDataSource<Seat>();
  anuladosData = [];
  errorfiltro = false;
  clearfiltro = false;

  configs: any = {
    id_field: 'code',
    parent_id_field: 'parent',
    parent_display_field: 'code',
    data_loading_text: 'Cargando Cuentas ...',
    filter: false,
    css: {
      expand_class: 'icon-arrow-right',
      collapse_class: 'icon-arrow-down',
      add_class: 'icon-plus',
      edit_class: 'icon-pencil',
      delete_class: 'icon-trash',
      save_class: 'icon-check',
      cancel_class: 'icon-close',
    },
    columns: [
      {
        name: 'code',
        header: 'Código',
        width: '10%',
        type: 'custom',
        component: CodeComponent
      },
      {
        name: 'name',
        header: 'Nombre',
        width: '60%',
        editable: true,
        renderer: function (value) {
          return value;
        }
      },
      {
        name: 'total_debit',
        header: 'Debito',
        width: '10%',
        editable: true,
        renderer: function (value) {
          return value;
        }
      },
      {
        name: 'total_credit',
        header: 'Crédito',
        width: '10%',
        editable: true,
        renderer: function (value) {
          return value;
        }
      },
      {
        name: 'total_balance',
        header: 'Saldo',
        width: '10%',
        editable: true,
        renderer: function (value) {
          return value;
        }
      }
    ],
    row_class_function: function (rec) {
      return 'row-ledger';
    }
  };
  public currency = getCurrency();
  constructor(
    private _seatService: SeatService,
    private _categoryService: CategoryService,
    private formBuilder: FormBuilder,
  ) {
    this.Seat = {} as Seat;
    this.bankCtrl.setValue('');
  }

  ngOnInit() {
    this.createSeatForm();
    this.getDataFromServices();
  }

  clearFiltro() {
    this.data = this.dataFija.data;
    this.errorfiltro = false;
    this.clearfiltro = false;
  }


  getDataFromServices(): void {
    this._seatService.getLedger().subscribe(
      (res) => {
        this.data = res as Seat[];
        console.log('Data libro mayor: ', res);
        this.todos.data = this.data;
      },
      error => console.error('Error al obtener data libro mayor: ', error)
    );

    this._categoryService.getCategories().subscribe(response => {
      this.detailsCategories = response as Category[];
      this.detailsCategories = this.detailsCategories.filter(category => category.type !== 'Grupo').sort((n1, n2) => n1.code - n2.code);
    }, err => console.error('Error al obtener las cuentas: ', err));

  }

  expandAll() {
    this.angularGrid.expandAll();
  }

  searchSeats() {
    this.errorfiltro = false;
    this.f1 = this.seatForm.controls['date_1'].value;
    this.f2 = this.seatForm.controls['date_2'].value;
    this._seatService.getLedgerWithFilter(this.f1, this.f2, '').subscribe(res => {
        this.data = res as Seat[];
        console.log('Data recibida de la busqueda: ', res);
        this.todos.data = this.data;
      }, error => console.error('Error al en la busqueda'));

  }



  getTotalDebit() {
    return this.data.map(t => t.total_debit).reduce((acc, value) => acc + value, 0);
  }
  getTotalCredit() {
    return this.data.map(t => t.total_credit).reduce((acc, value) => acc + value, 0);
  }
  getTotalBalance() {
    return this.data.map(t => t.total_balance).reduce((acc, value) => acc + value, 0);
  }

  createSeatForm(): void {
    this.seatForm = this.formBuilder.group({
      date_2:       ['', Validators.required],
      date_1:       ['', Validators.required],
      date_voucher: ['', Validators.required]
    });
  }

  ngOnDestroy(): void {
    // this.myFormValueChanges$.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
