import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { SaleVouchersModule } from '../sale-vouchers/sale-vouchers.module';
import { IhcafeVoucherAddComponent } from './ihcafe-voucher-add/ihcafe-voucher-add.component';
import { IhcafeVoucherEditComponent } from './ihcafe-voucher-edit/ihcafe-voucher-edit.component';
import { IhcafeVoucherComponent } from './ihcafe-voucher/ihcafe-voucher.component';

@NgModule({
  declarations: [
    IhcafeVoucherComponent,
    IhcafeVoucherAddComponent,
    IhcafeVoucherEditComponent
  ],
  imports: [
    SharedModule,
    SaleVouchersModule,
  ],
  entryComponents: [
    IhcafeVoucherAddComponent,
    IhcafeVoucherEditComponent
  ]
})
export class IhcafeVouchesModule { }
