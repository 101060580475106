import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { DescriptorAddComponent } from './descriptor-add/descriptor-add.component';
import { DescriptorEditComponent } from './descriptor-edit/descriptor-edit.component';
import { DescriptorListComponent } from './descriptor-list/descriptor-list.component';

@NgModule({
  declarations: [
    DescriptorAddComponent,
    DescriptorEditComponent,
    DescriptorListComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    DescriptorAddComponent
  ]
})
export class DescriptorsModule { }
