import {Customer} from './../../../../models/customer';
import {CustomerService} from './../../../../services/customer.service';
import {UserService} from './../../../../services/user.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductionService} from './../../../../services/production.service';
import {Production} from './../../../../models/production';
import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-inv-production-list',
  templateUrl: './inv-production-list.component.html',
  styleUrls: ['./inv-production-list.component.css']
})
export class InvProductionListComponent implements OnInit {
  form: FormGroup;
  filters = false;
  orders: Production[] = [];
  lenData = 0;
  page = 0;
  limit = 10;
  harvests = ['Todas', '2018 / 2019', '2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024'];
  currentHarvest = "";
  customersData: Customer[];
  qqComplete = 0;
  qqPending = 0;
  searchMicrolot = false;

  collectionSize = 0;
  pageSize = 10;
  currentPage = 1;
  maxSize = 2;
  firstLastButtons = true;
  nextPreviousButtons = true;
  small = false;
  totalPages: any[] = [];

  constructor(
    private _productionService: ProductionService,
    private fb: FormBuilder,
    private _userService: UserService,
    private _customerService: CustomerService
  ) { }

  ngOnInit() {
    this.createForm();
    this.getInvProductions();
    this.getCustomers();
  }

  getInvProductions(){
    this.currentHarvest = this._userService.getCompany().harvest;
    this.form.get('harvest').setValue(this.currentHarvest);
    this.form.get('status').setValue('pendiente');
    
    setTimeout(() => {
      this._productionService.getInvProductions({}).subscribe((res) => {
        console.log(res);
        res.map( (ress: Production) => {
          if(ress.dry_inventory.length > 0){
            let drieds = "";
            ress.dry_inventory.forEach(element => {
              if(drieds == ""){
                drieds = element.cod;
              }else{
                drieds = `${drieds}, ${element.cod}`;
              }
            });
            ress.list_dry_inv = drieds;
          }

          ress.complete ? (this.qqComplete = parseFloat((this.qqComplete + ress.quintals).toFixed(2))) :
          (this.qqPending = parseFloat((this.qqPending + ress.quintals).toFixed(2)));
        });
        const modifiedData = this.calcularSumatoriaGlobal(res);
        this.loadPagination(modifiedData.length);
        this.orders = modifiedData;
      }, (error: HttpErrorResponse) => console.error('Error al obtener producciones', error));
    })
  }
  
  private loadPagination(ordersSize:number){
    this.collectionSize = ordersSize;
    this.totalPages = new Array(Math.ceil(ordersSize / this.pageSize))
  }

  private sumExportb(values) {
    return values.reduce((acc, value) => acc + (value.exportb || 0), 0);
  }

  private calcularSumatoriaGlobal(data) {
    return data.map((item) => {
      let totalqq = 0;

      if (item.lots && Array.isArray(item.lots) && item.lots.length > 0) {
        totalqq = item.lots.reduce((acc, lot) => {
          if (lot.values && Array.isArray(lot.values) && lot.values.length > 0) {
            return acc + this.sumExportb(lot.values);
          }
          return acc;
        }, 0);
      }

      return { ...item, totalqq };
    });
  }

  getCustomers(){
    this._customerService.getCustomers().subscribe(res => {
      this.customersData = res;
    })
  }

  changePage(paginator: MatPaginator): void {
    console.log('ChangePage');
    
    // this.page = paginator.pageIndex;
    // const tableRef = window.document.getElementById('matTableAdvancePayment');
    // tableRef !== null ? tableRef.scrollTo(0, 0) : console.log('tableRefNull', tableRef);
    // this.getAdvancePaymentsWithFilters();
  }

  getFilterData(): void {
    console.log('Filter data');
    const filters = JSON.parse(JSON.stringify(this.form.getRawValue()));
    if(this.searchMicrolot){
      filters.microlot = true;
    }
    this.qqComplete = 0;
    this.qqPending = 0;

    setTimeout(() => {
      this._productionService.getInvProductions(filters).subscribe((res) => {
        res.map( (ress: Production) => {
          if(ress.dry_inventory.length > 0){
            let drieds = "";
            ress.dry_inventory.forEach(element => {
              if(drieds == ""){
                drieds = element.cod;
              }else{
                drieds = `${drieds}, ${element.cod}`;
              }
            });
            ress.list_dry_inv = drieds;
          }

          ress.complete ? (this.qqComplete = parseFloat((this.qqComplete + ress.quintals).toFixed(2))) :
          (this.qqPending = parseFloat((this.qqPending + ress.quintals).toFixed(2)));
        });

        this.orders = res;
        
      }, (error: HttpErrorResponse) => console.error('Error al obtener producciones', error));
    })

  }

  clearFilter(){
    this.qqComplete = 0;
    this.qqPending = 0;

    setTimeout(() => {
      this._productionService.getInvProductions({}).subscribe((res) => {
        res.map( (ress: Production) => {
          if(ress.dry_inventory.length > 0){
            let drieds = "";
            ress.dry_inventory.forEach(element => {
              if(drieds == ""){
                drieds = element.cod;
              }else{
                drieds = `${drieds}, ${element.cod}`;
              }
            });
            ress.list_dry_inv = drieds;
          }
          
          ress.complete ? (this.qqComplete = parseFloat((this.qqComplete + ress.quintals).toFixed(2))) :
          (this.qqPending = parseFloat((this.qqPending + ress.quintals).toFixed(2)));
        });

        this.orders = res;
      }, (error: HttpErrorResponse) => console.error('Error al obtener producciones', error));
    });
  }

  clearField(event: any, field?: string): void {
    event.stopPropagation();
    this.form.get(field).setValue('');
  }

  selectMicrolot(completed: boolean) {
    this.searchMicrolot = completed;
  }

  /** Set page number */
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
  }

  /** Set next page number */
  next() {
    const nextPage = this.currentPage + 1;
    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }

  /** Set previous page number */
  previous() {
    const previousPage = this.currentPage - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  createForm(): void {
    this.form = this.fb.group({
      harvest:            [''],
      customer:           [''],
      microlot:           [''],
      status:             [''],
      date_in:            [''],
      date_out:           ['']
    });
  }
}
