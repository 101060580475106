import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { LocationService } from '../../../../services/location.service';
import { BlendsService } from '../../../../services/blends.service';
import { SampleService } from '../../../../services/sample.service';
import { DriedService } from '../../../../services/dried.service';
import { LotService } from '../../../../services/lot.service';

import { Location } from '../../../../models/location';
import { Sample } from '../../../../models/sample';
import { Dried } from '../../../../models/dried';
import { Blend } from '../../../../models/blend';

@Component({
  selector: 'app-sample-edit',
  templateUrl: './sample-edit.component.html',
  styleUrls: ['./sample-edit.component.css'],
  styles: [`
    :host {
      height: 100%;
      overflow-y: auto;
    }`
  ],
})
export class SampleEditComponent implements OnInit {

  public form: FormGroup;
  public sampleTypes: string[] = ['Pre-compra', 'Oferta', 'Evaluación', 'Inventario seco', 'Mezcla'];
  public coffeeTypes: string[] = ['Oro', 'Pergamino seco'];
  public varieties: string[] = ['Catuaí', 'Caturra', 'Bourbón', 'ihcafe 90', 'Parainema',
  'Typica', 'Lempira', 'Pacas', 'Obata', 'Colombiano', 'Geisha', 'Icatu', 'Magarogipe', 'Blend','Java', 'Anacafe 14',  'Paca Nanca', 'Blend', 'Borbon Rosado', 'Pacamara',
'Aji Natural', 'Bourbon Sidra', 'Wush Wush', 'Chiroso', 'Laurina', 'Sudan Rume', 'Sidra', 'Bernardina', 'Moka', 'Castilla', 'Purpurasea', 'Heirloom'  ];
  public selectedVarieties: string[] = [];
  public locations: Location[] = [];
  public alert = false;
  public drieds: Dried[] = [];
  public blends: Blend[] = [];
  public searchForm: FormGroup;
  public sample: Sample;
  constructor(
    private _sampleService: SampleService,
    private _locationService: LocationService,
    private _driedService: DriedService,
    private _blendService: BlendsService,
    private _lotService: LotService,
    private _sidebarManager: SidebarManagerService,
    private _fb: FormBuilder
  ) { }

  ngOnInit() {
    this.sample = this._sidebarManager.getInput('sample-edit');
    console.log('sample to edit', this.sample);
    this.createForm(this.sample);
    this.getLocations();
    this.listeners();
  }

  getLocations(): void {
    this._locationService.getLocations().subscribe( res => {
      this.locations = res;
    }, (err: HttpErrorResponse) => console.error('Error al obtener ubicaciones', err));
  }

  listeners = (): void => {
    this.searchForm.get('driedCode').valueChanges.subscribe((value) => {
      if (value) this.searchForm.get('wnCode').setValue('');
    });
    this.searchForm.get('wnCode').valueChanges.subscribe((value) => {
      if (value) this.searchForm.get('driedCode').setValue('');
    });
    this.searchForm.get('blendCode').valueChanges.subscribe((value) => {
      if (value) this.searchForm.get('driedCode').setValue('');
    });
    this.form.get('type').valueChanges.subscribe((type: string) => {
      if (type === 'Inventario seco') this.form.controls['dryingOrder'].setErrors({'incorrect': true});
      else this.form.controls['dryingOrder'].clearValidators();
      console.log('form', this.form);
    });
  }

  searchData = (): void => {
    const form = this.searchForm.getRawValue();
    if (this.f.type.value === 'Inventario seco') {
      this._driedService.searchSampleDrieds(form).subscribe((res: Dried[]) => {
        console.log('drieds', res);
        this.drieds = res;
        this.form.patchValue({
          dryingOrder: res.length > 0 ? res[0] : null
        });
        if (res.length === 0) this.form.controls['dryingOrder'].setErrors({ 'incorrect': true });
        else this.form.controls['dryingOrder'].clearValidators();
      }, (err: HttpErrorResponse) => console.error('Error al obtener ordenes de secado', err));
    } else if (this.f.type.value === 'Mezcla') {
      this._blendService.searchSampleBlend(form).subscribe((res: Blend[]) => {
        console.log('blends', res);
        this.blends = res;
        this.form.patchValue({
          blend: res.length > 0 ? res[0] : null
        });
        if (res.length === 0) this.form.controls['blend'].setErrors({ 'incorrect': true });
        else this.form.controls['blend'].clearValidators();
      }, (err: HttpErrorResponse) => console.error('Error al obtener ordenes de secado', err));
    }
  }

  editSample(): void {
    const sample: Sample = { ..._.cloneDeep(this.sample), ...this.form.getRawValue() };
    Object.keys(sample).forEach(key => ((sample[key] === '' || sample[key] === null) ? delete sample[key] : ''));
    if (sample.type === 'Inventario seco' && !sample.dryingOrder) {
      this.form.controls['driedCode'].setErrors({'incorrect': true});
    } else if (sample.type === 'Lote' && !sample.lot) {
      this.form.controls['lotCode'].setErrors({'incorrect': true});
    } else {
      if (sample.type !== 'Inventario seco') sample.dryingOrder = null;
      if (sample.type !== 'Mezcla') sample.blend = null;
      this._sampleService.updateSample(sample._id, sample).subscribe(res => {
        this.checkData(sample);
        this._sidebarManager. close('sample-edit', true);
      }, (err: HttpErrorResponse) => console.error('Error al editar muestra', err));
    }
  }

  // todo Considerar la parte de lotes.
  checkData = (sample: Sample): void => {
    console.log('check data', sample, this.sample);
    if (sample.dryingOrder) {
      if (this.sample.dryingOrder) { // * Si la muestra editando tenia una orden de secado.
        // * Si no se ha cambiado de orden de secado.
        if (sample.dryingOrder._id === this.sample.dryingOrder._id) return;
        // * La muestra editando a cambiado de orden de secado.
        if (this.sample.dryingOrder) {
          this.updateSamplesList(this.sample.dryingOrder, sample._id, 'remove');
          console.log('Orden de secado diferente');
        }
      }
      // * Agrega la secada nueva.
      setTimeout(() => {
        this.updateSamplesList(sample.dryingOrder, sample._id);
      }, 500);
      console.log('Orden diferente agregada');
    } else if (sample.blend) {
      if (this.sample.blend) {
        if (sample.blend._id === this.sample.blend._id) return;
        if (this.sample.blend) {
          console.log('Mezcla diferente');
          this.updateBlendRef(this.sample.blend, sample._id, 'remove');
        }
      }
      setTimeout(() => {
        this.updateBlendRef(sample.blend, sample._id);
      }, 500);
      console.log('Orden diferente agregada');
    } else {
      // * La secada fue removida y fue cambiado el tipo.
      if (this.sample.dryingOrder) {
        this.updateSamplesList(this.sample.dryingOrder, sample._id, 'remove');
      } else if (this.sample.blend) {
        this.updateBlendRef(this.sample.blend, sample._id, 'remove');
      }
    }
  }

  updateSamplesList = (dried: Dried, sampleId: string, action: 'add' | 'remove' = 'add'): void => {
    const samples: string[] = dried.inventoryStatus.samples ? dried.inventoryStatus.samples : [];
    dried.inventoryStatus = Object.assign(dried.inventoryStatus, {
      samples: samples.length > 0
        ? action === 'add' ? samples.concat(sampleId) : samples.filter((curr: string) => curr !== sampleId)
        : action === 'add' ? [sampleId] : []
    });
    dried.inventoryStatus.samples = dried.inventoryStatus.samples.length > 0 ? dried.inventoryStatus.samples : null;
    this._driedService.editDried(dried._id, { inventoryStatus: dried.inventoryStatus }).subscribe((res2) =>
      console.log('Orden actualizada', dried),
      (err: HttpErrorResponse) => console.error('Error al actualizar inventario seco', err));
  }

  updateBlendRef = (blend: Blend, sampleId: string, action: 'add' | 'remove' = 'add'): void => {
    const samples: string[] = blend.samples ? blend.samples : [];
    blend.samples = samples.length > 0
        ? action === 'add' ? samples.concat(sampleId) : samples.filter((curr: string) => curr !== sampleId)
        : action === 'add' ? [sampleId] : [];
    blend.samples = blend.samples.length > 0 ? blend.samples : null;
    this._blendService.updateBlend(blend._id, blend).subscribe((res2) =>
      console.log('Mezcla actualizada', blend, action),
      (err: HttpErrorResponse) => console.error('Error al actualizar inventario seco', err));
  }

  createForm(dataForm: any | Sample = {}): void {
    this.form = this._fb.group({
      name:         [''],
      coffeeType:   [''],
      owner:    this._fb.group({
        name:       [''],
        telephone:  [''],
        quintals:   [''],
        farm:       ['']
      }),
      lot:         [''],
      dryingOrder: [''],
      blend:       [''],
      weight:      [''],
      date:        [new Date(), Validators.required],
      location:    [''],
      type:        [''],
      varieties:   [''],
      water_activity: [''],
      offer: [''],
    });
      this.form.patchValue({
        ...dataForm
      });
    if (dataForm.location) {
      this.form.patchValue({
        location: dataForm.location._id
      });
    }

    this.searchForm = this._fb.group({
      driedCode:  [this.sample.dryingOrder ? this.sample.dryingOrder.cod : ''],
      wnCode:     [''],
      blendCode:  [this.sample.blend ? this.sample.blend.code : ''],
    });
    if (this.sample.dryingOrder || this.sample.blend) this.searchData();
  }

  get f() {
    return this.form.controls;
  }

}
