import { OriginService } from './origin.service';
import { ProductorService } from './productor.service';
import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { CategoryService } from './category.service';
import * as _ from 'lodash';
import { InventoryService } from './inventory.service';
@Injectable({
  providedIn: 'root'
})
export class ImportDataService {
  private indexed: any = {};
  private principalCategoriesCode = {
    1 : 1, 11: 2, 111: 3, 112: 4, 113: 5, 114: 6, 115: 7, 12: 8, 121: 9, 122: 10, 123: 11, 124: 12, 2: 13,
    21: 14, 211: 15, 3: 16, 31: 17, 32: 18, 33: 19, 331: 20, 332: 21, 333: 22, 4: 23, 41: 24, 42: 25, 5: 26,
    51: 27, 52: 28, 6: 29, 61: 30, 62: 31, 63: 32, 631: 33, 632: 34, 633: 35,
  };

  constructor(
    private categoryService: CategoryService,
    private coffeefarmerService: ProductorService,
    private originService: OriginService,
    private weightNotesService: InventoryService,
  ) {
    /* this.coffeefarmerService.getProductores().subscribe(res => {
      this.indexed = _.groupBy(res, 'name');
      console.log('indexed: ', this.indexed);
      console.log('res: ', res);
    }); */
  }

  importToCategories(selectedFile, dataCode): any {
    console.log('File: ', selectedFile);
    const tempIDArr = dataCode;
    console.log('\n\n\n\n--------------------------CONVIRTIENDO EXCEL A CATEGORY-TREE -------------------------');
    if (selectedFile) {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (event: Event) => {
          const data = fileReader.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const json = {};
          const myData = XLSX.utils.sheet_to_json(workbook.Sheets.A);
          console.log('MyData: ', myData);
          console.log('TOtal items: ', Object.keys(myData).length);
          const repetidos = [];
          const checked = [];
          Object.keys(myData).forEach(key => {
              const curr = myData[key];
              curr['children'] = [];
              if (!checked.some(item => item === curr.code)) {
                  checked.push(curr.code);
              } else {
                  console.error('repetido :', curr.code);
                  repetidos.push(curr);
                  repetidos.push(json[curr.code]);

              }
              json[curr.code] = curr;
          });
          console.log('myDataLen: ', myData.length);
          console.log('jsonLen: ', Object.keys(json).length);
          const tipos = ['Detalle', 'Grupo'];
          const especialesType = [];
          const descripciones = ['Activo', 'Pasivos', 'Capital', 'Gastos', 'Ingresos', 'Costos', 'Costos y Gastos'];
          const especialesDescription = [];
          console.log('0');
          // console.log('json: ', json);
          for (let i = 1; i < 7; i++) {
            const cuurr = json[i];
            if ( cuurr !== undefined) {
              cuurr['parent'] = null;
              cuurr['parent_pathx'] = i.toString();
              cuurr['pathx'] = i.toString();
            } else {
              console.log('undefined principal: ', i);
            }
          }
          console.log('1');
          Object.keys(json).forEach(key => {
            const current = json[key];
            // console.log('current: ', current);
            current.code = Number(current.code);
            const code = String(current.code);
            // current.description = current.description.toLowerCase();
            current.description = current.description.charAt(0).toUpperCase() + current.description.slice(1);
                // ? Validacion de descripcion.
            if (current.description.includes('Activos'))    { current.description = 'Activo'; }
            if (current.description.includes('Pasivo'))     { current.description = 'Pasivos'; }
            if (current.description.includes('Capitales'))  { current.description = 'Capital'; }
            if (current.description.includes('Gasto')) {
              current.description = 'Gastos';
              if (String(current.code)[0] === '5') { current.description = 'Costos'; }
            }
            if (current.description.includes('Ingreso'))    { current.description = 'Ingresos'; }
            if (current.description.includes('Costo')) { current.description = 'Costos'; }
            if (!descripciones.some(item => item === current.description)) { especialesDescription.push(current); }
            // ? Validacion de tipos.
            if (current.type.toLowerCase().includes('titulo')) { current.type = 'Grupo'; }
            if (current.type.toLowerCase().includes('detalle')) { current.type = 'Detalle'; }
            if (!tipos.some(item => item === current.type)) { especialesType.push(current); }
            for (let i = 0; i < code.length; i++) {
              const sub = code.substring(0, i);
              if (sub !== code) {
                // * Si existe una cuenta con este nombre
                if (json.hasOwnProperty(sub)) {
                  const parent = json[sub];
                  if (parent.levelx === current.levelx - 1) {
                    current.parent = parent.code;
                    if (!parent.hasOwnProperty('pathx')) { parent.pathx = String(parent.code); }
                    current.pathx = String(parent.pathx + '.' + current.code);
                    current.parent = parent.code;
                    current.parent_pathx = parent.pathx;
                    current.code = Number(current.code);
                    parent.children.push(current);
                    break;
                  }
                }
                if (current.type === 'TITULO') {
                  current.type = 'Grupo';
                }
              }
            }
          });
          console.log('2: jsonLen: ', Object.keys(json).length);
          Object.keys(this.principalCategoriesCode).forEach(key => {
            if (!json.hasOwnProperty(key)) {
              console.error('La cuenta principal no ha sido agregada: ', key);
            } else {
              const curr = json[key];
              // console.log('curr: ', curr, key);
              curr['number'] = this.principalCategoriesCode[key];
            }
          });
          const especialChildrens = [];
          Object.keys(json).forEach(key => {
            const c = json[key];
            if (c.children.length > 0 && c.type === 'Detalle') {
              especialChildrens.push(c);
            }
          });

          Object.keys(json).forEach(key => {
            const current = json[key];
            const parent = json[json[key].parent];
            if (parent !== null && parent !== undefined) {
              if (parent.levelx !== current.levelx - 1) {
                console.error('Error en nivel: ', current);
              }
            } else {
              if (!(Number(key) > 0 && Number(key) <= 6)) {
                console.error('Parent undefined: ', current);
              }
            }
          });

          console.error('CODIGOS REPETIDOS: ', repetidos);
          console.error('CUENTA DETALLE CON HIJOS! :', especialChildrens);
          console.error('Cuentas con mala descripcion: ', especialesDescription);
          console.error('Cuentas con mal tipado: ', especialesType);
          console.log('indexedNUEVO: ', json);
          console.log('lenIndexed: ', Object.keys(json).length);
          const arrayOfObjects = [];
        for (let i = 1; i < 7; i++) {
          if (json[i] !== undefined) {
            json[i].parent_pathx = `${i}`;
            json[i].parent = null;
            arrayOfObjects.push(json[i]);
          }
        }
        console.log('Array of  json DATA: ', arrayOfObjects);
        const myDataFinal = arrayOfObjects;
        console.log('To Send: ', json);
        // return arrayOfObjects;
        // return myData;
        /* Object.keys(json).forEach((key, index) => {
          const currentCategory = json[key];
          if (!tempIDArr.some(id => id === currentCategory.code)) {
            setTimeout(() => {
              this.categoryService.addCategoryToCompany(currentCategory).subscribe(response => {
                console.log('Cuenta agregada: ', response, index);
              }, err => console.error('Error al agregar manualmente: ', err));
            }, 2000);
          } else {
            console.error('Cuenta ya agregada: ', currentCategory);
          }
        }); */
      };
    } else {
      console.error('Error en el archivo');
    }
  }

  importCoffeefarmers(selectedFile: any): void {
    console.log('indexed: ', this.indexed);
    if (selectedFile) {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (event: Event) => {
          const data = fileReader.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          console.log('workBook: ', workbook);
          const coffeefarmersData = XLSX.utils.sheet_to_json(workbook.Sheets['Farmers']);
          coffeefarmersData.forEach((cf: any) => {
            // const current = this.indexed[cf.name];
            // console.log('current: ', current);
            // if (current !== undefined) {
              // if (current.length > 1) { console.error('Tiene mas de 1: ', current); }
              /* cf = Object.assign(cf, this.indexed[cf.name][0]);
              cf['status'] = 'Afiliado';
              this.coffeefarmerService.editProductor(cf._id, cf).subscribe(res => {
                console.log('cf editado: ', res);
              }, err => console.error('Error al actualizar prodctor: ', err)); */
            // } else {
            //   console.error('No se encontro: ', current);
            // }
            // cf['cod_cert'] = cf['cod certificacion'];
            // delete cf['cod certificacion'];
            this.coffeefarmerService.addProductor(cf).subscribe(res => {
              console.log('Productor agregado');
            }, err => console.error('Error al agregar productor: ', err));
          });
          console.log('CooffeefarmerData: ', coffeefarmersData);
      };
    }
  }

  importOrigins(selectedFile: any): void {
    if (selectedFile) {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (event: Event) => {
          const data = fileReader.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          console.log('workBook: ', workbook);
          const originsData = XLSX.utils.sheet_to_json(workbook.Sheets['Hoja 1']);
          originsData.forEach((origin: any) => {
            delete origin['cosecha'];
            this.originService.addOrigin(origin).subscribe(res => console.log('Procedencia agregada'), err =>
            console.error('Error al agregar procedencia: ', err));
          });
          console.log('originData: ', originsData);
        };
      }
    }

  importWeightNotesToUpdate(selectedFile: any, data: any): void {
    const grouped = _.groupBy(data.currentList, 'cod');
    // console.log('grouped', grouped);
    console.log('data', data);
    if (selectedFile) {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (event: Event) => {
          const result = fileReader.result;
          const workbook = XLSX.read(result, { type: 'binary' });
          const originsData = XLSX.utils.sheet_to_json(workbook.Sheets['Hoja1']).reverse();
          for (let i = data.start; i < data.end; i++) {
            const current = originsData[i];
            if (grouped[current['N°']] === undefined) {
              console.error('code undefined', current);
              continue ;
            }
            const item: any = grouped[current['N°']][0];
            if (current['Fecha'] === undefined || current['Fecha'] === null) {
              console.error('Date error', current);
              continue ;
            }
            const date = current['Fecha'].split('-');
            const temp = {
              _id: item._id,
              date: `${date[2]}-${date[1]}-${date[0]}` + 'T06:00:00.000Z'
            };
            if (temp.date === undefined || temp.date === null || temp.date === '') console.error('date error', item);
            if (temp._id === undefined || temp._id === null || temp._id === '') console.error('_id error', item);
            // console.log('item', temp);
            /* setTimeout(() => {
              this.weightNotesService.editInventory(temp._id, temp).subscribe(res => {
                console.log('actualizado');
              }, err => console.error('Error al actualizar', err, temp));
            }, 2000); */
          }
      };
    }
  }

  importWarehouseToUpdate(selectedFile: any, data: any): void {
    const grouped = _.groupBy(data.currentList, 'cod');
    // console.log('grouped', grouped);
    console.log('data', data);
    if (selectedFile) {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (event: Event) => {
          const result = fileReader.result;
          const workbook = XLSX.read(result, { type: 'binary' });
          const originsData = XLSX.utils.sheet_to_json(workbook.Sheets['CAFESMO']).reverse();
          for (let i = data.start; i < data.end; i++) {
            const current: any = originsData[i];
            const item: any = grouped[current.cod][0];
            if (item === undefined || item === null) {
              console.warn('No esta el codigo');
              continue ;
            }
            const temp = {
              _id: item._id,
              warehouse: current.warehouse
            };
            if (temp._id === undefined || temp._id === null || temp._id === '') console.error('_id error', item);
            if (temp.warehouse === undefined || temp.warehouse === null || temp.warehouse === '') console.error('warehouse error', item);
            // console.log('item', temp);
            setTimeout(() => {
              this.weightNotesService.editInventory(temp._id, temp).subscribe(res => {
                console.log('actualizado');
              }, err => console.error('Error al actualizar', err, temp));
            }, 1000);
          }
      };
    }
  }
}
