import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';

import { DriedService } from '../../../../../services/dried.service';
import { UserService } from '../../../../../services/user.service';
import { CollectionCenterService } from '../../../../../services/collectionCenter.service';
import { GLOBAL } from '../../../../../services/global';

import { Inventory } from '../../../../../models/inventory';

@Component({
  selector: 'app-step-re-one',
  templateUrl: './step-re-one.component.html',
  styleUrls: ['./step-re-one.component.css']
})
export class StepOneReComponent implements OnInit, AfterViewInit, OnDestroy {

  public weightNotes: Inventory[] = [];
  public indexSelected: any = {};
  public loading = false;
  public form: FormGroup;

  public page = 0;
  public lenData = 0;
  public limit = 100;
  public total_qq_ps = 0;
  public r_qq = 0;
  public totalTare = 0;
  public warehouse;
  public bags = 0;
  public referral: any = {};
  public currency;
  public identity;
  public collectionCenter;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private _driedService: DriedService,
    private _collectionCenterService: CollectionCenterService,
    private _userService: UserService,
    private fb: FormBuilder,
    public dialog: MatDialog,
  ) {
    const data = localStorage.getItem('referral');
    const index = localStorage.getItem('indexSelectedDrieds');
    this.identity = this._userService.getIdentity();
    this.referral = data !== null ? JSON.parse(data) : {};
    console.log(this.identity);
    this.indexSelected = index !== null ? JSON.parse(index) : {};
    this.total_qq_ps = this.sumBy(this.indexSelected, 'net_qq');
    this.bags = this.sumBy(this.indexSelected, 'bags');
    this.r_qq = this.sumBy(this.indexSelected, 'r_qq');
  }

  ngOnInit() {
    this.createForm();
    this.getDrieds();
    this._userService.getUser(this.identity._id).subscribe(resp => {
      console.log(resp.collectionCenter);
      if (this.identity.collectionCenter) {
        this._collectionCenterService.getCollectionCenter(resp.collectionCenter).subscribe( res => {
          console.log(res);
          this.collectionCenter = res;
          if (res.price) {
            this.referral.price = res.price;
          }
        });
      }
    });
    this.currency = GLOBAL.currency;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.form.patchValue(this.referral);
    }, 100);
  }

  ngOnDestroy(): void {
    this.saveData();
  }

  createForm(): void {
    this.form = this.fb.group({
      price:     [0],
      bags:      [0],
      label:     [""],
      driver:     [""],
      licensePlate:     [""],
      dredgePlate:     [""],
      truckPlate:     [""],
      shippingAddress: [""]
    });
  }

  getDrieds(): void {
    this.loading = true;
    this._driedService.getDriedsToReferral().subscribe(res => {
      this.loading = false;
      if (res !== null) {
        console.log(res);
        this.weightNotes = res;
      }
      }, err => console.error('Error al obtener inventario seco: ', err)
    );
  }

  highlight(dry: any): void {
    console.log('highlight: ', dry);
    this.warehouse = dry.warehouse.address;
    this.form.get('shippingAddress').setValue(this.warehouse);
    dry['net_qq'] = dry.hasOwnProperty('net_qq') ? dry.net_qq : dry.dry_qq;
    if (this.indexSelected.hasOwnProperty(dry._id)) {
      delete this.indexSelected[dry._id];
    } else {
      this.indexSelected[dry._id] = {
        net_qq: dry.net_qq,
        bags: dry.bags,
        r_qq: dry.r_qq,
        inventoryStatus: dry.inventoryStatus,
        toSale: dry.inventoryStatus.referral.ref.length === 0 ? dry.dry_qq : dry.inventoryStatus.referral.remain
      };
    }
    console.log(this.indexSelected);
    this.getSum();
  }

  changePage(paginator: MatPaginator) {
    console.log('Event: ', paginator);
    this.limit = paginator.pageSize;
    this.page = paginator.pageIndex;
  }

  saveData() {
    this.getSum();
    const index: any = this.indexSelected;
    const form = this.form.getRawValue();
    let temp = 0;
    Object.keys(index).forEach(key => {
      const current: any = index[key];
        if (current.toSale > current.dry_qq) {
          current.toSale = current.dry_qq;
        }
        current.net_qq = current.toSale >= current.net_qq ? current.net_qq : current.toSale;
        temp += current.net_qq;
    });
    // this.total_qq_ps = temp;
    this.totalTare = this.bags * 0.00226796;
    console.log(this.bags, this.totalTare);
    const stepOneData = Object.assign({
      inventories: Object.keys(index),
      taked_qq: this.mapBy(index, 'net_qq'),
      rest_qq: Object.keys(index).map(key =>
        parseFloat(Number(Number(index[key].toSale) - Number(index[key].net_qq)).toFixed(6))),
      total_qq_ps: this.total_qq_ps,
      bags: this.bags,
      label: form.label,
      r_qq: this.r_qq,
      driver: form.driver,
      licensePlate: form.licensePlate,
      total_tare: this.totalTare,
      total_qq_net: this.total_qq_ps - this.totalTare,
      sub_total: parseFloat(Number(this.total_qq_ps * form.price).toFixed(2)),
      price: form.price,
      total: parseFloat(Number((this.total_qq_ps - this.totalTare) * form.price).toFixed(2)),
      total_net: this.getTotalNet(this.referral, this.total_qq_ps * form.price),
    }, form);
    stepOneData.bags = this.bags;
    stepOneData.r_qq = this.r_qq.toFixed(2);
    stepOneData.trend = this.r_qq.toFixed(2);
    this.referral = Object.assign(this.referral, stepOneData);
    localStorage.setItem('referral', JSON.stringify(this.referral));
    localStorage.setItem('indexSelectedDrieds', JSON.stringify(index));
    console.log('step1 data: ', this.referral);
  }

  getSum(): void {
    this.total_qq_ps = this.sumBy(this.indexSelected, 'net_qq');
    this.bags = this.sumBy(this.indexSelected, 'bags');
    this.r_qq = this.sumBy(this.indexSelected, 'r_qq');
  }

  sumBy(object: any, field: string): number {
    return Object.keys(object).reduce((acc, key) => acc + object[key][field] , 0);
  }

  mapBy (object: any, field: string): Object {
    return Object.keys(object).map(key => object[key][field]);
  }

  getTotalNet(settlement: any, total): number {
    let total_net = total;
    const numbers = _.pick(settlement, ['r_qq', 'bags', 'wet_quintals', 'dry_qq',
      'inventory_qq', 'moisture_loss', 'dry_qq', 'total_qq_referral', 'total_sale']);
    Object.keys(numbers).forEach(key => {
      numbers[key] = isNaN(numbers[key]) ? 0 : numbers[key];
      total_net -= numbers[key];
    });
    return parseFloat(Number(total_net).toFixed(2));
  }

}
