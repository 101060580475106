import { Component, OnInit } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';

import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { BlendsService } from '../../../../services/blends.service';
import { PrintService } from '../../../../services/print.service';

import { PaginatorConfig } from '../../../../models/miscellaneous';
import { AppSidebar } from '../../../../models/sidebar';
import { Blend } from '../../../../models/blend';
import { Dried } from '../../../../models/dried';
import { DeleteModalComponent } from '../../../../pages/delete-modal/delete-modal.component';


@Component({
  selector: 'app-blend-list',
  templateUrl: './blend-list.component.html',
  styleUrls: ['./blend-list.component.css']
})
export class BlendListComponent implements OnInit {
  public blends: Blend[] = [];
  public paginatorConfig: PaginatorConfig = {
    count: 0,
    page: 0,
    limit: 100,
    label: 'Inventario'
  };
  public sidebars: AppSidebar[] = [
    { name: 'blend-add',        title: 'Agregar mezcla', layout: {
      width: 25, height: 'full'
    }},
    { name: 'sample-add', title: 'Agregar muestra', layout: {
      width: 25, height: 'auto'
    }},
  ];
  constructor(
    public _sidebarManager: SidebarManagerService,
    private _blendsService: BlendsService,
    private _printService: PrintService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this._sidebarManager.setSidebarsList(this.sidebars);
    this.getBlends();
  }

  getBlends = () => {
    this._blendsService.getBlends().subscribe((blends: Blend[]) => {
      this.blends = blends;
      console.log('blends', this.blends);
    }, (err: HttpErrorResponse) => console.error('Error al obtener mezclas', err));
  }

  disableBlend(blend: Blend): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Anular mezcla',
        title: '',
        text: `¿Seguro que quieres anular la mezcla <strong>#${blend.code}</strong>?`,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this._blendsService.updateBlend(blend._id, {
          enabled: false,
        }).subscribe(() => this.getBlends()
        , error => console.error('Error al anular muestra: ', error));
      }
    });
  }

  print = () => {

  }

  addBlend = () => {
    this._sidebarManager.open('blend-add');
    this._sidebarManager.afterClosed('blend-add').subscribe((res) => {
      if (res) this.getBlends();
    });
  }

  addSample = (blend: Blend): void => {
    this._sidebarManager.open('sample-add', {
      blend
    });
    this._sidebarManager.afterClosed('sample-add').subscribe((results) => {
      if (results) this.getBlends();
    });
  }

  changePage(paginator: MatPaginator) {
    this.paginatorConfig.page = paginator.pageIndex;
    // window.document.getElementById('matTableSettlement').scrollTo(0, 0);
    this.getBlends();
  }

}
