import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { UserService } from '../../../services/user.service';
import { DriedService } from '../../../services/dried.service';

@Component({
  selector: 'app-edit-date-dried',
  templateUrl: './edit-date-dried.component.html',
  styleUrls: ['./edit-date-dried.component.css'],
  providers: [UserService, DriedService]
})
export class EditDateDriedComponent implements OnInit {

  public title = 'Cambio de fecha';
  public form: FormGroup;

  constructor(
    private driedService: DriedService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditDateDriedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('toChangeDate: ', this.data);
  }

  ngOnInit() {
    this.createForm();
    this.form.patchValue(this.data);
  }

  createForm(): void {
    this.form = this.fb.group({
      date: [''],
      date_end: ['']
    });
  }

  submitForm() {
    this.dialogRef.close();
    const dried = Object.assign(this.data, this.form.getRawValue());
    console.log(dried);
    const days = Math.floor(
      Math.abs(
        (new Date(dried.date).getTime() - new Date(dried.date_end).getTime()) / (1000 * 60 * 60 * 24)
      )
    );
    dried.days = days + 1;
    this.driedService.editDried(dried._id, dried).subscribe(response => {
      console.log('Fechas cambiadas: ', response);
    }, err => console.error('Error al cambiar fecha de la orden de secado: ', err));
  }

}
