import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Sample } from '../models/sample';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class SampleService {

  public url = GLOBAL.url + '/samples';
  constructor(
    private http: HttpClient
  ) { }

  addSample = (sample: Sample): Observable<any> => this.http.post(`${this.url}/new`, sample);

  getSamples = (filters: any = {}): Observable<any> => this.http.get(`${this.url}/list`, { params: filters });

  getSample = (tasteId: string): Observable<any> => this.http.get(`${this.url}/${tasteId}`);

  updateSample = (sampleId, sample: any): Observable<any> => this.http.put(`${this.url}/${sampleId}`, sample);

  disableSample = (sampleId: string): Observable<any> => this.http.delete(`${this.url}/to-disable/${sampleId}`);
}
