import { NgModule } from '@angular/core';

import { LeftoverListComponent } from './leftover-list/leftover-list.component';
import { LeftoverAddComponent } from './leftover-add/leftover-add.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [LeftoverListComponent, LeftoverAddComponent],
  imports: [
    SharedModule,
  ],
  exports: [
    LeftoverListComponent,
    LeftoverAddComponent
  ]
})
export class LeftoversModule { }
