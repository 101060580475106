import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { CenterCostService } from '../../services/center-cost.service';
import { CenterCost } from '../../models/center-cost';

@Component({
  selector: 'app-centercost-edit',
  templateUrl: './centercost-edit.component.html',
  styleUrls: ['./centercost-edit.component.css'],
  providers: [CenterCostService]
})
export class CentercostEditComponent implements OnInit {

  public titulo: string;
  public centercost: any = {};
  public identity;
  public token;
  public alertMessage;
  public is_edit;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _centercostService: CenterCostService
  ) {
    this.titulo = 'Editar Centro de Costos';
    this.centercost = {} as CenterCost;
  }

  ngOnInit() {
    this.getCenterCost();
  }

  getCenterCost() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._centercostService.getCenterCost(id).subscribe(
        response => {
          if (!response) {
            this._router.navigate(['/']);
          } else {
            this.centercost = response.centercost;
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            console.log(error);
          }
        }
      );
    });
  }
  onSubmit() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._centercostService.editCenterCost(id, this.centercost).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Centro de Costos actualizada correctamente';
            this._router.navigate(['/ajustes']);
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );

    });
  }

}
