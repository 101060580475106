import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { CheckboxItem } from '../../models/checkbox-item';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.css']
})
export class CheckboxGroupComponent implements OnInit, OnChanges {

  @Input() options = Array<CheckboxItem>();
  @Input() selectedValues: string[];
  @Output() toggle = new EventEmitter<any[]>();

  public inventoryModel = {
    stamp: []
  };

  constructor() { }

  ngOnInit() {
  }
  ngOnChanges() {
    this.selectedValues.forEach(label => {
      const element = this.options.find(x => x.label === label);
      if (element) {
        element.checked = true;
      }
    });
  }
  onToggle() {
    const checkedOptions = this.options.filter(x => x.checked);
    this.selectedValues = checkedOptions.map(x => x.label);
    this.toggle.emit(checkedOptions.map(x => x.label));
  }

}
