import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Sale } from '../models/sale';

@Injectable()
export class SaleService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getSales(): Observable<any> { return this.http.get(`${this.url}/sales/list`); }
  getInvoiceSales(): Observable<any> { return this.http.get(`${this.url}/sales/invoice-list`); }
  getPaidOutSales(): Observable<any> { return this.http.get(`${this.url}/sales/paid-out-list`); }

  addSale(sale: Sale): Observable<any> {
    return this.http.post(`${this.url}/sales/new`, sale);
  }
  getSale(id): Observable<any> {return this.http.get(`${this.url}/sales/${id}`); }
  editSale(id, sale): Observable<any> {return this.http.put(`${this.url}/sales/${id}`, sale); }
  deleteSale(id): Observable<any> {return this.http.delete(`${this.url}/sales/${id}`); }
  getSalePerWarehouse(warehouse): Observable<any> {
    return this.http.get(`${this.url}/sales/warehouse/${warehouse}`);
  }
  getSalePerCustomer(customer): Observable<any> {
    return this.http.get(`${this.url}/sales/customer/${customer}`);
  }
  getSalePerWeightNotes(weightnotes): Observable<any> {
    return this.http.get(`${this.url}/sales/weightnotes/${weightnotes}`);
  }
}
