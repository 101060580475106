import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-importer',
  templateUrl: './importer.component.html',
  styleUrls: ['./importer.component.css']
})
export class ImporterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
