import { Component, OnInit } from '@angular/core';

import { GLOBAL } from '../../services/global';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { Company } from '../../models/company';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { UploadService } from '../../services/upload.service';

import { UploadimagenComponent } from '../uploadimagen/uploadimagen.component';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  providers: [UserService, UploadService]
})

export class UserEditComponent implements OnInit {
  public titulo: string;
  public user: User;
  public company: Company;
  public identity;
  public token;
  public alertMessage;
  public status;
  public url: string;
  public filesToUpload: Array<File>;
  public isImageLoading;
  imageToShow: any;
  public image;
  public mask = ['(', '5', '0', '4', ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public maskHarvest = [/\d/, /\d/, /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/];
  public maskPrice = [/\d/, /\d/, /\d/, /\d/, ' ', 'Lps.'];
  public company_id;

  constructor(
    private _userService: UserService,
    private _uploadService: UploadService,
    private _router: Router,
    public dialog: MatDialog
  ) {
    this.titulo = 'Actualizar mis datos';
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.user = this.identity;
    this.url = GLOBAL.url;
    this.company = this._userService.getCompany();
    this.image = this.company.image;
  }

  ngOnInit() {
   this.getImage();
  }

  onSubmit() {
    this._userService.updateUser(this.company).subscribe(
      response => {
        if (!response.user) {
          this.status = 'error';
        } else {
          this.status = 'success';
          localStorage.setItem('company', JSON.stringify(response.user));
         //  localStorage.setItem('user', JSON.stringify(this.user));
          // tslint:disable-next-line:max-line-length
        }
      },
      error => {
        const errorMessage = <any>error;
        if (errorMessage != null) {
          this.status = 'error';
        }
      }
    );
  }

  fileChangeEvent(fileInput: any) {
    this.filesToUpload = <Array<File>>fileInput.target.files;
  }
  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
  getImage() {
    this.image = this.company.image;
    this.isImageLoading = true;
    this._uploadService.getImage(this.image).subscribe(data => {
      this.createImageFromBlob(data);
    }, error => {
      this.isImageLoading = false;
      console.log(error);
    });
  }



  // Nuevo suber imagen
  suberimg() {

    const dialogRef = this.dialog.open(UploadimagenComponent, {
      width: '400px'
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result === 'listo') {
        this.company = this._userService.getCompany();
        this.getImage();

        window.location.reload();

      } else {

        this.company = this._userService.getCompany();
        this.getImage();

      }

    });
  }

/*  onSubmit() {
    this._userService.updateUser(this.user).subscribe(
      response => {
        if (!response.user) {
          this.status = 'error';
        } else {
          this.status = 'success';
          localStorage.setItem('identity', JSON.stringify(this.user));
          localStorage.setItem('user', JSON.stringify(this.user));
          // tslint:disable-next-line:max-line-length
          this._uploadService.makeFileRequest(`${this.url}/users/upload-image-user/${this.user._id}`,
           [], this.filesToUpload, this.token, 'image')
            .then((result: any) => {
              this.user.image = result.image;
              localStorage.setItem('identity', JSON.stringify(this.user));
              localStorage.setItem('user', JSON.stringify(this.user));
              window.location.reload();
            });
        }
      },
      error => {
        const errorMessage = <any>error;
        if (errorMessage != null) {
          this.status = 'error';
        }
      }
    );
  }

  fileChangeEvent(fileInput: any) {
    this.filesToUpload = <Array<File>>fileInput.target.files;
  }
  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
  getImage() {
     this.image = this.identity.image;
          this.isImageLoading = true;
          this._uploadService.getImage(this.image).subscribe(data => {
           this.createImageFromBlob(data);
            this.isImageLoading = false;
          }, error => {
            this.isImageLoading = false;
            console.log(error);
          });
  }*/
}
