import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Taster } from '../models/taster';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class TasterService {

  public url = GLOBAL.url + '/tasters';
  constructor(
    private http: HttpClient
  ) { }

  addTaster(taster: Taster): Observable<any> {
    return this.http.post(`${this.url}/new`, taster);
  }

  getTasters(): Observable<any> {
     return this.http.get(`${this.url}/list`);
   }

   getTaster(tasterId: string): Observable<any> {
     return this.http.get(`${this.url}/${tasterId}`);
   }

   updateTaster(tasterId: string, taster: Taster): Observable<any> {
     return this.http.put(`${this.url}/${tasterId}`, taster);
   }
}
