import {SharedModule} from './../../../shared/shared.module';
import {MatSelectSearchModule} from './../../mat-select-search/mat-select-search.module';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvProductionListComponent } from './inv-production-list/inv-production-list.component';

@NgModule({
  declarations: [
    InvProductionListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatSelectSearchModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class InvProductionModule { }
