import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material';

import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { UserService } from '../../services/user.service';
import { FD3Service } from '../../services/utz.service';
import { FD3 } from '../../models/FD3';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { Fd34EditComponent } from '../fd34-edit/fd34-edit.component';
import { Fd34AddComponent } from '../fd34-add/fd34-add.component';
import { InventoryService } from '../../services/inventory.service';
import { Inventory } from '../../models/inventory';
import { PrintService } from '../../services/print.service';
import { DriedService } from '../../services/dried.service';
import { Dried } from '../../models/dried';
import { ExcelService } from '../../services/excel.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-fd34',
  templateUrl: './fd34.component.html',
  styleUrls: ['./fd34.component.css'],
  providers: [UserService, FD3Service, ExcelService, InventoryService, PrintService, DriedService]
})
export class Fd34Component implements OnInit, AfterViewInit {

  public titulo: string;
  public fd3s: FD3[];
  public dried: any = {};
  public convertToExcel: any = {};
  public fd3 = [];
  public identity;
  public confirmado;
  public alertMessage;
  public token;
  public cfName;
  public wName;
  public total_qq;
  public oName;
  data: any[] = [

  ];
  data2: any[] = [

  ];
  pipe = new DatePipe('es-HN');
  closeResult: string;
  public inventory: any = {};

  displayedColumns: string[] = ['cod', 'lot', 'date', 'total_qq', 'total_kg', 'action'];
  highlightedRows = [];
  dataSource = new MatTableDataSource<FD3>();
  // dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _fd3Service: FD3Service,
    private _userService: UserService,
    public dialog: MatDialog,
    private _printService: PrintService,
    private excelService: ExcelService,
    private _inventoryService: InventoryService,
    private _driedService: DriedService,
  ) {
    this.titulo = 'Lista de Productores';
    this.token = this._userService.getToken();
    this.inventory = {} as Inventory;
    this.convertToExcel = {} as FD3;
    this.identity = this._userService.getCompany();
    this.dried = {} as Dried;
  }

  ngOnInit() {
    this.getFD3s();
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  openDialog() {
    const dialogRef = this.dialog.open(Fd34AddComponent, {
      width: '750px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getFD3s();
    });
  }
  getPFD3(fd3) {

    this._fd3Service.getFD3(fd3._id).subscribe(
      list => {
        this.convertToExcel = list as FD3[];
        const result: any[] = [];
        let limit = 0;
        this.convertToExcel.weight_notes.forEach((elem, index) => {
          limit++;
          console.log(elem.coffeefarmer.name);
          console.log(this.convertToExcel.qqForWn[index]);
          const match = result.find((r: any) => r.coffeefarmer.toString() === elem.coffeefarmer.toString());
          if (match) {
            elem.total_net_qq = match.total_net_qq + this.convertToExcel.qqForWn[index];
            Object.assign(match, elem);
          } else {
            elem.total_net_qq = this.convertToExcel.qqForWn[index];
            result.push(elem);
          }
        });

        if (limit === this.convertToExcel.weight_notes.length) {
          Object.assign(this.convertToExcel, { consolidate: result });
          this.convertToExcel.consolidate.forEach((element, i) => {

            const rows = {
              Total_Neto_qq: element.total_net_qq,
              Total_kg: (element.total_net_qq * 100) / 2.2046,
              Productor: element.coffeefarmer.name
            };

            this.convertToExcel = Object.assign([], this.convertToExcel, { [i]: rows });
          });
          this.excelService.exportAsExcelFile(this.convertToExcel, 'Lista de Productores');
        }

      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }
  openDialogD(fd3) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar fd34',
        title: '',
        text: `¿Seguro que quieres eliminar <strong>#${fd3.lot}</strong>?`,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.updateWn(fd3);
      }
    });
  }
  openDialogC(fd3: any) {
    const dialogRef = this.dialog.open(Fd34EditComponent, { width: '750px', data: fd3 });
    dialogRef.afterClosed().subscribe(result => {
      this.getFD3s();
    });
  }
  getFD3() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._fd3Service.getFD3(id).subscribe(
        response => {
          if (!response) {
            this._router.navigate(['/']);
          } else {
            this.fd3 = response.fd3;
          }
        }
      );
    });
  }
  print(id) {
    this._printService.getUTZ(id).then(
      (res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      })
      .catch(err => console.log('Error', err));
  }
  getFD3s() {

    this._fd3Service.getFD3s().subscribe(
      (res) => {
        this.dataSource.data = res as FD3[];
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }
  chorro() {
    this.data.forEach(id => {
      this.inventory.utz = true;
      this.inventory.total_net_utz = 0;
      this._inventoryService.editInventory(id, this.inventory).subscribe(
        res => {
          console.log('listo');
        }
      );
    });
  }
  chorro2() {
    this.data2.forEach(id => {
      this.dried.utz = true;
      this._driedService.editDried(id, this.dried).subscribe(
        response => {
          console.log('listo');
        }
      );
    });
  }
  updateWn(fd3) {
    let limit = 0;
    fd3.weight_notes.forEach((item, index) => {
      limit++;
      const total_qq = fd3.qqForWn[index] + fd3.restForWn[index];
      this._inventoryService.getInventory(item._id).subscribe(response => {
        if (total_qq === response.total_net_qq) {
          this.inventory.utz = false;
        } else {
          this.inventory.utz = true;
        }
        this.inventory.total_net_utz = total_qq;
        this._inventoryService.editInventory(item._id, this.inventory).subscribe(
          res => {
            if (!res) {
              this.alertMessage = 'Error en el servidor';
            } else {
              this.alertMessage = 'Nota de peso actualizada correctamente';
            }
          }
        );
      });
    });
    if (limit === fd3.weight_notes.length) {
      this.update_dry(fd3);
    }
  }
  update_dry(fd3) {
    let limit = 0;
    fd3.dry_notes.forEach((item, index) => {
      limit++;
      this.dried.utz = false;
      this._driedService.editDried(item._id, this.dried).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Nota de Secado actualizada correctamente';
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );
    });
    if (limit === fd3.dry_notes.length) {
      this.deleteFD3(fd3);
    }
  }
  deleteFD3(fd3) {
    this._fd3Service.deleteFD3(fd3._id).subscribe(
      response => {
        if (!response) {
          alert('Error en el servidor');
        } else {
          this.getFD3s();
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }
}

