import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractListComponent } from './contract-list/contract-list.component';
import { ContractAddComponent } from './contract-add/contract-add.component';
import { ContractEditComponent } from './contract-edit/contract-edit.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [
    ContractListComponent,
    ContractAddComponent,
    ContractEditComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  entryComponents: [
    ContractAddComponent,
    ContractEditComponent
  ]
})
export class ContractsModule { }
