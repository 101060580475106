import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { LocationService } from '../../../../services/location.service';
import { BlendsService } from '../../../../services/blends.service';
import { SampleService } from '../../../../services/sample.service';
import { DriedService } from '../../../../services/dried.service';
import { LotService } from '../../../../services/lot.service';

import { Location } from '../../../../models/location';
import { Sample } from '../../../../models/sample';
import { Dried } from '../../../../models/dried';
import { Blend } from '../../../../models/blend';

@Component({
  selector: 'app-sample-add',
  templateUrl: './sample-add.component.html',
  styleUrls: ['./sample-add.component.css'],
  styles: [`
    :host {
      height: 100%;
      overflow-y: auto;
    }`
  ],
})
export class SampleAddComponent implements OnInit {

  public form: FormGroup;
  public sampleTypes: string[] = ['Pre-compra', 'Oferta', 'Evaluación', 'Inventario seco', 'Mezcla'];
  public coffeeTypes: string[] = ['Oro', 'Pergamino seco'];
  public varieties: string[] = ['Catuaí', 'Caturra', 'Bourbón', 'ihcafe 90', 'Parainema',
  'Typica', 'Lempira', 'Pacas', 'Obata', 'Colombiano', 'Geisha', 'Icatu', 'Magarogipe', 'Blend' ,'Java', 'Anacafe 14',  'Paca Nanca', 'Blend', 'Borbon Rosado', 'Pacamara',
'Aji Natural', 'Bourbon Sidra', 'Wush Wush', 'Chiroso', 'Laurina', 'Sudan Rume', 'Sidra', 'Bernardina', 'Moka', 'Castilla', 'Purpurasea', 'Heirloom' ];
  public selectedVarieties: string[] = [];
  public locations: Location[] = [];
  public drieds: Dried[] = [];
  public blends: Blend[] = [];
  public searchForm: FormGroup;
  public alert = false;
  constructor(
    private _sampleService: SampleService,
    private _locationService: LocationService,
    private _driedService: DriedService,
    private _blendService: BlendsService,
    private _lotService: LotService,
    private _sidebarManager: SidebarManagerService,
    private _fb: FormBuilder
  ) { }
    // todo, Obtener secadas finalizadas, cambiar la verificacion de codigo de secada.
  ngOnInit() {
    this.createForm();
    this.getLocations();
    this.listeners();
    this.checkInput();
  }

  getLocations(): void {
    this._locationService.getLocations().subscribe( res => {
      this.locations = res;
    }, (err: HttpErrorResponse) => console.error('Error al obtener ubicaciones', err));
  }

  listeners = (): void => {
    this.searchForm.get('driedCode').valueChanges.subscribe((value) => {
      if (value) this.searchForm.get('wnCode').setValue('');
    });
    this.searchForm.get('wnCode').valueChanges.subscribe((value) => {
      if (value) this.searchForm.get('driedCode').setValue('');
    });
    this.searchForm.get('blendCode').valueChanges.subscribe((value) => {
      if (value) this.searchForm.get('driedCode').setValue('');
    });
    this.form.get('type').valueChanges.subscribe((type: string) => {
      if (type === 'Inventario seco') this.form.controls['dryingOrder'].setErrors({'incorrect': true});
      else this.form.controls['dryingOrder'].clearValidators();
      console.log('form', this.form);
    });
  }

  checkInput = (): void => {
    const input = this._sidebarManager.getInput('sample-add');
    if (!input) return;
    if (input.dried) {
      this.form.patchValue({
        type: 'Inventario seco'
      });
      this.form.get('type').disable();
      this.searchForm.get('driedCode').setValue(input.dried.cod);
      this.searchForm.get('driedCode').disable();
    } else if (input.blend) {
      this.form.patchValue({
        type: 'Mezcla'
      });
      this.form.get('type').disable();
      this.searchForm.get('blendCode').setValue(input.blend.code);
      this.searchForm.get('blendCode').disable();
    }
    this.searchData();
  }

  searchData = (): void => {
    const form = this.searchForm.getRawValue();
    if (this.f.type.value === 'Inventario seco') {
      this._driedService.searchSampleDrieds(form).subscribe((res: Dried[]) => {
        console.log('drieds', res);
        this.drieds = res;
        this.form.patchValue({
          dryingOrder: res.length > 0 ? res[0] : null
        });
        if (res.length === 0) this.form.controls['dryingOrder'].setErrors({ 'incorrect': true });
        else this.form.controls['dryingOrder'].clearValidators();
      }, (err: HttpErrorResponse) => console.error('Error al obtener ordenes de secado', err));
    } else if (this.f.type.value === 'Mezcla') {
      this._blendService.searchSampleBlend(form).subscribe((res: Blend[]) => {
        console.log('blends', res);
        this.blends = res;
        this.form.patchValue({
          blend: res.length > 0 ? res[0] : null
        });
        if (res.length === 0) this.form.controls['blend'].setErrors({ 'incorrect': true });
        else this.form.controls['blend'].clearValidators();
      }, (err: HttpErrorResponse) => console.error('Error al obtener ordenes de secado', err));
    }
  }

  addSample = (): void => {
    const sample: Sample = this.form.getRawValue();
    // sample.tasteStatus = 'Ofertado';
    const dried: Dried = sample.dryingOrder as Dried;
    sample.microlot = dried.microlot;
    Object.keys(sample).forEach(key => ((sample[key] === '' || sample[key] === null) ? delete sample[key] : ''));
    this._sampleService.addSample(sample).subscribe((res: Sample) => {
      // * Agregar la muestra al inventario seco.
      console.log('muestra agregada', res);
      this.checkSampleRef(Object.assign(res, sample));
      setTimeout(() => {
        this._sidebarManager.close('sample-add', true);
      }, 1000);
    }, (err: HttpErrorResponse) => console.error('Error al crear muestra', err));
  }

  checkSampleRef = (sample: Sample): void => {
    console.log('sample', sample);
    // * Si la muestra tiene vinculada una orden de secado.
    if (sample.dryingOrder) {
      const dried: Dried = sample.dryingOrder as Dried;
      dried.inventoryStatus = Object.assign(dried.inventoryStatus, {
        samples: dried.inventoryStatus.samples.length > 0 ? dried.inventoryStatus.samples.concat(sample._id) : [sample._id]
      });
      // dried.tasteStatus = 'Ofertado';
      console.log(dried);
      this._driedService.editDried(dried._id, { inventoryStatus: dried.inventoryStatus }).subscribe((res2) => {
        console.log('inventario seco actualizado', dried);
      }, (err: HttpErrorResponse) => console.error('Error al actualizar inventario seco', err));
    } else if (sample.blend) { // * Si la muestra tiene vinculada una mezcla.
      const blend: Blend = sample.blend as Blend;
      blend['samples'] = blend.samples.length > 0 ? blend.samples.concat(sample._id) : [sample._id];
      this._blendService.updateBlend(blend._id, blend).subscribe((res2) => {
        console.log('mezcla actualizada', blend);
      }, (err: HttpErrorResponse) => console.error('Error al actualizar mezcla seco', err));
    }
  }

  createForm(): void {
    this.form = this._fb.group({
      name:         [''],
      coffeeType:   [''],
      owner:    this._fb.group({
        name:         [''],
        telephone:    [''],
        quintals:     [''],
        farm:         ['']
      }),
      lot:          [''],
      dryingOrder:  [''],
      blend:        [''],
      weight:       [''],
      date:         [new Date(), Validators.required],
      location:     [''],
      type:         [''],
      varieties:    ['', ],
      water_activity: [''],
      offer: [''],
    });

    this.searchForm = this._fb.group({
      driedCode:  [''],
      wnCode:     [''],
      blendCode:  [''],
    });
  }

  get f() {
    return this.form.controls;
  }

}
