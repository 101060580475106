import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Origin } from '../models/origin';

@Injectable()
export class OriginService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getOrigins(): Observable<any> { return this.http.get(`${this.url}/procedencias/list`); }

  addOrigin(origin: Origin): Observable<any> {return this.http.post(`${this.url}/procedencias/new`, origin); }
  getOrigin(id): Observable<any> {return this.http.get(`${this.url}/procedencias/${id}`); }
  editOrigin(id, origin): Observable<any> {return this.http.put(`${this.url}/procedencias/${id}`, origin); }
  deleteOrigin(id): Observable<any> {return this.http.delete(`${this.url}/procedencias/${id}`); }

}
