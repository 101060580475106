import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ContractService } from '../../services/contract.service';
import { Contract } from '../../models/contract';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-pin-up',
  templateUrl: './pin-up.component.html',
  styleUrls: ['./pin-up.component.css'],
  providers: [ContractService]
})
export class PinUpComponent implements OnInit {

  form = this.fb.group({
    price: null,
    pinup_date: null
  });

  public titulo: string;
  public contract: any = {};
  public alertMessage;
  public id;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private fb: FormBuilder,
    private _contractService: ContractService,
    public dialogRef: MatDialogRef<PinUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.titulo = 'Fijar Contracto';
    this.contract = {} as Contract;
  }

  ngOnInit() {
    this.getContract();
    this.id = this.data._id;
    this.contract = this.data;
    this.form = this.fb.group({
      price: ['', Validators.required],
      pinup_date: ['', Validators.required]
    });
  }

  getContract() {
    this._contractService.getContract(this.data._id).subscribe(
      response => {
        this.contract = response.contract;
        this.form.patchValue(this.contract);
      }
    );
  }
  onCancelClick(): void {
    this.dialogRef.close('confirm');
  }
  save() {
    this.contract = this.form.value;
    this._contractService.editContract(this.data._id, this.contract).subscribe(
      response => {
        this.onCancelClick();
      },
      error => {
          console.log(error);
      }
    );

  }

}
