import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { UserService } from '../../../services/user.service';
import { OriginService } from '../../../services/origin.service';
import { CheckboxItem } from '../../../models/checkbox-item';
import { Certification } from '../../../models/certification';
import { CertificationService } from '../../../services/certification.service';
@Component({
  selector: 'app-origin-add',
  templateUrl: './origin-add.component.html',
  styleUrls: ['./origin-add.component.css'],
  providers: []
})

export class OriginAddComponent implements OnInit {
  public form: FormGroup;
  public variety = [];
  public varieties = [
    { value: 'Catuaí' },
    { value: 'Caturra' },
    { value: 'Bourbón' },
    { value: 'ihcafe 90' },
    { value: 'Parainema' },
    { value: 'Typica' },
    { value: 'Lempira' },
    { value: 'Pacas' },
    { value: 'Obata' },
    { value: 'Colombiano' },
    { value: 'Geisha' },
    { value: 'Icatu' },
  ];

  public soils: any = [
    { value: 'Franco Arenoso' },
    { value: 'Franco Calizo' },
    { value: 'Franco Humífero' },
    { value: 'Franco Arcilloso' },
    { value: 'Franco Pedregoso' },
    { value: 'Franco Mixto' }
  ];

  public titulo = 'Agregar Procedencia';
  public certifications = [];
  public varieOptions = new Array<CheckboxItem>();

  constructor(
    private fb: FormBuilder,
    private _certificationService: CertificationService,
    private _originService: OriginService,
    public dialogRef: MatDialogRef<OriginAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.createForm();
    this.getCertifications();
    this.varieOptions = this.varieties.map((x, index) => new CheckboxItem(index, x.value));
  }

  createForm(): void {
    this.form = this.fb.group({
      name:           ['', Validators.required],
      address:        ['', Validators.required],
      altitude:       ['', Validators.required],
      soil:           [''],
      certifications: [''],
      varieties:      [''],
    });
  }

  getCertifications() {
    this._certificationService.getCertifications().subscribe(res => {
        this.certifications = res as Certification[];
      }
    );
  }

  onVarietyChange(value) {
    this.form.get('varieties').setValue(value);
  }

  submitForm() {
    this.dialogRef.close();
    const origin = this.form.getRawValue();
    console.log('form: ', this.form.value);

    this._originService.addOrigin(origin).subscribe(response => {
      console.log('Procedencia agregada: ', response);
    }, error => console.error('Error al agregar procedencia: ', error));
  }

}
