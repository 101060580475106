import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GLOBAL } from './global';
import { PeriodClosing } from '../models/period-closing';

@Injectable({
  providedIn: 'root'
})
export class PeriodClosingService {
  public url: string = GLOBAL.url + '/periods-closing';

  constructor(
    private http: HttpClient
  ) { }

  addPeriodClosing(periodClosing: PeriodClosing): Observable<any> {
    return this.http.post(`${this.url}/new`, periodClosing);
  }

  getPeriodsClosing(): Observable<any> {
    return this.http.get(`${this.url}/list`);
  }

  getPeriodClosing(id: string): Observable<any> {
    return this.http.get(`${this.url}/${id}`);
  }

  getLastPeriod(currentPeriod: number): Observable<any> {
    return this.http.get(`${this.url}/last-period/${currentPeriod}`);
  }
}
