import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cash-count-edit',
  templateUrl: './cash-count-edit.component.html',
  styleUrls: ['./cash-count-edit.component.css']
})
export class CashCountEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
