import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Transfer } from '../models/transfer';

@Injectable()
export class TransferService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getTransferPerPage(batch, lastKey?, page?): Observable<any> {

    const query = {
      limit: batch,
      page: page
    };
    if (lastKey) { query['startAt'] = lastKey; }

    return this.http.get(`${this.url}/transfers/listPerPage`, { params: query });
  }
  getTransfers(): Observable<any> { return this.http.get(`${this.url}/transfers/list`); }
  getTranfersPerCode(code): Observable<any> {
    return this.http.get(`${this.url}/transfers/list/${code}`);
  }

  addTransfer(transfer: Transfer): Observable<any> {return this.http.post(`${this.url}/transfers/new`, transfer); }
  getTransfer(id): Observable<any> {return this.http.get(`${this.url}/transfers/${id}`); }
  editTransfer(id, transfer): Observable<any> {return this.http.put(`${this.url}/transfers/${id}`, transfer); }
  deleteTransfer(id): Observable<any> {return this.http.delete(`${this.url}/transfers/${id}`); }
  getTransferPerWeightNotes(weightnotes): Observable<any> {
    return this.http.get(`${this.url}/transfers/weightnotes/${weightnotes}`);
  }

}
