import { Injectable } from '@angular/core';
import { GLOBAL } from './global';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Expense } from '../models/expense';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {
  private url = GLOBAL.url;
  constructor(
    private http: HttpClient
  ) {}

  addExpense(expense: Expense): Observable<any> {
    return this.http.post(`${this.url}/expenses/new`, expense);
  }

  editExpense(id, expense): Observable<any> {
    return this.http.put(`${this.url}/expenses/${id}`, expense);
  }

  getExpensesWithFilters(filters: any): Observable<any> {
    return this.http.get(`${this.url}/expenses/list-filter`, {params: filters});
  }

  deleteExpense(id: string): Observable<any> {
    return this.http.delete(`${this.url}/expenses/${id}`);
  }
}
