import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatPaginator } from '@angular/material';
import { GLOBAL } from '../../../../services/global';

import { SaleVoucherService } from '../../../../services/sale-voucher.service';
import { PrintService } from '../../../../services/print.service';
import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { IhcafeVoucherService } from '../../../../services/ihcafe-voucher.service';

import { IhcafeVoucher, SaleVoucherData } from '../../../../models/ihcafe-voucher';
import { PaginatorConfig } from '../../../../models/miscellaneous';
import { SaleVoucher } from '../../../../models/sale-voucher';
import { AppSidebar } from '../../../../models/sidebar';
import { DeleteModalComponent } from '../../../../pages/delete-modal/delete-modal.component';

@Component({
  selector: 'app-sale-voucher-list',
  templateUrl: './sale-voucher-list.component.html',
  styleUrls: ['./sale-voucher-list.component.css']
})
export class SaleVoucherListComponent implements OnInit {
  public saleVouchers: SaleVoucher[] = [];
  public form: FormGroup;
  public totalSold = 0;
  public currency = GLOBAL.currency;
  public paginatorConfig: PaginatorConfig = {
    count: 0,
    page: 0,
    limit: 100,
    label: 'Inventario'
  };
  public sidebars: AppSidebar[] = [
    { name: 'sale-voucher-edit',        title: 'Editar comprobante de venta', layout: {
      width: 30, height: 'full'
    }},
  ];

  constructor(
    private _saleVoucherService: SaleVoucherService,
    private _printService: PrintService,
    private _ihcafeVoucherService: IhcafeVoucherService,
    private _fb: FormBuilder,
    private _sidebar: SidebarManagerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this._sidebar.setSidebarsList(this.sidebars);
    this.getSaleVouchers();
    this.getTotalSold();
    this.createForm();
  }

  getSaleVouchers = (): void => {
    this._saleVoucherService.getSaleVouchers().subscribe((vouchers: SaleVoucher[]) => {
      this.saleVouchers = vouchers;
      console.log('vouchers', vouchers);
    }, (err: HttpErrorResponse) => console.error('Error al obtener comprobantes de ventas', err));
  }

  getTotalSold = (): void => {
    this._saleVoucherService.getTotalSold().subscribe((total: number) => {
      this.totalSold = 0;
    }, (err: HttpErrorResponse) => console.error('Error al obtener total qq', err));
  }

  changePage(paginator: MatPaginator) {
    this.paginatorConfig.page = paginator.pageIndex;
    this.getSaleVouchers();
  }

  printVoucher = (voucher: SaleVoucher): void => {
    this._printService.getSaleVoucher(voucher._id);
  }

  openVoucherEdit = (voucher: SaleVoucher): void => {
    this._sidebar.open('sale-voucher-edit', voucher._id);
  }

  openDialogDelete = (voucher: SaleVoucher): void => {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Anular comprobante',
        title: '',
        text: `¿Seguro que quieres anular el comprobante de venta?`,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      this.paginatorConfig.page = 0;
      this._saleVoucherService.updateSaleVoucher(voucher._id, { enabled: false }).subscribe(() => {
        this.getSaleVouchers();
        this._saleVoucherService.getSaleVoucher(voucher._id).subscribe((item: SaleVoucher) => {
          item.retentions.forEach((retention: IhcafeVoucher, i: number) => {
            const data: SaleVoucherData = retention.sale;
            data.remaining += item.sold_qq[i];
            data.sold -= item.sold_qq[i];
            if (data.sold < 0) {
              data.sold = 0;
            }
            if (data.remaining > data.gold) {
              data.remaining = data.gold;
            }
            data.vouchers = data.vouchers.filter((id) => id !== voucher._id);
            this._ihcafeVoucherService.updateVoucher(retention._id, { sale: data }).subscribe(() => {
              console.log('Retencion actualizada');
            }, (err: HttpErrorResponse) => console.error('Error al actualizar retencion', err));
          });
          console.log('voucher to delete', item);
        }, (err: HttpErrorResponse) => console.error('Error al obtener comprobante de venta', err));
      });
    });
  }

  createForm = (): void => {

  }

}
