import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';

import { ShippingService } from '../../../services/shipping.service';
import { Shipping } from '../../../models/shipping';
import { Lot } from '../../../models/lot';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { LotService } from '../../../services/lot.service';
@Component({
  selector: 'app-shipping-edit',
  templateUrl: './shipping-edit.component.html',
  styleUrls: ['./shipping-edit.component.css'],
  providers: [ShippingService, LotService]
})
export class ShippingEditComponent implements OnInit {
  public title = 'Editar envío';
  public form: FormGroup;
  lots: any[] = [];
  lotes = [];
  shiping: Shipping;
  currentLots = [];
  enable = false;
  public disabled = false;
  public exportersData: any[];
  public filteredExportersMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  /*------------------chips--------------------------*/
  public allLots: Lot[] = [];
  public lot: any = {};
  id;
  public chipSelectedLots: any[] = [];
  public objectsLots: any[] = [];
  public filteredLots: Observable<String[]>;

  private allowFreeTextAddLot = true;

  public lotControl = new FormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('lotInput') lotInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
/*--------------------------------------------*/
  constructor(
    private shippingService: ShippingService,
    public fb: FormBuilder,
    private _lotService: LotService,
    public dialogRef: MatDialogRef<ShippingEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
        this.lot = {} as Lot;
  }

  ngOnInit() {
    this.createForm();
    this.getLots();
    this.getDataFromServices();
    this.currentLots = this.shiping.lots;
    this.shiping.lots.forEach((item) => {
      this.selectLotById(item);
    });
    this.form.patchValue(this.shiping);
        /*------------------chips--------------*/
    this.filteredLots = this.lotControl.valueChanges.pipe(
      // tslint:disable-next-line: deprecation
      startWith(null),
      map(lotName => this.filterOnValueChange(lotName))
    );
    /*-------------------------------------*/
  }
  /*-----------------------------------chips--------------------*/
    public addLot(event: MatChipInputEvent): void {
      if (!this.allowFreeTextAddLot) {
        console.log('allowFreeTextAddLot is false');
        return;
      }
      console.log(event);

      if (this.matAutocomplete.isOpen) {
        return;
      }
      const value = event.value;
      if ((value || '').trim()) {
        this.selectLotByName(value.trim());
      }

      this.resetInputs();
    }

    public removeLot(lot: Lot): void {
      const index = this.chipSelectedLots.indexOf(lot);
      if (index >= 0) {
        this.chipSelectedLots.splice(index, 1);
        this.resetInputs();
      }
    }

    public lotSelected(event: MatAutocompleteSelectedEvent): void {
      this.selectLotByName(event.option.value);
      this.resetInputs();
    }

    private resetInputs() {
      this.lotInput.nativeElement.value = '';
      this.lotControl.setValue(null);
    }

    public filterExportersMulti() {
      if (!this.exportersData) {
        return;
      }
      let toSearch = this.form.get('exporterName').value;
      if (!toSearch) {
        this.filteredExportersMulti.next(this.exportersData.slice());
        return;
      } else {
        toSearch = toSearch.toLowerCase();
      }
      this.filteredExportersMulti.next(
        this.exportersData.filter( cert => cert.name.toLowerCase().indexOf(toSearch) > -1)
      );
    }

    private filterOnValueChange(lotName: string | null): String[] {
      let result: String[] = [];

      const allLotsLessSelected = this.allLots.filter(lot => this.chipSelectedLots.indexOf(lot) < 0);
      if (lotName) {
        result = this.filterLot(allLotsLessSelected, lotName);
      } else {
        result = allLotsLessSelected.map(lot => lot.lot);
      }
      return result;
    }

    private filterLot(lotList: Lot[], lotName: String): String[] {
      let filteredLotList: Lot[] = [];
      const filterValue = lotName.toLowerCase();
      const lotsMatchingLotName = lotList.filter(lot => lot.lot.toLowerCase().indexOf(filterValue) === 0);
      if (lotsMatchingLotName.length || this.allowFreeTextAddLot) {

        filteredLotList = lotsMatchingLotName;
      } else {

        filteredLotList = lotList;
      }

      return filteredLotList.map(lot => lot.lot);
    }

    private selectLotByName(lotName) {
      const foundLot = this.allLots.filter(lot => lot.lot === lotName);
      if (foundLot.length) {

        this.chipSelectedLots.push(foundLot[0]);
        this.getLot(foundLot[0]);
      } else {

        const highestEmployeeId = Math.max(...this.chipSelectedLots.map(lot => lot.seq), 0);
        this.chipSelectedLots.push({ lot: lotName, seq: highestEmployeeId + 1 });
      }
    }
    private selectLotById(res) {
      this.getLot(res);
      this.chipSelectedLots.push(res);
    }

    getLots() {
      this._lotService.getLotsOffProd().subscribe(
        (res) => {
          this.allLots = res as Lot[];
          console.log('Lotes obtenidos:', res);
        }
      );
    }

    getLot(lot) {
      console.log(lot);
      this.enable = true;
      this._lotService.getLot(lot._id).subscribe(
        response => {
          this.lot = response.lot;
        }
      );
    }
  /*----------------------------------chips-----------------------*/

  getDataFromServices(): void {
    this.shippingService.getShipping(this.data._id).subscribe(response => {
      console.log('Data a editar: ', response.shipping as Shipping);
      this.form.patchValue(response.shipping as Shipping);
      console.log('Nuevo form: ', this.form.value);
    }, err => console.error('Error al obtener el despacho: ', err));
  }

  createForm(): void {
    this.form = this.fb.group({
      date:               [''],
      lots:               [''],
      shipping:           [''],
      shipping_company:   [''],
      transport_company:  [''],
      driver:             [''],
      license_plate:      [''],
      container:          [''],
      chassis_plate:      [''],
      label:              [''],
      booking:            [''],
      observations:       [''],
      _id:                [''],
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  sendData(): void {
    const shippingObj: Shipping = this.form.value;
    const lotes = this.chipSelectedLots.map(lot => lot._id);
    shippingObj.lots = this.chipSelectedLots;
    this.updateLotsCurrent(this.currentLots, lotes, shippingObj);
  }

  updateLotsCurrent(current, lots, shippingObj) {
    this.lot = {} as Lot;
    this.lot.shippingStatus = false;
    this.lot.complete = false;
    let count = 0;
    current.forEach(item => {
      count++;
      this._lotService.editLot(item._id, this.lot).subscribe(
        response => {
          console.log(response);
        }
      );
    });
    if (count === current.length) {
      this.updateLots(lots, shippingObj);
    }
  }

  updateLots(lots, shippingObj) {
    this.lot = {} as Lot;
    this.lot.shippingStatus = true;
    this.lot.complete = true;
    this.lot.shipping = shippingObj._id;
    let count = 0;
    lots.forEach(_id => {
      count++;
      this._lotService.editLot(_id, this.lot).subscribe(
        response => {
          console.log(response);
        }
      );
    });
    if (count === lots.length) {
      console.log('Data editada: ', shippingObj);
      this.shippingService.editShipping(shippingObj._id, shippingObj).subscribe(() => {
        console.log('Data editada con exito!');
        this.dialogRef.close(true);
      }, err => console.error('Error al editar la data: ', err));
    }
  }
}
