import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { GLOBAL } from '../../../services/global';
import { BrokerService } from '../../../services/broker.service';
import { Broker } from '../../../models/broker';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-broker-add',
  templateUrl: './broker-add.component.html',
  styleUrls: ['./broker-add.component.css'],
  providers: [BrokerService]
})
export class BrokerAddComponent implements OnInit {

    form = this.fb.group({
    name: null,
    address: null,
    email: null,
    country: null,
    telephone: null
  });

  public titulo: string;
  public broker: any = {};
  public url: string;
  public alertMessage;
  public disabled = false;

  constructor(
    private _router: Router,
    private fb: FormBuilder,
    private _certificationService: BrokerService,
    public dialogRef: MatDialogRef<BrokerAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.titulo = 'Agregar broker';
    this.url = GLOBAL.url;
    this.broker = {} as Broker;
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      email: ['', Validators.required],
      country: ['', Validators.required],
      telephone: ['', Validators.required]
    });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  save() {
    this.broker = this.form.value;
    this._certificationService.addBroker(this.broker).subscribe(
      response => {
        this.alertMessage = 'Registro creado correctamente';
        this._router.navigate(['/brokers']);
        this.onCancelClick();
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          this.alertMessage = body.message;
          console.log(error);
        }
      }
    );
  }

}
