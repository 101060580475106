import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { GLOBAL } from './global';
import { PurchaseContract } from '../models/purchase-contract';

@Injectable({
  providedIn: 'root'
})
export class PurchaseContractService {
  public url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = `${GLOBAL.url}/purchase-contracts`;
  }

  createPurchaseContract(purchaseContract: PurchaseContract): Observable<any> {
    return this.http.post(`${this.url}/create`, purchaseContract);
  }

  updatePurchaseContract(id: string, purchaseContract: PurchaseContract): Observable<any> {
    return this.http.put(`${this.url}/update/${id}`, purchaseContract);
  }

  getPurchaseContract(id: string): Observable<any> {
    return this.http.get(`${this.url}/get/${id}`);
  }

  getPurchaseContracts(): Observable<any> {
    return this.http.get(`${this.url}/get-list`);
  }

  deletePurchaseContract(id: string): Observable<any> {
    return this.http.delete(`${this.url}/${id}`);
  }
}
