import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';

import { DriedService } from '../../../../services/dried.service';
import { LotService } from '../../../../services/lot.service';

import { Lot } from '../../../../models/lot';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-lot-edit',
  templateUrl: './lot-edit.component.html',
  styleUrls: ['./lot-edit.component.css']
})

export class LotEditComponent implements OnInit {

  public form: FormGroup;
  public next = true;
  public types = [
    { value: 'Saco Yute', viewValue: 'Saco Yute' },
    { value: 'Jumbo Liner', viewValue: 'Jumbo Liner' },
    { value: 'BigBag', viewValue: 'BigBag' }
  ];

  constructor(
    private fb: FormBuilder,
    private _lotService: LotService,
    private _driedService: DriedService,
    public dialogRef: MatDialogRef<LotEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.createForm();
    this.form.patchValue(this.data);
  }

  save() {
    const lot: Lot = Object.assign(this.data, this.form.getRawValue());
    this._lotService.editLot(lot._id, lot).subscribe((res: Lot) => {
      console.log('Lote actualizado: ', res);
      this.dialogRef.close(true);
    }, (err: HttpErrorResponse) => console.error('Error al editar lote: ', err));
  }

  private createForm(): void {
    this.form = this.fb.group({
      lot:        ['', Validators.required],
      stamp:      [''],
      bags:       [0],
      total_qq:   [0, Validators.required],
      departure:  [''],
      quality:    [''],
      mark:       ['']
    });
  }

}
