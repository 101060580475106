import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { LaboratoryService } from '../../../../services/laboratory.service';

import { Laboratory } from '../../../../models/laboratory';

@Component({
  selector: 'app-laboratory-add',
  templateUrl: './laboratory-add.component.html',
  styleUrls: ['./laboratory-add.component.css']
})
export class LaboratoryAddComponent implements OnInit {
  public form: FormGroup;
  constructor(
    private _fb: FormBuilder,
    private _laboratoryService: LaboratoryService,
    private _sidebarManager: SidebarManagerService,
  ) { }

  ngOnInit() {
    this.createForm();
  }

  addLaboratory = (): void => {
    const laboratoy: Laboratory = this.form.getRawValue();
    this._laboratoryService.addLaboratory(laboratoy).subscribe(res => {
      this._sidebarManager.close('laboratory-add');
    }, (err: HttpErrorResponse) => console.error('Error al agregar laboratorio', err));
  }

  createForm = (): void => {
    this.form = this._fb.group({
      name:       ['', Validators.required],
      address:    ['', Validators.required],
      telephone:  ['', Validators.required],
    });
  }

}
