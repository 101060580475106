import { UserService } from './../../../../services/user.service';
import { GLOBAL } from './../../../../services/global';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _moment from 'moment';
import * as _ from 'lodash';

import { AdvancePaymentService } from '../../../../services/advancePayment.service';
import { AdvancePayment } from '../../../../models/advancePayment';
@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.css']
})
export class StepTwoComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public advancePayments: AdvancePayment[] = [];
  public indexSelected: any = {};
  public settlement: any = {};
  public identity;
  public totalNet = 0;
  public currency;
  public balanceStatus: any = {};
  constructor(
    private userService: UserService,
    private _advancePaymentService: AdvancePaymentService,
    private fb: FormBuilder,
    ) {
      this.identity = this.userService.getCompany()._id;
      const data = localStorage.getItem('settlement');
      const index = localStorage.getItem('indexSelectedAdvancePayments');
      this.settlement = data !== null ? JSON.parse(data) : {};
      this.indexSelected = index !== null ? JSON.parse(index) : {};
      this.totalNet = this.settlement.total_net + this.settlement.total_deductions;
      console.log('Step 2 current LS data: ', this.settlement);
  }

  ngOnInit() {
    this.currency = GLOBAL.currency;
    setTimeout(() => {
      this.getAdvancePaymentsPerCoffeefarmer(this.settlement.coffeefarmer_id);
    }, 1000);
    if (this.identity === '5f50fdc413c3ab1f4454c17d') {
      this.createForm();
      this.getCoffeefarmerBalance();
    }
  }

  getAdvancePaymentsPerCoffeefarmer(id: string): void {
    console.log('to get', id);
    this._advancePaymentService.getAdvancePaymentPerCoffeeFarmer(id).subscribe((data) => {
      if (data !== null) {
        this.advancePayments = data;
        console.log(data);
      } else {}
    }, err => console.error('Error al obtener anticipos por productor: ', err));
  }

  getCoffeefarmerBalance(): void {
    this._advancePaymentService.getBalancePerCoffeefarmer(this.settlement.coffeefarmer_id).subscribe(res => {
      console.log('Saldo de productor: ', res);
      this.balanceStatus = res;
    }, err => console.error('Error al obtener saldo de productor: ', err));
  }

  public highlight(ap: any): void {
    ap['paymentValue'] = ap.hasOwnProperty('paymentValue') ? ap.paymentValue : ap.total_balance;
    // console.log('value: ', ap, ap.paymentValue);
    if (this.indexSelected.hasOwnProperty(ap._id)) {
      delete this.indexSelected[ap._id];
    } else {
      this.indexSelected[ap._id] = { total_balance: ap.total_balance, paymentValue: ap.paymentValue };
    }
    this.getSum();
  }

  getSum(option = 1): void {
    const totalPayment = Object.keys(this.indexSelected).reduce((acc, key) => acc + this.indexSelected[key].paymentValue, 0);
    this.settlement.total_deductions = parseFloat(Number(totalPayment).toFixed(2));
    this.settlement.total_net = this.getTotalNet(this.settlement, this.settlement.total);
  }

  saveData(): void {
    const index = this.indexSelected;
    Object.keys(index).forEach(key => {
      const current = index[key];
      current.paymentValue = current.total_balance >= current.paymentValue ? current.paymentValue : current.total_balance;
    });
    const convertedArray = Object.keys(index).map(key => {
        return {
            id: key,
            ...index[key]
        };
    });

    console.log(convertedArray);
    let totalPayment = this.sumBy(this.indexSelected, 'paymentValue');
    const stepTwoData = Object.assign(this.settlement, {
      advancePayments: Object.keys(index),
      total_deductions: parseFloat(Number(totalPayment).toFixed(2))
    });
    this.settlement.aps = convertedArray;
    stepTwoData['total_net'] = this.getTotalNet(stepTwoData, stepTwoData.total);
    this.settlement = Object.assign(this.settlement, stepTwoData);
    console.log('settlement end step 2:  ', this.settlement);
    localStorage.setItem('settlement', JSON.stringify(this.settlement));
    localStorage.setItem('indexSelectedAdvancePayments', JSON.stringify(index));
  }

  getTotalNet(settlement: any, total): number {
    let total_net = total;
    const numbers = _.pick(settlement, ['cost_per_beneficiary', 'freight', 'contributions', 'export_costs',
      'export_cert', 'financial_expenses', 'other_deductions', 'total_deductions']);
    Object.keys(numbers).forEach(key => {
      numbers[key] = isNaN(numbers[key]) ? 0 : numbers[key];
      total_net -= numbers[key];
    });
    return parseFloat(Number(total_net).toFixed(2));
  }

  sumBy(object: any, field: string): number {
    return Object.keys(object).reduce((acc, key) => acc + object[key][field] , 0);
  }

  ngOnDestroy(): void {
    this.saveData();
  }

  createForm(): void {
    this.form = this.fb.group({
      total_deductions: [this.settlement.total_deductions]
    });

    this.form.get('total_deductions').valueChanges.subscribe(value => {
        if (value > this.totalNet) {
          this.form.controls['total_deductions'].setErrors({'incorrect': true});
          this.settlement.total_deductions = 0;
        } else {
          this.form.controls['total_deductions'].clearValidators();
          this.settlement.total_deductions = value;
        }
        this.settlement.total_net = this.getTotalNet(this.settlement, this.settlement.total);
    });
    console.log('form init: ', this.form.value);
  }
}

