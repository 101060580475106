import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { LaboratoryService } from '../../../../services/laboratory.service';
import { DescriptorService } from '../../../../services/descriptor.service';
import { SensoryService } from '../../../../services/sensory.service';
import { TasterService } from '../../../../services/taster.service';
import { SampleService } from '../../../../services/sample.service';

import { SensoryAnalysisResult, AnalysisValue, SensoryAnalysis, defaultSensoryValues } from '../../../../models/sensory';
import { Laboratory } from '../../../../models/laboratory';
import { Descriptor } from '../../../../models/descriptor';
import { Taster } from '../../../../models/taster';
import { Sample } from '../../../../models/sample';

import { getDescriptorsList } from '../../../../helpers/descriptos';
import { SelectedSampleData } from '../../samples/sample-list/sample-list.component';
@Component({
  selector: 'app-sensory-edit',
  templateUrl: './sensory-edit.component.html',
  styleUrls: ['./sensory-edit.component.css'],
  styles: [`
    :host {
      height: 100%;
      overflow-y: auto;
    }`
  ],
})
export class SensoryEditComponent implements OnInit {

  public categories: string[] = ['SHG', 'HG', 'Fuera de Grado', 'Estandar', 'Especial'];
  public roastOptions: string[] = ['Oscuro', 'Medio', 'Claro'];
  public filteredSamples: Sample[] = [];
  public descriptors: Descriptor[] = [];
  public labs: Laboratory[] = [];
  public tasters: Taster[] = [];
  public currentSample: any;
  public samples: Sample[] = [];
  public form: FormGroup;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public descriptorsIndex = {
    smell: {
      selected: [],
      filtered: [],
    },
    flavor: {
      selected: [],
      filtered: [],
    },
    acidity: {
      selected: [],
      filtered: [],
    },
  };
  public descriptorsItems = [
    { title: 'Aroma', key: 'smell' },
    { title: 'Sabor', key: 'flavor' },
    { title: 'Acidez', key: 'acidity' },
  ];
  public sensoryEditing: SensoryAnalysis;

  constructor(
    private _tasterService: TasterService,
    private _sampleService: SampleService,
    private _laboratoryService: LaboratoryService,
    private _descriptorService: DescriptorService,
    private _sensoryService: SensoryService,
    private _sidebarManager: SidebarManagerService,
    private _fb: FormBuilder
  ) {
    this.createForm();
    const sensoryId: string = this._sidebarManager.getInput('sensory-edit');
    this._sensoryService.getSensory(sensoryId).subscribe((res: any) => {
      this.sensoryEditing = res;
      console.log('editing', res);

      this.setFormValues(res.tasterInfo);

      // this._sensoryService.updateSensory(_.cloneDeep(res)).subscribe(() => {});
      this.sensoryEditing.results.forEach((curr: SensoryAnalysisResult) => {
        curr.values.forEach((currValue: AnalysisValue) => {
          if (!currValue.cups || currValue.cups.length === 0) delete currValue.cups;
        });
      });
      this.form.patchValue(Object.assign({
        ...this.sensoryEditing,
        tasters: this.sensoryEditing.tasters.map((taster: Taster) => taster._id),
        lab: this.sensoryEditing.lab._id,
      }));
      this.form.setControl('results', this._fb.array(this.sensoryEditing.results.map((current: SensoryAnalysisResult) =>
        this._fb.group({
          sample:       [current.sample, Validators.required],
          roast:        [current.roast, Validators.required],
          values:       this.createAnaylisisForm(current.values),
          puntuation:   [current.puntuation, Validators.required],
          intensity:    [current.intensity, Validators.required]
        })
      )));
    }, (err: HttpErrorResponse) => console.error('Error al obtener analisis sensorial a editar', err));
  }

  ngOnInit() {
    this.getTasters();
    this.getSamples();
    this.getLabs();
    this.getDescriptors();
  }

  setFormValues(tastersInfoValues: any[]): void {
    if (tastersInfoValues) {
      const tastersInfoArray = this.form.get('tastersInfo') as FormArray;

      while (tastersInfoArray.length !== 0) {
        tastersInfoArray.removeAt(0);
      }

      tastersInfoValues.forEach(tasterInfo => {
        tastersInfoArray.push(this._fb.group({
          tasterId: [tasterInfo.tasterId],
          tasterName: [tasterInfo.tasterName],
          score: [tasterInfo.score]
        }));
      });
    }
  }

  getTasters(): void {
    this._tasterService.getTasters().subscribe(res => {
      this.tasters = res;
    }, (err: HttpErrorResponse) => console.error('Error al obtener catadores', err));
  }

  getLabs(): void {
    this._laboratoryService.getLaboratories().subscribe(res => {
      this.labs = res;
    }, (err: HttpErrorResponse) => console.error('Error al obtener labs', err));
  }

  getSamples(): void {
    this._sampleService.getSamples({ 'analysis.sensory': null, enabled: true }).subscribe((res: Sample[]) => {
      this.filteredSamples = res;
      this.samples = res;
    }, (err: HttpErrorResponse) => console.error('Error al obtener muestras', err));
  }

  getDescriptors(): void {
    this._descriptorService.getDescriptors().subscribe((res: Descriptor[]): void => {
      this.descriptors = getDescriptorsList().concat(res);
      this.descriptorsIndex.flavor.filtered = this.descriptors;
      this.descriptorsIndex.acidity.filtered = this.descriptors;
      this.descriptorsIndex.smell.filtered = this.descriptors;
    }, (err: HttpErrorResponse) => console.error('Error al obtener descriptores', err));
  }

  // * Agregar a la lista una nueva muestra para catar.
  pushSample(sample: Sample): void {
    this.selectedItems.push(this._fb.group({
      sample:     [sample, Validators.required],
      roast:      ['Medio', Validators.required],
      values:     this.createAnaylisisForm(defaultSensoryValues),
      puntuation: [72, Validators.required],
      intensity:  [2, Validators.required],
    }));
    this.filterSamples();
  }

  // * Ver analisis de una muestra seleccionada.
  selectSample(index: number): void {
    this.currentSample = this.selectedItems.at(index);
  }

  // * Eliminar una muestra seleccionada
  removeChip(index: number, sampleId: string): void {
    this.selectedItems.removeAt(index);
    if (this.currentSample) if (sampleId === this.currentSample.value.sample._id) this.currentSample = null;
    this.filterSamples();
  }

  // * Realizar la busqueda con el nombre de la muestra.
  searchSample = (name: string): void => {
    this.filteredSamples = _.differenceBy(this.samples, this.selectedItems.value
      .map(((item: SensoryAnalysisResult) => item.sample)), '_id')
      .filter(item => item.name.includes(name));
  }

  searchSamples = (name: string | number): void => {
    this.filteredSamples = this.samples
      .filter(sample => {
        const codeString = String(sample.code); // Asegurarse de que el código sea una cadena
        return codeString.indexOf(String(name)) !== -1;
      })
      .filter(sample => !this.selectedItems.value.some(item => item.sample._id === sample._id));
  }

  selectCup(valueItem: AbstractControl, indexCup: number): void {
    const cupItem = valueItem.value;
    valueItem.value.cups[indexCup] = !valueItem.value.cups[indexCup];
    valueItem.get('cups').setValue(valueItem.value.cups);
    const countDeselected = _.filter((cupItem.cups), (cup: boolean) => !cup).length;
    const countSelected = _.filter((cupItem.cups), (cup: boolean) => cup).length;
    valueItem.get('puntuation').setValue(countDeselected * 2);
    if (cupItem.key === 'cleanCup') {
      this.setValueChange('defects', countSelected * this.currentSample.get('intensity').value);
    }
    this.valueChange();
  }

  // * ------------- DESCRIPTORS C H I P S -------------
  searchDescriptor = (descriptor: string, key: string): void => {
    const selected = this.getValueItemByKey(key).value.descriptors;
    this.descriptorsIndex[key].filtered = _.differenceBy(this.descriptors, selected, 'name')
      .filter((item: Descriptor) => item.name.includes(descriptor));
  }

  selectedDescriptor(descriptor: string, key: string): void {
    const list = this.getValueItemByKey(key);
    const descriptors: string[] = list.value.descriptors;
    this.setValueChange(key, descriptors.concat(descriptor), 'descriptors');
  }

  removeDescriptor(index: number, key: string): void {
    const list = this.getValueItemByKey(key);
    const descriptors: string[] = list.value.descriptors;
    descriptors.splice(index, 1);
    this.setValueChange(key, descriptors, 'descriptors');
  }

  getDescriptorChips(key: string): string[] {
    if (!this.currentSample) return [];
    return this.getValueItemByKey(key).value.descriptors;
  }
  // * ------------- END DESCRIPTORS C H I P S -------------

  changeIntensity(value: number): void {
    console.log('change intensity');
    const cleanCup = this.getValueItemByKey('cleanCup').value;
    const selectedCount = _.filter(cleanCup.cups as boolean[], (cup) => cup).length;
    this.setValueChange('defects', selectedCount * value);
    this.valueChange();
  }

  valueChange = (value?: any): void => {
    const analysis: SensoryAnalysisResult = this.currentSample.getRawValue();
    const total = _.sumBy(analysis.values, 'puntuation') - (this.getValueItemByKey('defects').value.puntuation * 2);
    this.currentSample.get('puntuation').setValue(total);
  }

  setValueChange = (key, value, item = 'puntuation'): void => {
    const defectValueItem = this.getValueItemByKey(key);
      defectValueItem.get(item).setValue(value);
  }

  editSensory(): void {
    const sensoryAnalysis: SensoryAnalysis = Object.assign(_.cloneDeep(this.sensoryEditing), this.form.getRawValue());
    const removedSamples = _.differenceBy(this.sensoryEditing.results, sensoryAnalysis.results, 'sample._id');
    const tastersInfoArray = this.form.get('tastersInfo') as FormArray;

    let totalScore = 0;
    tastersInfoArray.controls.forEach((control: FormGroup) => {
      totalScore += parseInt(control.get('score').value, 10);
    });

    const averageScore = totalScore / tastersInfoArray.length;
    if (averageScore) {
      sensoryAnalysis.results[0].puntuation = averageScore;
    }
    removedSamples.forEach((current: SensoryAnalysisResult, index) => {
      current.sample.analysis.sensory = null;
      this._sampleService.updateSample(current.sample._id, current.sample).subscribe((res) => {
        console.log('muestra removida actualizada');
        if (index === removedSamples.length - 1 && sensoryAnalysis.results.length === 0)
          this._sidebarManager.close('sensory-edit', true);
      },
        err => console.error('Error al actualizar muestra removida', err));
    });
    console.log('to edit', sensoryAnalysis);
    this._sensoryService.updateSensory(sensoryAnalysis).subscribe((res: SensoryAnalysis) => {
      sensoryAnalysis.results.forEach((item: SensoryAnalysisResult, index: number) => {
        item.sample.analysis.sensory = sensoryAnalysis._id;
        this._sampleService.updateSample(item.sample._id, item.sample).subscribe(resp2 => {
          if (index === sensoryAnalysis.results.length - 1) this._sidebarManager.close('sensory-edit', true);
        }, (err: HttpErrorResponse) => console.error('Error al actualizar muestra', err));
      });
    }, (err: HttpErrorResponse) => console.error('Error al editar analisis sensorial', err));
  }

  createForm(): void {
    this.form = this._fb.group({
      session:             [''],
      date:             [new Date(), Validators.required],
      category:         [''],
      tasters:          ['', Validators.required],
      lab:              ['', Validators.required],
      observations:     [''],
      results:          this._fb.array([]),
      tastersInfo: this._fb.array([])
    });

    this.form.get('tasters').valueChanges.subscribe((selectedTasters: number[]) => {
      const tastersInfoArray = this.form.get('tastersInfo') as FormArray;

      while (tastersInfoArray.length !== 0) {
        tastersInfoArray.removeAt(0);
      }

      selectedTasters.forEach((tasterId: any) => {
        const taster = this.tasters.find(t => t._id === tasterId);
        if (taster) {
          tastersInfoArray.push(this._fb.group({
            tasterId: [taster._id],
            tasterName: [taster.name],
            score: ['', Validators.required]
          }));
        }
      });
    });
  }

  getValueItemByKey (key: string) {
    const index = _.findIndex(this.currentSample.getRawValue().values, (value: AnalysisValue) => value.key === key);
    return (this.currentSample.controls.values.controls as FormArray).at(index);
  }

  filterSamples = (): void => {
    this.filteredSamples = _.differenceBy(this.samples, this.selectedItems.value
      .map(((item: SensoryAnalysisResult) => item.sample)), '_id');
  }

  createAnaylisisForm(data: AnalysisValue[] = []): FormArray {
    return this._fb.array(
      data.map((item: AnalysisValue) =>
        this._fb.group(Object.keys(item).reduce((acc: object, curr: string) => ({
          ...acc,
          [curr]: !!item.cups && curr === 'cups'
            ? this._fb.array(item.cups)
            : [item[curr]],
        }), {}))
      )
    );
  }

  get currentValues(): any {
    return !this.currentSample ? [] : Object.keys(this.currentSample.values);
  }

  get cupItems(): any[] {
    return (this.currentSample.controls.values.controls as FormArray[])
      .filter(item => !!item.value.cups);
  }

  get valuesList(): any[] {
    return (this.currentSample.controls.values.controls as FormArray[])
      .filter(item => !item.value.cups);
  }

  get selectedItems() {
    return this.form.controls.results as FormArray;
  }

}
