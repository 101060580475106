import { HttpErrorResponse } from '@angular/common/http';
import { Input } from '@angular/core';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { DescriptorService } from '../../../../services/descriptor.service';

import { Descriptor } from '../../../../models/descriptor';

@Component({
  selector: 'app-descriptor-add',
  templateUrl: './descriptor-add.component.html',
  styleUrls: ['./descriptor-add.component.css']
})
export class DescriptorAddComponent implements OnInit {
  public form: FormGroup;
  constructor(
    private _fb: FormBuilder,
    private _descriptorService: DescriptorService,
    private _sidebarManager: SidebarManagerService,
  ) { }

  ngOnInit() {
    this.createForm();
  }

  addDescriptor = (): void => {
    const descriptor: Descriptor = this.form.getRawValue();
    this._descriptorService.addDescriptor(descriptor).subscribe(res => {
      this._sidebarManager.close('descriptor-add');
    }, (err: HttpErrorResponse) => console.error('Error al agregar descriptor', err));
  }

  createForm = (): void => {
    this.form = this._fb.group({
      name:       ['', Validators.required],
      type:       ['', Validators.required],
    });
  }

}
