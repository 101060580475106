import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DescriptorListComponent } from './descriptors/descriptor-list/descriptor-list.component';
import { LaboratoryListComponent } from './laboratories/laboratory-list/laboratory-list.component';
import { LocationListComponent } from './locations/location-list/location-list.component';
import { TasterListComponent } from './tasters/taster-list/taster-list.component';
import { SampleListComponent } from './samples/sample-list/sample-list.component';
import { PhysicalListComponent } from './physicals/physical-list/physical-list.component';
import { SensoryListComponent } from './sensories/sensory-list/sensory-list.component';

const routes: Routes = [
  { path: 'qa/muestras', component: SampleListComponent },
  { path: 'qa/descriptores', component: DescriptorListComponent },
  { path: 'qa/ubicaciones', component: LocationListComponent },
  { path: 'qa/laboratorios', component: LaboratoryListComponent },
  { path: 'qa/catadores', component: TasterListComponent },
  { path: 'qa/fisicos', component: PhysicalListComponent },
  { path: 'qa/sensoriales', component: SensoryListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QARoutingModule { }
