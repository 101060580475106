import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '../models/location';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  public url = GLOBAL.url + '/locations';
  constructor(
    private http: HttpClient
  ) { }

  addLocation(location: Location): Observable<any> {
    return this.http.post(`${this.url}/new`, location);
  }

  getLocations(): Observable<any> {
     return this.http.get(`${this.url}/list`);
   }

   getLocation(locationId: string): Observable<any> {
     return this.http.get(`${this.url}/${locationId}`);
   }

   updateLocation(locationId: string, location: Location): Observable<any> {
     return this.http.put(`${this.url}/${locationId}`, location);
   }
}
