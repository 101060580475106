import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Farmer } from '../models/farmer';

@Injectable()
export class FarmerService {
  public url: string;
  public coffeeFamerData;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }
  getFarmerPerCode(code): Observable<any> {
    return this.http.get(`${this.url}/farmers/${code}`);
  }

  addFarmer(productor: Farmer): Observable<any> {
    return this.http.post(`${this.url}/farmers/new`, productor);
  }


}
