import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import * as _ from 'lodash';

import { CustomerService } from '../../../../services/customer.service';
import { SaleService } from '../../../../services/sale.service';
import { GLOBAL } from '../../../../services/global';

import { Customer } from '../../../../models/customer';
import { Sale } from '../../../../models/sale';
import { Router } from '@angular/router';

@Component({
  selector: 'app-simple-sale-add',
  templateUrl: './simple-sale-add.component.html',
  styleUrls: ['./simple-sale-add.component.css']
})
export class SimpleSaleAddComponent implements OnInit {

  public alertMessage;
  public title: string;
  public filteredCurtomers: Customer[];
  public customersData: Customer[];
  public form: FormGroup;
  public currency;

  constructor(
    private _customerService: CustomerService,
    private _saleService: SaleService,
    private fb: FormBuilder,
    private _route: Router,
    public dialogRef: MatDialogRef<SimpleSaleAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = 'Agregar Venta';
  }

  ngOnInit() {
    this.createForm();
    this.currency = GLOBAL.currency;
    this.getDataFromServices();
    this.listenersFormChanges();
  }

  createForm(): void {
    this.form = this.fb.group({
      price:             ['', Validators.required],
      date:              ['', Validators.required],
      exporter:          [null],
      customerName:      [''],
      total_qq_ps:       [null],
      sub_total:         [0],
      differential:      [0],
      customer:          [''],
      contract:          [''],
      total:             ['', Validators.required],
      observations:     [''],
    });
  }

  calculateQQ() {
    const price = this.form.get('price').value;
    const total_qq_net = this.form.get('total_qq_ps').value;
    const total = total_qq_net * price;
    this.form.get('total').setValue(total);
  }

  getDataFromServices() {

    this._customerService.getCustomers().subscribe(resp => {
        this.customersData = resp;
        this.filteredCurtomers = resp;
     });

  }

  listenersFormChanges() {
    this.form.get('customerName').valueChanges.subscribe(name => {
      this.filteredCurtomers = this.customersData
      .filter((customer: Customer) => this.existString(customer.name, name));
    });

    this.form.get('total_qq_ps').valueChanges.subscribe(value => {
      if (value !== null || value !== '') {
        this.calculateQQ();
      }
    });

    this.form.get('price').valueChanges.subscribe(value => {
      if (value !== null || value !== '') {
        this.calculateQQ();
      }
    });

  }

  existString(text1: string, text2: string): boolean {
    const status = text1
    .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .indexOf(text2
      .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1;
    return status;
  }

  submitForm() {
    const sale: Sale = this.form.getRawValue();
    sale.status = 'Depósito';
    sale.total_qq_referral = sale.total_qq_ps;
    sale.total_qq_sale = sale.total_qq_ps;
    console.log('Valor de venta: ', sale);
    this._saleService.addSale(sale).subscribe(response => {
      this._route.navigateByUrl('administracion/ventas');
      console.log('Venta registrada...: ', response);
      this.dialogRef.close();
    }, error => console.error('Error al agregar la venta: ', error));
  }

}
