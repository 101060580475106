import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatSelect, MatOption } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Category } from '../../../../models/category';
import {FormControl} from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ReportService } from '../../../../services/reports.service';
import { UserService } from '../../../../services/user.service_old';
import { Seat } from '../../../../models/seat';
import { CategoryService } from '../../../../services/category.service';

@Component({
  selector: 'app-print-modal',
  templateUrl: './print-modal.component.html',
  styleUrls: ['./print-modal.component.css'],
  providers: [ReportService, UserService, CategoryService]
})
export class PrintModalComponent implements OnInit {
  public form: FormGroup;
  public title = 'Imprimir reporte de cuentas';
  public filterDetailsCategories: Category[] = [];
  public tempFilterCategories: Category[] = [];
  public detailsCategoriesData: Category[] = [];
  public seatData: Seat[] = [];
  public selectedCategory: Category;
  public alertMessage = '';
  public select = new FormControl();
  public indexedCategories = {};
  public allSelected = false;
  public indexedVoucher = {};
  public periods: number[] = [];
  public availableCategories: Category[] = [];
  public filteredLedgerCategories: Category[] = [];
  public allCategories = {name: 'Todas las categorias', code: 0o0};

  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild('mySel') skillSel: MatSelect;
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private reportService: ReportService,
    private _categoryService: CategoryService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.periods = this.data.periods;
    this.selectedCategory = this.data.selected;
  }

  ngOnInit() {
    this.createForm();
    this.getCategories();
    this.listenersValues();
    if (this.data.selected) {
      this.select.setValue([this.data.selected._id]);
    }
  }

  getCategories(): void {
    this._categoryService.getLedgerCategories().subscribe(res => {
      this.availableCategories = res;
      this.filteredLedgerCategories = res;
      this.indexedCategories = this.indexedArray(res, '_id');
    });
  }
  listenersValues(): void {
    this.form.get('categoryName').valueChanges.subscribe(value => {
      this.filteredLedgerCategories = this.availableCategories
      .filter(category => String(category.code + category.name)
        .toLowerCase().includes(value.toLowerCase()))
          .sort((n1, n2) => n1.code - n2.code);
    });

    this.select.valueChanges.subscribe((values: any[]) => {
      const index = values.indexOf(0);
      if (index > -1) {
        values.splice(index, 1);
      }
      this.form.get('categories').setValue(values);
    });

  }

  createForm(): void {
    this.form = this.fb.group({
      categories:   ['', Validators.required],
      categoryName: [''],
      // period:       ['', Validators.required],
      date_in:       ['', Validators.required],
      date_out:       ['', Validators.required],
    });
  }

  async printList() {
    const ids = this.form.get('categories').value;
    const index = ids.indexOf(0);
    if (index > -1) {
      ids.splice(index, 1);
    }
    const params = this.form.getRawValue();
    delete params.categoryName;
    console.log('params: ', params);

    this.reportService.getLedgerReport(params).then((result: any) => {
      const blob = new Blob([result], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
      })
      .catch(err => console.log('Error', err));
  }

  toggleAllSelection() {
    this.allSelected = !this.allSelected;  // to control select-unselect
    if (this.allSelected) {
      this.skillSel.options.forEach( (item: MatOption) => item.select());
    } else {
      this.skillSel.options.forEach( (item: MatOption) => {item.deselect(); });
    }
  }

  indexedArray(data: any[], key): Object {
    return data.reduce((acc, curr) => ({
      ...acc,
      [curr[key]]: curr,
    }), {});
  }

}
