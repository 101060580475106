import { Component, OnInit, Inject } from '@angular/core';
import { ExporterService } from '../../../services/exporter.service';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Exporter } from '../../../models/exporter';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-exporter-edit',
  templateUrl: './exporter-edit.component.html',
  styleUrls: ['./exporter-edit.component.css'],
  providers: [ExporterService,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}],
})
export class ExporterEditComponent implements OnInit {
  public title = 'Editar Exportador';
  formGroup: FormGroup;
  exporterId: string;
  constructor(
    private exporterService: ExporterService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ExporterEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.exporterId = this.data.id;
  }

  ngOnInit() {
    this.createForm();
    this.getExporterData();
    console.log('Data recibida: ', this.data);
  }

  createForm(): void {
    const emailRegex = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$';
    this.formGroup = this.fb.group({
      _id:         [''],
      name:       ['', Validators.required],
      email:      ['', Validators.pattern(emailRegex)],
      address:    ['', Validators.required],
      rtn:    [''],
      country:    ['', Validators.required],
      telephone:  ['', Validators.required],
    });
  }

  saveData(): void {
    console.log('Form:', this.formGroup);
    const newExporter: Exporter = this.formGroup.value;
    console.log('Exporter: ', this.formGroup.value);
    this.exporterService.editExporter(newExporter._id , newExporter).subscribe( response => {
      console.log('Respuesta al agregar:', response);
      this.cancelDialog();
    }, error => {
      console.error('Error al agregar exportador');
      const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.error(error);
        }
    });

  }

  getExporterData(): void {
    console.log('Id a editar:', this.exporterId);
    this.exporterService.getExporter(this.exporterId).subscribe(exporter => {
      console.log('Datos del exportador a editar:', exporter.exporter);
      this.formGroup.patchValue(exporter.exporter);
      console.log('Form despues de patch: ', this.formGroup.value);
    });

  }

  cancelDialog(): void {
    this.dialogRef.close();
  }

}
