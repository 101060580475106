import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SaleVoucher } from '../models/sale-voucher';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class SaleVoucherService {

  public url = GLOBAL.url + '/sale-vouchers';
  constructor(
    private http: HttpClient
  ) { }

  createSaleVoucher = (voucher: SaleVoucher): Observable<any> => this.http.post(`${this.url}/new`, voucher);

  getSaleVouchers = (): Observable<any> => this.http.get(`${this.url}/list`);

  getSaleVoucher = (voucherId: string): Observable<any> => this.http.get(`${this.url}/${voucherId}`);

  getTotalSold = (): Observable<any> => this.http.get(`${this.url}/total-sold`);

  updateSaleVoucher = (voucherId: string, voucher: any): Observable<any> => this.http.put(`${this.url}/${voucherId}`, voucher);
}
