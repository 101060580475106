import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { CollectionCenterService } from '../../../services/collectionCenter.service';
import { CollectionCenter } from '../../../models/collectionCenter';

@Component({
  selector: 'app-collection-center-edit',
  templateUrl: './collection-center-edit.component.html',
  styleUrls: ['./collection-center-edit.component.css'],
  providers: [UserService, CollectionCenterService]
})
export class CollectionCenterEditComponent implements OnInit {

public titulo: string;
  public collectionCenter: any = {};
  public identity;
  public token;
  public url: string;
  public alertMessage;
  public is_edit;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _collectionCenterService: CollectionCenterService
  ) {
    this.titulo = 'Editar Centro de Acopio';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.url = GLOBAL.url;
    this.collectionCenter = {} as CollectionCenter;
  }

  ngOnInit() {
    this.getCollectionCenter();
  }

  getCollectionCenter() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._collectionCenterService.getCollectionCenter(id).subscribe(
        response => {
          if (!response) {
            this._router.navigate(['/']);
          } else {
            console.log(response);
            this.collectionCenter = response;
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            console.log(error);
          }
        }
      );
    });
  }
  onSubmit() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._collectionCenterService.editCollectionCenter(id, this.collectionCenter).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Certificación actualizada correctamente';
            // this.collectionCenter = response.collectionCenter;
            // console.log(this.collectionCenter);
            this._router.navigate(['/notas-de-peso/centros-acopio']);
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );

    });
  }

}
