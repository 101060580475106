import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlendListComponent } from './blend-list/blend-list.component';
import { BlendAddComponent } from './blend-add/blend-add.component';
import { BlendEditComponent } from './blend-edit/blend-edit.component';
import { SharedModule } from '../../../shared/shared.module';
import { MaterialModule } from '../../../material-module';
import { SamplesModule } from '../../QA/samples/samples.module';

@NgModule({
  declarations: [
    BlendListComponent,
    BlendAddComponent,
    BlendEditComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SamplesModule,
    MaterialModule,
  ]
})
export class BlendsModule { }
