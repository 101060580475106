import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Leftover } from '../../../../models/leftover';
import * as _ from 'lodash';

import { LeftoversService } from '../../../../services/leftovers.service';
import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { ProductionService } from '../../../../services/production.service';
import { LotService } from '../../../../services/lot.service';

@Component({
  selector: 'app-leftover-add',
  templateUrl: './leftover-add.component.html',
  styleUrls: ['./leftover-add.component.css']
})
export class LeftoverAddComponent implements OnInit {
  public toEdit: Leftover;
  public prod: any = {};
  public lot: any = {};
  public lotEdit: any = {};
  public form: FormGroup;
  public totalqq = 0;
  public values: any = [];
  public valuesL: any = [];
  public exceeded = false;

  constructor(
    private _fb: FormBuilder,
    private _leftoverService: LeftoversService,
    private _sidebar: SidebarManagerService,
    private _prodService: ProductionService,
    private _lotService: LotService,
  ) {
    const { prod, leftover } = this._sidebar.getInput('leftover-add');
    console.log(this._sidebar.getInput('leftover-add'));
    this.prod = _.cloneDeep(prod.prod);
    this.lot = _.cloneDeep(prod.lot);
    this.toEdit = _.cloneDeep(leftover);
  }

  ngOnInit() {
    this.createForm();
    console.log(this.lot);

    this.form.valueChanges.subscribe((values) => {
      this.totalqq = _.sum(Object.values(values));
      this.exceeded = this.totalqq > this.available ? true : false;
    });
  }

  addLeftover = (): void => {
    if (this.exceeded) return ;
    this.valuesL = [];
    this.values = [];
    const result = this.form.getRawValue();
    result.prod = this.prod._id;
    result.adjustmentId = this.toEdit._id;
    const leftovers = _.cloneDeep(this.toEdit);
    result.lot = this.toEdit.lot;
    if (this.lot.leftoverValues.length > 0) {
      this.values = this.lot.leftoverValues;
      result
      this.values.push(result);
    } else {
      this.values.push(result);
    }
    if (leftovers.prods.length > 0) {
      this.valuesL = leftovers.prods;
      this.valuesL.push(result);
    } else {
      this.valuesL.push(result);
    }
    let pendingValue = 0;
    let sum = 0;
    let pushTo = 0;
    ['surplus', 'revision', 'stock', 'undertow', 'other'].forEach((field: string) => {
      leftovers[field] = leftovers[field] - result[field];
      pendingValue += leftovers[field];
      sum += result[field];
    });
    if (this.prod.microlot !== true) {
      pushTo = this.fill + sum;
    } else {
      pushTo = result.surplus;
    }
    
    this.lotEdit.complete = this.goal <= pushTo ? true : false;
    leftovers.finished = pendingValue <= 0 ? true : false;
    leftovers.ready = pendingValue <= 0 ? false : true;
    leftovers.prods = this.valuesL;
    this.lotEdit.leftoverValues = this.values;
    console.log('Resultados', pendingValue, leftovers, this.lotEdit);
    this._leftoverService.updateLeftover(this.toEdit._id, leftovers).subscribe((res) => {
      console.log('sobrante actualizado');
      this._lotService.editLot(this.lot._id, this.lotEdit).subscribe(() => {
        console.log('Lote actualizada');
        this._sidebar.close('leftover-add', true);
      }, (err: HttpErrorResponse) => console.error('Error al actualizar sobrante', err));
    }, (err: HttpErrorResponse) => console.error('Error al crear sacar sobrantes', err));
  }

  createForm = (): void => {
    this.form = this._fb.group({
      surplus:  [0],
      revision: [0],
      stock:    [0],
      undertow: [0],
      other:    [0],
      prod:     [0],
      lot:      [0],
    });
  }

  get goal () {
    if (!this.lot) return 0;
    return this.lot.total_qq;
  }

  get fill () {
    if (!this.prod) return 0;
    const exp = _.sumBy(this.lot.values, 'exportb');
    const left = _.sumBy(this.lot.leftoverValues, 'surplus');
    return exp + left;
  }

  get available () {
    return this.goal - this.fill;
  }
}
