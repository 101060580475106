import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

import { ContractService } from '../../../services/contract.service';
import { LotService } from '../../../services/lot.service';
import { ShippingService } from '../../../services/shipping.service';

import { Shipping } from '../../../models/shipping';
import { Contract } from '../../../models/contract';
import { Lot } from '../../../models/lot';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocomplete, MatDialog, MatAutocompleteSelectedEvent } from '@angular/material';

import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-shipping-add',
  templateUrl: './shipping-add.component.html',
  styleUrls: ['./shipping-add.component.css'],
  providers: [LotService, ContractService, ShippingService]
})
export class ShippingAddComponent implements OnInit, AfterViewInit {
  public title = 'Agregar envío';
  public form: FormGroup;
  private lotId: string;
  public lot: Lot;
  public lots: string[] = [];
  enable = false;
  public exportersData: any[];
  public filteredExportersMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  /*------------------chips--------------------------*/
  public allLots: Lot[] = [];
  public chipSelectedLots: any[] = [];
  public objectsLots: any[] = [];
  public filteredLots: Observable<String[]>;

  private allowFreeTextAddLot = true;

  public lotControl = new FormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('lotInput') lotInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  /*--------------------------------------------*/

  public contract: Contract;
  public stamps = {
    coffee_bird: 'Coffee Bird friendly',
    rain_forest: 'Rain Forest',
    nespresso:   'Nespresso AAA',
    fairtrade:   'Fairtrade',
    organic:     'USDA organic',
    diff:        'Diferencial',
    utz:         'UTZ',
    _4c:         '4C',
  };

  constructor(
    private fb: FormBuilder,
    private lotService: LotService,
    private contractService: ContractService,
    private shippingService: ShippingService,
    public dialog: MatDialog,
    private _lotService: LotService,
    public dialogRef: MatDialogRef<ShippingAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('\n\n\n!!!!!!!!Shipping add construido', this.data);
    if (this.data) {
      this.lotId = this.data.lotId;
    }
  }

  ngOnInit() {
    this.createForm();
    this.getLots();
    this.getDataFromServices();
        /*------------------chips--------------*/
    this.filteredLots = this.lotControl.valueChanges.pipe(
      // tslint:disable-next-line: deprecation
      startWith(null),
      map(lotName => this.filterOnValueChange(lotName))
    );
    this.form.patchValue({ quintals: this.data ? this.data : 0 });
    /*-------------------------------------*/
  }

  ngAfterViewInit(): void { }

  /*-----------------------------------chips--------------------*/
  public addLot(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddLot) {
      console.log('allowFreeTextAddLot is false');
      return;
    }
    console.log(event);

    if (this.matAutocomplete.isOpen) {
      return;
    }
    const value = event.value;
    if ((value || '').trim()) {
      this.selectLotByName(value.trim());
    }

    this.resetInputs();
  }

    public removeLot(lot: Lot): void {
      const index = this.chipSelectedLots.indexOf(lot);
      if (index >= 0) {
        this.chipSelectedLots.splice(index, 1);
        this.resetInputs();
      }
    }

    public lotSelected(event: MatAutocompleteSelectedEvent): void {
      this.selectLotByName(event.option.value);
      this.resetInputs();
    }

    private resetInputs() {
      this.lotInput.nativeElement.value = '';
      this.lotControl.setValue(null);
    }

    public filterExportersMulti() {
      if (!this.exportersData) {
        return;
      }
      let toSearch = this.form.get('exporterName').value;
      if (!toSearch) {
        this.filteredExportersMulti.next(this.exportersData.slice());
        return;
      } else {
        toSearch = toSearch.toLowerCase();
      }
      this.filteredExportersMulti.next(
        this.exportersData.filter( cert => cert.name.toLowerCase().indexOf(toSearch) > -1)
      );
    }

    private filterOnValueChange(lotName: string | null): String[] {
      let result: String[] = [];

      const allLotsLessSelected = this.allLots.filter(lot => this.chipSelectedLots.indexOf(lot) < 0);
      if (lotName) {
        result = this.filterLot(allLotsLessSelected, lotName);
      } else {
        result = allLotsLessSelected.map(lot => lot.lot);
      }
      return result;
    }

    private filterLot(lotList: Lot[], lotName: String): String[] {
      let filteredLotList: Lot[] = [];
      const filterValue = lotName.toLowerCase();
      const lotsMatchingLotName = lotList.filter(lot => lot.lot.toLowerCase().indexOf(filterValue) === 0);
      if (lotsMatchingLotName.length || this.allowFreeTextAddLot) {

        filteredLotList = lotsMatchingLotName;
      } else {

        filteredLotList = lotList;
      }

      return filteredLotList.map(lot => lot.lot);
    }

    private selectLotByName(lotName) {
      const foundLot = this.allLots.filter(lot => lot.lot === lotName);
      if (foundLot.length) {

        this.chipSelectedLots.push(foundLot[0]);
        this.getLot(foundLot[0]);
      } else {

        const highestEmployeeId = Math.max(...this.chipSelectedLots.map(lot => lot.seq), 0);
        this.chipSelectedLots.push({ lot: lotName, seq: highestEmployeeId + 1 });
      }
    }
    private selectLotById(res) {
      this.getLot(res);
      this.chipSelectedLots.push(res);
    }

    getLots() {
      this._lotService.getLotsOffComplete().subscribe(
        (res) => {
          this.allLots = res as Lot[];
          console.log('Lotes obtenidos:', res);
        }
      );
    }

    getLot(lot) {
      console.log(lot);
      this.enable = true;
      this._lotService.getLot(lot._id).subscribe(
        response => {
          this.lot = response.lot;
        }
      );
    }
  /*----------------------------------chips-----------------------*/

  getDataFromServices(): void {
    console.log('id abuscar: ', this.lotId);
    if (this.lotId) {
        this.lotService.getLot(this.lotId).subscribe(response1 => {
        this.lot = response1.lot as Lot;
        console.log('Lote obtenido: ', this.lot);
        this.selectLotById(this.lot);
        this.contractService.getContract(this.lot.contract).subscribe(response2 => {
            this.contract = response2 as Contract;
            console.log('Contract obtenido: ', this.contract);
        }, err => console.error('Error al obtener contrato: ', err));
    }, err => console.error('Error al obtener el lote: ', err));
    }
  }

  createForm(): void {
    this.form = this.fb.group({
      company:            [''], // ? contrato
      date:               [''],
      shipping:           [''],
      shipping_company:   [''],
      transport_company:  [''],
      driver:             [''],
      license_plate:      [''],
      container:          [''],
      chassis_plate:      [''],
      label:              [''],
      booking:            [''],

      observations:       [''],
      seq:                [''], // ? lote
      certs:              [''], // Certificados del contrato (diferenciales - strings);
      lote:               [''], // ? objecto lote
      harvest:            [''],
      status:             [''],
      user:               [''],
      lots:               [''], // ? Lote
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  sendData(): void {
    const shippingObj: Shipping = this.form.value;
    shippingObj.lots = this.chipSelectedLots.map(lot => lot._id);
    shippingObj.lote = this.lot;                // !
    shippingObj.status = true;                    // !
    const certs = [];
    if (this.contract) {
      Object.keys(this.contract.dif).forEach(key => certs.push(this.stamps[key]));
    }
    shippingObj.certs = certs;
    this.shippingService.addShipping(shippingObj).subscribe(response => {
      this.updateLots(shippingObj.lots, response._id);
    }, err => console.error('Error a realizar el envio: ', err));
  }

  updateLots(lots, id) {
    lots.forEach((_id, index) => {
      this._lotService.editLot(_id, { shipping: id, shippingStatus: true, complete: true }).subscribe(response => console.log('Lote actualizado'));
      if ((index+1) === lots.length) {
        this.dialogRef.close(true);
      }
    });
  }
}
