import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material';
import * as _ from 'lodash';

import { ExcelService } from '../../../services/excel.service';
import { ReportService } from '../../../services/reports.service';
import { SeatService } from '../../../services/seat.service';
import { UserService } from '../../../services/user.service';

import { Seat } from '../../../models/seat';
import { getCurrency } from '../../../helpers/number';

@Component({
  selector: 'app-dailybook',
  templateUrl: './dailybook.component.html',
  styleUrls: ['./dailybook.component.css'],
})

export class DailybookComponent implements OnInit {

  public title = 'Libro diario';
  public alertMessage = '';
  public countSeat = 0;
  public limit = 100;
  public page = 0;
  loading;
  public convertToExcel = [];
  public pipe = new DatePipe('es-HN');
  public form: FormGroup;
  public seatsData: Seat[] = [];
  public status = ['Activo', 'Anulado'];
  public tags = ['Anticipo', 'Liquidación', 'Abono', 'Varios'];
  public currentPeriod = 0;
  public tagColor = {
    'Abono': 'primary',
    // 'Gasto': 'danger',
    'Anticipo': 'success',
    'Liquidación': 'warning',
    'Varios': 'secondary',
  };
  public periods = [1, 2, 3];
  public currency = getCurrency();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private _seatService: SeatService,
    private _reportService: ReportService,
    private _userService: UserService,
    private excelService: ExcelService,
    private fb: FormBuilder,
  ) {
    this.currentPeriod = this._userService.getIdentity().company.settings.period;
    this.currentPeriod = this.currentPeriod ? this.currentPeriod : 1;
    for (let i = 1; i <= this.currentPeriod; i ++) this.periods.push(i);
  }

  ngOnInit() {
    this.createForm();
    this.form.get('period').setValue(this.currentPeriod);
    this.getSeatingWithFilters();
    this.paginator._intl.itemsPerPageLabel = 'Asientos por página';
  }

  getSeatingWithFilters() {
    const filters = this.setDataFilter();
    console.log('datFilter: ', filters);
    this._seatService.getSeatsWithFilters(filters).subscribe((res: any) => {
      console.log('data: ', res);
      this.seatsData = res.items;
      this.countSeat = res.count;
      if (this.seatsData.length === 0 ) {
        this.alertMessage = 'No se encontraron asientos';
      } else {
        this.alertMessage = '';
      }
      // window.document.getElementById('matTableDaily').scrollTo(0, 0);
    }, err => console.error('Error al obtener los asientos: ', err));
  }

  changePage(paginator: MatPaginator) {
    console.log('Event: ', paginator);
    this.limit = paginator.pageSize;
    this.page = paginator.pageIndex;
    // window.document.getElementById('matTableDaily').scrollTo(0, 0);
    this.getSeatingWithFilters();
  }

  setDataFilter(): void {
    const filters = JSON.parse(JSON.stringify(this.form.getRawValue()));
    Object.keys(filters).forEach((key) => {
      if (filters[key] == null || filters[key] === '' || (key === 'harvest' && filters[key] === 'Todas')) {
        delete filters[key];
      }
    });
    filters['skip'] = this.page * this.limit;
    filters['filter'] = Object.keys(filters).length > 2 ? false : true;
    filters['limit'] = this.limit;
    filters['status'] = filters.status === 'Activo' ? true : false;
    console.log('filters : ', filters);
    return filters;
  }

  filterData(): void {
    this.limit = 0;
    this.paginator.firstPage();
    this.getSeatingWithFilters();
  }

  getTotalCredit(seat: Seat): number {
    return _.sumBy(seat.SeatCategory, 'credit');
  }
  getTotalDebit(seat: Seat): number {
    return _.sumBy(seat.SeatCategory, 'debit');
  }

  createForm(): void {
    this.form = this.fb.group({
      date_in:      [''],
      date_out:     [''],
      status:       ['Activo'],
      tag:          [''],
      period:       ['']
    });
  }

   print() {
    const filters = this.setDataFilter();
    this._reportService.getDaily(filters);
   }

  /* ? ----------------------------------- Exportable a EXCEL -------------------------------- */
  exportAsXLSX(): void {
    this._seatService.getSeating().subscribe(
      res => {
        if (!res) {
        } else {
          this.convertToExcel = res as Seat[];
          this.convertToExcel.forEach((element, i) => {
            const newDate = this.pipe.transform(element.date_voucher, 'shortDate');
            if (element === undefined) {
              console.error('Undefined element: ', element, i);
            }

              const rows = {
                Número: element.number,
                Periodo: element.period,
                Código: element.abbreviation + '-' + element.code,
                Fecha: newDate,
                descripción: element.description,
                Total_Crédito: element.total_credit.toFixed(2),
                Total_Débito: element.total_debit.toFixed(2),
                Cosecha: element.harvest,
                Estado: element.status
              };

              const convertToExcel = Object.assign([], this.convertToExcel, { [i]: rows });
              this.convertToExcel = convertToExcel;

          });
        }
        console.log('ToExcelSeats: ', this.convertToExcel);
        this.excelService.exportAsExcelFile(this.convertToExcel, 'Libro Diario');
      },
      error => {
          console.log(error);
      }
    );
  }
}
