import { UserService } from './../../services/user.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';

import { MatPaginator } from '@angular/material';
import { FD3Service } from '../../services/fd3.service';
import { FD3 } from '../../models/FD3';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { Fd3EditComponent } from '../fd3-edit/fd3-edit.component';
import { Fd3AddComponent } from '../fd3-add/fd3-add.component';
import { InventoryService } from '../../services/inventory.service';
import { Inventory } from '../../models/inventory';
import { PrintService } from '../../services/print.service';
import { DriedService } from '../../services/dried.service';
import { Dried } from '../../models/dried';
import { ExcelService } from '../../services/excel.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

@Component({
  selector: 'app-fd3',
  templateUrl: './fd3.component.html',
  styleUrls: ['./fd3.component.css'],
  providers: [UserService, FD3Service, ExcelService, InventoryService, PrintService, DriedService]
})

export class Fd3Component implements OnInit {

  public title: string;
  public fd3s: FD3[];
  public dried: any = {};
  public convertToExcel: any = {};
  public convertToExcell: any = {};
  public alertMessage;
  public cfName;
  public wName;
  public total_qq = 0;
  public total_kg = 0;
  public pipe = new DatePipe('es-HN');
  public inventory: any = {};

  public fd3Data: FD3[] = [];
  public limit = 100;
  public page = 0;
  public harvests = ['2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023'];
  public form: FormGroup;
  public countFD3 = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private _router: Router,
    private _fd3Service: FD3Service,
    public dialog: MatDialog,
    private _printService: PrintService,
    private excelService: ExcelService,
    private _inventoryService: InventoryService,
    private _driedService: DriedService,
    private _fb: FormBuilder,
    private _userService: UserService
  ) {
    this.title = 'Lista de Exportación';
    this.inventory = {} as Inventory;
    this.convertToExcel = {} as FD3;
    this.dried = {} as Dried;
  }

  ngOnInit() {
    const harvest = this._userService.getIdentity().company.harvest;
    console.log('currrent harvest: ', harvest);
    this.createForm();
    this.form.get('harvest').setValue(harvest);
    this.getDataWithFilters();
    this.getSum();
    // this.getFD3s();
  }

  getSum(): void {
    this._fd3Service.getSumPerHarves().subscribe(res => {
      console.log('sum fd3 res: ', res);
      this.total_kg = res.total_kg_val;
      this.total_qq = res.total_qq_val;
    }, err => console.error('Error al obtener sumas fd3 del periodo: ', err));
  }

  searchFD3s(): void {
    this.page = 0;
    this.paginator.firstPage();
    this.getDataWithFilters();
  }

  /* getFD3s() {
    this._fd3Service.getAll().subscribe((res) => {
      this.fd3Data = res;
    }, error => console.error('Error al obtener datos: ', error));
  } */

  getDataWithFilters(): void {
    const params = this.form.getRawValue();
    params['skip'] = this.page * this.limit;
    params['limit'] = this.limit;
    Object.keys(params).forEach(key => {
      const current = params[key];
      if (current === '' || current === null || current === undefined) delete params[key];
    });
    console.log('params: ', params);
    this._fd3Service.getFd3sWithFilters(params).subscribe((res) => {
      console.log('res: ', res);
      this.fd3Data = res.items;
      this.countFD3 = res.count;
    }, err => console.error('Error al obtener fd3 con filtros: ', err));
  }

  changePage(paginator: MatPaginator) {
    console.log('Event: ', paginator);
    this.limit = paginator.pageSize;
    this.page = paginator.pageIndex;
    const tableRef = window.document.getElementById('matTablePayments');
    tableRef !== null ? tableRef.scrollTo(0, 0) : console.log('tableRefNull', tableRef);
    this.getDataWithFilters();
  }

  createForm(): void {
    this.form = this._fb.group({
      harvest: [''],
      date_in: [''],
      date_out: ['']
    });
  }

  // * --------------------------------------------------------------------------
  openDialog() {
    const dialogRef = this.dialog.open(Fd3AddComponent, {
      width: '750px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getDataWithFilters();
    });
  }

  print(id) {
    this._printService.getFd3(id).then(
      (res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      })
      .catch(err => console.log('Error', err));
  }

  getPFD3(fd3) {
    this._fd3Service.getFD3(fd3._id).subscribe(
      list => {
        this.convertToExcel = list as FD3[];
        const result: any[] = [];
        let limit = 0;
        this.convertToExcel.weight_notes.forEach((elem, index) => {
          limit++;
          const match = result.find((r: any) => r.coffeefarmer.toString() === elem.coffeefarmer.toString());
          if (match) {
            elem.total_net_qq = match.total_net_qq + this.convertToExcel.qqForWn[index];
            Object.assign(match, elem);
          } else {
            elem.total_net_qq = this.convertToExcel.qqForWn[index];
            result.push(elem);
          }
        });

        if (limit === this.convertToExcel.weight_notes.length) {
          Object.assign(this.convertToExcel, { consolidate: result });
          this.convertToExcel.consolidate.forEach((element, i) => {

            const rows = {
              Total_Neto_qq: element.total_net_qq,
              Total_kg: (element.total_net_qq * 100) / 2.2046,
              Productor: element.coffeefarmer.name
            };

            this.convertToExcel = Object.assign([], this.convertToExcel, { [i]: rows });
          });
          this.excelService.exportAsExcelFile(this.convertToExcel, 'FD3');
        }

      }, error => console.error('Error al obtener fd3 para exportar', error));
  }

  openDialogD(fd3) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: '¿Estas Seguro?',
        title: '',
        text: `¿Seguro que quieres eliminar <strong>${fd3.lot}</strong>?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.updateWn(fd3);
      }
    });
  }

  openDialogC(fd3: any) {
    const dialogRef = this.dialog.open(Fd3EditComponent, { width: '750px', data: fd3 });
    dialogRef.afterClosed().subscribe(result => {
      this.getDataWithFilters();
    });
  }

  exportAsXLSX(): void {
    const params = this.form.getRawValue();
    this.convertToExcell = {};
    params['skip'] = 0;
    params['limit'] = 25000;
    Object.keys(params).forEach(key => {
      const current = params[key];
      if (current === '' || current === null || current === undefined) delete params[key];
    });
    console.log('params to export : ', params);
    this._fd3Service.getFd3sWithFilters(params).subscribe(res => {
      console.log('res to export: ', res);
      const data: any = res.items as FD3[];
      data.forEach((element, i) => {
        const newDate = this.pipe.transform(element.date, 'shortDate');
        let count = 0;

        element.qqForWn.forEach((item, index) => {
          count++;
          let secada = 0;
          let nota = 0;

          if (element.dry_notes[index] === undefined) {
            secada = 0;
          } else {
            secada = element.dry_notes[index].cod;
          }
          if (element.weight_notes[index] === undefined) {
            this.wName = '';
            this.cfName = '';
            nota = 0;
          } else {
            nota = element.weight_notes[index].cod;
            this.cfName = element.weight_notes[index].coffeefarmer.name;
            this.wName = element.weight_notes[index].warehouse_name;
  /*                 if (element.weight_notes[index].coffeefarmer === undefined) {
              this.cfName = '';
            } else {
              this.cfName = element.weight_notes[index].coffeefarmer.name;
            }
            if (element.weight_notes[index].warehouse === undefined) {
              this.wName = '';
            } else {
              this.wName = element.weight_notes[index].warehouse.name;
            } */
          }

          const rows = {
            code: element.cod,
            Lote: element.lot,
            Fecha: newDate,
            Secada: secada,
            Nota_peso: nota,
            Productor: this.cfName,
            Bodega: this.wName,
            Total_neto_qq: item,
            Total_fd3_kg: element.total_kg,
            Total_fd3_qq: element.total_qq
          };

          // const convertToExcel = Object.assign([], this.convertToExcell, { [index]: rows });
          // this.convertToExcell = convertToExcel;
          const convertToExcel = _.concat(this.convertToExcell, rows);
          this.convertToExcell = convertToExcel;
        });
      });
      this.excelService.exportAsExcelFile(this.convertToExcell, 'all_fd3');
    });
  }

  updateWn(fd3) {
    let limit = 0;
    fd3.weight_notes.forEach((item, index) => {
      limit++;
      const total_qq = fd3.qqForWn[index] + fd3.restForWn[index];
      this._inventoryService.getInventory(item).subscribe(response => {
        if (total_qq === response.total_net_qq) {
          this.inventory.fd3 = false;
        } else {
          this.inventory.fd3 = true;
        }
        console.log(response.total_net_qq);
        this.inventory.total_net_fd3 = total_qq;
        this._inventoryService.editInventory(item, this.inventory).subscribe(
          res => {
            if (!res) {
              this.alertMessage = 'Error en el servidor';
            } else {
              this.alertMessage = 'Nota de peso actualizada correctamente';
            }
          }
        );
      });
    });
    if (limit === fd3.weight_notes.length) {
      this.update_dry(fd3);
    }
  }

  update_dry(fd3) {
    let limit = 0;
    fd3.dry_notes.forEach((item, index) => {
      limit++;
        this.dried.fd3 = false;
      this._driedService.editDried(item, this.dried).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Nota de Secado actualizada correctamente';
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );
    });
    if (limit === fd3.dry_notes.length) {
      this.deleteFD3(fd3);
    }
  }

  deleteFD3(fd3) {
    this._fd3Service.deleteFD3(fd3._id).subscribe(
      response => {
        if (!response) {
          alert('Error en el servidor');
        } else {
          this.getDataWithFilters();
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }
}
