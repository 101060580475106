import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Settlement } from '../models/settlement';
import { query } from '@angular/core/src/render3';

@Injectable()
export class SettlementService {
  public url: string;


  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getSettlements(): Observable<any> {
    return this.http.get(`${this.url}/settlements/list`);
  }
  getSettlementsWithFilter(filters: any): Observable<any> {
    return this.http.get(`${this.url}/settlements/list-filter`, {params: filters});
  }
  getSettlementsByHarvest(harvest: string, skip: number): Observable<any> {
    const data = harvest.replace('/', '-');
    return this.http.get(`${this.url}/settlements/list-per/${data}/${skip}`);
  }
  getSettlementsG(): Observable<any> {
    return this.http.get(`${this.url}/settlements/listG`);
  }
  getSumSettlement(): Observable<any> {
    return this.http.get(`${this.url}/settlements/sum`);
  }
  getTotalOpeningDiscounted(id): Observable<any> {
    return this.http.get(`${this.url}/settlements/total-discounted/${id}`);
  }
  getCountByHarvest(harvest: string): Observable<any> {
    const splitted = harvest.replace('/', '-');
    return this.http.get(`${this.url}/settlements/count-harvest/${splitted}`);
  }
  getSumSettlementByHarvest(harvest: string): Observable<any> {
    const splitted = harvest.replace('/', '-');
    return this.http.get(`${this.url}/settlements/sum-harvest/${splitted}`);
  }
  getHarvests(): Observable<any> {
    return this.http.get(`${this.url}/settlements/harvests`);
  }
  addSettlement(settlement: Settlement): Observable<any> {
    return this.http.post(`${this.url}/settlements/new`, settlement);
  }
  addSettlements(settlement: Settlement): Observable<any> {
    return this.http.post(`${this.url}/settlements/news`, settlement);
  }
  deleteSettlement(id): Observable<any> {
    return this.http.delete(`${this.url}/settlements/${id}`);
  }
  getCode(code): Observable<any> {
    return this.http.get(`${this.url}/settlements/code/${code}`);
  }
  updateSettlement(id, settlement: Settlement): Observable<any> {
    return this.http.put(`${this.url}/settlements/put/${id}`, settlement);
  }
  updateSettlementPending(id, settlement: Settlement): Observable<any> {
    return this.http.put(`${this.url}/settlements/edit/${id}`, settlement);
  }
  getSettlementPerCoffeeFarmer(coffeefarmer): Observable<any> {
    return this.http.get(`${this.url}/settlements/coffeefarmer/${coffeefarmer}`);
  }
  getSettlementPerCoffeeFarmerTqq(params): Observable<any> {
    return this.http.get(`${this.url}/settlements/coffeefarmerqq`, {params: params});
  }
  getSettlementPerAdvancePayment(advancePayment): Observable<any> {
    return this.http.get(`${this.url}/settlements/advancePayment/${advancePayment}`);
  }
  getSettlementPerWeightNotes(weightnotes): Observable<any> {
    return this.http.get(`${this.url}/settlements/weightnotes/${weightnotes}`);
  }
  getSumHarvest(harvest): Observable<any> {
    const query_ = { harvest: harvest };
    return this.http.get(`${this.url}/settlements/sum-per-harvest`, {params: query_});
  }
  getSettlement(id: string) {
    return this.http.get(`${this.url}/settlements/${id}`);
  }
  getFieldsNull(field: any): Observable<any> {
    return this.http.get(`${this.url}/settlements/field-null/${field}`);
  }
/*
  addSettlement(settlement: Settlement) {
    const params = JSON.stringify(settlement);
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this._http.post(`${this.url}/settlements/new`, params, { headers: headers })
      .pipe(map(res => res.json()));
  }
  getSettlements(token) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    const options = new RequestOptions({headers: headers});
    return this._http.get(`${this.url}/settlements/list`, { headers: headers })
                     .pipe(map(res => res.json()));
  }

  getSumSettlement(token) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    const options = new RequestOptions({ headers: headers });
    return this._http.get(`${this.url}/settlements/sum`, { headers: headers })
      .pipe(map(res => res.json()));
  }



  deleteSettlement(id: string) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    const options = new RequestOptions({ headers: headers });
    return this._http.delete(`${this.url}/settlements/${id}`, options)
                     .pipe(map(res => res.json()));
  }*/

}
