import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit {
  public alert = JSON.parse(JSON.stringify({}));
  public textElement: HTMLElement;
  public modalBody = {
    header: 'Eliminar',
    title: '¿Desea eliminar el registro?',
    text: '"OK" para eliminar ó "Cancelar" para cancerlar la acción.'
  };
  public alerts = {
    warning: { icon: 'report_problem', color: '#ffc107' },
    error: { icon: 'error', color: '#dc3545' },
    info: { icon: 'info', color: '#17a2b8' }
  };
  constructor(
    public dialogRef: MatDialogRef<DeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {
    this.textElement = document.getElementById('textContent');
    this.textElement.innerHTML = this.data.text;
    this.alert = this.data.hasOwnProperty('alert') ? this.alerts[this.data.alert] : this.alerts.warning;
  }
  onCloseConfirm() {
    this.dialogRef.close('confirm');
  }
  onCloseCancel() {
    this.dialogRef.close('cancel');
  }

}
