import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { startWith } from 'rxjs/operators/startWith';
import { map } from 'rxjs/operators/map';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { ProductorService } from '../../../services/productor.service';
import { WarehouseService } from '../../../services/warehouse.service';
import { InventoryService } from '../../../services/inventory.service';
import { CertificationService } from '../../../services/certification.service';
import { OriginService } from '../../../services/origin.service';
import { Inventory } from '../../../models/inventory';
import { Certification } from '../../../models/certification';
import { LiquidationComponent } from '../liquidation/liquidation.component';

@Component({
  selector: 'app-weight-notes-edit',
  templateUrl: './weight-notes-edit.component.html',
  styleUrls: ['./weight-notes-edit.component.css'],
  providers: []
})
export class WeightNotesEditComponent implements OnInit {

  public titulo: string;
  public productor: any = {};
  public warehouse: any = {};
  public inventory: any = {};
  public origin: any = {};
  public cert: any = {};
  public certs: Certification[];
  public identity;
  public token;
  public url: string;
  public alertMessage;
  public tara: number;

  public sacos: number;
  public tsacos: number;
  public desc: number;
  public descuentoHumedad: number;
  public valor: number;
  public CFname;
  public WHname;
  public OGname;
  public certif = [];
  public desc_h;


  myControl: FormControl = new FormControl();
  myControlCF: FormControl = new FormControl();
  myControlOrigin: FormControl = new FormControl();
  public availableWarehouse = [];
  public availableCoffeeFarmer = [];
  public availableOrigin = [];
  public filteredOptions: Observable<any[]>;
  public filteredOptionsCF: Observable<any[]>;
  public filteredOptionsOrigin: Observable<any[]>;
  public selectedWarehouse = null;
  public selectedCoffeeFarmer = null;
  public selectedOrigin = null;
  public selectedName = '';
  public selectedNameCF = '';
  public selectedNameOrigin = '';

  interestFormGroup: FormGroup;
  interests: any;
  selected: any;

  states = [
    { value: 'Seco', viewValue: 'Seco' },
    { value: 'Uva', viewValue: 'Uva' },
    { value: 'Mojado', viewValue: 'Mojado' },
    { value: 'Humedo', viewValue: 'Humedo' }
  ];

  receptions = [
    { value: 'Pergamino', viewValue: 'Pergamino' },
    { value: 'Uva', viewValue: 'Uva' },
    { value: 'Oro Exportable', viewValue: 'Oro Exportable' }
  ];

  isDisabled = false;
  form = new FormGroup({
    'cb1': new FormControl({ value: 0, disabled: false }),
    'cb2': new FormControl({ value: 0, disabled: false })
  });

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _productorService: ProductorService,
    private _warehouseService: WarehouseService,
    private _inventoryService: InventoryService,
    private _originService: OriginService,
    public dialog: MatDialog,
    private _certificationService: CertificationService,
        private formBuilder: FormBuilder,
    private _fb: FormBuilder,
    fb: FormBuilder
  ) {
    this.titulo = 'Editar Nota de Peso';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.inventory = {} as Inventory;
    this.url = GLOBAL.url;
    this.form.get('cb1').disable();
    this.form.get('cb2').disable();
  }

  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());

  ngOnInit() {
    this.interestFormGroup = this.formBuilder.group({
      interests: this.formBuilder.array([])
    });
    this.getInventory();
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.getCertifications();
    /*------------------Autocompletes--------------------*/
    this._warehouseService.getWarehouses().subscribe(warehouse => {
      this.availableWarehouse = warehouse;
    });

    this._productorService.getProductores().subscribe(productor => {
      this.availableCoffeeFarmer = productor;
    });

    this._originService.getOrigins().subscribe(origin => {
      this.availableOrigin = origin;
    });

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filter(val))
      );

    this.filteredOptionsCF = this.myControlCF.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterCF(val))
      );

    this.filteredOptionsOrigin = this.myControlOrigin.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterOrigin(val))
      );

    /*------------------/Autocompletes--------------------*/
  }
  /*------------------Autocompletes--------------------*/
  openDialog() {
    this.dialog.open(LiquidationComponent);
  }
  filter(val: any): any[] {
    return this.availableWarehouse.filter(warehouse =>
      warehouse.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
  }
  filterCF(val: any): any[] {
    return this.availableCoffeeFarmer.filter(productor =>
      productor.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
  }
  filterOrigin(val: any): any[] {
    return this.availableOrigin.filter(origin =>
      origin.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
  }

  /*------------------/Autocompletes--------------------*/

  submitForm() {
    this._inventoryService.addInventory(this.inventory).subscribe(
      response => {
        this.alertMessage = 'Registro creado correctamente';
        this._router.navigate(['/notas-de-peso']);
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          this.alertMessage = body.message;
          console.log(error);
        }
      }
    );
  }

  enableSlideToggle() {
    this.form.get('cb1').enable();
    this.form.get('cb2').enable();
  }
  getCertifications() {

    this._certificationService.getCertifications().subscribe(
      (res) => {
        if (!res) {
          this._router.navigate(['/']);
        } else {
          this.interests = res.map(element => element.name);
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  calculateGross(newVal) {
    this.sacos = newVal;
    this.inventory.gross_total_lb = this.sacos * 100;
  }

  calculate(desc) {
    this.descuentoHumedad = this.desc / 100;
    this.inventory.total_discount = this.sacos * this.descuentoHumedad;
    this.inventory.tare = this.sacos;
    this.inventory.total_net_lb = this.inventory.gross_total_lb - this.inventory.total_discount - this.inventory.tare;
    this.inventory.total_net_qq = this.inventory.total_net_lb / 100;
  }

  calculateVB(valor) {
    if (this.valor > 0) {
      this.inventory.cost_per_beneficiary = this.inventory.total_net_qq * this.valor;
    } else {
      this.inventory.cost_per_beneficiary = 0;
    }
  }

  getInventory() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._inventoryService.getInventory(id).subscribe(
        response => {
          if (!response) {
            this._router.navigate(['/']);
          } else {
            this.inventory = response;
            this.CFname = this.inventory.coffeefarmer.name;
            this.WHname = this.inventory.warehouse.name;
            this.OGname = this.inventory.origin.name;
            this.certif = this.inventory.certifications.certs;
            this.desc_h = this.inventory.inventoryDetails[0].desc_humedad;
            console.log(this.inventory);
            console.log(this.certif);
            console.log(this.desc_h);
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            console.log(error);
          }
        }
      );
    });
  }

  onChange(event) {
    const interests = <FormArray>this.interestFormGroup.get('interests') as FormArray;

    if (event.checked) {
      interests.push(new FormControl(event.source.value));
    } else {
      const i = interests.controls.findIndex(x => x.value === event.source.value);
      interests.removeAt(i);
    }
  }
  onSubmit() {
    this.inventory.certification = this.interestFormGroup.value;
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._inventoryService.editInventory(id, this.inventory).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Nota de Peso actualizada correctamente';
            // this.origin = response.origin;
            // console.log(this.origin);
            this._router.navigate(['/notas-de-peso']);
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );

    });
  }

}
