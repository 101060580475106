import { Observable } from 'rxjs/Observable';
import { WarehouseService } from '../../../../services/warehouse.service';
import { ProductorService } from '../../../../services/productor.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { InventoryService } from '../../../../services/inventory.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-weight-notes-select',
  templateUrl: './weight-notes-select.component.html',
  styleUrls: ['./weight-notes-select.component.css']
})
export class WeightNotesSelectComponent implements OnInit, OnDestroy {
  public dataSource = new TableVirtualScrollDataSource<any>();
  public highlightedRows = [];
  public availableCoffeefarmers: any[];
  public filteredCoffeefarmers: any[];
  public displayedColumns = ['check', 'cod', 'date', 'total_net_sett', 'net_qq'];
  public form: FormGroup;
  public infoColumns;
  constructor(
    private fb: FormBuilder,
    private coffeefarmerService: ProductorService,
    private weightNotesService: InventoryService
  ) { }

  ngOnInit() {
    this.createForm();
    this.listenerFormValues();
    this.getData();
  }

  createForm(): void {
    this.form = this.fb.group({
      coffeefarmer:     [''],
      coffeefarmerName: [''],
      total_net:        [0]
    });
  }

  listenerFormValues(): void {
    this.form.get('coffeefarmerName').valueChanges.subscribe((value: string) => {
      this.filteredCoffeefarmers = this.availableCoffeefarmers
        .filter((field: any) => field.name
          .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value
            .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });

    this.form.get('coffeefarmer').valueChanges.subscribe(id => {
      this.getDataById(id);
    });
  }

  getData(): void {
    this.coffeefarmerService.getProductores().subscribe(res => {
      this.availableCoffeefarmers = res;
      this.filteredCoffeefarmers = res;
    }, err => console.error(`Error al obtener data`, err));
  }

  getDataById(id): void {
    this.weightNotesService.getInventoryPerCoffeeFarmerWithoutPopulation(id).subscribe(res => {
      console.log('Data obtenida: ', res);
      this.dataSource.data = res;
      this.dataSource.data.forEach(item => {
        item['net_qq'] = item.total_net_sett;
        item['highlighted'] = false;
      });
    }, err => console.error(`Error al obtener data de`, err));
  }

  public getSum(): void {
    this.form.get('total_net').setValue(_.sumBy(this.highlightedRows, 'net_qq'));
  }

  public highlight(index): void {
    console.log('index highlight: ', index);
    this.dataSource.data[index]['highlighted'] = !this.dataSource.data[index]['highlighted'];
    this.selectRow(this.dataSource.data[index]);
  }

  public selectRow(row) {
    this.highlightedRows = row.highlighted === true ?
      this.highlightedRows.concat(row) :
      this.highlightedRows.filter(item => item._id !== row._id);
    this.getSum();
  }

  ngOnDestroy(): void {
    const total = _.sumBy(this.highlightedRows, function (item) {
      return item.net_qq > item.total_net_sett ? item.total_net_sett : item.net_qq;
    });
    console.log('el total fue: ', total);
  }
}
