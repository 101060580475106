import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Production } from '../models/production';

@Injectable()
export class ProductionService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getProductions(): Observable<any> {
    return this.http.get(`${this.url}/productions/list`);
  }
  getProductionSummaries(): Observable<any> {
    return this.http.get(`${this.url}/productions/summaries`);
  }
  getInvProductions(filters:any): Observable<any> {
    return this.http.get(`${this.url}/productions/list-inv`, { params: filters });
  }
  getGlobalProductions(): Observable<any> {
    return this.http.get(`${this.url}/productions/listG`);
  }
  addProduction(production: Production): Observable<any> {
    return this.http.post(`${this.url}/productions/new`, production);
  }
  getProduction(id): Observable<any> {
    return this.http.get(`${this.url}/productions/${id}`);
  }
  editProduction(id, production): Observable<any> {
    return this.http.put(`${this.url}/productions/${id}`, production);
  }
  deleteProduction(id): Observable<any> {
    return this.http.delete(`${this.url}/productions/${id}`);
  }

  updateProduction(id: string, fields: any): Observable<any> {
    return this.http.put(`${this.url}/productions/${id}`, fields);
  }

}
