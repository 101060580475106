import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Asisstance } from '../models/asisstance';

@Injectable()
export class AsisstanceService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getAsisstances(): Observable<any> { return this.http.get(`${this.url}/assists/list`); }
  create(asisstance: Asisstance): Observable<any> { return this.http.post(`${this.url}/assists/new`, asisstance); }
  getAsisstance(id): Observable<any> {return this.http.get(`${this.url}/assists/${id}`); }
  update(id, asisstance): Observable<any> {return this.http.put(`${this.url}/assists/${id}`, asisstance); }

}
