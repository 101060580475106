import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { MatSort } from '@angular/material';

import { BrokerService } from '../../../services/broker.service';
import { SaleService } from '../../../services/sale.service';
import { Broker } from '../../../models/broker';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { BrokerAddComponent } from '../broker-add/broker-add.component';
import { BrokerEditComponent } from '../broker-edit/broker-edit.component';

@Component({
  selector: 'app-brokers',
  templateUrl: './brokers.component.html',
  styleUrls: ['./brokers.component.css'],
  providers: [BrokerService, SaleService]
})
export class BrokersComponent implements OnInit {

  public titulo: string;
  public brokers: Broker[];
  public broker = [];
  public alertMessage;
  closeResult: string;

  displayedColumns: string[] = ['cod', 'name', 'address', 'email', 'country', 'telephone', 'action'];
  highlightedRows = [];
  public dataSource = new TableVirtualScrollDataSource<Broker>();
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }
  constructor(
    private _brokerService: BrokerService,
    public dialog: MatDialog
  ) {
    this.titulo = 'Brokers';
  }

  ngOnInit() {
    this.getBrokers();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog() {
    const dialogRef = this.dialog.open(BrokerAddComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getBrokers();
    });
  }

  openDialogEdit(broker: any) {
    const dialogRef = this.dialog.open(BrokerEditComponent, { width: '550px', data: broker });
    dialogRef.afterClosed().subscribe(result => {
      this.getBrokers();
    });
  }

  openDialogD(broker) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar broker',
        title: '',
        text: `¿Seguro que quieres eliminar a <br><strong>${broker.name}</strong>?`,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.delete(broker._id);
      }
    });
  }

  getBrokers() {
    this._brokerService.getBrokers().subscribe(
      (res) => {
        this.dataSource.data = res as Broker[];
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  delete(id) {
    this.alertMessage = 'Registo eliminado';
    this._brokerService.deleteBroker(id).subscribe(
      response => {
        if (!response) {
          alert('Error en el servidor');
        } else {
          this.getBrokers();
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

}
