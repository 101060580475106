import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { WarehouseService } from '../../../services/warehouse.service';
import { User } from '../../../models/user';
import { Warehouse } from '../../../models/warehouse';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-warehouse-add',
  templateUrl: './warehouse-add.component.html',
  styleUrls: ['./warehouse-add.component.css'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class WarehouseAddComponent implements OnInit {

  public titulo: string;
  public warehouse: any = {};
  public identity;
  public token;
  public url: string;
  public alertMessage;
  public mask = ['#', '#', '#', '#'];
  public harvest;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _warehouseService: WarehouseService,
    public dialogRef: MatDialogRef<WarehouseAddComponent>,
  ) {
    this.titulo = 'Agregar Productor';
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.url = GLOBAL.url;
    this.warehouse = {} as Warehouse;
  }

  ngOnInit() {
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.harvest = this.identity.harvest;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  submitForm() {
    this.warehouse.harvest = this.harvest;
    this._warehouseService.addWarehouse(this.warehouse).subscribe(
      response => {
        this.alertMessage = 'La bodega se ha creado correctamente';
        // this._router.navigate(['/bodegas']);
        // location.reload();
        this.onCancelClick();
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          this.alertMessage = body.message;
          console.log(error);
        }
      }
    );
  }

}
