import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BrokerService } from '../../../services/broker.service';
import { Broker } from '../../../models/broker';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-broker-edit',
  templateUrl: './broker-edit.component.html',
  styleUrls: ['./broker-edit.component.css'],
  providers: [BrokerService]
})
export class BrokerEditComponent implements OnInit {

  form = this.fb.group({
    name: null,
    address: null,
    email: null,
    country: null,
    telephone: null
  });

  public titulo: string;
  public broker: any = {};
  public alertMessage;
  public id;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private fb: FormBuilder,
    private _brokerService: BrokerService,
    public dialogRef: MatDialogRef<BrokerEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.titulo = 'Editar Broker';
    this.broker = {} as Broker;
  }

  ngOnInit() {
    this.getBroker();
    this.id = this.data._id;
    this.broker = this.data;
    this.form = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      email: ['', Validators.required],
      country: ['', Validators.required],
      telephone: ['', Validators.required]
    });
  }

  getBroker() {
    this._brokerService.getBroker(this.data._id).subscribe(
      response => {
        this.broker = response.broker;
        this.form.patchValue(this.broker);
      }
    );
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  save() {
    this.broker = this.form.value;
    this._brokerService.editBroker(this.data._id, this.broker).subscribe(
       response => {
         this.onCancelClick();
       },
       error => {
         const errorMessage = <any>error;
         if (errorMessage != null) {
           const body = JSON.parse(error._body);
           this.alertMessage = body.message;
           console.log(error);
         }
       }
     );

  }

}
