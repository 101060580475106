import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { TasterService } from '../../../../services/taster.service';

import { Taster } from '../../../../models/taster';

@Component({
  selector: 'app-taster-add',
  templateUrl: './taster-add.component.html',
  styleUrls: ['./taster-add.component.css']
})
export class TasterAddComponent implements OnInit {

  public form: FormGroup;
  constructor(
    private _tasterService: TasterService,
    private _sidebarManager: SidebarManagerService,
    private _fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.createForm();
  }

  addTaster(): void {
    const taster: Taster = this.form.getRawValue();
    this._tasterService.addTaster(taster).subscribe(res => {
      this._sidebarManager.close('taster-add', true);
    }, err => console.error('Error al agregar catador', err));
  }

  createForm(): void {
    this.form = this._fb.group({
      name:       ['', Validators.required],
      email:      ['', Validators.required],
      telephone:  ['', Validators.required]
    });
  }

}
