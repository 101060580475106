import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: number, decimals: number = 2): any {
    if (value !== undefined && value !== null) {
      const temp = value.toString().split('.');
      const int = temp[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const decimal = temp.length > 1 ? temp[1].slice(0, decimals) : '00';
      return `${int}.${decimal}`;
    } else {
      console.log('undefined value pipe: ', value);
      return 0;
    }
  }

}
