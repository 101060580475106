import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material';
import * as _ from 'lodash';

import { PrintService } from '../../../services/print.service';
import { SeatService } from '../../../services/seat.service';
import { UserService } from '../../../services/user.service';
import { GLOBAL } from '../../../services/global';

import { ClosePeriodComponent } from '../period-closings/close-period/close-period.component';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';

import { Seat } from '../../../models/seat';
@Component({
  selector: 'app-seating',
  templateUrl: './seating.component.html',
  styleUrls: ['./seating.component.css'],
  providers: [UserService, SeatService, PrintService]
})
export class SeatingComponent implements OnInit {

  public title = 'Asientos Contables';
  public identity;
  public alertMessage = '';
  public page = 0;
  public limit = 100;
  public form: FormGroup;
  public loading = false;
  public tableRef: HTMLElement;
  public countSeat = 0;
  public currency;
  public tagColor = {
    'Abono': 'primary',
    // 'Gasto': 'danger',
    'Anticipo': 'success',
    'Liquidación': 'warning',
    'Varios': 'secondary',
  };
  public currentPeriod: number;
  public status = ['Activo', 'Anulado'];
  public tags = ['Anticipo', 'Liquidación', 'Abono', 'Varios'];

  public periods: number[] = [];
  public seatsData: Seat[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private _seatService: SeatService,
    private _userService: UserService,
    private _printService: PrintService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.identity = this._userService.getIdentity();
  }

  ngOnInit() {
    this.createForm();
    this.currentPeriod = this.identity.company.settings.period;
    this.currentPeriod = this.currentPeriod ? this.currentPeriod : 1;
    console.log('currentPeriod: ', this.currentPeriod);
    this.form.get('period').setValue(this.currentPeriod);
    this.getSeatsWithFilters();
    this.paginator._intl.itemsPerPageLabel = 'Asientos por página';
    this.identity = this._userService.getIdentity();
    for (let i = 1; i <= this.currentPeriod; i ++) this.periods.push(i);
    this.currency = GLOBAL.currency;
  }

  getSeatsWithFilters(): void {
    const filters = this.getFilterData();
    this.loading = true;
    this._seatService.getSeatsWithFilters(filters).subscribe(res => {
      this.loading = false;
      this.seatsData = res.items as Seat[];
      this.countSeat = res.count;
      const f = this.seatsData.filter(current =>
        current.SeatCategory.some(category => category.category === null));
      console.error('Asientos con cuentas inexistentes', f);
      const invalid = this.seatsData.filter(current => current.total_credit !== current.total_debit);
      console.error('Totales invalidos: ', invalid);
    }, error => {
        console.error('error al obtener los asientos: ', error);
        this.loading = false;
        this.alertMessage = 'No se puedieron obtener los asientos';
      }
    );
  }

  getFilterData(): any {
    const filters = JSON.parse(JSON.stringify(this.form.getRawValue()));
    this.loading = true;
    Object.keys(filters).forEach((key) => {
      if (filters[key] == null || filters[key] === '' || (key === 'harvest' && filters[key] === 'Todas')) {
        delete filters[key];
      }
    });
    filters['skip'] = this.page * this.limit;
    filters['filter'] = Object.keys(filters).length > 2 ? false : true;
    filters['limit'] = this.limit;
    filters['status'] = filters.status === 'Activo' ? true : false;
    console.log('filters : ', filters);
    return filters;
  }

  changePage(paginator: MatPaginator) {
    console.log('Event: ', paginator);
    this.limit = paginator.pageSize;
    this.page = paginator.pageIndex;
    const tableRef = window.document.getElementById('matTableSeats');
    tableRef !== null ? tableRef.scrollTo(0, 0) : console.log('tableRefNull', tableRef);
    this.getSeatsWithFilters();
  }

  openDialogClosePeriod() {
    const dialogRef = this.dialog.open(ClosePeriodComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getSeatsWithFilters();
    });
  }

  openDialogD(seat: Seat) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Anular asiento',
        text: `¿Seguro que quieres anular el asiento <strong>${seat.abbreviation}-${seat.code}</strong>?`,
        alert: 'warning',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        seat.status = false;
        this._seatService.editSeat(seat._id, seat).subscribe(response => {
            this.getSeatsWithFilters();
          }, error => console.error('Error al anular asiento: ', error)
        );
      }
    });
  }

  approveSeat(seat: Seat): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '400px',
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      data: {
        header: `Aprobar asiento`,
        text: `¿Seguro que quieres aprobar el asiento <strong>${seat.abbreviation}-${seat.code}</strong>?`,
        alert: 'info'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        seat.approve = true;
        this._seatService.editSeat(seat._id, seat).subscribe(response => {
          console.log('Asiento aprobado con exito');
        }, err => console.error('Error al cambiar estado: ', err));
      }
    });
  }

  print(seat) {
    console.log('seat to print: ', seat._id);
    this._printService.getSeat(seat._id);
  }

  // ------------------------------------------------------------------
  clearForm(): void {
    this.form.reset();
    this.page = 0;
    this.getSeatsWithFilters();
  }

  createForm(): void {
    this.form = this.fb.group({
      code:         [''],
      description:  [''],
      date_in:      [''],
      date_out:     [''],
      status:       ['Activo'],
      period:       [''],
      tag:          ['']
    });
  }

  filterData(): void {
    this.page = 0;
    this.paginator.firstPage();
    this.getSeatsWithFilters();
  }

}
