import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';


import { UserService } from '../../services/user.service';
import { BankService } from '../../services/bank.service';
import { BankModel } from '../../models/bankModel';


@Component({
  selector: 'app-bank-edit',
  templateUrl: './bank-edit.component.html',
  styleUrls: ['./bank-edit.component.css'],
  providers: [UserService, BankService]
})

export class BankEditComponent implements OnInit {

  public titulo: string;
  public bank: any = {};
  public identity;
  public alertMessage;
  public mask = ['(', '5', '0', '4', ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _bankService: BankService
  ) {
    this.titulo = 'Editar Banco';
    this.identity = this._userService.getCompany();
    this.bank = {} as BankModel;
  }

  ngOnInit() {
    this.getBank();
  }


  getBank() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._bankService.getBank(id).subscribe(
        response => {

          console.log(response.bank);
          if (!response) {
            this._router.navigate(['/']);
          } else {
            this.bank = response.bank;
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            console.log(error);
          }
        }
      );
    });
  }
  onSubmit() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._bankService.editBank(id, this.bank).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Banco actualizado correctamente';
            this._router.navigate(['/bancos']);
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );

    });
  }

}


