import { UserService } from './../../../services/user.service';
import { GLOBAL } from '../../../services/global';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PrintService } from '../../../services/print.service';
import { SeatService } from '../../../services/seat.service';
import * as _ from 'lodash';
import { ExcelService } from '../../../services/excel.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-trial-balance',
  templateUrl: './trial-balance.component.html',
  styleUrls: ['./trial-balance.component.css'],
  providers: [ExcelService, SeatService, PrintService]
})
export class TrialBalanceComponent implements OnInit, OnDestroy {
  public title = 'Balance de Comprobacion';
  public form: FormGroup;
  public alertMessage = '';
  public categories: any[];
  public dataTrialBalance = JSON.parse(JSON.stringify({}));
  public trialBalanceData = [];
  public periods: number[] = [];
  public convertToExcel = [];
  public currentPeriod = 0;
  public currency;

  enabledExcel = false;

  constructor(
    private fb: FormBuilder,
    private _userService: UserService,
    private printService: PrintService,
    private seatService: SeatService,
    private excelService: ExcelService,
  ) {
    this.currentPeriod = this._userService.getIdentity().company.settings.period;
    this.currentPeriod = this.currentPeriod ? this.currentPeriod : 1;
    for (let i = 1; i <= this.currentPeriod; i ++) this.periods.push(i);
  }

  ngOnInit() {
    this.currency = GLOBAL.currency;
    this.form = this.fb.group({
      date_1: [''],
      date_2: [''],
      period: [this.currentPeriod]
    });
  }

  getTrialBalance(): void {
    const params = this.setDataFilter();
    console.log('params: ', params);
    this.seatService.getTrialBalance(params).subscribe(response => {
      console.log('trial balance: ', response);
      this.dataTrialBalance = response;
      this.dataTrialBalance.categories = _.orderBy(this.dataTrialBalance.categories, (item) => {
        return String(item.code).substring(0, 2);
      }, 'desc');
      // console.log('grouped: ', this.dataTrialBalance.categories);
      this.dataTrialBalance.categories.sort((n1, n2) =>
        Number(String(n1.code).substring(0, 1)) - Number(String(n2.code).substring(0, 3)));
      this.categories = response.categories;
    }, err => console.error('Error al obtener balance de comprobacion: ', err));
  }

  printTrialBalance(): void {
    const date2 = new Date(this.form.get('date_2').value);
    date2.setDate(date2.getDate() + 1);
    date2.setSeconds(date2.getSeconds() - 1);
    const params = {
      date_out: date2
    };
    this.printService.getTrialBalance(params).then((result: any) => {
      console.log('result: ', result);
      if (result !== null) {
        this.alertMessage = '';
        const blob = new Blob([result], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      } else {
        this.alertMessage = 'No se encontraron asientos a la fecha';
      }
      })
      .catch(err => console.log('Error', err));
  }

  exportAsXLSX(): void {
      const array: any = [];
      this.categories.forEach((element, i) => {

            const row = {
              Código: +element.code,
              Cuenta: element.name,
              Crédito: element.credit,
              Débito: element.debit,
              Deudor: element.debitBalance,
              Acreedor: element.creditBalance
            };

            array.push(row);
      });
      console.log(array);
      this.convertToExcel = array;

      this.excelService.exportAsExcelFile(array, 'Balance');
  }

  setDataFilter(): void {
    const params = this.form.getRawValue();
    console.log('form', params);
    Object.keys(params).forEach(key => {
      const current = params[key];
      if (current === '' || current === null || current === undefined) {
        delete params[key];
      } else {
        if (key === 'date_out') {
          const temp = new Date(current);
          temp.setHours(23, 59, 59);
          params[key] = temp;
        }
      }
    });
    return params;
  }

  goUp(): void {
    // window.document.getElementById('generalContent').scrollTo(0, 0);
  }

  ngOnDestroy(): void {
    // window.document.getElementById('generalContent').removeEventListener('scroll', this.scrollUpTree);
  }
}
