import { NgModule } from '@angular/core';

import { QARoutingModule } from './qa-routing.module';
import { DescriptorsModule } from './descriptors/descriptors.module';
import { LaboratoriesModule } from './laboratories/laboratories.module';
import { LocationsModule } from './locations/locations.module';
import { PhysicalsModule } from './physicals/physicals.module';
import { SamplesModule } from './samples/samples.module';
import { SensoriesModule } from './sensories/sensories.module';
import { TastersModule } from './tasters/tasters.module';

@NgModule({
  declarations: [
  ],
  imports: [
    QARoutingModule,
    DescriptorsModule,
    LaboratoriesModule,
    LocationsModule,
    PhysicalsModule,
    SamplesModule,
    SensoriesModule,
    TastersModule,
  ],
  exports: [ ],
})
export class QAModule { }
