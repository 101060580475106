import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material';
import * as _moment from 'moment';
import * as _ from 'lodash';

import { DriedService } from '../../../services/dried.service';
import { InventoryService } from '../../../services/inventory.service';
import { MachineService } from '../../../services/machine.service';
import { WarehouseService } from '../../../services/warehouse.service';

import { Dried } from '../../../models/dried';
import { Inventory } from '../../../models/inventory';
import { Router } from '@angular/router';
import { Machine } from '../../../models/machine';
import { Warehouse } from '../../../models/warehouse';

@Component({
  selector: 'app-dried-add',
  templateUrl: './dried-add.component.html',
  styleUrls: ['./dried-add.component.css'],
})
export class DriedAddComponent implements OnInit, AfterViewInit {

  public title = 'Agregar orden de secado';
  public form: FormGroup;
  public currentCapacity: any = '0';
  public totalSelected = 0;
  public alert = { message: '', color: 'info' };
  public tableRef: HTMLElement;

  public availableWarehouses: Warehouse[] = [];
  public warehousesFiltered: Warehouse[] = [];
  public availableMachines: Machine[] = [];
  public machinesFiltered: Machine[] = [];
  public next = false;
  public page1 = 0;
  public page2 = 0;
  public limit = 50;
  public direction = 'right';
  public indexSelected: any = {};

  public stepperParams = {
    'steps': ['Detalle', 'Notas de Peso', 'Notas Seleccionadas'],
    'step': 1
  };
  public availableWeightNotes: Inventory[] = [];
  public highlightedRows = [];
  public paginatedHighlightedRows = [];
  public lenData1 = 0;
  public containerList1: HTMLElement;
  public containerList2: HTMLElement;
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;

  constructor(
    private _warehouseService: WarehouseService,
    private _inventoryService: InventoryService,
    private _driedService: DriedService,
    private _machineService: MachineService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    // * total_qq_dry representa el valor qq restantes por secar.
    this.createForm();
    this.listenerFormValue();
    this.getDataFromServices();
    console.log('paginator1: ', this.paginator1);
  }

  ngAfterViewInit(): void {
    this.containerList1 = window.document.getElementById('containerList1');
    this.containerList2 = window.document.getElementById('containerList2');
    console.log('list1: ', this.containerList1);
    console.log('list2: ', this.containerList2);
  }

  private getDataFromServices() {
    this._warehouseService.getWarehouses().subscribe(warehouse => {
      this.availableWarehouses = warehouse as Warehouse[];
      this.warehousesFiltered = warehouse as Warehouse[];
    });

    this._machineService.getMachines().subscribe(machine => {
      this.availableMachines = machine as Machine[];
      this.machinesFiltered = machine as Machine[];
    });
  }

  private listenerFormValue(): void {
    this.form.valueChanges.subscribe(() => {
      if (
        this.form.get('date').valid &&
        this.form.get('hours').valid &&
        this.form.get('warehouse').valid &&
        this.form.get('machine').valid &&
        this.form.get('start_temp').valid &&
        this.form.get('final_temp').valid
        ) {
        this.next = true;
      } else {
        this.next = false;
      }
    });

    this.form.get('machine').valueChanges.subscribe((machineId: string) => {
      this._machineService.getMachine(machineId).subscribe(machine_ => {
        const machine: Machine = machine_.machine;
        console.log('Maquina obtenida: ', machine);
        this.currentCapacity = machine.capacity;
      });
    });

    this.form.get('warehouse').valueChanges.subscribe((warehouse) => {
      this.page1 = 0;
      this.highlightedRows = [];
      this.indexSelected = {};
      this.form.get('wet_quintals').setValue(0);
      this.getWeightNotesByWarehouse(warehouse._id);
    });

    this.form.get('wet_quintals').valueChanges.subscribe(value => {
      console.log('change: ', parseFloat(Number(value).toFixed(4)), parseFloat(Number(this.totalSelected).toFixed(4)));
      if (parseFloat(Number(value).toFixed(4)) > parseFloat(Number(this.totalSelected).toFixed(4))) {
        this.form.controls['wet_quintals'].setErrors({'incorrect': true});
        this.alert.message = 'Cantidad de qq exceden el total seleccionados.';
        this.alert.color = 'danger';
      } else {
        this.alert.message = '';
        this.alert.color = 'info';
        this.form.controls['wet_quintals'].clearValidators();
        this.form.controls['wet_quintals'].setValidators(Validators.required);
      }
    });
  }

  getWeightNotesByWarehouse(id: string): void {
    this._inventoryService.getNonDried({
      ids: [],
      warehouse: id,
      skip: this.page1 * this.limit,
      limit: this.limit
    }).subscribe(res => {
      console.log('Inventerio por bodega: ', res);
      this.lenData1 = res.count;
      this.availableWeightNotes = res.items as Inventory[];
      this.alert.message =  this.availableWeightNotes.length === 0 ? `No hay Notas de Peso en la Bodega` : '';
      /* this.availableWeightNotes.forEach((wn) => {
        this._driedService.testDry(wn._id, wn).subscribe(resp => {
          console.log('wn test', resp, wn);
          const total_wet = _.sumBy(resp[0].weight_notes, 'total_net_qq');
          if (total_wet !== resp[0].wet_quintals) {
            console.error('Es diferente', total_wet, resp[0].wet_quintals);
          } else {
            console.log('Son iguales', total_wet, resp[0].wet_quintals);
            wn.dried = true;
            this._inventoryService.editInventory(wn._id, wn).subscribe(resp3 => {
              console.log('updated: ', wn);
            }, err => console.error('Error update: ', err));
          }
        }, err => console.error('Error al hacer dry test', err));
      }); */
    }, err => console.error('Error al obtener notasd de peso: ', err));
  }

  public getSum(): void {
    this.form.get('wet_quintals').setValue(_.sumBy(this.highlightedRows, 'net_qq'));
  }

  public getHighlighteds(): void {
    const left = this.page2 * this.limit;
    const right = (this.page2 * this.limit) + this.limit;
    console.log('left: ', left, 'right: ', right);
    this.paginatedHighlightedRows = this.highlightedRows.slice(left, right);
    this.totalSelected = _.sumBy(this.highlightedRows, 'total_qq_dry');
    this.form.get('wet_quintals').setValue(_.sumBy(this.highlightedRows, 'net_qq'));
  }

  public changeStep(direction: string, step: number): void {
    this.stepperParams.step = step;
    this.direction = direction;
    console.log('change step: ', direction, this.stepperParams);
  }

  changePage(paginator: MatPaginator, table: number): void {
    if (table === 1) {
      window.document.getElementById('containerList1').scrollTo(0, 0);
      this.page1 = paginator.pageIndex;
      this.getWeightNotesByWarehouse(this.form.get('warehouse').value._id);
    } else {
      window.document.getElementById('containerList2').scrollTo(0, 0);
      this.page2 = paginator.pageIndex;
      this.getHighlighteds();
    }
    console.log('page1: ', this.page1, 'page2: ', this.page2);
  }

  public highlight(wn: any): void {
    wn['net_qq'] = wn.hasOwnProperty('net_qq') ? wn.net_qq : wn.total_qq_dry;

    // * Remover el elemento.
    if (this.indexSelected.hasOwnProperty(wn._id)) {
      delete this.indexSelected[wn._id];
      const ref = document.getElementById(`item-${wn._id}`);
      if (ref !== null) { ref.classList.add('animate__fadeOutRightBig'); }
      setTimeout(() => {
        this.highlightedRows = this.highlightedRows.filter(item => item._id !== wn._id);
        this.getHighlighteds();
      }, 300);
      wn.net_qq = wn.total_qq_dry;
    } else {
      this.indexSelected[wn._id] = true;
      this.highlightedRows = this.highlightedRows.concat(wn);
      this.getHighlighteds();
    }
    console.log('1: ', this.availableWeightNotes.length);
    console.log('2: ', this.highlightedRows.length);
  }

  public addDried() {
    const dried: Dried = this.form.getRawValue();
    this.highlightedRows.forEach(item => {
      item.net_qq = item.total_qq_dry >= item.net_qq ? item.net_qq : item.total_qq_dry;
    });
    dried.wet_quintals = parseFloat(Number(_.sumBy(this.highlightedRows, 'net_qq')).toFixed(6));
    dried.weight_notes = this.highlightedRows.map(item => item._id);
    dried.driedqq = this.highlightedRows.map(item => item.net_qq);
    dried.status = 'Proceso';
    dried.restqq = this.highlightedRows.map((item) => parseFloat(Number(item.total_qq_dry - item.net_qq).toFixed(4)));
    this.createDried(dried);
  }

  private createDried(dried): void {
    this._driedService.addDried(dried).subscribe(response => {
      this.router.navigateByUrl('ordenes-secado');
      this.updateDataWn(dried);
      console.log('Orden de secado agregada: ', response);
    });
  }

  private updateDataWn(dried: any): void {
    this.highlightedRows.forEach(wn => {
      wn['partialData'] = wn.hasOwnProperty('partialData') ? wn.partialData : {dry: false, settlement: false};
      wn.total_qq_dry = parseFloat(Number(wn.total_qq_dry - wn.net_qq).toFixed(6));
      wn.total_qq_dry = wn.total_qq_dry < 0 ? 0 : wn.total_qq_dry;
      if (wn.total_qq_dry === 0) {
        wn.dried = true;
        wn.partialData.dry = false;
      } else if (wn.total_qq_dry === wn.total_net_qq ) {
        wn.dried = false;
        wn.partialData.dry = false;
      } else {
        wn.dried = false;
        wn.partialData.dry = true;
      }
      this._inventoryService.editInventory(wn._id, wn).subscribe(response => {
        console.log('Inventario editado: ', response);
      }, err => console.error('Error al editar inventario: ', err));
    });
  }

  private createForm(): void {
    this.form = this.fb.group({
      date:           [new Date(), Validators.required],
      hours:          ['', Validators.required],
      warehouse:      ['', Validators.required],
      wet_quintals:   [0,  Validators.required],
      start_temp:     ['', Validators.required],
      final_temp:     ['', Validators.required],
      machine:        ['', Validators.required],
      days:           [''],
      warehouseName:  [''],
      machineName:    [''],
      observations:   [''],
    });
  }

}

