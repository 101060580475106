import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TextMaskModule } from 'angular2-text-mask';

// Importaciones internas

import { GLOBAL } from '../../services/global';
import { UserService } from '../../services/user.service';
import { ProveedoresService } from '../../services/proveedores.service';
import { ProveedoresModel } from '../../models/proveedoresModel';


@Component({
  selector: 'app-addproveedores',
  templateUrl: './addproveedores.component.html',
  styleUrls: ['./addproveedores.component.css'],
  providers: [UserService, ProveedoresService]
})


export class AddproveedoresComponent implements OnInit {

  public titulo: string;
  public proveedor: any = {};
  public identity;
  public token;
  public harvest;
  public url: string;
  public alertMessage;
  public disabled = false;
  public mask = ['(', '5', '0', '4', ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _certificationService: ProveedoresService,
    public dialogRef: MatDialogRef<AddproveedoresComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.titulo = 'Agregar Proveedor';
    this.identity = this._userService.getCompany();
    this.url = GLOBAL.url;
    this.proveedor = {} as ProveedoresModel;
  }

  ngOnInit() {
    this.identity = this._userService.getCompany();
    this.harvest = this.identity.harvest;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  submitForm() {
    this.disabled = true;

    console.log(this.proveedor);

    this._certificationService.addProveedor(this.proveedor).subscribe(
      response => {
        this.alertMessage = 'Registro creado correctamente';
        //this._router.navigate(['/proveedores']);
        //this.onCancelClick();
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          this.alertMessage = body.message;
          console.log(error);
        }
      }
    );
  }

}

