import { Pipe, PipeTransform } from '@angular/core';
import { Dried } from '../models/dried';

@Pipe({ name: 'searchByCode' })

export class SearchByCodePipe implements PipeTransform {
  transform(drieds: Dried[], searchText?: string): any[] {
    if (!drieds) { return []; }
    if (!searchText) { return drieds; }
    searchText = searchText.toLowerCase();
    return drieds.filter(dried =>
      dried.cod.toString().toLowerCase().indexOf(searchText) > -1);
  }
}
/*
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText?: string): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      return it.toLowerCase().includes(searchText);
    });
  }

}*/
