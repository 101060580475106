import { Component, OnInit  } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ma-rightsidebar',
  templateUrl: './rightsidebar.component.html'
})
export class RightSidebarComponent implements OnInit {
  public alertMessage;
  options: FormGroup;

  constructor(fb: FormBuilder) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });
  }

  ngOnInit() {

  }

  onSubmit() {

  }
}
