import { InventoryService } from './../../../services/inventory.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ProductorService } from './../../../services/productor.service';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-settlement-add',
  templateUrl: './settlement-add.component.html',
  styleUrls: ['./settlement-add.component.css']
})
export class SettlementAddComponent implements OnInit {
  public paramsData = {};
  public form: FormGroup;
  constructor(
    private coffeefarmerService: ProductorService,
    private weightNotesService: InventoryService,
    private fb: FormBuilder
  ) {
    this.paramsData['data'] = 'coffeefarmers';
    this.paramsData['field'] = 'total_net_sett';
    this.paramsData['columns'] = ['check', 'cod', 'date', 'total_net_sett', 'net_qq'];
  }

  ngOnInit() {
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      date: [''],
    });
  }

}
