import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { ReferralAddComponent } from './referral-add/referral-add.component';
import { ReferralEditComponent } from './referral-edit/referral-edit.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { StepOneReComponent } from './referral-add/step-one/step-re-one.component';
import { StepTwoReComponent } from './referral-add/step-two/step-re-two.component';
import { StepThreeReComponent } from './referral-add/step-three/step-re-three.component';

@NgModule({
  declarations: [
    ReferralAddComponent,
    ReferralEditComponent,
    ReferralsComponent,
    StepOneReComponent,
    StepTwoReComponent,
    StepThreeReComponent,
  ],
  imports: [
    SharedModule,
    CommonModule
  ]
})
export class ReferralsModule { }
