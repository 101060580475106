import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Invoice } from '../models/invoice';

@Injectable()
export class InvoiceService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getInvoices(): Observable<any> { return this.http.get(`${this.url}/invoices/list`); }
  getPaidOutInvoices(): Observable<any> { return this.http.get(`${this.url}/invoices/paid-out-list`); }

  addInvoice(invoice: Invoice): Observable<any> {
    return this.http.post(`${this.url}/invoices/new`, invoice);
  }
  getInvoice(id): Observable<any> {return this.http.get(`${this.url}/invoices/${id}`); }
  editInvoice(id, invoice): Observable<any> {return this.http.put(`${this.url}/invoices/${id}`, invoice); }
  deleteInvoice(id): Observable<any> {return this.http.delete(`${this.url}/invoices/${id}`); }
  getInvoicePerWarehouse(warehouse): Observable<any> {
    return this.http.get(`${this.url}/invoices/warehouse/${warehouse}`);
  }
  getInvoicePerCustomer(customer): Observable<any> {
    return this.http.get(`${this.url}/invoices/customer/${customer}`);
  }
  getInvoicePerWeightNotes(weightnotes): Observable<any> {
    return this.http.get(`${this.url}/invoices/weightnotes/${weightnotes}`);
  }
}
