import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';

import { GLOBAL } from '../../../../services/global';
import { ProductionService } from '../../../../services/production.service';
import { Production } from '../../../../models/production';
import { LotService } from '../../../../services/lot.service';
import { BrokerService } from '../../../../services/broker.service';
import { CustomerService } from '../../../../services/customer.service';
import { ExporterService } from '../../../../services/exporter.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Lot } from '../../../../models/lot';
import { Exporter } from '../../../../models/exporter';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-production-add',
  templateUrl: './production-add.component.html',
  styleUrls: ['./production-add.component.css'],
  providers: [ProductionService, LotService, CustomerService, BrokerService, ExporterService]
})
export class ProductionAddComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  enable = false;
  public lot: any = {};
  public lots: string[] = [];
  public exportersData: Exporter[];
  public filteredExportersMulti: ReplaySubject<Exporter[]> = new ReplaySubject<Exporter[]>(1);
  /*------------------chips--------------------------*/
  public allLots: Lot[] = [];
  public chipSelectedLots: any[] = [];
  public objectsLots: any[] = [];
  public filteredLots: Observable<String[]>;

  private allowFreeTextAddLot = true;

  public lotControl = new FormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('lotInput') lotInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
/*--------------------------------------------*/
  private _onDestroy = new Subject<void>();
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  constructor(
    private _router: Router,
    private fb: FormBuilder,
    private _productionService: ProductionService,
    private _exporterService: ExporterService,
    private _lotService: LotService,
    public dialogRef: MatDialogRef<ProductionAddComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any = null,
  ) {
    this.createForm();
    console.log('data recibida: ', this.data);
  }

  ngOnInit() {
    this.listenersFormValues();
    this.getLots();
    this.getExporterData();
    /*------------------chips--------------*/
    this.filteredLots = this.lotControl.valueChanges.pipe(
      // tslint:disable-next-line: deprecation
      startWith(null),
      map(lotName => this.filterOnValueChange(lotName))
    );
    this.form.patchValue({ quintals: this.data ? this.data : 0 });
    /*-------------------------------------*/
  }

  ngAfterViewInit(): void { }

  createForm() {
    this.form = this.fb.group({
      date_i:       ['', Validators.required],
      date_f:       ['', Validators.required],
      exporter:     [''],
      exporterName: [''],
      analysis:     ['', Validators.required],
      quintals:     ['', Validators.required],
      load:         ['', Validators.required],
      observations: [''],
      external: [false],
    });
  }

  getExporterData() {
    this._exporterService.getExporters().subscribe(resp => {
      this.exportersData = resp;
      this.filteredExportersMulti.next(this.exportersData.slice());
    });
  }

  listenersFormValues() {
    this.form.get('exporterName').valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe( () => {
      this.filterExportersMulti();
    });
  }

  /*-----------------------------------chips--------------------*/
  public addLot(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddLot) {
      console.log('allowFreeTextAddLot is false');
      return;
    }
    console.log(event);

    if (this.matAutocomplete.isOpen) {
      return;
    }
    const value = event.value;
    if ((value || '').trim()) {
      this.selectLotByName(value.trim());
    }

    this.resetInputs();
  }

    public removeLot(lot: Lot): void {
      const index = this.chipSelectedLots.indexOf(lot);
      if (index >= 0) {
        this.chipSelectedLots.splice(index, 1);
        this.resetInputs();
      }
    }

    public lotSelected(event: MatAutocompleteSelectedEvent): void {
      this.selectLotByName(event.option.value);
      this.resetInputs();
    }

    private resetInputs() {
      this.lotInput.nativeElement.value = '';
      this.lotControl.setValue(null);
    }

    public filterExportersMulti() {
      if (!this.exportersData) {
        return;
      }
      let toSearch = this.form.get('exporterName').value;
      if (!toSearch) {
        this.filteredExportersMulti.next(this.exportersData.slice());
        return;
      } else {
        toSearch = toSearch.toLowerCase();
      }
      this.filteredExportersMulti.next(
        this.exportersData.filter( cert => cert.name.toLowerCase().indexOf(toSearch) > -1)
      );
    }

    private filterOnValueChange(lotName: string | null): String[] {
      let result: String[] = [];

      const allLotsLessSelected = this.allLots.filter(lot => this.chipSelectedLots.indexOf(lot) < 0);
      if (lotName) {
        result = this.filterLot(allLotsLessSelected, lotName);
      } else {
        result = allLotsLessSelected.map(lot => lot.lot);
      }
      return result;
    }

    private filterLot(lotList: Lot[], lotName: String): String[] {
      let filteredLotList: Lot[] = [];
      const filterValue = lotName.toLowerCase();
      const lotsMatchingLotName = lotList.filter(lot => lot.lot.toLowerCase().indexOf(filterValue) === 0);
      if (lotsMatchingLotName.length || this.allowFreeTextAddLot) {

        filteredLotList = lotsMatchingLotName;
      } else {

        filteredLotList = lotList;
      }

      return filteredLotList.map(lot => lot.lot);
    }

    private selectLotByName(lotName) {
      const foundLot = this.allLots.filter(lot => lot.lot === lotName);
      if (foundLot.length) {

        this.chipSelectedLots.push(foundLot[0]);
        this.getLot(foundLot[0]);
      } else {

        const highestEmployeeId = Math.max(...this.chipSelectedLots.map(lot => lot.seq), 0);
        this.chipSelectedLots.push({ lot: lotName, seq: highestEmployeeId + 1 });
      }
    }
    private selectLotById(res) {
      this.getLot(res);
      this.chipSelectedLots.push(res);
    }

    getLots() {
      this._lotService.getLotsOffProd().subscribe(
        (res) => {
          this.allLots = res as Lot[];
          console.log('Lotes obtenidos:', res);
        }
      );
    }

    getLot(lot) {
      console.log(lot);
      this.enable = true;
      this._lotService.getLot(lot._id).subscribe(
        response => {
          this.lot = response.lot;
        }
      );
    }
  /*----------------------------------chips-----------------------*/

  save() {
    const production = this.form.value;
    production.external = this.data ? false : true;
    production.lots = this.chipSelectedLots.map(lot => lot._id);
    if (production.exporter === "") {
      delete production.exporter;
    }
    console.log('production', production);
    this._productionService.addProduction(production).subscribe((res) => {
      this.updateLots(res);
      this.dialogRef.close(res);
            setTimeout(() => {
        this.router.navigateByUrl('/produccion');
      }, 500);
    }, (err: HttpErrorResponse) => console.error('Error al agregar produccion', err));
  }

  updateLots(production: Production) {
    production.lots.forEach(_id => {
      this._lotService.editLot(_id, { prod: production._id }).subscribe(response => console.log('Lote actualizado'));
    });
  }

}
