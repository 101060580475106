import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { CertificationService } from './../../../services/certification.service';
import { ProductorService } from './../../../services/productor.service';
import { PurchaseContractService } from '../../../services/purchase-contract.service';
import { GLOBAL } from '../../../services/global';

import { Certification } from '../../../models/certification';
import { CoffeeFarmer } from '../../../models/productor';

@Component({
  selector: 'app-purchase-contract-add',
  templateUrl: './purchase-contract-add.component.html',
  styleUrls: ['./purchase-contract-add.component.css']
})
export class PurchaseContractAddComponent implements OnInit {
  public form: FormGroup;
  public title = 'Agregar Contrato de Compra';
  public availableCoffeefarmers: CoffeeFarmer[];
  public filteredCoffeefarmers: CoffeeFarmer[];
  public availableCertifications: Certification[];
  public filteredCertifications: Certification[];
  public currency;

  constructor(
    private fb: FormBuilder,
    private purchaseContract: PurchaseContractService,
    private coffeefarmerService: ProductorService,
    private certificationService: CertificationService,
    public dialogRef: MatDialogRef<PurchaseContractAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.currency = GLOBAL.currency;
    this.createForm();
    this.listenersFormValues();
    this.getDataFromServices();
  }

  getDataFromServices(): void {
    this.certificationService.getCertifications().subscribe(resp => {
      this.availableCertifications = resp;
      this.filteredCertifications = resp;
    });

    this.coffeefarmerService.getProductores().subscribe(res => {
      this.availableCoffeefarmers = res;
      this.filteredCoffeefarmers = res;
    });
  }

  listenersFormValues(): void {
    this.form.get('total_qq').valueChanges.subscribe(value => {
      if (this.form.get('price').valid) {
        this.form.get('contractValue').setValue(this.form.get('price').value * value);
      }
    });

    this.form.get('price').valueChanges.subscribe(value => {
      if (this.form.get('total_qq').valid) {
        this.form.get('contractValue').setValue(this.form.get('total_qq').value * value);
      }
    });

    this.form.get('coffeefarmerName').valueChanges.subscribe((value: string) => {
      this.filteredCoffeefarmers = this.availableCoffeefarmers
        .filter((productor: CoffeeFarmer) => productor.name
          .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value
            .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      coffeefarmer:       [''],
      coffeefarmerName:   [''],
      certifications:     [''],
      certificationName:  [''],
      contract:           [''],
      date:               [new Date()],
      observations:       [''],
      price:              [0],
      contractValue:      [''],
      total_qq:           [0],
    });
  }

  save(): void {
    const purchaseContract: any = this.form.getRawValue();
    console.log('data: ', purchaseContract);
    this.purchaseContract.createPurchaseContract(purchaseContract).subscribe(res => {
      this.dialogRef.close();
      console.log('Contrato de compra creado: ', res);
    }, err => console.error('Error al crear contrato de compra: ', err));
  }
}
