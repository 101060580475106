import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { CollectionCenter } from '../models/collectionCenter';
import { Http, Response, Headers } from '@angular/http';

@Injectable()
export class CollectionCenterService {
  public url: string;

  constructor(private _http: Http, private http: HttpClient ) {
    this.url = GLOBAL.url;
  }

  getCollectionCenters(): Observable<any> { return this.http.get(`${this.url}/collection-centers/list`); }

  addCollectionCenter(collectionCenter: CollectionCenter): Observable<any> {
    return this.http.post(`${this.url}/collection-centers/new`, collectionCenter);
  }
  getCollectionCenter(id): Observable<any> {return this.http.get(`${this.url}/collection-centers/${id}`); }
  editCollectionCenter(id, collectionCenter): Observable<any> {
    return this.http.put(`${this.url}/collection-centers/${id}`, collectionCenter);
  }
  deleteCollectionCenter(id): Observable<any> {
    return this.http.delete(`${this.url}/collection-centers/${id}`);
  }
}
 