import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';

import { InventoryService } from '../../../../services/inventory.service';
import { ProductorService } from '../../../../services/productor.service';
import { UserService } from '../../../../services/user.service';
import { CollectionCenter } from '../../../../models/collectionCenter';
import { CollectionCenterService } from '../../../../services/collectionCenter.service';
import { GLOBAL } from '../../../../services/global';

import { CoffeeFarmer } from '../../../../models/productor';
import { Inventory } from '../../../../models/inventory';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.css']
})
export class StepOneComponent implements OnInit, AfterViewInit, OnDestroy {

  public weightNotes: Inventory[] = [];
  public availableCoffeefarmers: CoffeeFarmer[] = [];
  public filteredCoffeefarmers: CoffeeFarmer[] = [];
  public availablecashCounts: CollectionCenter[];
  public filteredcashCounts: CollectionCenter[];
  public indexSelected: any = {};
  public loading = false;
  public form: FormGroup;

  public page = 0;
  public lenData = 0;
  public limit = 100;
  public total_qq = 0;
  public settlement: any = {};
  public currency;
  public identity;
  public collectionCenter;
  public company: any;
  public gals = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private _inventoryService: InventoryService,
    private _coffeeFarmerService: ProductorService,
    private _collectionCenterService: CollectionCenterService,
    private _userService: UserService,
    private fb: FormBuilder,
    public dialog: MatDialog,
  ) {
    const data = localStorage.getItem('settlement');
    const index = localStorage.getItem('indexSelectedWeightNotes');
    this.identity = this._userService.getIdentity();
    this.company = this._userService.getIdentity().company;
    this.settlement = data !== null ? JSON.parse(data) : {};
    console.log(this.identity);
    this.indexSelected = index !== null ? JSON.parse(index) : {};
    this.total_qq = this.sumBy(this.indexSelected, 'net_qq');
  }

  ngOnInit() {
    this.createForm();
    this.getCoffeefarmers();
    this.listeners();
    this.getCashCounts();
    this._userService.getUser(this.identity._id).subscribe(resp => {
      console.log(resp.collectionCenter);
      if (this.identity.collectionCenter) {
        this._collectionCenterService.getCollectionCenter(resp.collectionCenter).subscribe( res => {
          console.log(res);
          this.collectionCenter = res;
          if (res.price) {
            this.settlement.coffee_price = res.price;
          }
        });
      }
    });
    this.currency = GLOBAL.currency;
  }

  getCashCounts(): void {
    this._collectionCenterService.getCollectionCenters().subscribe(res => {
      this.availablecashCounts = res as CollectionCenter[];
      this.filteredcashCounts = res as CollectionCenter[];
    }, err => console.error('Error al obtener arqueos de caja', err));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.form.patchValue(this.settlement);
    }, 100);
  }

  ngOnDestroy(): void {
    this.saveData();
  }

  createForm(): void {
    this.form = this.fb.group({
      coffee_price:     [0, Validators.required],
      coffeefarmer_id:  ['', Validators.required],
      coffeefarmer:     ['', Validators.required],
      collectionCenter: [''],
      collectionCenterS: [''],
      coffeefarmerName: ['']
    });
  }

  getCoffeefarmers(): void {
    this._coffeeFarmerService.getProductores().subscribe(res => {
      this.availableCoffeefarmers = res;
      this.filteredCoffeefarmers = res;
    }, err => console.error('Error al obtener productores: ', err));
  }

  listeners(): void {
    this.form.get('coffeefarmerName').valueChanges.subscribe((value: string) => {
      this.filteredCoffeefarmers = this.availableCoffeefarmers
        .filter(coffeefarmer => String(coffeefarmer.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });

    this.form.get('collectionCenterS').valueChanges.subscribe((value: string) => {
      this.filteredcashCounts = this.availablecashCounts
        .filter(cash => String(cash.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });

    this.form.get('coffeefarmer_id').valueChanges.subscribe((id: string) => {
      this.getWeightNotesPerCoffeefarmer(id);
    });
  }

  getWeightNotesPerCoffeefarmer(id): void {
    this.loading = true;
    this._inventoryService.getInventoryPerCoffeeFarmer(id).subscribe(res => {
      this.loading = false;
      if (res !== null) {
        this.weightNotes = res;
      }
    }, err => {
      console.error('Error al obtener notas de peso por productor: ', err);
      this.loading = false;
    });
  }

  highlight(wn: any): void {
    console.log('highlight: ', wn);

    this.gals = wn.gallons;
    wn['net_qq'] = wn.hasOwnProperty('net_qq') ? wn.net_qq : wn.total_net_sett;
    if (this.indexSelected.hasOwnProperty(wn._id)) {
      delete this.indexSelected[wn._id];
    } else {
      if (this.company._id === '643d773bfebd7823442022cb') {
        this.indexSelected[wn._id] = {
          net_qq: wn.net_qq,
          total_net_sett: wn.total_net_sett,
          cost_per_beneficiary: isNaN(wn.cost_per_beneficiary) ? 0 : (wn.total_net_sett !== wn.total_net_lb ? 0 : wn.cost_per_beneficiary)
        };
      } else {
        this.indexSelected[wn._id] = {
          net_qq: wn.net_qq,
          total_net_sett: wn.total_net_sett,
          cost_per_beneficiary: isNaN(wn.cost_per_beneficiary) ? 0 : (wn.total_net_sett !== wn.total_net_qq ? 0 : wn.cost_per_beneficiary)
        };
      }
    }
    this.getSum();
  }

  changePage(paginator: MatPaginator) {
    console.log('Event: ', paginator);
    this.limit = paginator.pageSize;
    this.page = paginator.pageIndex;
    this.getCoffeefarmers();
  }

  saveData() {
    this.getSum();
    const index = this.indexSelected;
    const form = this.form.getRawValue();
    delete form['coffeefarmerName'];
    let temp = 0;
    let gal = false;
/*     if (index[0].gallons && index[0].gallons === true) {
      gal = true;
    } else {
      gal = false;
    } */
    console.log(index);
    Object.keys(index).forEach(key => {
      const current = index[key];
      if (this.company._id === '643d773bfebd7823442022cb') {
        if (current.total_net_sett > current.total_net_lb) {
          current.total_net_sett = current.total_net_lb;
        }
      } else {
        if (current.total_net_sett > current.total_net_qq) {
          current.total_net_sett = current.total_net_qq;
        }
      }
      current.net_qq = current.total_net_sett >= current.net_qq ? current.net_qq : current.total_net_sett;
      temp += current.net_qq;
    });
    this.total_qq = temp;
    const stepOneData = Object.assign({
      collectionCenter: form['collectionCenter'],
      weight_notes: Object.keys(index),
      gallons: this.gals,
      qqForWn: this.mapBy(index, 'net_qq'),
      restForWn: Object.keys(index).map(key =>
        parseFloat(Number(Number(index[key].total_net_sett) - Number(index[key].net_qq)).toFixed(6))),
      costs_per_beneficiary: this.mapBy(index, 'cost_per_beneficiary'),
      cost_per_beneficiary: this.sumBy(index, 'cost_per_beneficiary'),
      total_qq: this.total_qq,
      coffee_price: form.coffee_price,
      total: parseFloat(Number(this.total_qq * form.coffee_price).toFixed(2)),
      total_net: this.getTotalNet(this.settlement, this.total_qq * form.coffee_price),
    }, form);
    this.settlement = Object.assign(this.settlement, stepOneData);
    localStorage.setItem('settlement', JSON.stringify(this.settlement));
    localStorage.setItem('indexSelectedWeightNotes', JSON.stringify(index));
    console.log('step1 data: ', this.settlement);
  }

  getSum(): void {
    this.total_qq = this.sumBy(this.indexSelected, 'net_qq');
  }

  sumBy(object: any, field: string): number {
    return Object.keys(object).reduce((acc, key) => acc + object[key][field] , 0);
  }

  mapBy (object: any, field: string): Object {
    return Object.keys(object).map(key => object[key][field]);
  }

  getTotalNet(settlement: any, total): number {
    let total_net = total;
    const numbers = _.pick(settlement, ['cost_per_beneficiary', 'freight', 'contributions', 'export_cost',
      'export_cert', 'financial_expenses', 'other_deductions', 'total_deductions']);
    Object.keys(numbers).forEach(key => {
      numbers[key] = isNaN(numbers[key]) ? 0 : numbers[key];
      total_net -= numbers[key];
    });
    return parseFloat(Number(total_net).toFixed(2));
  }

}
