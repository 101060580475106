import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { GLOBAL } from './global';
import { Lot } from '../models/lot';

@Injectable()
export class LotService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getLots(): Observable<any> {
    return this.http.get(`${this.url}/lots/list`);
  }
  getLotsOffProd(): Observable<any> {
    return this.http.get(`${this.url}/lots/list-prod`);
  }
  getLotsOffComplete(): Observable<any> {
    return this.http.get(`${this.url}/lots/list-shipping`);
  }
  getLotsOffContract(): Observable<any> {
    return this.http.get(`${this.url}/lots/list-contract`);
  }
  getLotes(): Observable<any> {
    return this.http.get(`${this.url}/lots/lista`);
  }
  getAllLots(): Observable<any> {
    return this.http.get(`${this.url}/lots/list-all`);
  }
  addLot(lot: Lot): Observable<any> {
    return this.http.post(`${this.url}/lots/new`, lot);
  }
  getLot(id): Observable<any> {
    return this.http.get(`${this.url}/lots/${id}`);
  }
  editLot(id, lot): Observable<any> {
    return this.http.put(`${this.url}/lots/${id}`, lot);
  }
  deleteLot(id): Observable<any> {
    return this.http.delete(`${this.url}/lots/${id}`);
  }

  findPerCode = (code: number): Observable<any> => this.http.get(`${this.url}/lots/find-per-code/${code}`);

}
