import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { MatDialogRef} from '@angular/material';

import {UserService} from '../../../services/user.service';
import { ProductorService } from '../../../services/productor.service';
import { OriginService } from '../../../services/origin.service';

import { CoffeeFarmer } from '../../../models/productor';
import { Origin } from '../../../models/origin';
@Component({
  selector: 'app-productor-add',
  templateUrl: './productor-add.component.html',
  providers: []
})

export class ProductorAddComponent implements OnInit {

  public title = 'Agregar Nuevo Productor';
  public identity;
  public harvest;
  public alertMessage = '';
  public civilStatus = ['Soltero', 'Casado'];
  public genders = ['Masculino', 'Femenino'];
  public farmerTypes = ['Empresa Privada', 'Productor', 'Gobierno', 'Sociedad Civil', 'ONG'];
  public mask = ['(', '5', '0', '4', ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public maskihcafe = [/\d/, '-', /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
  public maskid = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
  public maskrtn = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  public filteredOriginMulti: Origin[] = [];
  public form: FormGroup;
  public status_list = ['Afiliado', 'No Afiliado', 'Proveedor', 'Empleado'];
  public idExits = false;
  private ids: string[];
  private availableOrigins: Origin[] = [];

  constructor(
    private _userService: UserService,
    private _productorService: ProductorService,
    private _originService: OriginService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ProductorAddComponent>,
  ) {
    this.identity = this._userService.getCompany();
  }

  ngOnInit() {
    this.createForm();
    this.getDataFromService();
    this.listenerFormValue();
    this.identity = this._userService.getCompany();
    this.harvest = this.identity.harvest;
  }

  getDataFromService(): void {
    this._productorService.getProducersId().subscribe(idList => {
      this.ids = idList;
    }, err => console.error('Error al obtener los ids: ', err));

    this._originService.getOrigins().subscribe(res => {
        this.availableOrigins = res;
        this.filteredOriginMulti = res;
      }, err => console.error('Error al obtener las fincas: ', err)
    );
  }

  listenerFormValue(): void {
    this.form.get('n_id').valueChanges.subscribe(value => {
      if (this.ids.hasOwnProperty(value)) {
        console.log('Ya existe ID');
        this.idExits = true;
        this.form.controls['n_id'].setErrors({'incorrect': true});
      } else {
        this.idExits = false;
        this.form.controls['n_id'].clearValidators();
        this.form.controls['n_id'].setValidators(Validators.required);
      }
    });

    this.form.get('originName').valueChanges.subscribe((value: string) => {
      this.filteredOriginMulti = this.availableOrigins
        .filter(origin => String(origin.name)
          .toLowerCase().includes(value.toLowerCase()));
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      name:         ['', Validators.required],
      email:        [''],
      address:      ['', Validators.required],
      status:       ['Afiliado', Validators.required],
      n_id:         ['', Validators.required],
      cod_cert:     [''],
      cod_cant:     [''],
      cod_area:     [''],
      originName:   [''],
      origins:      [''],
      birth:        [''],
      farmerType:   [''],
      gender:       [''],
      bank:         [''],
      ihcafe:       ['', Validators.required],
      rtn:          [''],
      telephone:    ['', Validators.required],
      civilStatus:  ['Soltero']
    });
  }

  addProducer() {
    const producer: CoffeeFarmer = this.form.getRawValue();
    console.log('submit: ', producer);
    producer.harvest = this.harvest;
    this._productorService.addProductor(producer).subscribe(response => {
      this.alertMessage = 'El Productor se ha creado correctamente';
      this.dialogRef.close();
    }, error => console.error('Error al agregar productor: ', error));
  }
}
