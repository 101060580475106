import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatDialog } from '@angular/material';
import { Subject, ReplaySubject } from 'rxjs';

import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { CategoryService } from '../../../services/category.service';
import { SeatService } from '../../../services/seat.service';
import { Category } from '../../../models/category';
import { UserService } from '../../../services/user.service_old';
import { ExcelService } from '../../../services/excel.service';
import { ImportDataService } from '../../../services/import-data.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-categories-tree',
  templateUrl: './categories-tree.component.html',
  styleUrls: ['./categories-tree.component.css'],
  providers: [CategoryService, SeatService, UserService]
})

export class CategoriesTreeComponent implements OnInit {
  public searchControl: FormControl = new FormControl();
  public expandedRows: Category[] = [];
  public types = ['Grupo', 'Detalle'];
  public title = 'Cuentas contables';
  public totalCategories = 0;
  public enabledForm = false;
  public showBtnUp = false;
  public myData = [];
  goUp;
  public dataForTesting = [];
  public filteredCategoriesMulti: ReplaySubject<Category[]> = new ReplaySubject<Category[]>(1);
  public filteredCategories = [];
  private categoriesData: Category[];
  public treeControl = new NestedTreeControl<Category>(node => node.children);
  public form: FormGroup;
  public editActivate = false;
  public inputCodeExist = false;
  public currentEdit = {} as Category;
  private currentSearchRow: string;
  private selectedRows = [];
  private indexedCodeData = {};
  private indexedNameData = {};
  private saveNumber: number;
  private category: any = {};
  private highligthted;
  public collapse = true;
  private selectedFile: File = null;
  public alertMessage = {message: '', color: 'primary'};
  private childrenOfCurrentEdit: number[] = [];
  private saveScrollPosition = 0;
  private categoriesCannotDelete: number[] = [];
  private tempIDArr = [];
  @ViewChild('searchSelect') search: any;
  private _onDestroy = new Subject<void>();
  /* private scrollUpTree = function(event) {
    const btn = window.document.getElementById('btnUp');
    const scrollTop =  (event.target as HTMLTextAreaElement).scrollTop;
    if (scrollTop > 200) {
      btn.style.display = 'block';
    } else {
      btn.style.display = 'none';
    }
  }; */

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    // window.document.getElementById('categories-tree').removeEventListener('scroll', this.scrollUpTree);
  }

  constructor(
    private categoryService: CategoryService,
    private excelService: ExcelService,
    private fb: FormBuilder,
    private seatService: SeatService,
    private importDataService: ImportDataService,
    public dialog: MatDialog,
    public userService: UserService,
    ) { }

  ngOnInit(): void {
    this.createForm();
    this.getCategories();
    this.listenersFormChanges();
    // this.createArchitecture();
  }

  createArchitecture = (): void => {
    this.categoryService.createArchitecture2().subscribe((res) => {
      console.log('ARQUITECTURA CREADA');
    }, (err: HttpErrorResponse) => console.error('Error al crear arquitectura de cuentas', err));
  }

  hasChild = (_: number, node: Category) => !!node.children && node.children.length > 0;

  getCategories() {
    this.categoryService.getCategories().subscribe(res => {
      // console.log('res ', res);
      /* res.forEach(cat => {
        if (cat.description === 'Gasto' || cat.description === 'Gastos') {
          setTimeout(() => {
            cat.description = 'Costos y Gastos';
            this.categoryService.editCategory(cat._id, cat).subscribe(resp => {
              console.log('Categoria editada: ', resp);
            }, err => console.error('Error al editar categoria: ', err));
          }, 5000);
        }
      }); */
      this.categoriesData = res;
      this.treeControl.dataNodes = this.categoriesData;
      this.indexedCodeData = this.indexedFormatCode(res);
      const data: any = [];
      Object.keys(this.indexedCodeData).forEach(code => {
        const currentCategory = this.indexedCodeData[code];
        const parentIndex: number = currentCategory.parent;
        // * NO Es un cuenta root.
        if (currentCategory.parent !== null ) {
            const parent = this.indexedCodeData[parentIndex];
            parent.children.push(currentCategory);
        } else {
            data.push(currentCategory);
        }
      });
        console.log('Categories tree data: ', data);
        this.myData = data;
    }, error => console.error('Error al obtener la data: ', error));
  }

  listenersFormChanges() {
    // window.document.getElementById('categories-tree').addEventListener('scroll', this.scrollUpTree);

    this.form.get('parentName').valueChanges.subscribe((value: string) => {
      value = value === undefined ? '' : value;
      this.filteredCategories = this.categoriesData
      .filter(category => category.type !== 'Detalle' && String(category.code)
      .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      .indexOf(String(value).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
      console.log('parentValu: ', value);
      console.log('filtered: ', this.filteredCategories);
    });

    this.form.get('code').valueChanges.subscribe(value => {
      if (this.currentEdit.code !== value) {
        this.inputCodeExist = this.indexedCodeData.hasOwnProperty(value);
        if (this.inputCodeExist) {
          this.form.controls['code'].setErrors({'incorrect': true});
        } else {
          this.form.controls['code'].clearValidators();
          this.form.controls['code'].setValidators(Validators.required);
        }
      } else {
        this.inputCodeExist = false;
        this.form.controls['code'].clearValidators();
        this.form.controls['code'].setValidators(Validators.required);
      }
    });

    this.form.get('codeSearch').valueChanges.subscribe(value => {
      const item = this.indexedCodeData[value];
      // this.treeControl.collapseAll();
      if (item !== undefined && item.parent !== null) {
        this.saveNumber = value;
        item.pathx.split('.').forEach(code => {
          const currentIndex = this.indexedCodeData[code];
          this.treeControl.expand(currentIndex);
        });
        // this.treeControl.expand(this.indexedCodeData[item.parent]);
      }
    });

    this.treeControl.expansionModel.changed.subscribe(value => {
      const selected = this.treeControl.expansionModel.selected;
      this.collapse = selected.length > 0 ? false : true;
    });
  }

  setFormatValues(category: Category, parent: number): Category {
    const parentData = this.indexedCodeData[parent];
    category.parent = parentData.code;
    category.description = parentData.description;
    category.pathx = parentData.pathx + '.' + category.code;
    category.parent_pathx = parentData.pathx;
    category.levelx = parentData.levelx + 1;
    return category;
  }

  async addCategory(): Promise<void> {
    if (this.form.valid) {
      console.log('\nValid form');
      this.category = this.form.getRawValue();                                  // name, code, parent
      // * Agregar nueva categoria
      if (!this.editActivate) {
        const parentData = this.form.get('parent').value.code;
        this.category = this.setFormatValues(this.category, parentData);
        delete this.category._id;
        this.categoryService.addCategory(this.category).subscribe(response => {
          console.log('Cuenta agregada!');
          this.enabledForm = false;
          this.setAlertMessage(`Cuenta ${this.category.name} agregada`);
          setTimeout(() => { this.getCategories(); }, 1500);
          this.saveNumber = this.category.code;
        }, error => console.error('Error al agregar cuenta: ', error));
      } else {
        // * Editar categoria
        this.category.children = this.indexedCodeData[this.currentEdit.code].children;
        this.category.parent = this.form.get('parent').value.code;
        this.category = this.setFormatValues(this.category, this.category.parent);
        this.indexedCodeData[this.category.code] = this.indexedCodeData[this.currentEdit.code];
        if (this.category.code !== this.currentEdit.code) {
          delete this.indexedCodeData[this.currentEdit.code];
        }
        this.indexedCodeData[this.category.code].code = this.category.code;
        if (
          this.currentEdit.code === this.category.code &&
          this.currentEdit.parent === this.form.get('parent').value.code &&
          this.currentEdit.name.trim() !== this.form.get('name').value.trim()
        ) {
          this.category = this.setFormatValues(this.category, this.category.parent);
          await this.categoryService.editCategory(this.category._id, this.category).subscribe(response => {
            console.log('Editado solo nombre: ', response);
          }, err => console.error('Error al editar solo nombre: ', err));
        } else {
          await this.recursiveForEdit(this.category);
        }
        setTimeout(() => this.getCategories() , 1000);
        this.setAlertMessage(`Cuenta ${this.category.name} editada.`);
        this.saveNumber = this.category.code;
      }
      this.hideForm();
    } else {
      this.setAlertMessage('Complete todos los campos del formulario', 4000, 'danger');
      console.error('Invalid form');
    }
  }

  // * Se ejecutara cuando se haya cambiando el codigo o el padre.
  recursiveForEdit(category: Category): void {
    const parentcategory: Category = this.indexedCodeData[category.parent];
    category = this.setFormatValues(category, parentcategory.code);
    this.indexedCodeData[category.code] = category;
    this.categoryService.editCategory(category._id, category).subscribe(response => {
      console.log('Editado!: ');
    }, err => console.error('Error al editar :' , category, err));
    if (category.children.length > 0) {
      category.children.forEach(current => {
        this.indexedCodeData[current.code].parent = category.code;
        current.parent = category.code;
        this.recursiveForEdit(current);
      });
    }
    }

  editCategory(node: Category): void {
    this.editActivate = true;
    this.saveScrollPosition = document.getElementById('categories-tree').scrollTop;
    this.currentEdit = Object.assign({}, this.indexedCodeData[node.code]);
    this.getChildrenOfCurrentEdit(this.currentEdit);
    console.log('Node a editar: ', this.currentEdit);
    this.form.patchValue(node);
    this.form.get('parent').setValue(this.indexedCodeData[node.parent]);
    this.form.get('parentName').setValue(node.parent);
    // this.form.get('name').setValue(node.name);
    // this.form.get('code').setValue(node.code);
    // this.form.get('type').setValue(node.type);
    // this.form.get('_id').setValue(node._id);
    // this.form.get('parent').setValue(this.indexedCodeData[node.parent]);
    console.log('nwe form: ', this.form.value);
    this.enabledForm = true;
    this.inputCodeExist = false;
  }

  getChildrenOfCurrentEdit(category: Category): void {
    if (category.children.length > 0) {
      this.childrenOfCurrentEdit.push(category.code);
      category.children.forEach(currentItem => {
        this.getChildrenOfCurrentEdit(currentItem);
      });
    } else {
      if (category.type === 'Grupo') {
        this.childrenOfCurrentEdit.push(category.code);
      }
    }
  }

  onRowDelete(row: Category) {
    this.openDialogD(row);
  }

  openDialogD(category: Category) {
    this.categoryService.constraintCategory(category._id).subscribe(res => {
      console.log('constraint res: ', res);
      if (res) {
        this.setAlertMessage(`La cuenta esta siendo utilizada en un asiento.`, 5000, 'danger');
      } else {
        if (category.number >= 1 && category.number <= 35) {
          console.error('NO PUEDE BORRAR LAS CUENTAS PRINCIPALES: ', category);
          this.setAlertMessage(`No es posible eliminar cuentas principales.`, 5000, 'danger');
        } else {
          const dialogRef = this.dialog.open(DeleteModalComponent, {
            width: '400px',
            panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
            data: {
              header: 'Eliminar cuenta',
              title: '',
              alert: 'warning',
              text: `¿Seguro que quieres eliminar la cuenta <br> <strong>${category.code}-${category.name}</strong> ?`
            }
          });
          dialogRef.afterClosed().subscribe(async result => {
            if (result === 'confirm') {
              await this.recursiveForDelete(category);
              this.setAlertMessage(`Cuenta ${category.name} ha sido eliminada.`, 5000);
              this.getCategories();
            }
          });
        }
      }
    }, err => console.error('Error al verificar restriccion de la cuenta: ', err));
  }

  recursiveForDelete(category: Category) {
    this.categoryService.deleteCategory(category._id).subscribe(response => {
      console.log('Nodo eliminado!: ', response);
    }, err => console.error('Error al borrar :' , err));
    if (category.children.length > 0) {
      category.children.forEach(current => {
        this.recursiveForDelete(current);
      });
    }
  }

  hideForm(): void {
    this.enabledForm = false;
    this.editActivate = false;
    this.form.get('code').setValue(null);
    this.form.get('name').setValue('');
    this.form.get('type').setValue('');
    this.form.get('parent').setValue('');
    this.form.get('parentName').setValue('');

    // this.form.get('parentName').enable();
    // this.form.get('parent').enable();
    this.currentEdit = {} as Category;
    this.childrenOfCurrentEdit = [];
  }

  expand(): void {
    this.treeControl.expandAll();
  }

  group(): void {
    this.treeControl.collapseAll();
  }

  activeForm(): void {
    this.hideForm();
    this.enabledForm = true;
  }

  createForm(): void {
    this.form = this.fb.group({
        code:       ['', Validators.required],
        name:       ['', Validators.required],
        type:       ['', Validators.required],
        parent:     ['', Validators.required],
        parentName: [''],
        codeSearch: [''],
        _id:        [''],
        companyId:  ['']
      });
  }

  indexedFormatCode(data: Category[]): Object {
    const indexed = {};
    data.forEach((current, index) => {
      current['index'] = index;
      current['children'] = [];
      if (indexed.hasOwnProperty(current.code)) {
        console.error('CODIGO DUPLICADO', current.code);
      } else {
        indexed[current.code] = current;
      }
    });
    return indexed;
  }

  isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  getFormat(name: string): boolean {
    const status = String(name).split(' ').map(letter => letter.toLocaleLowerCase()).join('_') === this.highligthted;
    return status;
  }

  changeStatus(): void {
    this.collapse = !this.collapse;
    if (this.collapse) {
      this.treeControl.collapseAll();
    } else {
      this.treeControl.expandAll();
    }
  }

  setAlertMessage(message: string, time: number = 4000, color: string = 'primary'): void {
    this.alertMessage.message = message;
    this.alertMessage.color = color;
    setTimeout(() => this.alertMessage.message = '', time);
  }

  exportAsXLSX(): void {
    console.log('MyData: ', this.myData);
    this.categoryService.getCategories().subscribe(response => {
      const categories = response as Category[];
      const toExport = [];
      const count = 0;
      this.myData.forEach((category, index) => {
        this.destructureCategory(category, toExport, count);
      });
      console.log('toExcelCategories: ', toExport);
      this.excelService.exportAsExcelFile(toExport, 'Catalogo de cuentas contables');
    }, err => console.error('Error al obtener cuentas: ', err));
  }

  destructureCategory(category, container, count): void {
    const row = {
      code: category.code,
      name: category.name,
      description: category.description,
      type: category.type,
      levelx: count
    };
    container.push(row);
    if (category.children.length > 0) {
      count += 1;
      category.children.forEach((categoryChildren, indexChildren) => {
        this.destructureCategory(categoryChildren, container, count);
      });

    }
  }

  saveFile(file): void {
    this.selectedFile = file;
    console.log('File: ', file);
  }

  // ? ----------------------------------------- PARA CONVERTIR IMPORTAR CATALOGO DE CUENTAS DESDE EXCEL ---------------------
  importData(): void {
    this.myData = this.importDataService.importToCategories(this.selectedFile, this.tempIDArr);
  }

}
