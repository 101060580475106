import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DriedService } from '../../../../services/dried.service';
import { Dried } from '../../../../models/dried';
import { Warehouse } from '../../../../models/warehouse';
import { BlendsService } from '../../../../services/blends.service';
import { SidebarManagerService } from '../../../../services/sidebar-manager.service';
import { WarehouseService } from '../../../../services/warehouse.service';
import { SensoryAnalysis } from '../../../../models/sensory';
import { Sample } from '../../../../models/sample';
import { Blend } from '../../../../models/blend';

@Component({
  selector: 'app-blend-add',
  templateUrl: './blend-add.component.html',
  styleUrls: ['./blend-add.component.css']
})
export class BlendAddComponent implements OnInit {
  public form: FormGroup;
  public warehouses: Warehouse[] = [];
  public drieds: Dried[] = [];
  public blend: Dried[] = [];
  public blendIndex: any = {};
  constructor(
    private fb: FormBuilder,
    private _blendManager: BlendsService,
    private _warehouseService: WarehouseService,
    private _driedService: DriedService,
    public _sidebarManager: SidebarManagerService,
  ) { }

  ngOnInit() {
    this.createForm();
    this.getDrieds();
    this.getWarehouses();
  }

  getDrieds = (): void => {
    this._driedService.getDriedsForBlend().subscribe((drieds: Dried[]) => {
      this.drieds = drieds.filter((dried: Dried) => {
        if (dried.inventoryStatus.samples[dried.inventoryStatus.samples.length - 1].analysis.sensory) {
          dried.puntuation = this.getPuntuation(dried.inventoryStatus.samples[dried.inventoryStatus.samples.length - 1]);
          return dried;
        }
        return;
      });
      console.log('drieds blend', this.drieds);
    }, (err: HttpErrorResponse) => console.error('Error al obtener secadas en muestra', err));
  }

  getWarehouses = (): void => {
    this._warehouseService.getWarehouses().subscribe((warehouses: Warehouse[]) => {
      this.warehouses = warehouses;
    }, (err: HttpErrorResponse) => console.error('Error al obtener bodegas', err));
  }

  getPuntuation = (sample: Sample): number => {
    const index = sample.analysis.sensory.results.findIndex((result) => result.sample === sample._id);
    return sample.analysis.sensory.results[index].puntuation;
  }

  selectDryInventory = (dried: Dried, driedIndex: number): void => {
    const selected = dried._id in this.blendIndex;
    if (selected) {
      this.blend.splice(driedIndex, 1);
      delete this.blendIndex[dried._id];
    } else {
      this.blendIndex[dried._id] = true;
      this.blend.push(dried);
    }
  }

  addBlend = (): void => {
    const blend: Blend = this.form.getRawValue();
    blend.dryInventory = Object.keys(this.blendIndex);
    this._blendManager.addBlend(blend).subscribe((res: Blend) => {
      console.log('mezcla agregada', res);
      this.updateDryInventory(res._id);
      this._sidebarManager.close('blend-add', true);
    }, (err: HttpErrorResponse) => console.error('Error al crear mezcla', err));
  }

  updateDryInventory = (blendId: string): void => {
    this.blend.forEach((dried: Dried) => {
      dried.inventoryStatus['blend'] = blendId;
      this._driedService.editDried(
        dried._id,
        { inventoryStatus: dried.inventoryStatus }
      ).subscribe((results) => {
        console.log('Inventario seco actualizado', dried);
      }, (err: HttpErrorResponse) => console.error('Error al actualizar inventario seco', err));
    });
  }

  createForm = (): void => {
    this.form = this.fb.group({
      date:         [new Date(), Validators.required],
      warehouse:    ['', Validators.required],
      area:         ['', Validators.required],
      observations: [''],
    });
  }

}
