import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Dried } from '../models/dried';

@Injectable()
export class DriedService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getDrieds(): Observable<any> { return this.http.get(`${this.url}/drieds/list`); }

  getDriedsData = (filters: any = {}): Observable<any> => this.http.get(`${this.url}/drieds/list-data`, { params: filters });

  getDriedsForBlend = (): Observable<any> => this.http.get(`${this.url}/drieds/list-for-blend`);

  searchSampleDrieds = (filters: any = {}): Observable<any> => this.http.get(`${this.url}/drieds/sample-list`, { params: filters });

  getDryInventoryWithFilters(params: any): Observable<any> {
    return this.http.get(`${this.url}/drieds/dry-inventory-filtered`, {params: params});
  }

  getDriedToEdit(id: string): Observable<any> {
    return this.http.get(`${this.url}/drieds/dried-to-edit`, {params: {_id: id}});
  }

  getDriedsScroll(batch, lastKey?, page?): Observable<any> {
    const query = {
      limit: batch,
      page: page
    };
    if (lastKey) { query['startAt'] = lastKey; }
    return this.http.get(`${this.url}/drieds/listScroll`, { params: query });
  }

  getInventoryDrieds(): Observable<any> {
    return this.http.get(`${this.url}/drieds/inventory-list`);
  }

  getProductionDrieds(): Observable<any> {
    return this.http.get(`${this.url}/drieds/production-list`);
  }

  getDriedPerCode(code): Observable<any> {
    return this.http.get(`${this.url}/drieds/list/${code}`);
  }

  getSoldDriedsFalse(): Observable<any> {
    return this.http.get(`${this.url}/drieds/sold-false`);
  }

  getFD3DriedsFalse(): Observable<any> {
    return this.http.get(`${this.url}/drieds/fd3-false`);
  }

  addDried(dried: Dried): Observable<any> {
    return this.http.post(`${this.url}/drieds/new`, dried);
  }

  getDried(id): Observable<any> {
    return this.http.get(`${this.url}/drieds/${id}`);
  }

  editDried(id, dried): Observable<any> {
    return this.http.put(`${this.url}/drieds/${id}`, dried);
  }

  deleteDried(id): Observable<any> {
    return this.http.delete(`${this.url}/drieds/${id}`);
  }

  getDriedPerWarehouse(warehouse): Observable<any> {
    return this.http.get(`${this.url}/drieds/warehouse/${warehouse}`);
  }

  getDriedPerWeightNote(weightnote): Observable<any> {
    return this.http.get(`${this.url}/drieds/weightnote/${weightnote}`);
  }

  getDriedPerMachine(machine): Observable<any> {
    return this.http.get(`${this.url}/drieds/machine/${machine}`);
  }

  getDriedsWithFilter(filters): Observable<any> {
    return this.http.get(`${this.url}/drieds/list-filter`, {params: filters});
  }

  getDriedsToSale(): Observable<any> {
    return this.http.get(`${this.url}/drieds/list-sale`);
  }

  getDriedsToReferral(): Observable<any> {
    return this.http.get(`${this.url}/drieds/list-referral`);
  }

  getAll(params): Observable<any> {
    return this.http.get(`${this.url}/drieds/list-all`, {params: params});
  }

  checkConstraint(id): Observable<any> {
    return this.http.get(`${this.url}/drieds/check-constraint/${id}`);
  }

  testFd3(id: string, wn): Observable<any> {
    return this.http.get(`${this.url}/drieds/fd3test/${id}` , wn);
  }

  testDry(id: string, wn): Observable<any> {
    return this.http.get(`${this.url}/drieds/drytest/${id}` , wn);
  }

  getSummaryDry(): Observable<any> {
    return this.http.get(`${this.url}/drieds/summary-dry`);
  }

  findPerCode = (code: number): Observable<any> => this.http.get(`${this.url}/drieds/find-per-code/${code}`);
}
