
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AvatarModule } from 'ngx-avatar';
import { ExportsRoutingModule } from './exports-routing.module';

import { ExportersComponent } from './exporters/exporters.component';
import { ExporterAddComponent } from './exporter-add/exporter-add.component';
import { ExporterEditComponent } from './exporter-edit/exporter-edit.component';
import { CustomerAddComponent } from './customer-add/customer-add.component';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { CustomersComponent } from './customers/customers.component';
import { BrokersComponent } from './brokers/brokers.component';
import { BrokerAddComponent } from './broker-add/broker-add.component';
import { BrokerEditComponent } from './broker-edit/broker-edit.component';
import { LotsModule } from './lots/lots.module';
import { SharedModule } from '../../shared/shared.module';
import { ContractsModule } from './contracts/contracts.module';

@NgModule({
  declarations: [
    ExportersComponent,
    ExporterAddComponent,
    ExporterEditComponent,
    CustomerAddComponent,
    CustomerEditComponent,
    CustomersComponent,
    BrokersComponent,
    BrokerAddComponent,
    BrokerEditComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    LotsModule,
    ContractsModule,
    ExportsRoutingModule,
  ],
  entryComponents: [
    BrokerAddComponent,
    BrokerEditComponent,
    ExporterAddComponent,
    ExporterEditComponent,
    CustomerEditComponent,
  ]
})
export class ExportsModule { }
