import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';

import { CoffeeFarmer } from '../../../../models/productor';
import { Warehouse } from '../../../../models/warehouse';
import { Origin } from '../../../../models/origin';

import { UserService } from '../../../../services/user.service';
import { OriginService } from '../../../../services/origin.service';
import { ProductorService } from '../../../../services/productor.service';
import { WarehouseService } from '../../../../services/warehouse.service';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.css'],
  providers: [WarehouseService, OriginService, ProductorService, UserService]
})

export class FilterPanelComponent implements OnInit {
  public form: FormGroup;
  public availableCoffeefarmers: CoffeeFarmer[];
  public filteredCoffeeFarmers: CoffeeFarmer[];
  public availableOrigins: Origin[];
  public availableWarehouses: Warehouse[];
  public currentHarvest;
  public entries = ['Depósito', 'Propio'];
  public statusList = ['Seco', 'Uva', 'Mojado', 'Húmedo', 'Resaca', 'StockLot', 'Oro Exportable'];
  public harvests = ['2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024', 'Todas'];

  @Input() dataFilter: any = {};
  @Output() dataToFilter: EventEmitter<any>;

  constructor(
    private _productorService: ProductorService,
    private _warehouseService: WarehouseService,
    private _originService: OriginService,
    private _userService: UserService,
    private fb: FormBuilder,
  ) {
    this.dataToFilter = new EventEmitter();
  }

  ngOnInit() {
    console.log('ONINIT!!');
    this.createFilterForm();
    this.getDataFromServices();
    this.listeners();
    this.currentHarvest = this._userService.getCompany().harvest;
    this.form.get('harvest').setValue(this.currentHarvest);
  }

  getDataFromServices(): void {
    this._productorService.getProductores().subscribe(response => {
      this.availableCoffeefarmers = _.sortBy(response, 'name');
      this.filteredCoffeeFarmers = _.sortBy(response, 'name');
    }, err => console.error('Error al obtener productores: ', err));

    this._warehouseService.getWarehouses().subscribe(response => {
      this.availableWarehouses = response;
    }, err => console.error('Error al obtener productores: ', err));

    this._originService.getOrigins().subscribe(response => {
      this.availableOrigins = response;
    }, err => console.error('Error al obtener productores: ', err));

  }

  listeners(): void {
    this.form.get('coffeefarmerSearch').valueChanges.subscribe((value: string) => {
      this.filteredCoffeeFarmers = this.availableCoffeefarmers
        .filter(coffeefarmer => String(coffeefarmer.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });
  }

  filterWeightNotes(): void {
    const filterData = this.form.getRawValue();
    Object.keys(filterData).forEach(key => {
      const current = filterData[key];
      if (current === '' || current === undefined || current === null || current === 'Todas') {
        delete filterData[key];
      }
    });
    console.log('toFilter: ', filterData);
    this.dataToFilter.emit(filterData);
  }

  resetForm(): void {
    // tslint:disable-next-line:forin
    for (const name in this.form.controls) {
      this.form.controls[name].setValue('');
    }
    this.form.get('harvest').setValue(this.currentHarvest);
    console.log(this.form.value);
  }

  createFilterForm(): void {
    this.form = this.fb.group({
      cod:          [''],
      date_gte:     [''],
      date_lte:     [''],
      coffeefarmer: [''],
      coffeefarmerSearch: [''],
      state:        [''],
      entry:        [''],
      warehouse:    [''],
      origin:       [''],
      harvest:      ['']
      // observations: ['']
    });
  }



}
