import { Taster } from './taster';

export interface PhysicalValue {
  primaryDefect: number;
  secondaryDefect: number;
  machineDamage: number;
  bandDamage: number;
  exportDamage: number;
  grossWeight: number;
  goldWeight: number;
  threshingPerformance: number;
  humidity: number;
}

export interface TotalResults {
  defects: number;
  damages: number;
  subtotal: number;
  total: number;
}
export interface AnalysisPhysicalResult {
    sample: any;
    values?: PhysicalValue;
    total: TotalResults;
}

export interface PhysicalAnalysis {
    date: string;
    certificates: string[];
    results: AnalysisPhysicalResult[];
    tasters: Taster[];
    comment: string;
    code: number;
    _id: string;
}

// ! DEPREC Campos para llenar sobre cada muestra.
export const physicalValuesDEPRE = [
    { key: 'primaryDefect',   required: true, name: 'Defectos primarios' },
    { key: 'secondaryDefect', required: true, name: 'Defectos secundarios'},
    { key: 'countDefect',     required: true, name: 'Cantidad de defectos'},
    { key: 'damageMachine',   required: true, name: 'Daño Maquina',           suffix: '%' },
    { key: 'damageBand',      required: true, name: 'Daño Banda',             suffix: '%' },
    { key: 'damage',          required: true, name: 'Daño',                   suffix: '%' },
    { key: 'humidity',        required: true, name: 'Humedad',                suffix: '%' },
    { key: 'temperature',     required: true, name: 'Temperatura',            suffix: '°C' },
    { key: 'density',         required: true, name: 'Densidad' },
    { key: 'water',           required: true, name: 'Actividad Agua',         suffix: '%' },
    { key: 'color',           required: true, name: 'Color' },
    { key: 'smell',           required: true, name: 'Aroma' },
    { key: 'uniformity',      required: true, name: 'Uniformidad' },
];

export const physicalValues: string[] = [
  'primaryDefect',
  'secondaryDefect',
  'machineDamage',
  'bandDamage',
  'exportDamage',
  'grossWeight',
  'goldWeight',
  'threshingPerformance',
  'humidity',
];
