import { Component, OnInit } from '@angular/core';
import { PrintService } from '../../services/print.service';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-liquidation-pdf',
  templateUrl: './liquidation-pdf.component.html',
  styleUrls: ['./liquidation-pdf.component.css'],
  providers: [PrintService, UserService]
})
export class LiquidationPdfComponent implements OnInit {

  public report;
  public token;

  constructor(
    private _printService: PrintService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService
  ) {
    this.token = this._userService.getToken();
   }

  ngOnInit() {
    this.getReport();
  }

  getReport() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];
      this._printService.getLiquidation(id).then(
        (res: any) => {
          const blob = new Blob([res], {type: 'application/pdf'});
          const blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        })
        .catch(err => console.log('Error', err));
    });
  }

}
