import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';
import { Http, Response, Headers, RequestOptions } from '@angular/http';

export interface InventoryQuery {
  search: string;
  registration: Date;
}
@Injectable()
export class ChekkuService {
  public url: string;
  public chekku: any = {};
  public token;
  filter: Observable<any>;
  public cadet = [];

  constructor(
    public http: HttpClient,
    private _http: Http,
  ) {
    this.url = GLOBAL.url;
  }

  getSatelliteImage(lat: number, lng: number, year: number): Observable<any> {
    const params = new HttpParams()
      .set('lat', lat.toString())
      .set('lng', lng.toString())
      .set('year', year.toString());

    return this.http.get(`${this.url}/checkins/satellite-image`, { params });
  }

  getCheckinsPerTeam(): Observable<any> {

    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('api-key', 'c8a747d63474b50d5f9437777acdee7be7c275c86f07904fd38e948843');
    console.log(headers);
    return this.http.get(`https://integrations.chekku.site/api/checkins?page=1`, {headers: headers});
  }

  getCheckins(page): Observable<any> {

    const query = {
      page: page,
      apiKey: 'c8a747d63474b50d5f9437777acdee7be7c275c86f07904fd38e948843'
    };

    return this.http.get(`${this.url}/checkins/list`, { params: query });
  }

  getCheckinTypes(): Observable<any> {
    return this.http.get(`${this.url}/checkins/types`);
  }

}
