export class CashCount {
  code: string;
  opening: number;
  closing: number;
  previousBalance: number;
  subOpening: number;
  closed: boolean;
  description: string;
  numberCheck: string;
  enabled: boolean;
  company: any;
  harvest: string;
  collectionCenter: any;
  user: any;
  _id: string;
  totalCount: number;
  saleDate: string;
  denominationsCount: any;
}
