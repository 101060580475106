import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Shipping } from '../models/shipping';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class ShippingService {
  public url = GLOBAL.url;
  constructor(
    public http: HttpClient
  ) { }

  getShippings(): Observable<any> {
    return this.http.get(`${this.url}/shippings/list`);
  }
  addShipping(shipping: Shipping): Observable<any> {
    return this.http.post(`${this.url}/shippings/new`, shipping);
  }
  getShipping(id): Observable<any> {
    return this.http.get(`${this.url}/shippings/${id}`);
  }
  editShipping(id, shipping): Observable<any> {
    return this.http.put(`${this.url}/shippings/${id}`, shipping);
  }
  deleteShipping(id): Observable<any> {
    return this.http.delete(`${this.url}/shippings/${id}`);
  }
}
