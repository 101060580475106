import { ProductionControl } from '../models/dried';

export const prodConstraint = (control: ProductionControl): void => {
  if (control.produced < 0) {
    control.produced = 0;
  }
  if (control.remaining < 0) {
    control.remaining = 0;
  }
  if (control.remaining > control.total) {
    control.remaining = control.total;
  }
  if (control.produced > control.total) {
    control.produced = control.total;
  }
  if (control.remaining > 0 && control.remaining < control.total) {
    control.partial = true;
    control.finished = false;
  } else if (control.remaining === control.total) {
    control.finished = false;
    control.partial = false;
  } else {
    control.finished = true;
    control.partial = false;
  }
};

export const saleConstraint = (control: any, total): void => {
  if (control.produced < 0) {
    control.produced = 0;
  }
  if (control.saled < 0) {
    control.saled = 0;
  }
  if (control.saled > total) {
    control.saled = total;
  }
  if (control.produced > total) {
    control.produced = total;
  }
  if (control.saled > 0 && control.saled < total) {
    control.partial = true;
    control.finished = false;
  } else if (control.saled === total) {
    control.finished = false;
    control.partial = false;
  } else {
    control.finished = true;
    control.partial = false;
  }
};
