import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

import { ContractService } from '../../../../services/contract.service';
import { Contract } from '../../../../models/contract';
import { DeleteModalComponent } from '../../../delete-modal/delete-modal.component';
import { ContractAddComponent } from '../contract-add/contract-add.component';
import { PinUpComponent } from '../../../pin-up/pin-up.component';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.css'],
})
export class ContractListComponent implements OnInit {

  public titulo: string;
  public contracts: Contract[];
  public contract = [];
  public alertMessage;
  public selectedData = [];
  public selectedView = [];
  public totalStamps = 0;
  public openDetails = false;
  closeResult: string;

  displayedColumns: string[] = ['cod', 'customer', 'broker', 'lots', 'type', 'price', 'bags', 'quintals', 'pinup_date', 'dif', 'action'];
  highlightedRows = [];
  public dataSource = new TableVirtualScrollDataSource<Contract>();
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }

  constructor(
    private _contractService: ContractService,
    public dialog: MatDialog
  ) {
    this.titulo = 'Contratos';
  }

  ngOnInit() {
    this.getContracts();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog() {
    const dialogRef = this.dialog.open(ContractAddComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getContracts();
    });
  }
  getDetailsData(contract: Contract) {
    this.selectedData = [];
    this.totalStamps = 0;
    Object.keys(contract.dif).forEach(key => {
      this.selectedData.push({'name': key, 'value': contract.dif[key]});
      this.totalStamps += contract.dif[key];
    });
    console.log('selectedData:', this.selectedData);
  }

  openDialogD(contract) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar contrato',
        title: '',
        text: `¿Seguro que quieres eliminar el contrato <strong>#${contract.cod}</strong>?`,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.delete(contract._id);
      }
    });
  }

  pinUp(contract: any) {
    const dialogRef = this.dialog.open(PinUpComponent, { width: '400px', data: contract });
    dialogRef.afterClosed().subscribe(result => {

      if (result === 'confirm') {
        this.getContracts();
      }
    });
  }

  getContracts() {
    this._contractService.getContracts().subscribe(
      (res) => {
        this.dataSource.data = res as Contract[];
        this.dataSource.connect().next(res);
        this.dataSource.data.forEach(contract => {
          let totalStamps = 0;
          Object.keys(contract.dif).forEach(stamp => {
            totalStamps += contract.dif[stamp];
          });
          contract.differential = totalStamps;
        });
        console.log('Data:', this.dataSource.data);
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  delete(id) {
    this.alertMessage = 'Registo eliminado';
    this._contractService.deleteContract(id).subscribe(
      response => {
        if (!response) {
          alert('Error en el servidor');
        } else {
          this.getContracts();
        }
      },
      error => {
          console.log(error);
      }
    );
  }

  capitalize(name: string): string {
    const temp = name.replace('_', ' ');
    const capitalize = temp.charAt(0).toUpperCase() + temp.slice(1);
    return capitalize;
  }

}
