import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Warehouse } from '../models/warehouse';

@Injectable()
export class WarehouseService {
  public url: string;


  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getWarehouses(): Observable<any> {
    return this.http.get(`${this.url}/warehouses/list`);
  }
  getWarehousesPerCode(code): Observable<any> {
    return this.http.get(`${this.url}/warehouses/list/${code}`);
  }
  addWarehouse(warehouse: Warehouse): Observable<any> {
    return this.http.post(`${this.url}/warehouses/new`, warehouse);
  }
  getWarehouse(id): Observable<any> {
    return this.http.get(`${this.url}/warehouses/${id}`);
  }
  editWarehouse(id, warehouse): Observable<any> {
    return this.http.put(`${this.url}/warehouses/${id}`, warehouse);
  }
  deleteWarehouse(id): Observable<any> {
    return this.http.delete(`${this.url}/warehouses/${id}`);
  }

  /*--------------------------------------------------------------------------------------- */
/*
  addWarehouse(token, warehouse: Warehouse) {
    const params = JSON.stringify(warehouse);
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this._http.post(`${this.url}/warehouses/new`, params, { headers: headers })
      .map(res => res.json());
  }
  getWarehouses(token) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    const options = new RequestOptions({headers: headers});
    return this._http.get(`${this.url}/warehouses/list`, { headers: headers })
                     .map(res => res.json());
  }

  getWarehouse(token, id: string) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    const options = new RequestOptions({ headers: headers });
    return this._http.get(`${this.url}/warehouses/${id}`, options)
                     .map(res => res.json());
  }

  editWarehouse(token, id: string, warehouse: Warehouse) {
    const params = JSON.stringify(warehouse);
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this._http.put(`${this.url}/warehouses/${id}`, params, { headers: headers })
                     .map(res => res.json());
  }

  deleteWarehouse(token, id: string) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    const options = new RequestOptions({ headers: headers });
    return this._http.delete(`${this.url}/warehouses/${id}`, options)
                     .map(res => res.json());
  }

*/
}
