import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Referral } from '../models/referral';

@Injectable()
export class ReferralService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getReferrals(): Observable<any> { return this.http.get(`${this.url}/referrals/list`); }
  getReferralsSum(): Observable<any> { return this.http.get(`${this.url}/referrals/sum`); }
  getPaidOutReferrals(): Observable<any> { return this.http.get(`${this.url}/referrals/paid-out-list`); }
  getPendingReferrals(): Observable<any> { return this.http.get(`${this.url}/referrals/pending-list`); }

  addReferral(referral: Referral): Observable<any> {
    return this.http.post(`${this.url}/referrals/new`, referral);
  }
  getReferral(id): Observable<any> {return this.http.get(`${this.url}/referrals/${id}`); }
  editReferral(id, referral): Observable<any> {return this.http.put(`${this.url}/referrals/${id}`, referral); }
  deleteReferral(id): Observable<any> {return this.http.delete(`${this.url}/referrals/${id}`); }
  getReferralPerWarehouse(warehouse): Observable<any> {
    return this.http.get(`${this.url}/referrals/warehouse/${warehouse}`);
  }
  getReferralPerCustomer(customer): Observable<any> {
    return this.http.get(`${this.url}/referrals/customer/${customer}`);
  }
  getReferralPerWeightNotes(weightnotes): Observable<any> {
    return this.http.get(`${this.url}/referrals/weightnotes/${weightnotes}`);
  }
}
