import { NgModule } from '@angular/core';
import { CapitalizePipe } from './capitalize.pipe';
import { InputNumberFormatPipe } from './input-number-format.pipe';
import { NumberFormatPipe } from './number-format.pipe';

@NgModule({
    declarations: [
        NumberFormatPipe,
        InputNumberFormatPipe,
        CapitalizePipe,
    ],
    exports: [
        NumberFormatPipe,
        InputNumberFormatPipe,
        CapitalizePipe
    ]
  })
  export class PipesModule {}
