import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

import { GLOBAL } from '../../services/global';
import { UserService } from '../../services/user.service';
import { ReportService } from '../../services/reports.service';
import { ProductorService } from '../../services/productor.service';
import { InventoryService } from '../../services/inventory.service';
import { SettlementService } from '../../services/settlement.service';
import { CashCountsService } from '../../services/cash-counts.service';
import { PaymentService } from '../../services/payment.service';
import { CollectionCenterService } from '../../services/collectionCenter.service';
import { AdvancePaymentService } from '../../services/advancePayment.service';
import { CoffeeFarmer } from '../../models/productor';
import { Inventory } from '../../models/inventory';
import { Settlement } from '../../models/settlement';
import { CashCount } from '../../models/cash-count';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexPlotOptions,
  ApexResponsive,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend,
  ApexFill
} from 'ng-apexcharts';

export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  fill: ApexFill;
  stroke: ApexStroke;
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
  responsive: ApexResponsive[];
  labels: any;
  markers: ApexMarkers;
  tooltip: any;
  colors: any;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
}

import * as _moment from 'moment';
import { ReferralService } from '../../services/referral.service';
import { ProductionService } from '../../services/production.service';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-starter',
  templateUrl: './starter.component.html',
  providers: [
    UserService,
    InventoryService,
    AdvancePaymentService,
    PaymentService,
    ReportService,
    SettlementService,
    ProductorService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class StarterComponent implements OnInit, OnDestroy {
  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('chartC') chartC: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartOptionsC: Partial<ChartOptions>;

  date = new FormControl(moment());

  public titulo = 'Dashboard';
  public settlements: Settlement[];
  public inventories: Inventory[];
  public inventory = [];
  public identity: any = {};
  public url: string;
  public next_page;
  public prev_page;
  public confirmado;
  public sum: any;
  public sett: any;
  public currency;
  public objDate = Date.now();
  public totalDiscountedAP = 0;
  public totalDiscountedSett = 0;

  public total_qq: number;
  public total_lb: number;
  public countP: number;

  public totaldeposito: number;
  public totalcompra: number;
  public averagePrice;

  public productores: CoffeeFarmer[];
  public currentHarvest = '';
  public sumBudget = 0;

  num = 1;

  apSum;
  pSum;
  series = [];
  seriesC = [];
  seriesd = [44, 55, 13, 43, 22];
  charts = {
    height: 380,
    type: 'area',
    colors: ['#000']
  };
  fill = {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.45,
          opacityTo: 0.05,
          stops: [20, 100, 100, 100]
        }
      };
  fillc = {
    color: 'transparent'
  };
  colors = ['#162d72', '#00e9ff', '#f7ca18', '#17b978', '#a7ff83', '#f7ca18', '#17b978', '#f64747'];
  colorsC = ['#8ac185', '#071a52', '#2f3d4a'];
  stroke = {
    curve: 'smooth'
  };
  dataLabels = {
    enabled: false,
  };
  labels = [];
  leyend = {
     tooltipHoverFormatter: function(val, opts) {
      return (
        val +
        ' - <strong>' +
        opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
        '</strong>'
      );
    }
  };
  markers = {
    size: 0,
    hover: {
      sizeOffset: 6
    }
  };
   markersC = {
    enabled: false
  };
  grid = {
    borderColor: '#f1f1f1'
  };
  gridC = {
    borderColor: 'transparent'
  };
  legend = {
    enabled: false
  };
  today;
  xaxis = {};
      responsives = [
        {
          breakpoint: 480,
          chart: {
            width: 200
          },
          options: {
            legend: {
              enabled: false
            }
          }
        }
      ];
  public total_net = [];
  public t_qq = [];
  public total_deductions = [];
  public dates = [];
  public collectionCenter;
  public opening = 0;
  public sumReferrals = 0;
  public openings = [];
  public productionSummaries: any = {};
  public cashCounts: CashCount[] = [];
  now = new Date();
  private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    private _inventoryService: InventoryService,
    private _advancePaymentService: AdvancePaymentService,
    private _paymentService: PaymentService,
    private _userService: UserService,
    private _cashCountService: CashCountsService,
    private _settlementService: SettlementService,
    private _collectionCenterService: CollectionCenterService,
    private _referralService: ReferralService,
    private _productionService: ProductionService,
    public datepipe: DatePipe
  ) {
    this.currentHarvest = this._userService.getCompany().harvest;
    this.identity = this._userService.getIdentity();
    this.chartOptionsC = {
      chart: {
        type: 'donut'
      }
    };
  }
  ngOnInit() {
    console.log(this.identity);
    this.currency = GLOBAL.currency;
    this.getSumInventory();
    this.getSumSettlement();
    this.getSumPayment();
    this.getSumAdPayment();
    this.getFullInventory();
    this.getTodayInventory();
    this.getSumReferrals();
    this.getProductionSummaries();    
    if (this.identity._id === '5fa2a50e1c9a8952e6e1bc1f' || this.identity._id === '5f49cc3e6952eb738a3b4488' || this.identity._id === '631f6f435bf0d46118a5f34d' || this.identity._id === '6320a0138a7e0c6668b454c1') {
      this.getCashCounts();
    } else {
      if (this.identity.collectionCenter) {
      this._collectionCenterService.getCollectionCenter(this.identity.collectionCenter).subscribe( res => {
        this.collectionCenter = res;
        this.getTotalOpeningDiscounted();
        this.opening = res.cashCount.opening;
      });
    }
    }
    this.chartOptions = {
      tooltip: {
        format: 'dd/MM/yy HH:mm',
        y: [
          {
            title: {
              formatter: function(val) {
                return val + ' Lps.';
              }
            }
          },
/*           {
            title: {
              formatter: function(val) {
                return val + ' qq';
              }
            }
          } */
        ]
      }
    };
    this._settlementService.getSettlementsG().subscribe((res: any) => {

      const days = this.now.getDate() - 7;
      const now = this.now.getDate();
      res.forEach(item => {
        if (item.date && item.total_net && item.total_qq) {
          const dateI = new Date(item.date);
          const date = (dateI.getDate());
          if (date >= days && date <= now) {
            const month = (dateI.getMonth() + 1);
            const dateF = date.toString() + '/' + month.toString();
            this.dates.push(dateF);
            this.total_net.push(item.total_net.toFixed(2));
            this.t_qq.push(item.total_qq.toFixed(2));
          }
        } else {
        }
      });
      this.series = [
        {
          name: 'Pago',
          data: this.total_net
        },/* 
        {
          name: 'Quintales',
          data: this.t_qq
        } */
      ];
      this.xaxis = {
        labels: {
          trim: true
        },
        categories: this.dates
      };
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  getProductionSummaries() {
    this._productionService.getProductionSummaries().subscribe((res) => {
      console.log(res);
      this.productionSummaries = res;
    });
  }

  getCashCounts() {
    this._collectionCenterService.getCollectionCenters().subscribe(res => {
      this.cashCounts = res;
      this.openings = [];
      this.cashCounts.forEach(async (cash: any) => {
        if (cash.cashCount && cash.cashCount.closed === false) {
          const sett: any = await this._settlementService.getTotalOpeningDiscounted(cash._id).toPromise();
          const advanceP: any = await this._advancePaymentService.getTotalOpeningDiscounted(cash._id).toPromise();
          const weight_notes: any = await this._inventoryService.getTotalOpeningDiscounted(cash._id).toPromise();
          const discounted = advanceP.sum + sett.sum;
          let disc = 0;
          console.log(weight_notes.sum);
          if (discounted >= cash.cashCount.opening) {
            disc = 0;
          } else {
            disc = cash.cashCount.opening - discounted;
          }
          const open = {
            name: cash.name,
            address: cash.address,
            budget: disc,
            weight_notes: weight_notes.sum
          }
          this.opening = this.opening + disc;
          this.openings.push(open);
        } else {
          const weight_notes: any = await this._inventoryService.getTotalOpeningDiscounted(cash._id).toPromise();
          const open = {
            name: cash.name,
            address: cash.address,
            budget: 0,
            weight_notes: weight_notes.sum
          }
          this.openings.push(open);
        }
      });
    }, err => console.error('Error al obtener arqueos de caja', err));
  }
  getSumPayment() {
    this._paymentService.getSumPaymentsCurrentHarvest().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.pSum = res[0].total_payment;
    });
  }

  getSumAdPayment() {
    this._advancePaymentService.getTotalAdvancePaymentPerHarvest(this.currentHarvest)
    .pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
      this.apSum = res.sum;
    }, err => console.error('Error al obtener sumAdPayement: ', err));
  }

  getFullInventory() {
    this._inventoryService.getInventoriesState().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
        const sortedData = res.sort((a, b) => b.percentage - a.percentage);
        this.labels = sortedData.map(item => item.state);
        this.seriesC = sortedData.map(item => item.total);
    });
  }

  getTodayInventory() {
    this._inventoryService.getToday().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
      this.today = res.qq;
    }, err => console.error('Error al obtener inventario de hoy: ', err));
  }

  getSumInventory() {
    this._inventoryService.getSumInventoryPerHarvest(this.currentHarvest).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
      const data = res;
      this.total_qq = data.totalDeposito + data.totalPropio;
    }, err => console.error('Error al obtener la sumas de inventario: ', err));
  }

  getSumReferrals(): void {
    this._referralService.getReferralsSum().subscribe(res => {
      if (res) {
        this.sumReferrals = res.totalqq;
      }
    }, err => {
      console.error('ERROR EN Liquidaciones con filtro ', err);
    });
  }

  getSumSettlement() {
    this._settlementService.getSumSettlementByHarvest(this.currentHarvest).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
      const data = res;
      this.totaldeposito = data.totalqq;
      const total = data.total;
      this.totalcompra = data.total;
      this.averagePrice = total / this.totaldeposito;
    }, err => console.error('Error al obtener la suma de liquidaciones: ', err));
  }

  getTotalOpeningDiscounted() {
    this._settlementService.getTotalOpeningDiscounted(this.identity.collectionCenter).subscribe(res => {
      const data = res;
      this.totalDiscountedSett = data.sum;
      this.getTotalOpeningDiscountedAP();
    }, err => console.error('Error al obtener la suma de descuento: ', err));
  }

  getTotalOpeningDiscountedAP() {
    this._advancePaymentService.getTotalOpeningDiscounted(this.identity.collectionCenter).subscribe(res => {
      const data = res;
      this.totalDiscountedAP = data.sum;
      const discounted: number = this.totalDiscountedSett + this.totalDiscountedAP;
      if (discounted >= this.opening) {
        this.opening = 0;
      } else {
        this.opening = this.opening - discounted;
      }
    }, err => console.error('Error al obtener la suma de descuento: ', err));
  }
}
