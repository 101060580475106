import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Machine } from '../models/machine';
import { Http, Response, Headers } from '@angular/http';

@Injectable()
export class MachineService {
  public url: string;

  constructor(private _http: Http, private http: HttpClient ) {
    this.url = GLOBAL.url;
  }
  getMachines(): Observable<any> { return this.http.get(`${this.url}/machines/list`); }

  addMachine(machine: Machine): Observable<any> {
    return this.http.post(`${this.url}/machines/new`, machine);
  }
  getMachine(id): Observable<any> {return this.http.get(`${this.url}/machines/${id}`); }
  editMachine(id, machine): Observable<any> {
    return this.http.put(`${this.url}/machines/${id}`, machine);
  }
  deleteMachine(id): Observable<any> {
    return this.http.delete(`${this.url}/machines/${id}`);
  }
}
