export class UserRules {
  user: any;
  dashboard: boolean;
  weight_notes: boolean;
  drieds: boolean;
  admin: boolean;
  exports: boolean;
  production: boolean;
  accounting: boolean;
  reports: boolean;
  constructor() {
    this.dashboard = true;
    this.weight_notes = true;
    this.drieds = true;
    this.admin = true;
    this.exports = true;
    this.production = true;
    this.accounting = true;
    this.reports = true;
  }
}
