import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Broker } from '../models/broker';

@Injectable()
export class BrokerService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  getBrokers(): Observable<any> {
    return this.http.get(`${this.url}/brokers/list`);
  }
  addBroker(broker: Broker): Observable<any> {
    return this.http.post(`${this.url}/brokers/new`, broker);
  }
  getBroker(id): Observable<any> {
    return this.http.get(`${this.url}/brokers/${id}`);
  }
  editBroker(id, broker): Observable<any> {
    return this.http.put(`${this.url}/brokers/${id}`, broker);
  }
  deleteBroker(id): Observable<any> {
    return this.http.delete(`${this.url}/brokers/${id}`);
  }

}
