import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';

import { SampleService } from '../../../../services/sample.service';
import { TasterService } from '../../../../services/taster.service';
import { PhysicalService } from '../../../../services/physical.service';
import { SidebarManagerService } from '../../../../services/sidebar-manager.service';

import { AnalysisPhysicalResult, PhysicalAnalysis, PhysicalValue, physicalValues, TotalResults } from '../../../../models/physical';
import { Sample } from '../../../../models/sample';
import { Taster } from '../../../../models/taster';

@Component({
  selector: 'app-physical-add',
  templateUrl: './physical-add.component.html',
  styleUrls: ['./physical-add.component.css'],
  styles: [`
    :host {
      height: 100%;
      overflow-y: auto;
    }`
  ],
})
export class PhysicalAddComponent implements OnInit {
  public form: FormGroup;
  public tasters: Taster[] = [];
  public filteredSamples: Sample[] = [];
  public samples: Sample[] = [];
  public currentSample: any;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public certificates: string[] = ['Organic', 'Fairtrade', 'UTZ', 'Convencional', 'RFA'];
  public analysisValues = physicalValues;

  constructor(
    private _tasterService: TasterService,
    private _sampleService: SampleService,
    private _physicalService: PhysicalService,
    private _sidebarManager: SidebarManagerService,
    private _fb: FormBuilder
  ) { }

  ngOnInit() {
    this.createForm();
    this.getTasters();
    this.getSamples();
  }

  getTasters(): void {
    this._tasterService.getTasters().subscribe(res => {
      this.tasters = res;
    }, (err: HttpErrorResponse) => console.error('Error al obtener catadores', err));
  }

  getSamples(): void {
    this._sampleService.getSamples({ 'analysis.physical': null, enabled: true }).subscribe((res: Sample[]) => {
      this.filteredSamples = res;
      this.samples = res;
    }, (err: HttpErrorResponse) => console.error('Error al obtener muestras', err));
  }

  searchSample = (name: string): void => {
    this.filteredSamples = _.differenceBy(this.samples, this.selectedItems.value
      .map(((item: AnalysisPhysicalResult) => item.sample)), '_id')
      .filter(item => item.name.includes(name));
  }

  searchSamples = (name: string | number): void => {
    this.filteredSamples = this.samples
      .filter(sample => {
        const codeString = String(sample.code); // Asegurarse de que el código sea una cadena
        return codeString.indexOf(String(name)) !== -1;
      })
      .filter(sample => !this.selectedItems.value.some(item => item.sample._id === sample._id));
  }

  pushSample(sample: Sample): void {
    this.selectedItems.push(this._fb.group({
      sample: sample,
      values: this.createAnaylisisForm(sample),
      total: this._fb.group({
        defects:    [0],
        damages:    [0],
        subtotal:   [0],
        total:      [0],
      })
    }));
    console.log('2');
    this.filterSamples();
    console.log('3');
  }

  selectSample(index: number): void {
    this.currentSample = this.selectedItems.at(index);
  }

  remove(index: number): void {
    const list = this.form.controls.results as FormArray;
    list.removeAt(index);
    this.filterSamples();
  }

  addPhysical(): void {
    const physicalAnalysis: PhysicalAnalysis = this.form.getRawValue();
    console.log('to create', physicalAnalysis);
    this._physicalService.addPhysical(physicalAnalysis).subscribe(res => {
      physicalAnalysis.results.forEach((item: AnalysisPhysicalResult, index: number) => {
        item.sample.analysis.physical = res._id;
        this._sampleService.updateSample(item.sample._id , item.sample).subscribe({
          next: (val) => {
            console.log('next', val);
          },
          complete: () => {
            console.log('complete');
            if (index === physicalAnalysis.results.length - 1) this._sidebarManager.close('physical-add', true);
          },
          error: (err: HttpErrorResponse) => console.error('Error al actualizar muestra', err)
        });
      });
    }, (err: HttpErrorResponse) => console.error('Error al agregar analisis fisico', err));
  }

  createForm(): void {
    this.form = this._fb.group({
      date:           [new Date(), Validators.required],
      certificates:   ['', Validators.required],
      tasters:        ['', Validators.required],
      observations:   [''],
      results: this._fb.array([], Validators.required)
    });
  }

  filterSamples = (): void => {
    this.filteredSamples = _.differenceBy(this.samples, this.selectedItems.value
      .map(((item: AnalysisPhysicalResult) => item.sample)), '_id');
  }

  createAnaylisisForm(data: Sample): FormGroup {
    const form: any = {};
    this.analysisValues.forEach(field => form[field] = ['', Validators.required]);
    const analysisForm = this._fb.group(form);
    analysisForm.patchValue({
      grossWeight: data.weight
    });
    return analysisForm;
  }

  sumValues = (type = 'damage'): void => {
    if (!this.currentSample) return;
    if (type === 'damage') {
      const defects = this.values.primaryDefect + this.values.secondaryDefect;
      const damages = this.values.machineDamage + this.values.bandDamage;
      const subtotal = defects + damages;
      const total = subtotal - this.values.exportDamage;
      this.currentSample.get('total').setValue({
        defects,
        damages,
        subtotal,
        total,
      });
    } else {
      this.currentSample.get('values').patchValue({
        threshingPerformance:  parseFloat((this.values.grossWeight / this.values.goldWeight).toFixed(4))
      });
    }
  }

  get selectedItems() {
    return this.form.controls.results as FormArray;
  }

  get analysis(): AnalysisPhysicalResult {
    return this.currentSample.value;
  }

  get values(): PhysicalValue {
    return this.currentSample.value.values;
  }

  get total(): TotalResults {
    return this.currentSample.value.total;
  }

}
