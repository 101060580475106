import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CollectionCenter } from '../../../../models/collectionCenter';
import { CashCountsService } from '../../../../services/cash-counts.service';
import { CollectionCenterService } from '../../../../services/collectionCenter.service';
import { UserService } from '../../../../services/user.service';
import { CashCount } from '../../../../models/cash-count';
import { SettlementService } from '../../../../services/settlement.service';
import { AdvancePaymentService } from '../../../../services/advancePayment.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-cash-count-add',
  templateUrl: './cash-count-add.component.html',
  styleUrls: ['./cash-count-add.component.css']
})
export class CashCountAddComponent implements OnInit {
  public form: FormGroup;
  public registers: CollectionCenter[] = [];
  public lasCashCount: CashCount[] = [];
  public identity;
  public opening;
  public open;
  public totalDiscountedAP = 0;
  public totalDiscountedSett = 0;
  public isOpening = false;
  loading = false;
  public cashId;
  public sold;
  constructor(
    private _collectionCenterService: CollectionCenterService,
    private _cashCountService: CashCountsService,
    private _fb: FormBuilder,
    public _authService: UserService,
    private _advancePaymentService: AdvancePaymentService,
    private _settlementService: SettlementService,
    public dialogRef: MatDialogRef<CashCountAddComponent>
  ) {
    this.identity = this._authService.getIdentity();
  }

  ngOnInit(): void {
    this.createForm();
    this.getCashRegisters();
    this.listenersFormValues();
  }

  listenersFormValues(): void {
    this.form.get('collectionCenter').valueChanges.subscribe((value: string) => {
      console.log(value);
      this.getCashCounts(value);
    });
    this.form.get('newOpening').valueChanges.subscribe((value: string) => {
      const newOpening = Number(value);
      if (!isNaN(newOpening)) {
          this.calculateOpenig();
      }
    });
  }

  getCashRegisters(): void {
    this._collectionCenterService.getCollectionCenters().subscribe(res => {
      this.registers = res;
    }, err => console.error('Error al obtener cajas registradoras', err));
  }

  getCashCounts(id): void {
    this._cashCountService.getCashCountsLast(id).subscribe(res => {
      this.lasCashCount = res;
      if (res) {
        this.isOpening = true;
        this.cashId = res._id;
        console.log(this.lasCashCount);
        this.getTotalOpeningDiscounted(id);
        this.opening = res.opening;
        console.log(this.opening);
      } else {
        this.isOpening = false;
      }
    }, err => console.error('Error al obtener arqueos de caja', err));
  }

  getTotalOpeningDiscounted(id) {
    this._settlementService.getTotalOpeningDiscounted(id).subscribe(res => {
      const data = res;
      console.log(data);
      this.totalDiscountedSett = data.sum;
      this.getTotalOpeningDiscountedAP(id);
    }, err => console.error('Error al obtener la suma de descuento: ', err));
  }

  getTotalOpeningDiscountedAP(id) {
    this._advancePaymentService.getTotalOpeningDiscounted(id).subscribe(res => {
      const data = res;
      console.log('descuento ', data);
      this.totalDiscountedAP = data.sum;
      const discounted: number = this.totalDiscountedSett + this.totalDiscountedAP;
      console.log(discounted);
      console.log(this.opening);
      if (discounted >= this.opening) {
        this.opening = 0;
      } else {
        this.opening = this.opening - discounted;
        this.open = discounted;
      }
      this.form.get('lastOpening').setValue(this.opening);

      console.log(this.opening);
    }, err => console.error('Error al obtener la suma de descuento: ', err));
  }

  calculateOpenig(): void {
      const newOpening = Number(String(this.form.get('newOpening').value).split(',').join(''));
      const opening = this.opening + newOpening;
      this.form.get('opening').setValue(opening);
  }

  addOpening(): void {
    this.loading = true;
    const cashCount = this.form.getRawValue();
    console.log(cashCount);
    if (this.isOpening) {
      const cash = {
        totalCount: this.open,
        closing: this.open,
        sold: this.open,
        closed: true,
      };
      console.log(cash);
      this._cashCountService.updateCashCount(this.cashId, cash ).subscribe(res => {
        console.log(res);
        this.dialogRef.close(true);
      });
    }
    cashCount.previousBalance = cashCount.lastOpening;
    cashCount.subOpening = cashCount.newOpening;
    this._cashCountService.addCashCount(cashCount).subscribe(res => {
      console.log(res);
      this.dialogRef.close(true);
    });
  }

  createForm(): void {
    this.form = this._fb.group({
      collectionCenter: ['', Validators.required],
      lastOpening: [''],
      numberCheck: [''],
      newOpening:      [''],
      opening:      ['', Validators.required],
    });
  }

}
